<div class="grid table-demo">
    <div class="col-12">
        <div class="card">
            <div class="grid">
                <div class="col-12">
                    <span><a href="javascript:" (click)="goToFolder(null)" *ngIf="pathSegments !=null">Root</a>
                        <ng-container *ngIf="pathSegments == null">Root</ng-container>
                        <ng-container *ngFor="let item of pathSegments; let last = last;" class="ml-2">/<a
                                href="javascript:" (click)="goToFolder(item)" *ngIf="!last">{{item.name}}</a>
                            <ng-container *ngIf="last">{{item.name}}</ng-container>
                        </ng-container>
                    </span>
                    
                    <button pButton pRipple label="Add Document" (click)="addDocument()" class="p-button-success mr-2"
                        style="float: right;" [disabled]="pathSegments == null || !allowAddDocument"></button>
                    <button pButton pRipple label="Add Folder" (click)="newFolder()" class="p-button-success mr-2"
                        style="float: right;"
                        [disabled]="(pathSegments != null && pathSegments.length==3) || !allowAddFolder"></button>
                        <button   pButton pRipple label="Back" icon="pi pi-arrow-left" class="p-button-success mr-2" style="float: right;"(click)="appUtil.return()"></button>
                    <div class="field col-12 md:col-12 text-right" *ngIf="mediaLoading">
                        <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner"
                            strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                    </div>
                </div>
            </div>
            <div class="grid">
                <div class="col-12">
                    <p-dataView #dv [value]="items" [lazy]="true" (onLazyLoad)="loadData($event)"
                        [totalRecords]="totalRecords" layout="grid" [paginator]="true" [rows]="50" filterBy="name"
                        [sortField]="sortField" [sortOrder]="sortOrder" [first]="firstRow">
                        <ng-template pTemplate="header">
                            <div class="block mb-2"> Documents/Images with grey background are public and can be accessed without login.</div>
                            <div class="flex flex-column md:flex-row md:justify-content-between">
                               
                                <div>
                                    <p-dropdown [options]="sortOptions" optionLabel="name" optionValue="code"
                                        [(ngModel)]="sortKey" (onChange)="onSortChange($event)"
                                        styleClass="mb-2 md:mb-0 ml-1"></p-dropdown>
                                </div>
                                <div>
                                    
                                    <!-- <i class="pi pi-circle-fill"></i> <span style="margin-right: 10px;">- Used</span> -->
                                    <span class="p-input-icon-left mb-2 md:mb-0">
                                        <i class="pi pi-search"></i>
                                        <input type="text" pInputText placeholder="Search by Name" [(ngModel)]="term"
                                            (keyup.enter)="search(0)">
                                    </span>
                                    <p-dropdown [options]="accessTypes" optionLabel="name" optionValue="code" *ngIf="!onlyPublicImages"
                                        [(ngModel)]="accessType" (onChange)="onAccessTypeChange($event)"
                                        styleClass="mb-2 md:mb-0 ml-1"></p-dropdown>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template let-item pTemplate="gridItem">
                            <div class="col-12 p-as-end" [ngClass]="{'md:col-1' : !embeded, 'md:col-2': embeded}">
                                <div class="product-grid-item card"> 
                                    <div class="product-grid-item-content" (click)="openItem($event, item)">
                                        <div
                                            style="text-align: center; align-items: flex-end; display: flex;justify-content: center;">
                                            <img src="/assets/layout/images/folder.jpg" 
                                                *ngIf="item.folder"
                                                style="box-shadow: none; width: auto; max-width: 95%;" />
                                            <img src="/assets/layout/images/folder.jpg" 
                                                *ngIf="item.type == 'xls'"
                                                style="box-shadow: none; width: auto; max-width: 95%;" />
                                            <img src="/assets/layout/images/folder.jpg" 
                                                *ngIf="item.type == 'pdf'"
                                                style="box-shadow: none; width: auto; max-width: 95%;" />
                                            <img src="/assets/layout/images/folder.jpg" 
                                                *ngIf="item.type == 'word'"
                                                style="box-shadow: none; width: auto; max-width: 95%;" />
                                            <img src="/assets/layout/images/folder.jpg" 
                                                *ngIf="item.type == 'txt'"
                                                style="box-shadow: none; width: auto; max-width: 95%;" />
                                            <img src="/assets/layout/images/folder.jpg" 
                                                *ngIf="item.type == 'ppt'"
                                                style="box-shadow: none; width: auto; max-width: 95%;" />
                                            <img imgSrc="{{item.url}}" 
                                                *ngIf="item.type == 'image'"
                                                style="box-shadow: none; width: auto; max-width: 95%;align-self: center;" />
                                        </div>
                                        <div class="product-name" style="word-wrap: break-word; font-size: 1rem;">
                                            {{item.name}}</div>
                                    </div>
                                    <div class="text-center">
                                        <span class="p-buttonset">
                                            <button pButton pRipple icon="pi pi-pencil" class="p-button-sm" [ngClass]="item.availableToGuestUser?'p-button-success':'p-button-secondary'" (click)="editItem(item)" *ngIf="item.editable" title="Edit"></button>
                                            <button pButton pRipple icon="pi pi-copy" class="p-button-sm" [ngClass]="item.availableToGuestUser?'p-button-success':'p-button-secondary'" (click)="copyUrl(item)" *ngIf="!item.folder" title="Copy Url"></button>
                                            <button pButton pRipple icon="pi pi-trash" class="p-button-sm" [ngClass]="item.availableToGuestUser?'p-button-success':'p-button-secondary'" (click)="deleteItem(item)" *ngIf="item.editable" title="Delete"></button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </p-dataView>

                </div>
            </div>
        </div>
    </div>
</div>
<p-dialog [(visible)]="folderDialog" (onHide)="hideFolderForm()" [style]="{width: '450px'}" [header]="folderTitle"
    [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
        <form [formGroup]="form" id="clform" (ngSubmit)="saveFolder()">
            <div class="p-fluid  grid">
                <div class="col-12">
                    <label for="value">Parent Folder</label>
                    <div><strong>Root <ng-container *ngFor="let item of pathSegments; let last = last;"> >>
                                {{item.name}}</ng-container></strong></div>
                </div>
                <div class="col-12 field">
                    <label for="value">Folder Name</label><span class="mandate">*</span>
                    <input type="text" pInputText formControlName="name"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.name.errors }" required autofocus />
                    <small class="p-error" *ngIf="submitted && f.name.invalid && f.name.hasError('required')">Name is
                        required.</small>
                    <small class="p-error"
                        *ngIf="submitted && f.name.invalid && f.name.hasError('message')">{{f.name.errors.message}}</small>
                </div>
            </div>

        </form>
    </ng-template>

    <ng-template pTemplate="footer">
        <div *ngIf="loadingSpinner">
            <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
                fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
        </div>
        <div *ngIf="loadingSpinner == false">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
                (click)="hideFolderForm()"></button>
            <button pButton pRipple label="Save" form="clform" icon="pi pi-check" class="p-button-text"></button>
        </div>
    </ng-template>
</p-dialog>

<p-dialog [(visible)]="documentDialog" (onHide)="hideDocumentDialog()" [style]="{width: '450px'}" [header]="fileTitle"
    [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
        <form [formGroup]="documentForm" id="clform1" (ngSubmit)="saveDocument()">
            <div class="p-fluid  grid">
                <div class="field col-12">
                    <p-checkbox styleClass="form-control" (onChange)="isPublicChecked($event)"
                        formControlName="isPublic" [binary]="true" label="Is Public"></p-checkbox>
                </div>
            </div>
            <!--
      <div class="field">
        <label for="value">Url Key</label>
        <input type="text"  pInputText  formControlName="urlKey"/>
        <span class="p-error" *ngIf="submittedUpload && df.urlKey.invalid && df.urlKey.hasError('required')">Url Key is required.</span>
        <span class="p-error" *ngIf="submittedUpload && df.urlKey.invalid && df.urlKey.hasError('message')">{{df.urlKey.errors.message}}</span> 
    </div>   
    -->
            <div class="field">
                <p-fileUpload name="file" #form (onRemove)="deleteDocument($event,form)"
                    (onSelect)="selectFile($event,form)" [maxFileSize]="maxFileSize" [auto]="true" chooseLabel="Browse"
                    [multiple]="false">

                </p-fileUpload>
                <span class="p-error" *ngIf="submittedUpload && df.file.invalid && df.file.hasError('required')">File is
                    required.</span>
                <span class="p-error"
                    *ngIf="submittedUpload && df.file.invalid && df.file.hasError('message')">{{df.file.errors.message}}</span>
            </div>
            <div class="field" *ngIf="!itemSelectedForEditing.folder">
                <label class="font-bold inline">Current Document/Image</label>
                <div
                style="text-align: center; align-items: flex-end; display: flex;justify-content: center;">
                <img src="/assets/layout/images/folder.jpg" 
                    *ngIf="itemSelectedForEditing.folder"
                    style="box-shadow: none; width: auto; max-width: 60px;" />
                <img src="/assets/layout/images/folder.jpg" 
                    *ngIf="itemSelectedForEditing.type == 'xls'"
                    style="box-shadow: none; width: auto; max-width: 60px;" />
                <img src="/assets/layout/images/folder.jpg" 
                    *ngIf="itemSelectedForEditing.type == 'pdf'"
                    style="box-shadow: none; width: auto; max-width: 60px;" />
                <img src="/assets/layout/images/folder.jpg" 
                    *ngIf="itemSelectedForEditing.type == 'word'"
                    style="box-shadow: none; width: auto; max-width: 60px;" />
                <img src="/assets/layout/images/folder.jpg" 
                    *ngIf="itemSelectedForEditing.type == 'txt'"
                    style="box-shadow: none; width: auto; max-width: 60px;" />
                <img src="/assets/layout/images/folder.jpg" 
                    *ngIf="itemSelectedForEditing.type == 'ppt'"
                    style="box-shadow: none; width: auto; max-width: 60px;" />
                <img imgSrc="{{itemSelectedForEditing.url}}" 
                    *ngIf="itemSelectedForEditing.type == 'image'"
                    style="box-shadow: none; width: auto; max-width: 60px;align-self: center;" />
            </div>
            </div>
        </form>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
            (click)="hideDocumentDialog()"></button>
        <button pButton pRipple label="Save" form="clform1" icon="pi pi-check" class="p-button-text"></button>
    </ng-template>
</p-dialog>
<p-toast></p-toast>