import { AppUtil } from '../../_helpers/app.util';
import { Validators } from '@angular/forms';
import { QueryBuilderComponent, Rule, QueryBuilderConfig, RuleSet } from 'angular2-query-builder';
import { Component, Input, OnInit, Output, ViewChild, EventEmitter, SimpleChanges } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'tbp-query-builder',
    templateUrl: './tbp-query-builder.component.html',
    styleUrls: ['./tbp-query-builder.component.scss'],
    providers: [DatePipe]
})
export class TbpQueryBuilderComponent implements OnInit {


    @Input() fields: any = {};
    @Input() query: any = { condition: 'and', rules: [] };
    @Output() onSearch = new EventEmitter<any>();


    operatorMap: { [key: string]: string[] } = {
        string: ['matches'],
        number: ['=', '!=', '>', '>=', '<', '<='],
        range: ['>', '>=', '<', '<='],
        time: ['=', '!=', '>', '>=', '<', '<='],
        date: ['=', '!=', '>', '>=', '<', '<='],
        daterange: ['>', '>=', '<', '<='],
        category: ['equals', 'not equals', 'in', 'not in'],
        boolean: ['=']
    };

    @ViewChild('qbuilder') qbuilder: QueryBuilderComponent;
    @ViewChild('op') panel: OverlayPanel;

    config: QueryBuilderConfig = {
        "allowEmptyRulesets": false,
        "fields": {}
    }

    queryString: string = '';
    errorMsg: string = '';
    yearRange: string = '2017:';

    constructor(private datePipe: DatePipe, public appUtil:AppUtil) {
        const d = new Date();
        this.yearRange = this.yearRange + d.getFullYear();
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes.fields) {
            this.config.fields = this.fields;
        }
        if (changes.query) {
            if (this.query == '' || !this.query.hasOwnProperty('condition') || !this.query.hasOwnProperty('rules')) {
                this.query = { condition: 'and', rules: [] };
            }
            else {
                this.query = changes.query.currentValue;
            }

        }
    }

    prepareQueryString(event) {
        this.query = event;
        this.queryString = this.appUtil.convertToQueryString(this.query, this.fields)
    }

    validate(rules) {
        if (rules.length == 0) {
            this.errorMsg = 'A ruleset cannot be empty. Please add a rule or remove it all together.';
            return;
        }

        for (var i = 0; i < rules.length; i++) {
            if (Array.isArray(rules[i].rules)) {
                this.validate(rules[i].rules);
                if (this.errorMsg != '')
                    break;
            }
            else if ((typeof rules[i].value === 'undefined') || rules[i].value == null
                || ((typeof rules[i].value === 'string') && rules[i].value.trim() == '')) {
                this.errorMsg = 'One of the rules does not have a criteria defined. Please defined  a criteria or remove the rule all together.';
                break;
            }

        }

    }

    search() {
        this.errorMsg = '';
        if (this.query.rules.length == 0) {
            this.errorMsg = 'Please define at least one rule';
        }
        else {
            this.validate(this.query.rules);
        }

        if (this.errorMsg == '') {
            const queryClone = JSON.parse(JSON.stringify(this.query));
            //this.addSearchTypeToRule(queryClone);
            this.onSearch.emit({ 'query': queryClone, 'queryString': this.queryString });
        }
    }
}

