import { AccountService } from './../_services/account.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from '../app.breadcrumb.service';
import { UsersService } from "../users/users.service";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DataService } from "../_services/data.service";
import { OrganizationService } from '../organization/organization.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AppUtil } from './../_helpers';
import { Location } from '@angular/common';

@Component({
    selector: 'app-user-add',
    templateUrl: './user.add.component.html',
    styleUrls: ['./user.add.component.scss'],
    providers: [MessageService, ConfirmationService]
})
export class UserAddComponent implements OnInit {
    @ViewChild('cform') formElement;
    form: UntypedFormGroup;
    organizationForm: UntypedFormGroup;
    submitted = false;
    isEdit: boolean = false;
    loadingSpinner: boolean = false;
    organizationName: any[];
    selectedorganizationName: any[];
    submitted1 = false;
    diasbleAccountFields: boolean = false;
    emailPreferences: string[] = [];
    lookupList: any = { ACCOUNT_SOURCE: [], EMAIL_PREFERENCES: [], TAG: [], MAILING_LIST: [] };
    tbpEmployees = [];
    classificationList: any = {
        CATEGORY_LEVEL1: [], CATEGORY_LEVEL2: [], CATEGORY_LEVEL3: [],
        VERTICAL: [], ECOSYSTEM: []
    };
    countries = [];
    addresses = [];
    addressShow: boolean = false;
    states = [];
    modelState = '';
    masterProfile: boolean = false;
    helpList: any = {CONTACT:[]};
    stateDisabled:boolean = false;
    currentStatus:string = null;

    constructor(
        private _location: Location,
        private organizationService: OrganizationService,
        private userService: UsersService,
        private breadcrumbService: BreadcrumbService,
        private router: Router,
        private formBuilder: UntypedFormBuilder,
        private dataService: DataService,
        private route: ActivatedRoute,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        private accountService: AccountService,
        public appUtil: AppUtil) {
        if (this.router.url.indexOf("/edit/") != -1) {
            this.isEdit = true;
        }
        if (this.isEdit) {
            this.breadcrumbService.setItems([
                { label: 'Edit Contact' }
            ]);
        } else {
            this.breadcrumbService.setItems([
                { label: 'New Contact' }
            ]);
        }
    }

    ngOnInit(): void {
        this.loadform();
        this.classificationList = this.route.snapshot.data['classificationList'];
        this.lookupList = this.route.snapshot.data['lookupList'];
        this.countries = this.route.snapshot.data['countries'];
        this.tbpEmployees = this.route.snapshot.data['tbpEmployees'];
         this.helpList = this.route.snapshot.data['helpList']['CONTACT'];
        if (this.isEdit) {
            this.userService.getContact(this.route.snapshot.params.uuid).subscribe(data => {
               this.modelToForm(data as any);
            });
        }
        else {
            let country = this.countries.find(field => field.name === "United States");
            this.f.country.setValue(country);
            const org = this.dataService.getDataByKey('org-');
            if (org != undefined) {
                this.f.organization.setValue(org);
                this.dataService.setData('org-', undefined);
            }
        }
        this.checkState();
    }

    checkState()
    {
        if(this.f.country.value && this.f.country.value !=null && this.f.country.value !='' )
        {
            this.stateDisabled = false;
        }
        else
        {
            this.stateDisabled = true;
            this.f.state.setValue(null);
            this.modelState = null;
            this.states = [];
        }
    }

    loadform() {
        this.form = this.formBuilder.group({
            uuid: [],
            firstName: [null, [Validators.required, Validators.maxLength(40)]],
            lastName: [null, [Validators.required, Validators.maxLength(80)]],
            organization: [null, [Validators.required, Validators.maxLength(80)]],
            phone: [''],
            mobile: [''],
            email: [null, [Validators.required, Validators.pattern, Validators.pattern(this.appUtil.emailPattern)]],
            secondaryEmail: [null, [Validators.pattern, Validators.pattern(this.appUtil.emailPattern)]],
            leadSource: [],
            title: [],
            primaryContact: [],
            isAdmin: [],
            accreditedInvestor: [false],
            masterProfile: [false],
            sendMAOpportunityEmails: [true],
            sendSpotlightEmails: [true],
            linkedInProfile: [null,[Validators.pattern(this.appUtil.urlPattern)]],
            assistantName: [],
            assistantPhone: [''],
            assistantEmail: [null, [Validators.pattern, Validators.pattern(this.appUtil.emailPattern)]],
            description: [],
            accountOwner: [this.accountService.userValue.uuid],
            address: [],
            city: [],
            state: [],
            postalCode: [],
            country: [],
            level1: [],
            level2: [],
            level3: [],
            vertical: [],
            ecosystem: [],
            doNotSendUpdates: [],
            lookupValue: [],
            tags: [],
            mailingList: [],
            verified: [false],
            individual: [false],
        });
    }


    modelToForm(model) {
        let tempCnt = null;
        this.currentStatus = model.status;
        if (model.country != "" && model.country != null) {
            tempCnt = this.countries.find(field => field.name === model.country);
        }
        if (model.doNotSendUpdates == true) {
            this.f.doNotSendUpdates.disable();
        }
        this.form.setValue({
            uuid: model.uuid,
            country: tempCnt,
            firstName: model.firstName,
            lastName: model.lastName,
            organization: model.organization,
            phone: model.phone,
            mobile: model.mobile,
            email: model.email,
            secondaryEmail: model.secondaryEmail,
            leadSource: (model.leadSource == null || model.leadSource == '') ? null : this.lookupList['ACCOUNT_SOURCE'].find(field => field.value === model['leadSource']),
            title: model.title,
            primaryContact: model.primaryContact,
            isAdmin: model.isAdmin,
            accreditedInvestor: model.accreditedInvestor,
            masterProfile: model.masterProfile,
            sendMAOpportunityEmails: model.sendMAOpportunityEmails,
            sendSpotlightEmails: model.sendSpotlightEmails,
            linkedInProfile: model.linkedInProfile,
            assistantName: model.assistantName,
            assistantPhone: model.assistantPhone,
            assistantEmail: model.assistantEmail,
            description: model.description,
            lookupValue: this.getUUid(model.lookupValue),
            accountOwner: (model.accountOwner == null) ? null : model.accountOwner?.uuid,
            address: model.address,
            city: model.city,
            postalCode: model.postalCode,
            level1: (model.classification.level1 == null) ? null : model.classification.level1,
            level2: (model.classification.level2 == null) ? null : model.classification.level2,
            level3: (model.classification.level3 == null) ? null : model.classification.level3,
            vertical: (model.classification.vertical == null) ? null : model.classification.vertical,
            ecosystem: (model.classification.ecosystem == null) ? null : model.classification.ecosystem,
            doNotSendUpdates: model.doNotSendUpdates,
            tags: (model.tags == null) ? null : model.tags.map(a => a.uuid),
            state: null,// state value is set via call to getStates method
            mailingList: this.appUtil.getObjects(this.lookupList.MAILING_LIST, model.mailingList, 'value'),
            verified: model.verified,
            individual: model.organization?false:true
        });
        if(model.organization)
        {
            this.f.organization.enable();
        }
        else
        {
            this.f.organization.disable();
        }
        this.modelState = model.state;
        this.getStates();
    }

    protected getUUid(data) {
        const uuid = [];
        if (data) {
            for (var i = 0; i < data.length; i++) {
                uuid.push(data[i].uuid);
            }
        }
        this.emailPreferences = uuid;
        return uuid;
    }

    setupFields($events)
    {
        let orgValue:any = $events == null?this.f.organization.value:$events;
        if (orgValue !== undefined && (orgValue != null && this.f.individual)) {
            this.f.primaryContact.enable();
            this.f.accreditedInvestor.disable();
            this.f.isAdmin.enable();
            this.f.title.enable();
        }
        else {
            this.f.primaryContact.disable();
            this.f.accreditedInvestor.enable();
            this.f.isAdmin.disable();
            this.f.title.disable();
        }
    }

    selectAccount(value) {
        if (value != null && value != undefined && value.classification != undefined) {
            this.getAllAddress(value.uuid);
            this.addressShow = true;
            this.confirmationService.confirm({
                message: 'Would you like to use classification from selected account?',
                accept: () => {
                    if (value.classification.level1 != null)
                        this.f.level1.setValue(value.classification.level1);
                    if (value.classification.level2 != null)
                        this.f.level2.setValue(value.classification.level2);
                    if (value.classification.level3 != null)
                        this.f.level3.setValue(value.classification.level3);
                    if (value.classification.vertical != null)
                        this.f.vertical.setValue(value.classification.vertical);
                    if (value.classification.ecosystem != null)
                        this.f.ecosystem.setValue(value.classification.ecosystem);
                }
            });
        }
    }

    get f() { return this.form.controls; }

    save() {
        this.submitted = true;
        if (this.form.invalid) {
            this.handleError(null);
            return;
        }
        this.loadingSpinner = true;
        let { level1, level2, level3, vertical, ecosystem, isAdmin, ...others } = this.form.value;
        let dataToPost = { ...others, isAdmin: (this.f.organization.value == null ? false : this.f.isAdmin.value), classification: { level1, level2, level3, vertical, ecosystem } };

        if (this.f.accountOwner.value) {
            dataToPost.accountOwner = this.appUtil.getObject(this.tbpEmployees, this.f.accountOwner.value);
        }
        if (this.f.leadSource.value) {
            dataToPost.leadSource = this.f.leadSource.value.value;
        }
        if (this.f.mailingList.value) {
            dataToPost.mailingList = this.f.mailingList.value.map(tag => tag.value);
        }
        if (this.f.level1.value) {
            dataToPost.classification.level1 = this.f.level1.value;
        }
        if (this.f.level2.value) {
            dataToPost.classification.level2 = this.f.level2.value;
        }
        if (this.f.level3.value) {
            dataToPost.classification.level3 = this.f.level3.value;
        }
        if (this.f.vertical.value) {
            dataToPost.classification.vertical = this.f.vertical.value;
        }
        if (this.f.ecosystem.value) {
            dataToPost.classification.ecosystem = this.f.ecosystem.value;
        }
        if (this.f.doNotSendUpdates.value) {
            dataToPost.doNotSendUpdates = this.f.doNotSendUpdates.value;
        }
        if (this.f.country.value) {
            dataToPost.country = this.f.country.value.name;
        }
        if (this.f.state.value) {
            if (typeof (this.f.state.value) === 'string')
                dataToPost.state = this.f.state.value;
            else
                dataToPost.state = this.f.state.value.name;
        }
        if (this.f.lookupValue.value != null) {
            const temp = [];
            let lookval = this.f.lookupValue.value;
            for (var i = 0; i < lookval.length; i++) {
                var searchdata = this.lookupList['EMAIL_PREFERENCES'].find(obj => obj.uuid === lookval[i]);
                temp.push(searchdata);
            }
            dataToPost['lookupValue'] = temp;
        }
        if (this.f.tags.value) {
            dataToPost.tags = this.appUtil.getObjects(this.lookupList.TAG, this.f.tags.value);
        }
        let apiToCall: any;
        if (this.isEdit) {
            apiToCall = this.userService.updateContact(dataToPost);
        }
        else {
            apiToCall = this.userService.addContact(dataToPost);
        }
        apiToCall.subscribe(
            data => {
                let prof = data as any;
                let detail = 'Contact Created';
                if (this.isEdit) {
                    detail = 'Contact Updated';
                }
                this.dataService.setData('message', detail);
                this.dataService.setData('vcontact-' + prof.uuid, prof);
                this.appUtil.routeToViewURL('contact', prof.uuid);
                this.submitted = false;
            },
            error => {
                this.loadingSpinner = false;
                let errors = error.error;
                if (errors.code && errors.code == 'VALIDATION_ERROR') {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: errors.subErrors[0].message, life: 3000 });
                }
            }
        );

    }

    handleError(elemId) {
        this.messageService.add({ severity: 'error', summary: 'Form Errors', detail: "Form has errors. Please fix them first", life: 3000 });
        let invalidControl = this.formElement.nativeElement.querySelector('.ng-invalid');
        if (invalidControl) {
            invalidControl.focus();
        }
        else if (elemId != null) {
            invalidControl = this.formElement.nativeElement.querySelector('#' + elemId);
            if (invalidControl) {
                invalidControl.focus();
            }
        }
    }

    getStates() {
        this.checkState();
        if(this.countries != null)
        {
            if(this.f.country && this.f.country.value != '' && this.f.country.value != null)
            {
                let states = this.appUtil.getStates(this.countries, this.f.country.value.name);
                if(states == null)
                {
                    states = []
                }
                this.states = states;

                if (this.modelState != null && this.modelState != '') {
                    let tempState = this.states.find(field => field.name === this.modelState);
                    if (tempState != undefined)
                        this.f.state.setValue(tempState);
                    else
                        this.f.state.setValue(this.modelState);
                }
            }
        }
    }

    cancel() {
        this._location.back();
    }

    setAddress(address) {
        for (let i = 0; i < this.addresses.length; i++) {
            if (this.addresses[i].title == address.value) {
                if (this.addresses[i].title == "New") {
                    let country = this.countries.find(field => field.name === "United States");
                    this.f.country.setValue(country);
                    this.f.address.setValue(null);
                    this.f.city.setValue(null);
                    this.f.postalCode.setValue(null);
                } else {
                    if (this.addresses[i].country != "" && this.addresses[i].country != null) {
                        let tempCnt = this.countries.find(field => field.name === this.addresses[i].country);
                        this.f.country.setValue(tempCnt);
                    }
                    this.f.address.setValue(this.addresses[i].address);
                    this.f.city.setValue(this.addresses[i].city);
                    this.f.state.setValue(this.addresses[i].state);
                    this.f.postalCode.setValue(this.addresses[i].postalCode);
                }
            }
        }

    }

    getAllAddress(uuid) {
        this.organizationService.getAllAddress(uuid).subscribe(data => {
            this.addresses = data as any;
            this.addresses.push({ "title": "Use New Address" });
        });
    }

    onChangeCheckbox(event, val) {
        var index = this.emailPreferences.indexOf(val);
        if (event.checked && index === -1)
            this.emailPreferences.push(val);
        else
            this.emailPreferences.splice(index, 1);
        this.f.lookupValue.setValue(this.emailPreferences);
    }

    public onFormGroupChangeEvent(_event) {
        this.f.organization.setValue(_event);
    }
    confirm(event: Event) {
        if (this.isEdit == true && this.masterProfile == false)
            this.confirmationService.confirm({
                target: event.target,
                message: 'Are you sure that you want to proceed?',
                icon: 'pi pi-exclamation-triangle',
                reject: () => {
                    this.f.masterProfile.setValue(false);
                }
            });
    }

    getHelpText(key) {
        let test = this.helpList.filter(function (element) {
            return element.key == key;
        });
        if (test[0] != undefined)
            return test[0]['text'];
    }

    checkStatus($event)
    {
        if(this.f.verified.value && (this.currentStatus == 'PENDING_ACTIVATION'))
        {
            this.confirmationService.confirm({
                message: 'User is yet to activate his/her profile after signup. Marking such user to verified will also change his/her status to active.',
                reject: () => {
                    this.f.verified.setValue(false);
                }
            });
        }
    }
    checkIndividual($event){
        if(this.f.individual.value){
            this.f.organization.setValue("null");
            this.f.organization.disable();
        }
        else{
            this.f.organization.enable();
            this.f.organization.setValue("");
       }

    }

}
