import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SellSide } from "../_models";
import { BreadcrumbService } from '../app.breadcrumb.service';
import { MessageService } from 'primeng/api';
import { SellsideService } from './sellside.service';
import { AccountService } from 'src/app/_services';
import { DataService } from '../_services/data.service';
import { LazyLoadEvent, MenuItem } from 'primeng/api';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmailCampaign } from '../_models/emailcampaign';
import { AppUtil } from '../_helpers';

@Component({
    selector: 'app-view.sellside',
    templateUrl: './view.sellside.component.html',
    styleUrls: ['./view.sellside.component.scss'],
    providers: [MessageService]
})
export class ViewSellsideComponent implements OnInit {

    @ViewChild('tform') formElement;
    uuid: string;
    sellSide: SellSide;
    lebelName: string;
    // Campaign  List
    campaigns: EmailCampaign[];
    selectedCampaign: EmailCampaign[] = [];
    campaignLoading: boolean = true;
    campaignsTotalRecords: number = 0;
    term: string;
    activeIndex: number = 0;
    blindProfileVew:string = 'view';

    constructor(
        private sellsideService: SellsideService,
        private messageService: MessageService,
        private router: Router,
        public accountService: AccountService,
        private route: ActivatedRoute,
        private dataService: DataService,
        private breadcrumbService: BreadcrumbService,
        private formBuilder: UntypedFormBuilder,
        private location: Location,
        private appUtil: AppUtil
    ) {
        this.breadcrumbService.setItems([
            { label: ' View SellSide' }
        ]);
        this.term = '';
    }

    ngOnInit(): void {
        this.route.url.subscribe((url) => {
            this.uuid = this.route.snapshot.params.uuid;
            if(url.length == 2)
            {
                this.activeIndex = 0;
            }
            else if(url[2].path == 'blind-profile')
            {
                this.activeIndex = 1;
                if(url.length > 3 && (url[3].path == 'new' || url[3].path == 'edit'))
                {
                    this.blindProfileVew = url[3].path;
                }
                else {
                    this.blindProfileVew = 'view';
                }
            }
            else
            {
                this.activeIndex = 2;
            }
            this.lebelName = "Create Blind Profile";
            this.sellSide = new SellSide;
            this.sellsideService.getSellside(this.route.snapshot.params.uuid).subscribe(data => {
                this.sellSide = data as any;
            });
    
            let message = this.dataService.getDataByKey('message');
            if (message != undefined) {
                setTimeout(() => { this.messageService.add({ severity: 'success', summary: 'Successful', detail: message, life: 3000 }); })
                this.dataService.setData('message', undefined);
            }
        })

    }

    get role() {
        return this.accountService.userValue.role;
    }

    edit() {
        this.router.navigate(['/sell-side/edit/' + this.sellSide.uuid]);
    }

    loadCampaigns(e: LazyLoadEvent = { first: 0, rows: 5, sortField: 'title', sortOrder: 1 }) {
        var searchParams = {};
        searchParams['page'] = (e.first / e.rows);
        searchParams['pageSize'] = e.rows;
        var sortBy = []
        var sortDir = e.sortOrder == 1 ? 'DESC' : 'ASC';
        if (e.sortField && e.sortField != '') {
            sortBy[sortBy.length] = { "property": e.sortField, "direction": sortDir };
        }
        searchParams['sortDetails'] = sortBy;

        if (this.term != '') {
            searchParams['query'] = this.term;
        }
        this.sellsideService.getCampaign(this.uuid, searchParams).subscribe(
            data => {
                let response = data as any;
                this.campaignsTotalRecords = response.totalRecords;
                this.campaigns = response.records;
                this.campaignLoading = false;
            });
    }

    handleError(elemId) {
        this.messageService.add({ severity: 'error', summary: 'Form Errors', detail: "Form has errors. Please fix them first", life: 3000 });
        let invalidControl = this.formElement.nativeElement.querySelector('.ng-invalid');
        if (invalidControl) {
            invalidControl.focus();
        }
        else if (elemId != null) {
            invalidControl = this.formElement.nativeElement.querySelector('#' + elemId);
            if (invalidControl) {
                invalidControl.focus();
            }
        }
    }

    blindProfile() {
        if (this.sellSide.hasBlindProfile) {
            this.location.go('sell-side/' + this.uuid +'/blind-profile/edit');
            this.blindProfileVew = 'edit';
        }
        else {
            this.location.go('sell-side/' + this.uuid +'/blind-profile/new');
            this.blindProfileVew = 'new';
        }
    }

    getDebt(current) {
        let temp = [];
        if (current != null) {
            for (let i = 0; i < current.length; i++) {

                temp.push(current[i].name);
            }
        }
        return temp;
    }
    
    changeTab($event)
    {
        if($event.index == 1)
        {
            this.location.go('sell-side/' + this.uuid +'/blind-profile');
        }
        else if($event.index == 2)
        {
            this.location.go('sell-side/' + this.uuid +'/prospects');
        }
        else
        {
            this.location.go('sell-side/' + this.uuid);
        }
    }


}
