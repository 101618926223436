<div class="card">
    <form [formGroup]="form" #cform>
        <h5 style="margin-bottom: 22px;">Engagement Blind Profile</h5>
        <div class="p-fluid  grid">
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <input id="title" [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.title.errors }"
                        formControlName="title" type="text" maxlength="150" pInputText>
                    <label for="title">Title<span class="mandate">*</span></label>
                </span>
                <span class="p-error" *ngIf="form.get('title').hasError('required') && submitted">Title is
                    Required</span>
                <span class="p-error"
                    *ngIf="submitted && f.title.invalid && f.title.hasError('message')">{{f.title.errors.message}}</span>
            </div>
            <div class="field col-12 md:col-8">
                <span class="p-float-label">
                    <textarea pInputTextarea formControlName="companyDescription" [rows]="1"></textarea>
                    <label for="title">Company Description<span class="mandate">*</span></label>
                </span>
                <span class="p-error" *ngIf="form.get('companyDescription').hasError('required') && submitted">Company
                    Description is Required</span>
                <span class="p-error"
                    *ngIf="submitted && f.companyDescription.invalid && f.companyDescription.hasError('message')">{{f.companyDescription.errors.message}}</span>
            </div>
            <div class="field col-12 md:col-6">
                <label for="serviceOfferings">Service Offerings<span class="mandate">*</span></label>
                <editor #serviceOfferings formControlName="serviceOfferings" [init]="appUtil.getInitData"></editor>
                <span class="p-error" *ngIf="form.get('serviceOfferings').hasError('required') && submitted">Service
                    Offerings is Required</span>
                <span class="p-error"
                    *ngIf="submitted && f.serviceOfferings.invalid && f.serviceOfferings.hasError('message')">{{f.serviceOfferings.errors.message}}</span>
            </div>
            <div class="field col-12 md:col-6">
                <label for="customers">Customers <span class="mandate">*</span></label>
                <editor #customers formControlName="customers" [init]="appUtil.getInitData"></editor>
                <span class="p-error" *ngIf="form.get('customers').hasError('required') && submitted">Customers is
                    Required</span>
                <span class="p-error"
                    *ngIf="submitted && f.customers.invalid && f.customers.hasError('message')">{{f.customers.errors.message}}</span>
            </div>
        </div>
        <h5 style="margin-bottom: 22px;">Business</h5>
        <div class="p-fluid  grid">
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <input id="headquarter" maxlength="200"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.headquarter.errors }"
                        formControlName="headquarter" pInputText>
                    <label for="headquarter">HQ<span class="mandate">*</span></label>
                </span>
                <span class="p-error" *ngIf="form.get('headquarter').hasError('required') && submitted">HQ is
                    Required</span>
                <span class="p-error"
                    *ngIf="submitted && f.headquarter.invalid && f.headquarter.hasError('message')">{{f.headquarter.errors.message}}</span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <input id="offices" maxlength="200"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.offices.errors }" formControlName="offices"
                        pInputText>
                    <label for="offices">Offices<span class="mandate">*</span></label>
                </span>
                <span class="p-error" *ngIf="form.get('offices').hasError('required') && submitted">Offices is
                    Required</span>
                <span class="p-error"
                    *ngIf="submitted && f.offices.invalid && f.offices.hasError('message')">{{f.offices.errors.message}}</span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <input id="employees" maxlength="200"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.employees.errors }"
                        formControlName="employees" pInputText>
                    <label for="employees">Employees<span class="mandate">*</span></label>
                </span>
                <span class="p-error" *ngIf="form.get('employees').hasError('required') && submitted">Employees is
                    Required</span>
                <span class="p-error"
                    *ngIf="submitted && f.employees.invalid && f.employees.hasError('message')">{{f.employees.errors.message}}</span>
            </div>
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <textarea pInputTextarea formControlName="financials" [rows]="10"></textarea>
                    <label for="financials">Financials<span class="mandate">*</span></label>
                </span>
                <span class="p-error" *ngIf="form.get('financials').hasError('required') && submitted">Financials is
                    Required</span>
                <span class="p-error"
                    *ngIf="submitted && f.financials.invalid && f.financials.hasError('message')">{{f.financials.errors.message}}</span>
            </div>
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <textarea pInputTextarea formControlName="investmentConsiderations" [rows]="10"></textarea>
                    <label for="investmentConsiderations">Investment Considerations<span
                            class="mandate">*</span></label>
                </span>
                <span class="p-error"
                    *ngIf="form.get('investmentConsiderations').hasError('required') && submitted">Investment
                    Considerations is Required</span>
                <span class="p-error"
                    *ngIf="submitted && f.investmentConsiderations.invalid && f.investmentConsiderations.hasError('message')">{{f.investmentConsiderations.errors.message}}</span>
            </div>
        </div>
        <div class="formgrid grid">
            <div class="field col-12 md:col-12 text-right" *ngIf="loadingSpinner == false">
                <p-button label="Save" icon="pi pi-check" iconPos="left" (click)="save()" styleClass="mr-2">
                </p-button>
                <p-button label="Cancel" icon="pi pi-times" iconPos="left" (click)="cancel()"
                    styleClass="p-button-secondary"></p-button>
            </div>
            <div class="field col-12 md:col-12 text-right" *ngIf="loadingSpinner">
                <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
                    fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
            </div>
        </div>
    </form>
</div>
<p-toast></p-toast>