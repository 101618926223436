<p-dialog [(visible)]="show" (onHide)="hide()" [style]="{width: '30vw'}" [header]="title" [modal]="true"
    (onHide)="hide()">
    <ng-template pTemplate="content">
        <form [formGroup]="form" #contact>
            <div class="p-fluid grid">
                <div class="field col-12" style="margin-top: 20px;">
                    <span class="p-float-label">
                        <tbp-autocomplete [forceSelection]="true" [items]="" [formControl]="f.contact" [multiple]="true"
                            [type]="'Contact'" field="displayName" [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.contact.errors }" >
                        </tbp-autocomplete>
                        <label for="contact">Contact<span class="mandate">*</span></label>

                    </span>
                    <span class="p-error"
                        *ngIf="submitted && f.contact.invalid && f.contact.hasError('required')">Contact is
                        required.</span>
                    <span class="p-error"
                        *ngIf="submitted && f.contact.invalid && f.contact.hasError('message')">{{f.contact.errors.message}}</span>
                </div>
            </div>
        </form>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hide()"></button>
        <button pButton pRipple label="Save" form="clform" icon="pi pi-check" class="p-button-text"
            (click)="saveContact()"></button>
    </ng-template>
</p-dialog>
<p-toast></p-toast>