import { UserPopupService } from './user-popup.service';
import { Router } from '@angular/router';
import { LazyLoadEvent, MenuItem } from 'primeng/api';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
    selector: 'user-popup-list',
    templateUrl: './user-popup.list.component.html',
    styleUrls: ['./user-popup.list.component.scss']
})
export class UserPopupListComponent implements OnInit {

    @Input() parent: any = null;
    @Input() canChangeOrganization:boolean = true;

    menuItems: MenuItem[];
    users: Task[];
    selectedUsers: Task[] = [];
    loading: boolean = true;
    totalRecords: number = 0;
    showForm: boolean = false;
    userLoading: boolean = true;
    constructor(private router: Router, private userPopupService: UserPopupService) {

    }

    ngOnInit(): void {
    }


    ngOnChanges(changes: SimpleChanges) {

        if (changes.hasOwnProperty("parent")) {
            this.load();
        }
    }

    load(e: LazyLoadEvent = { first: 0, rows: 5, sortField: 'name', sortOrder: 1 }) {
        var searchParams = {};
        searchParams['page'] = (e.first / e.rows);
        searchParams['pageSize'] = e.rows;
        var sortBy = []
        sortBy[sortBy.length] = { "property": "lastModifiedDate", "direction": "Desc" };
        searchParams['sortDetails'] = sortBy;
        if (!this.userLoading)
            this.userLoading = true;
        searchParams['rootRule'] = { 'condition': 'and', rules: [] }
        searchParams['rootRule']['rules'].push({ 'searchType': 'exact', 'field': 'organization', 'operator': 'equals', 'value': this.parent.uuid });
        this.userPopupService.getContacts(this.parent.uuid, searchParams).subscribe(
            data => {
                let response = data as any;
                this.totalRecords = response.totalRecords;
                this.users = response.records;
                this.userLoading = false;
            });
    }

    new() {
        this.showForm = true;
    }

}
