import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class SpotlightService {

    constructor(private http: HttpClient) { }

    getCompanies(companyName: string) {
        return this.http.get<any>(environment.apiUrl + '/companies?companyName=' + companyName);
    }

    searchSpotlight(searchParams: any, orgUuid: string) {
        if (orgUuid != undefined)
            return this.http.post(environment.apiUrl + '/organization/' + orgUuid + '/spotlights', searchParams);
        else
            return this.http.post(environment.apiUrl + '/spotlights', searchParams);
    }

    addSpotlight(data) {
        return this.http.post(environment.apiUrl + '/spotlight', data);
    }

    updateSpotlight(data) {
        return this.http.put(environment.apiUrl + '/spotlight/' + data.uuid, data);
    }

    getSpotlight(uuid: string) {
        return this.http.get(environment.apiUrl + '/spotlight/' + uuid);
    }

    lookupSpotlight(query) {
        return this.http.get(environment.apiUrl + '/spotlights/lookup', { params: { query } });
    }

    getSpotlightDeal(uuid: string) {
        return this.http.get(environment.apiUrl + '/spotlight/' + uuid + '/deals');
    }

    deleteSpotlights(spotlights:any){
        return this.http.delete(environment.apiUrl + '/spotlights', { params: { spotlights } });
    }

}
