import { TbpFieldValueModule } from './../tbp-field-value/tbp-field-value.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TbpTableColumnComponent } from './tbp-table-column.component';

@NgModule({
  imports: [
    CommonModule,
    TbpFieldValueModule
  ],
  declarations: [TbpTableColumnComponent],
  exports: [TbpTableColumnComponent]
})
export class TbpTableColumnModule { }
