import { TbpQueryBuilderComponent } from './tbp-query-builder.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ToolbarModule } from 'primeng/toolbar';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CheckboxModule } from 'primeng/checkbox';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { RadioButtonModule } from 'primeng/radiobutton';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import { MenuModule } from 'primeng/menu';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CalendarModule } from 'primeng/calendar';
import { QueryBuilderModule } from 'angular2-query-builder';


@NgModule({
    declarations: [
        TbpQueryBuilderComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TableModule,
        ButtonModule,
        OverlayPanelModule,
        ToolbarModule,
        FileUploadModule,
        InputTextModule,
        DropdownModule,
        InputTextareaModule,
        CheckboxModule,
        AutoCompleteModule,
        RadioButtonModule,
        MultiSelectModule,
        PanelModule,
        MenuModule,
        SplitButtonModule,
        MessageModule,
        MessagesModule,
        ToastModule,
        FormsModule,
        DialogModule,
        ConfirmDialogModule,
        ProgressSpinnerModule,
        CalendarModule,
        QueryBuilderModule
    ],
    exports: [
        TbpQueryBuilderComponent,
    ]
})
export class TbpQueryBuilderModule { }
