import { ClassificationResolver } from './../classification/classification.resolver';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SpotlightAddComponent } from "./spotlight.add.component";
import { SpotlightListComponent } from "./spotlight.list.component";
import { SpotlightViewComponent } from './spotlight.view.component';
import { ConfigurationResolver } from '../configuration/configuration.resolver';
import { UserResolver } from '../users/user.resolver';
import { SpotlightDeal } from './spotlightdeal.resolver';
import { HelpResolver } from '../help/help.resolver';

const routes: Routes = [
    {
        path: 'spotlight', component: SpotlightListComponent,
        resolve: {
            classificationList: ClassificationResolver
        }
    },
    {
        path: 'organization/:uuid/spotlight', component: SpotlightListComponent,
        resolve: {
            classificationList: ClassificationResolver
        }
    },
    {
        path: 'spotlight/new', component: SpotlightAddComponent,
        resolve: {
            classificationList: ClassificationResolver,
            lookupList: ConfigurationResolver,
            tbpEmployees: UserResolver,
            helpList: HelpResolver,
        }
    },
    {
        path: 'spotlight/:uuid', component: SpotlightViewComponent,
        resolve: {
            spotlightDeal: SpotlightDeal,
        }
    },
    {
        path: 'spotlight/edit/:uuid', component: SpotlightAddComponent,
        resolve: {
            classificationList: ClassificationResolver,
            lookupList: ConfigurationResolver,
            tbpEmployees: UserResolver,
            spotlightDeal: SpotlightDeal,
            helpList: HelpResolver,
        }
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [ClassificationResolver, ConfigurationResolver, UserResolver, SpotlightDeal, HelpResolver]
})
export class SpotlightRoutingModule { }
