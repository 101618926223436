<div class="card">
    <form [formGroup]="form" #cform>
        <div class="formgrid grid headerSticky">
            <div class="field col-12 md:col-12 text-right" *ngIf="loadingSpinner == false">
                <p-button label="Save" icon="pi pi-check" iconPos="left" (click)="save()" styleClass="mr-2">
                </p-button>
                <p-button label="Cancel" icon="pi pi-times" iconPos="left" (click)="cancel()"
                    styleClass="p-button-secondary"></p-button>
            </div>
            <div class="field col-12 md:col-12 text-right" *ngIf="loadingSpinner">
                <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
                    fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
            </div>
        </div>
        <h5 class="custom-class">User Information</h5>
        <div class="p-fluid  grid">
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <input id="firstName" [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.firstName.errors }"
                        class="capitalize" (blur)="f.firstName.setValue(appUtil.toTitleCase(f.firstName.value))"
                        formControlName="firstName" type="text" maxlength="40" pInputText pTooltip="{{getHelpText('First Name')}}" tooltipPosition="top">
                    <label for="firstName">First Name <span class="mandate">*</span></label>
                </span>
                <span class="p-error" *ngIf="form.get('firstName').hasError('required') && submitted">First Name is
                    Required</span>
                <span class="p-error"
                    *ngIf="submitted && f.firstName.invalid && f.firstName.hasError('message')">{{f.firstName.errors.message}}</span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <input id="lastName" [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.lastName.errors }"
                        class="capitalize" (blur)="f.lastName.setValue(appUtil.toTitleCase(f.lastName.value))"
                        formControlName="lastName" type="text" maxlength="74" pInputText pTooltip="{{getHelpText('Last Name')}}" tooltipPosition="top">
                    <label for="lastName">Last Name <span class="mandate">*</span></label>
                </span>
                <span class="p-error" *ngIf="form.get('lastName').hasError('required') && submitted">Last Name is
                    Required</span>
                <span class="p-error"
                    *ngIf="submitted && f.lastName.invalid && f.lastName.hasError('message')">{{f.lastName.errors.message}}</span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <input id="email" [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.email.errors }"
                        class="lowercase" (blur)="f.email.setValue(appUtil.toLowerCase(f.email.value))"
                        formControlName="email" type="text" maxlength="175" pInputText pTooltip="{{getHelpText('Email')}}" tooltipPosition="top">
                    <label for="email">Email <span class="mandate">*</span></label>
                </span>
                <span class="p-error" *ngIf="form.get('email').hasError('required') && submitted">Email is
                    Required</span>
                <span class="p-error" *ngIf="form.get('email').hasError('pattern') && submitted">Email address is
                    invalid</span>
                <span class="p-error"
                    *ngIf="submitted && f.email.invalid && f.email.hasError('message')">{{f.email.errors.message}}</span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <input id="phone" type="text" tooltipPosition="top"
                        pTooltip="{{getHelpText('Phone')}}" maxlength="40"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.phone.errors }"
                        formControlName="phone" pInputText (blur)="appUtil.formatPhone(f.phone)">
                    <!-- <input id="phone" formControlName="phone"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.phone.errors }" type="text" maxlength="40"
                        pInputText pTooltip="{{getHelpText('Phone')}}" tooltipPosition="top"> -->
                    <label for="phone">Phone</label>
                </span>
                <span class="p-error" *ngIf="form.get('phone').hasError('required') && submitted">Phone is
                    Required</span>
                <span class="p-error"
                    *ngIf="submitted && f.phone.invalid && f.phone.hasError('message')">{{f.phone.errors.message}}</span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <input id="mobile" type="text" tooltipPosition="top"
                        pTooltip="{{getHelpText('Mobile')}}" maxlength="40"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.mobile.errors }" 
                        formControlName="mobile" pInputText (blur)="appUtil.formatPhone(f.mobile)">
                    <!-- <input id="mobile" formControlName="mobile"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.mobile.errors }" type="text" maxlength="40"
                        pInputText pTooltip="{{getHelpText('Mobile No')}}" tooltipPosition="top"> -->
                    <label for="mobile">Mobile No</label>
                </span>
                <span class="p-error" *ngIf="form.get('mobile').hasError('required') && submitted">Mobile is
                    Required</span>
                <span class="p-error"
                    *ngIf="submitted && f.mobile.invalid && f.mobile.hasError('message')">{{f.mobile.errors.message}}</span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <input id="title" formControlName="title"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.title.errors }" type="text" maxlength="130"
                        pInputText pTooltip="{{getHelpText('Title')}}" tooltipPosition="top">
                    <label for="title">Title </label>
                    <span class="p-error"
                        *ngIf="submitted && f.title.invalid && f.title.hasError('message')">{{f.title.errors.message}}</span>
                </span>
            </div>
            <div class="field col-12 md:col-4" *ngIf="isEdit == false">
                <span class="p-float-label">
                    <input pPassword type="password" formControlName="password" maxlength="20"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.title.errors }"  pTooltip="{{getHelpText('Password')}}" tooltipPosition="top"/>
                    <label for="password">Password<span class="mandate">*</span> </label>
                    <span class="p-error"
                        *ngIf="submitted && f.password.invalid && f.password.hasError('message')">{{f.password.errors.message}}</span>
                </span>
            </div>
            <div class="field col-12 md:col-4" *ngIf="isEdit == false">
                <span class="p-float-label">
                    <input pPassword type="password" formControlName="confirmPassword" maxlength="20"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.confirmPassword.errors }" pTooltip="{{getHelpText('Confirm Password')}}" tooltipPosition="top" />
                    <label for="confirmPassword">Confirm Password </label>
                    <span class="p-error"
                        *ngIf="submitted && f.confirmPassword.invalid && f.confirmPassword.hasError('message')">{{f.confirmPassword.errors.message}}</span>
                    <span class="p-error"
                        *ngIf="(f.password.value != '') && f.confirmPassword.hasError('confirmedValidator')">Passwords
                        do not match</span>
                </span>
            </div>

        </div>
    </form>
</div>
<p-toast></p-toast>