<p-panel [toggleable]="true" [collapsed]="false" expandIcon="pi pi-chevron-left" collapseIcon="pi pi-chevron-down">
    <p-header>
        <span class="p-panel-title text-teal">
            <i class="pi pi-bars"></i> Contact
        </span>
    </p-header>
    <ng-template pTemplate="icons">
        <button pButton class="p-panel-header-icon p-link" (click)="new()" label="" icon="pi pi-plus"></button>
    </ng-template>
    <p-table [autoLayout]="true" [value]="users" [(selection)]="selectedContacts" dataKey="userId" [lazy]="true"
        (onLazyLoad)="load($event)"
        styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm" [rowHover]="true"
        [rows]="10" [paginator]="true" [loading]="loading" [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [totalRecords]="totalRecords"
        [alwaysShowPaginator]="false">
        <ng-template pTemplate="header">
            <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Mobile</th>
                <th>Country</th>
                <th>Action</th>

            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-user>
            <tr>
                <td class="wrap"><a [routerLink]="['/contact/', user.uuid]">{{user.name}}</a></td>
                <td class="wrap"><a [routerLink]="['/contact/', user.uuid]">{{user.email}}</a></td>
                <td class="wrap">{{user.mobile}}</td>
                <td class="wrap">{{user.country}}</td>
                <td class="wrap"><button class="p-button-danger" pButton type="button" icon="pi pi-trash"></button></td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="5">No contacts have been created yet.</td>
            </tr>
        </ng-template>
    </p-table>
</p-panel>
<spotlight-contact-edit [(show)]="showForm" contactType="{{contactType}}" [parentUuid]="parentUuid"
    [(contactUuid)]="uuid"></spotlight-contact-edit>