<div class="grid table-demo">
    <div class="col-12">
        <p-toolbar styleClass="m-0">
            <ng-template pTemplate="left">
            </ng-template>

            <ng-template pTemplate="right">
                <button pButton pRipple label="Back" icon="pi pi-arrow-left" class="p-button-success mr-2" (click)="appUtil.return()"></button>
                <button pButton pRipple label="Edit" icon="pi pi-pencil" class="p-button-success mr-2"
                    (click)="edit()"></button>
            </ng-template>

        </p-toolbar>
        <p-tabView>
            <p-tabPanel header="Engagement Detail">
                <div class="grid mt-1">
                    <div class="col-12 md:col-6">
                        <div class="inline label">Created By:</div>
                         {{engagement.createByUser}} on {{engagement.createdDate | date:"MM-dd-yyyy HH:mm:ss"}}
                    </div>
                    <div class="col-12 md:col-6 text-right">
                        <div class="inline label">Modified By:</div>
                         {{engagement.modifiedByUser}} on {{engagement.lastModifiedDate | date:"MM-dd-yyyy HH:mm:ss"}}
                    </div>
                </div>
                <!-- <p-divider type="dashed"></p-divider> -->
                <div class="card divider-card">
                    <div class="p-fluid formgrid grid">
                        <div class="field col-12">
                            <div class="p-fluid formgrid grid">
                                <div class="field col-12 md:col-6">
                                    <div class="grid mt-1 limit-width-large dashed-bottom">
                                        <div class="col-12 md:col-4 label md:text-right">
                                            Engagement Name
                                        </div>
                                        <div class="field col-12 md:col-8">
                                            {{engagement.name}}
                                        </div>
                                    </div>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <div class="grid mt-1 limit-width-large dashed-bottom">
                                        <div class="col-12 md:col-4 label md:text-right">
                                            Client
                                        </div>
                                        <div class="field col-12 md:col-8" *ngIf="engagement.client !=null">
                                            <a
                                        [routerLink]="['/organization/', engagement['client']['uuid']]">
                                        {{engagement.client?engagement['client']['name']:''}}</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <div class="grid mt-1 limit-width-large dashed-bottom">
                                        <div class="col-12 md:col-4 label md:text-right">
                                            Engagement Type
                                        </div>
                                        <div class="field col-12 md:col-8">
                                            {{engagement.engagementType}}
                                        </div>
                                    </div>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <div class="grid mt-1 limit-width-large dashed-bottom">
                                        <div class="col-12 md:col-4 label md:text-right">
                                            Deal Team
                                        </div>
                                        <div class="field col-12 md:col-8">
                                            <span *ngFor="let user of engagement.dealTeam; let last = last;"><a
                                                [routerLink]="['/contact/', user.uuid]">{{user.name}}</a>{{last?'':';
                                            '}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <div class="grid mt-1 limit-width-large dashed-bottom">
                                        <div class="col-12 md:col-4 label md:text-right">
                                            Start Date
                                        </div>
                                        <div class="field col-12 md:col-8">
                                            {{engagement.startDate | date:'MM-dd-yyyy'}}
                                        </div>
                                    </div>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <div class="grid mt-1 limit-width-large dashed-bottom">
                                        <div class="col-12 md:col-4 label md:text-right">
                                            End Date
                                        </div>
                                        <div class="field col-12 md:col-8">
                                            {{engagement.endDate | date:'MM-dd-yyyy'}}
                                        </div>
                                    </div>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <div class="grid mt-1 limit-width-large dashed-bottom">
                                        <div class="col-12 md:col-4 label md:text-right">
                                            Status
                                        </div>
                                        <div class="field col-12 md:col-8">
                                             {{engagement.status}}
                                        </div>
                                    </div>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <div class="grid mt-1 limit-width-large dashed-bottom">
                                        <div class="col-12 md:col-4 label md:text-right">
                                            Progress
                                        </div>
                                        <div class="field col-12 md:col-8">
                                            {{engagement.progress}}
                                        </div>
                                    </div>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <div class="grid mt-1 limit-width-large dashed-bottom">
                                        <div class="col-12 md:col-4 label md:text-right">
                                            Actual Close Date
                                        </div>
                                        <div class="field col-12 md:col-8">
                                            {{engagement.actualCloseDate | date:'MM-dd-yyyy'}}
                                        </div>
                                    </div>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <div class="grid mt-1 limit-width-large dashed-bottom">
                                        <div class="col-12 md:col-4 label md:text-right">
                                            Next Milestone
                                        </div>
                                        <div class="field col-12 md:col-8" innerHtml="{{engagement.nextMilestone}}">
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 md:col-12 mt-4">
                            <note-list *ngIf="engagement.uuid != undefined" noteType="engagement" [parent]="engagement" [parentUuid]="engagement.uuid">
                            </note-list>
                        </div>

                        <div class="col-12 md:col-12 mt-4">
                            <p-panel [toggleable]="true" [collapsed]="false" expandIcon="pi pi-chevron-left"
                                collapseIcon="pi pi-chevron-down">
                                <p-header>
                                    <span class="p-panel-title text-teal">
                                        <i class="pi pi-bars"></i> Deal Team
                                    </span>
                                </p-header>
                                <ng-template pTemplate="icons">
                                    <button pButton class="p-panel-header-icon p-link" (click)="newContact()" label=""
                                        icon="pi pi-plus"></button>
                                </ng-template>
                                <p-table [autoLayout]="true" [value]="contacts" [(selection)]="selectedContacts"
                                    dataKey="userId" [lazy]="true" (onLazyLoad)="loadContacts($event)"
                                    styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm"
                                    [rowHover]="true" [rows]="10" [paginator]="true" [loading]="contactLoading"
                                    [showCurrentPageReport]="true"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                    [totalRecords]="contactTotalRecords" [alwaysShowPaginator]="false">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th>Name</th>
                                            <th>Phone</th>
                                            <th>Email</th>
                                            <th>Title</th>
                                            <th>Action</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-contact>
                                        <tr>
                                            <td class="wrap"><a
                                                    [routerLink]="['/contact/', contact.uuid]">{{contact.name}}</a></td>
                                            <td class="wrap">{{contact.phone}}</td>
                                            <td class="wrap">{{contact.email}}</td>
                                            <td class="wrap">{{contact.title}}</td>
                                            <td>
                                                <button pButton pRipple icon="pi pi-pencil"
                                                    class="p-button-success mr-2"></button>
                                                <!--<button pButton pRipple [label]="Delete" icon="pi pi-trash" class="p-button-danger  mr-2" ></button> -->
                                            </td>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="emptymessage">
                                        <tr>
                                            <td colspan="5">No contact found to be associated with engagement.</td>
                                        </tr>
                                    </ng-template>
                                </p-table>

                            </p-panel>
                        </div>

                        <div class="col-12 md:col-12 mt-4">

                            <p-panel [toggleable]="true" [collapsed]="false" expandIcon="pi pi-chevron-left"
                                collapseIcon="pi pi-chevron-down">
                                <p-header>
                                    <span class="p-panel-title text-teal">
                                        <i class="pi pi-bars"></i> Campaigns
                                    </span>
                                </p-header>
                                <p-table [autoLayout]="true" [value]="campaigns" [(selection)]="selectedCampaign"
                                    dataKey="userId" [lazy]="true" (onLazyLoad)="loadCampaigns($event)"
                                    styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm"
                                    [rowHover]="true" [rows]="5" [paginator]="true" [loading]="campaignLoading"
                                    [showCurrentPageReport]="true"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                    [totalRecords]="campaignsTotalRecords" sortField="title"
                                    [alwaysShowPaginator]="false">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th>Title</th>
                                            <th>Status</th>
                                            <th>Type</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-campaign>
                                        <tr>
                                            <td class="wrap"><a
                                                    [routerLink]="['/email-campaign/', campaign.uuid]">{{campaign.title}}</a>
                                            </td>
                                            <td class="wrap"> {{campaign.status}}</td>
                                            <td class="wrap">{{campaign.type}}</td>

                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="footer" *ngIf="campaignsTotalRecords != 0">
                                        <tr>
                                            <td colspan="5" class="text-right"><a href="./email-campaign/engagement/{{engagement.uuid}}/campaigns">View All</a></td>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="emptymessage">
                                        <tr>
                                            <td colspan="3">No Engagement found to be associated with Campaign.</td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </p-panel>
                        </div>

                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Blind Profile">
                <div class="card">
                    <button style="float: right;margin-top: -20px;" pButton pRipple class="p-button-success mr-2"
                        (click)="blindProfile()">{{lebelName}}</button>
                    <div class="grid">
                        <div class="col-12" *ngIf="engagement.blindProfile !=null">
                            <div class="grid">
                                <div class="col-2 font-bold">
                                    Title
                                </div>
                                <div class="col-4">
                                    {{engagement.blindProfile?.title}}
                                </div>
                                <div class="col-2 font-bold">
                                    Company Description
                                </div>
                                <div class="col-4">
                                    {{engagement.blindProfile?.companyDescription}}
                                </div>
                                <div class="col-2 font-bold">
                                    Service Offerings
                                </div>
                                <div class="col-4" innerHtml="{{engagement.blindProfile?.serviceOfferings}}">
                                </div>
                                <div class="col-2 font-bold">
                                    Customers
                                </div>
                                <div class="col-4" innerHtml="{{engagement.blindProfile?.customers}}">
                                </div>
                                <div class="col-2 font-bold">
                                    HQ
                                </div>
                                <div class="col-4">
                                    {{engagement.blindProfile?.headquarter}}
                                </div>
                                <div class="col-2 font-bold">
                                    Offices
                                </div>
                                <div class="col-4">
                                    {{engagement.blindProfile?.offices}}
                                </div>
                                <div class="col-2 font-bold">
                                    Employees
                                </div>
                                <div class="col-4">
                                    {{engagement.blindProfile?.employees}}
                                </div>
                                <div class="col-2 font-bold">
                                    Financials
                                </div>
                                <div class="col-4">
                                    {{engagement.blindProfile?.financials}}
                                </div>
                                <div class="col-2 font-bold">
                                    Investment Considerations
                                </div>
                                <div class="col-4">
                                    {{engagement.blindProfile?.investmentConsiderations}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Master Prospects">
                <div class="col-12 md:col-12">
                    <p-panel [toggleable]="true" [collapsed]="false" expandIcon="pi pi-chevron-left"
                        collapseIcon="pi pi-chevron-down">
                        <p-header>
                            <span class="p-panel-title text-teal">
                                <i class="pi pi-bars">&nbsp;</i>Master Prospects
                            </span>
                        </p-header>
                        <ng-template pTemplate="icons">
                            <button pButton class="p-panel-header-icon p-link" (click)="newMaster()" label=""
                                icon="pi pi-plus"></button>
                        </ng-template>
                        <p-table [autoLayout]="true" [value]="masters" [(selection)]="selectedMasters" dataKey="userId"
                            [lazy]="true" (onLazyLoad)="loadMasters($event)"
                            styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm"
                            [rowHover]="true" [rows]="10" [paginator]="true" [loading]="masterLoading"
                            [showCurrentPageReport]="true"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            [totalRecords]="masterTotalRecords" [alwaysShowPaginator]="false">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Phone</th>
                                    <th>Revenue</th>
                                    <th>Action</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-master>
                                <tr>
                                    <td class="wrap"><a
                                            [routerLink]="['/organization/', master.uuid]">{{master.name}}</a></td>
                                    <td class="wrap">{{master.companyType.replace('_',' ') | titlecase}}</td>
                                    <td class="wrap">{{master.phone}}</td>
                                    <td class="wrap">{{master.revenue}}</td>
                                    <td style="text-align: center;">
                                        <p-splitButton label="Action" [model]="masterActionItems"
                                            (onDropdownClick)="setCurrentItem(master)" class="p-button-sm">
                                        </p-splitButton>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="5">No Master Prospects found to be associated with Engagement.</td>
                                </tr>
                            </ng-template>
                        </p-table>

                    </p-panel>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Active Prospects">
                Workig on this service
            </p-tabPanel>
            <p-tabPanel header="Archive Prospects">
                Workig on this service
            </p-tabPanel>
        </p-tabView>

    </div>


</div>
<p-toast></p-toast>
<p-dialog [(visible)]="contactDialog" (onHide)="hideDialogContact()" [style]="{width: '30vw'}" [header]="contactTitle"
    [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
        <form [formGroup]="contactForm">
            <div class="field col-12" [style]="{margin: '6px'}">
                <span class="p-float-label">
                    <tbp-autocomplete [forceSelection]="true" [items]="" [formControl]="contactForm.controls.dealTeam" [multiple]="true"
                        [type]="'Contact'" field="displayName" [ngClass]="{ 'ng-invalid ng-dirty': contactForm.get('dealTeam').hasError('required') && cfSubmitted }">
                    </tbp-autocomplete>
                    <label for="dealTeam">Deal Team<span class="mandate">*</span></label>
                    <span class="p-error" *ngIf="contactForm.get('dealTeam').hasError('required') && cfSubmitted">Deal
                        Team is Required</span>
                    <span class="p-error"
                        *ngIf="cfSubmitted && cf.dealTeam.invalid && cf.dealTeam.hasError('message')">{{cf.dealTeam.errors.message}}</span>
                </span>
            </div>

        </form>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
            (click)="hideDialogContact()"></button>
        <button pButton pRipple label="Save" form="clform" icon="pi pi-check" class="p-button-text"
            (click)="saveContact()"></button>
    </ng-template>
</p-dialog>


<p-dialog [(visible)]="masterDialog" (onHide)="hideDialogMaster()" [style]="{width: '30vw'}" [header]="masterTitle"
    [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
        <form [formGroup]="masterForm">
            <div class="field col-12" [style]="{margin: '6px'}">
                <span class="p-float-label">
                    <p-autoComplete
                        [ngClass]="{ 'ng-invalid ng-dirty': masterForm.get('organization').hasError('required') && mfSubmitted }"
                        [unique]="true" [forceSelection]="true" [multiple]="true" [suggestions]="masterResults"
                        (completeMethod)="searchCompanies($event)" appendTo="body" formControlName="organization"
                        field="name"></p-autoComplete>
                    <label for="organization">Master Prospects<span class="mandate">*</span></label>
                    <span class="p-error"
                        *ngIf="masterForm.get('organization').hasError('required') && mfSubmitted">Master Prospects is
                        Required</span>
                    <span class="p-error"
                        *ngIf="mfSubmitted && mf.organization.invalid && mf.organization.hasError('message')">{{mf.organization.errors.message}}</span>
                </span>
            </div>

        </form>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
            (click)="hideDialogMaster()"></button>
        <button pButton pRipple label="Save" form="clform" icon="pi pi-check" class="p-button-text"
            (click)="saveMaster()"></button>
    </ng-template>
</p-dialog>