import { User } from './../_models/user';
import { AppUtil } from './../_helpers/app.util';
import { MessageService } from 'primeng/api';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { UsersService } from './users.service';

import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from './../app.breadcrumb.service';
import { Component, OnInit } from '@angular/core';
import { TitleCasePipe, DatePipe } from '@angular/common';

@Component({
    selector: 'app-merge-contact',
    templateUrl: './merge-contact.component.html',
    styleUrls: ['./merge-contact.component.scss']
})
export class MergeContactComponent implements OnInit {

    contact1: User;
    contact2: User;
    contact3: User;
    fields: object[];
    suggestionList: any[];
    contact2Uuid: string;
    contact3Uuid: string;
    mergeForm: UntypedFormGroup;

    constructor(
        private breadcrumbService: BreadcrumbService,
        private route: ActivatedRoute,
        private router: Router,
        private userService: UsersService,
        private messageService: MessageService,
        private formBuilder: UntypedFormBuilder,
        public appUtil: AppUtil) {
        this.breadcrumbService.setItems([
            { label: 'Merge Contacts' }
        ]);
        this.fields = [{ field: 'firstName', label: 'First Name' },
        { field: 'lastName', label: 'Last Name' }, { field: 'email', label: 'Email' },
        { field: 'phone', label: 'Phone' }, { field: 'mobile', label: 'Mobile' },
        { field: 'organization', label: 'Organization' }, { field: 'title', label: 'Title' },
        { field: 'primaryContact', label: 'Is primary Contact?' }, { field: 'secondaryEmail', label: 'Secondary Email' },
        { field: 'leadSource', label: 'Lead Source' }, { field: 'linkedInProfile', label: 'LinkedIn Profile URL' },
        { field: 'assistantName', label: 'Assistant Name' }, { field: 'assistantPhone', label: 'Assistant Phone' },
        { field: 'assistantEmail', label: 'Assistant Email' }, { field: 'accountOwner', label: 'Account Owner' },
        { field: 'sendMAOpportunityEmails', label: 'Send M&A Opportunities' }, { field: 'sendSpotlightEmails', label: 'Send Spotlights' },
        /*{field:'level1', label:'Level1'},
        {field:'level2', label:'Level2'},{field:'level3', label:'Level3'},
        {field:'vertical', label:'Vertical/Industry'},{field:'ecosystem', label:'Ecosystem'}*/
        { field: 'address', label: 'Street' },
        { field: 'city', label: 'City' }, { field: 'postalCode', label: 'Zip/Postal Code' },
        { field: 'country', label: 'Country' },
        { field: 'state', label: 'State/Province' }, { field: 'description', label: 'Description' },
        { field: 'createdBy', label: 'Created By' }, { field: 'modifiedBy', label: 'Last Modified By' },
        ];
    }

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            this.contact1 = this.route.snapshot.data['contact'];
            this.loadMergeForm();
            this.contact2 = null;
            this.contact3 = null;
            this.contact2Uuid = null;
            this.contact3Uuid = null;
        });
    }

    loadMergeForm() {
        this.mergeForm = this.formBuilder.group({
            masterId: [this.contact1.uuid, []],
            firstName: [this.contact1.uuid, []],
            lastName: [this.contact1.uuid, []],
            email: [this.contact1.uuid, []],
            phone: [this.contact1.uuid, []],
            mobile: [this.contact1.uuid, []],
            organization: [this.contact1.uuid, []],
            title: [this.contact1.uuid, []],
            primaryContact: [this.contact1.uuid, []],
            secondaryEmail: [this.contact1.uuid, []],
            leadSource: [this.contact1.uuid, []],
            linkedInProfile: [this.contact1.uuid, []],
            assistantName: [this.contact1.uuid, []],
            assistantPhone: [this.contact1.uuid, []],
            assistantEmail: [this.contact1.uuid, []],
            accountOwner: [this.contact1.uuid, []],
            sendMAOpportunityEmails: [this.contact1.uuid, []],
            sendSpotlightEmails: [this.contact1.uuid, []],
            /*
            level1: [this.contact1.uuid,[]],
            level2: [this.contact1.uuid,[]],
            level3: [this.contact1.uuid,[]],
            vertical: [this.contact1.uuid,[]],
            ecosystem: [this.contact1.uuid,[]],
            */
            address: [this.contact1.uuid, []],
            city: [this.contact1.uuid, []],
            postalCode: [this.contact1.uuid, []],
            country: [this.contact1.uuid, []],
            state: [this.contact1.uuid, []],

            description: [this.contact1.uuid, []],
            createdBy: [],
            modifiedBy: []
        });
    }

    getValue(org, fieldName) {
        let value: any;
        if (org) {
            switch (fieldName) {
                case 'accountOwner':
                    value = org[fieldName] ? (org[fieldName].name) : '';
                    break;
                case 'createdBy':
                    value = org.createdBy.split("::");
                    value = (value.length > 1) ? value[1] : '-';
                    value = value + ', ' + new DatePipe("en-US").transform(org.createdDate, "MM-dd-YYYY hh:mm a");
                    break;
                case 'modifiedBy':
                    value = org.lastModifiedBy.split("::");
                    value = (value.length > 1) ? value[1] : '-';
                    value = value + ', ' + new DatePipe("en-US").transform(org.lastModifiedDate, "MM-dd-YYYY hh:mm a");
                    break;
                case 'sendMAOpportunityEmails':
                case 'sendSpotlightEmails':
                    value = org[fieldName] ? 'Yes' : 'No';
                    break;
                case 'primaryContact':
                    value = org[fieldName] ? 'true' : 'false';
                    break;
                case 'organization':
                    value = org[fieldName] ? org[fieldName].name : '';
                    break;
                case 'level1':
                case 'level2':
                case 'level3':
                case 'vertical':
                case 'ecosystem':
                    value = this.appUtil.getConcatValue(org['classification'][fieldName], 'name');
                    break;
                default:
                    value = org[fieldName];
            }
        }
        return value;
    }


    get mf() {
        return this.mergeForm.controls;
    }

    resetSelections(olduuid) {
        if (olduuid && (this.mf.masterId.value == olduuid)) {
            this.mf.masterId.setValue(this.contact1.uuid);
        }
    }

    unselectSource($event, iscontact2) {
        if (iscontact2) {

            this.resetSelections(this.contact2Uuid);
            this.contact2 = null;
            this.contact2Uuid = null;
        }
        else {
            this.resetSelections(this.contact3Uuid);
            this.contact3 = null;
            this.contact3Uuid = null;
        }

    }

    getOrganizationData(data, iscontact2) {
        if (iscontact2) {
            this.contact2 = data;
            this.contact2Uuid = this.contact2.uuid;
        }
        else {
            this.contact3 = data;
            this.contact3Uuid = this.contact3.uuid;
        }
    }


    selectSource($event, isContact2) {

        this.userService.getContact($event.uuid).subscribe(
            data => this.getOrganizationData(data, isContact2)
        )

    }

    searchOrg($event, iscontact2) {
        this.userService.lookupContact($event.query).subscribe(data => {
            let suggestions = [];
            let results = data as any[];
            results.forEach(function (currentValue, index) {
                if (currentValue.uuid != this.comp.contact1.uuid) {
                    let add = true;
                    if (iscontact2) {
                        if (this.comp.contact3 && currentValue.uuid == this.comp.contact3.uuid) {
                            add = false;
                        }
                    }
                    else if (this.comp.contact2 && currentValue.uuid == this.comp.contact2.uuid) {
                        add = false;
                    }
                    if (add)
                        this.suggestions.push(currentValue);
                }
            }, { comp: this, suggestions });
            this.suggestionList = suggestions;
        });
    }

    merge() {
        this.mergeForm.markAllAsTouched();
        let dataToPost = { ...this.mergeForm.value };
        delete dataToPost.masterId;
        delete dataToPost.createdBy;
        delete dataToPost.modifedBy;

        if (this.contact2 || this.contact3) {
            let source1 = (this.contact2Uuid && this.contact2Uuid == this.mf.masterId.value) ? this.contact1.uuid : this.contact2Uuid;
            let source2 = (this.contact3Uuid && this.contact3Uuid == this.mf.masterId.value) ? this.contact1.uuid : this.contact3Uuid;
            this.userService.mergeContacts(this.mf.masterId.value, source1, source2, dataToPost)
                .subscribe(
                    data => {
                        this.messageService.add({ severity: 'success', summary: 'Merge Successful', detail: "Merge was successfull.", life: 3000 });
                        this.appUtil.routeToViewURL('contact', this.mf.masterId.value);
                    },
                    error => {
                        let errors = error.error;
                        if (errors.code && errors.code == 'VALIDATION_ERROR') {
                            for (var i = 0; i < errors.subErrors.length; i++) {
                                this.messageService.add({ severity: 'error', summary: 'Merge UnSuccessful', detail: errors.subErrors[i].message , life: 4000 });
                            }
                        } 
                        else {
                            this.messageService.add({ severity: 'error', summary: 'Merge UnSuccessful', detail: "Server encountered an unknown error while trying to perform merge operation.", life: 3000 });
                        }
                    },
                );
        }

    }


}
