import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment } from '@angular/router';
import { UserResolver } from '../users/user.resolver';
import { TaskToggleListComponent } from './task-toggle-list.component';

const routes: Routes = [
    {
    matcher: (url) => {
            if ( ((url.length >=3 && url[0].path == 'tasks')|| (url.length == 1 && url[0].path == 'tasks')||(url.length >= 1 && url[0].path == 'task')) && url.length <=4) {
                return {
                    consumed: url,
                    posParams: {
                        showListing: new UrlSegment('true', {})
                    }
                };
            }
            return null;
        },
        component: TaskToggleListComponent,
        resolve: {
            tbpEmployees: UserResolver, 
        }   
    }     
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: []
})
export class TaskRoutingModule { }
