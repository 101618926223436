import { OverlayPanel } from 'primeng/overlaypanel';
import { Component, Input, OnInit, NgZone, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { BreadcrumbService } from '../app.breadcrumb.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Table } from 'primeng/table';
import { SpotlightService } from "../spotlight/spotlight.service";
import { Spotlight } from "../_models/spotlight";
import { ConfirmationService, LazyLoadEvent, MenuItem } from 'primeng/api';
import { AccountService } from 'src/app/_services';
import { DataService } from '../_services/data.service';
import { MessageService } from 'primeng/api';
import { OrganizationService } from './../organization/organization.service';
import { AppUtil } from '../_helpers';
import { Subscription } from 'rxjs';
@Component({
    selector: 'app-spotlight-list',
    templateUrl: './spotlight.list.component.html',
    styleUrls: ['./spotlight.list.component.scss'],
    encapsulation: ViewEncapsulation.None,  
    providers: [MessageService, ConfirmationService],
})
export class SpotlightListComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription = new Subscription();
    pageSize: number;
    firstRow: number;
    sortField: string;
    scrollTop: string;
    sortOrder: number;
    spotlights: Spotlight[];
    selectedSpotlights: Spotlight[];
    @ViewChild('advsrch') advSrch: OverlayPanel;
    term: string;
    loading: boolean = false;
    currentMenuData: any;
    totalRecords: number = 0;
    spotlightItems: MenuItem[];
    conDialog: boolean = false;
    queryBuilderQuery: any = {};
    currentQuery: string = '';
    isAdvanceSearch: boolean = false;
    queryBuilderFields: any = {};
    orgUuid: string;
    classificationList: any = {
        CATEGORY_LEVEL1: [], CATEGORY_LEVEL2: [], CATEGORY_LEVEL3: [],
        VERTICAL: [], ECOSYSTEM: []
    };
    statuses = [{ code: 'ARCHIVED', name: 'Archived' }, { code: 'PUBLISHED', name: 'Published' }, { code: 'DRAFT', name: 'Draft' }];
    previewDialog: boolean = false;
    spotlight: any;

    constructor(private spotlightService: SpotlightService,
        private breadcrumbService: BreadcrumbService,
        private router: Router,
        public accountService: AccountService,
        private dataService: DataService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        private route: ActivatedRoute,
        public appUtil: AppUtil,
        private organizationService: OrganizationService,
        private ngZone: NgZone) {
        this.breadcrumbService.setItems([{ label: 'SpotLight Listing' }]);
        this.term = '';
        this.loading = true;
        this.currentMenuData = { id: '' };
        this.spotlightItems = [];
        this.pageSize = this.appUtil.pageSize;
        this.firstRow = 0;
        this.sortField = 'spotlightDate';
        this.sortOrder = -1;

    }

    get role() {
        return this.accountService.userValue.role;
    }

    ngOnInit(): void {
        const storedScrollTop = localStorage.getItem('spotlightScroll');
        if (storedScrollTop) {
            window.scrollTo(0, parseInt(storedScrollTop));
        }
        window.addEventListener('scroll', this.saveScrollPosition);
        this.classificationList = this.route.snapshot.data['classificationList'];
        let message = this.dataService.getDataByKey('message');
        if (message != undefined) {
            setTimeout(() => { this.messageService.add({ severity: 'success', summary: 'Successful', detail: message, life: 3000 }); })
            this.dataService.setData('message', undefined);
        }
        let tmpQueryFields = {
            title: { name: 'Title', type: 'string', searchType: 'textsearch' },
            content: { name: 'Content', type: 'string', searchType: 'textsearch' },
            spotlightDate: { name: 'Spotlight Date', type: 'date', searchType: 'datesearch' },
            organizations: { name: 'Accounts Involved', type: 'string', searchType: 'textsearch' },
            status: {
                name: 'Status', type: 'category', searchType: 'exactsearch',
                options: this.statuses.map((cat) => {
                    return { name: cat.name, value: cat.code }
                })
            }
        }
        this.queryBuilderFields = Object.keys(tmpQueryFields).sort(function (a, b) {
            var nameA = tmpQueryFields[a].name.toUpperCase(); // ignore upper and lowercase
            var nameB = tmpQueryFields[b].name.toUpperCase(); // ignore upper and lowercase
            return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
        }).reduce(function (result, key) {
            result[key] = tmpQueryFields[key];
            return result;
        }, {});
        this.orgUuid = this.route.snapshot.params.uuid;
        if (this.orgUuid != undefined) {
            delete (this.queryBuilderFields['organizations']);
            this.organizationService.getOrganization(this.orgUuid).subscribe(data => {
                this.breadcrumbService.setItems([{ label: 'SpotLight Listing ' + data['name'] }]);
            });

        }
        this.appUtil.initializeSearch(this);

    }

    edit() {
        this.router.navigate(['/spotlight/edit/' + this.currentMenuData.uuid]);
    }

    setCurrentItem(spotlight) {
        let items = [
            {
                label: 'Preview',
                icon: 'pi pi-print',
                command: (event) => {
                    this.previewDialog = true;
                }
            },
            {
                label: 'Edit',
                icon: 'pi pi-plus',
                command: (event) => {
                    this.edit();
                }
            },
            /*
            {
                label: 'Add Contact',
                icon: 'pi pi-plus',
                command: (event) => {
                    this.newContact();
                }
            },
            */
            {
                label: 'Delete',
                icon: 'pi pi-trash',
                command: () => {
                    let spotUuids = [];
                    spotUuids.push(spotlight);
                    this.delete(spotUuids);
                }
            }
        ];
        this.currentMenuData = spotlight;
        this.spotlightItems = items;
    }

    delete(spotlights: any){

        let spotUuids = [];
        for(let i=0;i<spotlights.length;i++){
            spotUuids.push(spotlights[i].uuid);
        }
        this.confirmationService.confirm({
            message: 'Are you sure you want to delete this spotlight?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.spotlightService.deleteSpotlights(spotUuids).subscribe(
                    data => {
                        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Spotlight Deleted', life: 3000 });
                        this.reload();
                    },
                    error => {
                        let errors = error.error;
                        if (errors.code && errors.code == 'VALIDATION_ERROR') {
                            this.messageService.add({ severity: 'error', summary: 'Error', detail: errors.subErrors[0].message, life: 3000 });
           
                        }
                    }
                )
            }
        });
    }

    loadData(e: LazyLoadEvent) {
        this.appUtil.paginateOrSort(this, '/spotlight', e);
    }

    search(first) {
        this.appUtil.search(this, '/spotlight', false, first, null, true);
    }

    advSearch(event, first) {
        this.advSrch.hide();
        this.appUtil.search(this, '/spotlight', true, first, event.query, true);
    }

    _search(e: LazyLoadEvent = { first: 0, rows: 25, sortField: 'spotlightDate', sortOrder: 1 }) {
        this.loading = false;
        var searchParams = {};
        searchParams['page'] = (e.first / e.rows);
        searchParams['pageSize'] = e.rows;
        var sortBy = []
        var sortDir = e.sortOrder == 1 ? 'ASC' : 'DESC';
        if (e.sortField && e.sortField != '') {
            sortBy[sortBy.length] = { "property": e.sortField, "direction": sortDir };
        }
        searchParams['sortDetails'] = sortBy;
        searchParams['rootRule'] = { 'condition': 'and', rules: [] }
        if (this.isAdvanceSearch) {
            searchParams['rootRule'] = this.appUtil.prepareRuleSetForQuery(JSON.parse(JSON.stringify(this.queryBuilderQuery)), this.queryBuilderFields);
        }
        else {
            if (this.term != '') {
                searchParams['query'] = this.term;
            }
        }
        this.spotlightService.searchSpotlight(searchParams, this.orgUuid).subscribe(
            data => {
                let response = data as any;
                this.totalRecords = response.totalRecords;
                this.spotlights = response.records;
                this.loading = false;
                this.appUtil.reinitializeShowMore();
                const data1 = localStorage.getItem('spotlightScroll');
                if (data1) {
                  this.ngZone.runOutsideAngular(() => {
                    setTimeout(() => {
                      window.scrollTo(0, parseInt(data1));
                    }, 0);
                  });
                }
            });
           
    }
    ngOnDestroy() {
        this.subscriptions.unsubscribe();
         window.removeEventListener('scroll', this.saveScrollPosition);
         localStorage.setItem('spotlightScroll', this.scrollTop); 
     }
     
     saveScrollPosition = (): void => {
         this.scrollTop = window.scrollY.toString();  
 
     }

    openNew() {
        this.router.navigate(['/spotlight/new']);
    }

    newContact() {
        this.conDialog = true;
    }

    reload()
    {
        let e: LazyLoadEvent = { first: this.firstRow, rows: this.pageSize, sortField: this.sortField, sortOrder: this.sortOrder };
        this._search(e);      
    }
}