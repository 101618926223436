import { InputSwitchModule } from 'primeng/inputswitch';
import { AccountPopupModule } from './../account-popup/account-popup.module';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserPopupAddComponent } from './user-popup.add.component';
import { UserPopupListComponent } from './user-popup.list.component';
import { MenuModule } from 'primeng/menu';
import { TableModule } from 'primeng/table';
import { PanelModule } from 'primeng/panel';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { LookupAutocompleteModule } from '../_components/lookup/lookup.module';
import {FileUploadModule} from 'primeng/fileupload';

@NgModule({
    declarations: [UserPopupAddComponent, UserPopupListComponent],
    imports: [
        CommonModule,
        DialogModule,
        FormsModule,
        InputTextModule,
        ButtonModule,
        ReactiveFormsModule,
        ToastModule,
        PanelModule,
        TableModule,
        MenuModule,
        AutoCompleteModule,
        AccountPopupModule,
        InputSwitchModule,
        LookupAutocompleteModule,
        FileUploadModule
    ],
    exports: [UserPopupAddComponent, UserPopupListComponent
    ]
})
export class UserPopupModule { }
