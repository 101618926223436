import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { AccountService } from 'src/app/_services';
import { AppUtil } from '../_helpers/app.util';
import { BreadcrumbService } from '../app.breadcrumb.service';
import { Deal, Organization, User } from './../_models/index';
import { DealService } from './../deal/deal.service';
import { OrganizationService } from './../organization/organization.service';
import { SpotlightService } from './../spotlight/spotlight.service';
import { TaskService } from './../task/task.service';
import { UsersService } from './../users/users.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'dashboard',
    templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit {
    contacts: User[];
    selectedContacts: User[] = [];
    contactLoading: boolean = true;
    contactTotalRecords: number = 0;
    showForm: boolean = false;
    contact: User;
    tbpEmployees: any[] = [];
    uuid: string[] = [];
    type: string = null;
    tasks: Task[];
    upCommingTasks: Task[];
    dueDateTasks: Task[];
    selectedTasks: Task[] = [];
    taskLoading: boolean = true;
    taskTotalRecords: number = 0;
    upCommingTaskTotalRecords: number = 0;
    dueDateTaskTotalRecords: number = 0;
    profileUuid = this.accountService.userValue.uuid;

    organizations: Organization[];
    selectedOrg: Organization[] = [];
    orgLoading: boolean = true;
    orgTotalRecords: number = 0;

    deals: Deal[];
    selectedDeal: Deal[] = [];
    dealLoading: boolean = true;
    dealTotalRecords: number = 0;
    selectedTask: any;

    constructor(public appUtil: AppUtil, private breadcrumbService: BreadcrumbService, private usersService: UsersService,
        private organizationService: OrganizationService, private spotlightService: SpotlightService,
        private dealService: DealService, private taskService: TaskService, public accountService: AccountService, private router: Router,
        private route: ActivatedRoute,) {
        this.breadcrumbService.setItems([
            { label: 'Dashboard' }
        ]);
    }

    ngOnInit(): void {
    }
    ngOnChange(e) {
         e.rows = 5; 
        this.loadTasks(e, "allTask");
        this.loadTasks(e, "upComingTask");
        this.loadTasks(e, "overDueTask");
    }
    loadTasks(e: LazyLoadEvent = { first: 0, rows: 5, sortField: 'name', sortOrder: 1 }, type: string) {
        const searchParams: any = {};
        searchParams['page'] = e.first / e.rows;
        searchParams['pageSize'] = e.rows;

        const sortBy = [];
        const sortDir = e.sortOrder === 1 ? 'ASC' : 'DESC';
        if (e.sortField && e.sortField !== '') {
            sortBy.push({ property: e.sortField, direction: sortDir });
        } else {
            sortBy.push({ property: 'id', direction: 'DESC' });
        }
        searchParams['sortDetails'] = sortBy;

        switch (type) {
            case 'allTask':
                this.taskService.searchDashboardTasks(this.profileUuid, searchParams, type).subscribe(
                    data => {
                        const response = data as any;
                        this.tasks = response.records;
                        this.taskTotalRecords = response.totalRecords;
                        this.taskLoading = false;
                    });
                break;
            case 'upComingTask':
                this.taskService.searchDashboardTasks(this.profileUuid, searchParams, type).subscribe(
                    data => {
                        const response = data as any;
                        this.upCommingTasks = response.records;
                        this.upCommingTaskTotalRecords = response.totalRecords;
                        this.taskLoading = false;
                    });
                break;
            case 'overDueTask':
                this.taskService.searchDashboardTasks(this.profileUuid, searchParams, type).subscribe(
                    data => {
                        const response = data as any;
                        this.dueDateTasks = response.records;
                        this.dueDateTaskTotalRecords = response.totalRecords;
                        this.taskLoading = false;
                    });
                break;
        }
    }

    edit(task: any) {
        this.uuid = task.uuid;
        this.showForm = true;
    }
    loadContacts(e: LazyLoadEvent = { first: 0, rows: 5, sortField: 'name', sortOrder: 1 }) {
        var searchParams = {};
        searchParams['page'] = (e.first / e.rows);
        searchParams['pageSize'] = e.rows;
        var sortBy = []
        var sortDir = e.sortOrder == 1 ? 'ASC' : 'DESC';
        if (e.sortField && e.sortField != '') {
            sortBy[sortBy.length] = { "property": e.sortField, "direction": sortDir };
        }
        else {
            sortBy[sortBy.length] = { "property": "id", "direction": "DESC" };
        }
        searchParams['sortDetails'] = sortBy;
        this.usersService.searchContact(searchParams).subscribe(
            data => {
                let response = data as any;
                this.contactTotalRecords = response.totalRecords;
                this.contacts = response.records;
                this.contactLoading = false;
            });
    }

    loadOrganizations(e: LazyLoadEvent = { first: 0, rows: 5, sortField: 'name', sortOrder: 1 }) {
        var searchParams = {};
        searchParams['page'] = (e.first / e.rows);
        searchParams['pageSize'] = e.rows;
        var sortBy = []
        var sortDir = e.sortOrder == 1 ? 'ASC' : 'DESC';
        if (e.sortField && e.sortField != '') {
            sortBy[sortBy.length] = { "property": e.sortField, "direction": sortDir };
        }
        else {
            sortBy[sortBy.length] = { "property": "id", "direction": "DESC" };
        }
        searchParams['sortDetails'] = sortBy;
        this.organizationService.searchOrganization(searchParams).subscribe(
            data => {
                let response = data as any;
                this.orgTotalRecords = response.totalRecords;
                this.organizations = response.records;
                this.orgLoading = false;
            });
    }

    loadDeals(e: LazyLoadEvent = { first: 0, rows: 5, sortField: 'name', sortOrder: 1 }) {
        var searchParams = {};
        searchParams['page'] = (e.first / e.rows);
        searchParams['pageSize'] = e.rows;
        var sortBy = []
        var sortDir = e.sortOrder == 1 ? 'ASC' : 'DESC';
        if (e.sortField && e.sortField != '') {
            sortBy[sortBy.length] = { "property": e.sortField, "direction": sortDir };
        }
        else {
            sortBy[sortBy.length] = { "property": "id", "direction": "DESC" };
        }
        searchParams['sortDetails'] = sortBy;
        this.dealService.searchDeal(searchParams).subscribe(
            data => {
                let response = data as any;
                this.dealTotalRecords = response.totalRecords;
                this.deals = response.records;
                this.dealLoading = false;
            });
    }

    get role() {
        return this.accountService.userValue.role;
    }

}
