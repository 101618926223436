import { Component, OnInit, ViewChild, NgZone, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Table } from 'primeng/table';
import { MenuItem, LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { SellSide } from '../_models/sellside';
import { SellsideService } from './sellside.service';
import { BreadcrumbService } from '../app.breadcrumb.service';
import { AccountService } from 'src/app/_services';
import { DataService } from '../_services/data.service';
import { MessageService } from 'primeng/api';
import { DomHandler } from 'primeng/dom';
import { AppUtil } from '../_helpers/app.util'
import { SearchHeler } from '../_helpers';
import { FieldInfo, makeFieldInfo } from '../_models';
import { UsersService } from '../users/users.service';
import { OrganizationService } from '../organization/organization.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
    selector: 'sellside-list',
    templateUrl: './sellside-list.component.html',
    styleUrls: ['./sellside-list.component.scss'],
    encapsulation: ViewEncapsulation.None , 
    providers: [ConfirmationService]
})
export class SellsideListComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription = new Subscription();
    @ViewChild('dt') dataTable: Table;
    @ViewChild('cform') formElement;
    form: UntypedFormGroup;
    sellsides: SellSide[];
    @ViewChild('dt') table: Table;
    selectedSellSide: SellSide [];
    loading: boolean;
    scrollTop: string;
    sellsideItems: MenuItem[];
    totalRecords: number;
    rowStatus: { [s: string]: Object; } = {};
    noRecordMessages: string[];
    sellside: SellSide;
    term: string;
    currentMenuData: any;
    loadingSpinner: boolean = false;
    classificationList: any = {
        CATEGORY_LEVEL1: [], CATEGORY_LEVEL2: [], CATEGORY_LEVEL3: [],
        VERTICAL: [], ECOSYSTEM: []
    };
    lookupList: any = { ACCOUNT_SOURCE: [] };
    showCheckbox: boolean = false;
    selectedColumns: any[];
    source: string;
    oldVal: any[] = [];
    debt: any[];
    equity:any[];
    privateEquityPartner:any[];
    primaryObjectiveUuid: string;
    timeline: any[];
    formsubmited: boolean = false;
    managementPreference: any[];
    pageSize: number;
    firstRow: number;
    sortField: string;
    sortOrder: number;
    organizationResults: any;
    sourceuuid: string;
    fieldInfo: FieldInfo[];
    searchUrl: string = '/sell-side/sellside';
    showAddDialog: any;
    editingMode: boolean = false;
    editingModes: { [key: string] : boolean}={};
    constructor(
        public appUtil: AppUtil,
        private dataService: DataService,
        private messageService: MessageService,
        private breadcrumbService: BreadcrumbService,
        private confirmationService: ConfirmationService,
        private sellsideService: SellsideService,
        private router: Router,
        private formBuilder: UntypedFormBuilder,
        private searchHelper: SearchHeler,
        private route: ActivatedRoute,
        private usersService: UsersService,
        private organizationService : OrganizationService,
        private accountService: AccountService,
        private ngZone: NgZone) {
        this.breadcrumbService.setItems([
            { label: 'Sell Side Listing' }
        ]);
        this.term = '';

        this.debt = [
            {value: 'A/R Financing'},
            {value: 'Junior Debt'},
            {value: 'Mezzanine Financing'},
            {value: 'Senior Debt'},
        ];
        this.equity = [
            {value: 'Growth Equity'},
            {value: 'Venture Capital'},
            {value: 'Angel Investment'},
            {value: 'Minority Recap'},
        ];
        this.timeline = [
            {value: '3-6 months'},
            {value: 'Next 3 months'},
            {value: 'No immediate plans'},
        ];
        this.managementPreference = [
            {value: 'Flexible'},
            {value: 'Management Will Exit'},
            {value: 'Management Will Stay'},
        ];
        this.privateEquityPartner = [
            {value: 'Control'},
            {value: '100%'},
            {value: 'Minority'},
        ];
       
    }
    loadSellSideForm() {
        this.form = this.formBuilder.group({
            uuid: [''],
            strategicPartner: [false],
            equityPartnerType:[],
            equityType: [],
            debtType: []
        });
    }
    ngOnInit(): void {
        const storedScrollTop = localStorage.getItem('SELLSIDESCROLL');
        if (storedScrollTop) {
            window.scrollTo(0, parseInt(storedScrollTop));
        }
        window.addEventListener('scroll', this.saveScrollPosition);
        let message = this.dataService.getDataByKey('message');
        this.loadSellSideForm(); 
        if (message != undefined) {
            setTimeout(() => { this.messageService.add({ severity: 'success', summary: 'Successful', detail: message, life: 3000 }); })
            this.dataService.setData('message', undefined);
        }
        this.route.params.subscribe(data => {
            this.source = data.source == '' ? null : data.source;
            this.sourceuuid = data.sourceuuid == '' ? null : data.sourceuuid;
            this.searchUrl = (data.source == '' ? '' : '/' + data.source) + (data.sourceuuid == '' ? '' : '/' + data.sourceuuid)
                + '/sellsides/';
            this.showCheckbox = data.showCheckbox == 'true' ? true : false;
        })
        this.classificationList = this.route.snapshot.data['classificationList'];
        this.lookupList = this.route.snapshot.data['lookupList'];
       
        
        let tmpFieldInfo: FieldInfo[] = [
            makeFieldInfo({ property: "screenTitle", label: 'Screen Title', type: 'string', searchType: 'text', forSearchTermQuery: true, columnIndex: 1, visible: 'A', sortable: true, linkPrefix: '/sell-side/', linkSuffix: '/prospects/', linkType: "p" }),
            makeFieldInfo({ property: "externalHeadline", label: 'External Headline', type: 'string', searchType: 'text', columnIndex: 3, visible: 'A', sortable: true }),
            makeFieldInfo({ property: "primaryObjective", label: 'Primary Objective', type: 'boolean', searchType: 'boolean', searchable: false, columnIndex: 4, visible: 'A', sortable: false }),
            makeFieldInfo({ property: "level1", label: 'Level1', type: 'classification', searchType: 'exact', searchOptions: this.classificationList.CATEGORY_LEVEL1, sortable: false, columnIndex: 8 }),
            makeFieldInfo({ property: "level2", label: 'Level2', type: 'classification', searchType: 'exact', searchOptions: this.classificationList.CATEGORY_LEVEL2, sortable: false, columnIndex: 5 }),
            makeFieldInfo({ property: "level3", label: 'Level3', type: 'classification', searchType: 'exact', searchOptions: this.classificationList.CATEGORY_LEVEL3, sortable: false, columnIndex: 6 }),
            makeFieldInfo({ property: "vertical", label: 'Vertical', type: 'classification', searchType: 'exact', searchOptions: this.classificationList.VERTICAL, sortable: false, columnIndex: 7 }),
            makeFieldInfo({ property: "ecosystem", label: 'Ecosystem', type: 'classification', searchType: 'exact', searchOptions: this.classificationList.ECOSYSTEM, sortable: false, columnIndex: 9 }),
            makeFieldInfo({ property: "targetRevenueLookup", label: 'Target Revenue ($m)', type: 'range', searchType: 'exact', valueProperty: "value", labelProperty: "value", searchOptions: this.lookupList.REVENUE, sortable: false, columnIndex: 11}),
            makeFieldInfo({ property: "targetEbitdaLookup", label: 'Target EBITDA ($m)', type: 'range', searchType: 'exact', valueProperty: "value", labelProperty: "value", searchOptions: this.lookupList.EBITDA, sortable: false, visible: "F" , columnIndex: 10}),
            makeFieldInfo({ property: "organization", label: 'Account', type: 'string', searchType: 'text', visible: 'A', sortable: false, linkPrefix: '/organization/', linkType: "s",columnIndex: 2 }),
            makeFieldInfo({ property: "contacts", label: 'Contacts', type: 'string', searchType: 'text', visible: 'F', sortable: false, linkPrefix: '/contact/', linkType: "s" }),
            makeFieldInfo({ property: "timeline", label: 'Ideal Timeline', type: 'string', searchType: 'text', visible: 'F', sortable: false }),
            makeFieldInfo({ property: "managementPreference", label: 'Management Preference', type: 'string', searchType: 'text', visible: 'F', sortable: false }),
            makeFieldInfo({ property: "turnaround", label: 'Turnaround', type: 'boolean', searchType: 'boolean', visible: 'F', sortable: false }),
            makeFieldInfo({ property: "strategicPartner", label: 'Strategic Partner', type: 'boolean', searchType: 'boolean', visible: 'H', sortable: false }),
            makeFieldInfo({ property: "controlling", label: 'Private Equity Partner (Control)', type: 'boolean', searchType: 'boolean', visible: 'H', sortable: false }),
            makeFieldInfo({ property: "hundredPercent", label: 'Private Equity Partner (100%)', type: 'boolean', searchType: 'boolean', visible: 'H', sortable: false }),
            makeFieldInfo({ property: "minority", label: 'Private Equity Partner (Minority)', type: 'boolean', searchType: 'boolean', visible: 'H', sortable: false }),
            makeFieldInfo({ property: "arFinancing", label: 'A/R Financing', type: 'boolean', searchType: 'boolean', visible: 'H', sortable: false }),
            makeFieldInfo({ property: "juniorDebt", label: 'Junior Debt', type: 'boolean', searchType: 'boolean', visible: 'H', sortable: false }),
            makeFieldInfo({ property: "mezzanineFinancing", label: 'Mezzanine Financing', type: 'boolean', searchType: 'boolean', visible: 'H', sortable: false }),
            makeFieldInfo({ property: "seniorDebt", label: 'Senior Debt', type: 'boolean', searchType: 'boolean', visible: 'H', sortable: false }),
            makeFieldInfo({ property: "growthEquity", label: 'Growth Equity', type: 'boolean', searchType: 'boolean', visible: 'H', sortable: false }),
            makeFieldInfo({ property: "ventureCapital", label: 'Venture Capital', type: 'boolean', searchType: 'boolean', visible: 'H', sortable: false }),
            makeFieldInfo({ property: "angelInvestment", label: 'Angel Investment', type: 'boolean', searchType: 'boolean', visible: 'H', sortable: false }),
            makeFieldInfo({ property: "minorityRecap", label: 'Minority Recap', type: 'boolean', searchType: 'boolean', visible: 'H', sortable: false }),
            makeFieldInfo({ property: "transactionObjective", label: 'Transaction Objective Description', type: 'string', visible: 'F', searchType: 'text', sortable: false, searchable: false }),
            makeFieldInfo({ property: "companyDescription", label: 'Target Description', type: 'string', searchType: 'text', visible: 'F', sortable: false, searchable: false }),
            makeFieldInfo({ property: "lastModifiedDate", label: "Modification Date", type: "date", searchType: "date", visible: 'T' }),
            makeFieldInfo({ property: "createdDate", label: "Creation Date", type: "date", searchType: "date", visible: 'F' }),
            makeFieldInfo({ property: "relevance", label: "Relevance", type: "date", searchable: false, visible: 'H' }),
            makeFieldInfo({ property: "status", label: "Status", type: "enum", searchType: "exact", visible: 'F', searchable: false }),
        ]
        this.fieldInfo = tmpFieldInfo;

        this.totalRecords = 0;
        this.sellsides = [];
        this.loading = true;
        this.currentMenuData = { id: '' };
        this.sellsideItems = [];   
    }

    search(e) {

        let searchParams = this.searchHelper.prepareSearchParams(e.searchTerm, e.filter, e.sortField, e.sortOrder, e.first, e.rows, this.fieldInfo);
        if(e.searchTerm) {
            this.term = e.searchTerm;
        }
        if (this.source != null && this.sourceuuid != null && !this.showCheckbox && this.source == 'contact') {
            searchParams['rootRule'].condition = 'and';
            searchParams['rootRule']['rules'] == undefined ? [] : searchParams['rootRule']['rules'];
            searchParams['rootRule']['rules'].push({ 'searchType': 'exact', 'field': (this.source == 'contact' ? this.source + 's' : this.source), 'operator': 'equals', 'value': this.sourceuuid });
            this.usersService.getSellSides(this.sourceuuid, searchParams).subscribe(
                data => {
                    let response = data as any;
                    this.totalRecords = response.totalRecords;
                    this.sellsides = response.records;
                    this.loading = false;
                    this.appUtil.reinitializeShowMore();
                });
      
        }else if(this.source != null && this.sourceuuid != null &&  !this.showCheckbox && this.source == 'organization'){
            searchParams['pageSize'] = -1;
            searchParams['rootRule']['rules'].push({ 'searchType': 'exact', 'field': 'organization', 'operator': 'equals', 'value': this.sourceuuid });
            this.organizationService.getSellSides(this.sourceuuid, searchParams).subscribe(
                data => {
                    let response = data as any;
                    this.totalRecords = response.totalRecords;
                    this.sellsides = response.records;
                    this.loading = false;
                    this.appUtil.reinitializeShowMore();
                });
        }
        else{
            this.sellsideService.searchSellSides(searchParams).subscribe(
                data => {
                    let response = data as any;
                    this.totalRecords = response.totalRecords;
                    this.sellsides = response.records;
                  
                    
                    this.loading = false;
                    this.appUtil.reinitializeShowMore();
                    if(e.searchTerm==null){
                    const localeCompareKey = localStorage.getItem('SELLSIDESCROLL');
                    if (localeCompareKey) {
                      this.ngZone.runOutsideAngular(() => {
                        setTimeout(() => {
                          window.scrollTo(0, parseInt(localeCompareKey));
                        }, 0);
                      });
                    }
                    }
                });
        }
        
    }
    ngOnDestroy() {
        this.subscriptions.unsubscribe();
         window.removeEventListener('scroll', this.saveScrollPosition);
         localStorage.setItem('SELLSIDESCROLL', this.scrollTop); 
     }
     
     saveScrollPosition = (): void => {
         this.scrollTop = window.scrollY.toString();  
 
     }
    new() {
        this.router.navigate(['/sell-side/new']);
    }

    edit() {
        this.router.navigate(['/sell-side/edit/' + this.currentMenuData.uuid]);
    }

    delete(sellsides: any) {

        let sellsideUuids = [];
        for (let i = 0; i < sellsides.length; i++) {
            sellsideUuids.push(sellsides[i].uuid);
        }
        this.confirmationService.confirm({
            message: 'Are you sure you want to delete this Sellside Screen?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.loadingSpinner = true;
                this.sellsideService.deleteSellsideScreens(sellsideUuids).subscribe(
                    data => {
                        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Sell Side Deleted', life: 3000 });
                        this.search({ first: 0, rows: 25, sortField: 'screenTitle', sortOrder: 1 });
                        this.loadingSpinner = false;
                    },
                    error => {
                        this.loadingSpinner = false;
                        let errors = error.error;
                        if (errors.code && errors.code == 'VALIDATION_ERROR') {
                            this.messageService.add({ severity: 'error', summary: 'Error', detail: errors.subErrors[0].message, life: 3000 });
                        }
                    }
                )
            }
        });
    }
    confirmAdd() {
        this.showAddDialog = true;
      }
      addToSource() {
        this.dataService.setData("sellside-" + this.source + "-" + this.sourceuuid,this.selectedSellSide);
        switch (this.source) {
          case "contacts":
            this.router.navigate(["/contact/" + this.sourceuuid]);
            break;
          case "organizations":
            this.router.navigate(["/organization/" + this.sourceuuid ]);
            break;
        }
      }
    
      remove(contact) {
        this.selectedSellSide = this.appUtil.deleteItem(
          this.selectedSellSide,
          contact
        );
        if (this.selectedSellSide.length == 0) {
          this.showAddDialog = false;
        }
      }
    setCurrentItem(sellside) {
        let items = [
            {
                label: 'Edit',
                icon: 'pi pi-plus',
                command: (event) => {
                    this.edit();
                }
            },
            {
                label: 'Delete',
                icon: 'pi pi-trash',
                command: (event) => {
                    let sellsides = [];
                    sellsides.push(sellside);
                    this.delete(sellsides);
                }
            }
        ];
        this.currentMenuData = sellside;
        this.sellsideItems = items;
    }

    // getPrivateEquityPartner(sellside : any){

    //     let val = "Private Equity Partner - ";
    //     let separator = "";
    //     if(sellside.primaryObjectives.privateEquityPartner.controlling) {
    //         val = val + separator + "Control";
    //         separator = "; ";
    //     }
    //     if(sellside.primaryObjectives.privateEquityPartner.hundredPercent) {
    //         val = val + separator + "100%";
    //         separator = "; ";
    //     }
    //     if(sellside.primaryObjectives.privateEquityPartner.minority) {
    //         val = val + separator + "Minority";
    //         separator = "; ";
    //     }
    //     if(val == "Private Equity Partner - "){
    //         val = "";
    //     }
    //     return val;
    // }

    // getFinancingDebt(sellside : any){

    //     let debt = sellside.primaryObjectives.financing.debt;
    //     let val = "Obtain Financing (Debt) - ";
    //     let separator = "";
    //     if(debt.arFinancing) {
    //         val = val + separator + "A/R Financing";
    //         separator = "; ";
    //     }
    //     if(debt.juniorDebt){
    //         val = val + separator + "Junior Debt"
    //         separator = "; "
    //     }
    //     if(debt.mezzanineFinancing){
    //         val = val + separator + "Mezannine Financing";
    //         separator = "; "
    //     }
    //     if(debt.seniorDebt){
    //         val = val + separator + "Senior Debt";
    //     }
    //     if(val == "Obtain Financing (Debt) - "){
    //         val = "";
    //     }
    //     return val;
    // }

    // getFinancingEquity(sellside : any){

    //     let equity = sellside.primaryObjectives.financing.equity;
    //     let val = "Obtain Financing (Equity) - ";
    //     let separator = "";
    //     if(equity.angelInvestment) {
    //         val = val + "Angel Investment";
    //         separator = "; ";
    //     }
    //     if(equity.growthEquity) {
    //         val = val + "Growth Equity";
    //         separator = "; ";
    //     }
    //     if(equity.minorityRecap) {
    //         val = val + "Minority Recap";
    //         separator = "; ";
    //     }
    //     if(equity.ventureCapital) {
    //         val = val + "Venture Capital";
    //         separator = "; ";
    //     }
    //     if(val == "Obtain Financing (Equity) - "){
    //         val = "";
    //     }
    //     return val;
    // }

    _updateSelectedColumns($event) {
        this.selectedColumns = $event;
    }

    disableNavigation(event: any) {
        if (event.key === "ArrowRight" || event.key === "Right"
            || event.key === "ArrowLeft" || event.key === "Left"
            || event.key === "ArrowUp" || event.key === "Up"
            || event.key === "ArrowDown" || event.key === "Down") {
            event.stopPropagation();
        }

    }
    getType(columnName: string){
        if(columnName === 'level2'){
            return "CATEGORY_LEVEL2";
        }
        else if(columnName === 'level3'){
            return  "CATEGORY_LEVEL3";
        }
        else if(columnName === 'vertical'){
            return  "VERTICAL";
        }
       else if(columnName === 'ecosystem'){
            return  "ECOSYSTEM";
        }
    }

    initEdit($event) {
        this.primaryObjectiveUuid =$event.data.uuid;
        this.sellsideService.getSellside($event.data.uuid).subscribe(data => {
            this.modelToForm(data as any);
          })
        let oldValues = this.oldVal[$event.data.uuid];
        if (!oldValues) {
            oldValues = [];
            this.oldVal[$event.data.uuid] = oldValues;
        }
        if ([ 'level1', 'level2', 'level3', 'vertical', 'ecosystem' ].includes($event.field)) {
          oldValues[$event.field] = $event.data.classification[$event.field]; 
      } else {
        oldValues[$event.field] = $event.data[$event.field];
      }
      }
      editCancelled($event) {
        let oldValues = this.oldVal[$event.data.uuid];
        delete oldValues[$event.field];
      }
      
      editorAction(event, sellside: any, field: string, cancelled: boolean) {

        if (cancelled) {
            this.editCancelled({ field, data: sellside });
            location.reload();
        }
        else {
            this.editCompleted({ field, data: sellside });
        }
        DomHandler.removeClass(this.dataTable.editingCell, 'p-cell-editing');
        this.dataTable.editingCell = null;
        this.dataTable.editingCellData = null;
        this.dataTable.editingCellField = null;
        this.dataTable.unbindDocumentEditListener();
        event.preventDefault();
        event.cancelBubble = true;
        event.stopPropagation();
        this.appUtil.reinitializeShowMore();
      }
      splitAndCapitalize(field: string): string {
        return field
          .split(/(?=[A-Z])/)
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      }
      editCompleted($event) {
        let oldValues = this.oldVal[$event.data.uuid];
              let dataToPost  = { uuid: $event.data.uuid };
             
        if(($event.field === 'screenTitle' || $event.field === 'externalHeadline') && $event.data[$event.field] === ""){
            const readableField = this.splitAndCapitalize($event.field);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: readableField+" is Required", life: 3000});
          this.reload(); 
          this.editingModes[$event.data.uuid] = false; 
          return; 
        }
        if ($event.field == 'level1' || $event.field == 'level2' || $event.field == 'level3' || $event.field == 'vertical' || $event.field == 'ecosystem' ) {
            dataToPost['classification'] = $event.data.classification ? $event.data.classification: null; 
            $event.field = 'classification';
        } 
        else {
             dataToPost[$event.field] = $event.data[$event.field];  
        }
        if (Array.isArray($event.data[$event.field]) && $event.data[$event.field].length > 0) {
           
            
            dataToPost[$event.field] = $event.data[$event.field].map(item => item.uuid);
        }
        if($event.field == 'targetEbitda' || $event.field == 'dollorValueRange' || $event.field == 'targetRevenue'){
            dataToPost[$event.field] = $event.data[$event.field]?.value;
        }
        if($event.field == 'organization' ){
            dataToPost[$event.field] =$event.data[$event.field].uuid;
        }

        if(oldValues[$event.field] !== $event.data[$event.field] ){
        this.sellsideService.updateSellsideList([$event.field], dataToPost).subscribe(
             data => {
                this.loading = false;
                this.editingModes[$event.data.uuid] = false;   
                //this.reload();
                // this.messageService.add({ severity: 'success', summary: 'Successful', detail: "Sellside Update Successfully", life: 3000})     
                 },
                 error => {
                 $event.data[$event.field] = oldValues[$event.field]
                 let errors = error.error;
                 if (errors.code && errors.code == 'VALIDATION_ERROR') {
                 this.messageService.add({ severity: 'error', summary: 'Error', detail: errors.subErrors[0].message, life: 3000 });
                 }
                 })
                }
              delete oldValues[$event.field];
            }
            reload() {
                let e: LazyLoadEvent  = { first: this.firstRow, rows: this.pageSize, sortField: this.sortField, sortOrder: 0 };
                    this.search(e);   
                    
              }  
      
      getOutputOrganization( value: any) {
        if (value && typeof value === 'object') {
            return value.name;
        }
        return value;
      }
      getOutput( value: any) {
        if (value && typeof value === 'object') {
            return value.value;
        }
        return value;
      }

      getOptionsForProperty(property: string): any[] {
        if (property === 'level1') {
          return this.classificationList.CATEGORY_LEVEL1;
        } else if (property === 'level2') {
          return this.classificationList.CATEGORY_LEVEL2;
        }  else if (property === 'level3') {
            return this.classificationList.CATEGORY_LEVEL3;
        } else if (property === 'vertical') {
            return this.classificationList.VERTICAL;
        } else if (property === 'ecosystem') {
        return this.classificationList.ECOSYSTEM;
        }
       
      }
      searchOrganizations(event) {
        this.organizationService.lookupOrganization(event.query).subscribe(data => {
            this.organizationResults = data as any;
        });
    }
      
      toggleEditingMode(sellsideUuid: string) {
        this.editingModes[sellsideUuid] = !this.editingModes[sellsideUuid];
    }
    isEditing( sellsideUuid: string): boolean{
      return this.editingModes[sellsideUuid] || false;

    }  
    closeDialog() {
        this.reload();
      } 
      onSelectionChange($event: any) {
          this.editCompleted($event);
      }
      get f() { return this.form.controls; }
modelToForm(model) {
        let debtTypeValues = [];
  if(model.primaryObjectives.financing.debt.arFinancing)
  {
      debtTypeValues.push(this.appUtil.findItemInList(this.debt, 'A/R Financing', 'value').value)
  }
  if(model.primaryObjectives.financing.debt.juniorDebt)
  {
      debtTypeValues.push(this.appUtil.findItemInList(this.debt, 'Junior Debt', 'value').value)
  }
  if(model.primaryObjectives.financing.debt.mezzanineFinancing)
  {
      debtTypeValues.push(this.appUtil.findItemInList(this.debt, 'Mezzanine Financing', 'value').value)
  }
  if(model.primaryObjectives.financing.debt.seniorDebt)
  {
      debtTypeValues.push(this.appUtil.findItemInList(this.debt, 'Senior Debt', 'value').value)
  }
  let equityTypeValues = [];
  if(model.primaryObjectives.financing.equity.growthEquity)
  {
      equityTypeValues.push(this.appUtil.findItemInList(this.equity, 'Growth Equity', 'value').value)
  }
  if(model.primaryObjectives.financing.equity.ventureCapital)
  {
      equityTypeValues.push(this.appUtil.findItemInList(this.equity, 'Venture Capital', 'value').value)
  }
  if(model.primaryObjectives.financing.equity.angelInvestment)
  {
      equityTypeValues.push(this.appUtil.findItemInList(this.equity, 'Angel Investment', 'value').value)
  }
  if(model.primaryObjectives.financing.equity.minorityRecap)
  {
      equityTypeValues.push(this.appUtil.findItemInList(this.equity, 'Minority Recap', 'value').value)
  }
  let equityPartnerTypeValues = [];
  if(model.primaryObjectives.privateEquityPartner.controlling)
  {
      equityPartnerTypeValues.push(this.appUtil.findItemInList(this.privateEquityPartner, 'Control', 'value').value)
  }
  if(model.primaryObjectives.privateEquityPartner.hundredPercent)
  {
      equityPartnerTypeValues.push(this.appUtil.findItemInList(this.privateEquityPartner, '100%', 'value').value)
  }
  if(model.primaryObjectives.privateEquityPartner.minority)
  {
      equityPartnerTypeValues.push(this.appUtil.findItemInList(this.privateEquityPartner, 'Minority', 'value').value)
  }

  this.form.setValue({
      uuid: model.uuid,
      equityPartnerType: equityPartnerTypeValues,
      equityType: equityTypeValues,
      debtType: debtTypeValues,
      strategicPartner: model.primaryObjectives.strategicPartner,
      
  });
}
nextStep() {
    let hasError:boolean = false;
   
        this.validatePrimaryObjective(null);
        hasError =  this.f.strategicPartner.invalid
            || (this.f.equityPartnerType.invalid)
            || this.f.debtType.invalid || this.f.equityType.invalid 
            
  }
handleError(elemId) {
  this.messageService.add({ severity: 'error', summary: 'Form Errors', detail: "Please select at least one primary objective", life: 3000 });
  let invalidControl = this.formElement.nativeElement.querySelector('.ng-invalid');

  if (invalidControl) {
      invalidControl.focus();
  }
  else if (elemId != null) {
      invalidControl = this.formElement.nativeElement.querySelector('#' + elemId);
      if (invalidControl) {
          invalidControl.focus();
      }
  }
}
validatePrimaryObjective(event: any) {  
  let error = { 'message': 'Primary objective is required.' };
  if (this.f.strategicPartner.value || (this.f.equityPartnerType.value != null && this.f.equityPartnerType.value.length>0)
      || (this.f.equityType.value !=null && this.f.equityType.value.length > 0) 
      || (this.f.debtType.value !=null && this.f.debtType.value.length > 0))
  {
      error = null;
  }
  
  this.appUtil.setError(this.form, 'strategicPartner', error);
  this.appUtil.setError(this.form, 'equityPartnerType', error);
  this.appUtil.setError(this.form, 'equityType', error);
  this.appUtil.setError(this.form, 'debtType', error);
  this.formsubmited = true;
}
      prepareModel(fieldName:string,  $event){
        this.nextStep();
        if (this.form.invalid) {
          this.handleError(null);
          return;
      }
        let arFinancing =  this.appUtil.findItemInList(this.f.debtType.value, this.appUtil.findItemInList(this.debt, 'A/R Financing', 'value').value)?true:false;
        let juniorDebt =  this.appUtil.findItemInList(this.f.debtType.value, this.appUtil.findItemInList(this.debt, 'Junior Debt', 'value').value)?true:false;
        let mezzanineFinancing =  this.appUtil.findItemInList(this.f.debtType.value, this.appUtil.findItemInList(this.debt, 'Mezzanine Financing', 'value').value)?true:false;
        let seniorDebt =  this.appUtil.findItemInList(this.f.debtType.value, this.appUtil.findItemInList(this.debt, 'Senior Debt', 'value').value)?true:false;
        let growthEquity =  this.appUtil.findItemInList(this.f.equityType.value, this.appUtil.findItemInList(this.equity, 'Growth Equity', 'value').value)?true:false;
        let ventureCapital =  this.appUtil.findItemInList(this.f.equityType.value, this.appUtil.findItemInList(this.equity, 'Venture Capital', 'value').value)?true:false;
        let angelInvestment =  this.appUtil.findItemInList(this.f.equityType.value, this.appUtil.findItemInList(this.equity, 'Angel Investment', 'value').value)?true:false;
        let minorityRecap =  this.appUtil.findItemInList(this.f.equityType.value, this.appUtil.findItemInList(this.equity, 'Minority Recap', 'value').value)?true:false;
        let controlling =  this.appUtil.findItemInList(this.f.equityPartnerType.value, this.appUtil.findItemInList(this.privateEquityPartner, 'Control', 'value').value)?true:false;
        let hundredPercent =  this.appUtil.findItemInList(this.f.equityPartnerType.value, this.appUtil.findItemInList(this.privateEquityPartner, '100%', 'value').value)?true:false;
        let minority =  this.appUtil.findItemInList(this.f.equityPartnerType.value, this.appUtil.findItemInList(this.privateEquityPartner, 'Minority', 'value').value)?true:false;
        let debt = {arFinancing, juniorDebt,mezzanineFinancing,seniorDebt}
        let equity = {growthEquity, ventureCapital, angelInvestment, minorityRecap}
        let privateEquityPartner = {controlling, hundredPercent, minority}
      
        let dataToPost :any= { uuid: this.primaryObjectiveUuid, primaryObjectives: { strategicPartner: this.f.strategicPartner.value, privateEquityPartner, financing: {debt, equity}} };
        this.sellsideService.updateSellsideList([fieldName], dataToPost).subscribe(
          data => {
              this.loading = false;  
              this.reload();
          })
          
      }   
}
