<p-dialog [(visible)]="show" (onHide)="hideDialogState()" [style]="{width: '450px'}" [header]="type" [modal]="true"
    styleClass="p-fluid">
    <ng-template pTemplate="content">
        <form [formGroup]="stateForm">
            <div class="field">
                <label for="name" class="label-set">Name</label><span class="mandate">*</span>
                <input type="text" maxlength="100" pInputText id="name" formControlName="name"
                    [ngClass]="{ 'ng-invalid ng-dirty': lookSubmitted && f.name.errors }" required autofocus />
                <small class="p-error" *ngIf="lookSubmitted && f.name.invalid && f.name.hasError('required')">Name is
                    required.</small>
                <small class="p-error"
                    *ngIf="lookSubmitted && f.name.invalid && f.name.hasError('message')">{{f.name.errors.message}}</small>
            </div>
            <div class="field">
                <label for="iso2" class="label-set">State Code (ISO 2)</label>
                <input type="text" maxlength="3" pInputText id="iso2" formControlName="iso2"
                    [ngClass]="{ 'ng-invalid ng-dirty': lookSubmitted && f.iso2.errors }" />
                <small class="p-error" *ngIf="submitted && f.iso2.invalid && f.iso2.hasError('required')">State code is
                    required.</small>
                <small class="p-error"
                    *ngIf="lookSubmitted && f.iso2.invalid && f.iso2.hasError('message')">{{f.iso2.errors.message}}</small>
            </div>
        </form>
    </ng-template>

    <ng-template pTemplate="footer">
        <div *ngIf="lookSpinner">
            <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
                fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
        </div>
        <div *ngIf="lookSpinner == false">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
                (click)="hideDialogState()"></button>
            <button pButton pRipple label="Save" form="clform" icon="pi pi-check" (click)="onSaveState()"
                class="p-button-text"></button>
        </div>
    </ng-template>
</p-dialog>
<p-toast></p-toast>