<div class="grid table-demo">
    <p-toast></p-toast>
    <div class="col-12">
        <div class="card">
            <p-toolbar styleClass="m-0">
                <div class="field col-12 md:col-10">
                    <p-dropdown inputId="name" (onChange)="onKeyChange($event)" [options]="keyList" optionLabel="name"
                        optionValue="id"></p-dropdown>
                </div>
                <ng-template pTemplate="right">
                    <button pButton pRipple label="Back" icon="pi pi-arrow-left" class="p-button-success mr-2 mb-3" (click)="appUtil.return()"></button>
                    </ng-template>
            </p-toolbar>
           
            <p-table dataKey="uuid" [autoLayout]="true" #dt [value]="helpValues" [(selection)]="selectedValues"
                styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm" [rowHover]="true"
                [rows]="10" [paginator]="false" [filterDelay]="0" [globalFilterFields]="['name']"
                [showCurrentPageReport]="true" [loading]="loading"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="key">Key
                            <p-sortIcon field="key"></p-sortIcon>
                        </th>
                        <th pSortableColumn="text">Text
                            <p-sortIcon field="text"></p-sortIcon>
                        </th>
                        <th style="text-align: center;">Action</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-helpvalue>
                    <tr>
                        <td class="wrap">
                            <span class="p-column-title">Key</span>
                            {{helpvalue.key}}
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Text</span>
                            {{helpvalue.text}}
                        </td>
                        <td style="text-align: center;">
                            <button type="button" pButton icon="pi pi-list"
                                (click)="setCurrentItem(helpvalue); menu.toggle($event)"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="3">No records found.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
<p-dialog [(visible)]="helpDialog" (onHide)="hideDialog()" [style]="{width: '450px'}" [header]="selectedKey.name"
    [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
        <form [formGroup]="form" id="clform" (ngSubmit)="onSubmit()">
            <div class="field">
                <label for="value">key</label><span class="mandate">*</span>
                <input type="text" maxlength="100" disabled="true" pInputText id="key" formControlName="key"
                    [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.key.errors }" required autofocus />
                <small class="p-error" *ngIf="submitted && f.key.invalid && f.key.hasError('required')">Key is
                    required.</small>
                <small class="p-error"
                    *ngIf="submitted && f.key.invalid && f.key.hasError('message')">{{f.key.errors.message}}</small>
            </div>
            <!-- <div class="field">
		<label for="value">Label</label><span class="mandate">*</span>
		<input type="text" maxlength="100" pInputText id="label" formControlName="label" [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.label.errors }" required autofocus />
		<small class="p-error" *ngIf="submitted && f.label.invalid && f.label.hasError('required')">Label is required.</small>
		<small class="p-error" *ngIf="submitted && f.label.invalid && f.label.hasError('message')">{{f.label.errors.message}}</small>
	  </div>	-->
            <div class="field">
                <label for="value">Text</label>
                <textarea [rows]="5" [cols]="30" formControlName="text" pInputTextarea
                    autoResize="autoResize"></textarea>
                <small class="p-error"
                    *ngIf="submitted && f.text.invalid && f.text.hasError('message')">{{f.text.errors.message}}</small>
            </div>
        </form>
    </ng-template>

    <ng-template pTemplate="footer">
        <div *ngIf="loadingSpinner">
            <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
                fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
        </div>
        <div *ngIf="loadingSpinner == false">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
                (click)="hideDialog()"></button>
            <button pButton pRipple label="Save" form="clform" icon="pi pi-check" class="p-button-text"></button>
        </div>
    </ng-template>
</p-dialog>
<p-menu #menu [popup]="true" [model]="menuItems"></p-menu>