<div class="grid table-demo">
    <div class="col-12">
        <div class="card">
            <p-toolbar styleClass="headerSticky">
                <div class="p-toolbar-group-left">
                    <div class="grid"></div>
                </div>
                <div class="p-toolbar-group-right">
                    <div class="grid">
                        <div class="col-12">
                            <button pButton pRipple label="Back" icon="pi pi-arrow-left" class="p-button-success mr-2" (click)="return()"></button>
                            <button pButton type="button" class="p-button-success mr-2" (click)="confirmAdd()" *ngIf="showCheckbox" 
                                [disabled]="(!appUtil.isArray(selectedOrganizations) || selectedOrganizations.length == 0)">
                                Add Selected ({{appUtil.isArray(selectedOrganizations)?selectedOrganizations.length:0}})
                            </button>
                            <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success mr-2"
                                (click)="appUtil.routeToNewURL('organization')" *ngIf="!showCheckbox"></button>
                            <p-splitButton label="Upload" (onClick)="accountUpload()" icon="pi pi-upload"
                                [model]="uploadItems" *ngIf="!showCheckbox"></p-splitButton>
                        </div>
                    </div>
                </div>
            </p-toolbar>
            <tbp-search #so [fields]="fieldInfo" filterFor="ORGANIZATION"
                searchTermPlaceHolder="Search Accounts By Name" [searchURL]="searchUrl" (onSearch)="_search($event)"
                (onShowHideField)="_updateSelectedColumns($event)">
            </tbp-search>
            <div class="p-fluid  grid" *ngIf="currentQuery">
                <div class="col-12">
                    <strong>Current Query:</strong> {{isAdvanceSearch?currentQuery:('matches ' +currentQuery)}}
                </div>
            </div>
            <p-table [autoLayout]="true" #dt [value]="organizations" [(selection)]="selectedOrganizations"
                [columns]="selectedColumns" dataKey="uuid" [lazy]="true"
                (onLazyLoad)="so.loadData($event.first,$event.rows,$event.sortField,$event.sortOrder)"
                [lazyLoadOnInit]="false"
                styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm" [rowHover]="true"
                [rows]="so.pageSize" [first]="so.firstRow" [paginator]="true"
                [rowsPerPageOptions]="[15,25,50,75,100]" [loading]="loading"
                [alwaysShowPaginator]="true" [showCurrentPageReport]="true"  [showPageLinks]="appUtil.getPagination(totalRecords)" [showFirstLastIcon] = "appUtil.getPagination(totalRecords)"
                currentPageReportTemplate="Showing {first} to {last} of {{appUtil.getPagination(totalRecords) ? totalRecords :'many'}} entries"
                [totalRecords]="totalRecords" [sortField]="so.sortField" (onEditInit)="initEdit($event)"
                (onEditComplete)="editCompleted($event)" (onEditCancel)="editCancelled($event)"
                [sortOrder]="so.sortOrder">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th style="width: 3rem" *ngIf="showCheckbox">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <ng-container *ngFor="let column of columns;">
                            <th pSortableColumn="{{column.property}}" [pSortableColumnDisabled]="!column.sortable">
                                {{column.label}}
                                <p-sortIcon field="{{column.property}}" *ngIf="column.sortable"></p-sortIcon>
                            </th>
                        </ng-container>
                        <th *ngIf="addContact"></th>
                        <th *ngIf="!addContact">Action</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-organization let-columns="columns" let-expanded="expanded">
                    <tr>
                        <td (click)="$event.stopPropagation();" *ngIf="showCheckbox">
                            <p-tableCheckbox [value]="organization"></p-tableCheckbox>
                        </td>
                        <ng-container *ngFor="let column of columns; let colIndex = index">
                            <!-- <td class="wrap" [attr.width]="column.columnWidth">
                                <ng-container *ngIf="column.property == 'companyType'">
                                    {{organization.companyType.replace('_',' ') | titlecase}}
                                </ng-container>
                                <ng-container *ngIf="column.property == 'location'">
                                        <span *ngIf="organization.state != null">{{organization.state | titlecase}}</span>
                                        <span *ngIf="organization.country != null">
                                            <ng-container *ngIf="organization.state != null">,</ng-container>
                                            {{organization.country | titlecase}}
                                        </span>
                                </ng-container>
                                <ng-container *ngIf="column.property == 'primaryContact'">
                                    <span *ngFor="let primaryContact of organization.primaryContact; let last = last;" class="lowercase">
                                        <a [routerLink]="['/contact/', primaryContact.uuid]">{{primaryContact.email}}&nbsp;{{primaryContact.phone}}{{last?'':'; '}}</a>
                                    </span>
                                </ng-container>
                                <ng-container *ngIf="column.property == 'parentCompany'">
                                    <span *ngIf="organization.parentCompany != null">
                                        <a>{{organization.parentCompany.name}}</a>
                                    </span>
                                </ng-container>
                                <ng-container *ngIf="column.property != 'companyType' && column.property != 'location' && column.property != 'primaryContact' && column.property != 'parentCompany'">
                                    <tbp-table-column [object]="organization" [fieldInfo]="column" (onPatch)="patchDeal($event)"></tbp-table-column>
                                </ng-container>
                            </td> -->

                            <ng-container *ngIf="column.property == 'name';else notName">
                                <td class="wrap" [attr.width]="column.columnWidth" [pEditableColumn]="organization"
                                    [pEditableColumnField]="'name'" [pEditableColumnRowIndex]="index">
                                    <ng-container *ngIf="!isEditing(organization.uuid); else editMode">
                                        <div class="organization-info">
                                          <a [routerLink]="['/organization', organization.uuid]" [innerHTML]="organization.name  | highlight:term"></a>
                                          <button pButton type="button" icon="pi pi-pencil" title="Edit"
                                            class="p-button-text edit-button"
                                            (click)="toggleEditingMode(organization.uuid)"></button>
                                        </div>
                                      </ng-container>
                                    <ng-template #editMode>
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <input pInputText type="text" [(ngModel)]="organization.name" (keydown)="disableNavigation($event)"/>
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                <a [routerLink]="['/organization', organization.uuid]"[innerHTML]="organization.name  | highlight:term"></a>
                                            </ng-template>
                                        </p-cellEditor>
                                    </ng-template>
                                </td>
                            </ng-container>

                            <ng-template #notName>
                                <ng-container *ngIf="column.property == 'accountOwner' ; else notAccountOwner ">
                                    <td class="wrap" [pEditableColumn]="organization"
                                        [pEditableColumnField]="'accountOwner'" [pEditableColumnRowIndex]="index">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <span class="p-column-title">{{ appUtil.getCustomLabel(column,
                                                    customLabels) }}</span>
                                                <p-autoComplete [(ngModel)]="organization.accountOwner"
                                                    [forceSelection]="true" [suggestions]="filteredValues"
                                                    (completeMethod)="searchLookupValue($event ,column.property )"
                                                    field="name"></p-autoComplete>

                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                <span class="p-column-title">{{appUtil.getCustomLabel(column,
                                                    customLabels)}}</span>

                                                {{ getOutput(organization.accountOwner)}}
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                </ng-container>
                            </ng-template>
                            <ng-template #notAccountOwner>
                                <ng-container *ngIf="column.property == 'location' ; else notLocation ">
                                    <td class="wrap" [pEditableColumn]="organization" [pEditableColumnField]="'country'"
                                        [pEditableColumnRowIndex]="index">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <span class="p-column-title">{{ appUtil.getCustomLabel(column,
                                                    customLabels) }}</span>
                                                <p-autoComplete [(ngModel)]="organization.country"
                                                    [forceSelection]="true" [suggestions]="filteredValues"
                                                    (completeMethod)="searchLookupValue($event ,column.property)"
                                                    field="name"></p-autoComplete>

                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                <span class="p-column-title">{{appUtil.getCustomLabel(column,
                                                    customLabels)}}</span>

                                                {{ getOutput(organization.country)}}
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                </ng-container>
                            </ng-template>
                            <ng-template #notLocation>
                                <ng-container
                                    *ngIf="(column.property == 'revenueLookup' || column.property == 'leadSource'  || column.property == 'numberOfEmployeeLookup'); else notLookup ">
                                    <td class="wrap" [pEditableColumn]="organization"
                                        [pEditableColumnField]="column.property" [pEditableColumnRowIndex]="index">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <span class="p-column-title">{{ appUtil.getCustomLabel(column,
                                                    customLabels) }}</span>
                                                <look-autocomplete [multiple]="false"
                                                    [type]="getLookupType(column.property)"
                                                    [(ngModel)]="organization[column.property]"
                                                    [items]="getLookupItems(column.property)" tooltipPosition="top"
                                                    (keydown)="disableNavigation($event)">
                                                </look-autocomplete>
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                <span class="p-column-title">{{ appUtil.getCustomLabel(column,
                                                    customLabels) }}</span>
                                                {{ getOutputs(column.property, organization) }}
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                </ng-container>
                            </ng-template>
                            <ng-template #notLookup>
                                <ng-container *ngIf="(column.property == 'status' || column.property == 'createdDate' || column.property == 'lastModifiedDate'); else notStatus">
                                    <td class="wrap">
                                        {{ getOutputValue(column, organization) }}
                                    </td>               
                                </ng-container>
                            </ng-template>
                            <ng-template #notStatus>
                                <ng-container *ngIf="column.property == 'primaryContact'; else notPrimaryContact">
                                    <td class="wrap"> 
                                                <span *ngFor="let primaryContact of organization.primaryContact; let last = last;" class="lowercase">
                                                    <a [routerLink]="['/contact/', primaryContact.uuid]">{{primaryContact.email}}&nbsp;{{primaryContact.phone}}{{last?'':'; '}}</a>
                                                </span>                                         
                                    </td>
                                </ng-container>
                            </ng-template>
                            <ng-template #notPrimaryContact>
                                <ng-container *ngIf="column.property == 'level1';else notLevel1">
                                    <td class="wrap" [pEditableColumn]="organization" [pEditableColumnField]="'level1'"
                                        [pEditableColumnRowIndex]="index">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <span class="p-column-title">{{appUtil.getCustomLabel(column,
                                                    customLabels)}}</span>
                                                <p-multiSelect [multiple]="false"
                                                    [options]="classificationList.CATEGORY_LEVEL1"
                                                    [(ngModel)]="organization.classification.level1" optionLabel="name"
                                                    placeholder="Choose" appendTo="body" dataKey="uuid"
                                                    (keydown)="disableNavigation($event)"></p-multiSelect>
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                <span class="p-column-title">{{appUtil.getCustomLabel(column,
                                                    customLabels)}}</span>
                                                <span
                                                    *ngFor="let classi of organization.classification.level1; let last = last;"
                                                    pTooltip="{{classi.name}}" tooltipPosition="bottom">
                                                    {{classi.name }}{{last?'':','}}
                                                </span>
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                </ng-container>
                            </ng-template>
                            <ng-template #notLevel1>
                                <ng-container *ngIf="column.property == 'level2';else notLevel2">
                                    <td class="wrap" [pEditableColumn]="organization" [pEditableColumnField]="'level2'"
                                        [pEditableColumnRowIndex]="index">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <span class="p-column-title">{{appUtil.getCustomLabel(column,
                                                    customLabels)}}</span>
                                                <p-multiSelect [multiple]="false"
                                                    [options]="classificationList.CATEGORY_LEVEL2"
                                                    [(ngModel)]="organization.classification.level2" optionLabel="name"
                                                    placeholder="Choose" appendTo="body" dataKey="uuid"
                                                    (keydown)="disableNavigation($event)"></p-multiSelect>
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                <span class="p-column-title">{{appUtil.getCustomLabel(column,
                                                    customLabels)}}</span>
                                                <span
                                                    *ngFor="let classi of organization.classification.level2; let last = last;"
                                                    pTooltip="{{classi.name}}" tooltipPosition="bottom">
                                                    {{classi.name }}{{last?'':','}}
                                                </span>
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                </ng-container>
                            </ng-template>
                            <ng-template #notLevel2>
                                <ng-container *ngIf="column.property == 'level3';else notLevel3">
                                    <td class="wrap" [pEditableColumn]="organization" [pEditableColumnField]="'level3'"
                                        [pEditableColumnRowIndex]="index">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <span class="p-column-title">{{appUtil.getCustomLabel(column,
                                                    customLabels)}}</span>
                                                <p-multiSelect [multiple]="false"
                                                    [options]="classificationList.CATEGORY_LEVEL3"
                                                    [(ngModel)]="organization.classification.level3" optionLabel="name"
                                                    placeholder="Choose" appendTo="body" dataKey="uuid"
                                                    (keydown)="disableNavigation($event)"></p-multiSelect>
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                <span class="p-column-title">{{appUtil.getCustomLabel(column,
                                                    customLabels)}}</span>
                                                <span
                                                    *ngFor="let classi of organization.classification.level3; let last = last;"
                                                    pTooltip="{{classi.name}}" tooltipPosition="bottom">
                                                    {{classi.name }}{{last?'':','}}
                                                </span>
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                </ng-container>
                            </ng-template>
                            <ng-template #notLevel3>
                                <ng-container *ngIf="column.property == 'vertical';else notVertical">
                                    <td class="wrap" [pEditableColumn]="organization"
                                        [pEditableColumnField]="'vertical'" [pEditableColumnRowIndex]="index">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <span class="p-column-title">{{appUtil.getCustomLabel(column,
                                                    customLabels)}}</span>
                                                <p-multiSelect [options]="classificationList.VERTICAL"
                                                    [(ngModel)]="organization.classification.vertical"
                                                    optionLabel="name" placeholder="Choose" appendTo="body"
                                                    dataKey="uuid" (keydown)="disableNavigation($event)">
                                                </p-multiSelect>
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                <span class="p-column-title">{{appUtil.getCustomLabel(column,
                                                    customLabels)}}</span>
                                                <span
                                                    *ngFor="let classi of organization.classification.vertical; let last = last;"
                                                    pTooltip="{{classi.name}}" tooltipPosition="bottom">
                                                    {{classi.name }}{{last?'':','}}
                                                </span>
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                </ng-container>
                            </ng-template>
                            <ng-template #notVertical>
                                <ng-container *ngIf="column.property == 'ecosystem';else notEcosystem">
                                    <td class="wrap" [pEditableColumn]="organization"
                                        [pEditableColumnField]="'ecosystem'" [pEditableColumnRowIndex]="index">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <span class="p-column-title">{{appUtil.getCustomLabel(column,
                                                    customLabels)}}</span>
                                                <p-multiSelect [options]="classificationList.ECOSYSTEM"
                                                    [(ngModel)]="organization.classification.ecosystem"
                                                    optionLabel="name" placeholder="Choose" appendTo="body"
                                                    dataKey="uuid" (keydown)="disableNavigation($event)">
                                                </p-multiSelect>
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                <span class="p-column-title">{{appUtil.getCustomLabel(column,
                                                    customLabels)}}</span>
                                                <span
                                                    *ngFor="let classi of organization.classification.ecosystem; let last = last;"
                                                    pTooltip="{{classi.name}}" tooltipPosition="bottom">
                                                    {{classi.name }}{{last?'':','}}
                                                </span>
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                </ng-container>
                            </ng-template>

                            <ng-template #notEcosystem>
                                <td class="wrap" [pEditableColumn]="organization"
                                    [pEditableColumnField]="column.property" [pEditableColumnRowIndex]="index">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <ng-container
                                                *ngIf="column.property == 'primaryContact'; else notPrimaryContact">
                                                <p-radioButton name="primaryContact" [value]="true"
                                                    (keydown)="disableNavigation($event)"
                                                    [(ngModel)]="organization[column.property]"
                                                    label="Y"></p-radioButton>
                                                <p-radioButton name="primaryContact" [value]="false"
                                                    (keydown)="disableNavigation($event)"
                                                    [(ngModel)]="organization[column.property]" label="N"
                                                    class="ml-2"></p-radioButton>
                                            </ng-container>
                                            <ng-template #notPrimaryContact>
                                                <ng-container
                                                    *ngIf="column.property == 'companyType'; else notCompanyType">
                                                    <p-dropdown [options]="column.searchOptions" optionLabel="name"
                                                        [(ngModel)]="organization.companyType" dataKey="uuid"
                                                        [showClear]="true"></p-dropdown>
                                                </ng-container>
                                            </ng-template>
                                            <ng-template #notCompanyType>
                                                <ng-container
                                                    *ngIf="column.property == 'parentCompany'; else notParentCompany">
                                                    <tbp-autocomplete [multiple]="false"
                                                        [(ngModel)]="organization.parentCompany" [items]=""
                                                        [type]="'Account'" tooltipPosition="top">
                                                    </tbp-autocomplete>
                                                </ng-container>
                                            </ng-template>
                                            <ng-template #notParentCompany>
                                                <ng-container *ngIf="column.property == 'website'; else notWebsite">
                                                    <input pInputText type="text" [(ngModel)]="organization.website"   (keydown)="disableNavigation($event)"
                                                    [ngClass]="{'invalid-website': !isValidUrl(organization.website)}">
                                                <div *ngIf="!isValidUrl(organization.website)" style="color: red;"   class="invalid-feedback">Invalid website URL(http://www.example.com)
                                                </div>
                                                </ng-container>
                                            </ng-template>
                                            <ng-template #notWebsite>
                                                <ng-container
                                                    *ngIf="( column.property=='description'); else notDescription">
                                                    <p-dialog [visible]="true"
                                                        [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
                                                        [style]="{width: '50vw'}" [closable]="false" [modal]="true">
                                                        <ng-template pTemplate="header">
                                                            <div class="p-fluid formgrid grid">
                                                                <div class="field col-12">
                                                                    <strong>{{organization.name}}:</strong>
                                                                    {{column.label}}
                                                                </div>
                                                            </div>
                                                        </ng-template>
                                                        <ng-template pTemplate="content">
                                                            <editor [(ngModel)]="organization[column.property]"
                                                                [init]="appUtil.getInitData(180,null)">
                                                            </editor>
                                                        </ng-template>
                                                        <ng-template pTemplate="footer">
                                                            <button pButton pRipple label="Save" icon="pi pi-check"
                                                                class="p-button-text"
                                                                (click)="editorAction($event, organization, column.property, false)"></button>
                                                            <button pButton pRipple label="Close" class="p-button-text"
                                                                (click)="editorAction($event, organization, column.property, true)"></button>
                                                        </ng-template>
                                                    </p-dialog>
                                                </ng-container>
                                            </ng-template>
                                            <ng-template #notDescription>
                                                <input pInputText type="text" mode="text"
                                                    [(ngModel)]="organization[column.property]">
                                            </ng-template>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <span class="p-column-title">{{appUtil.getCustomLabel(column,
                                                customLabels)}}</span>
                                            <ng-container
                                                *ngIf="column.property == 'primaryContact' ; else notPrimaryContact">
                                                {{organization[column.property] ===
                                                true?'Y':(organization[column.property] ===
                                                false?'N':'')}}
                                            </ng-container>
                                            <ng-template #notPrimaryContact>
                                                <ng-container
                                                    *ngIf="column.property == 'companyType' ; else notCompanyType">
                                                    {{getOutput(organization.companyType)}}
                                                </ng-container>
                                            </ng-template>
                                            <ng-template #notCompanyType>
                                                <ng-container
                                                    *ngIf="column.property == 'parentCompany'; else notParentCompany">
                                                    {{getOutput(organization.parentCompany)}}
                                                </ng-container>
                                            </ng-template>
                                            <ng-template #notParentCompany>
                                                <ng-container
                                                    *ngIf="column.property == 'website'; else notWebsite">
                                                 {{organization.website}}
                                                </ng-container>
                                            </ng-template>
                                            <ng-template #notWebsite>
                                                <ng-container
                                                    *ngIf=" column.property=='description' ; else notDescription ">
                                                    <span class="limitedText limitText"
                                                        [innerHTML]="organization[column.property]"></span>
                                                    <a href="javascript:"
                                                        (click)="appUtil.showHideClampedContent($event)"
                                                        class="moreless hidden ml-2 p-button-danger"
                                                        style="float:right;display:block;">More</a>
                                                </ng-container>
                                            </ng-template>

                                            <ng-template #notDescription>
                                                <ng-container *ngIf="column.property != 'name' || column.property != 'description' || column.property != 'ecosystem' 
                                          || (column.property != 'accountOwner') ">
                                                    {{organization[column.property]}}
                                                </ng-container>
                                            </ng-template>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>

                            </ng-template>

                        </ng-container>


                        <td>
                            <span class="p-column-title">Action</span>
                            <button type="button" pButton icon="pi pi-list" *ngIf="!showCheckbox"
                                (click)="setCurrentItem(organization); menu.toggle($event)"></button>
                            <button type="button" pButton pRipple [pRowToggler]="organization"
                                (click)="getContacts(organization)" label="Contacts"
                                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                                *ngIf="addContact"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="rowexpansion" let-organization>
                    <tr>
                        <td colspan="12" style="background-color:#64748B">
                            <div class="p-1">
                                <p-table [value]="organization?.users" dataKey="uuid">
                                    <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 3rem"></th>
                        <th pSortableColumn="name">Name</th>
                        <th pSortableColumn="Email">Email</th>
                        <th pSortableColumn="Title">Title</th>
                        <th pSortableColumn="primaryContact">Primary Contact</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-user>
                    <tr>
                        <td>
                            <p-checkbox [value]="user.uuid" [(ngModel)]="organization.selectedUUIDs"
                                (onChange)="processContact($event, organization, user)"></p-checkbox>
                        </td>
                        <td>{{user.name}}</td>
                        <td>{{user.email}}</td>
                        <td>{{user.title}}</td>
                        <td>{{user.primaryContact? 'Yes':'No'}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="5">There are no contacts for this organization yet.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        </td>
        </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="20">
                    <span *ngIf="term != ''">Search did not return any result. Try relaxing the criteria or use
                        wildcard [*, ?]</span>
                    <span *ngIf="term == ''">No organizations found</span>
                </td>
            </tr>
        </ng-template>
        </p-table>
    </div>
</div>
</div>
<p-toast></p-toast>
<app-upload-excel (onSuccess)="reload()" [(show)]="uploadPop" url="organization/upload" title="Account Upload">
</app-upload-excel>
<p-menu #menu [popup]="true" [model]="orgItems"></p-menu>
<note-edit [(show)]="notesDialog" noteType="organization" [parent]="currentMenuData"></note-edit>
<task-edit [(show)]="tasksDialog" taskType="organization" [parentUuid]="currentMenuData.uuid"
    [parent]="currentMenuData"></task-edit>
<user-popup-add [(show)]="showForm" [organization]="currentMenuData" [canChangeOrganization]="false"></user-popup-add>
<!-- <p-overlayPanel #advsrch [dismissable]="true" [showCloseIcon]="true">
    <ng-template pTemplate>
        <tbp-query-builder [fields]="queryBuilderFields" (onSearch)="advSearch($event,0)" [query]="queryBuilderQuery">
        </tbp-query-builder>
    </ng-template>
</p-overlayPanel> -->
<p-dialog [(visible)]="showAddDialog" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}"
    *ngIf="addContact">
    <ng-template pTemplate="header">
        <div class="p-fluid formgrid grid">
            <div class="field col-12">
                <i class="pi pi-exclamation-triangle mr-2"></i>Are you sure that you want to add the contacts listed
                below?
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="content">
        <div class="p-fluid formgrid grid">
            <div class="field col-12">
                <p-table [autoLayout]="true" sortField="name" sortMode="single" [value]="selectedOrganizations"
                    styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm"
                    [rowHover]="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="name">Account Name</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Title</th>
                            <th>Action</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-organization>
                        <ng-container *ngIf="organization.selectedUsers && organization.selectedUsers.length > 0">
                            <tr *ngFor="let user of organization.selectedUsers; let i = index">
                                <td class="wrap" [attr.rowspan]="organization.selectedUsers.length" *ngIf="i == 0">
                                    <span style="display: block;">{{organization.name}}</span>
                                    <span><button type="button" pButton icon="pi pi-minus-circle"
                                            (click)="remove(organization);"></button></span>
                                </td>
                                <td class="wrap capitalize">
                                    <span class="p-column-title">Name</span>{{user.name}}
                                </td>
                                <td class="wrap lowercase">
                                    <span class="p-column-title">Email</span>
                                    <a href="mailto:{{user.email}}">{{user.email}}</a>
                                </td>
                                <td class="wrap">
                                    <span class="p-column-title">Title</span>
                                    {{user.title}}
                                </td>
                                <td>
                                    <span class="p-column-title">Action</span>
                                    <button type="button" pButton icon="pi pi-minus-circle"
                                        (click)="removeContact(organization,user);"></button>
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="!organization.selectedUsers || organization.selectedUsers == 0">
                            <tr>
                                <td class="wrap">
                                    <span style="display: block;">{{organization.name}}</span>
                                </td>
                                <td class="wrap capitalize">
                                    <span class="p-column-title">Name</span>
                                </td>
                                <td class="wrap lowercase">
                                    <span class="p-column-title">Email</span>
                                </td>
                                <td class="wrap">
                                    <span class="p-column-title">Title</span>
                                </td>
                                <td>
                                    <span class="p-column-title">Action</span>
                                    <button type="button" pButton icon="pi pi-minus-circle"
                                        (click)="remove(organization);"></button>
                                </td>
                            </tr>
                        </ng-container>

                    </ng-template>
                </p-table>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Add" icon="pi pi-check" class="p-button-text" (click)="addToSource()"></button>
        <button pButton pRipple label="Close" class="p-button-text" (click)="showAddDialog = false"></button>
    </ng-template>
</p-dialog>
<p-dialog [(visible)]="showAddDialog" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}"
    *ngIf="!addContact && showCheckbox">
    <ng-template pTemplate="header">
        <div class="p-fluid formgrid grid">
            <div class="field col-12">
                <i class="pi pi-exclamation-triangle mr-2"></i>Are you sure that you want to add the organizations
                listed below?
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="content">
        <div class="p-fluid formgrid grid">
            <div class="field col-12">
                <p-table [autoLayout]="true" sortField="name" sortMode="single" [value]="selectedOrganizations"
                    styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm"
                    [rowHover]="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="name">Name</th>
                            <th>Type</th>
                            <th>Website</th>
                            <th>Level1</th>
                            <th>Action</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-organization>
                        <tr>
                            <td class="wrap">
                                <span class="p-column-title">Name</span>{{organization.name}}
                                <span><button type="button" pButton icon="pi pi-minus-circle"
                                        (click)="remove(organization);"></button></span>
                            </td>
                            <td class="wrap">
                                <span class="p-column-title">Type</span>
                                {{organization.companyType.replace('_',' ') | titlecase}}
                            </td>
                            <td class="wrap">
                                <span class="p-column-title">Location</span>
                                <span class="limitedText limitText">
                                    <span *ngIf="organization.state != null">{{organization.state | titlecase}}</span>
                                    <span *ngIf="organization.country != null">
                                        <ng-container *ngIf="organization.state != null">,</ng-container>
                                        {{organization.country | titlecase}}
                                    </span>
                                </span>
                                <a href="javascript:" (click)="appUtil.showHideClampedContent($event)"
                                    class="moreless hidden ml-2 p-button-danger"
                                    style="float:right;display:block;">More</a>
                            </td>
                            <td class="wrap">
                                <span class="p-column-title">Website</span>
                                {{organization.website}}
                            </td>

                            <td class="wrap">
                                <span class="p-column-title">Level1</span>
                                <span class="limitedText limitText">
                                    <span
                                        *ngFor="let classification of organization.classification.level1; let last = last;">{{classification.name}}{{last?'':';'}}</span>
                                </span>
                                <a href="javascript:" (click)="appUtil.showHideClampedContent($event)"
                                    class="moreless hidden ml-2 p-button-danger"
                                    style="float:right;display:block;">More</a>
                            </td>

                            <td>
                                <span class="p-column-title">Action</span>
                                <button type="button" pButton icon="pi pi-minus-circle"
                                    (click)="remove(organization);"></button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Add" icon="pi pi-check" class="p-button-text" (click)="addToSource()"></button>
        <button pButton pRipple label="Close" class="p-button-text" (click)="showAddDialog = false"></button>
    </ng-template>
</p-dialog>