import { MenuModule } from 'primeng/menu';
import { TableModule } from 'primeng/table';
import { PanelModule } from 'primeng/panel';
import { ToastModule } from 'primeng/toast';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxModule } from 'primeng/checkbox';
import { TaskEditComponent } from './task-edit.component';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TaskToggleListComponent } from './task-toggle-list.component';
import { TaskRoutingModule } from './task-routing.module';
import { TbpAutocompleteModule } from '../_components/tbp-autocomplete/tbp-autocomplete.module';
import { RadioButtonModule } from 'primeng/radiobutton';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    declarations: [TaskEditComponent, TaskToggleListComponent],
    imports: [
        CommonModule,
        DialogModule,
        DropdownModule,
        FormsModule,
        InputTextModule,
        ButtonModule,
        InputTextareaModule,
        ReactiveFormsModule,
        CalendarModule,
        AutoCompleteModule,
        ToastModule,
        PanelModule,
        CheckboxModule,
        TableModule,
        MenuModule,
        TaskRoutingModule,
        TbpAutocompleteModule,
        RadioButtonModule,
        EditorModule,   
        SharedModule,
    ],
    exports: [
        TaskEditComponent,
        TaskToggleListComponent
    ]
})
export class TaskModule { }
