import { AppUtil } from '../_helpers/app.util';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Component, OnInit, ViewChild, NgZone, OnDestroy, ViewEncapsulation } from '@angular/core';
import { BreadcrumbService } from '../app.breadcrumb.service';
import { OrganizationService } from './organization.service';
import { Organization } from '../_models/organization';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from "../_services/data.service";
import { LazyLoadEvent, MenuItem } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { FieldInfo, makeFieldInfo } from '../_models';
import { SearchHeler } from '../_helpers';
import { DatePipe } from '@angular/common';
import { DomHandler } from 'primeng/dom';
interface AutoCompleteCompleteEvent {
    originalEvent: Event;
    query: string;
}
import { first, Subscription } from 'rxjs';
import { HelpService } from '../help/help.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-organizations-list',
    templateUrl: './organizations-list.component.html',
    styleUrls: ['./organizations-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [MessageService, DatePipe]
})

export class OrganizationsListComponent  implements OnInit, OnDestroy {
    private subscriptions: Subscription = new Subscription();
    organizations: Organization[];
    selectedOrganizations: Organization[];
    oldVal: any[] = [];
    pageSize: number;
    firstRow: number;
    sortField: string;
    sortOrder: number;
    @ViewChild('advsrch') advSrch: OverlayPanel;
    @ViewChild('dt') dataTable: Table;
    term: string;
    loading: boolean = false;
    orgItems: MenuItem[];
    currentMenuData: any;
    totalRecords: any ;
    selectedStatus: string;
    scrollTop: string;
    nextSearchAfter: number | null = null;
    nextFieldId:number | null = null;
    firstValue:number | null = null;
    searchAfterStack: { searchAfter: number; fieldId: number }[] = [];
    lookupList: any = {
        ACCOUNT_SOURCE: [], REVENUE: [], ORG_TYPE_INVESTOR: [], No_OF_EMPLOYEES: [],
        ORG_TYPE_LENDER: [], ORG_TYPE_OTHER: []
    };
    classificationList: any = {
        CATEGORY_LEVEL1: [], CATEGORY_LEVEL2: [], CATEGORY_LEVEL3: [],
        VERTICAL: [], ECOSYSTEM: []
    };
    uploadPop: boolean = false;
    countries = [];
    uploadItems: MenuItem[];
    status: any = [{ name: 'Select Status', code: '' }, { name: 'Account Manager Assigned', code: 'WITH_OWNER' },
    { name: 'Account Manager Not Assigned', code: 'WITHOUT_OWNER' }, { name: 'Pending Verification', code: 'PENDING_VERIFICATION' }];
    sortOptions: any[] = [{ name: 'Name', 'code': 'name' }, { name: 'Type', 'code': 'companyType' }, { name: 'Location', 'code': 'location' },
    { name: 'Revenue', 'code': 'revenueLookup' }, { name: 'Modification Date', 'code': 'lastModifiedDate' },
    { name: 'Relevance', 'code': 'relevance' }]
    globalSortField: any = null;
    globalSortOrder: number;
    tasksDialog: boolean = false;
    notesDialog: boolean = false;
    showForm: boolean = false;
    queryBuilderQuery: any = {};
    currentQuery: string = '';
    isAdvanceSearch: boolean = false;
    queryBuilderFields: any = {};
    changeOrganization: boolean = false;
    source: string = null;
    sourceuuid: string = null;
    showCheckbox: boolean = false;
    searchUrl: string = '/organizations';
    showAddDialog: boolean = false;
    addContact: boolean = false;
    tbpEmployees: any[] = [];
    organizationResults: any;
    selectedAccountOwner: any;
    filteredValues: any[] | undefined;
    filteredLocation: any[] | undefined;
    editingModes: { [key: string]: boolean } = {};
    selectedColumns: any[];
    fieldInfo: FieldInfo[];
    previousUrl: string | null = null;
    currentUrl: string | null = null;
    searchTerm:string |null = null;
    constructor(
        private organizationService: OrganizationService, private dataService: DataService,private helpService:HelpService,
        private breadcrumbService: BreadcrumbService, private router: Router, private route: ActivatedRoute, private datePipe: DatePipe,
        private messageService: MessageService, public appUtil: AppUtil, public searchHelper: SearchHeler,  private ngZone: NgZone) {
        this.term = '';
        this.selectedStatus = '';
        this.currentMenuData = { id: '' };
        this.orgItems = [];
        this.uploadItems = [
            {
                label: 'Account Template', command: () => {
                    this.appUtil.downloadTemplate('account-template.xlsx');
                }
            }
        ];
        this.breadcrumbService.setItems([{ label: 'Account Listing' }]);
        this.pageSize = this.appUtil.pageSize;
        this.firstRow = 0;
        this.sortField = 'lastModifiedDate';
        this.globalSortField = this.sortField;
        this.sortOrder = -1;
        this.globalSortOrder = this.sortOrder;
    }


    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            this.searchTerm = params['search_term'] || null;
        });
        this.previousUrl = this.helpService.getPreviousUrl();
        this.currentUrl = this.helpService.getCurrentUrl();
        const storedScrollTop = localStorage.getItem('ORGANIZATIONSCROLL');
        if (storedScrollTop) {
            window.scrollTo(0, parseInt(storedScrollTop));
        }
        window.addEventListener('scroll', this.saveScrollPosition);
    
        this.classificationList = this.route.snapshot.data['classificationList'];
        this.tbpEmployees = this.route.snapshot.data['tbpEmployees'];
        this.lookupList = this.route.snapshot.data['lookupList'];        
        this.countries = this.route.snapshot.data['countries'];
        let tmpFieldInfo: FieldInfo[] = [
            makeFieldInfo({ property: "name", label: 'Name', type: 'string', searchType: 'text', forSearchTermQuery: true, columnIndex: 1, visible: 'A', linkPrefix: '/organization/', linkType: "p" }),
            makeFieldInfo({ property: "companyType", label: 'Type', type: 'string', sortable: true, searchType: 'exact', columnIndex: 2, searchOptions: [{ 'name': 'Operating Company', 'uuid': 'OPERATING_COMPANY' }, { 'name': 'Investor', 'uuid': 'INVESTOR' }, { 'name': 'Lender', 'uuid': 'LENDER' }, { 'name': 'Other', 'uuid': 'OTHER' }] }),
            makeFieldInfo({ property: "location", label: 'Location', type: 'string', columnIndex: 3, searchType: 'text', sortable: true }),
            makeFieldInfo({ property: "website", label: 'Website', type: 'string', searchable: false, searchType: 'text', sortable: false, columnIndex: 4 }),
            makeFieldInfo({ property: "level1", label: 'Level1', type: 'classification', searchType: 'exact', searchOptions: this.classificationList.CATEGORY_LEVEL1, sortable: false, columnIndex: 5 }),
            makeFieldInfo({ property: "primaryContact", label: "Primary Contacts", type: "string", searchType: "text", sortable: false, columnIndex: 6 }),
            makeFieldInfo({ property: "revenueLookup", label: 'Revenue', type: 'range', searchType: 'exact', labelProperty: "value", valueProperty: "value", sortable: true, columnIndex: 7, searchOptions: this.lookupList.REVENUE }),
            makeFieldInfo({ property: "level2", label: 'Level2', type: 'classification', searchType: 'exact', visible: 'F', searchOptions: this.classificationList.CATEGORY_LEVEL2, sortable: false }),
            makeFieldInfo({ property: "level3", label: 'Level3', type: 'classification', searchType: 'exact', visible: 'F', searchOptions: this.classificationList.CATEGORY_LEVEL3, sortable: false }),
            makeFieldInfo({ property: "vertical", label: 'Vertical', type: 'classification', searchType: 'exact', visible: 'F', searchOptions: this.classificationList.VERTICAL, sortable: false }),
            makeFieldInfo({ property: "ecosystem", label: 'Ecosystem', type: 'classification', searchType: 'exact', visible: 'F', searchOptions: this.classificationList.ECOSYSTEM, sortable: false }),
            makeFieldInfo({ property: "accountOwner", label: 'Account Owner', type: 'string', sortable: true, searchType: 'text', visible: 'F', linkPrefix: '/user/', linkType: "s" }),
            makeFieldInfo({ property: "leadSource", label: 'Lead Source', type: 'string', searchType: 'exact', visible: 'F', labelProperty: "value", valueProperty: "value", searchOptions: this.lookupList.ACCOUNT_SOURCE }),
            makeFieldInfo({ property: "lastModifiedDate", label: "Modification Date", type: "date", searchType: "date" }),
            makeFieldInfo({ property: "createdDate", label: "Creation Date", type: "date", searchType: "date", visible: 'F' }),
            makeFieldInfo({ property: "relevance", label: "Relevance", type: "date", searchable: false, visible: 'H' }),
            makeFieldInfo({ property: "status", label: "Status", type: "enum", searchType: "exact", visible: 'F', searchOptions: [{ 'name': 'Activation Pending', 'uuid': 'PENDING_ACTIVATION' }, { 'name': 'Active', 'uuid': 'ACTIVE' }, { 'name': 'Revoked', 'uuid': 'REVOKED' }] }),
            makeFieldInfo({ property: "isPubliclyTraded", label: "Is Publicly Traded", type: "boolean", searchType: "boolean", visible: 'F', sortable: false }),
            makeFieldInfo({ property: "numberOfEmployeeLookup", label: "Number Of Employees", type: "range", searchType: "exact", visible: 'F', sortable: false, labelProperty: "value", valueProperty: "value", searchOptions: this.lookupList.No_OF_EMPLOYEES }),
            makeFieldInfo({ property: "parentCompany", label: "Parent Company", type: "string", searchType: "text", sortable: false, visible: 'F', linkPrefix: '/organization/', linkType: "s" }),
            makeFieldInfo({ property: "description", label: "Description", type: "string", searchType: 'text', sortable: false, visible: 'F' })
        ]
        this.fieldInfo = tmpFieldInfo;

        this.route.params.subscribe(data => {
            this.source = data.source == '' ? null : data.source;
            this.searchUrl = (data.source == '' ? '' : '/' + data.source) + (data.sourceuuid == '' ? '' : '/' + data.sourceuuid)
                + '/organization' + (data.showCheckbox == 'true' ? '/add' : '');
            this.sourceuuid = data.sourceuuid == '' ? null : data.sourceuuid;
            this.showCheckbox = data.showCheckbox == 'true' ? true : false;
            if ((data.source == 'buyside' || data.source == 'sellside') && this.showCheckbox) {
                this.addContact = true;
            }
            else {
                this.addContact = false;
            }


            //   let tmpQueryFields = {
            //     name: { name: 'Name', type: 'string', searchType: 'textsearch' },
            //     description: { name: 'Description', type: 'string', searchType: 'textsearch' },
            //     companyType: { name: 'Type', type: 'string', searchType: 'textsearch' },
            //     level1: {
            //         name: 'Level1', type: 'category', searchType: 'exactsearch',
            //         options: this.classificationList.CATEGORY_LEVEL1.map((cat) => {
            //             return { name: cat.name, value: cat.uuid }
            //         })
            //     },
            //     level2: {
            //         name: 'Level2', type: 'category', searchType: 'exactsearch',
            //         options: this.classificationList.CATEGORY_LEVEL2.map((cat) => {
            //             return { name: cat.name, value: cat.uuid }
            //         })
            //     },
            //     level3: {
            //         name: 'Level3', type: 'category', searchType: 'exactsearch',
            //         options: this.classificationList.CATEGORY_LEVEL3.map((cat) => {
            //             return { name: cat.name, value: cat.uuid }
            //         })
            //     },
            //     vertical: {
            //         name: 'Vertical', type: 'category', searchType: 'exactsearch',
            //         options: this.classificationList.VERTICAL.map((cat) => {
            //             return { name: cat.name, value: cat.uuid }
            //         })
            //     },
            //     ecosystem: {
            //         name: 'Ecosystem', type: 'category', searchType: 'exactsearch',
            //         options: this.classificationList.ECOSYSTEM.map((cat) => {
            //             return { name: cat.name, value: cat.uuid }
            //         })
            //     },
            //     accountOwner: { name: 'Account Owner', type: 'string', searchType: 'textsearch' },
            //     leadSource: { name: 'Lead Source', type: 'string', searchType: 'textsearch' },
            //     revenue: { name: 'Revenue', type: 'number', searchType: 'rangesearch' },
            // }

            //   this.queryBuilderFields = Object.keys(tmpQueryFields).sort(function (a, b) {
            //     var nameA = tmpQueryFields[a].name.toUpperCase(); // ignore upper and lowercase
            //     var nameB = tmpQueryFields[b].name.toUpperCase(); // ignore upper and lowercase
            //     return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
            // }).reduce(function (result, key) {
            //     result[key] = tmpQueryFields[key];
            //     return result;
            // }, {});
            // this.queryBuilderQuery = {};
            // this.appUtil.initializeSearch(this);
        });
    }

    advSearch(event, first) {
        this.advSrch.hide();
        this.isAdvanceSearch = true;
       // this.appUtil.search(this, this.searchUrl, true, first, event.query, true);
    }
    _search(e) {
        this.term = e.searchTerm;
        let searchParams = this.searchHelper.prepareSearchParams(e.searchTerm, e.filter, e.sortField, e.sortOrder, e.first, e.rows, this.fieldInfo);
        if (searchParams != null && searchParams !== undefined && searchParams['rootRule']['rules'][0] !== undefined
            && searchParams['rootRule']['rules'][0].field === 'location') {
            searchParams['rootRule'].condition = ((searchParams['rootRule']['rules'][0].operator === 'matches' || searchParams['rootRule']['rules'][0].operator === 'exists') ? 'or' : 'and');
            searchParams['rootRule']['rules'][0].field = 'country';
            searchParams['rootRule']['rules'].push({ 'searchType': 'text', 'field': 'state', 'operator': (searchParams['rootRule']['rules'][0].operator), 'value': searchParams['rootRule']['rules'][0].value });
            searchParams['rootRule']['rules'][1].field = 'state';
        }
        this.loading = true;
    
        if (this.searchTerm != '' || this.searchTerm != null) {
            searchParams['query'] = this.searchTerm;
        }
        if(!this.appUtil.getPagination(this.totalRecords)){
            let isGoingForward = e.first > this.firstValue;
        if (isGoingForward && this.term == null && e.first - this.firstValue == e.rows) {
            if (this.searchAfterStack.length === 0 || this.searchAfterStack[this.searchAfterStack.length - 1].searchAfter !== this.nextSearchAfter) {
                this.searchAfterStack.push({ searchAfter: this.nextSearchAfter, fieldId: this.nextFieldId }); 
            }
            searchParams['nextSearchAfter'] = this.nextSearchAfter;
            searchParams['nextFieldId'] = this.nextFieldId;
        } else if (!isGoingForward && this.searchAfterStack.length > 0 && this.term == null && e.first - this.firstValue == e.rows) {
            this.searchAfterStack.pop(); 
            const prevSearchAfter = this.searchAfterStack.length > 0 ? this.searchAfterStack[this.searchAfterStack.length - 1] : null;
            if (prevSearchAfter) {
                searchParams['nextSearchAfter'] = prevSearchAfter.searchAfter;
                searchParams['nextFieldId'] = prevSearchAfter.fieldId;
            }
        }
        this.firstValue = e.first;
    }
        this.organizationService.searchOrganization(searchParams).subscribe(
            data => {
                let response = data as any;
                if(!this.appUtil.getPagination(response.totalRecords) )  {
                    this.totalRecords = environment.customTotalRecords;
                }else{
                    this.totalRecords = response.totalRecords;
                }
                this.organizations = response.records;
                this.nextSearchAfter = response.nextSearchAfter;
                this.nextFieldId = response.nextFieldId;
                this.loading = false;
                this.appUtil.reinitializeShowMore();
    
                if (e.searchTerm == null) {
                    const localeCompareKey = localStorage.getItem('ORGANIZATIONSCROLL');
                    if (!this.searchUrl.includes("add")) {
                        if (localeCompareKey) {
                            this.ngZone.runOutsideAngular(() => {
                                setTimeout(() => {
                                    window.scrollTo(0, parseInt(localeCompareKey));
                                }, 0);
                            });
                        }
                    }
                }
            }
        );
    }
    
    reload() {
        let e: LazyLoadEvent = { first: this.firstRow, rows: this.pageSize, sortField: this.sortField, sortOrder: this.sortOrder };
        this._search(e);
    }

    setCurrentItem(organization) {
        let items = [
            { label: 'Edit', icon: 'pi pi-plus', command: (event) => { this.appUtil.routeToEditURL('organization', this.currentMenuData.uuid); } },
            { label: 'Merge', icon: 'pi pi-link', command: (event) => { this.appUtil.routeToMergeURL('organization', this.currentMenuData.uuid); } },
            {
                label: 'New Task',
                icon: 'pi pi-plus',
                command: (event) => {
                    this.newTask();
                }
            },
            {
                label: 'Notes & Attachments',
                icon: 'pi pi-plus',
                command: (event) => {
                    this.newNote();
                }
            },
            {
                icon: 'pi pi-id-card',
                label: 'New Engagement',
                command: (event) => {
                    this.newEngagement();
                }
            },
            {
                icon: 'pi pi-plus',
                label: 'New Contact',
                command: (event) => {
                    this.newContact();
                }
            },
            {
                icon: 'pi pi-id-card',
                label: 'New Sell Side Screen',
                command: (event) => {
                    this.newSellSide();
                }
            },
            {
                icon: 'pi pi-credit-card',
                label: ' New Buy Side Screen',
                command: (event) => {
                    this.newBuySide();
                }
            }
        ];
        this.currentMenuData = organization;
        this.orgItems = items;
    }

    newEngagement() {
        this.dataService.setData('org-', this.currentMenuData);
        this.router.navigate(['/engagement/new']);
    }

    newSellSide() {
        this.dataService.setData('org-', this.currentMenuData);
        this.router.navigate(['/sell-side/new'], { queryParams: { 'account': this.currentMenuData.uuid } });
    }

    newBuySide() {
        this.dataService.setData('org-', this.currentMenuData);
        this.router.navigate(['/buy-side/new'], { queryParams: { 'account': this.currentMenuData.uuid } });
    }

    newContact() {
        this.showForm = true;
    }

    newTask() {
        this.tasksDialog = true;
    }
    newNote() {
        this.notesDialog = true;
    }

    accountUpload() {
        this.uploadPop = true;
    }

    changeSortField() {
        this.sortField = this.globalSortField;
    }

    changeSortOrder(sortOrder) {
        this.globalSortOrder = sortOrder;
        this.sortOrder = sortOrder;
    }

    confirmAdd() {
        this.showAddDialog = true;
    }

    addToSource() {
        this.dataService.setData('organizations-' + this.source + '-' + this.sourceuuid, this.selectedOrganizations);

        switch (this.source) {
            case 'buyside':
                this.router.navigate(['/buy-side/' + this.sourceuuid + '/prospects'])
                break;
            case 'sellside':
                this.router.navigate(['/sell-side/' + this.sourceuuid + '/prospects'])
                break;
        }

    }

    remove(org) {
        this.selectedOrganizations = this.appUtil.deleteItem(this.selectedOrganizations, org);
        if (this.addContact) {
            org.selectedUsers = [];
            org.selectedUUIDs = [];
        }
        if (this.selectedOrganizations.length == 0) {
            this.showAddDialog = false;
        }
    }

    removeContact(org, user) {
        org.selectedUsers = this.appUtil.deleteItem(org.selectedUsers, user);
        org.selectedUUIDs = this.appUtil.deleteItem(org.selectedUUIDs, user.uuid);
    }

    getContacts(organization) {
        if (!organization.users) {
            var searchParams = {};
            searchParams['page'] = 0;
            searchParams['pageSize'] = 25;
            var sortBy = []
            sortBy[sortBy.length] = { "property": "primaryContact", "direction": "Desc" };
            searchParams['sortDetails'] = sortBy;
            this.organizationService.getContacts(organization.uuid, searchParams).subscribe(data => {
                let response = data as any;
                organization.users = response.records;
                let org;
                if (this.selectedOrganizations) {
                    org = this.appUtil.getObject(this.selectedOrganizations, organization);
                }

                if (org && org.selectedUsers) {
                    organization.selectedUsers = org.selectedUsers;
                    organization.selectedUUIDs = org.selectedUUIDs;
                }
                else {
                    organization.selectedUsers = [];
                    organization.selectedUUIDs = [];
                }
            });
        }
    }

    processContact($event, organization, user) {
        if (this.appUtil.getObject(organization.selectedUUIDs, user.uuid) != null) {//checked
            organization.selectedUsers.push(user);
            if (!this.selectedOrganizations) {
                this.selectedOrganizations = [];
            }
            if (!this.appUtil.getObject(this.selectedOrganizations, organization)) {
                this.selectedOrganizations = [... this.selectedOrganizations, organization];
            }
        }
        else {//unchecked
            this.removeContact(organization, user);
        }
    }

    processRowSelection($event, selected: boolean = true) {
        if (!selected) {
            $event.data.selectedUUIDs = [];
            $event.data.selectedUsers = [];
        }
    }

    processHeaderSelection($event) {
        if (!$event.checked) {
            if (this.organizations) {
                this.organizations.forEach((org: any) => {
                    org.selectedUUIDs = []
                    org.selectedUsers = [];
                });
            }
        }
    }


    searchLookupValue(event: AutoCompleteCompleteEvent, property: string) {
        let filtered: any[] = [];
        let query = event.query;
        let sources: any[] = [];
        if (property == 'accountOwner') {
            sources = this.tbpEmployees;
        } if (property == 'location') {
            sources = this.countries;
        }
        for (let i = 0; i < (sources as any[]).length; i++) {
            let employee = (sources as any[])[i];
            if (employee.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                filtered.push(employee);
            }
        }
        this.filteredValues = filtered;
    }

    getOutput(input: any) {
        if (input && typeof input === 'object') {
            return input.name;
        }
        return input;
    }

    formatDate(date: any): string {
        return this.datePipe.transform(date, 'MM-dd-yyyy') || '';
    }
    getOutputValue(column, organization) {
        if (column.property == 'lastModifiedDate') {
            return this.datePipe.transform(organization.lastModifiedDate, 'MM-dd-yyyy') || '';
        }
        else if (column.property == 'createdDate') {
            return this.datePipe.transform(organization.createdDate, 'MM-dd-yyyy') || '';
        }
        else  if( column.property == 'status') {
            return organization.status;
        }
    }

    initEdit($event) {
        let oldValues = this.oldVal[$event.data.uuid];
        if (!oldValues) {
            oldValues = [];
            this.oldVal[$event.data.uuid] = oldValues;
        }

        if (['level1', 'level2', 'level3', 'vertical', 'ecosystem'].includes($event.field)) {
            oldValues[$event.field] = $event.data.classification[$event.field];


        } else {
            oldValues[$event.field] = $event.data[$event.field];
        }
    }
    editCancelled($event) {
        let oldValues = this.oldVal[$event.data.uuid];
        delete oldValues[$event.field];
    }

    editorAction(event, contact: any, field: string, cancelled: boolean) {
        if (cancelled) {
            this.editCancelled({ field, data: contact });
            this.reload();
        }
        else {
            this.editCompleted({ field, data: contact });
        }
        DomHandler.removeClass(this.dataTable.editingCell, 'p-cell-editing');
        this.dataTable.editingCell = null;
        this.dataTable.editingCellData = null;
        this.dataTable.editingCellField = null;
        this.dataTable.unbindDocumentEditListener();
        event.preventDefault();
        event.cancelBubble = true;
        event.stopPropagation();
        this.appUtil.reinitializeShowMore();
    }

    editCompleted($event) {
   
      if($event.field == 'name' && ($event.data[$event.field] =='' || $event.data[$event.field]==null)){
        this.messageService.add({severity: 'error', summary: 'Error', detail: 'Name is required'});
        this.reload();
        this.editingModes[$event.data.uuid] = false;
        return false ;  
      }

        let oldValues = this.oldVal[$event.data.uuid];
        interface DataToPost {
            level1?: string;
            level2?: string;
            level3?: string;
            vertical?: string;
            ecosystem?: string;
            [key: string]: any;
        }
        let dataToPost: DataToPost = { uuid: $event.data.uuid };
        if ($event.field == 'level1' || $event.field == 'level2' || $event.field == 'level3' || $event.field == 'vertical' || $event.field == 'ecosystem' ) {
            dataToPost['classification'] = $event.data.classification ? $event.data.classification: null; 
            $event.field = 'classification';
        } 
         else {
            dataToPost[$event.field] = $event.data[$event.field];
        }
        if ($event.field == 'country') {
            dataToPost[$event.field] =$event.data[$event.field]? $event.data[$event.field].name :null;
        }
        if ($event.field == 'companyType') {
            dataToPost[$event.field] = $event.data[$event.field].uuid;

        }
        if($event.field == 'leadSource'){
            dataToPost[$event.field] = $event.data[$event.field].value;
        } 

        if ($event.field  === 'website' && !this.isValidUrl($event.data[$event.field])){
          this.messageService.add({severity: 'error', summary: 'Error', detail: 'Please enter a valid URL'});
          this.reload();
            return false ;   
        }
         if (oldValues[$event.field] != $event.data[$event.field]) {
      
        this.organizationService.updateOrganizationList([$event.field], dataToPost).subscribe(
            data => {
                this.loading = false;
                this.editingModes[$event.data.uuid] = false;
                this.reload();
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: "Organization updated successful", life: 3000 });
            },
            error => {
                $event.data[$event.field] = oldValues[$event.field]
                let errors = error.error;
                if (errors.code && errors.code == 'VALIDATION_ERROR') {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: errors.subErrors[0].message, life: 3000 });
                }
            }
        )
          }
        delete oldValues[$event.field];
    }
    getLookupType(columnProperty: string): string {
        if (columnProperty === 'revenueLookup') {
            return 'REVENUE';
        }
        if (columnProperty === 'leadSource') {
            return 'ACCOUNT_SOURCE';
        }
        if (columnProperty === 'numberOfEmployeeLookup') {
            return 'No_OF_EMPLOYEES';
        }
    }
    getLookupItems(columnProperty: string): any[] {
        if (columnProperty === 'revenueLookup') {
            return this.lookupList.REVENUE;
        }
        if (columnProperty === 'leadSource') {
            return this.lookupList.ACCOUNT_SOURCE;
        }
        if (columnProperty === 'numberOfEmployeeLookup') {
            return this.lookupList.No_OF_EMPLOYEES;
        }
    }
    getOutputs(columnProperty, organization) {
        const columnValue = organization[columnProperty];

        if (columnValue && typeof columnValue === 'object') {
            return columnValue.value;
        }
        return columnValue;
    }
    disableNavigation(event: any) {  
        if (event.key === "ArrowRight" || event.key === "Right"
            || event.key === "ArrowLeft" || event.key === "Left"
            || event.key === "ArrowUp" || event.key === "Up"
            || event.key === "ArrowDown" || event.key === "Down") {
            event.stopPropagation();
        }

    }
    toggleEditingMode(contactUUID: string) {
        this.editingModes[contactUUID] = !this.editingModes[contactUUID];
      }
      
      isEditing(contactUUID: string): boolean {
        return this.editingModes[contactUUID] || false; // Default to false if editing mode is not set
      }
    _updateSelectedColumns($event) {
        this.selectedColumns = $event;
    }
 

    isValidUrl(url: string): boolean {
        const pattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
        return pattern.test(url);
      }
      ngOnDestroy() {
        window.removeEventListener('scroll', this.saveScrollPosition);
        localStorage.setItem('ORGANIZATIONSCROLL', this.scrollTop);
    }

    saveScrollPosition = (): void => {
        this.scrollTop = window.scrollY.toString();  
    }
    return(){ 
          window.history.back();
    }
}