import { EntityClassification } from "./entity-classification";
import { LookupValue } from "./lookup-value";

export class User {
    constructor() {
        this.classification = new EntityClassification;
    }
    uuid?: string;
    firstName?: string;
    lastName?: string;
    accountName?: string;
    mobile?: string;
    phone?: string;
    email?: string;
    secondaryEmail?: string;
    leadSource?: string;
    emailOutput?: string;
    primaryContact?: string;
    sendSpotlightEmails?: string;
    sendMAOpportunityEmails?: string;
    assistantName?: string;
    assistantPhone?: string;
    assistantEmail?: string;
    description?: string;
    accountOwner?: User;
    address?: string;
    city?: string;
    state?: string;
    postalCode?: string;
    country?: string;
    classification?: EntityClassification;
    name?: string;
    title?: string;
    linkedInProfile?: string;
    isAdmin?: string;
    accreditedInvestor?: boolean;
    doNotSendUpdates?: string;
    status?: string;
    hasCredentials?: boolean;
    lookupValue?: string;
    tags?: string;
    masterProfile?: string;
    mailingList?: [string];
    createByUser?: User;
    createdDate?: User;
    modifiedByUser?: User;
    lastModifiedDate?: User;
    verified? :boolean;
}