<div class="grid table-demo">
    <div class="col-12">
        <p-toolbar styleClass="headerSticky">
            <ng-template pTemplate="left"></ng-template>
            <ng-template pTemplate="right">
                <button pButton pRipple label="Back" icon="pi pi-arrow-left" class="p-button-success mr-2" (click)="appUtil.return()"></button>
                <button pButton pRipple label="Edit" icon="pi pi-pencil" class="mr-2" (click)="edit()"></button>
                <p-splitButton label="Recipients" (onClick)="uploadRecipients()" icon="pi pi-upload"
                    [model]="uploadItems" class="mr-2"></p-splitButton>
                <!-- <button pButton pRipple [label]="Delete" icon="pi pi-trash" class="p-button-danger  mr-2" ></button>-->
                <!--<button pButton pRipple label="Preview" icon="pi pi-print" class="p-button-success mr-2" (click)="preview()"></button> -->
            </ng-template>
        </p-toolbar>
        <div class="p-fluid formgrid grid">
            <div class="field col-12">
                <div class="grid mt-1">
                    <div class="col-12 md:col-6">
                        <div class="inline label">Created By:</div>
                         {{emailCampaign.createByUser}} on {{emailCampaign.createdDate | date:"MM-dd-yyyy HH:mm:ss"}}
                    </div>
                    <div class="col-12 md:col-6 text-right">
                        <div class="inline label">Modified By:</div>
                         {{emailCampaign.modifiedByUser}} on {{emailCampaign.lastModifiedDate | date:"MM-dd-yyyy HH:mm:ss"}}
                    </div>
                </div>
                <p-divider type="dashed"></p-divider>
            </div>
        </div>
        <p-tabView (onChange)="changeTab($event)" [activeIndex]="activeIndex">
            <p-tabPanel header="Campaign Detail">
                <ng-template pTemplate="content">
                    <div class="p-fluid formgrid grid">
                        <div class="field col-12  md:col-4">
                            <div class="grid mt-1 limit-width-large dashed-bottom">
                                <div class="col-12 md:col-4 label md:text-right">
                                    Title
                                </div>
                                <div class="field col-12 md:col-8">
                                    {{emailCampaign.title}}
                                </div>
                            </div>
                        </div>
                        <div class="field col-12 md:col-4">
                            <div class="grid mt-1 limit-width-large dashed-bottom">
                                <div class="col-12 md:col-4 label md:text-right">
                                    Type
                                </div>
                                <div class="field col-12 md:col-8">
                                    {{emailCampaign.type}}
                                </div>
                            </div>
                        </div>
                        <div class="field col-12 md:col-4">
                            <div class="grid mt-1 limit-width-large dashed-bottom">
                                <div class="col-12 md:col-4 label md:text-right">
                                    Related To
                                </div>
                                <div class="field col-12 md:col-8">
                                    <a *ngIf="emailCampaign.relatedTo == '' || emailCampaign.relatedTo == null"
                                        [href]="emailCampaign.buySide?'/buy-side/'+emailCampaign.buySide.uuid:emailCampaign.sellSide?'/sell-side/'+emailCampaign.sellSide.uuid:emailCampaign.spotlight?'/spotlight/'+emailCampaign.spotlight.uuid:emailCampaign.engagement?'/engagement/'+emailCampaign.engagement.uuid:'#'">
                                        {{emailCampaign.buySide?emailCampaign.buySide.screenTitle:emailCampaign.sellSide?emailCampaign.sellSide.screenTitle:emailCampaign.spotlight?emailCampaign.spotlight.title:emailCampaign.engagement?emailCampaign.engagement.name:emailCampaign.relatedTo}}
                                    </a>
                                    <div class="field col-12 md:col-8">
                                        {{emailCampaign.relatedTo}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="field col-12 md:col-4">
                            <div class="grid mt-1 limit-width-large dashed-bottom">
                                <div class="col-12 md:col-4 label md:text-right">
                                    Related Tag
                                </div>
                                <div class="field col-12 md:col-8">
                                    {{emailCampaign.relatedTag}}
                                </div>
                            </div>
                        </div>
                        <div class="field col-12 md:col-4">
                            <div class="grid mt-1 limit-width-large dashed-bottom">
                                <div class="col-12 md:col-4 label md:text-right">
                                    Attachments
                                </div>
                                <div class="field col-12 md:col-8">
                                    <span *ngFor="let file of emailCampaign.uploadedFiles; let last = last;">
                                        <a href="{{file.url}}" (click)="openDocument($event)">{{file.name}}</a><br>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="field col-12 md:col-4">
                            <div class="grid mt-1 limit-width-large dashed-bottom">
                                <div class="col-12 md:col-4 label md:text-right">
                                    Content
                                </div>
                                <div class="field col-12 md:col-8" innerHTML="{{emailCampaign.content}}">
                                </div>
                            </div>
                        </div>
                    </div>
                    <p-toolbar styleClass="m-0">
                        <ng-template pTemplate="left"></ng-template>
                        <ng-template pTemplate="right">
                            <button pButton pRipple label="Publish" icon="pi pi-plus"
                                class="p-button-success mr-2"></button>
                            <button pButton pRipple label="Run" icon="pi pi-plus"
                                class="p-button-success mr-2"></button>
                            <button pButton pRipple label="Stop" icon="pi pi-plus"
                                class="p-button-success mr-2"></button>
                            <button pButton pRipple label="Complete" icon="pi pi-plus"
                                class="p-button-success mr-2"></button>
                        </ng-template>
                    </p-toolbar>
                </ng-template>
            </p-tabPanel>
            <p-tabPanel header="Recipients" [selected]="false">
                <ng-template pTemplate="content">
                    <app-recipient-list [emailCampaign]="emailCampaign"></app-recipient-list>
                </ng-template>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>
<app-upload-excel [(show)]="uploadRecipient" url="campaign/{{uuid}}/recipients/upload" title="Upload Recipeints" (onSuccess)="afterUpload()">
</app-upload-excel>
<p-toast></p-toast>