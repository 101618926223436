import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { environment } from '../.././environments/environment';
@Injectable({ providedIn: 'root' })
export class RedirectGuard implements CanActivate {
    constructor(
        private router: Router, private route: ActivatedRoute
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        let fullPath = this.router.url;

        if (environment['login.url'] && environment['login.url'] != '' && fullPath.indexOf("account/login") != -1) {
            window.location.href = environment['login.url'];
            return false;
        }
        else if (environment['register.url'] && environment['register.url'] != '' && fullPath.indexOf("account/register") != -1) {
            window.location.href = environment['register.url'];
            return false;
        }
        else if (environment['resetpassword.url'] && environment['resetpassword.url'] != '' && fullPath.indexOf("account/reset-password") != -1) {
            let _token = fullPath.substring(fullPath.lastIndexOf('/') + 1);
            let redirect = environment['resetpassword.url'].replace(':token', _token);
            window.location.href = redirect;
            return false;
        }
        else if (environment['activation.url'] && environment['activation.url'] != '' && fullPath.indexOf("account/activate") != -1) {

            let _token = fullPath.substring(fullPath.lastIndexOf('/') + 1);
            let redirect = environment['resetpassword.url'].replace(':token', _token);
            window.location.href = redirect;
            return false;
        }
        else {
            return true;
        }
    }
}