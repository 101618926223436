<div class="card">
    <form [formGroup]="form" #cform>
        <div class="p-fluid grid">
            <div class="field col-12">
                <p-steps [model]="items" [(activeIndex)]="activeIndex"></p-steps>
            </div>
        </div><br><br>
        <div class="p-fluid grid" *ngIf=" activeIndex == 0">
            <div class="field col-12 md:col-6" *ngIf="role== 1 ">
                <span class="p-float-label limit-width">
                    <tbp-autocomplete [multiple]="false" [formControl]="f.organization" [required]="true"
                        (onSelect)="selectAccount($event)" [items]="" [type]="'Account'" field="name"
                        [ngClass]="{ 'ng-invalid ng-dirty': formsubmited && f.organization.errors }" pTooltip="{{getHelpText('Account')}}" tooltipPosition="top">
                    </tbp-autocomplete>
                    <label for="account">Account<span class="mandate">*</span></label>
                </span>
                <span class="p-error" *ngIf="formsubmited && form.get('organization').hasError('required')">Account is
                    Required</span>
            </div>
            <div class="field col-12 md:col-6">
                <span class="p-float-label limit-width">
                    <tbp-autocomplete [formControl]="f.contacts" [items]="" [type]="'Contact'" field="displayName" pTooltip="{{getHelpText('Contacts')}}" tooltipPosition="top"
                        [additionalData]="f.organization.value">
                    </tbp-autocomplete>
                    <label for="contacts">Contacts</label>
                </span>
            </div>  

            
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <textarea pInputTextarea class="limit-width" rows="1" [autoResize]="true"  maxlength="450" formControlName="screenTitle"
                        [ngClass]="{ 'ng-invalid ng-dirty': formsubmited && f.screenTitle.errors }" 
                        pTooltip="{{getHelpText('Screen Title')}}" tooltipPosition="top"></textarea>
                    <label for="screenTitle">Screen Title<span class="mandate">*</span></label>
                </span>
                <span class="p-error" *ngIf="formsubmited && form.get('screenTitle').hasError('required')">Screen Title
                    is Required</span>
                <span class="p-error"
                    *ngIf="f.screenTitle.invalid && f.screenTitle.hasError('message')">{{f.screenTitle.errors.message}}</span>
            </div>
            <div class="field col-12 md:col-6">
                <span class="p-float-label">
                    <textarea pInputTextarea class="limit-width"  rows="1" [autoResize]="true" maxlength="450" formControlName="externalHeadline"
                        [ngClass]="{ 'ng-invalid ng-dirty': formsubmited && f.externalHeadline.errors }"
                        pTooltip="{{getHelpText('External Headline')}}" tooltipPosition="top" style="min-height:35px !important;"></textarea>
                    <label for="externalHeadline">External Headline<span class="mandate">*</span></label>
                </span>
                <span class="p-error" *ngIf="formsubmited && form.get('externalHeadline').hasError('required')">External
                    Headline is Required</span>
                <span class="p-error"
                    *ngIf="f.externalHeadline.invalid && f.externalHeadline.hasError('message')">{{f.externalHeadline.errors.message}}</span>
            </div>           
            <div class="field col-12 md:col-6">
                <p-fieldset legend="What is the primary objective of this screen" styleClass="limit-width">
                    <div class="p-fluid grid">
                        <div class="field col-12">
                            <span class="p-error" *ngIf="formsubmited && f.strategicPartner.invalid">
                                Please select at least one primary objective.
                            </span>
                            <p-divider layout="horizontal" class="mt-3 mb-3"></p-divider>
                            <div class="p-fluid grid">
                                <div class="field col-12 mb-0 font-bold pb-0">
                                    <label>Sell the business</label>
                                </div>
                                <div class="field col-12 mb-0 pb-0">
                                    <p-checkbox [binary]="true" styleClass="form-control" [ngClass]="{ 'ng-invalid ng-dirty': formsubmited && f.strategicPartner.errors }"
                                        formControlName="strategicPartner" label="Strategic Partner"
                                        labelStyleClass="pr-2" (onChange)="validatePrimaryObjective($event)" pTooltip="{{getHelpText('Strategic Partner')}}" tooltipPosition="top"></p-checkbox>
                                </div>
                                <div class="field col-12 mb-0 pb-0">
                                    <div class="p-fluid grid">
                                        <div class="col-12 md:col-5 field mt-2 text-left" >
                                            Private Equity Partner
                                        </div>
                                        <div class="field col-12 md:col-7">
                                            <p-multiSelect [options]="privateEquityPartner" [ngClass]="{ 'ng-invalid ng-dirty': formsubmited && f.equityPartnerType.errors }"
                                                styleClass="form-control" formControlName="equityPartnerType"
                                                id="equityPartnerType" name="equityPartnerType" optionLabel="value"
                                                optionValue="value" [autoDisplayFirst]="false" placeholder="Select" (onChange)="validatePrimaryObjective($event)">
                                            </p-multiSelect>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <p-divider layout="horizontal" class="mt-3 mb-3"></p-divider>
                            <div class=" p-mb-1">
                                <div class="font-bold mt-1 mb-4">Obtain Financing</div>
                                <div class="ml-2">
                                    <div class="p-fluid grid">
                                        <div class="col-12 md:col-2 mt-2" >
                                            Equity
                                        </div>
                                        <div class="field col-12 md:col-10">
                                            <p-multiSelect [options]="equity" styleClass="form-control" [ngClass]="{ 'ng-invalid ng-dirty': formsubmited && f.equityType.errors }"
                                                formControlName="equityType" optionLabel="value" optionValue="value" 
                                                [autoDisplayFirst]="false" placeholder="Select" [showClear]="true" [dataKey]="value"
                                                (onChange)="validatePrimaryObjective($event)" pTooltip="{{getHelpText('Equity')}}" tooltipPosition="top">
                                            </p-multiSelect>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-1 ml-2">
                                    <div class="p-fluid grid">
                                        <div class="col-12 md:col-2 mt-2">
                                            Debt
                                        </div>
                                        <div class="field col-12 md:col-10">
                                            
                                            <p-multiSelect [options]="debt" styleClass="form-control" [ngClass]="{ 'ng-invalid ng-dirty': formsubmited && f.debtType.errors }"
                                                formControlName="debtType" optionLabel="value" optionValue="value" 
                                                [autoDisplayFirst]="false" placeholder="Select" [showClear]="true" 
                                                (onChange)="validatePrimaryObjective($event)" pTooltip="{{getHelpText('Debt')}}" tooltipPosition="top">
                                            </p-multiSelect>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </p-fieldset>
            </div>
            <div class="field col-12 md:col-6">
                <h6>Transaction objective description </h6>
                <div class="limit-width">
                    <editor #editor formControlName="transactionObjective" [init]="appUtil.getInitData(380,null)"
                    pTooltip="{{getHelpText('Transaction Objective')}}" tooltipPosition="top"></editor>
                </div>
            </div>
        </div>
        <div class="p-fluid grid" *ngIf=" activeIndex == 1">

            <div class="field col-12 md:col-6">
                <span class="p-float-label limit-width">
                    <look-autocomplete [multiple]="false" [formControl]="f.targetRevenue" [type]="'REVENUE'"
                        [items]="lookupList.REVENUE" placeholder="NA" pTooltip="{{getHelpText('Target Revenue($m)')}}" tooltipPosition="top"></look-autocomplete>
                    <label for="targetRevenue">Revenue ($m)</label>
                </span>
            </div>
            <div class="field col-12 md:col-6">
                <span class="p-float-label limit-width">
                    <look-autocomplete [multiple]="false" [formControl]="f.targetEbitda" [type]="'EBITDA'"
                        [items]="lookupList.EBITDA" placeholder="NA" pTooltip="{{getHelpText('Target Ebitda($m)')}}" tooltipPosition="top"></look-autocomplete>
                    <label for="targetEbitda">EBITDA ($m)</label>
                </span>
            </div>
            <div class="field col-12 md:col-6">
                <span class="p-float-label limit-width">
                    <h6> Ideal timeline for executing transaction</h6>
                    <p-dropdown [options]="timeline" styleClass="form-control" formControlName="timeline"
                        id="timeline" name="timeline" optionLabel="value" optionValue="value"
                        [autoDisplayFirst]="false" pTooltip="{{getHelpText('Ideal Timeline')}}" tooltipPosition="top" placeholder="NA" [showClear]="true">
                    </p-dropdown>
                </span>
            </div>
            <div class="field col-12 md:col-6">
                <h6>Management Preference</h6>
                <div class="limit-width">
                    <ng-container *ngFor="let managementPreference of managementPreference">
                        <p-radioButton styleClass="form-control" formControlName="managementPreference"
                            name="managementPreference" value="{{managementPreference.value}}"
                            label="{{managementPreference.value}}" labelStyleClass="pr-2" pTooltip="{{getHelpText('Manangement Preference')}}" tooltipPosition="top"></p-radioButton>
                    </ng-container>
                </div>
            </div>
            <div class="field col-12 md:col-6">
                <h6>Company undergoing turnaround/restructuring?</h6>
                <div class="limit-width">
                    <p-radioButton styleClass="form-control" formControlName="turnaround" 
                        [value]="true" label="Yes" labelStyleClass="pr-2" placeholder="NA" pTooltip="{{getHelpText('Turnaround')}}" tooltipPosition="top"></p-radioButton>
                    <p-radioButton styleClass="form-control" formControlName="turnaround" 
                        [value]="false" label="No" labelStyleClass="pr-2" placeholder="NA" pTooltip="{{getHelpText('Turnaround')}}" tooltipPosition="top"></p-radioButton>
                </div>
            </div>
            <div class="col-12 pb-0"><h5>Classification</h5></div>
            <div class="field col-12 md:col-6">
                <span class="p-float-label limit-width">
                    <tbp-autocomplete [formControl]="f.level1" [type]="'CATEGORY_LEVEL1'"
                        [items]="classificationList.CATEGORY_LEVEL1" [required]="true" [ngClass]="{ 'ng-invalid ng-dirty': formsubmited && f.level1.errors }"
                        pTooltip="{{getHelpText('Level1')}}" tooltipPosition="top">
                    </tbp-autocomplete>
                    <label for="level1">Level1 </label>
                </span>
            </div>
            <div class="field col-12 md:col-6">
                <span class="p-float-label limit-width">
                    <tbp-autocomplete [formControl]="f.level2" [type]="'CATEGORY_LEVEL2'"
                        [items]="classificationList.CATEGORY_LEVEL2" pTooltip="{{getHelpText('Level2')}}" tooltipPosition="top">
                    </tbp-autocomplete>
                    <label for="level2">Level2 </label>
                </span>
            </div>
            <div class="field col-12 md:col-6">
                <span class="p-float-label limit-width">
                    <tbp-autocomplete [formControl]="f.level3" [type]="'CATEGORY_LEVEL3'"
                        [items]="classificationList.CATEGORY_LEVEL3" pTooltip="{{getHelpText('Level3')}}" tooltipPosition="top">
                    </tbp-autocomplete>
                    <label for="level3">Level3 </label>
                </span>
            </div>
            <div class="field col-12 md:col-6">
                <span class="p-float-label limit-width">
                    <tbp-autocomplete [formControl]="f.vertical" [type]="'VERTICAL'"
                        [items]="classificationList.VERTICAL" pTooltip="{{getHelpText('Vertical/Industry')}}" tooltipPosition="top">
                    </tbp-autocomplete>
                    <label for="vertical">Vertical/Industry </label>
                </span>
            </div>
            <div class="field col-12 md:col-6">
                <span class="p-float-label limit-width">
                    <tbp-autocomplete [formControl]="f.ecosystem" [type]="'ECOSYSTEM'"
                        [items]="classificationList.ECOSYSTEM" pTooltip="{{getHelpText('Ecosystem')}}" tooltipPosition="top">
                    </tbp-autocomplete>
                    <label for="ecosystem">Ecosystem </label>
                </span>
            </div>

            <div class="field col-12 md:col-6">
                <h6>Company Description</h6>
                <div class="limit-width">
                <editor #editor formControlName="companyDescription" [init]="appUtil.getInitData(220,null)"
                pTooltip="{{getHelpText('Target Description')}}" tooltipPosition="top"></editor>
            </div>
            </div>
        </div>
        
        <div *ngIf=" activeIndex == 2">
            <sellside-view-include [model]="model"></sellside-view-include>
        </div>
        <div class="formgrid grid">
            <div class="field col-12 md:col-12 text-right" *ngIf=" loadingSpinner == false">
                <button pButton label="Previous" *ngIf=" activeIndex != 0" (click)="preStep()" iconPos="left"
                    class="mr-2"></button>
                <button *ngIf=" activeIndex != 2" pButton label="Next" (click)="nextStep()" iconPos="left"
                    class="mr-2"></button>
                <button *ngIf=" activeIndex == 2" pButton label="Finish" (click)="save()" iconPos="left"
                    class="mr-2"></button>
                <p-button label="Cancel" icon="pi pi-times" (click)="cancel()" iconPos="left"
                    styleClass="p-button-secondary"></p-button>
            </div>
            <div class="field col-12 md:col-12 text-right" *ngIf="loadingSpinner">
                <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
                    fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
            </div>
        </div>
    </form>
</div>
<p-toast></p-toast>
<account-popup (onFormGroupChange)="onFormGroupChangeEvent($event)" [(show)]="accountForm" type="sellSide">
</account-popup>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>