import { ClassificationService } from "../classification/classification.service";
import { EntityClassification } from "./entity-classification";
import { LookupValue } from "./lookup-value";
import { User } from "./user";
import { Address } from "./addresss"
export class Organization {
    constructor() {
        this.classification = new EntityClassification;
    }
    uuid: string;
    name: string;
    description: string;
    companyType: string;
    location: string;
    website: string;
    phone: string;
    revenue: string;
    customSubtypeValue: string;
    isPubliclyTraded: boolean;
    accountOwner: User;
    address: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
    leadSource: string;
    numberOfEmployee: string;
    subTypes: LookupValue[];
    classification: EntityClassification;
    revenueLookup: LookupValue;
    numberOfEmployeeLookup: LookupValue;
    parentCompany: string;
    investors: any[];
    addresses: Address;
    tags: string;
    linkedinPageId: string;
    pitchbookId: string;
    capitaliqId: string;
    createByUser?: User;
    createdDate?: User;
    modifiedByUser?: User;
    lastModifiedDate?: User;
}