import { Component, OnInit, ViewChild, NgZone, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { MenuItem, LazyLoadEvent } from 'primeng/api';
import { BreadcrumbService } from '../app.breadcrumb.service';
import { Engagement } from '../_models/engagement';
import { EngagementService } from './engagement.service';
import { AppUtil } from '../_helpers';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-engagement.list',
    templateUrl: './engagement.list.component.html',
    styleUrls: ['./engagement.list.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class EngagementListComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription = new Subscription();
    engagements: Engagement[];
    @ViewChild('dt') table: Table;
    selectedEngagement: Engagement;
    loading: boolean;
    scrollTop: string;
    engagementItems: MenuItem[];
    totalRecords: number;
    rowStatus: { [s: string]: Object; } = {};
    noRecordMessages: string[];
    engagement: Engagement;
    term: string;
    currentMenuData: any;


    constructor(private engagementService: EngagementService, private breadcrumbService: BreadcrumbService, 
        public appUtil:AppUtil ,private router: Router,private ngZone: NgZone,) {
        this.breadcrumbService.setItems([
            { label: 'Engagement Listing' }
        ]);
    }

    ngOnInit(): void {
        const storedScrollTop = localStorage.getItem('engagementScroll');
        if (storedScrollTop) {
            window.scrollTo(0, parseInt(storedScrollTop));
        }
        window.addEventListener('scroll', this.saveScrollPosition);

        this.loading = true;
        this.totalRecords = 0;
        this.engagements = [];
        this.loading = true;
        this.currentMenuData = { id: '' };
        this.engagementItems = [];

    }

    search(e: LazyLoadEvent = { first: 0, rows: 25, sortField: 'name', sortOrder: 1 }) {
        var searchParams = {};
        searchParams['page'] = (e.first / e.rows);
        searchParams['pageSize'] = e.rows;
        var sortBy = []
        var sortDir = e.sortOrder == 1 ? 'DESC' : 'ASC';
        if (e.sortField && e.sortField != '') {
            sortBy[sortBy.length] = { "property": e.sortField, "direction": sortDir };
        }
        searchParams['sortDetails'] = sortBy;

        if (this.term != '') {
            searchParams['query'] = this.term;
        }
        this.engagementService.searchEngagement(searchParams).subscribe(
            data => {
                let response = data as any;
                this.totalRecords = response.totalRecords;
                this.engagements = response.records;
                this.loading = false;
                const data1 = localStorage.getItem('engagementScroll');
                if (data1) {
                  this.ngZone.runOutsideAngular(() => {
                    setTimeout(() => {
                      window.scrollTo(0, parseInt(data1));
                    }, 0);
                  });
                }
            });
    }
    ngOnDestroy() {
        this.subscriptions.unsubscribe();
         window.removeEventListener('scroll', this.saveScrollPosition);
         localStorage.setItem('engagementScroll', this.scrollTop); 
     }
     
     saveScrollPosition = (): void => {
         this.scrollTop = window.scrollY.toString();  
    
     }
    new() {
        this.router.navigate(['/engagement/new']);
    }

    edit() {
        this.router.navigate(['/engagement/edit/' + this.currentMenuData.uuid]);
    }
    setCurrentItem(eng) {
        let items = [
            {
                label: 'Edit',
                icon: 'pi pi-plus',
                command: (event) => {
                    this.edit();
                }
            },
            {
                label: 'Delete',
                icon: 'pi pi-trash',
                command: () => {
                    //this.delete();
                }
            }
        ];
        this.currentMenuData = eng;
        this.engagementItems = items;
    }

}
