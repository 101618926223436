import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TaskService {

    constructor(private http: HttpClient) { }

    getList(taskType: string, parentUuid: string, searchParams: any) {
        return this.http.post(environment.apiUrl + '/' + taskType + '/' + parentUuid + '/tasks', searchParams);
    }

    addTask(data: any) {
        return this.http.post(environment.apiUrl + '/task', data);
    }

    updateTask(uuid: string, data: any) {
        return this.http.put(environment.apiUrl + '/task' + '/' + uuid, data);
    }
    quickUpdateTask(fieldsToBeUpdated: string[], data: any) {
        return this.http.patch(environment.apiUrl + '/task' + '/' + data.uuid, 
       {"fieldsToBeUpdated": fieldsToBeUpdated, "model": data});
    }
    get(uuid: string) {
        return this.http.get(environment.apiUrl + '/task/' + uuid);
    }

    private _listners = new Subject<any>();
    listen(): Observable<any> {
        return this._listners.asObservable();
    }

    filter(filterBy: string) {
        this._listners.next(filterBy);
    }

    getSellside(uuid: string) {
        return this.http.get(environment.apiUrl + '/sellside/' + uuid);
    }

    getBuyside(uuid: string) {
        return this.http.get(environment.apiUrl + '/buyside/' + uuid);
    }

    getContact(uuid: string) {
        return this.http.get(environment.apiUrl + '/contact/' + uuid);
    }

    getContacts(uuid: string, searchParams: any) {
        return this.http.post(environment.apiUrl + '/organization/' + uuid + '/contacts', searchParams);
    }

    getOrganization(uuid: string) {
        return this.http.get(environment.apiUrl + '/organization/' + uuid);
    }

    searchTasks(searchParams: any){ 
        return this.http.post(environment.apiUrl + '/tasks', searchParams);
    }
    searchDashboardTasks( uuid: string, searchParams: any, type: string) {
        const queryParams = {};
        queryParams['type'] = type;
        return this.http.post(environment.apiUrl + '/dashboard/' + uuid + '/tasks', searchParams ,{ params: queryParams });
    }
}
