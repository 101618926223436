<div class="grid table-demo">
  <div class="col-12">
    <div class="card divider-card">
      <p-toolbar styleClass="headerSticky" *ngIf="contact.status !== 'DELETED'">
        <ng-template pTemplate="left">
          <p-accordion *ngIf="duplicateContact != ''">
            <p-accordionTab header="Similar Profiles">
              <span *ngFor="let duplicateUser of duplicateContact">
                <a [routerLink]="['/contact/', duplicateUser.uuid]" target="_blank"><b>{{
                    duplicateUser.firstName + " " + duplicateUser.lastName
                    }}</b></a><br />
              </span>
            </p-accordionTab>
          </p-accordion>
        </ng-template>
        <ng-template pTemplate="right" *ngIf="role == 1">
          <button pButton pRipple label="Back" icon="pi pi-arrow-left" class="p-button-success mr-2"
            (click)="appUtil.return()"></button>
          <button pButton pRipple label="Edit" icon="pi pi-pencil" class="p-button-success mr-2"
            (click)="appUtil.routeToEditURL('contact', contact.uuid)"></button>

          <button *ngIf="userStatus" pButton pRipple label="{{labelStatus}}" class="p-button-success mr-2"
            (click)="changeStatus()"></button>

          <button *ngIf="userCredentials" pButton pRipple label="Create Credentials" class="p-button-success mr-2"
            (click)="newPassword()"></button>

          <button pButton pRipple label="Merge" icon="pi pi-link" class="p-button-info mr-2"
            (click)="appUtil.routeToMergeURL('contact', contact.uuid)"></button>

          <button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-success mr-2"
            (click)="showDialog()"></button>
        </ng-template>

      </p-toolbar>
      <div class="p-fluid formgrid grid">
        <div class="field col-12">
          <div class="grid mt-1">
            <div class="col-12 md:col-6">
              <div class="inline label">Created By:</div>
              {{ contact.createByUser }} on
              {{ contact.createdDate | date : "MM-dd-yyyy HH:mm:ss" }}
            </div>
            <div class="col-12 md:col-6 text-right">
              <div class="inline label">Modified By:</div>
              {{ contact.modifiedByUser }} on
              {{ contact.lastModifiedDate | date : "MM-dd-yyyy HH:mm:ss" }}
            </div>
          </div>
          <p-divider type="dashed"></p-divider>
        </div>
      </div>
      <h5>Contact Information</h5>
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-4" (click)="editField('firstName')">
          <div class="grid mt-1 limit-width-large dashed-bottom">
            <div class="col-12 md:col-4 label md:text-right">First Name</div>
            <div *ngIf="!editingField.firstName" class="field col-12 md:col-8 capitalize">
              {{ contact.firstName }}
            </div>
            <div *ngIf="editingField.firstName" class="field col-12 md:col-8 capitalize">
              <div class="input-container">
                <input pInputText type="text" [(ngModel)]="contact.firstName">
                <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                  (click)="saveField('firstName', contact.firstName)"></button>
                <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                  (click)="closeDescriptionDialog('firstName')"></button>
              </div>
            </div>
          </div>
        </div>
        <div class="field col-12 md:col-4" (click)="editField('lastName')">
          <div class="grid mt-1 limit-width-large dashed-bottom">
            <div class="col-12 md:col-4 label md:text-right">Last Name</div>
            <div *ngIf="!editingField.lastName" class="field col-12 md:col-8 capitalize">
              {{ contact.lastName }}
            </div>
            <div *ngIf="editingField.lastName" class="field col-12 md:col-8 capitalize">
              <div class="input-container">
                <input pInputText type="text" [(ngModel)]="contact.lastName">
                <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                  (click)="saveField('lastName', contact.lastName)"></button>
                <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                  (click)="closeDescriptionDialog('lastName')"></button>
              </div>
            </div>
          </div>
        </div>
        <div class="field col-12 md:col-4">
          <div class="grid mt-1 limit-width-large dashed-bottom">
            <div class="col-12 md:col-4 label md:text-right">Email</div>
            <div *ngIf="!editingField.email" class="field col-12 md:col-8 contact-info">
              <a href="mailto:{{ contact.email }}">{{ contact.email }}</a>&nbsp;
              <a *ngIf="contact.status == 'ACTIVE' && contact.hasCredentials" href="javascript:void(0)"
                (click)="resetPassword()">
                <i class="pi pi-key" style="font-size: 1rem" title="Reset Password"></i>
              </a>
              <span *ngIf="contact.verified && !editingField.email">
                <i class="pi pi-check" style="color: rgb(4, 107, 232);" title="Verified"></i>
              </span>
              <button pButton type="button" icon="pi pi-pencil" title="Edit" class="p-button-text edit-button"
                (click)="editField('email')"></button>
            </div>
            <div *ngIf="editingField.email" class="field col-12 md:col-8">
              <!-- <input pInputText type="email" [(ngModel)]="contact.email">
              <button *ngIf="!isInvalidEmail(contact.email)" pButton type="button" label="Save"
                class="p-button-text" (click)="saveField('email', contact.email)"></button> -->
              <div class="input-container">
                <input pInputText type="text" [(ngModel)]="contact.email">
                <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                  (click)="saveField('email', contact.email)"></button>
                <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                  (click)="closeDescriptionDialog('email')"></button>
              </div>
              <div *ngIf="isInvalidEmail(contact.email)" style="color: red;">Invalid email format</div>
            </div>
          </div>
        </div>
        <div class="field col-12 md:col-4" (click)="editField('phone')">
          <div class="grid mt-1 limit-width-large dashed-bottom">
            <div class="col-12 md:col-4 label md:text-right">Phone</div>
            <div *ngIf="!editingField.phone" class="field col-12 md:col-8">
              {{ contact.phone }}
            </div>
            <div *ngIf="editingField.phone" class="field col-12 md:col-8">
              <div class="input-container">
                <p-inputNumber mode="decimal" [useGrouping]="false" [(ngModel)]="contact.phone"></p-inputNumber>
                <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                  (click)="saveField('phone', contact.phone)"></button>
                <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                  (click)="closeDescriptionDialog('phone')"></button>
              </div>
            </div>
          </div>
        </div>
        <div class="field col-12 md:col-4" (click)="editField('mobile')">
          <div class="grid mt-1 limit-width-large dashed-bottom">
            <div class="col-12 md:col-4 label md:text-right">Mobile</div>
            <div *ngIf="!editingField.mobile" class="field col-12 md:col-8">
              {{ contact.mobile }}
            </div>
            <div *ngIf="editingField.mobile" class="field col-12 md:col-8">
              <div class="input-container">
                <p-inputNumber mode="decimal" [useGrouping]="false" [(ngModel)]="contact.mobile"></p-inputNumber>
                <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                  (click)="saveField('mobile', contact.mobile)"></button>
                <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                  (click)="closeDescriptionDialog('mobile')"></button>
              </div>
            </div>
          </div>
        </div>
        <div class="field col-12 md:col-4">
          <div class="grid mt-1 limit-width-large dashed-bottom">
            <div class="col-12 md:col-4 label md:text-right">Account</div>
            <div *ngIf="!editingField.organization" class="field col-12 md:col-8 contact-info">
              <ng-container *ngIf="contact['organization'] != null ; else individualContact">
                <a [routerLink]="['/organization/', contact['organization']['uuid']]">
                  {{ contact["organization"]["name"] }}
                </a>&nbsp;
                <button pButton type="button" icon="pi pi-pencil" title="Edit" class="p-button-text edit-button"
                  (click)="editField('organization')"></button>
              </ng-container>
              <ng-template #individualContact>
                <i title="Individual Contact" style="color: rgb(4, 107, 232);" class="pi pi-user"></i>
                <button pButton type="button" icon="pi pi-pencil" title="Edit" class="p-button-text edit-button"
                  (click)="editField('organization')"></button>
              </ng-template>
            </div>
            <div *ngIf="editingField.organization" class="field col-12 md:col-8">
              <div class="input-container">
                <p-autoComplete appendTo="body" [unique]="true" [forceSelection]="true"
                  [suggestions]="organizationResults" [(ngModel)]="contact.organization"
                  (completeMethod)="searchOrganizations($event)" field="name"> </p-autoComplete>
                <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                  (click)="saveField('organization', contact.organization)"></button>
                <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                  (click)="closeDescriptionDialog('organization')"></button>
              </div>
            </div>
          </div>
        </div>
        <div class="field col-12 md:col-4" (click)="editField('title')">
          <div class="grid mt-1 limit-width-large dashed-bottom">
            <div class="col-12 md:col-4 label md:text-right">Title</div>
            <div *ngIf="!editingField.title" class="field col-12 md:col-8 capitalize">
              {{ contact.title }}&nbsp;
            </div>
            <div *ngIf="editingField.title" class="field col-12 md:col-8 capitalize">
              <div class="input-container">
                <input pInputText type="text" [(ngModel)]="contact.title">
                <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                  (click)="saveField('title', contact.title)"></button>
                <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                  (click)="closeDescriptionDialog('title')"></button>
              </div>
            </div>
          </div>
        </div>
        <div class="field col-12 md:col-4" (click)="editField('secondaryEmail')">
          <div class="grid mt-1 limit-width-large dashed-bottom">
            <div class="col-12 md:col-4 label md:text-right">Secondary Email</div>
            <div *ngIf="!editingField.secondaryEmail" class="field col-12 md:col-8">
              {{ contact.secondaryEmail }}&nbsp;
            </div>
            <div *ngIf="editingField.secondaryEmail" class="field col-12 md:col-8">
              <div class="input-container">
                <input pInputText type="text" [(ngModel)]="contact.secondaryEmail">
                <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                  (click)="saveField('secondaryEmail', contact.secondaryEmail)"></button>
                <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                  (click)="closeDescriptionDialog('secondaryEmail')"></button>
              </div>
              <div *ngIf="isInvalidEmail(contact.secondaryEmail) && contact.secondaryEmail" style="color: red;">Invalid
                email format</div>
            </div>
          </div>
        </div>
        <div class="field col-12 md:col-4" (click)="editField('leadSource')">
          <div class="grid mt-1 limit-width-large dashed-bottom">
            <div class="col-12 md:col-4 label md:text-right">Lead Source</div>
            <div *ngIf="!editingField.leadSource" class="field col-12 md:col-8">
              {{contact.leadSource}}&nbsp;
            </div>
            <div *ngIf="editingField.leadSource" class="field col-12 md:col-8">
              <div class="input-container">
                <look-autocomplete [multiple]="false" [(ngModel)]="leadSource"
                [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.leadSource.errors }" [type]="'ACCOUNT_SOURCE'"
                [items]="lookupList.ACCOUNT_SOURCE" ></look-autocomplete>
                <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                  (click)="saveField('leadSource',leadSource)"></button>
                <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                  (click)="closeDescriptionDialog('leadSource')"></button>
              </div>
            </div>
          </div>
        </div>
        <div class="field col-12 md:col-4">
          <div class="grid mt-1 limit-width-large dashed-bottom">
            <div class="col-12 md:col-4 label md:text-right">LinkedIn Profile URL</div>
            <div *ngIf="!editingField.linkedInProfile" class="field col-12 md:col-8 contact-info">
              <a href="javascript: void(0);" *ngIf="
              contact.linkedInProfile != null &&
              contact.linkedInProfile != ''
            " (click)="appUtil.externalLink(contact.linkedInProfile, 'url')">{{ contact.linkedInProfile }}</a>
              <i class="pi pi-google link" (click)="
              appUtil.externalLink(contact.name, 'google', 'linkedin.com:')
            " style="float: right" *ngIf="
              contact.linkedInProfile == null ||
              contact.linkedInProfile == ''
            "></i>
              <button pButton type="button" icon="pi pi-pencil" title="Edit" class="p-button-text edit-button"
                (click)="editField('linkedInProfile')"></button>
            </div>
            <div *ngIf="editingField.linkedInProfile" class="field col-12 md:col-8">
              <div class="input-container">
                <input pInputText type="text" [(ngModel)]="contact.linkedInProfile">
                <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                  (click)="saveField('linkedInProfile', contact.linkedInProfile)"></button>
                <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                  (click)="closeDescriptionDialog('linkedInProfile')"></button>
              </div>
            </div>
          </div>
        </div>
        <div class="field col-12 md:col-4" (click)="editField('assistantName')">
          <div class="grid mt-1 limit-width-large dashed-bottom">
            <div class="col-12 md:col-4 label md:text-right"> Assistant's Name</div>
            <div *ngIf="!editingField.assistantName" class="field col-12 md:col-8 ">
              {{ contact.assistantName }}
            </div>
            <div *ngIf="editingField.assistantName" class="field col-12 md:col-8">
              <div class="input-container">
                <input pInputText type="text" [(ngModel)]="contact.assistantName">
                <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                  (click)="saveField('assistantName', contact.assistantName)"></button>
                <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                  (click)="closeDescriptionDialog('assistantName')"></button>
              </div>
            </div>
          </div>
        </div>
        <div class="field col-12 md:col-4" (click)="editField('assistantPhone')">
          <div class="grid mt-1 limit-width-large dashed-bottom">
            <div class="col-12 md:col-4 label md:text-right"> Assistant's Phone</div>
            <div *ngIf="!editingField.assistantPhone" class="field col-12 md:col-8">
              {{ contact.assistantPhone }}
            </div>
            <div *ngIf="editingField.assistantPhone" class="field col-12 md:col-8">
              <div class="input-container">
                <p-inputNumber mode="decimal" [useGrouping]="false"
                  [(ngModel)]="contact.assistantPhone"></p-inputNumber>
                <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                  (click)="saveField('assistantPhone', contact.assistantPhone)"></button>
                <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                  (click)="closeDescriptionDialog('assistantPhone')"></button>
              </div>
            </div>
          </div>
        </div>
        <div class="field col-12 md:col-4" (click)="editField('assistantEmail')">
          <div class="grid mt-1 limit-width-large dashed-bottom">
            <div class="col-12 md:col-4 label md:text-right"> Assistant's Email</div>
            <div *ngIf="!editingField.assistantEmail" class="field col-12 md:col-8">
              {{ contact.assistantEmail }}&nbsp;
            </div>
            <div *ngIf="editingField.assistantEmail" class="field col-12 md:col-8">
              <div class="input-container">
                <input pInputText type="text" [(ngModel)]="contact.assistantEmail">
                <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                  (click)="saveField('assistantEmail', contact.assistantEmail)"></button>
                <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                  (click)="closeDescriptionDialog('assistantEmail')"></button>
              </div>
              <div *ngIf="isInvalidEmail(contact.assistantEmail)  && contact.assistantEmail " style="color: red;">
                Invalid email format</div>
            </div>
          </div>
        </div>

        <div class="field col-12 md:col-4">
          <div class="grid mt-1 limit-width-large dashed-bottom">
            <div class="col-12 md:col-4 label md:text-right">Account Owner</div>
            <div *ngIf="!editingField.accountOwner" class="field col-12 md:col-8 contact-info">
              <a *ngIf="contact.accountOwner != null " [routerLink]="['/user/', contact.accountOwner.uuid]">
                {{contact.accountOwner ? contact.accountOwner.name : "" }}</a>&nbsp;
              <button pButton type="button" icon="pi pi-pencil" title="Edit" class="p-button-text edit-button"
                (click)="editField('accountOwner')"></button>
            </div>
            <div *ngIf="editingField.accountOwner" class="field col-12 md:col-8">
              <div class="input-container">
                <p-autoComplete appendTo="body" [unique]="true" [forceSelection]="true" [suggestions]="filteredValues"
                  [(ngModel)]="contact.accountOwner" (completeMethod)="searchLookupValue($event ,'accountOwner')"
                  field="name"> </p-autoComplete>
                <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                  (click)="saveField('accountOwner', contact.accountOwner)"></button>
                <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                  (click)="closeDescriptionDialog('accountOwner')"></button>
              </div>
            </div>
          </div>
        </div>
        <div class="field col-12 md:col-4" (click)="editField('mailingList')">
          <div class="grid mt-1 limit-width-large dashed-bottom">
            <div class="col-12 md:col-4 label md:text-right">Mailing List</div>
            <div *ngIf="!editingField.mailingList" class="field col-12 md:col-8">
              {{getLookupOutput(contact.mailingList)}}&nbsp;
            </div>
            <div *ngIf="editingField.mailingList" class="field col-12 md:col-8">
              <div class="input-container">
                <look-autocomplete [multiple]="true"   [(ngModel)]="mailingList"
                [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.mailingList.errors }" [type]="'MAILING_LIST'"
                [items]="lookupList.MAILING_LIST"></look-autocomplete>
                <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                  (click)="saveField('mailingList',mailingList)"></button>
                <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                  (click)="closeDescriptionDialog('mailingList')"></button>
              </div>
            </div>
          </div>
        </div>
        <div class="field col-12 md:col-4" (click)="editField('masterProfile')">
          <div class="grid mt-1 limit-width-large dashed-bottom">
            <div class="col-12 md:col-4 label md:text-right">
              Is Primary Profile?
            </div>
            <div *ngIf="!editingField.masterProfile" class="field col-12 md:col-8">
              {{ contact.masterProfile?'Y':'N' }}&nbsp;
            </div>
            <div *ngIf="editingField.masterProfile" class="field col-12 md:col-8">
              <p-radioButton name="masterProfile" [value]="true" (keydown)="disableNavigation($event)"
                [(ngModel)]="contact.masterProfile" label="Y"
                (ngModelChange)="saveField('masterProfile', contact.masterProfile)"></p-radioButton>
              <p-radioButton name="masterProfile" [value]="false" (keydown)="disableNavigation($event)"
                [(ngModel)]="contact.masterProfile" label="N"
                (ngModelChange)="saveField('masterProfile', contact.masterProfile)"></p-radioButton>
            </div>
          </div>
        </div>
        <div class="field col-12 md:col-4" (click)="editField('accreditedInvestor')">
          <div class="grid mt-1 limit-width-large dashed-bottom">
            <div class="col-12 md:col-4 label md:text-right">
              Is Accredited Investor?
            </div>
            <div *ngIf="!editingField.accreditedInvestor" class="field col-12 md:col-8">
              {{
              contact["organization"] != null
              ? "N.A."
              : contact.accreditedInvestor
              ? "Y"
              : "N"
              }}&nbsp;
            </div>
            <div *ngIf="editingField.accreditedInvestor" class="field col-12 md:col-8">
              <p-radioButton name="accreditedInvestor" [value]="true" (keydown)="disableNavigation($event)"
                [(ngModel)]="contact.accreditedInvestor" label="Y"
                (onClick)="saveField('accreditedInvestor', contact.accreditedInvestor)"></p-radioButton>
              <p-radioButton name="accreditedInvestor" [value]="false" (keydown)="disableNavigation($event)"
                [(ngModel)]="contact.accreditedInvestor" label="N"
                (onClick)="saveField('accreditedInvestor', contact.accreditedInvestor)"></p-radioButton>
            </div>
          </div>
        </div>
        <div class="field col-12 md:col-4" (click)="editField('primaryContact')">
          <div class="grid mt-1 limit-width-large dashed-bottom">
            <div class="col-12 md:col-4 label md:text-right">
              Is Primary Contact?
            </div>
            <div *ngIf="!editingField.primaryContact" class="field col-12 md:col-8">
              {{
              contact["organization"] == null
              ? "N.A."
              : contact.primaryContact
              ? "Y"
              : "N"
              }}&nbsp;
            </div>
            <div *ngIf="editingField.primaryContact" class="field col-12 md:col-8">
              <p-radioButton name="primaryContact" [value]="true" (keydown)="disableNavigation($event)"
                [(ngModel)]="contact.primaryContact" label="Y"
                (onClick)="saveField('primaryContact', contact.primaryContact)"></p-radioButton>
              <p-radioButton name="primaryContact" [value]="false" (keydown)="disableNavigation($event)"
                [(ngModel)]="contact.primaryContact" label="N"
                (onClick)="saveField('primaryContact', contact.primaryContact)"></p-radioButton>
            </div>
          </div>
        </div>
      </div>
      <h5>Description</h5>
      <div class="p-fluid formgrid grid" (click)="editField('description')">
        <div *ngIf="!editingField.description" class="field col-12 md:col-8">
          <div *ngIf="contact.description != ''" innerHTML="{{ contact.description }}">
          </div>
        </div>
        <div class="col-12" *ngIf="contact.description == null || contact.description == ''">
          <div class="mt-1 mt-1 dashed-bottom">Not Applicable</div>
        </div>
        <div *ngIf="editingField.description" class="field col-12 md:col-8">
          <p-dialog [visible]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}"
            [closable]="false" [modal]="true">
            <ng-template pTemplate="header">
              <div class="p-fluid formgrid grid">
                <div class="field col-12">
                  <strong>{{ contact.name }}:</strong>
                  {{ "Description" }}
                </div>
              </div>
            </ng-template>
            <ng-template pTemplate="content">
              <editor [(ngModel)]="contact.description" [init]="appUtil.getInitData(180,null)"></editor>
            </ng-template>
            <ng-template pTemplate="footer">
              <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
                (click)="saveField('description', contact.description)"></button>
              <button pButton pRipple label="Close" class="p-button-text"
                (click)="closeDescriptionDialog('description' ,$event)"></button>
            </ng-template>
          </p-dialog>
        </div>
      </div>
      <h5>Email Preferences</h5>
      <div class="p-fluid formgrid grid">
        <ng-container *ngFor="let preference of emailPreferences">
          <div class="field col-12 md:col-4" (click)="editField(preference.fieldName)">
            <div class="grid mt-1 limit-width-large dashed-bottom">
              <div class="col-12 md:col-4 label md:text-right">{{ preference.label }}</div>
              <div *ngIf="!editingField[preference.fieldName]" class="field col-12 md:col-8">
                {{ contact[preference.fieldName] ? "Y" : "N" }}
              </div>
              <div *ngIf="editingField[preference.fieldName]" class="field col-12 md:col-8">
                <p-radioButton name="{{ preference.fieldName }}" [value]="true" (keydown)="disableNavigation($event)"
                  [(ngModel)]="contact[preference.fieldName]" label="Y"
                  (onClick)="saveField(preference.fieldName, contact[preference.fieldName])"></p-radioButton>
                <p-radioButton name="{{ preference.fieldName }}" [value]="false" (keydown)="disableNavigation($event)"
                  [(ngModel)]="contact[preference.fieldName]" label="N"
                  (onClick)="saveField(preference.fieldName, contact[preference.fieldName])"></p-radioButton>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <h5>Classification</h5>
      <div class="p-fluid formgrid grid">
        <ng-container *ngFor="let category of categories">
          <div class="field col-12 md:col-4" (click)="editField(category.label)">
            <div class="grid mt-1 limit-width-large dashed-bottom">
              <div class="col-12 md:col-4 label md:text-right">{{ category.label }}</div>
              <div *ngIf="!editingField[category.label]" class="field col-12 md:col-8">
                <span *ngFor="let classification of contact.classification[category.model]; let last = last">
                  {{ classification.name }}{{ last ? '' : ', ' }}
                </span>
                &nbsp;
              </div>
              <div *ngIf="editingField[category.label]" class="field col-12 md:col-8">
                <div class="input-container">
                  <p-multiSelect [options]="classificationList[category.value]"
                    [(ngModel)]="contact.classification[category.model]" optionLabel="name" placeholder="Choose"
                    appendTo="body" dataKey="uuid" (keydown)="disableNavigation($event)"></p-multiSelect>
                  <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                    (click)="saveField('classification',  contact.classification)"></button>
                  <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                    (click)="closeDescriptionDialog(category.label)"></button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <h5>Address Information</h5>
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-4" (click)="editField('address')">
          <div class="grid mt-1 limit-width-large dashed-bottom">
            <div class="col-12 md:col-4 label md:text-right">Street</div>
            <div *ngIf="!editingField.address" class="field col-12 md:col-8 capitalize">
              {{ contact.address }}
            </div>
            <div *ngIf="editingField.address" class="field col-12 md:col-8 capitalize">
              <div class="input-container">
                <input pInputText type="text" [(ngModel)]="contact.address" maxlength="100">
                <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                  (click)="saveField('address', contact.address)"></button>
                <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                  (click)="closeDescriptionDialog('address')"></button>
              </div>
            </div>
          </div>
        </div>
        <div class="field col-12 md:col-4" (click)="editField('city')">
          <div class="grid mt-1 limit-width-large dashed-bottom">
            <div class="col-12 md:col-4 label md:text-right">City</div>
            <div *ngIf="!editingField.city" class="field col-12 md:col-8 capitalize">
              {{ contact.city }}
            </div>
            <div *ngIf="editingField.city" class="field col-12 md:col-8 capitalize">
              <div class="input-container">
                <input pInputText type="text" [(ngModel)]="contact.city" maxlength="100">
                <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                  (click)="saveField('city', contact.city)"></button>
                <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                  (click)="closeDescriptionDialog('city')"></button>
              </div>
            </div>
          </div>
        </div>
        <div class="field col-12 md:col-4" (click)="editField('postalCode')">
          <div class="grid mt-1 limit-width-large dashed-bottom">
            <div class="col-12 md:col-4 label md:text-right">Zip/Postal Code</div>
            <div *ngIf="!editingField.postalCode" class="field col-12 md:col-8 capitalize">
              {{ contact.postalCode }}
            </div>
            <div *ngIf="editingField.postalCode" class="field col-12 md:col-8 capitalize">
              <div class="input-container">
                <input pInputText type="text" [(ngModel)]="contact.postalCode" maxlength="100">
                <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                  (click)="saveField('postalCode', contact.postalCode)"></button>
                <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                  (click)="closeDescriptionDialog('postalCode')"></button>
              </div>
            </div>
          </div>
        </div>
        <div class="field col-12 md:col-4" (click)="editField('country')">
          <div class="grid mt-1 limit-width-large dashed-bottom">
            <div class="col-12 md:col-4 label md:text-right">Country</div>
            <div *ngIf="!editingField.country" class="field col-12 md:col-8">
              {{ getOutput(contact.country) }}
            </div>
            <div *ngIf="editingField.country" class="field col-12 md:col-8 capitalize">
              <div class="input-container">
                <tbp-autocomplete [multiple]="false"  [(ngModel)]="country" 
                [type]="'COUNTRY'" [items]="countries">
              </tbp-autocomplete>
                <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                  (click)="saveField('country', country)"></button>
                <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                  (click)="closeDescriptionDialog('country')"></button>
              </div>
            </div>
          </div>
        </div>
        <div class="field col-12 md:col-4"  (click)="editField('state')">
          <div class="grid mt-1 limit-width-large dashed-bottom">
            <div class="col-12 md:col-4 label md:text-right">State</div>
            <div *ngIf="!editingField.state" class="field col-12 md:col-8 capitalize">
              {{ getOutput(contact.state) }}
            </div>
            <div *ngIf="editingField.state && !stateDisabled" class="field col-12 md:col-8 capitalize">
              <div class="input-container">
                <tbp-autocomplete [multiple]="false" [cntType]="'STATE'"  [disabled]="stateDisabled"  [(ngModel)]="state"  [cntName]="country?.name" [cntUuid]="country?.uuid"
                [type]="'STATE'" [items]="stateSuggestions" ></tbp-autocomplete>
                <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                  (click)="saveField('state', state)"></button>
                <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                  (click)="closeDescriptionDialog('state')"></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="(contact.masterProfile || showRelatedProfile) && showDropdown">
        <h5 class="mt-4">Related Profiles</h5>
        <span class="p-error" *ngIf="
            relatedProfileForm.get('membersToAdd').hasError('required') &&
            submitted
          ">Please fill Related Profile</span>
        <form [formGroup]="relatedProfileForm" *ngIf="contact.status !== 'DELETED'">
          <tbp-autocomplete id="membersToAdd" [multiple]="true" [formControl]="acf.membersToAdd" [items]=""
            [type]="'Contact'" field="displayName" [ngClass]="{
              'ng-invalid ng-dirty': submitted && acf.membersToAdd.errors
            }"></tbp-autocomplete>
          &nbsp;
          <button pButton pRipple label="Add" (click)="SaveRelatedProfile()"></button>
        </form>
      </div>
      <div class="grid mt-3" *ngIf="role">
        <div class="col-12 md:col-12">
          <p-panel [toggleable]="true" [collapsed]="false" expandIcon="pi pi-chevron-left"
            collapseIcon="pi pi-chevron-down">
            <p-header>
              <span class="p-panel-title text-teal">
                <i class="pi pi-bars"></i> Related Profiles
              </span>
            </p-header>
            <ng-template pTemplate="icons">
              <button *ngIf="contact.status !== 'DELETED' && contact.masterProfile" pButton title="Add"
                class="p-panel-header-icon p-link" (click)="toggleDropdown()" label="" icon="pi pi-plus"></button>
            </ng-template>
            <p-table [autoLayout]="true" #dt [value]="relatedProfiles" dataKey="userId"
              styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm">
              <ng-template pTemplate="header">
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Organization</th>
                  <th>Website</th>
                  <th>Action</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-profile>
                <tr>
                  <td>
                    <a [routerLink]="['/contact', profile.uuid]" target="event.target">{{ profile.name }}</a>
                  </td>
                  <td>
                    <a href="mailto:{{ profile.email }}">{{ profile.email }}</a>&nbsp;
                  </td>
                  <td>
                    <ng-container *ngIf="profile.organization">
                      <a [routerLink]="['/organization', profile.organization.uuid]" target="event.target"> {{
                        profile.organization?.name}}</a>
                    </ng-container>
                  </td>
                  <td>
                    <ng-container *ngIf="profile.organization">
                      <a href="javascript: void(0);"
                        (click)="appUtil.externalLink(profile.organization.website, 'url')">
                        {{ profile.organization.website }}</a>&nbsp;
                    </ng-container>
                  </td>
                  <ng-container>
                    <td *ngIf="!profile.masterProfile && contact.status!=='DELETED'">
                      <button type="button" pButton icon="pi pi-list"
                        (click)="setCurrentItem(profile); menu.toggle($event)"></button>
                    </td>
                  </ng-container>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="5">
                    This Contact does not have any Related Profile.
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </p-panel>
        </div>
        <p-menu #menu [popup]="true" [model]="contactItems"></p-menu>
        <div class="col-12 md:col-12" *ngIf="contact.uuid != undefined">
          <note-list noteType="contact" [parent]="contact" [parentUuid]="contact.uuid"></note-list>
        </div>
        <div class="col-12 md:col-12">
          <p-panel [toggleable]="true" [collapsed]="false" expandIcon="pi pi-chevron-left"
            collapseIcon="pi pi-chevron-down">
            <p-header>
              <span class="p-panel-title text-teal">
                <i class="pi pi-bars"></i> Buy Side
              </span>
            </p-header>
            <ng-template pTemplate="icons">
              <button pButton *ngIf="contact.status !== 'DELETED'" title="Add as Prospects or contacts ?"
                class="p-panel-header-icon p-link" (click)="overlayPanel.toggle($event)"><i
                  class="pi pi-search-plus"></i>
              </button>
              <button *ngIf="contact.status !== 'DELETED'" pButton title="Add" class="p-panel-header-icon p-link"
                (click)="setCurrentBuyside()" label="" icon="pi pi-plus"></button>
            </ng-template>
            <p-table [autoLayout]="true" [value]="buysides" [(selection)]="selectedBuySides" dataKey="userId"
              [lazy]="true" (onLazyLoad)="loadBuySides($event)"
              styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm" [rowHover]="true"
              [rows]="5" [paginator]="false" [loading]="buysideLoading" [showCurrentPageReport]="true"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              [totalRecords]="buysideTotalRecords" [alwaysShowPaginator]="false">
              <ng-template pTemplate="header">
                <tr>
                  <th>Screen Title</th>
                  <th>Account</th>
                  <th>IsProspect ?</th>
                  <th>External Headline</th>
                  <th>Primary Objective</th>
                  <th>Target Revenue</th>
                  <th>Action</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-buyside>
                <tr>
                  <td class="wrap">
                    <a [routerLink]="['/buy-side/', buyside.uuid ,'prospects']">{{
                      buyside.screenTitle
                      }}</a>
                  </td>
                  <td class="wrap">{{ buyside.organization.name }}</td>
                  <td class="wrap">
                    <ng-container *ngIf="buyside.contacts && buyside.contacts.length > 0">
                      <ng-container *ngIf="checkIfAnyContactIsProspect(buyside.contacts); else noContact">
                        No
                      </ng-container>
                      <ng-container *ngIf="!checkIfAnyContactIsProspect(buyside.contacts)">
                        Yes
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="!buyside.contacts || buyside.contacts.length === 0">
                      Yes
                    </ng-container>
                  </td>
                  <td class="wrap">{{ buyside.externalHeadline }}</td>
                  <td class="wrap" [innerHTML]="buyside | buysideobjective"></td>
                  <td class="wrap">{{ buyside.targetRevenue }}</td>
                  <td>
                    <button pButton pRipple [routerLink]="['/buy-side/edit/', buyside.uuid]" icon="pi pi-pencil"
                      class="p-button-success mr-2"></button>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="7">
                    No Buy side found to be associated with contact.
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="footer">
                <tr *ngIf="buysideTotalRecords > 5">
                  <td colspan="8" class="text-right">
                    <a href="./contact/{{ uuid }}/buysides">View More</a>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </p-panel>
        </div>

        <div class="col-12 md:col-12">
          <p-panel [toggleable]="true" [collapsed]="false" expandIcon="pi pi-chevron-left"
            collapseIcon="pi pi-chevron-down">
            <p-header>
              <span class="p-panel-title text-teal">
                <i class="pi pi-bars"></i> Sell Side
              </span>
            </p-header>
            <ng-template pTemplate="icons">
              <button *ngIf="contact.status !== 'DELETED'" pButton title="Add as Prospects or contacts ?"
                class="p-panel-header-icon p-link" (click)="op.toggle($event)" label=""
                icon="pi pi-search-plus"></button>
              <button *ngIf="contact.status !== 'DELETED'" pButton title="Add" class="p-panel-header-icon p-link"
                (click)="setCurrentSellside()" label="" icon="pi pi-plus"></button>
            </ng-template>
            <p-table [autoLayout]="true" [value]="sellsides" [(selection)]="selectedSellSides" dataKey="userId"
              [lazy]="true" (onLazyLoad)="loadSellSides($event)"
              styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm" [rowHover]="true"
              [rows]="5" [paginator]="false" [loading]="sellsideLoading" [showCurrentPageReport]="true"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              [totalRecords]="sellsideTotalRecords" [alwaysShowPaginator]="false">
              <ng-template pTemplate="header">
                <tr>
                  <th>Screen Title</th>
                  <th>Account</th>
                  <th>IsProspect ?</th>
                  <th>External Headline</th>
                  <th>Primary Objective</th>
                  <th>Revenue</th>
                  <th>Action</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-sellside>
                <tr>
                  <td class="wrap">
                    <a [routerLink]="['/sell-side/', sellside.uuid, 'prospects']">{{
                      sellside.screenTitle
                      }}</a>
                  </td>
                  <td class="wrap">{{ sellside.organization.name }}</td>
                  <td class="wrap">{{ sellside.contacts && sellside.contacts.length > 0 ? (sellside.contacts[0].name ===
                    contact.name ? 'No' : 'Yes') : 'Yes' }}</td>
                  <td class="wrap">{{ sellside.externalHeadline }}</td>
                  <td class="wrap" [innerHTML]="sellside | sellsideobjective"></td>
                  <td class="wrap">{{ sellside.targetRevenue }}</td>
                  <td>
                    <button pButton pRipple [routerLink]="['/sell-side/edit/', sellside.uuid]" icon="pi pi-pencil"
                      class="p-button-success mr-2"></button>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="7">
                    No Sell side found to be associated with contact.
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="footer">
                <tr *ngIf="sellsideTotalRecords > 5">
                  <td colspan="8" class="text-right">
                    <a href="./contact/{{ uuid }}/sellsides">View More</a>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </p-panel>
        </div>

        <div class="col-12 md:col-12">
          <task-toggle-list taskType="contact" [parentUuid]="uuid" [parent]="contact"></task-toggle-list>
        </div>

        <div class="col-12 md:col-12">
          <p-panel [toggleable]="true" [collapsed]="false" expandIcon="pi pi-chevron-left"
            collapseIcon="pi pi-chevron-down">
            <p-header>
              <span class="p-panel-title text-teal">
                <i class="pi pi-bars"></i> Email Campaign
              </span>
            </p-header>
            <ng-template pTemplate="icons">
              <button *ngIf="contact.status !== 'DELETED'" pButton title="Add" class="p-panel-header-icon p-link"
                (click)="setCurrentCampaign()" label="" icon="pi pi-plus"></button>
            </ng-template>
            <p-table [autoLayout]="true" [value]="emailCampaigns" [(selection)]="selectedEmailCampaigns"
              dataKey="userId" [lazy]="true" (onLazyLoad)="loadEmailCampaigns($event)"
              styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm" [rowHover]="true"
              [rows]="5" [paginator]="false" [loading]="emailCampaignLoading" [showCurrentPageReport]="true"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              [totalRecords]="emailCampaignTotalRecords" [alwaysShowPaginator]="false">
              <ng-template pTemplate="header">
                <tr>
                  <th>Title</th>
                  <th>Type</th>
                  <th>Related To</th>
                  <th>Related Tag</th>
                  <th>Attachments</th>
                  <th>Action</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-emailCampaign>
                <tr>
                  <td class="wrap">
                    <a [routerLink]="['/email-campaign/', emailCampaign.uuid]">{{ emailCampaign.title }}</a>
                  </td>
                  <td class="wrap">{{ emailCampaign.type }}</td>
                  <td class="wrap">
                    <span *ngIf="emailCampaign.buySide != null">
                      <a [routerLink]="[
                          '/buy-side/',
                          emailCampaign.buySide.uuid
                        ]">{{ emailCampaign.buySide.screenTitle }}</a>
                    </span>
                    <span *ngIf="emailCampaign.sellSide != null">
                      <a [routerLink]="[
                          '/sell-side/',
                          emailCampaign.sellSide.uuid
                        ]">{{ emailCampaign.sellSide.screenTitle }}</a>
                    </span>
                    <span *ngIf="emailCampaign.engagement != null">
                      <a [routerLink]="[
                          '/engagement/',
                          emailCampaign.engagement.uuid
                        ]">{{ emailCampaign.engagement.name }}</a>
                    </span>
                    <span *ngIf="emailCampaign.spotlight != null">
                      <a [routerLink]="[
                          '/spotlight/',
                          emailCampaign.spotlight.uuid
                        ]">{{ emailCampaign.spotlight.title }}</a>
                    </span>
                  </td>
                  <td class="wrap">{{ emailCampaign.relatedTag }}</td>
                  <td class="wrap">
                    <span *ngFor="
                        let file of emailCampaign.uploadedFiles;
                        let last = last
                      ">
                      <a href="{{ file.url }}" (click)="openDocument($event)">{{
                        file.name
                        }}</a><br />
                    </span>
                  </td>
                  <td>
                    <button pButton pRipple [routerLink]="[
                        '/email-campaign/edit/',
                        emailCampaign.uuid
                      ]" icon="pi pi-pencil" class="p-button-success mr-2"></button>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="6">
                    No Email Campaign found to be associated with contact.
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="footer">
                <tr *ngIf="emailCampaignTotalRecords > 5">
                  <td colspan="6" class="text-right">
                    <a href="./contact/{{ contact.uuid }}/campaigns">View More</a>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </p-panel>
        </div>

        <div class="col-12 md:col-12">
          <p-panel [toggleable]="true" [collapsed]="false" expandIcon="pi pi-chevron-left"
            collapseIcon="pi pi-chevron-down">
            <p-header>
              <span class="p-panel-title text-teal">
                <i class="pi pi-bars"></i> Email Logs
              </span>
            </p-header>
            <p-table [autoLayout]="true" [value]="mailLogs" [(selection)]="selectedMailLogs" dataKey="uuid"
              [lazy]="true" (onLazyLoad)="loadMailLogs($event)"
              styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm" [rowHover]="true"
              [rows]="5" [paginator]="true" [loading]="mailLogsLoading" [showCurrentPageReport]="true"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              [totalRecords]="mailLogTotalRecords" [alwaysShowPaginator]="false">
              <ng-template pTemplate="header">
                <tr>
                  <th>Received On</th>
                  <th>Subject</th>
                  <th>Content</th>
                  <th>Attachments</th>
                  <th>Action</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-maillog>
                <tr>
                  <td class="wrap">
                    {{ maillog.receivedOn | date : "M-d-yyyy h:mm a" }}
                  </td>
                  <td class="wrap">
                    <span class="limitedText limitText">
                      <p [innerHTML]="maillog.subject"></p>
                    </span>
                    <a href="javascript:" (click)="appUtil.showHideClampedContent($event)"
                      class="moreless hidden ml-2 p-button-danger" style="float: right; display: block">More</a>
                  </td>
                  <td class="wrap">
                    <span class="limitedText limitText">
                      {{ maillog.content }}
                    </span>
                    <a href="javascript:" (click)="appUtil.showHideClampedContent($event)"
                      class="moreless hidden ml-2 p-button-danger" style="float: right; display: block">More</a>
                  </td>
                  <td class="wrap">
                    <div *ngFor="let attachment of maillog.attachments">
                      <a href="{{ attachment.url }}" (click)="openDocument($event)" *ngIf="attachment != null">{{
                        attachment.nameWithExtension }}</a>
                    </div>
                  </td>
                  <td></td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="5">No email logs found.</td>
                </tr>
              </ng-template>
            </p-table>
          </p-panel>
        </div>
        <div class="col-12 md:col-12">
          <p-panel [toggleable]="true" [collapsed]="false" expandIcon="pi pi-chevron-left"
            collapseIcon="pi pi-chevron-down">
            <p-header>
              <span class="p-panel-title text-teal">
                <i class="pi pi-bars"></i> Portfolio Companies
              </span>
            </p-header>
            <p-table [autoLayout]="true" [value]="portfolio" [(selection)]="selectedPortfolio" dataKey="userId"
              [lazy]="true" (onLazyLoad)="loadportfolio($event)" [lazyLoadOnInit]="false"
              styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm" [rowHover]="true"
              [rows]="5" [paginator]="true" [loading]="portfolioLoading" [showCurrentPageReport]="true"
              [rowsPerPageOptions]="[5,15,25,50,75,100, { showAll: 'All' }]"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              [totalRecords]="portfolioTotalRecords" [alwaysShowPaginator]="false">
              <ng-template pTemplate="header">
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Location</th>
                  <th>Website</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-parent>
                <tr>
                  <td>
                    <a [routerLink]="['/organization/', parent.uuid]">{{
                      parent.name
                      }}</a>
                  </td>
                  <td>
                    {{ parent.companyType.replace("_", " ") | titlecase }}
                  </td>
                  <td>
                    <span *ngIf="parent.state != null">{{ parent.state | titlecase }},
                    </span>
                    <span *ngIf="parent.country != null">{{
                      parent.country | titlecase
                      }}</span>
                  </td>
                  <td>{{ parent.website }}</td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="4">
                    Selected organization does not have any Portfolio Companies
                    listed.
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </p-panel>
        </div>
      </div>
    </div>
  </div>
</div>
<p-dialog [(visible)]="passwordsDialog" (onHide)="hidePassword()" [style]="{ width: '35vw' }"
  [header]="dialogTitlePassword" [modal]="true">
  <ng-template pTemplate="content">
    <form [formGroup]="passwordForm" #pform>
      <div class="card">
        <div class="p-fluid formgrid grid">
          <div class="field col-12">
            <span class="p-float-label">
              <input type="text" pInputText [disabled]="true" value="{{ contact.email }}" />
              <label for="email">Email <span class="mandate">*</span></label>
            </span>
          </div>
          <div class="field col-12 spacing">
            <span class="p-input-icon-right">
              <i class="pi pi-eye" (click)="showHidePassword()"></i>
              <input pPassword id="password" (keyup.enter)="createCredential()" formControlName="password"
                placeholder="Password *" [ngClass]="{
                  'ng-invalid ng-dirty': pfSubmitted && pf.password.errors
                }" [feedback]="false" [showPassword]="showPassword" />
            </span>
            <span class="p-error" *ngIf="
                pfSubmitted &&
                pf.password.invalid &&
                pf.password.hasError('required')
              ">Password is required.</span>
          </div>
          <div class="field col-4">
            <button pButton label="Generate Password" class="p-button-success" (click)="generatePassword()"></button>
          </div>
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template pTemplate="footer">
    <div *ngIf="loadingSpinner == false">
      <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hidePassword()"></button>
      <button pButton pRipple label="Save" form="clform" icon="pi pi-check" class="p-button-text"
        (click)="createCredential()"></button>
    </div>
    <div *ngIf="loadingSpinner">
      <p-progressSpinner [style]="{ width: '50px', height: '50px' }" styleClass="custom-spinner" strokeWidth="8"
        fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
    </div>
  </ng-template>
</p-dialog>
<p-toast></p-toast>
<!-- <p-confirmDialog [style]="{width: '500px'}"></p-confirmDialog> -->
<p-dialog header="Confirm" [(visible)]="visible" [style]="{ 'width': '350px', 'height': '300px' }">
  <div class="p-fluid formgrid grid">
    <div class="field col-12">
      <p-message severity="warn" text="Are you sure you want to delete this Contact?"
        styleClass="warning-message"></p-message>
    </div>
  </div>
  <div class="grid" style="margin-top: 20px; text-align: right;">
    <div class="col">
      <input type="text" pInputText placeholder="Type DELETE to delete this Contact" [(ngModel)]="deleteConfirmation"
        style="width: 100%; margin-bottom: 10px;" (keyup.enter)="deleteContactOnEnter()" />
      <button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-success mr-2" (click)="deleteContact()"
        [disabled]="!isDeleteEnabled()"></button>
      <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-success mr-2"
        (click)="onCancel()"></button>
    </div>
  </div>
</p-dialog>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-overlayPanel #overlayPanel [dismissable]="true">
  <div class="buttons">
    <button pButton type="button" label="Add as Prospects" class="p-button-link" (click)="setAsProspects('buysides')">
    </button>
  </div>
  <div class="buttons">
    <button pButton type="button" label="Add as Contact" class="p-button-link" (click)="setAsContact('buysides')">
    </button>
  </div>
</p-overlayPanel>
<p-overlayPanel #op [dismissable]="true">
  <div class="buttons">
    <button pButton type="button" label="Add as Prospects" class="p-button-link" (click)="setAsProspects('sellsides')">
    </button>
  </div>
  <div class="buttons">
    <button pButton type="button" label="Add as Contact" class="p-button-link" (click)="setAsContact('sellsides')">
    </button>
  </div>
</p-overlayPanel>