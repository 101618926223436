<p-dialog [(visible)]="show" [style]="{width: '950px'}" header="Spotlight Preview" [modal]="true" styleClass="p-fluid"
    (onHide)="hide()">
    <ng-template pTemplate="content">
        <div class="container">
            <div class="spotlight-preview">
                <div class="p-fluid  grid">
                    <div class="col-12">
                        <div class="date">{{spotlight?.spotlightDate | date:'MMM dd, yyyy'}}</div>
                    </div>
                </div>
                <div class="p-fluid  grid">
                    <div class="col-4">
                        <img src="{{spotlight?.leftLogo?.url}}" class="logo" />
                    </div>
                    <div class="col-4">&nbsp;</div>
                    <div class="col-4">
                        <img src="{{spotlight?.rightLogo?.url}}" class="logo" />
                    </div>
                </div>
                <div class="p-fluid  grid">
                    <div class="col-12">
                        <h1 style="margin-top: 5px !important; margin-bottom: 5px !important;">{{spotlight?.title}}</h1>
                        <div outerHTML="{{spotlight?.content}}"></div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hide()"></button>
    </ng-template>
</p-dialog>