import { Component, OnInit, Input } from '@angular/core';
import { DealSpotlightContactService } from './deal-spotlight-contact.service';
import { ActivatedRoute } from '@angular/router';
import { LazyLoadEvent, MenuItem } from 'primeng/api';
import { User } from './../_models/index';
@Component({
    selector: 'deal-spotlight-contact',
    templateUrl: './deal-spotlight-contact.list.component.html',
    styleUrls: ['./deal-spotlight-contact.list.component.scss']
})
export class DealSpotlightContactListComponent implements OnInit {


    @Input() parentUuid: string = null;
    @Input() contactType: string = null; /* Deal, Spotlight */
    users: User[];
    menuItems: MenuItem[];
    loading: boolean = true;
    totalRecords: number = 0;
    showForm: boolean = false;
    uuid: string = null;
    selectedContacts: User[] = [];
    constructor(
        private dealSpotlightContactService: DealSpotlightContactService,
    ) {
        this.dealSpotlightContactService.listen().subscribe((m: any) => {
            this.load();
        })
    }

    ngOnInit(): void {
    }


    load(e: LazyLoadEvent = { first: 0, rows: 10, sortField: 'subject', sortOrder: 1 }) {
        var searchParams = [];
        searchParams['pageNo'] = (e.first / e.rows);
        searchParams['pageSize'] = e.rows;
        if (e.sortField && e.sortField != '')
            searchParams['sortBy'] = e.sortField;

        searchParams['sortDir'] = e.sortOrder == 0 ? 'asc' : 'desc';
        if (!this.loading)
            this.loading = true;

        this.dealSpotlightContactService.getContact(this.contactType, this.parentUuid, searchParams).subscribe(
            data => {
                let response = data as any;
                this.totalRecords = response.totalRecords;
                this.users = response.records;
                this.loading = false;
            });
    }

    new() {
        this.uuid = null;
        this.showForm = true;
    }

}
