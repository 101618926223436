import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class UsersService {
    addBuysideSelside(uuid: string, buysideIds: any[], sellsideIds: any[] ,addAsContact: boolean) {
        return this.http.post(environment.apiUrl + '/contact/' + uuid + '/screens',
            { "buysides": buysideIds, "sellsides": sellsideIds , 'addAsContact':addAsContact});
    }

    constructor(private http: HttpClient) { }

    searchContact(searchParams: any) {
        return this.http.post(environment.apiUrl + '/contacts', searchParams);
    }

    getContact(id) {
        return this.http.get(environment.apiUrl + '/contact/' + id);
    }

    addContact(data) {
        return this.http.post(environment.apiUrl + '/contact', data);
    }

    updateContact(data) {
        return this.http.put(environment.apiUrl + '/contact/' + data.uuid, data);
    }

    lookupContact(query, organizationId = undefined) {
        if(organizationId && organizationId != '')
            return this.http.get(environment.apiUrl + '/contacts/lookup', { params: { query, organizationId} });
        
        return this.http.get(environment.apiUrl + '/contacts/lookup', { params: { query} });
    }

    getOrganizations(uuid, searchParams: any) {
        return this.http.get(environment.apiUrl + '/contact/' + uuid + '/organizations', { params: searchParams });
    }

    getDuplicateUsers(id) {
        return this.http.get(environment.apiUrl + '/contacts/' + id);
    }

    deleteContact(uuid) {
        return this.http.delete(environment.apiUrl + '/contact/' + uuid);
    }

    deleteEmployee(uuid: string, newContactOwner?: any, newAccountOwner?: any) {
        const queryParams = {};

        if (newContactOwner && newContactOwner.uuid) {
            queryParams['newContactOwner'] = newContactOwner.uuid;
        }

        if (newAccountOwner && newAccountOwner.uuid) {
            queryParams['newAccountOwner'] = newAccountOwner.uuid;
        }

        const url = `${environment.apiUrl}/employee/${uuid}`;
        return this.http.post<boolean>(url, null, { params: queryParams });
    }

    getTBPEmployees() {
        return this.http.get<any>(environment.apiUrl + '/users/employees/ACTIVE');
    }

    createProfile(uuid, data: any) {
        return this.http.post(environment.apiUrl + '/contact/' + uuid + '/update', data);
    }

    getBuySides(uuid: string, searchParams: any) {
        return this.http.post(environment.apiUrl + '/contact/' + uuid + '/buysides', searchParams);
    }

    getEmailCampaigns(uuid: string, searchParams: any) {
        return this.http.post(environment.apiUrl + '/contact/' + uuid + '/email-campaigns', searchParams);
    }

    getSellSides(uuid: string, searchParams: any) {
        return this.http.post(environment.apiUrl + '/contact/' + uuid + '/sellsides', searchParams);
    }

    updateStatus(uuid: string, data: any) {
        return this.http.post(environment.apiUrl + '/contact/' + uuid + "/status", data);
    }

    createCredential(uuid: string, data: any) {
        return this.http.post(environment.apiUrl + '/contact/' + uuid + "/credential", data);
    }

    getUserCount() {
        return this.http.get(environment.apiUrl + '/users/userstatuscounts');
    }

    searchTbpUSer(searchParams: any) {
        return this.http.post(environment.apiUrl + '/employees', searchParams);
    }

    addTbpUser(data) {
        return this.http.post(environment.apiUrl + '/employee', data);
    }

    updateTbpUser(data) {
        return this.http.put(environment.apiUrl + '/employee/' + data.uuid, data);
    }

    getTbpUser(id) {
        return this.http.get(environment.apiUrl + '/employee/' + id);
    }

    downloadContact(searchParams: any) {
        return this.http.post(environment.apiUrl + '/users/download/xlsx', searchParams, {
            responseType: 'blob'
        });
    }

    getMailLogs(uuid: string, searchParams: any) {
        return this.http.post(environment.apiUrl + '/contact/' + uuid + '/email-logs', searchParams);
    }

    searchUnrecognizedEmailLogs(searchParams: any) {
        return this.http.post(environment.apiUrl + '/contact/unrecognized-email-logs', searchParams);
    }

    discardUnrecognizedEmailLog(uuid) {
        return this.http.delete(environment.apiUrl + '/contact/unrecognized-email-log/' + uuid);
    }

    quickNote(data) {
        return this.http.post(environment.apiUrl + '/quicknote', data);
    }

    private _listners = new Subject<any>();

    listen(): Observable<any> {
        return this._listners.asObservable();
    }

    refershTable(filterBy: string) {
        this._listners.next(filterBy);
    }

    mergeContacts(master: string, source1: string, source2: string, data: any) {
        let url = '/contact/' + master + "/merge";
        if (source1) {
            url = url + "/" + source1;
        }
        if (source2) {
            url = url + "/" + source2;
        }
        return this.http.post(environment.apiUrl + url, data);
    }

    getReletedProfile(uuid) {
        return this.http.get(environment.apiUrl + '/contact/' + uuid + "/related-profiles");
    }

    addReletedProfile(uuid: string, data) {
        return this.http.post(environment.apiUrl + '/contact/' + uuid + '/related-profiles', data);
    }


    getPortfolioCompanies(uuid: string, searchParams: any) {
        return this.http.post(environment.apiUrl + '/contact/' + uuid + '/portfolio-companies', searchParams);
    }

    updateContactList(fieldsToBeUpdated: string[], data: any) {
        return this.http.patch(environment.apiUrl + '/contacts/' + data.uuid,
            { "fieldsToBeUpdated": fieldsToBeUpdated, "model": data });
    }

}
