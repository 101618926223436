import { ChangeDetectorRef, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersActivityRoutingModule } from './users-activity-routing.module';
import { ToolbarModule } from 'primeng/toolbar';
import { UsersActivityComponent } from './users-activity.component';
import { TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { TableModule } from 'primeng/table';
import { TbpAutocompleteModule } from '../_components/tbp-autocomplete/tbp-autocomplete.module';
import { ButtonModule } from 'primeng/button';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ToastModule } from 'primeng/toast';
import { MenuModule } from 'primeng/menu';
import { FormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { InputTextModule } from 'primeng/inputtext';
import { TbpSearchModule } from '../_components/tbp-search/tbp-search.module';
import { TbpTableColumnModule } from '../_components/tbp-table-column/tbp-table-column.module';


@NgModule({
  declarations: [UsersActivityComponent],
  imports: [
    CommonModule,
    UsersActivityRoutingModule,
    ToolbarModule,
    TableModule,
    TbpAutocompleteModule,
    ButtonModule,
    MenuModule,
    FormsModule,TbpTableColumnModule,
    ToastModule,AutoCompleteModule,
    CalendarModule, InputTextModule,TbpSearchModule
   
    
  ],   providers: [
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
]
})
export class UsersActivityModule { }
