<div class="card">
    <p-message severity="error" [text]="errorMsg" *ngIf="errorMsg"></p-message>
    <div class="col-12">
        Query: {{queryString}}
    </div>
    <div class="p-fluid grid">


        <div class="col-12">
            <query-builder #qbuilder [ngModel]='query' (ngModelChange)="prepareQueryString($event)" [config]='config'
                [operatorMap]="operatorMap" [persistValueOnFieldChange]="true">
                <ng-container *queryInput="let rule; type: 'date'; let onChange=onChange">
                    <p-calendar appendTo="body" [showIcon]="true" dateFormat="mm-dd-yy" [(ngModel)]="rule.value"
                        (ngModelChange)="onChange($event, rule)" inputId="icon" [readonlyInput]="true"
                        showButtonBar="true" [yearNavigator]="true" [monthNavigator]="true" class="q-control-date"
                        [yearRange]="yearRange"></p-calendar>
                </ng-container>
            </query-builder>
        </div>
    </div>
    <div class="grid p-jc-center">
        <div class="col-2">
            <button pButton pRipple label="Search" class="p-button-success mr-2" (click)="search()"></button>
        </div>
    </div>
</div>