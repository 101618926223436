<div class="grid table-demo">
    <div class="col-12">
            <p-toolbar styleClass="headerSticky" *ngIf="showCheckbox">
                <ng-template pTemplate="left"></ng-template>
                <ng-template pTemplate="right">
                    <button pButton pRipple class="p-button-success mr-2"
                        (click)="addSelectedRecipients()" [disabled]="(!appUtil.isArray(selectedRecipients) || selectedRecipients.length == 0)">
                        Add Selected ({{appUtil.isArray(selectedRecipients)?selectedRecipients.length:0}})</button>
                </ng-template>
            </p-toolbar>
            <p-toolbar styleClass="headerSticky" *ngIf="!showCheckbox">
                <ng-template pTemplate="left"></ng-template>
                <ng-template pTemplate="right">
                    <button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-success mr-2"
                        [disabled]="(!appUtil.isArray(selectedRecipients) || selectedRecipients.length == 0)" (click)="delete(null, true)"></button>
                    <button pButton pRipple label="Sync With Screen" icon="pi pi-sync" class="p-button-success mr-2"
                        [disabled]="!showSync" (click)="syncWithScreen()"></button>
                    <button pButton pRipple label="Copy From Other Campaigns" icon="pi pi-copy" class="p-button-success mr-2"
                        (click)="_showOptions()"></button>
                    <button pButton pRipple label="Add Recipient" icon="pi pi-plus" class="p-button-success mr-2"
                        (click)="addRecipient()"></button>
                    <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()"
                        class="p-button-success mr-2" pTooltip="XLS" tooltipPosition="bottom"
                        label="Download"></button>
                    <p-progressSpinner *ngIf="loading" [style]="{width: '50px', height: '50px'}" 
                        styleClass="custom-spinner" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s">
                    </p-progressSpinner>
                </ng-template>
            </p-toolbar><br>
            <p-table [autoLayout]="true" #dt [value]="recipients" dataKey="uuid" [lazy]="true"
                (onLazyLoad)="getRecipientList($event)" [rowHover]="true" [(selection)]="selectedRecipients"
                styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm"
                [rows]="15" [paginator]="true" [rowsPerPageOptions]="[15,25,50,75,100]" [loading]="loading"
                [alwaysShowPaginator]="true" [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [totalRecords]="totalRecords" sortField="lastModifiedDate">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 3rem">
							<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
						</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Status</th>
                        <th>Opened</th>
                        <th>Opened Date</th>
                        <th>Response</th>
                        <th *ngIf="!showCheckbox">Actions</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-recipient>
                    <tr class="p-selectable-row">
                        <td (click)="$event.stopPropagation();">
							<p-tableCheckbox  [value]="recipient"></p-tableCheckbox>
						</td>
                        <td class="wrap">
                            <a [routerLink]="['/contact/', recipient.uuid]">{{recipient.name}}</a>
                        </td>
                        <td class="wrap">
                            <a [routerLink]="['/contact/', recipient.uuid]">{{recipient.email}}</a>
                        </td>
                        <td class="wrap">
                            {{recipient.Status}}
                        </td>
                        <td class="wrap">
                            {{recipient?.openCount}}
                        </td>
                        <td class="wrap">
                            {{recipient?.lastDateOpen}}
                        </td>
                        <td class="wrap">
                            {{recipient?.lastResponseDate}}
                        </td>
                        <td style="text-align: center;" *ngIf="!showCheckbox">
                            <button type="button" pButton icon="pi pi-list"
                                (click)="setCurrentItem(recipient); menu.toggle($event)"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="8">
                            <span>No Recipients found</span>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
    </div>
</div>
<p-menu #menu [popup]="true" [model]="recipientItems"></p-menu>
<p-confirmDialog [style]="{width: '650px'}"></p-confirmDialog>
<p-dialog [(visible)]="showOptions" [style]="{width: '30vw'}" (onHide)="hideDialog()" header="Select Email Campaign">
    <ng-template pTemplate="content" >
        <form [formGroup]="copyRecipientForm">
            <div class="p-fluid grid">
                <div class="field col-12" style="margin-top: 20px;">
                    <span class="p-float-label">
                        <tbp-autocomplete [items]="" [formControl]="f.campaign" field="title"
                                    [multiple]="false" [type]="'Email Campaign'" [exclude]="emailCampaign">
                        </tbp-autocomplete>
                        <label for="campaign">Email Campaign</label>
                    </span>
                </div>
            </div>
            <div *ngIf="f.campaign.value != null && f.campaign.value.uuid != null">
                <p-radioButton
                    styleClass="form-control p-mb-2" formControlName="copyRecipients" name="copyRecipients" value="Copy All"
                    label="Copy All" labelStyleClass="pr-2"></p-radioButton>
                <p-radioButton
                    styleClass="form-control p-mb-2" formControlName="copyRecipients" name="copyRecipients" value="Select And Copy"
                    label="Select & Copy" labelStyleClass="pr-2"></p-radioButton>
            </div>
        </form>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple class="p-button-text"
             (click)="showRecipients()" *ngIf="f.campaign.value != null && f.campaign.value.uuid != null && f.copyRecipients.value!=null">
             {{f.copyRecipients.value==='Copy All' ? 'Copy Recipients' : 'Show Recipients'}}
            </button>
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
    </ng-template>
</p-dialog>