import { AppUtil } from './../_helpers/app.util';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AccountService } from './../_services';
import { confirmValidator } from './../_helpers/confirmed.validator';
import { ConfigurationService } from '../configuration/configuration.service';
import { MessageService } from 'primeng/api';

@Component({
    templateUrl: './register.component.html',
    providers: [MessageService]
})
export class RegisterComponent implements OnInit {
    form: UntypedFormGroup;
    loading = false;
    submitted = false;
    showPassword = false;
    hasError:Function;

    options = [];
    constructor(
        private formBuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private messageService: MessageService,
        private accountService: AccountService,
        private lookupService: ConfigurationService,
        public appUtil:AppUtil) { }

    ngOnInit() {
        this.hasError = this.appUtil.hasError;
        this.form = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            email: ['', [Validators.required, Validators.pattern(this.appUtil.emailPattern)]],
            acceptTermsAndConditions: ['', Validators.required],
            password: ['', [Validators.required, Validators.minLength(6)]],
            confirmPassword: ['']
        }, {
            validator: confirmValidator('password', 'confirmPassword')
        });


    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }
        let { firstName, lastName, email, acceptTermsAndConditions, password, confirmPassword } = this.form.value;
        let dataToPost: any = { firstName, lastName, email, acceptTermsAndConditions, password, confirmPassword };
        if (this.f.password.value != this.f.confirmPassword.value) {
            this.f.confirmPassword.setErrors({ 'message': 'Passwords do not match' });
            return
        }
        else {
            dataToPost.password = this.appUtil.passwordEncrypt(this.f.password.value);
            dataToPost.confirmPassword = dataToPost.password;
        }
        this.loading = true;
        this.accountService.register(dataToPost)
            .pipe(first())
            .subscribe(
                data => {
                    this.loading = false;
                    this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'An email with activation link has been sent to the registered email.Please open the email and click on the link to activate your profile.', life: 6000 });
                    setTimeout(() => {
                        this.router.navigate(['../login'], { relativeTo: this.route });
                    }, 6000);

                    this.submitted = false;
                    this.form.reset();
                },
                error => {
                    let errors = error.error;
                    if (errors.code && errors.code == 'VALIDATION_ERROR') {
                        for (var i = 0; i < errors.subErrors.length; i++) {
                            this.f[errors.subErrors[i].field].setErrors({ 'message': errors.subErrors[i].message });
                        }
                    }
                    this.loading = false;
                }

            );
    }

    showHidePassword() {
        this.showPassword = !(this.showPassword);
    }
}