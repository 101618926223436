import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { DealSpotlightContactService } from './deal-spotlight-contact.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsersService } from '../users/users.service';
@Component({
    selector: 'spotlight-contact-edit',
    templateUrl: './deal-spotlight-contact.edit.component.html',
    styleUrls: ['./deal-spotlight-contact.edit.component.scss'],
    providers: [MessageService]
})
export class DealSpotlightContactEditComponent implements OnInit {

    @Input() show: boolean = false;
    @Output() showChange = new EventEmitter<boolean>();
    @Input() contactUuid: string = null;
    @Output() contactUuidChange = new EventEmitter<string>();
    @Input() parentUuid: string = null;
    @Input() closeAfterSave: boolean = true;
    @Input() contactType: string = null; /* organization, contact */
    @Output() afterSave = new EventEmitter<any>();
    formInitialValue: any = null;
    @ViewChild('contact') formElement;
    title: string = "Add Contact";
    form: UntypedFormGroup;
    participantResults: [];
    submitted: boolean = false;
    constructor(
        private route: ActivatedRoute,
        private messageService: MessageService,
        private formBuilder: UntypedFormBuilder,
        private usersService: UsersService,
        private dealSpotlightContactService: DealSpotlightContactService,
        private http: HttpClient) {
        this.form = this.formBuilder.group({
            uuid: [],
            contact: [null, Validators.required]
        })
        this.formInitialValue = this.form.value;
    }

    ngOnInit(): void {
    }



    ngOnChanges(changes: SimpleChanges) {
        this.form.reset(this.formInitialValue);
        if (this.show) {
            if (this.contactUuid == null || this.contactUuid == '') {
                this.title = "Add Contact";
            }
        }
    }

    get f() { return this.form.controls; }

    saveContact() {
        this.submitted = true;
        if (this.form.invalid) {
            return;
        }
        let dataToPost = this.f.contact.value;
        let apiToCall: any;
        apiToCall = this.dealSpotlightContactService.addContact(this.contactType, this.parentUuid, dataToPost);
        apiToCall.subscribe(
            data => {
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: "Save Successfully", life: 3000 });
                this.submitted = false;
                this.afterSave.emit(data);
                if (this.closeAfterSave) {
                    this.dealSpotlightContactService.filter(data);
                    this.hide();
                }
            },
            error => {
                let errors = error.error;
                if (errors.code && errors.code == 'VALIDATION_ERROR') {
                    for (var i = 0; i < errors.subErrors.length; i++) {
                        this.f[errors.subErrors[i].field].setErrors({ 'message': errors.subErrors[i].message });
                    }
                }
            }
        );
    }

    hide() {
        this.form.reset(this.formInitialValue);
        this.submitted = false;
        this.show = false;
        this.showChange.emit(false);
    }

    searchParticipant(event) {
        this.usersService.lookupContact(event.query).subscribe(data => {
            this.participantResults = data as any;
        });

    }

}
