import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ConfigurationService } from "../configuration/configuration.service";
import { Component, OnInit, ViewChild, NgZone, OnDestroy } from '@angular/core';
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../app.breadcrumb.service';
import { LookupValue } from '../_models/lookup-value';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { MenuItem } from 'primeng/api';
import { UntypedFormBuilder, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AppUtil } from '../_helpers';
import { HelpService } from '../help/help.service';
@Component({
    selector: 'app-list-global-lookups',
    templateUrl: './configuration.list.component.html',
    styleUrls: ['./configuration.list.component.scss'],
    providers: [MessageService, ConfirmationService]
})
export class ConfigurationListComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription = new Subscription();
    uuid: string;
    keyList = [];
    scrollTop: string;
    lookupValues: LookupValue[];
    selectedValue: LookupValue;
    selectedValues: LookupValue[];
    stateDialog: boolean = false;
    stateTitle: string;
    cUuid: string;
    @ViewChild('dt') table: Table;
    loading: boolean;
    lookupDialog: boolean = false;
    loadingSpinner: boolean = false;
    currentMenuData: any;
    menuItems: MenuItem[];
    selectedKey: any;
    selectedKeyId: string;
    showMerge: boolean;
    mergeForm: UntypedFormGroup;
    suggestionList: LookupValue[];
    clsSpinner: boolean;
    previousUrl: string | null = null;
    currentUrl: string | null = null;
    constructor(
        private service: ConfigurationService,private route: ActivatedRoute,
        private breadcrumbService: BreadcrumbService,private formBuilder: UntypedFormBuilder,
        private messageService: MessageService, private confirmationService: ConfirmationService,
        private ngZone: NgZone,
        private helpService:HelpService,private router: Router,
        public appUtil: AppUtil,
        private location: Location
    ) {
        this.breadcrumbService.setItems([
            { label: 'Global Lookup' }
        ]);
        this.loadMergeForm();
        this.showMerge = false;
        this.clsSpinner = false;
    }

    ngOnInit(): void {
        this.previousUrl = this.helpService.getPreviousUrl();
        this.currentUrl = this.helpService.getCurrentUrl();
        const storedScrollTop = localStorage.getItem('globalLookupScroll');
        if (storedScrollTop) {
            window.scrollTo(0, parseInt(storedScrollTop));
        }
        window.addEventListener('scroll', this.saveScrollPosition);
        this.cUuid = '';
        this.selectedKey = {name:'',id:''};
        this.service.getLookupKeys().subscribe(
            data => {
                
                this.keyList = (data as any);
                this.keyList.unshift({id:'COUNTRY', name:'Country'});
                let selected = this.keyList[0];
                if(this.route.snapshot.params.keyid)
                {
                    selected = this.keyList.find(field => field.id === this.route.snapshot.params.keyid);
                }
                if(selected == undefined)
                {
                    selected = this.keyList[0];
                }
                this.keyList = this.keyList.filter(function( element ) {
                    return element.id !== 'EMAIL_PREFERENCES';
                });
                this.selectedKey = selected;
                this.selectedKeyId = selected.id;
                this.fetchLookupValues();
            });
        this.currentMenuData = { id: '' };
        this.menuItems = [];
    }

    fetchLookupValues() {
        if (this.selectedKeyId == 'COUNTRY') {
            this.service.getCountries().subscribe(
                data => {
                    this.lookupValues = data as any;
                    const data1 = localStorage.getItem('globalLookupScroll');
                    if (data1) {
                      this.ngZone.runOutsideAngular(() => {
                        setTimeout(() => {
                          window.scrollTo(0, parseInt(data1));
                        }, 0);
                      });
                    }
                });
        }
        else {
            this.service.getLookupValues(this.selectedKeyId).subscribe(
                data => {
                    this.lookupValues = data[this.selectedKeyId];
                });
        }
        this.location.go('/globalLookup/' + this.selectedKeyId)
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
         window.removeEventListener('scroll', this.saveScrollPosition);
         localStorage.setItem('globalLookupScroll', this.scrollTop); 
     }
     
     saveScrollPosition = (): void => {
         this.scrollTop = window.scrollY.toString();  
 
     }
    onKeyChange(event: any) {
         this.selectedKey = this.keyList.find(field => field.id === this.selectedKeyId);
        this.fetchLookupValues();
    }

    edit(lookupValue: LookupValue): void {
        this.lookupDialog = true;
        this.uuid = lookupValue.uuid;
    }

    editState(state, lookupvalue) {
        this.stateTitle = lookupvalue.name;
        this.cUuid = lookupvalue.uuid;
        this.uuid = state.uuid;
        this.stateDialog = true;
    }

    openNew() {
        this.uuid = undefined;
        this.lookupDialog = true;
    }

    newState(lookupvalue) {
        this.uuid = undefined;
        this.stateTitle = lookupvalue.name;
        this.cUuid = lookupvalue.uuid;
        this.stateDialog = true;
    }

    delete(lookupValue: LookupValue) {
        this.confirmationService.confirm({
            message: 'Are you sure you want to delete ' + lookupValue.value + '?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.service.deleteLookupValue(lookupValue.uuid).subscribe(
                    data => {
                        this.lookupValues = this.lookupValues.filter(val => val.uuid !== lookupValue.uuid);
                        this.selectedValue = {};
                    })
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Lookup Value Deleted.', life: 3000 });
            }
        });
    }

    setCurrentItem(lookupValue) {
        let items = [
            {
                label: 'Edit',
                icon: 'pi pi-plus',
                command: (event) => {
                    this.edit(lookupValue);
                }
            }
        ];
        this.currentMenuData = lookupValue;
        this.menuItems = items;
    }

    loadMergeForm() {
        this.mergeForm = this.formBuilder.group({
            master: ['', [Validators.required]],
            source1: ['', [Validators.required]],
            source2: []
        });
    }

    searchItem($event, field) {

        this.suggestionList = this.lookupValues.filter((currentValue, index) => {
            let item = (currentValue.value.indexOf($event.query) != -1);
            let isMasterValue = (!this.mf.master.value || this.mf.master.value.uuid != currentValue.uuid);
            let isSource1Value = (!this.mf.source1.value || this.mf.source1.value.uuid != currentValue.uuid);
            let isSource2Value = (!this.mf.source2.value || this.mf.source2.value.uuid != currentValue.uuid)
            return item && ((field == 'master') ? (isSource1Value && isSource2Value) : (isMasterValue && ((field == 'source1') ? isSource2Value : isSource1Value)));
        });
    }

    get mf() {
        return this.mergeForm.controls;
    }

    showMergeOptions() {

        this.showMerge = (this.lookupValues == undefined || this.lookupValues.length<2) ? false : true;
        this.mergeForm.reset();
    }

    mergeLookups(){
        this.mergeForm.markAllAsTouched();
        if (!this.mergeForm.invalid) {
            this.clsSpinner = true;
            this.service.mergeLookups(this.mf.master.value.uuid, this.mf.source1.value.uuid, this.mf.source2.value ? this.mf.source2.value.uuid : null).
            subscribe(
                data => {
                    this.showMerge = false;
                    this.fetchLookupValues();
                    this.messageService.add({ severity: 'success', summary: 'Merge Successful', detail: "Merge was successfull.", life: 3000 });
                },
                error => {
                    let errors = error.error;
                    if (errors.code && errors.code == 'VALIDATION_ERROR') {
                        for (var i = 0; i < errors.subErrors.length; i++) {
                            this.mf[errors.subErrors[i].field].setErrors({ 'message': errors.subErrors[i].message });
                        }
                    }
                },
                () => {
                    this.clsSpinner = false;
                }
            );
        }
    }

    return(){
        if(this.previousUrl == this.currentUrl){
            window.history.back();
        }
        this.router.navigateByUrl(this.previousUrl);
    
        delete this.previousUrl;
    }
    showAddButton(): boolean {
        const keyIds = [
            'BUYSIDE_PROSPECT_LOOKUP_1',
            'SELLSIDE_PROSPECT_LOOKUP_1',
            'SELLSIDE_PROSPECT_LOOKUP_2',
            'BUYSIDE_PROSPECT_LOOKUP_2'
        ];   
        return !keyIds.includes(this.selectedKeyId);
    }
    
}
