import { AppUtil } from './../_helpers';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AccountService } from './../_services';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'app-forgotpassword',
    templateUrl: './forgotpassword.component.html',
    styleUrls: ['./forgotpassword.component.scss'],
    providers: [MessageService, ConfirmationService]
})
export class ForgotpasswordComponent implements OnInit {

    form: UntypedFormGroup;
    loading = false;
    submitted = false;
    errorCode: string;
    linkForm: UntypedFormGroup;
    linkFormSubmitted = false;
    linkErrorCode: string;
    showDialog: boolean = false;
    hasError:Function;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountService,
        private messageService: MessageService,
        public appUtil:AppUtil  
    ) { }

    ngOnInit(): void {
         this.errorCode = '';
         this.hasError = this.appUtil.hasError;

        this.form = this.formBuilder.group({
            email: ['', [Validators.required, Validators.pattern(this.appUtil.emailPattern)]]
        });
        this.linkForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.pattern(this.appUtil.emailPattern)]]
        });
    }

    get f() { return this.form.controls; }

    onSubmit() {
        this.submitted = true;
        this.errorCode = '';
        this.messageService.clear();
        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        this.accountService.forgotPassword(this.f.email.value)
            .pipe(first())
            .subscribe(
                data => {
                    this.errorCode = 'SUCCESS';
                    this.loading = false;
                },
                error => {
                    let errors = error.error;
                    if (errors.code) {
                        this.errorCode = errors.code;
                    }
                    this.loading = false;
                }
            );
    }

    showLinkForm() {
        this.linkFormSubmitted = false;
        this.linkErrorCode = '';
        this.linkForm.controls.email.reset();
        this.linkForm.controls.email.setValue(this.f.email.value);
        this.showDialog = true;
    }

    hideDialog() {
        this.linkErrorCode = '';
        this.linkForm.controls.email.reset();
        this.showDialog = false;
    }

    sendActivationEmail() {
        this.linkFormSubmitted = true;
        this.linkErrorCode = '';
        if (this.linkForm.invalid) {
            return;
        }
        this.messageService.clear();
        this.accountService.sendActivationEmail(this.linkForm.controls.email.value).subscribe(
            data => {
                this.linkErrorCode = 'SUCCESS';
            },
            error => {
                let errors = error.error;
                if (errors.code) {
                    this.linkErrorCode = errors.code;
                }
            }
        );
    }

    goToSignUp() {
        this.router.navigate(['/account/register']);
    }

}
