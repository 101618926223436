import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
@Injectable({
    providedIn: 'root'
})
export class NoteService {

    constructor(private http: HttpClient) { }

    addNote(data: any) {
        return this.http.post(environment.apiUrl + '/note', data);
    }

    updateNote(noteuuid: string, data: any) {
        return this.http.put(environment.apiUrl + '/note/' + noteuuid, data);
    }

    getNote(uuid: string) {
        return this.http.get(environment.apiUrl + '/note/' + uuid);
    }

    getNotes(noteType: string, parentUuid: any, searchParams: any) {
        return this.http.post(environment.apiUrl + '/' + noteType + '/' + parentUuid + '/notes', searchParams);
    }

    searchNotes(searchParams: any){
        return this.http.post(environment.apiUrl + '/notes', searchParams);
    }

    getContacts(uuid: string, searchParams: any) {
        return this.http.post(environment.apiUrl + '/organization/' + uuid + '/contacts', searchParams);
    }
    getContact(uuid: string) {
        return this.http.get(environment.apiUrl + '/contact/' + uuid);
    }

}
