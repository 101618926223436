    <div class="formgrid grid headerSticky mb-2">
        <div class="field col-12 md:col-12 text-right" *ngIf="loadingSpinner == false">
            <p-button label="Print" icon="pi pi-print" iconPos="left" styleClass="mr-2">
            </p-button>
            <p-button label="Edit" icon="pi pi-pencil" iconPos="left" styleClass="mr-2" (click)="editBlindProfile()">
            </p-button>
        </div>
        <div class="field col-12 md:col-12 text-right" *ngIf="loadingSpinner">
            <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
                fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
        </div>
    </div>
	<div style="border: 2px solid #000; padding: 30px;">
        <div class="grid p-fluid mt-1">
            <div class="field col-12 md:col-11">
                <div class="grid p-fluid">
                    <div class="field col-12 pb-0">
                        <p-inplace #ipe1 styleClass="normal">
                            <ng-template pTemplate="display">
                                <span style="font-size: 35px; font-weight: bold;">{{blindProfile?.title}}</span>
                            </ng-template>
                            <ng-template pTemplate="content">
                                <input type="text" [(ngModel)]="fieldData.title.value" pInputText [ngClass]="{ 'ng-invalid': fieldData.title.error!=null }">
                                <span class="p-error" *ngIf="fieldData.title.error!=null">{{fieldData.title.error}}</span>
                                <span style="margin-top: 5px; display: block; text-align: end;">
                                    <p-button icon="pi pi-check" class="mr-1" (click)="patch(ipe1,'title')" [loading]="fieldData.title.loading"></p-button>
                                    <p-button icon="pi pi-times" (click)="close(ipe1,'title')"></p-button>
                                </span>
                            </ng-template>
                        </p-inplace>
                        <hr style="border-top: 2px solid #000; margin: 0;">
                    </div>
                    <div class="field col-12">
                        <p-inplace #ipe2 styleClass="normal">
                            <ng-template pTemplate="display">
                                <span style="font-size: 20px; font-style: italic;">{{blindProfile?.headline}}</span>
                            </ng-template>
                            <ng-template pTemplate="content">
                                <input type="text" [(ngModel)]="fieldData.headline.value" pInputText [ngClass]="{ 'ng-invalid': fieldData.headline.error!=null }">
                                <span class="p-error" *ngIf="fieldData.headline.error!=null">{{fieldData.headline.error}}</span>
                                <span style="margin-top: 5px; display: block; text-align: end;">
                                    <p-button icon="pi pi-check" class="mr-1" (click)="patch(ipe2,'headline')" [loading]="fieldData.headline.loading"></p-button>
                                    <p-button icon="pi pi-times" (click)="close(ipe2,'headline')"></p-button>
                                </span>
                            </ng-template>
                        </p-inplace>
                    </div>
                </div>                
            </div>
            <div class="field col-12 md:col-1">
                <p-image src="assets/layout/images/tbp-logo-grey.jpg" width="100"></p-image>
            </div>
        </div>
		<div class="grid p-fluid mt-1">
			<div class="field col-12 md:col-5">
				<h2 style="text-align: center; font-style: italic;" class="mb-5">Offerings</h2>
					<h4 style="font-style: italic;">Services</h4>
					<p-inplace #ipe3 styleClass="wyswyg">
						<ng-template pTemplate="display">
							<span style="font-size: 20px; font-style: italic;" [innerHTML]="blindProfile?.services"></span>
						</ng-template>
						<ng-template pTemplate="content">
                            <editor #editor  [(ngModel)]="fieldData.services.value" pInputText [ngClass]="{ 'ng-invalid': fieldData.services.error!=null }"></editor>
                            <span style="margin-top: 5px; display: block; text-align: end;">
                                <p-button icon="pi pi-check" class="mr-1" (click)="patch(ipe3,'services')" [loading]="fieldData.services.loading"></p-button>
                                <p-button icon="pi pi-times" (click)="close(ipe3,'services')"></p-button>
                            </span>
						</ng-template>
					</p-inplace>
					<h4 style="font-style: italic;">Customers</h4>
					<p-inplace #ipe4  styleClass="wyswyg">
						<ng-template pTemplate="display">
							<span style="font-size: 20px; font-style: italic;" [innerHTML]="blindProfile?.customers"></span>
						</ng-template>
						<ng-template pTemplate="content">
                            <editor #editor  [(ngModel)]="fieldData.customers.value" pInputText [ngClass]="{ 'ng-invalid': fieldData.customers.error!=null }"></editor>
                            <span style="margin-top: 5px; display: block; text-align: end;">
                                <p-button icon="pi pi-check" class="mr-1" (click)="patch(ipe4,'customers')" [loading]="fieldData.customers.loading"></p-button>
                                <p-button icon="pi pi-times" (click)="close(ipe4,'customers')"></p-button>
                            </span>
						</ng-template>
					</p-inplace>
					<h4 style="font-style: italic;">Business</h4>
					<p-inplace #ipe5 styleClass="wyswyg">
						<ng-template pTemplate="display">
							<span style="font-size: 20px; font-style: italic;" [innerHTML]="blindProfile?.business"></span>
						</ng-template>
						<ng-template pTemplate="content">
                            <editor #editor  [(ngModel)]="fieldData.business.value" pInputText [ngClass]="{ 'ng-invalid': fieldData.business.error!=null }"></editor>
                            <span style="margin-top: 5px; display: block; text-align: end;">
                                <p-button icon="pi pi-check" class="mr-1" (click)="patch(ipe5,'business')" [loading]="fieldData.business.loading"></p-button>
                                <p-button icon="pi pi-times" (click)="close(ipe5,'business')"></p-button>
                            </span>
						</ng-template>
					</p-inplace>
			</div>
			<div class="field col-12 md:col-2">
				<p-divider layout="vertical" type="dashed"></p-divider>
			</div>
			<div class="field col-12 md:col-5">
				<h2 style="text-align: center; font-style: italic;" class="mb-5">Financials</h2>
                <p-table [value]="financials" dataKey="id"  [scrollable]="true"  scrollHeight="400px" scrollDirection="both"
                (onEditComplete)="editCompleted($event)">
                    <ng-template pTemplate="header">
                        <tr>
                            <ng-container *ngFor="let year of years; let i = index">
                                <th  style="width:150px" pFrozenColumn *ngIf="year == 'dummy'"></th>
                                <th  style="width:150px" *ngIf="year != 'dummy'">
                                    {{year}} <a href="#" style="margin-left: 5px;"><i class="pi pi-eye-slash"></i></a>
                                </th>
                            </ng-container>
                            
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-financial let-editing="editing">
                        <tr>
                            <ng-container *ngFor="let year of years; let i = index">
                                <td *ngIf="year == 'dummy'" style="width:150px" pFrozenColumn>
                                    {{financial[year]}}
                                </td>

                                <td *ngIf="year != 'dummy'" style="width:150px" pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input pInputText type="text" [(ngModel)]="financial[year]"  [ngModelOptions]="{standalone: true}" >
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{financial[year]}}
                                        </ng-template>
                                    </p-cellEditor>
                                    
                                </td>
                            </ng-container>

                        </tr>
                    </ng-template>
                </p-table>
				
					<h4 style="font-style: italic;">Investment Considerations</h4>
					<p-inplace #ipe7 styleClass="wyswyg">
						<ng-template pTemplate="display">
							<span style="font-size: 20px; font-style: italic;" [innerHTML]="blindProfile?.investmentConsiderations"></span>
						</ng-template>
						<ng-template pTemplate="content">
                            <editor #editor  [(ngModel)]="fieldData.investmentConsiderations.value" pInputText [ngClass]="{ 'ng-invalid': fieldData.investmentConsiderations.error!=null }"></editor>
                            <span style="margin-top: 5px; display: block; text-align: end;">
                                <p-button icon="pi pi-check" class="mr-1" (click)="patch(ipe7,'investmentConsiderations')" [loading]="fieldData.investmentConsiderations.loading"></p-button>
                                <p-button icon="pi pi-times" (click)="close(ipe7,'investmentConsiderations')"></p-button>
                            </span>
						</ng-template>
					</p-inplace>
			</div>
		</div>
		<div class="grid p-fluid mt-1">
			<div class="field col-12 md:col-4" style="text-align: right;">
				<span>&nbsp;</span>
			</div>
			<div class="field col-12 md:col-4" style="text-align: center;">
				<span>Confidential</span>
			</div>
			<div class="field col-12 md:col-4" style="text-align: right;">
				<span>&copy; 2021 True Blue Partners All rights reserved.</span>
			</div>
		</div>
	</div>