import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class EngagementService {

    constructor(private http: HttpClient) { }

    searchEngagement(searchParams: any) {
        return this.http.post(environment.apiUrl + '/engagements', searchParams);
    }

    getEngagement(uuid: string) {
        return this.http.get(environment.apiUrl + '/engagement/' + uuid);
    }

    addEngagement(data) {
        return this.http.post(environment.apiUrl + '/engagement', data);
    }

    updateEngagement(data) {
        return this.http.put(environment.apiUrl + '/engagement/' + data.uuid, data);
    }

    lookupEngagement(query) {
        return this.http.get(environment.apiUrl + '/engagement/lookup', { params: { query } });
    }

    getContact(uuid: string, searchParams: any) {
        return this.http.get(environment.apiUrl + '/engagement/' + uuid + '/dealteams', searchParams);
    }

    updateDealTeam(uuid: string, data: any) {
        return this.http.post(environment.apiUrl + '/engagement/' + uuid + '/dealteam', data);
    }

    getBlindProfile(sellUuid: string, uuid: string) {
        return this.http.get(environment.apiUrl + '/engagement/' + sellUuid + '/blindprofile/' + uuid);
    }

    addBlindProfile(sellUuid: string, data: any) {
        return this.http.post(environment.apiUrl + '/engagement/' + sellUuid + '/blindprofile/', data);
    }

    UpdateBlindProfile(sellUuid: string, uuid: string, data: any) {
        return this.http.put(environment.apiUrl + '/engagement/' + sellUuid + '/blindprofile/' + uuid, data);
    }

    getMasterProspect(uuid: string, searchParams: any) {
        return this.http.get(environment.apiUrl + '/engagement/' + uuid + '/masterprospect', searchParams);
    }

    masterProspects(uuid: string, data: any) {
        return this.http.post(environment.apiUrl + '/engagement/' + uuid + '/masterprospect', data);
    }

    getCampaign(uuid: string, searchParams: any) {
        return this.http.post(environment.apiUrl + '/engagement/' + uuid + '/campaign', searchParams);
    }

}
