export class FieldInfo {
    label:string;
    property:string;
    visible:string; // A=> Always visible, T=>Visible, F=> Not visible, H => Always hidden
    sortable:boolean = true;
    columnWidth:string;
    columnIndex:number; // sequence of the columnd to be displayed 
    type:string; // type of object like date, enum, string, account, contact etc,
    linkPrefix:string;
    linkSuffix:string;
    linkType:string;
    labelProperty:string;
    valueProperty:string;
    searchable:boolean;
    forSearchTermQuery:boolean;
    searchType:string = null;
    searchOptions:any[];
}

export function makeFieldInfo(options?: Partial<FieldInfo>)
{
    const defaults = {
        label:null,
        property:null,
        visible: 'T',
        sortable: true,
        columnWidth: null,
        columnIndex:999,
        type: null,
        linkPrefix: null,
        linkSuffix:null,
        linkType: 's',
        labelProperty:'name',
        valueProperty:'uuid',
        searchable:true,
        searchType:null,
        searchOptions:null,
        forSearchTermQuery:false
      };

      if(defaults.searchable && defaults.searchType == null)
      {
        defaults.searchType = 'textsearch';
      }

      return {
        ...defaults,
        ...options,
      };
}
