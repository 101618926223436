import { MergeOrganizationComponent } from './merge-organization.component';
import { ClassificationResolver } from './../classification/classification.resolver';
import { UserResolver } from './../users/user.resolver';
import { CountryResolver } from './../configuration/country.resolver';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment } from '@angular/router';
import { OrganizationAddComponent } from './organization.add.component';
import { OrganizationViewComponent } from './organization.view.component';
import { ConfigurationResolver } from '../configuration/configuration.resolver';
import { OrganizationResolver } from './organization.resolver';
import { HelpResolver } from './../help/help.resolver'
import { OrganizationsListComponent } from './organizations-list.component';

const routes: Routes = [

    {
        matcher: (url) => { // urls like organizations, buyside/:uuid/organization/add
            if ((url.length == 1 && url[0].path == 'organizations') || (url.length ==4 && url[2].path == 'organization'
                || (url.length == 1 && url[0].path == 'organization')
                )) {
                return {
                    consumed: url,
                    posParams: {
                        source: url.length ==4?url[0]:new UrlSegment('', {}),
                        sourceuuid: url.length ==4?url[1]:new UrlSegment('', {}),
                        showCheckbox: new UrlSegment(url.length ==4?'true':'false', {})
                    }
                };
            }
            return null;
        },
        component: OrganizationsListComponent,
        resolve: {
            lookupList: ConfigurationResolver,
            countries: CountryResolver,
            tbpEmployees: UserResolver,
            classificationList: ClassificationResolver
        }
    },
    {
        path: 'organization/new', component: OrganizationAddComponent,
        resolve: {
            lookupList: ConfigurationResolver,
            countries: CountryResolver,
            tbpEmployees: UserResolver,
            classificationList: ClassificationResolver,
            helpList: HelpResolver
        }
    },
    {
        path: 'organization/:uuid', component: OrganizationViewComponent,
        resolve: {
            countries: CountryResolver,
            tbpEmployees: UserResolver,
            organization: OrganizationResolver,
            lookupList: ConfigurationResolver,
            classificationList: ClassificationResolver
        }
    },
    {
        path: 'organization/edit/:uuid', component: OrganizationAddComponent,
        resolve: {
            lookupList: ConfigurationResolver,
            countries: CountryResolver,
            tbpEmployees: UserResolver,
            classificationList: ClassificationResolver,
            organization: OrganizationResolver,
            helpList: HelpResolver
            
        }
    },
    {
        path: 'organization/merge/:uuid', component: MergeOrganizationComponent,
        resolve: {
            organization: OrganizationResolver,
            helpList: HelpResolver,
            lookupList: ConfigurationResolver,
        }
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [ConfigurationResolver, CountryResolver, UserResolver, ClassificationResolver, OrganizationResolver, HelpResolver]
})
export class OrganizationRoutingModule { }