import { Table } from 'primeng/table';
import { filter } from "rxjs/operators";
import { DomHandler } from 'primeng/dom';
import { SearchHeler } from "./../_helpers/search-helper";
import { FieldInfo, makeFieldInfo } from "./../_models/field-info";
import { FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit, ViewChild, Input, NgZone, OnDestroy, ViewEncapsulation } from "@angular/core";
import { BreadcrumbService } from "../app.breadcrumb.service";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { ConfirmationService, LazyLoadEvent, MenuItem } from "primeng/api";
import { UsersService } from "./users.service";
import { DataService } from "../_services/data.service";
import { MessageService } from "primeng/api";
import { saveAs } from "file-saver";
import { Subscription } from 'rxjs';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { AccountService } from "../_services";
import { AppUtil } from "../_helpers";
import { Classification, RuleSet, User } from "../_models";
import { DatePipe } from '@angular/common';
import { OrganizationService } from '../organization/organization.service';
import { Location } from "@angular/common";
import { HelpService } from '../help/help.service';
import { environment } from 'src/environments/environment';

interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}

interface DataToPost {
  level1?: string;
  level2?: string;
  level3?: string;
  vertical?: string;
  ecosystem?: string;
  [key: string]: any;
}
@Component({
  selector: "contact-list",
  templateUrl: "./contact-list.component.html",
  styleUrls: ["./contact-list.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [MessageService, ConfirmationService, DatePipe]
})
export class ContactListComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  @ViewChild('dt') dataTable: Table;
  form: UntypedFormGroup;
  uploadPop: boolean = false;
  contacts: User[];
  scrollTop: string;
  selectedContacts: User[];
  loading: boolean;
  previousSearch: any;
  contactItems: MenuItem[];
  currentMenuData: any;
  selectedStatus: string;
  totalRecords: number = 0;
  uploadItems: MenuItem[];
  lookupList: any = { ACCOUNT_SOURCE: [] };
  myGroup: FormGroup;
  classificationList: any = {
    CATEGORY_LEVEL1: [],
    CATEGORY_LEVEL2: [],
    CATEGORY_LEVEL3: [],
    VERTICAL: [],
    ECOSYSTEM: [],
  };
  status: any = [
    { name: "Select Status", code: "" },
    { name: "Pending Activation", code: "PENDING_ACTIVATION" },
    { name: "Pending Profile", code: "PENDING_PROFILE" },
    { name: "Pending Verification", code: "PENDING_VERIFICATION" },
    { name: "Profile With No Credentials", code: "NO_CREDENTIALS" },
    { name: "Active Profile", code: "ACTIVE" },
    { name: "Revoked Profile", code: "REVOKED" },
    { name: "Account Manager Assigned", code: "WITH_OWNER" },
    { name: "Account Manager Not Assigned", code: "WITHOUT_OWNER" },
  ];
  searchTerm: string | null = null;
  tasksDialog: boolean = false;
  notesDialog: boolean = false;
  userExcel: {};
  queryBuilderFields: any = {};
  queryBuilderQuery: any = {};
  currentQuery: string = "";
  /////////// Create Credentials
  credentialDialog: boolean;
  dialogTitleCredential: string;
  credentialForm: UntypedFormGroup;
  pfSubmitted: boolean;
  showPassword = false;
  loadingSpinner: boolean = false;
  userCredentials: boolean = true;
  source: string = null;
  sourceuuid: string = null;
  showCheckbox: boolean = false;
  searchUrl: string = "/contacts";
  showAddDialog: boolean = false;
  selectedColumns: any[];
  oldVal: any[] = [];
  pageSize: number;
  firstRow: number;
  sortField: string;
  sortOrder: number;
  pageId: number;
  globalSortField: any = null;
  //search fields
  fieldInfo: FieldInfo[];
  orgUuid: string;
  contactDeleted = false;
  tbpEmployees: any[] = [];
  organizationResults: any;
  selectedAccountOwner: any;
  filteredAccountOwner: any[] | undefined;
  editingModes: { [key: string]: boolean } = {};
  previousUrl: string | null = null;
  currentUrl: string | null = null;
  term: string = "";
  nextSearchAfter: number | null = null;
  nextFieldId: number | null = null;
  firstValue: number | null = null;
  searchAfterStack: { searchAfter: number; fieldId: number }[] = [];
  constructor(
    private route: ActivatedRoute,
    private messageService: MessageService,
    private userService: UsersService,
    private dataService: DataService,
    private breadcrumbService: BreadcrumbService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountService,
    public appUtil: AppUtil,
    public searchHelper: SearchHeler,
    private confirmationService: ConfirmationService,
    private datePipe: DatePipe,
    private organizationService: OrganizationService,
    private ngZone: NgZone,
    private location: Location,
    private helpService: HelpService
  ) {
    this.myGroup = new FormGroup({
      firstName: new FormControl(''), // Initialize your form control with a default value if needed
      // Add other form controls as needed
    });
    this.breadcrumbService.setItems([{ label: "Contact Listing" }]);
    this.selectedStatus = "";
    this.loading = true;
    this.currentMenuData = { id: "" };
    this.contactItems = [];
    this.firstRow = 0;
    this.sortField = "lastModifiedDate";
    this.sortOrder = -1;
    this.pageSize = this.appUtil.pageSize;
    this.globalSortField = this.sortField;
    this.uploadItems = [
      {
        label: "Contact Template",
        command: () => {
          this.appUtil.downloadTemplate("contact-template.xlsx");
        },
      },
    ];
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.searchTerm = params['search_term'] || null;
    });
    this.previousUrl = this.helpService.getPreviousUrl();
    this.currentUrl = this.helpService.getCurrentUrl();
    const storedScrollTop = localStorage.getItem('CONTACTSCROLL');
    if (storedScrollTop) {
      window.scrollTo(0, parseInt(storedScrollTop));
    }
    window.addEventListener('scroll', this.saveScrollPosition);
    this.classificationList = this.route.snapshot.data["classificationList"];
    this.lookupList = this.route.snapshot.data["lookupList"];
    this.tbpEmployees = this.route.snapshot.data['tbpEmployees'];
    this.pageId = 1;
    let tmpFieldInfo: FieldInfo[] = [
      makeFieldInfo({ property: "name", label: "Name", type: "string", searchType: "text", forSearchTermQuery: true, columnIndex: 1 }),
      makeFieldInfo({ property: "description", label: "Description", type: "string", searchType: "text", sortable: false, visible: "F" }),
      makeFieldInfo({ property: "email", label: "Email", type: "email", searchType: "text", columnIndex: 5 }),
      makeFieldInfo({ property: "phone", label: "Phone", type: "string", searchable: false, columnIndex: 3 }),
      makeFieldInfo({ property: "mobile", label: "Mobile", type: "string", searchable: false, columnIndex: 4 }),
      makeFieldInfo({ property: "title", label: "Title", type: "string", searchType: "text", columnIndex: 6 }),
      makeFieldInfo({ property: "level1", label: "Level1", type: "classification", searchType: "exact", searchOptions: this.classificationList.CATEGORY_LEVEL1, sortable: false, columnIndex: 7 }),
      makeFieldInfo({ property: "level2", label: "Level2", type: "classification", searchType: "exact", visible: "F", searchOptions: this.classificationList.CATEGORY_LEVEL2, sortable: false }),
      makeFieldInfo({ property: "level3", label: "Level3", type: "classification", searchType: "exact", visible: "F", searchOptions: this.classificationList.CATEGORY_LEVEL3, sortable: false }),
      makeFieldInfo({ property: "vertical", label: "Vertical", type: "classification", searchType: "exact", visible: "F", searchOptions: this.classificationList.VERTICAL, sortable: false }),
      makeFieldInfo({ property: "ecosystem", label: "Ecosystem", type: "classification", searchType: "exact", visible: "F", searchOptions: this.classificationList.ECOSYSTEM, sortable: false }),
      makeFieldInfo({ property: "accountOwner", label: "Account Owner", type: "string", searchType: "text", visible: "F", linkPrefix: "/user/", linkType: "s" }),
      makeFieldInfo({ property: "leadSource", label: "Lead Source", type: "string", searchType: "exact", visible: "F", labelProperty: "value", valueProperty: "value", searchOptions: this.lookupList.ACCOUNT_SOURCE }),
      makeFieldInfo({ property: "lastModifiedDate", label: "Modification Date", type: "date", searchType: "date", visible: "T" }),
      makeFieldInfo({ property: "createdDate", label: "Creation Date", type: "date", searchType: "date", visible: "F" }),
      makeFieldInfo({ property: "organization", label: "Account", type: "string", searchType: "text", visible: "A", searchable: this.source === undefined || this.source != "organization", columnIndex: 2, linkPrefix: "/organization/", linkType: "s" }),
      makeFieldInfo({ property: "relevance", label: "Relevance", type: "date", searchable: false, visible: "H" }),
      makeFieldInfo({ property: "status", label: "Status", type: "enum", searchType: "exact", visible: "F", searchOptions: [{ name: "Activation Pending", uuid: "PENDING_ACTIVATION" }, { name: "Active", uuid: "ACTIVE" }, { name: "Revoked", uuid: "REVOKED" }] }),
      makeFieldInfo({ property: "hasCredentials", label: "Has Credentials", type: "boolean", searchType: "boolean", visible: "H", sortable: false }),
      makeFieldInfo({ property: "selfSignUp", label: "Is Self Registered", type: "boolean", searchType: "boolean", visible: "H", sortable: false }),
      makeFieldInfo({ property: "verified", label: "Is Verified", type: "boolean", searchType: "boolean", visible: "H", sortable: false }),
      makeFieldInfo({ property: "primaryContact", label: "Is Primary Contact", type: "boolean", searchType: "boolean", visible: "F" }),
      makeFieldInfo({ property: "masterProfile", label: "Is Master Profile", type: "boolean", searchType: "boolean", visible: "F" }),
      makeFieldInfo({ property: "accreditedInvestor", label: "Is Accredited Investor", type: "boolean", searchType: "boolean", visible: "F" }),
      makeFieldInfo({ property: "mailingList", label: "Mail Tags", type: "string", searchType: "text", visible: "H", sortable: false }),
    ];
    this.fieldInfo = tmpFieldInfo;
    this.loadCredential();
    this.route.params.subscribe((data) => {
      this.source = data.source == "" ? null : data.source;
      this.searchUrl = (data.source == "" ? "" : "/" + data.source) + (data.sourceuuid == "" ? "" : "/" + data.sourceuuid) + "/contacts/" + (data.showCheckbox == "true" ? "/add" : "");
      this.sourceuuid = data.sourceuuid == "" ? null : data.sourceuuid;
      this.showCheckbox = data.showCheckbox == "true" ? true : false;
      if (this.source && this.source == "organization") {
        this.orgUuid = this.sourceuuid;
      }
      // let tmpQueryFields = {
      //     name: { name: 'Name', type: 'string', searchType: 'textsearch' },
      //     description: { name: 'Description', type: 'string', searchType: 'textsearch' },
      //     email: { name: 'Type', type: 'string', searchType: 'textsearch' },
      //     level1: {
      //         name: 'Level1', type: 'category', searchType: 'exactsearch',
      //         options: this.classificationList.CATEGORY_LEVEL1.map((cat) => {
      //             return { name: cat.name, value: cat.uuid }
      //         })
      //     },
      //     level2: {
      //         name: 'Level2', type: 'category', searchType: 'exactsearch',
      //         options: this.classificationList.CATEGORY_LEVEL2.map((cat) => {
      //             return { name: cat.name, value: cat.uuid }
      //         })
      //     },
      //     level3: {
      //         name: 'Level3', type: 'category', searchType: 'exactsearch',
      //         options: this.classificationList.CATEGORY_LEVEL3.map((cat) => {
      //             return { name: cat.name, value: cat.uuid }
      //         })
      //     },
      //     vertical: {
      //         name: 'Vertical', type: 'category', searchType: 'exactsearch',
      //         options: this.classificationList.VERTICAL.map((cat) => {
      //             return { name: cat.name, value: cat.uuid }
      //         })
      //     },
      //     ecosystem: {
      //         name: 'Ecosystem', type: 'category', searchType: 'exactsearch',
      //         options: this.classificationList.ECOSYSTEM.map((cat) => {
      //             return { name: cat.name, value: cat.uuid }
      //         })
      //     },
      //     accountOwner: { name: 'Account Owner', type: 'string', searchType: 'textsearch' },
      //     leadSource: { name: 'Lead Source', type: 'string', searchType: 'textsearch' },
      //     notes: { name: 'Notes', type: 'string', searchType: 'textsearch' },
      //     mailingList: { name: 'Mail Tags', type: 'string', searchType: 'textsearch' }
      // }
      // if(this.source && this.source != 'organization')
      // {
      //     this.queryBuilderFields['organization'] = { name: 'Account', type: 'string', searchType: 'textsearch' };
      // }
      // this.queryBuilderFields = Object.keys(tmpQueryFields).sort(function (a, b) {
      //     var nameA = tmpQueryFields[a].name.toUpperCase(); // ignore upper and lowercase
      //     var nameB = tmpQueryFields[b].name.toUpperCase(); // ignore upper and lowercase
      //     return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
      // }).reduce(function (result, key) {
      //     result[key] = tmpQueryFields[key];
      //     return result;
      // }, {});
      // this.queryBuilderQuery = {};
      // this.appUtil.initializeSearch(this);
    });
  }
  _search(e) {
    this.term = e.searchTerm;
    let searchParams = this.searchHelper.prepareSearchParams(e.searchTerm, e.filter, e.sortField, e.sortOrder, e.first, e.rows, this.fieldInfo);
    this.userExcel = searchParams;
    this.loading = true;
    if (
      this.source != null &&
      this.sourceuuid != null &&
      !this.searchUrl.includes("add")
    ) {
      searchParams["rootRule"].condition = "and";
      searchParams["rootRule"]["rules"] == undefined ? [] : searchParams["rootRule"]["rules"];
      searchParams["rootRule"]["rules"].push({
        searchType: "exact", field: this.source, operator: "equals", value: this.sourceuuid,
      });
    }
    if (this.searchTerm != '' || this.searchTerm != null) {
      searchParams['query'] = this.searchTerm;
    }
    if (!this.appUtil.getPagination(this.totalRecords)) {
      let isGoingForward = e.first > this.firstValue;
      if (isGoingForward && this.term == null && e.first - this.firstValue == e.rows) {
        if (this.searchAfterStack.length === 0 || this.searchAfterStack[this.searchAfterStack.length - 1].searchAfter !== this.nextSearchAfter) {
          this.searchAfterStack.push({ searchAfter: this.nextSearchAfter, fieldId: this.nextFieldId });
        }
        searchParams['nextSearchAfter'] = this.nextSearchAfter;
        searchParams['nextFieldId'] = this.nextFieldId;
      } else if (!isGoingForward && this.searchAfterStack.length > 0 && this.term == null && e.first - this.firstValue == e.rows) {
        this.searchAfterStack.pop();
        const prevSearchAfter = this.searchAfterStack.length > 0 ? this.searchAfterStack[this.searchAfterStack.length - 1] : null;
        if (prevSearchAfter) {
          searchParams['nextSearchAfter'] = prevSearchAfter.searchAfter;
          searchParams['nextFieldId'] = prevSearchAfter.fieldId;
        }
      }
      this.firstValue = e.first;
    }
    this.userService.searchContact(searchParams).subscribe((data) => {
      let response = data as any;
      if (!this.appUtil.getPagination(response.totalRecords)) {
        this.totalRecords = environment.customTotalRecords;
      } else {
        this.totalRecords = response.totalRecords;
      }
      this.userExcel["pageSize"] = this.totalRecords;
      this.contacts = response.records;
      this.nextSearchAfter = response.nextSearchAfter;
      this.nextFieldId = response.nextFieldId;
      this.loading = false;
      this.appUtil.reinitializeShowMore();
      if (e.searchTerm == null) {
        const localeCompareKey = localStorage.getItem('CONTACTSCROLL');
        if (!this.searchUrl.includes("add")) {
          if (localeCompareKey) {
            this.ngZone.runOutsideAngular(() => {
              setTimeout(() => {
                window.scrollTo(0, parseInt(localeCompareKey));
              }, 0);
            });
          }
        }
      }
    });
  }

  reload() {

    let e: LazyLoadEvent = { first: this.firstRow, rows: this.pageSize, sortField: this.sortField, sortOrder: this.sortOrder };
    this._search(e);

  }

  setCurrentItem(user) {
    let items = [
      {
        label: "Edit",
        icon: "pi pi-plus",
        command: (event) => {
          this.appUtil.routeToEditURL("contact", this.currentMenuData.uuid);
        },
      },

      {
        label: "Merge",
        icon: "pi pi-link",
        command: (event) => {
          this.appUtil.routeToMergeURL("contact", this.currentMenuData.uuid);
        },
      },
      {
        label: "Delete",
        icon: "pi pi-trash",
        command: () => {
          this.deleteContact();
        },
      },
    ];
    if (user.hasCredentials && user.status == "ACTIVE") {
      items.push(
        {
          label: "New Task",
          icon: "pi pi-plus",
          command: (event) => {
            this.newTask();
          },
        },
        {
          label: "Notes & Attachments",
          icon: "pi pi-plus",
          command: (event) => {
            this.newNote();
          },
        },
        {
          label: "New Sell Side Screen",
          icon: "pi pi-id-card",
          command: (event) => {
            this.newSellSide();
          },
        },
        {
          label: " New Buy Side Screen",
          icon: "pi pi-credit-card",
          command: (event) => {
            this.newBuySide();
          },
        },
        {
          label: "New Engagement",
          icon: "pi pi-id-card",
          command: (event) => {
            this.newEngagement();
          },
        },
        {
          label: "Reset Password",
          icon: "pi pi-key",
          command: () => {
            this.resetPassword();
          },
        }
      );
    }
    if (user.hasCredentials != true) {
      items.push({
        label: "Create Credentials",
        icon: "pi pi-key",
        command: () => {
          this.createCredential();
        },
      });
    }

    this.currentMenuData = user;
    this.contactItems = items;
  }
  ngOnDestroy() {
    window.removeEventListener('scroll', this.saveScrollPosition);
    localStorage.setItem('CONTACTSCROLL', this.scrollTop);
  }

  saveScrollPosition = (): void => {
    this.scrollTop = window.scrollY.toString();
  }
  newEngagement() {
    this.dataService.setData("user-", this.currentMenuData);
    this.dataService.setData("org-", this.currentMenuData.organization);
    this.router.navigate(["/engagement/new"]);
  }

  newSellSide() {
    this.dataService.setData("user-", this.currentMenuData);
    this.dataService.setData("org-", this.currentMenuData.organization);
    this.router.navigate(["/sell-side/new"], {
      queryParams: { contact: this.currentMenuData.uuid },
    });
  }

  newBuySide() {
    this.dataService.setData("user-", this.currentMenuData);
    this.dataService.setData("org-", this.currentMenuData.organization);
    this.router.navigate(["/buy-side/new"], {
      queryParams: { contact: this.currentMenuData.uuid },
    });
  }

  downloadUser() {
    this.userService
      .downloadContact(this.userExcel)
      .subscribe((blob) => saveAs(blob, "Users.xlsx"));
  }

  newTask() {
    this.tasksDialog = true;
  }
  newNote() {
    this.notesDialog = true;
  }

  contactUpload() {
    this.uploadPop = true;
  }

  get pf() {
    return this.credentialForm.controls;
  }

  loadCredential() {
    this.credentialForm = this.formBuilder.group({
      password: ["", [Validators.required, Validators.minLength(6)]],
    });
  }

  createCredential() {
    this.credentialForm.reset();
    this.dialogTitleCredential = "New Password";
    this.credentialDialog = true;
  }

  hidePassword() {
    this.pfSubmitted = false;
    this.credentialForm.reset();
    this.credentialDialog = false;
  }

  saveCredential() {
    this.pfSubmitted = true;
    if (this.credentialForm.invalid) {
      return;
    }
    this.loadingSpinner = true;
    let dataToPost: any = {
      uuid: this.currentMenuData.uuid,
      password: this.appUtil.passwordEncrypt(this.pf.password.value),
    };
    let apiToCall = this.userService.createCredential(
      this.currentMenuData.uuid,
      dataToPost
    );
    apiToCall.subscribe((data) => {
      this.messageService.add({
        severity: "success",
        summary: "Successful",
        detail: "Credentials has been created Successfully",
        life: 3000,
      });
      //revisit this.searchHelper.search(this,this.searchUrl, 0, true, true);
      this.currentMenuData.hasCredentials = true;
      this.credentialDialog = false;
      this.loadingSpinner = false;
      this.userCredentials = false;
    },
      error => {
        let errors = error.error;
        if (errors.code && errors.code == 'VALIDATION_ERROR') {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: errors.subErrors[0].message, life: 3000 });

          this.loadingSpinner = false;
          // this.userCredentials = true;
        }
      });

  }

  generatePassword() {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    this.pf.password.setValue(retVal);
  }

  showHidePassword() {
    this.showPassword = !this.showPassword;
  }

  resetPassword() {
    this.loading = true;
    let apiToCall = this.accountService.forgotPassword(
      this.currentMenuData.email
    );
    apiToCall.subscribe(
      (data) => {
        this.messageService.add({
          severity: "success",
          summary: "Successful",
          detail: "Reset password email has been sent successfully",
          life: 3000,
        });
        // revisit this.searchHelper.search(this,this.searchUrl, 0, true, true);
      },
      (error) => {
        let errors = error.error;
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: errors.subErrors[0].message,
          life: 3000,
        });
      }
    );
  }

  confirmAdd() {
    this.showAddDialog = true;
  }

  addToSource() {
    this.dataService.setData("contacts-" + this.source + "-" + this.sourceuuid, this.selectedContacts);
    switch (this.source) {
      case "buyside":
        this.router.navigate(["/buy-side/" + this.sourceuuid + "/prospects"]);
        break;
      case "sellside":
        this.router.navigate(["/sell-side/" + this.sourceuuid + "/prospects"]);
        break;
      case "email-campaign":
        this.router.navigate([
          "/email-campaign/" + this.sourceuuid + "/recipients",
        ]);
        break;
    }
  }

  remove(contact) {
    this.selectedContacts = this.appUtil.deleteItem(
      this.selectedContacts,
      contact
    );
    if (this.selectedContacts.length == 0) {
      this.showAddDialog = false;
    }
  }

  _updateSelectedColumns($event) {
    this.selectedColumns = $event;
  }
  deleteContact() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this contact?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.userService.deleteContact(this.currentMenuData.uuid).subscribe(
          data => {
            this.messageService.add({ severity: 'success', summary: 'Successful', detail: "Contact deleted", life: 3000 });
            this.contacts = this.contacts.filter(contact => contact.uuid !== this.currentMenuData.uuid);
            this.totalRecords -= 1;
            this.contactDeleted = true;
          },
          error => {
            let errors = error.error;
            if (errors.code && errors.code == 'VALIDATION_ERROR') {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: errors.subErrors[0].message, life: 3000 });

            }
          }
        )
      }
    });
  }
  searchOrganizations(event) {
    this.organizationService.lookupOrganization(event.query).subscribe(data => {
      this.organizationResults = data as any;
    });
  }


  searchAccountOwner(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.tbpEmployees as any[]).length; i++) {
      let employee = (this.tbpEmployees as any[])[i];
      if (employee.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(employee);
      }
    }

    this.filteredAccountOwner = filtered;
  }
  getOutput(organization: any) {
    if (organization && typeof organization === 'object') {
      return organization.name;
    }
    return organization;
  }
  getAccountOwner(employee: any) {
    if (employee && typeof employee === 'object') {
      return employee.name;
    }
    return employee;
  }

  formatDate(date: any): string {
    return this.datePipe.transform(date, 'MM-dd-yyyy') || '';
  }
  getOutputValue(column, contact) {
    if (column.property == 'lastModifiedDate') {
      return this.datePipe.transform(contact.lastModifiedDate, 'MM-dd-yyyy') || '';
    }
    else if (column.property == 'createdDate') {
      return this.datePipe.transform(contact.createdDate, 'MM-dd-yyyy') || '';
    }
    else {
      return contact.status;
    }

  }

  initEdit($event) {

    if (($event.field === 'accreditedInvestor' && $event.data.organization != null) ||
      ($event.field === 'primaryContact' && $event.data.organization == null)) {
      this.reload();
      return;
    }

    let oldValues = this.oldVal[$event.data.uuid];

    if (!oldValues) {
      oldValues = [];
      this.oldVal[$event.data.uuid] = oldValues;
    }

    if (['level1', 'level2', 'level3', 'vertical', 'ecosystem'].includes($event.field)) {
      oldValues[$event.field] = $event.data.classification[$event.field];
    } else {
      oldValues[$event.field] = $event.data[$event.field];
    }
  }
  editCancelled($event) {
    let oldValues = this.oldVal[$event.data.uuid];
    delete oldValues[$event.field];
  }

  editorAction(event, contact: any, field: string, cancelled: boolean) {

    if (cancelled) {
      this.editCancelled({ field, data: contact });
      this.reload();
    }
    else {
      this.editCompleted({ field, data: contact });
    }
    DomHandler.removeClass(this.dataTable.editingCell, 'p-cell-editing');
    this.dataTable.editingCell = null;
    this.dataTable.editingCellData = null;
    this.dataTable.editingCellField = null;
    this.dataTable.unbindDocumentEditListener();
    event.preventDefault();
    event.cancelBubble = true;
    event.stopPropagation();
    this.appUtil.reinitializeShowMore();
  }

  editCompleted($event) {

    let oldValues = this.oldVal[$event.data.uuid];
    if ($event.field === 'name' && !$event.data.name.includes(' ')) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Both first and last name are required for the contact.', life: 3000 });
      this.reload();
      this.editingModes[$event.data.uuid] = false;
      return false;
    }
    if ($event.field == 'email' && this.isInvalidEmail($event.data[$event.field])) {
      this.messageService.add({ severity: 'error', summary: 'Invalid Email', detail: 'Please enter a valid email address', life: 3000 });
      this.reload();
      return false;

    }

    let dataToPost: DataToPost = { uuid: $event.data.uuid };

    if ($event.field == 'level1' || $event.field == 'level2' || $event.field == 'level3' || $event.field == 'vertical' || $event.field == 'ecosystem') {
      dataToPost['classification'] = $event.data.classification ? $event.data.classification : null;
      $event.field = 'classification';
    } else {

      dataToPost[$event.field] = $event.data[$event.field];
    }
    if (($event.field === 'accreditedInvestor' && $event.data.organization != null) ||
      ($event.field === 'primaryContact' && $event.data.organization == null)) {
      this.reload();
      return;
    }
    if ($event.field == 'leadSource') {
      dataToPost[$event.field] = $event.data[$event.field].value;
    }

    if (oldValues[$event.field] != $event.data[$event.field]) {
      this.userService.updateContactList([$event.field], dataToPost).subscribe(
        data => {
          this.loading = false;
          this.editingModes[$event.data.uuid] = false;
          //    this.reload();
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: "Contact updated successfully", life: 3000 });
        },
        error => {
          $event.data[$event.field] = oldValues[$event.field]
          let errors = error.error;
          if (errors.code && errors.code == 'VALIDATION_ERROR') {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: errors.subErrors[0].message, life: 3000 });
          }
        }
      )
    }

    delete oldValues[$event.field];
  }

  toggleEditingMode(contactUUID: string) {
    this.editingModes[contactUUID] = !this.editingModes[contactUUID];
  }

  isEditing(contactUUID: string): boolean {
    return this.editingModes[contactUUID] || false;
  }
  isInvalidEmail(email: string): boolean {
    if (email === '' || email === undefined) {
      return false;
    }
    return !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }
  disableNavigation(event: any) {
    if (event.key === "ArrowRight" || event.key === "Right"
      || event.key === "ArrowLeft" || event.key === "Left"
      || event.key === "ArrowUp" || event.key === "Up"
      || event.key === "ArrowDown" || event.key === "Down") {
      event.stopPropagation();
    }
  }

  getLookupType(columnProperty: string): string {

    if (columnProperty === 'leadSource') {
      return 'ACCOUNT_SOURCE';
    }

  }
  getLookupItems(columnProperty: string): any[] {

    if (columnProperty === 'leadSource') {
      return this.lookupList.ACCOUNT_SOURCE;
    }

  }
  getOutputs(columnProperty) {


    if (columnProperty && typeof columnProperty === 'object') {
      return columnProperty.value;
    }
    return columnProperty;
  }
  return() {
    window.history.back();
  }
}
