import { Router, UrlSerializer } from '@angular/router';
import { UntypedFormGroup, AbstractControl } from '@angular/forms';
import { LazyLoadEvent } from 'primeng/api';
import { DatePipe, DOCUMENT, Location, LowerCasePipe, TitleCasePipe } from '@angular/common'
import * as CryptoJS from 'crypto-js';
import { environment } from '../../environments/environment';

import { Injectable, Inject } from '@angular/core';
import { FieldInfo } from '../_models';


@Injectable({
    providedIn: 'root'
})

export class AppUtil {
 defaultTotalRecords: number = environment.defaultTotalRecords;
    constructor(private router: Router,
        @Inject(DOCUMENT) private _document: Document,
        private location: Location,
        private serializer: UrlSerializer) {

    }

    get appName() {
        return environment.appName || "TBP Network";
    }

    public toPaginationParam(e: LazyLoadEvent) {
        return '';
    }

    public getClassificationObject(uuid: any, data: any) {
        let resultselected: any[] = uuid as any[];
        let result: any[] = data as any[];
        let cls = [];
        for (let i = 0; i < result.length; i++) {
            for (let j = 0; j < resultselected.length; j++) {
                if (result[i].uuid == resultselected[j]) {
                    cls.push(result[i].data);
                }
            }
        }
        return cls;
    }

    /* returns the first match */
    public getObject(dataList: any, selected: any, fieldName: string = 'uuid') {
        let item = dataList.find(function (current: any) {
            let val: any = this as any;
            if (val == null || (typeof val != 'object' && typeof current != 'object')) {
                return val == current;
            }
            else if (typeof val === 'object') {
                if (typeof current == 'object') {
                    return val[fieldName] == current[fieldName];
                }
                else {
                    return val[fieldName] == current;
                }
            }
            else {
                return val == current[fieldName];
            }
        }, selected);
        return item ? item : null;
    }

    /* returns all objects matching the one passed as array */
    public getObjects(dataList: any[], selected: any[], fieldName: string = 'uuid') {
        let data = null;
        if (selected != null) {
            data = dataList.filter(function (current: any) {
                let found: boolean = false;
                for (var i = 0; i < this.length; i++) {
                    let val: any = this[i] as any;
                    if ((typeof val === 'object' && val != null && current[fieldName] == val[fieldName])
                        || (current[fieldName] == val)) {
                        found = true;
                    }
                }
                return found;
            }, selected);
            if (data == undefined)
                data = null;
        }
        return data;
    }
    public getClassificationUuid(data: any) {
        let result: any[] = data as any[];
        let uuid = [];
        if (result != null) {
            for (let i = 0; i < result.length; i++) {
                uuid.push(result[i].uuid);
            }
        }
        return uuid;
    }

    public hasError(form: UntypedFormGroup, fieldName: string, submitted: boolean) {
        let f = form.controls[fieldName];
        return f.invalid && (submitted || f.dirty || f.touched)
    }


    public getCountry(countryList: any[], country: any): string {
        if (country == null)
            return null;

        var cntry = countryList.find(function (current: any) {
            let val: any = this as any;
            if (typeof this === 'object' && val != null)
                return current.name == val.name;
            return current.name == val;
        }, country);
        if (typeof cntry === 'undefined') {
            countryList.push({ name: country });
            cntry = country;
        }
        if (typeof cntry === 'object') {
            cntry = cntry.name
        }
        return cntry;
    }

    public getStates(countryList: any[], country: any): any {
        if (country == null)
            return null;
        var cntry = countryList.find(function (current: any) {
            let val: any = this as any;
            if (typeof this === 'object' && val != null)
                return current.name == val.name;
            return current.name == val;
        }, country);
        if (typeof cntry === 'undefined') {
            return null;
        }
        return cntry.states;
    }

    public getState(stateList: any[], state: any) {
        if (state == null || stateList == null || stateList.length == 0)
            return state;

        var stat = stateList.find(function (current: any) {
            let val: any = this as any;
            if (typeof this === 'object' && this != null)
                return current.name == val.name;
            return current.name == val;
        }, state);

        if (typeof stat === 'undefined') {
            stateList.push({ name: state });
            stat = state;
        }
        if (typeof stat === 'object') {
            stat = stat.name;
        }

        return stat;
    }

    public get emailPattern(): string {
        return "(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])";
    }

    public get urlPattern(): string {
        return '^(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,20}(:[0-9]{1,5})?(\\/.*)?$';
    }


    public calendarToDbDateFormat(inputDate, withTime: boolean = false) {
        if (typeof inputDate == undefined || inputDate == null)
            return null;
        let newDate = new Date(inputDate);
        if (isNaN(newDate.getTime())) {
            let dateString = inputDate.split('-');
            newDate = new Date(dateString[2], dateString[0] - 1, dateString[1]);
        }
        let dbDate = new Date(newDate.getTime() - (newDate.getTimezoneOffset() * 60000)).toISOString().split("T")[0] + (withTime ? "T00:00:00.000+00:00" : "");
        return dbDate;
    }

    public DbToCalendarDateFormat(inputDate) {
        if (typeof inputDate == undefined || inputDate == null)
            return null;
        const date = new DatePipe('en-US').transform(inputDate, 'MM-dd-yyyy');
        return date;
    }

    public calenderToDbDate(inputDate) {
        if (typeof inputDate == undefined || inputDate == null)
            return null;
        let newDate = new Date(inputDate);
        if (isNaN(newDate.getTime())) {
            let dateString = inputDate.split(" ");
            let da = dateString[0].split("-");
            var time = dateString[1].split(':');
            newDate = new Date(da[2], da[0] - 1, da[1], time[0], time[1]);
        }
        let dbDate = new Date(newDate.getTime()).toISOString();
        return dbDate;
    }

    public viewformatDate(inputDate) {
        var myDate = new Date(inputDate);
        var formattedDate = (myDate.getMonth() + 1) + "-" + myDate.getDate() + "-" + myDate.getFullYear() + " " + myDate.getHours() + ":" + myDate.getMinutes();
        return formattedDate;
    }


    public passwordEncrypt(_password) {
        var iv = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex);
        var key = CryptoJS.PBKDF2(environment.passphrase, CryptoJS.enc.Hex.parse(environment.salt), { keySize: 128 / 32, iterations: 1024 });
        let encrypted = CryptoJS.AES.encrypt(_password, key, { iv: CryptoJS.enc.Hex.parse(iv), mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
        return btoa(encrypted.ciphertext.toString(CryptoJS.enc.Base64) + "::" + iv);
    }

    public get pageSize() {
        if (environment.defaultPageSize)
            return environment.defaultPageSize;

        return 25;
    }
    getPagination(totalRecords: number):boolean {
        if (totalRecords >= this.defaultTotalRecords) {
            return false;
        }
        return true;
    }

    public keysMap = {
        condition: 'c',
        rules: 'r',
        field: 'f',
        operator: 'o',
        value: 'v',
        searchType: 's',
        matches: 'm'
    };
    public valueMap = {
        matches: 'm',
        equals: 'e',
        'not equals': 'e',
        'not in': 'n',
        'exactsearch': 'es',
        'textsearch': 'ts',
        'rangesearch': 'rs',
        'booleansearch': 'bs'
    };


    public convertForQueryParam(rules) {
        var str = JSON.stringify(rules);
        for (const key in this.keysMap) {
            str = str.replace(new RegExp('"' + key + '":', 'g'), '"' + this.keysMap[key] + '":');
        }
        for (const key in this.valueMap) {
            str = str.replace(new RegExp('"' + key + '"'), '"#' + this.valueMap[key] + '#"');
        }
        return str;
    }

    public convertFromQueryParam(rules) {
        var str = rules;

        for (const key in this.valueMap) {
            str = str.replace(new RegExp('"#' + this.valueMap[key] + '#"', 'g'), '"' + key + '"');
        }
        for (const key in this.keysMap) {
            str = str.replace(new RegExp('"' + this.keysMap[key] + '":', 'g'), '"' + key + '":');
        }

        return JSON.parse(str);
    }

    private _prepareQuery(rules, queryStr, condition, fields): string {
        for (var i = 0; i < rules.length; i++) {
            let rule = rules[i];
            if (Array.isArray(rule.rules)) {
                if (queryStr != '') {
                    queryStr = queryStr + ' ' + condition + ' ';
                }
                let tmp = this._prepareQuery(rule.rules, '', rule.condition, fields);
                if (tmp != '') {
                    queryStr = queryStr + "(";
                    queryStr = queryStr + tmp;
                    queryStr = queryStr + ")";
                }
            }
            else if ((typeof rule.value != 'undefined') && rule.value != null
                && ((typeof rule.value != 'string') || rule.value.trim() != '')) {
                if (queryStr != '') {
                    queryStr = queryStr + ' ' + condition + ' ';;
                }

                queryStr = queryStr + "(";
                let field = fields[rule.field];
                queryStr = queryStr + field.name + " " + this._getOperator(rule.operator) + " " + this._getValue(field, rule.operator, rule.value);
                queryStr = queryStr + ")";
            }

        }
        return queryStr;
    }

    private _getOperator(operator) {
        if (operator == 'equals') {
            return '=';
        }
        else if (operator == 'not equals') {
            return '!=';
        }
        else if (operator == 'equals') {
            return '=';
        }
        return operator;
    }

    private _getValue(field, operator, value) {
        let retVal = '';
        if (operator == 'in' || operator == 'not in') {
            retVal = ' (';
        }
        if (field.type == 'category') {
            let val;
            if (Array.isArray(value)) {
                val = value;
            }
            else {
                val = [value];
            }
            let finalVal = '';
            for (var i = 0; i < val.length; i++) {
                if (typeof val[i] !== 'undefined') {
                    if (finalVal != '') {
                        finalVal = finalVal + ', ';
                    }
                    finalVal = finalVal + field.options.filter(obj => { return obj.value === val[i] })[0].name;
                }
            }
            retVal = retVal + finalVal;
        }
        else if (field.type == 'date') {
            retVal = retVal + new DatePipe('en-US').transform(value, 'MM-dd-yyyy');
        }
        else {
            retVal = retVal + value;
        }

        if (operator == 'in' || operator == 'not in') {
            retVal = retVal + ') ';
        }
        return retVal;
    }

    public convertToQueryString(query, fields) {
        return this._prepareQuery(query.rules, '', query.condition, fields);
    }

    public search(component, route: string, isAdvance: boolean, first: number, advanceQuery: any, sortByRelevance: boolean) {
        if (sortByRelevance) {
            component.sortField = "relevance";
            component.globalSortField = component.sortField;
            component.sortOrder = -1;
        }
        let urlTree;
        if (isAdvance) {
            urlTree = this.router.createUrlTree([route], { queryParams: { 'advsearch': true, 'term': this.convertForQueryParam(advanceQuery), 'first': first, 'pagesize': component.pageSize, 'sortfield': component.sortField, 'sortorder': component.sortOrder } });
        }
        else {
            urlTree = this.router.createUrlTree([route], { queryParams: { 'term': component.term, 'first': first, 'pagesize': component.pageSize, 'sortfield': component.sortField, 'sortorder': component.sortOrder } });
        }
        this.location.go(this.serializer.serialize(urlTree));
        if (isAdvance) {
            component.isAdvanceSearch = true;
            component.queryBuilderQuery = advanceQuery;
            component.currentQuery = this.convertToQueryString(component.queryBuilderQuery, component.queryBuilderFields)
        }
        else {
            component.isAdvanceSearch = false;
            component.queryBuilderQuery = {};
            component.currentQuery = '';
        }
        let e: LazyLoadEvent = { first: component.firstRow, rows: component.pageSize, sortField: component.sortField, sortOrder: component.sortOrder };
        component._search(e);
    }

    public paginateOrSort(component, route: string, e: LazyLoadEvent) {
        component.sortOrder = e.sortOrder;
        component.sortField = e.sortField;
        component.globalSortField = e.sortField;
        component.pageSize = e.rows;
        component.firstRow = e.first;
        this.search(component, route, component.isAdvanceSearch, component.firstRow, (component.isAdvanceSearch ? component.queryBuilderQuery : null), false);
    }

    public initializeSearch(component) {
        let searchTerm = component.route.snapshot.queryParams['term'];
        let advsearch = component.route.snapshot.queryParams['advsearch'];
        component.currentQuery = (advsearch == true) ? component.currentQuery : '';
        component.isAdvanceSearch = false;
        if (typeof searchTerm !== 'undefined') {
            component.firstRow = Number(component.route.snapshot.queryParams['first']);
            component.pageSize = Number(component.route.snapshot.queryParams['pagesize']);
            component.sortField = component.route.snapshot.queryParams['sortfield'];
            component.globalSortField = component.sortField;
            component.sortOrder = Number(component.route.snapshot.queryParams['sortorder']);
            component.globalSortOrder = component.sortOrder;
            if (advsearch) {
                component.isAdvanceSearch = true;
                component.queryBuilderQuery = this.convertFromQueryParam(searchTerm);
                component.currentQuery = this.convertToQueryString(component.queryBuilderQuery, component.queryBuilderFields)
            }
            else {
                component.term = searchTerm;
                component.queryBuilderQuery = {};
                component.currentQuery = '';
            }
        }
        let e: LazyLoadEvent = { first: component.firstRow, rows: component.pageSize, sortField: component.sortField, sortOrder: component.sortOrder };
        component._search(e);
    }

    public prepareRuleSetForQuery(ruleset: any, fields: any) {
        if (ruleset && ruleset.rules && ruleset.rules.length > 0) {
            ruleset.rules.forEach((item) => {
                if (item.rules) {
                    return this.prepareRuleSetForQuery(item, fields);
                } else if (item.field) {
                    const field = fields[item.field];
                    item.searchType = field.searchType;
                    if (Array.isArray(item.value)) {

                        item.values = item.value;
                        item.value = null;
                    }
                    if (field.type == 'date') {
                        item.value = new DatePipe('en-US').transform(item.value, 'MM-dd-yyyy');
                    }
                }
            });
        }
        return ruleset;
    }

    public _getObjectToUuid(data: any) {
        let uuid = null;
        if (data != null && data != '') {
            uuid = data.uuid;
        }
        return uuid;
    }

    public getConcatValue(values: any[], fieldName) {
        let value = '';
        if (values) {
            value = '';
            values.forEach(function (currentValue, index) {
                if (index != 0) {
                    value = value + "; ";
                }
                value = value + (fieldName ? currentValue[fieldName] : currentValue);
            });
        }
        return value;
    }

    public routeToEditURL(component: string, uuid: string) {
        this.router.navigate(['/' + component + '/edit/' + uuid]);
    }

    public routeToMergeURL(component: string, uuid: string) {
        this.router.navigate(['/' + component + '/merge/' + uuid]);
    }

    public routeToViewURL(component: string, uuid: string) {
        this.router.navigate(['/' + component + '/' + uuid]);
    }

    public routeToNewURL(component: string) {
        this.router.navigate(['/' + component + '/new']);
    }

    public reinitializeShowMore() {
        setTimeout(() => {

            let elements = this._document.querySelectorAll('.limitedText');
            if (elements.length > 0) {
                for (let i = 0; i < elements.length; i++) {
                    let elm = elements[i];
                    const isTextClamped = elm.scrollHeight > elm.clientHeight
                    if (isTextClamped) {
                        elm.nextElementSibling.className = 'moreless p-button-danger ml-2';
                    }
                    else {
                        elm.nextElementSibling.className = 'moreless p-button-danger ml-2 hidden';
                    }
                }

            }

        }, 250);

    }


    public showHideClampedContent($event) {
        let el = $event.srcElement;
        let show = el.innerText == 'More';
        if (show) {
            el.previousElementSibling.className = "limitText";
            el.innerText = "Less";
        }
        else {
            el.previousElementSibling.className = "limitText limitedText";
            el.innerText = "More";
        }

    }

    statusToTitleCase(str) {
        return str.replace(
            /\w\S*/g,
            function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }

    getInitData(height: number, width: number, minimalToolbar: boolean = true) {
        var data: any = {
            height: height,
            menubar: !minimalToolbar,
            body_class: "spotlight-preview",
            content_css: [
                "/assets/theme/blue/theme-light.css",
                "/assets/layout/css/layout-light.css",
                "/assets/layout/css/app.css"
            ],
            content_style: 'body {background: #ffffff !important;}',
            plugins: [
                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                'insertdatetime', 'media', 'table', 'help', 'wordcount'
            ],
            toolbar: 'undo redo | blocks | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | link |' +
                'removeformat | help',
        };
        if (width != null) {
            data.width = width;
            data.theme_advanced_resizing_max_width = width;
        }
        return data;
    }

    setError(fg: UntypedFormGroup, control: string, error: any) {
        fg.controls[control].setErrors(error);
        fg.controls[control].markAsTouched();
    }

    findItemInList(dataList: any, item: any, fieldName: string = null) {
        if (item != null && dataList != null) {
            return dataList.find(function (current: any) {
                let found: boolean = false;
                if (this.fieldName == null || this.fieldName == '') {
                    if (this.item == current)
                        found = true;
                }
                else {
                    let val = (typeof this.item == 'object') ? this.item[this.fieldName] : this.item;
                    if (current[this.fieldName] == val)
                        found = true;
                }
                return found;
            }, { item, fieldName });
        }
    }

    formatPhone(phoneControl: AbstractControl) {
        let phone = phoneControl.value;

        if (phone != null && phone != undefined) {
            let formattedPhone = phone;
            if (formattedPhone.startsWith('+')) {
                formattedPhone = formattedPhone.substring(1);
            }
            let phoneSegments = formattedPhone.trim().split(' ');
            let trimmedPhoneSegments = [];
            for (var i = 0; i < phoneSegments.length; i++) {
                let segment = phoneSegments[i].trim();
                if (segment != '') {
                    trimmedPhoneSegments.push({ segment, isNumeric: /^\d+$/.test(segment) });
                }
            }
            if (trimmedPhoneSegments.length > 0) {
                if (trimmedPhoneSegments[0].segment.length >= 10 && trimmedPhoneSegments[0].isNumeric) {
                    formattedPhone = (trimmedPhoneSegments[0].segment.length > 10) ? '+' + trimmedPhoneSegments[0].segment.substring(0, trimmedPhoneSegments[0].segment.length - 10) : '';
                    formattedPhone = formattedPhone + ' ' + trimmedPhoneSegments[0].segment.substring(trimmedPhoneSegments[0].segment.length - 10).replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
                    trimmedPhoneSegments.splice(0, 1);
                    trimmedPhoneSegments.forEach(function (currentValue, index, arr) {
                        formattedPhone = formattedPhone + ' ' + currentValue.segment;
                    })
                    phone = formattedPhone.trim();
                }
                else if (trimmedPhoneSegments.length > 1 && trimmedPhoneSegments[1].segment.length == 10 && trimmedPhoneSegments[1].isNumeric) {
                    formattedPhone = '+' + trimmedPhoneSegments[0].segment + ' ' + trimmedPhoneSegments[1].segment.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
                    trimmedPhoneSegments.splice(0, 2);
                    trimmedPhoneSegments.forEach(function (currentValue, index, arr) {
                        formattedPhone = formattedPhone + ' ' + currentValue.segment;
                    })
                    phone = formattedPhone.trim();
                }
            }
        }

        phoneControl.setValue(phone);
    }

    toTitleCase(value: string) {
        if (value != null)
            value = new TitleCasePipe().transform(value);
        return value;
    }

    toLowerCase(value: string) {
        if (value != null)
            value = new LowerCasePipe().transform(value);
        return value;
    }

    externalLink(id: string, type: string, prefix: string = '', open: boolean = true) {
        let redirect = type == 'url' ? id : environment[type + '.url'];
        if (redirect != null && redirect.trim() != '' && id != null && id.trim() != '') {
            id = (prefix ? prefix + ' ' : '') + encodeURIComponent(id);

            switch (type) {
                case 'google':
                    redirect = redirect.replace(':text', id);
                    break;
                case 'capiq':
                    redirect = redirect.replace(':capiqId', id);
                    break;
                case 'pitchbook':
                    redirect = redirect.replace(':pitchbookId', id);
                    break;
                case 'linkedin':
                    redirect = redirect.replace(':linkedinId', id);
                    break;
                default:
                    if (!id.startsWith('http')) {
                        redirect = '//' + redirect;
                    }
            }
            if (open)
                window.open(redirect, "_blank");
            else
                return redirect;
        }
        return null;
    }

    compareString(a: any, b: any, property: string) {
        const nameA = property ? a[property].toUpperCase() : a.toUpperCase(); // ignore upper and lowercase
        const nameB = property ? b[property].toUpperCase() : b.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    }

    sortByStringProperty(items: any[], property: string) {
        items.sort((a, b) => this.compareString(a, b, property));
    }

    compareNumber(a: any, b: any, property: string) {
        const nameA = property ? a[property] : a;
        const nameB = property ? b[property] : b;
        let toRet = -1;
        if (nameA == nameB) {
            return 0;
        }
        else if (nameA) {
            if (!nameB) {
                toRet = -1;
            }
            toRet = nameA - nameB;
        }
        return toRet;
    }

    sortByNumberProperty(items: any[], property: string) {
        items.sort((a, b) => this.compareString(a, b, property));
    }


    sortByRangeProperties(items: any[], minProptery: string, maxProperty: string) {
        items.sort((a, b) => this.compareRange(a, b, minProptery, maxProperty));
    }

    compareRange(a: any, b: any, minProperty: string, maxProperty: string) {
        let toRet = -1;
        let minvalA = minProperty ? a[minProperty] : a;
        let minvalB = minProperty ? b[minProperty] : b;


        if (minvalA == minvalB) {
            toRet = 0;
        }
        else if (minvalA) {
            if (minvalB) {
                toRet = minvalA - minvalB;
            }
            else {
                toRet = 1;
            }
        }

        if (toRet == 0)// as it is a range and min numbers are equal sorting will depend upon max
        {
            let maxvalA = maxProperty ? a[maxProperty] : a;
            let maxvalB = maxProperty ? b[maxProperty] : b;
            if (maxvalA == maxvalB) {
                toRet = 0;
            }
            else if (maxvalA) {
                if (maxvalB) {
                    toRet = maxvalA - maxvalB;
                }
                else {
                    toRet = -1;
                }
            }
            else // maxvalA is null so it should come later
            {
                toRet = 1;
            }
        }

        return toRet;
    }

    public isArray(obj) {
        return Array.isArray(obj);
    }

    public deleteItem(dataList: any[], item: any, fieldName: string = null) {
        return this.deleteItems(dataList, item ? [item] : null, fieldName);
    }

    /* single or array of items to be deleted */
    public deleteItems(dataList: any[], item: any[], fieldName: string = null) {
        let toRet = [];
        if (item != null && dataList != null) {
            toRet = dataList.filter((current, index) => {
                let found = false;
                item.forEach((toBeRemoved) => {
                    if (fieldName == null || fieldName == '') {
                        if (toBeRemoved == current)
                            found = true;
                    }
                    else {
                        let val = (typeof toBeRemoved == 'object') ? toBeRemoved[fieldName] : toBeRemoved;
                        if (current[fieldName] == val)
                            found = true;
                    }
                });
                return !found;
            });
        }
        return toRet;
    }

    downloadTemplate(templateFileName: string) {
        window.open('/assets/download-Template/' + templateFileName, '_blank');
    }

    convertNameToInitials(name: string) {

        let initials = "";
        if (name != null) {
            name.split(" ").forEach((str) => {
                initials = initials.concat(str[0] + "");
            });
        }
        return initials;
    }

    getUserStatus(contact) {
        if (contact.status == 'PENDING_ACTIVATION') {
            return "Waiting for email verification by user";
        }
        else if (contact.status == 'PENDING_PROFILE') {
            return "Waiting for user to complete profile";
        }
        else if (!contact.verified) {
            return "Waiting for profile verification by admin";
        }
        else if (contact.status == 'REVOKED') {
            return "Access Revoked";
        }
        return "Active";
    }

    prepareMessageForProspects(response: any) {

        let msg: string = '';
        if (response['add'] > 0) {
            msg = msg.concat(response['add'] + (response['add'] > 1 ? ' Prospects' : ' Prospect') + ' Added.')
        }
        if (response['skip-contact'] > 0) {
            msg = msg.concat((response['add'] > 0 ? '\n' : '') + response['skip-contact'] +
                (response['skip-contact'] > 1 ? ' Prospects' : ' Prospect') + ' Skipped As ' +
                (response['skip-contact'] > 1 ? 'they are' : 'it is') + ' already associated as CONTACT for this screen.')
        }
        if (response['skip-prospect'] > 0) {
            msg = msg.concat(((response['add'] > 0 || response['skip-contact'] > 0) ? '\n' : '') + (response['skip-prospect']) +
                (response['skip-prospect'] > 1 ? ' Prospects' : ' Prospect') + ' Skipped As ' +
                (response['skip-prospect'] > 1 ? 'they are' : 'it is') + ' already associated as PROSPECT.')
        }
        if (response['add-contact'] > 0) {
            msg = msg.concat(response['add-contact'] + (response['add-contact'] > 1 ? 'Screens' : ' Screen') + ' Added.')
        }
        if (response['skip-screens'] > 0) {
            msg = msg.concat((response['add'] > 0 ? '\n' : '') + response['skip-screens'] +
                (response['skip-screens'] > 1 ? ' Screens' : ' Screen') + ' Skipped As ' +
                (response['skip-contact'] > 1 ? 'they are' : 'it is') + ' already associated with this contact as Primary!')
        }
        return msg;
    }

    public getCustomLabel(fieldInfo: FieldInfo, customLabels: any) {
        if (!customLabels || !customLabels.has(fieldInfo.property)
            || customLabels.get(fieldInfo.property) == null || customLabels.get(fieldInfo.property) == "") {
            return fieldInfo.label;
        }
        return customLabels.get(fieldInfo.property);
    }
    maxFileSize: number = 20000000;

    return(){
        window.history.back();
    }
}