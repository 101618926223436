import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';


@Injectable({
    providedIn: 'root'
})
export class PredefinedFilterService {

    constructor(private http: HttpClient) { }

    createFilter(data) {
        return this.http.post(environment.apiUrl + '/filter', data);
    }

    updateFilter(data, uuid) {
        return this.http.post(environment.apiUrl + '/filter/' + uuid, data);
    }

    getFilters(filterFor:string, entityUUID:string) {
        if(entityUUID && entityUUID != '')
            return this.http.get(environment.apiUrl + '/user-filters/' + filterFor + "/" + entityUUID);

        return this.http.get(environment.apiUrl + '/user-filters/' + filterFor);
    }

    getFilterForEntity(filterFor:string, entityUUID:string ){
        return this.http.get(environment.apiUrl+ "/entity-filters/" + filterFor + "/" + entityUUID);
    }
}
