<div class="login-body">
    <div class="login-wrapper">
        <div class="login-panel">
            <img src="assets/layout/images/tbp-logo.png" class="logo" alt="TruebBlue Partners" />
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="login-form">
                    <div class="p-fluid formgrid grid">
                        <div class="field col-12 md:col-12 ml-2">
                            <h2>Create Your Free Account</h2>
                            <p>Already have an account? <a routerLink="../login">Login</a></p>
                        </div>
                        <p-messages></p-messages>
                        <div class="field col-12 md:col-12 text-left">
                            <span class="p-input-icon-right">
                                <i class="pi pi-user"></i>
                                <input pInputText id="firstName" formControlName="firstName" maxlength="40"
                                    placeholder="First Name" class="capitalize" (blur)="f.firstName.setValue(appUtil.toTitleCase(f.firstName.value))"
                                    [ngClass]="{ 'ng-invalid ng-dirty': hasError(form,'firstName',submitted)}" required
                                    autofocus />
                            </span>
                            <small class="p-error p-align-left"
                                *ngIf="hasError(form,'firstName',submitted) && f.firstName.errors?.required">First Name
                                is required.</small>
                            <small class="p-error"
                                *ngIf="f.firstName.errors?.message">{{f.firstName.errors.message}}</small>
                        </div>
                        <div class="field col-12 md:col-12 text-left">
                            <span class="p-input-icon-right">
                                <i class="pi pi-user"></i>
                                <input pInputText id="lastName" formControlName="lastName" maxlength="75"
                                    placeholder="Last Name"  class="capitalize" (blur)="f.lastName.setValue(appUtil.toTitleCase(f.lastName.value))"
                                    [ngClass]="{ 'ng-invalid ng-dirty': hasError(form,'lastName',submitted) }" required
                                    autofocus />
                            </span>
                            <small class="p-error p-align-left"
                                *ngIf="hasError(form,'lastName',submitted) && f.lastName.errors?.required">Last Name is
                                required.</small>
                            <small class="p-error"
                                *ngIf="f.lastName.errors?.message">{{f.lastName.errors.message}}</small>
                        </div>
                        <div class="field col-12 md:col-12 text-left">
                            <span class="p-input-icon-right">
                                <i class="pi pi-user"></i>
                                <input pInputText id="email" formControlName="email" maxlength="175" placeholder="Email"
                                    class="lowercase" (blur)="f.email.setValue(appUtil.toLowerCase(f.email.value))"
                                    [ngClass]="{ 'ng-invalid ng-dirty': hasError(form,'email',submitted) }" required
                                    autofocus />
                            </span>
                            <ng-container *ngIf="hasError(form,'email',submitted)">
                                <small class="p-error p-align-left" *ngIf="f.email.errors?.required">Email is
                                    required.</small>
                                <small class="p-error p-align-left" *ngIf="f.email.errors?.pattern">Email is not
                                    valid.</small>
                                <small class="p-error"
                                    *ngIf="f.email.errors?.message">{{f.email.errors.message}}</small>
                            </ng-container>
                        </div>
                        <div class="field col-12 md:col-12 text-left">
                            <span class="p-input-icon-right">
                                <i class="pi pi-eye" (click)="showHidePassword()"></i>
                                <input pPassword id="password" formControlName="password" maxlength="20"
                                    placeholder="Password"
                                    [ngClass]="{ 'ng-invalid ng-dirty': hasError(form,'password',submitted) }"
                                    [feedback]=false [showPassword]="showPassword" />
                            </span>
                            <small class="p-error" *ngIf="f.password.hasError('minlength') ">Minimum length required is
                                {{f.password.errors.minlength.requiredLength}} </small>
                            <small class="p-error" *ngIf="submitted && f.password.hasError('required')">Password is
                                required.</small>
                            <small class="p-error"
                                *ngIf="f.password.errors?.message">{{f.password.errors.message}}</small>
                        </div>
                        <div class="field col-12 md:col-12 text-left">
                            <span class="p-input-icon-right">
                                <i class="pi pi-eye" (click)="showHidePassword()"></i>
                                <input pPassword id="confirmPassword" maxlength="20" formControlName="confirmPassword"
                                    placeholder="Confirm Password" [showPassword]="showPassword" />
                                <small class="p-error"
                                    *ngIf="(f.password.value != '') && f.confirmPassword.hasError('confirmedValidator')">Passwords
                                    do not match</small>
                            </span>
                        </div>
                        <div class="field col-12 md:col-12 text-left">
                            <p-checkbox styleClass="form-control" formControlName="acceptTermsAndConditions"
                                name="acceptTermsAndConditions"
                                [ngClass]="{ 'ng-invalid ng-dirty': hasError(form,'acceptTermsAndConditions',submitted) }"
                                [binary]="true" label="You agree to the terms of use, privacy policy & legal policy."
                                labelStyleClass="pr-2"></p-checkbox> <br>
                            <small class="p-error p-align-left"
                                *ngIf="hasError(form,'acceptTermsAndConditions',submitted) && f.acceptTermsAndConditions.errors?.required">This
                                field is required.</small>
                            <small class="p-error"
                                *ngIf="f.acceptTermsAndConditions.errors?.message">{{f.acceptTermsAndConditions.errors.message}}</small>
                        </div>
                        <div class="field col-12 md:col-12">
                            <button pButton pRipple label="Register"></button>
                        </div>
                    </div>
                </div>
            </form>
            <p-progressSpinner *ngIf="loading"></p-progressSpinner>
            <div class="p-fluid formgrid grid" style="width: 70%;">
                <div class="field col-12 md:col-12">
                    <h2>OR</h2>
                    <button pButton type="button" label="Sign In With Google" icon="pi pi-google" iconPos="left"
                        class="p-button-raised p-button-rounded p-button-help"></button>
                </div>
                <div class="field col-12 md:col-12">
                    <button pButton type="button" label="Sign In With LinkedIn" icon="pi pi-linkedin" iconPos="left"
                        class="p-button-raised p-button-rounded p-button-help"></button>
                </div>
            </div>

            <p>A problem? <a href="#">Click here</a> and let us help you.</p>
        </div>
        <div class="login-image">
            <div class="login-image-content">
                <h1>Benefits of {{appUtil.appName}}</h1>
                <h3>Lorem ipsum dolor sit amet, consectetur
                    <br /> adipiscing elit. Donec posuere velit nec enim
                    <br /> sodales, nec placerat erat tincidunt.
                </h3>
            </div>
            <div class="image-footer">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                <div class="icons">
                    <i class="pi pi-github"></i>
                    <i class="pi pi-twitter"></i>
                </div>
            </div>
        </div>
    </div>
</div>