import { AppUtil } from './_helpers/app.util';
import { Component, OnDestroy } from '@angular/core';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { BreadcrumbService } from './app.breadcrumb.service';
import { Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { AccountService } from './_services';
import { AuthUser } from './_models';
import { ActivatedRoute, Router } from '@angular/router';
import { SpotlightService } from './spotlight/spotlight.service';
import { EngagementService } from './engagement/engagement.service';
import { BuysideService } from './buyside/buyside.service';
import { SellsideService } from './sellside/sellside.service';
import { UserActivityService } from './users-activity/users-activity.service';
@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnDestroy {

    subscription: Subscription;
    term: string;
    items: MenuItem[];
    user: AuthUser;
    spotlightResults: [];
    engagementResults: [];
    buySideResults: [];
    sellSideResults: [];
    minDate: Date;
    constructor(private router: Router,
        public breadcrumbService: BreadcrumbService,
        public app: AppComponent,
        private spotlightService: SpotlightService,
        private engagementService: EngagementService,
        private buySideService: BuysideService,
        private sellSideService: SellsideService,
        public appMain: AppMainComponent,
        private accountService: AccountService,
        private route: ActivatedRoute,
        private userActivityService: UserActivityService,
        private appUtil: AppUtil) {
        this.subscription = breadcrumbService.itemsHandler.subscribe(response => {
            this.items = response;
        });

        this.minDate = new Date();
    }

    ngOnInit() {
        this.user = this.accountService.userValue;
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    logout() {
        this.accountService.logout();
        this.userActivityService.logLogOutActivity(this.user.name);
    }

    profile() {
        if (this.user.role === 1) {
            this.router.navigate(['/user/' + this.user.uuid]);
        } else {
            this.router.navigate(['/contact/' + this.user.uuid]);
        }

    }

    _search(event) {
        const key = event.which;
        if (key === 13 && this.term != '') {
            this.router.navigate(['global-search'], { queryParams: { term: this.term } });
        }
    }
    searchSpotlights(event) {
        this.spotlightService.lookupSpotlight(event.query).subscribe(data => {
            this.spotlightResults = data as any;
        });
    }

    searchEngagements(event) {
        this.engagementService.lookupEngagement(event.query).subscribe(data => {
            this.engagementResults = data as any;
        });
    }

    searchBuySide(event) {
        this.buySideService.lookupBuySide(event.query).subscribe(data => {
            this.buySideResults = data as any;
        });
    }

    searchSellSide(event) {
        this.sellSideService.lookupSellSide(event.query).subscribe(data => {
            this.sellSideResults = data as any;
        });
    }

}
