import { FieldInfo, makeFieldInfo } from './../_models/field-info';
import { Component, OnInit, NgZone, OnDestroy, ViewEncapsulation } from '@angular/core';
import { BreadcrumbService } from '../app.breadcrumb.service';
import { DealService } from './deal.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Deal, Organization } from "../_models";
import { ConfirmationService, MenuItem } from 'primeng/api';
import { AccountService } from 'src/app/_services';
import { saveAs } from 'file-saver';
import { MessageService } from 'primeng/api';
import { AppUtil, SearchHeler } from '../_helpers';
import { UsersService } from '../users/users.service';
import { OrganizationService } from '../organization/organization.service';
import { DataService } from '../_services/data.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
@Component({
    selector: 'deals-list',
    templateUrl: './deals-list.component.html',
    styleUrls: ['./deals-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [ConfirmationService]
})
export class DealsListComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription = new Subscription();
    deals: Deal[];
    loading: boolean = false;
    currentMenuData: any;
    scrollTop: string;
    selectedDeals: Deal[];
    totalRecords: number = 0;
    dealItems: MenuItem[];
    conDialog: boolean = false;
    uploadPop: boolean = false;
    uploadItems: MenuItem[];
    localStrogeObj: any = null;
    localstroageKey: string = null;
    orgUuid: string;
    selectedColumns: any[];
    userExcel: {};
    selectedOrganizations: Organization[];
    source: string;
    sourceuuid: string;
    searchUrl: string = '/deals';
    fieldInfo: FieldInfo[];
    showCheckbox: boolean = false;

    showAddDialog: boolean = false;
    pageSize: number;
    firstRow: number;
    sortField: string;
    sortOrder: number;
    term: string;
    queryBuilderFields: any = {};
    queryBuilderQuery: any = {};
    nextSearchAfter: number | null = null;
    nextFieldId: number | null = null;
    firstValue: number | null = null;
    searchAfterStack: { searchAfter: number; fieldId: number }[] = [];
    classificationList: any = {
        CATEGORY_LEVEL1: [], CATEGORY_LEVEL2: [], CATEGORY_LEVEL3: [],
        VERTICAL: [], ECOSYSTEM: []
    };
    lookupList: any = {
        DEAL_TYPE: []
    };
    searchTerm: string | null = null;
    constructor(
        private dealService: DealService,
        private breadcrumbService: BreadcrumbService,
        private confirmationService: ConfirmationService,
        private messageService: MessageService,
        private router: Router,
        public accountService: AccountService,
        private route: ActivatedRoute,
        private dataService: DataService,
        public appUtil: AppUtil,
        public searchHelper: SearchHeler,
        public userService: UsersService,
        public organizationService: OrganizationService,
        private ngZone: NgZone,
    ) {
        this.breadcrumbService.setItems([{ label: 'Deal Listing' }]);
        this.currentMenuData = { id: '' };
        this.dealItems = [];
        this.uploadItems = [{ label: 'Template', command: () => { this.appUtil.downloadTemplate('deal-template.xlsx'); } }];
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe(params => {
            this.searchTerm = params['search_term'] || null;
        });
        const storedScrollTop = localStorage.getItem('DEAL_INFOSCROLL');
        if (storedScrollTop) {
            window.scrollTo(0, parseInt(storedScrollTop));
        }
        window.addEventListener('scroll', this.saveScrollPosition);
        this.classificationList = this.route.snapshot.data['classificationList'];
        this.lookupList = this.route.snapshot.data['lookupList'];
        this.pageSize = this.appUtil.pageSize;
        this.firstRow = 0;
        this.sortField = 'dealDate';
        this.sortOrder = -1;
        this.route.params.subscribe(data => {
            this.source = data.source == '' ? null : data.source;
            this.sourceuuid = data.sourceuuid == '' ? null : data.sourceuuid;
            this.showCheckbox = data.showCheckbox == 'true' ? true : false;
            this.searchUrl = (data.source == '' ? '' : '/' + data.source) + (data.sourceuuid == '' ? '' : '/' + data.sourceuuid)
                + '/deals' + (data.showCheckbox == 'true' ? '/add' : '');

            if (this.source && this.source == 'organization') {
                this.orgUuid = this.sourceuuid;
            }
            if (this.source == 'spotlight' && this.route.snapshot.queryParamMap.get('spot') != null) {
                this.localstroageKey = this.route.snapshot.queryParamMap.get('spot');
                if (sessionStorage.getItem(this.localstroageKey) != null) {
                    this.breadcrumbService.setItems([{ label: 'Spotlight: Deal Selection' }]);
                    this.localStrogeObj = JSON.parse(sessionStorage.getItem(this.localstroageKey));
                    this.selectedDeals = this.localStrogeObj['spotlight']['deals'];
                }
            }
        });
        let tmpFieldInfo: FieldInfo[] = [
            makeFieldInfo({ property: "dealDate", label: 'Date', type: 'date', searchType: 'date', columnIndex: 1, linkPrefix: '/deal/', linkType: "p", visible: 'A', columnWidth: "7rem", sortable: true }),
            makeFieldInfo({
                property: "buyerOrganizations", label: 'Buyers/Investors', type: 'string', searchType: 'text', sortable: false, forSearchTermQuery: true,
                visible: 'A', searchable: (this.source === undefined || this.source != 'organization'), columnIndex: 2, linkPrefix: '/organization/', linkType: "s"
            }),
            makeFieldInfo({
                property: "targetOrganization", label: 'Target/Issuer', type: 'string', searchType: 'text', forSearchTermQuery: true,
                visible: 'A', searchable: (this.source === undefined || this.source != 'organization'), columnIndex: 3, linkPrefix: '/organization/', linkType: "s"
            }),
            makeFieldInfo({
                property: "dealType", label: 'Deal Type', type: 'enum', searchType: 'exact', columnIndex: 4, columnWidth: "10rem",
                searchOptions: [{ 'name': 'Private Placement', 'uuid': 'PRIVATE_PLACEMENT' }, { 'name': 'Merger Acquisition', 'uuid': 'MERGER__ACQUISITION' }]
            }),
            makeFieldInfo({ label: "Rating", property: "rating", type: "rating", searchType: "text", columnIndex: 5, columnWidth: "7rem" }),
            makeFieldInfo({ label: "Transaction Value", property: "transactionValue", type: 'numeric_emptydash', searchType: "text", columnIndex: 6 }),
            makeFieldInfo({ property: "level1", label: 'Level1', type: 'classification', searchType: 'exact', searchOptions: this.classificationList.CATEGORY_LEVEL1, sortable: false, columnIndex: 7 }),
            makeFieldInfo({ property: "level2", label: 'Level2', type: 'classification', searchType: 'exact', searchOptions: this.classificationList.CATEGORY_LEVEL2, sortable: false, columnIndex: 8 }),
            makeFieldInfo({ property: "level3", label: 'Level3', type: 'classification', searchType: 'exact', searchOptions: this.classificationList.CATEGORY_LEVEL3, sortable: false, columnIndex: 9 }),
            makeFieldInfo({ property: "vertical", label: 'Vertical', type: 'classification', searchType: 'exact', searchOptions: this.classificationList.VERTICAL, sortable: false, columnIndex: 10 }),
            makeFieldInfo({ property: "ecosystem", label: 'Ecosystem', type: 'classification', visible: "F", searchType: 'exact', searchOptions: this.classificationList.ECOSYSTEM, sortable: false }),
            makeFieldInfo({ label: "EV/Revenue", property: "evRev", visible: "F", type: 'double', searchType: "double", sortable: false }),
            makeFieldInfo({ label: "EV/EBITDA", property: "evEbitda", visible: "F", type: 'double', searchType: "double", sortable: false }),
            makeFieldInfo({ label: "Business Description", property: "targetDescription", type: "string", searchType: 'text', sortable: false }),
            makeFieldInfo({ property: "createdDate", label: "Creation Date", type: "date", searchType: "date", visible: 'F' }),
            makeFieldInfo({ property: "status", label: "Status", type: "enum", searchType: "exact", visible: 'F', searchOptions: [{ 'name': 'Verification Pending', 'uuid': 'PENDING_VERIFICATION' }, { 'name': 'Active', 'uuid': 'ACTIVE' }] }),
            makeFieldInfo({ property: "relevance", label: "Relevance", type: "date", searchable: false, visible: 'H' }),
            makeFieldInfo({ property: "lastModifiedDate", label: "Modification Date", type: "date", searchType: "date", visible: 'F' }),
        ]
        this.fieldInfo = tmpFieldInfo;
    }

    get role() {
        return this.accountService.userValue.role;
    }

    edit() {
        this.router.navigate(['/deal/edit/' + this.currentMenuData.uuid]);
    }

    openNew() {
        this.router.navigate(['/deal/new']);
    }

    setCurrentItem(deal) {
        let items = []
        if (this.role == 1) {
            items = [
                {
                    label: 'Edit',
                    icon: 'pi pi-plus',
                    command: (event) => {
                        this.edit();
                    }
                },
                {
                    label: 'Add Contact',
                    icon: 'pi pi-plus',
                    command: (event) => {
                        this.newContact();
                    }
                },
                {
                    label: 'Delete',
                    icon: 'pi pi-trash',
                    command: (event) => {
                        let deals = [];
                        deals.push(deal);
                        this.delete(deals);
                    }
                }
            ];
        }
        this.currentMenuData = deal;
        this.dealItems = items;
    }

    delete(deals: any) {

        let dealUuids = [];
        for (let i = 0; i < deals.length; i++) {
            dealUuids.push(deals[i].uuid);
        }
        this.confirmationService.confirm({
            message: 'Are you sure you want to delete this deal?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.dealService.deleteDeals(dealUuids).subscribe(
                    data => {
                        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Deal Deleted', life: 3000 });
                        this.reload();
                    },
                    error => {
                        let errors = error.error;
                        if (errors.code && errors.code == 'VALIDATION_ERROR') {
                            this.messageService.add({ severity: 'error', summary: 'Error', detail: errors.subErrors[0].message, life: 3000 });
                        }
                    })
            }
        });
    }

    _search(e) {
        this.term = e.searchTerm;
        let searchParams = this.searchHelper.prepareSearchParams(e.searchTerm, e.filter, e.sortField, e.sortOrder, e.first, e.rows, this.fieldInfo);
        this.userExcel = searchParams;
        this.loading = true;
        if (this.source != null && this.sourceuuid != null && !(this.searchUrl.includes('add'))) {
            if (searchParams['rootRule']['rules'] != undefined && searchParams['rootRule']['rules'].length > 0) {
                let newRules = [];
                searchParams['rootRule'].condition = 'and';
                newRules.push({ condition: 'or', rules: searchParams['rootRule']['rules'] })
                newRules.push({
                    condition: 'or', rules: [
                        { 'searchType': 'exact', 'field': 'targetOrganization', 'operator': 'equals', 'value': this.sourceuuid },
                        { 'searchType': 'exact', 'field': 'buyerOrganizations', 'operator': 'in', 'values': [this.sourceuuid] }
                    ]
                })
                searchParams['rootRule']['rules'] = newRules;
            }
            else {
                searchParams['rootRule'].condition = 'or';
                searchParams['rootRule']['rules'].push({ 'searchType': 'exact', 'field': 'targetOrganization', 'operator': 'equals', 'value': this.sourceuuid });
                searchParams['rootRule']['rules'].push({ 'searchType': 'exact', 'field': 'buyerOrganizations', 'operator': 'in', 'values': [this.sourceuuid] });
            }
        }
        if (this.searchTerm != '' || this.searchTerm != null) {
            searchParams['query'] = this.searchTerm;
        }
        if(!this.appUtil.getPagination(this.totalRecords)){
        let isGoingForward = e.first > this.firstValue;
        if (isGoingForward && this.term == null && e.first - this.firstValue == e.rows) {
            if (this.searchAfterStack.length === 0 || this.searchAfterStack[this.searchAfterStack.length - 1].searchAfter !== this.nextSearchAfter) {
                this.searchAfterStack.push({ searchAfter: this.nextSearchAfter, fieldId: this.nextFieldId });
            }
            searchParams['nextSearchAfter'] = this.nextSearchAfter;
            searchParams['nextFieldId'] = this.nextFieldId;
        } else if (!isGoingForward && this.searchAfterStack.length > 0 && this.term == null && e.first - this.firstValue == e.rows) {
            this.searchAfterStack.pop();
            const prevSearchAfter = this.searchAfterStack.length > 0 ? this.searchAfterStack[this.searchAfterStack.length - 1] : null;
            if (prevSearchAfter) {
                searchParams['nextSearchAfter'] = prevSearchAfter.searchAfter;
                searchParams['nextFieldId'] = prevSearchAfter.fieldId;
            }
        }
        this.firstValue = e.first;
    }
        this.dealService.searchDeal(searchParams).subscribe(
            data => {
                let response = data as any;
                if (!this.appUtil.getPagination(response.totalRecords)) {
                    this.totalRecords = environment.customTotalRecords;
                } else {
                    this.totalRecords = response.totalRecords;
                }
                this.userExcel['pageSize'] = this.totalRecords;
                this.deals = response.records;
                this.nextSearchAfter = response.nextSearchAfter;
                this.nextFieldId = response.nextFieldId;
                this.loading = false;
                this.appUtil.reinitializeShowMore();
                if (e.searchTerm == null) {
                    const localeCompareKey = localStorage.getItem('DEAL_INFOSCROLL');
                    if (!this.searchUrl.includes("add")) {
                        if (localeCompareKey) {
                            this.ngZone.runOutsideAngular(() => {
                                setTimeout(() => {
                                    window.scrollTo(0, parseInt(localeCompareKey));
                                }, 0);
                            });
                        }
                    }
                }
            });
    }
    ngOnDestroy() {
        this.subscriptions.unsubscribe();
        window.removeEventListener('scroll', this.saveScrollPosition);
        localStorage.setItem('DEAL_INFOSCROLL', this.scrollTop);
    }

    saveScrollPosition = (): void => {
        this.scrollTop = window.scrollY.toString();
    }
    newContact() {
        this.conDialog = true;
    }

    dealUpload() {
        this.uploadPop = true;
    }

    getSelectedDeals() {
        this.localStrogeObj.spotlight.deals = this.selectedDeals;
        let spot = { uuid: this.localstroageKey, spotlight: this.localStrogeObj['spotlight'] };
        sessionStorage.setItem(spot.uuid, JSON.stringify(spot));
        if (this.localStrogeObj['spotlight']['uuid'] != '') {
            this.router.navigate(['spotlight/edit/' + this.localStrogeObj['spotlight'].uuid], { queryParams: { spot: spot.uuid } });
        } else {
            this.router.navigate(['spotlight/new'], { queryParams: { spot: spot.uuid } });
        }
    }
    associate() {
        if (this.source == 'buyside' || this.source == 'sellside') {
            let orgMap = new Map<string, any>();
            let orgs = [];
            this.selectedDeals.forEach((deal: any) => {
                if (deal.buyerOrganizations) {
                    deal.buyerOrganizations.forEach((org) => {
                        if (!orgMap.has(org.uuid)) {
                            orgMap.set(org.uuid, org);
                            orgs.push(org);
                        }
                    })
                }
                if (deal.targetOrganization && !orgMap.has(deal.targetOrganization.uuid)) {
                    orgMap.set(deal.targetOrganization.uuid, deal.targetOrganization);
                    orgs.push(deal.targetOrganization);
                }
            })
            this.selectedOrganizations = orgs;
            this.showAddDialog = true;
        }
        else if (this.source == 'spotlight') {
            this.localStrogeObj.spotlight.deals = this.selectedDeals;
            let spot = { uuid: this.localstroageKey, spotlight: this.localStrogeObj['spotlight'] };
            sessionStorage.setItem(spot.uuid, JSON.stringify(spot));
            if (this.localStrogeObj['spotlight']['uuid'] != '') {
                this.router.navigate(['spotlight/edit/' + this.localStrogeObj['spotlight'].uuid], { queryParams: { spot: spot.uuid } });
            } else {
                this.router.navigate(['spotlight/new'], { queryParams: { spot: spot.uuid } });
            }
        }
    }


    reload() {
        /*
          let e: LazyLoadEvent = { first: this.firstRow, rows: this.pageSize, sortField: this.sortField, sortOrder: this.sortOrder };
          this._search(e);
          */
    }

    patchDeal($event) {
        let dataToPost = { uuid: $event.data.uuid };
        dataToPost[$event.field] = $event.data[$event.field];
        this.dealService.patchProspect([$event.field], dataToPost).subscribe(
            data => {
                this.loading = false;
            },
            error => {
                //$event.data[$event.field] = oldValues[$event.field]
            }
        )
    }

    remove(organization) {
        this.selectedOrganizations = this.appUtil.deleteItem(this.selectedOrganizations, organization);
        if (this.selectedOrganizations.length == 0) {
            this.showAddDialog = false;
        }
    }

    downloadDeals() {
        this.dealService.downloadDeals(this.userExcel).subscribe(blob => saveAs(blob, "deals.xlsx"));
    }

    _updateSelectedColumns($event) {
        this.selectedColumns = $event;
    }

    addToSource() {

        this.dataService.setData('organizations-' + this.source + "-" + this.sourceuuid,
            this.selectedOrganizations);

        switch (this.source) {
            case 'buyside':
                this.router.navigate(['/buy-side/' + this.sourceuuid + '/prospects'])
                break;
            case 'sellside':
                this.router.navigate(['/sell-side/' + this.sourceuuid + '/prospects'])
                break;
        }

    }
    return() {
        window.history.back();
    }
}

