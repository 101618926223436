import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DealSpotlightContactService {

    constructor(private http: HttpClient) { }

    getContact(contactType: string, parentUuid: string, searchParams: any) {
        return this.http.get(environment.apiUrl + '/' + contactType + '/' + parentUuid + '/profile', { params: searchParams });
    }

    addContact(contactType: string, uuid: string, data: any) {
        return this.http.post(environment.apiUrl + '/' + contactType + '/' + uuid + '/profile', data);
    }

    private _listners = new Subject<any>();
    listen(): Observable<any> {
        return this._listners.asObservable();
    }
    filter(filterBy: string) {
        this._listners.next(filterBy);
    }

}
