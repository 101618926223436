<div class="login-body">
    <div class="login-wrapper">
        <div class="login-panel">
            <img src="assets/layout/images/tbp-logo.png" class="logo" alt="TruebBlue Partners" />
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="login-form">
                    <div class="p-fluid formgrid grid">
                        <div class="field col-12 md:col-12">
                            <h2>Reset Password</h2>
                        </div>
                        <div class="field col-12 md:col-12">
                            <p-messages severity="success" *ngIf="errorCode=='SUCCESS'">
                                <ng-template pTemplate>
                                    <div class="ml-2 text-left">
                                        <p>
                                            Password was successfully updated for you account.
                                            Please click on the button below to login and start using {{appUtil.appName}}.
                                        </p>
                                        <div class="field col-12 md:col-12">
                                            <button pButton pRipple label="Login" (click)="goToLogin()"></button>
                                        </div>
                                    </div>
                                </ng-template>
                            </p-messages>
                            <p-messages severity="error" *ngIf="errorCode=='INVALID_TOKEN'">
                                <ng-template pTemplate>
                                    <div class="ml-2 text-left">Password reset link you used has expired.<br />
                                        Click <strong><a routerLink="/account/forgot-password">here</a></strong> to
                                        generate a new link.</div>
                                </ng-template>
                            </p-messages>
                        </div>


                        <div class="field col-12 md:col-12 text-left">
                            <span class="p-input-icon-right">
                                <i class="pi pi-eye" (click)="showHidePassword()"></i>
                                <input pPassword id="password" formControlName="password" placeholder="Password"
                                    [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.password.errors }"
                                    [feedback]=false [showPassword]="showPassword" />
                            </span>
                            <small class="p-error" *ngIf="f.password.hasError('minlength') ">Minimum length required is
                                {{f.password.errors.minlength.requiredLength}} </small>
                            <small class="p-error" *ngIf="submitted && f.password.hasError('required')">Password is
                                required.</small>
                        </div>
                        <div class="field col-12 md:col-12 text-left">
                            <span class="p-input-icon-right">
                                <i class="pi pi-eye" (click)="showHidePassword()"></i>
                                <input pPassword id="confirmPassword" formControlName="confirmPassword"
                                    placeholder="Confirm Password" [showPassword]="showPassword" />
                                <small class="p-error"
                                    *ngIf="(f.password.value != '') && f.confirmPassword.hasError('confirmedValidator')">Passwords
                                    do not match</small>
                            </span>
                        </div>
                        <div class="field col-12 md:col-12">
                            <button pButton pRipple label="Reset"></button>
                        </div>
                    </div>
                </div>
            </form>
            <div class="p-fluid formgrid grid" style="width: 70%;">
                <div class="field col-12 md:col-12">
                    <h2>OR</h2>
                    <button pButton type="button" label="Sign In With Google" icon="pi pi-google" iconPos="left"
                        class="p-button-raised p-button-rounded p-button-help"></button>
                </div>
                <div class="field col-12 md:col-12">
                    <button pButton type="button" label="Sign In With LinkedIn" icon="pi pi-linkedin" iconPos="left"
                        class="p-button-raised p-button-rounded p-button-help"></button>
                </div>
            </div>

            <p>A problem? <a href="#">Click here</a> and let us help you.</p>
        </div>
        <div class="login-image">
            <div class="login-image-content">
                <h1>Benefits of {{appUtil.appName}}</h1>
                <h3>Lorem ipsum dolor sit amet, consectetur
                    <br /> adipiscing elit. Donec posuere velit nec enim
                    <br /> sodales, nec placerat erat tincidunt.
                </h3>
            </div>
            <div class="image-footer">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                <div class="icons">
                    <i class="pi pi-github"></i>
                    <i class="pi pi-twitter"></i>
                </div>
            </div>
        </div>
    </div>
</div>