import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class MediaService {

    constructor(private http: HttpClient) { }

    getRootFolders() {
        return this.http.get(environment.apiUrl + '/media/root-items');
    }

    getFolder(id) {
        return this.http.get(environment.apiUrl + '/media/folder/' + id);
    }


    getFolderContent(id, data) {
        return this.http.post(environment.apiUrl + '/media/folder/' + id + '/content', data);
    }

    createFolder(data, uuid: string) {
        return this.http.post(environment.apiUrl + '/media/' + (uuid == null ? 'root' : uuid) + '/folder', data);
    }

    updateFolder(data) {
        return this.http.put(environment.apiUrl + '/media/folder/' + data.uuid, data);
    }

    deleteFolder(data) {
        return this.http.delete(environment.apiUrl + '/media/folder/' + data.uuid);
    }

    uploadMedia(form, parentuuid) {
        return this.http.post(environment.apiUrl + '/media/' + parentuuid + '/file', form);
    }

    updateMedia(form, uuid) {
        return this.http.put(environment.apiUrl + '/media/file/' + uuid, form);
    }

    getItemsInPath(path) {
        return this.http.get(environment.apiUrl + '/media/path-items' + path);
    }
}
