import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from '../app.breadcrumb.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EngagementService } from './engagement.service';
import { Location } from '@angular/common';
import { OrganizationService } from 'src/app/organization/organization.service';
import { DataService } from '../_services/data.service';
import { AppUtil } from './../_helpers';
import { UsersService } from '../users/users.service';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'app-engagement.add',
    templateUrl: './engagement.add.component.html',
    styleUrls: ['./engagement.add.component.scss'],
    providers: [MessageService]
})
export class EngagementAddComponent implements OnInit {

    @ViewChild('cform') formElement;
    form: UntypedFormGroup;
    submitted = false;
    loadingSpinner: boolean = false;
    isEdit: boolean = false;
    organizations: any[] = [];
    companyResults: [];
    lookupList: any = { PROGRESS: [], ENGAGEMENT_TYPE: [] };
    status = [{ value: 'DRAFT' }, { value: 'ACTIVE' }, { value: 'ARCHIVED' }];
    participantResults: [];
    participants: [];
    accountForm: boolean = false;
    tbpEmployees = [];
    helpList: any = {ENGAGEMENT:[]};

    constructor(private usersService: UsersService, private dataService: DataService, private organizationService: OrganizationService, private route: ActivatedRoute, private _location: Location, private engagementService: EngagementService,
        private breadcrumbService: BreadcrumbService, private router: Router, private formBuilder: UntypedFormBuilder, private messageService: MessageService,public appUtil: AppUtil
    ) {

        if (this.router.url.indexOf("/edit/") != -1) {
            this.isEdit = true;
        }
        if (this.isEdit) {
            this.breadcrumbService.setItems([
                { label: 'Edit Engagement' }
            ]);
        } else {
            this.breadcrumbService.setItems([
                { label: 'New Engagement' }
            ]);
        }
    }

    ngOnInit(): void {

        this.lookupList = this.route.snapshot.data['lookupList'];
        this.tbpEmployees = this.route.snapshot.data['tbpEmployees'];
        this.helpList = this.route.snapshot.data['helpList']['ENGAGEMENT'];
        this.loadEngagementForm();
        if (this.isEdit) {
            this.engagementService.getEngagement(this.route.snapshot.params.uuid).subscribe(data => {
                this.modelToForm(data as any);
            });
        }
        else {
            const org = this.dataService.getDataByKey('org-');
            const user = this.dataService.getDataByKey('user-');
            if (org != undefined) {
                this.f.client.setValue(org);
            }
            if (user != undefined) {
                this.f.dealTeam.setValue([user]);
            }
        }


    }

    modelToForm(model) {
        if (model.startDate != null) {
            model.startDate = this.appUtil.DbToCalendarDateFormat(model.startDate);
        }
        if (model.endDate != null) {
            model.endDate = this.appUtil.DbToCalendarDateFormat(model.endDate);
        }
        if (model.actualCloseDate != null) {
            model.actualCloseDate = this.appUtil.DbToCalendarDateFormat(model.actualCloseDate);
        }
        this.form.setValue({
            uuid: model.uuid,
            name: model.name,
            startDate: model.startDate.split('T')[0],
            endDate: model.endDate.split('T')[0],
            actualCloseDate: (model.actualCloseDate == null) ? "" : model.actualCloseDate.split('T')[0],
            status: { 'value': model.status },
            engagementType: model.engagementType,
            progress: model.progress,
            nextMilestone: model.nextMilestone,
            client: model.client,
            dealTeam: model.dealTeam
        });
    }

    loadEngagementForm() {
        this.form = this.formBuilder.group({
            uuid: [''],
            name: ['', Validators.required],
            startDate: ['', Validators.required],
            endDate: ['', Validators.required],
            actualCloseDate: [],
            status: [{ 'value': 'DRAFT' }],
            engagementType: ['', Validators.required],
            progress: [],
            nextMilestone: ['', Validators.required],
            client: ['', Validators.required],
            dealTeam: []
        })
    }

    get f() { return this.form.controls; }

    searchCompanies(event) {
        this.organizationService.lookupOrganization(event.query).subscribe(data => {
            this.companyResults = data as any;
        });
    }

    searchParticipant(event) {
        this.usersService.lookupContact(event.query).subscribe(data => {
            this.participantResults = data as any;
        });
    }
    save() {

        if (this.f.startDate.value != '' && this.f.endDate.value != '') {
            const stDate = this.appUtil.DbToCalendarDateFormat((this.appUtil.calendarToDbDateFormat(this.f.startDate.value)));
            const enDate = this.appUtil.DbToCalendarDateFormat((this.appUtil.calendarToDbDateFormat(this.f.endDate.value)));
            if (stDate > enDate) {
                this.f.endDate.setErrors({ 'message': 'End Date must be greater than or equal to Start Date.' });

            }
            else {
                this.f.endDate.setErrors(null);
            }
            if (this.f.actualCloseDate.value != null && this.f.actualCloseDate.value != '') {
                const actDate = this.appUtil.DbToCalendarDateFormat((this.appUtil.calendarToDbDateFormat(this.f.actualCloseDate.value)));
                if (enDate > actDate) {
                    this.f.actualCloseDate.setErrors({ 'message': 'Actual Date must be greater than or equal to End Date.' });
                }
                else {
                    this.f.actualCloseDate.setErrors(null);
                }
            }
        }

        this.submitted = true;
        if (this.form.invalid) {
            return;
        }
        this.loadingSpinner = true;
        let { uuid, name, startDate, endDate, actualCloseDate, status, engagementType, progress, nextMilestone, client, dealTeam } = this.form.value;
        let dataToPost: any = { uuid, name, startDate, endDate, actualCloseDate, status, engagementType, progress, nextMilestone, client, dealTeam };

        if (this.f.actualCloseDate.value != null && this.f.actualCloseDate.value != '') {
            dataToPost['actualCloseDate'] = this.appUtil.calendarToDbDateFormat(this.f.actualCloseDate.value);
        }
        dataToPost['status'] = this.f.status.value.value;
        dataToPost['startDate'] = this.appUtil.calendarToDbDateFormat(this.f.startDate.value);
        dataToPost['endDate'] = this.appUtil.calendarToDbDateFormat(this.f.endDate.value);
        let apiToCall: any;

        if (this.isEdit) {
            apiToCall = this.engagementService.updateEngagement(dataToPost);
        }
        else {
            apiToCall = this.engagementService.addEngagement(dataToPost);
        }
        apiToCall.subscribe(
            data => {
                let engagement = data as any;
                let detail = 'Engagement Created';
                if (this.isEdit) {
                    detail = 'Engagement Updated';
                }
                this.dataService.setData('message', detail);
                this.router.navigate(['/engagement/' + engagement.uuid]);
                this.submitted = false;
            },
            error => {
                this.loadingSpinner = false;
                let errors = error.error;
                if (errors.code && errors.code == 'VALIDATION_ERROR') {
                    for (var i = 0; i < errors.subErrors.length; i++) {
                        this.f[errors.subErrors[i].field].setErrors({ 'message': errors.subErrors[i].message });
                    }
                    this.handleError(errors.subErrors[0].field);
                }
            }
        );
    }

    handleError(elemId) {
        this.messageService.add({ severity: 'error', summary: 'Form Errors', detail: "Form has errors. Please fix them first", life: 3000 });
        let invalidControl = this.formElement.nativeElement.querySelector('.ng-invalid');
        if (invalidControl) {
            invalidControl.focus();
        }
        else if (elemId != null) {
            invalidControl = this.formElement.nativeElement.querySelector('#' + elemId);
            if (invalidControl) {
                invalidControl.focus();
            }
        }
    }

    cancel() {
        this._location.back();
    }

    createAccount() {
        this.accountForm = true;
    }

    public onFormGroupChangeEvent(_event) {
        this.f.client.setValue(_event);
    }
   
    getHelpText(key) {
        let test = this.helpList.filter(function (element) {
            return element.key == key;
        });
        if (test[0] != undefined)
            return test[0]['text'];
    }
}
