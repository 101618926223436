<div class="grid table-demo">
    <div class="col-12" *ngIf="role == 1">
        <div class="card divider-card">
            <p-toolbar styleClass="headerSticky" *ngIf="role == 1">
                <ng-template pTemplate="left">
                </ng-template>

                <ng-template pTemplate="right">
                    <button pButton pRipple label="Back" icon="pi pi-arrow-left" class="p-button-success mr-2" (click)="appUtil.return()"></button>
                    <button pButton pRipple label="Edit" icon="pi pi-pencil" class="p-button-success mr-2"
                        (click)="edit()"></button>
                    <!-- <button pButton pRipple [label]="Delete" icon="pi pi-trash" class="p-button-danger  mr-2" ></button>-->
                    <p-button label="Preview" icon="pi pi-print" class="p-button-success mr-2" (click)="preview()">
                    </p-button>
                </ng-template>

            </p-toolbar>
            <div class="p-fluid formgrid grid">
                <div class="field col-12">
                    <div class="grid mt-1">
                        <div class="col-12 md:col-6">
                            <div class="inline label">Created By:</div>
                             {{spotlight.createByUser}} on {{spotlight.createdDate | date:"MM-dd-yyyy HH:mm:ss"}}
                        </div>
                        <div class="col-12 md:col-6 text-right">
                            <div class="inline label">Modified By:</div>
                             {{spotlight.modifiedByUser}} on {{spotlight.lastModifiedDate | date:"MM-dd-yyyy HH:mm:ss"}}
                        </div>
                    </div>
                    <p-divider type="dashed"></p-divider>
                </div>
            </div>

            <h5>Spotlights</h5>
            <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-4">
                    <div class="grid mt-1 limit-width-large dashed-bottom">
                        <div class="col-12 md:col-4 label md:text-right">
                            Title
                        </div>
                        <div class="field col-12 md:col-8">
                            {{spotlight.title}}&nbsp;
                        </div>
                    </div>
                </div>
                <div class="field col-12 md:col-4">
                    <div class="grid mt-1 limit-width-large dashed-bottom">
                        <div class="col-12 md:col-4 label md:text-right">
                            Spotlight Date
                        </div>
                        <div class="field col-12 md:col-8">
                            {{spotlight.spotlightDate | date:"MM-dd-yyyy"}}&nbsp;
                        </div>
                    </div>
                </div>
                <div class="field col-12 md:col-4">
                    <div class="grid mt-1 limit-width-large dashed-bottom">
                        <div class="col-12 md:col-4 label md:text-right">
                            Status
                        </div>
                        <div class="field col-12 md:col-8">
                            {{spotlight.status | titlecase}}&nbsp;
                        </div>
                    </div>
                </div>                
                <div class="field col-12 md:col-4">
                    <div class="grid mt-1 limit-width-large dashed-bottom">
                        <div class="col-12 md:col-4 label md:text-right">
                            Url Key
                        </div>
                        <div class="field col-12 md:col-8">
                            {{spotlight?.urlKey}}&nbsp;
                        </div>
                    </div>
                </div>
                <div class="field col-12 md:col-4">
                    <div class="grid mt-1 limit-width-large dashed-bottom">
                        <div class="col-12 md:col-4 label md:text-right">
                            Companies Involved
                        </div>
                        <div class="field col-12 md:col-8">
                            <span *ngFor="let organizations of spotlight.organizations; let last = last;">
                                <a
                                    [routerLink]="['/organization/', organizations.uuid]">{{organizations.name}}</a>{{last?'':';
                                '}}
                            </span>&nbsp;
                        </div>
                    </div>
                </div>
                <div class="field col-12 md:col-4">
                    <div class="grid mt-1 limit-width-large dashed-bottom">
                        <div class="col-12 md:col-4 label md:text-right">
                            Meta Keywords
                        </div>
                        <div class="field col-12 md:col-8">
                            {{spotlight.metaKeywords}}&nbsp;
                        </div>
                    </div>
                </div>
                <div class="field col-12 md:col-4">
                    <div class="grid mt-1 limit-width-large dashed-bottom">
                        <div class="col-12 md:col-4 label md:text-right">
                            Meta Description
                        </div>
                        <div class="field col-12 md:col-8">
                            {{spotlight.metaDescription}}&nbsp;
                        </div>
                    </div>
                </div>
                <div class="field col-12 md:col-4">
                    <div class="grid mt-1 limit-width-large dashed-bottom">
                        <div class="col-12 md:col-4 label md:text-right">
                            Left Logo
                        </div>
                        <div class="field col-12 md:col-8">
                            <img src="{{spotlight.leftLogo?.url}}" style="height: 70px;" />
                        </div>
                    </div>
                </div>
                <div class="field col-12 md:col-4">
                    <div class="grid mt-1 limit-width-large dashed-bottom">
                        <div class="col-12 md:col-4 label md:text-right">
                            Right Logo
                        </div>
                        <div class="field col-12 md:col-8">
                            <img src="{{spotlight.rightLogo?.url}}" style="height: 70px;" />
                        </div>
                    </div>
                </div>

            </div>
            <div class="p-fluid  grid">
                <div class="field col-12 md:col-4">
                    <div class="grid  limit-width-large dashed-bottom">
                        <div class="col-6 md:col-4 label md:text-right">
                            Content
                        </div>
                        <div class="field col-6 md:col-8">
                            <div class="spotlight-preview" innerHTML="{{spotlight.content}}"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div>
                <p-panel [toggleable]="true" [collapsed]="false" expandIcon="pi pi-chevron-left"
                    collapseIcon="pi pi-chevron-down">
                    <p-header>
                        <span class="p-panel-title text-teal">
                            <i class="pi pi-bars"></i> Associated Deals
                        </span>
                    </p-header>
                    <p-table [value]="spotlight.deals" sortField="dealDate" sortOrder="-1" [autoLayout]="true"
                        styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Date</th>
                                <th>Buyers/Investors</th>
                                <th>Target/Issuer</th>
                                <th>Deal Type</th>
                                <th>Transaction Value ($m)</th>
                                <th>EV/Revenue ($m)</th>
                                <th>EV/EBITDA</th>
                                <th>Level1</th>
                                <th>Level2</th>
                                <th>Level3</th>
                                <th>Vertical/Industry</th>
                                <th>Ecosystem</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-deal>
                            <tr [ngClass]="{ primary: (deal.uuid == spotlight.primaryDeal?.uuid)}">
                                <td class="wrap"
                                    style="width: 7rem"><a [routerLink]="['/deal/', deal.uuid]">{{deal.dealDate |
                                        date:'MM-dd-yyyy'}}</a></td>
                                <td class="wrap">
                                    <span class="limitedText limitText">
                                        <span *ngFor="let organization of deal.buyerOrganizations; let last = last;"><a
                                                [routerLink]="['/organization/', organization.uuid]">{{organization.name}}</a>{{last?'':';
                                            '}}</span>
                                    </span>
                                    <a href="javascript:" (click)="appUtil.showHideClampedContent($event)"
                                        class="moreless hidden ml-2 p-button-danger"
                                        style="float:right;display:block;">More</a>
                                </td>
                                <td class="wrap"><a *ngIf="deal.targetOrganization"
                                        [routerLink]="['/organization/', deal.targetOrganization.uuid]">{{deal.targetOrganization?.name}}</a>
                                </td>
                                <td class="wrap">{{deal.dealType | dealPipe}}</td>
                                <td class="wrap">
                                    {{deal.transactionValue==null?'-':deal.transactionValue==0?'-':deal.transactionValue}}
                                </td>
                                <td class="wrap">{{deal.evRev==null?'-':deal.evRev==0?'-':deal.evRev}}</td>
                                <td class="wrap">{{deal.evEbitda==null?'-':deal.evEbitda==0?'-':deal.evEbitda}}</td>
                                <td class="wrap">
                                    <span class="limitedText limitText">
                                        <span
                                            *ngFor="let classification of deal.classification.level1; let last = last;">{{classification.name}}{{last?'':';
                                            '}}</span>
                                    </span>
                                    <a href="javascript:" (click)="appUtil.showHideClampedContent($event)"
                                        class="moreless hidden ml-2 p-button-danger"
                                        style="float:right;display:block;">More</a>
                                </td>
                                <td class="wrap">
                                    <span class="limitedText limitText">
                                        <span
                                            *ngFor="let classification of deal.classification.level2; let last = last;">{{classification.name}}{{last?'':';
                                            '}}</span>
                                    </span>
                                    <a href="javascript:" (click)="appUtil.showHideClampedContent($event)"
                                        class="moreless hidden ml-2 p-button-danger"
                                        style="float:right;display:block;">More</a>
                                </td>
                                <td class="wrap">
                                    <span class="limitedText limitText">
                                        <span
                                            *ngFor="let classification of deal.classification.level3; let last = last;">{{classification.name}}{{last?'':';
                                            '}}</span>
                                    </span>
                                    <a href="javascript:" (click)="appUtil.showHideClampedContent($event)"
                                        class="moreless hidden ml-2 p-button-danger"
                                        style="float:right;display:block;">More</a>
                                </td>
                                <td class="wrap">
                                    <span class="limitedText limitText">
                                        <span
                                            *ngFor="let classification of deal.classification.vertical; let last = last;">{{classification.name}}{{last?'':';
                                            '}}</span>
                                    </span>
                                    <a href="javascript:" (click)="appUtil.showHideClampedContent($event)"
                                        class="moreless hidden ml-2 p-button-danger"
                                        style="float:right;display:block;">More</a>
                                </td>
                                <td class="wrap">
                                    <span class="limitedText limitText">
                                        <span
                                            *ngFor="let classification of deal.classification.ecosystem; let last = last;">{{classification.name}}{{last?'':';
                                            '}}</span>
                                    </span>
                                    <a href="javascript:" (click)="appUtil.showHideClampedContent($event)"
                                        class="moreless hidden ml-2 p-button-danger"
                                        style="float:right;display:block;">More</a>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="12">No deals found to be associated with spotlight</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </p-panel><br>
            </div> -->
            <!--<deal-spotlight-contact contactType="spotlight" [parentUuid]="uuid"></deal-spotlight-contact>-->
        </div>
    </div>
    <div class="col-12" *ngIf="role == 0">
        <div class="p-fluid  grid">
            <div class="field col-12" style="margin-bottom: -26px;">
                {{spotlight.spotlightDate | date:"MM-dd-yyyy"}}
            </div>
            <div class="field col-6">
                <img src="{{spotlight.leftLogo?.url}}" style="float: left; width: 100px; height: 100px;" />
            </div>
            <div class="field col-6">
                <img src="{{spotlight.rightLogo?.url}}" style="float: right; width: 100px; height: 100px;" />
            </div>
            <div class="field col-12" style="text-align: center; color: #1E4E78;">
                <h1> {{spotlight.title}}</h1>
            </div>
            <div class="field col-12" innerHTML="{{spotlight.content}}"></div>
        </div>
    </div>

</div>
<tbp-spotlight-preview [(show)]="previewDialog" [spotlight]="spotlight"></tbp-spotlight-preview>
<p-toast></p-toast>