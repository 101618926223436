import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SellsideService {

    constructor(private http: HttpClient) { }

    searchSellSides(searchParams: any) {
        return this.http.post(environment.apiUrl + '/sellsides', searchParams);
    }

    getSellside(uuid: string) {
        return this.http.get(environment.apiUrl + '/sellside/' + uuid);
    }

    addSellside(data) {
        return this.http.post(environment.apiUrl + '/sellside', data);
    }

    updateSellside(data) {
        return this.http.put(environment.apiUrl + '/sellside/' + data.uuid, data);
    }

    lookupSellSide(query) {
        return this.http.get(environment.apiUrl + '/sellside/lookup', { params: { query } });
    }

    getBlindProfile(sellUuid: string) {
        return this.http.get(environment.apiUrl + '/sellside/' + sellUuid + '/blind-profile');
    }

    addBlindProfile(sellUuid: string, data: any) {
        return this.http.post(environment.apiUrl + '/sellside/' + sellUuid + '/blind-profile', data);
    }

    UpdateBlindProfile(sellUuid: string, uuid: string, data: any) {
        return this.http.put(environment.apiUrl + '/sellside/blind-profile/' + uuid, data);
    }

    patchBlindProfile(fieldsToBeUpdated: string[], data: any): Observable<any> {
        return this.http.patch(environment.apiUrl + '/sellside/blind-profile/' + data.uuid,
            { "fieldsToBeUpdated": fieldsToBeUpdated, "model": data });
    }

    getCampaign(uuid: string, searchParams: any) {
        if (searchParams == null)
            return this.http.get(environment.apiUrl + '/sellside/' + uuid + '/all-campaigns');
        else
            return this.http.post(environment.apiUrl + '/sellside/' + uuid + '/campaign', searchParams);
    }

    getProspects(uuid: string, searchParams: any) {
        return this.http.post(environment.apiUrl + '/sellside/' + uuid + '/prospects', searchParams);
    }

    getContactsAsProspects(uuid: string) {
        return this.http.post(environment.apiUrl + '/sellside/' + uuid + '/contact-as-prospect', null);
    }

    addProspects(uuid: string, contactIds: string[], accountIds: string[]) {
        return this.http.post(environment.apiUrl + '/sellside/' + uuid + '/add-prospects',
            { "contacts": contactIds, "accounts": accountIds });
    }
    addAssociatedContactsAsProspects(uuid: string, contactIds: string[] , prospect:string) {
        return this.http.post(environment.apiUrl + '/sellside/' + uuid + '/add-associated-contact-as-prospects',
            { "contacts": contactIds ,"prospectUuid" :prospect} );
    }
    patchProspect(fieldsToBeUpdated: string[], data: any) {
        return this.http.patch(environment.apiUrl + '/sellside/prospect/' + data.uuid,
            { "fieldsToBeUpdated": fieldsToBeUpdated, "model": data });
    }

    deleteProspects(sellsideUuid: string, prospects: any) {
        return this.http.post(environment.apiUrl + '/sellside/' + sellsideUuid + "/remove-prospects", prospects);
    }

    deleteSellsideScreens(sellsides: any): Observable<any> {
        return this.http.delete(environment.apiUrl + '/sellsides', { params: { sellsides } });
    }

    addProspectAsRecipients(campaignuuids: string[], prospectUuid: string) {
        return this.http.post(environment.apiUrl + "/sellside/prospect/" + prospectUuid + "/as-recipient", campaignuuids);
    }

    updateProspectSortOrder(prospectUuid: string, newSortOrder: number) {
        return this.http.put(environment.apiUrl + "/sellside/prospect/" + prospectUuid + "/updateSortOrder", { newSortOrder });
    }
    downloadProspect(searchParams: any, uuid: string) {
        return this.http.post(environment.apiUrl + '/sellside/' + uuid + '/download/xlsx', searchParams, {

            responseType: 'blob'
        });
    }
    updateSellsideList(fieldsToBeUpdated: string[], data: any) {    
        return this.http.patch(environment.apiUrl + '/sellsides/' + data.uuid,
            { "fieldsToBeUpdated": fieldsToBeUpdated, "model": data });
    }
}
