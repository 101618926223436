import { AuthUser } from './../_models/authuser';
import { AppUtil } from './../_helpers/app.util';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from './../_services';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { first } from 'rxjs/operators';
@Component({
    selector: 'app-verify',
    templateUrl: './verify.component.html',
    styleUrls: ['./verify.component.scss'],
    providers: [MessageService, ConfirmationService]
})
export class VerifyComponent implements OnInit {

    errorCode: string;
    linkErrorCode: string;
    form: UntypedFormGroup;
    loading = false;
    submitted = false;
    showDialog: boolean = false;
    hasError:Function;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private messageService: MessageService,
        private accountService: AccountService,
        private route: ActivatedRoute,
        private router: Router,
        public appUtil: AppUtil) {
    }

    ngOnInit(): void {
        this.hasError = this.appUtil.hasError;
        this.errorCode = '';
        this.messageService.clear();
        this.showDialog = false;
        this.form = this.formBuilder.group({
            email: ['', [Validators.required, Validators.pattern(this.appUtil.emailPattern)]]
        });

        this.accountService.activate(this.route.snapshot.params.actionUUID)
            .pipe(first())
            .subscribe({
                next: data => {
                    let respData = data as any;
                    this.accountService.storeAuthUser(respData.user);
                    if (respData.redirectToUrl != null && respData.redirectToUrl != '') {
                        window.location.href = respData.redirectToUrl;
                    }
                    else {
                        this.errorCode = 'VERIFIED';
                        setTimeout(() => {
                            this.goToHome(respData.user);
                        }, 6000);
                    }
                },
                error: error => {
                    let errors = error.error;
                    if (errors.code) {
                        this.errorCode = errors.code;
                    }
                }
            });

    }

    get f() { return this.form.controls; }

    showLinkForm() {
        this.submitted = false;
        this.linkErrorCode = '';
        this.f.email.reset();
        this.showDialog = true;
    }

    sendActivationEmail() {
        this.submitted = true;
        this.linkErrorCode = '';
        if (this.form.invalid) {
            return;
        }
        this.messageService.clear();
        this.accountService.sendActivationEmail(this.f.email.value).subscribe(
            data => {
                this.linkErrorCode = 'SUCCESS';
            },
            error => {
                let errors = error.error;
                if (errors.code) {
                    this.linkErrorCode = errors.code;
                }
            }
        );
    }


    goToLogin() {
        this.router.navigate(['/account/login']);
    }

    goToHome(data) {
        if (data.status == "PENDING_PROFILE") {
            this.router.navigate(['/profile/' + data.uuid]);
        }
        else {
            const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
            this.router.navigateByUrl(returnUrl);
        }
    }

    goToSignUp() {
        this.router.navigate(['/account/register']);
    }


    hideDialog() {
        this.linkErrorCode = '';
        this.form.controls.email.reset();
        this.showDialog = false;
    }
}
