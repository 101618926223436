import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment } from '@angular/router';
import { DealAddComponent } from "./deal.add.component";
import { DealViewComponent } from './deal.view.component';
import { ConfigurationResolver } from '../configuration/configuration.resolver';
import { ClassificationResolver } from './../classification/classification.resolver';
import { DealResolver } from './deal.resolver';
import { UserResolver } from '../users/user.resolver';
import { HelpResolver } from '../help/help.resolver';
import { DealsListComponent } from './deals-list.component';
import { resolve } from 'dns';

const routes: Routes = [
    {
        matcher: (url) => { // urls like deals, organization/:uuid/deals, buyside/:uuid/deals/add
            if ( ((url.length == 1 && url[0].path == 'deals') || (url.length >=3 && url[2].path == 'deals')) && url.length <=4) {
                return {
                    consumed: url,
                    posParams: {
                        source: url.length !=1?url[0]:new UrlSegment('', {}),
                        sourceuuid: url.length !=1?url[1]:new UrlSegment('', {}),
                        showCheckbox: new UrlSegment(url.length ==4?'true':'false', {})
                    }
                };
            }
            return null;
        },
        component: DealsListComponent,
        resolve: {
            classificationList: ClassificationResolver,
            lookupList: ConfigurationResolver
        }
    },
    {
        path: 'deal/new', component: DealAddComponent,
        resolve: {
            lookupList: ConfigurationResolver,
            classificationList: ClassificationResolver,
            tbpEmployees: UserResolver,
            helpList: HelpResolver,
        }
    },
    { path: 'deal/:uuid', component: DealViewComponent },
    {
        path: 'deal/edit/:uuid', component: DealAddComponent,
        resolve: {
            lookupList: ConfigurationResolver,
            classificationList: ClassificationResolver,
            deal: DealResolver,
            tbpEmployees: UserResolver,
            helpList: HelpResolver,
        }
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [ConfigurationResolver, ClassificationResolver, DealResolver, UserResolver, HelpResolver]
})

export class DealRoutingModule { }
