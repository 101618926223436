import { Component, ElementRef, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MenuService } from './app.menu.service';
import { PrimeNGConfig } from 'primeng/api';
import { AppComponent } from './app.component';
import { UsersService } from './users/users.service';
import { AccountService } from './_services';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { AppUtil } from './_helpers';

@Component({
    selector: 'app-main',
    templateUrl: './app.main.component.html',   
    providers: [MessageService]
})
export class AppMainComponent implements OnInit {
    overlayMenuActive: boolean;

    staticMenuDesktopInactive: boolean;

    staticMenuMobileActive: boolean;

    menuClick: boolean;

    search = false;

    searchClick = false;

    userMenuClick: boolean;

    topbarUserMenuActive: boolean;

    notificationMenuClick: boolean;

    topbarNotificationMenuActive: boolean;

    rightMenuClick: boolean;

    rightMenuActive: boolean;

    configActive: boolean;

    configClick: boolean;

    resetMenu: boolean;

    menuHoverActive = false;
    showError: boolean = false;
    errorMsg: string;
    PENDING_VERIFICATION: number = 0;
    quickSpinner: boolean = false;
    quickSubmitted: boolean = false;
    formInitialValue: any = null;
    tbpEmployees = [];
    quickTaskShow: boolean;
    selectedHour: number = undefined;
    selectedMinute: number = undefined;
    quickTaskForm: UntypedFormGroup;
    entityTypes = [{ value: 'Spotlight' }, { value: 'Industry Report' }, { value: 'Marketing' }, { value: 'Engagement Outreach' }, { value: 'BuySide Screen Outreach' }, { value: 'SellSide Screen Outreach' }];
    status = [{ code: 'STARTED', name: 'Started' }, { code: 'NOT_STARTED', name: 'Not Started' }, { code: 'COMPLETED', name: 'Completed' }];
    priority = [{ code: 'LOW', name: 'Low' }, { code: 'HIGH', name: 'High' }, { code: 'MEDIUM', name: "Medium" }];
    @ViewChild("msgElem", { static: false }) msgElem: ElementRef;
    constructor(private messageService: MessageService,
         private formBuilder: UntypedFormBuilder,
        public accountService: AccountService,
        private usersService: UsersService, 
        private menuService: MenuService,
        private primengConfig: PrimeNGConfig,
        public app: AppComponent, public appUtil: AppUtil) {
        this.quickTaskForm = this.formBuilder.group({
            contacts: [],
            organization: [],
            subject: [null, Validators.required],
            notes: [],
            dueDate: [],
            status: ['NOT_STARTED'],
            priority: ['MEDIUM'],
            assignedTo: [null, Validators.required],
            relatedType: [],
            createTask: [false],
            spotlight: [],
            engagement: [],
            buySideScreen: [],
            sellSideScreen: [],
            reminderChecked: false,
            reminderDate: [],
            completionDate: [null],
        });
        this.formInitialValue = this.quickTaskForm.value;
    }

    ngOnInit(): void {
        this.usersService.getTBPEmployees().subscribe(
            data => {
                this.tbpEmployees = data as any;
            });
        if (this.accountService.userValue.role == 1) {
            this.getUserCount();
            //setInterval(() => {this.getUserCount();}, 60000);     
        }
    }
    get f() { return this.quickTaskForm.controls; }

    onLayoutClick() {
        if (!this.searchClick) {
            this.search = false;
        }

        if (!this.userMenuClick) {
            this.topbarUserMenuActive = false;
        }

        if (!this.notificationMenuClick) {
            this.topbarNotificationMenuActive = false;
        }

        if (!this.rightMenuClick) {
            this.rightMenuActive = false;
        }

        if (!this.menuClick) {
            if (this.isSlim()) {
                this.menuService.reset();
            }

            if (this.overlayMenuActive || this.staticMenuMobileActive) {
                this.hideOverlayMenu();
            }

            this.menuHoverActive = false;
            this.unblockBodyScroll();
        }

        if (this.configActive && !this.configClick) {
            this.configActive = false;
        }

        this.searchClick = false;
        this.configClick = false;
        this.userMenuClick = false;
        this.rightMenuClick = false;
        this.notificationMenuClick = false;
        this.menuClick = false;
    }

    onMenuButtonClick(event) {
        this.menuClick = true;
        this.topbarUserMenuActive = false;
        this.topbarNotificationMenuActive = false;
        this.rightMenuActive = false;

        if (this.isOverlay()) {
            this.overlayMenuActive = !this.overlayMenuActive;
        }

        if (this.isDesktop()) {
            this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
        } else {
            this.staticMenuMobileActive = !this.staticMenuMobileActive;
            if (this.staticMenuMobileActive) {
                this.blockBodyScroll();
            } else {
                this.unblockBodyScroll();
            }
        }

        event.preventDefault();
    }

    onSearchClick(event) {
        this.search = !this.search;
        this.searchClick = !this.searchClick;
    }

    onSearchHide(event) {
        this.search = false;
        this.searchClick = false;
    }

    onMenuClick($event) {
        this.menuClick = true;
        this.resetMenu = false;
    }

    onTopbarUserMenuButtonClick(event) {
        this.userMenuClick = true;
        this.topbarUserMenuActive = !this.topbarUserMenuActive;

        this.hideOverlayMenu();

        event.preventDefault();
    }

    onTopbarNotificationMenuButtonClick(event) {
        this.notificationMenuClick = true;
        this.topbarNotificationMenuActive = !this.topbarNotificationMenuActive;

        this.hideOverlayMenu();

        event.preventDefault();
    }

    onRightMenuClick(event) {
        this.rightMenuClick = true;
        this.rightMenuActive = !this.rightMenuActive;

        this.hideOverlayMenu();

        event.preventDefault();
    }

    onRippleChange(event) {
        this.app.ripple = event.checked;
        this.primengConfig = event.checked;
    }

    onConfigClick(event) {
        this.configClick = true;
    }

    isSlim() {
        return this.app.menuMode === 'slim';
    }

    isOverlay() {
        return this.app.menuMode === 'overlay';
    }

    isDesktop() {
        return window.innerWidth > 991;
    }

    isMobile() {
        return window.innerWidth <= 991;
    }

    hideOverlayMenu() {
        this.overlayMenuActive = false;
        this.staticMenuMobileActive = false;
    }

    blockBodyScroll(): void {
        if (document.body.classList) {
            document.body.classList.add('blocked-scroll');
        } else {
            document.body.className += ' blocked-scroll';
        }
    }

    unblockBodyScroll(): void {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
                'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    }

    getUserCount() {
        this.usersService.getUserCount().subscribe(data => {
            this.PENDING_VERIFICATION = data['PENDING_VERIFICATION'];
        });
    }

    quickTaskPop() {
        this.quickTaskShow = true;
        this.f.assignedTo.setValue(this.accountService.userValue.uuid);
    }

    hideQuickTask() {
        this.f.createTask.setValue([false]);
        this.showError = false;
        this.quickTaskForm.reset(this.formInitialValue);
        this.quickTaskShow = false;
        this.quickSubmitted = false;
        this.quickSpinner = false;
        this.f.notes.clearValidators();
        this.f.notes.setErrors(null);
        this.f.subject.setValidators([Validators.required]);
    }


    savefrom() {
        this.quickSubmitted = true;
        if(this.f.status.value === 'COMPLETED'){
            this.f.dueDate.setErrors(null); 
            this.f.reminderDate.setValue(null);
        }
        if (this.f.completionDate.value != null && this.f.dueDate.value == null) {
            this.f.reminderDate.setValue(null);   
            this.f.dueDate.setErrors({ 'message': 'Due Date is Required for Completion Date.' });
            return;
        }
        if (this.f.reminderDate.value != null && this.f.dueDate.value == null) { 
            this.f.completionDate.setValue(null);   
            this.f.dueDate.setErrors({ 'message': 'Due Date is Required for Reminder Date.' });
            return;
        }
        if ((this.f.organization.value == null || this.f.organization.value == '') &&
            (this.f.contacts.value == null || (this.f.contacts.value).length == 0)) {
            this.errorMsg = "Please either fill the Account or Contact";
            this.showError = true;
            return;
        }
        if (this.f.dueDate.value != null && this.f.reminderDate.value != null) {
            this.f.completionDate.setValue(null);   
            const dueDate = this.appUtil.calendarToDbDateFormat(this.f.dueDate.value);
            const reminderDate = this.appUtil.calenderToDbDate(this.f.reminderDate.value);
            const date = new Date(this.f.reminderDate.value);
            const minutes = date.getMinutes();
            const newDue = dueDate.split("T");
            const newrem = reminderDate.split("T");
            if (newDue[0] < newrem[0] || newDue[0] == newrem[0]) {
                this.f.dueDate.setErrors({ 'message': 'Due Date must be greater than Reminder Date.' });
                return;
        }else if( minutes!= 0 && minutes!= 30){
                this.f.reminderDate.setErrors({ 'message': 'Task Reminder time minute Should be less than  30 ' });
                return;
            }
            else {
                this.f.dueDate.setErrors(null);
                this.f.reminderDate.setErrors(null);
            }
        }
        if (this.quickTaskForm.invalid ) {
            return false;
        }
        this.showError = false;
        let { createTask, contacts, organization } = this.quickTaskForm.value;
        let dataToPost: any = { createTask, contacts, organization, task: {}, note: {} };
        dataToPost.note.notes = this.f.notes.value;
        dataToPost.task.subject = this.f.subject.value;
        dataToPost.task.status = this.f.status.value;
        dataToPost.task.priority = this.f.priority.value;
        dataToPost.task.completionDate = (this.f.completionDate.value == null) ? null : this.appUtil.calendarToDbDateFormat(this.f.completionDate.value);
        dataToPost.task.reminderDate = (this.f.reminderDate.value == null) ? null : this.appUtil.calendarToDbDateFormat(this.f.reminderDate.value);
        dataToPost.task.reminderChecked = this.f.reminderChecked.value;
        dataToPost.task.assignedTo = this.appUtil.getObject(this.tbpEmployees, this.f.assignedTo.value);;
        dataToPost.task.dueDate = (this.f.dueDate.value == null) ? null : this.appUtil.calendarToDbDateFormat(this.f.dueDate.value);
        if(this.f.relatedType.value)
        {
            const relatedType = this.f.relatedType.value.value;
        dataToPost.task.relatedType = relatedType;
            if (relatedType === 'Spotlight') {
                dataToPost.task.spotlight=this.f.spotlight.value
            } else if (relatedType === 'Engagement Outreach') {
                dataToPost.task.engagement = this.f.engagement.value;
            } else if (relatedType === 'BuySide Screen Outreach') {
                dataToPost.task.buySideScreen = this.f.buySideScreen.value;
            } else if (relatedType === 'SellSide Screen Outreach') {
                dataToPost.task.sellSideScreen = this.f.sellSideScreen.value;
            }
        }
        else
        {
            dataToPost.task.relatedType = null;
        }
        
        dataToPost.createTask = (this.f.createTask.value == true) ? false : true;
        if (this.f.organization.value == '') {
            dataToPost['organization'] = null;
        }
        let apiToCall: any;
        apiToCall = this.usersService.quickNote(dataToPost);
        apiToCall.subscribe(
            data => {
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: "Save Successfully", life: 3000 });
                this.hideQuickTask();
            },
            error => {
                let errors = error.error;
                if (errors.code && errors.code == 'VALIDATION_ERROR') {
                    for (var i = 0; i < errors.subErrors.length; i++) {
                        this.f[errors.subErrors[i].field].setErrors({ 'message': errors.subErrors[i].message });
                    }
                }
            }

        );
    }

    taskOption(event)
    {
        if(this.f.createTask.value)
        {
            this.f.subject.clearValidators();
            this.f.subject.setErrors(null);
            this.f.notes.setValidators([Validators.required]);
        }
        else
        {
            this.f.notes.clearValidators();
            this.f.notes.setErrors(null);
            this.f.subject.setValidators([Validators.required]);
        }
        this.f.notes.updateValueAndValidity();
        this.f.subject.updateValueAndValidity();
    }
}
