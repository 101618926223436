<p-dialog [(visible)]="show" (onHide)="hide()" [style]="{width: '65vw'}" [header]="title" [modal]="true"
    (onHide)="hide()">
    <ng-template pTemplate="content">
        <form [formGroup]="form" #taskform>
            <div class="p-fluid grid">
                <div class="field col-12 md:col-6" style="margin-top: 19px;">
                    <span class="p-float-label">
                        <input [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.subject.errors }"
                            formControlName="subject" type="text" maxlength="95" pInputText>
                        <label for="subject">Next Step <span class="mandate">*</span></label>
                    </span>
                    <span class="p-error" *ngIf="submitted && f.subject.invalid && f.subject.hasError('required')">Next
                        Step is required.</span>
                        <span class="p-error" *ngIf="submitted && f.subject.invalid && f.subject.hasError('whitespace')">Next Step cannot be just spaces.</span>
                    <span class="p-error"
                        *ngIf="submitted && f.subject.invalid && f.subject.hasError('message')">{{f.subject.errors.message}}</span>
                </div>
                <div class="field col-12 md:col-6" style="margin-top: 19px;">
                    <span class="p-float-label">
                        <p-dropdown [options]="tbpEmployees"
                            [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.assignedTo.errors }" [appendTo]="body"
                            styleClass="form-control" formControlName="assignedTo" optionLabel="displayName"
                            optionValue="uuid" [filter]="true" [autoDisplayFirst]="false">
                        </p-dropdown>
                        <label for="assignedTo">Assigned To<span class="mandate">*</span></label>
                    </span>
                    <span class="p-error"
                        *ngIf="submitted && f.assignedTo.invalid && f.assignedTo.hasError('required')">Assigned To is
                        required.</span>
                    <span class="p-error"
                        *ngIf="submitted && f.assignedTo.invalid && f.assignedTo.hasError('message')">{{f.assignedTo.errors.message}}</span>
                </div>
                <div class="field col-12 md:col-6">
                    <span class="p-float-label">
                        <p-calendar [showIcon]="false" dateFormat="mm-dd-yy" formControlName="dueDate" inputId="icon"
                            appendTo="body" [readonlyInput]="true"></p-calendar>
                        <label for="dueDate">Due Date</label>
                    </span>
                    <span class="p-error"
                        *ngIf="submitted && f.dueDate.invalid && f.dueDate.hasError('message')">{{f.dueDate.errors.message}}</span>
                </div>
                <div class="field col-12 md:col-6">
                    <span class="p-float-label">
                        <p-dropdown [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.priority.errors }"
                            [options]="priority" styleClass="form-control" formControlName="priority"
                            [autoDisplayFirst]="false" optionLabel="name" optionValue="code">
                        </p-dropdown> <label for="priority">Priority<span class="mandate">*</span> </label>
                    </span>
                    <span class="p-error"
                        *ngIf="submitted && f.priority.invalid && f.priority.hasError('required')">Priority is
                        required.</span>
                </div>
                <div class="field col-12 md:col-6">
                    <span class="p-float-label">
                        <p-dropdown [options]="status" styleClass="form-control" formControlName="status"
                            [autoDisplayFirst]="false" optionLabel="name" optionValue="code">
                        </p-dropdown> <label for="status">Status <span class="mandate">*</span> </label>
                    </span>
                    <span class="p-error"
                        *ngIf="submitted && f.status.invalid && f.status.hasError('message')">{{f.status.errors.message}}</span>
                </div>
                <div class="field col-12 md:col-6">
                    <span class="p-float-label">
                        <p-dropdown [options]="entityTypes" [appendTo]="body" styleClass="form-control"
                            [autoDisplayFirst]="false" formControlName="relatedType" optionLabel="value"
                           ></p-dropdown>
                        <label for="relatedType">Type </label>
                    </span>
                    <span class="p-error"
                        *ngIf="submitted && f.relatedType.invalid && f.relatedType.hasError('message')">{{f.relatedType.errors.message}}</span>
                </div>

                <div class="field col-12 md:col-6"
                    *ngIf="f.relatedType.value != null && f.relatedType.value.value === 'Spotlight'">
                    <span class="p-float-label">
                        <p-autoComplete [unique]="true" dataKey="uuid" [forceSelection]="true"
                            formControlName="spotlight" [suggestions]="spotlightResults"
                            (completeMethod)="searchSpotlights($event)" field="title"> </p-autoComplete> <label
                            for="spotlight">Spotlight </label>
                    </span>
                </div>

                <div class="field col-12 md:col-6"
                    *ngIf="f.relatedType.value != null && f.relatedType.value.value === 'Engagement Outreach'">
                    <span class="p-float-label">
                        <p-autoComplete [unique]="true" dataKey="uuid" [forceSelection]="true"
                            formControlName="engagement" [suggestions]="engagementResults"
                            (completeMethod)="searchEngagements($event)" field="name"> </p-autoComplete> <label
                            for="engagement">Engagement </label>
                    </span>
                </div>
                <div class="field col-12 md:col-6"
                    *ngIf="f.relatedType.value != null && f.relatedType.value.value === 'BuySide Screen Outreach'">
                    <span class="p-float-label">
                        <p-autoComplete [unique]="true" dataKey="uuid" [forceSelection]="true"
                            formControlName="buySideScreen" [suggestions]="buySideResults"
                            (completeMethod)="searchBuySide($event)" field="screenTitle"> </p-autoComplete> <label
                            for="buySideScreen">BuySide Screen Outreach </label>
                    </span>
                </div>
                <div class="field col-12 md:col-6"
                    *ngIf="f.relatedType.value != null && f.relatedType.value.value === 'SellSide Screen Outreach'">
                    <span class="p-float-label">
                        <p-autoComplete appendTo="body" [unique]="true" dataKey="uuid" [forceSelection]="true" 
                            formControlName="sellSideScreen" [suggestions]="sellSideResults"
                            (completeMethod)="searchSellSide($event)" field="screenTitle"> </p-autoComplete> <label
                            for="sellSideScreen">SellSide Screen Outreach </label>
                    </span>
                </div>
                <div class="field col-12 md:col-6">
                    <span class="p-float-label">
                        <tbp-autocomplete [forceSelection]="true" field="displayName" 
                            [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.contacts.errors }" [additionalParam]="f.organization.value?.uuid"
                            [multiple]="true" [formControl]="f.contacts" [items]="" [type]="'Contact'" [additionalData]="f.organization.value">
                        </tbp-autocomplete>
                        <label for="user">Contact</label>
                    </span>
                    <span class="p-error"
                        *ngIf="submitted && f.contacts.invalid && f.contacts.hasError('message')">{{f.contacts.errors.message}}</span>
                </div>
                <div class="field col-12 md:col-6">
                    <span class="p-float-label">
                        <p-autoComplete appendTo="body" [unique]="true" dataKey="uuid" [forceSelection]="true"
                            formControlName="organization" [suggestions]="organizationResults"
                            (completeMethod)="searchOrganizations($event)" field="name"> </p-autoComplete> <label
                            for="organization">Account </label>
                    </span>
                </div>
                <ng-container *ngIf="f.status.value === 'COMPLETED' ">
                    <div class="field col-12 md:col-6">
                        <span class="p-float-label">
                            <p-calendar [showIcon]="false" dateFormat="mm-dd-yy" formControlName="completionDate"
                                inputId="icon" appendTo="body" [readonlyInput]="true"></p-calendar>
                            <label>Completion Date  <span class="mandate">*</span></label>
                        </span>
                        <span class="p-error"
                            *ngIf="submitted && f.completionDate.invalid && f.completionDate.hasError('message')">{{f.completionDate.errors.message}}</span>
                    </div>
                </ng-container>
                <div class="field col-12">
                    <textarea [rows]="5" [cols]="30" formControlName="description" placeholder="Comment" pInputTextarea
                        autoResize="autoResize"></textarea>
                    <span class="p-error"
                        *ngIf="submitted && f.description.invalid && f.description.hasError('message')">{{f.description.errors.message}}</span>
                </div>
                <ng-container *ngIf="f.status.value === 'COMPLETED' ">
                    <div class="field col-12">
                        <textarea [rows]="5" [cols]="30" formControlName="completionComment"
                            placeholder="Completion Comment" pInputTextarea autoResize="autoResize"></textarea>
                        <span class="p-error"
                            *ngIf="submitted && f.description.invalid && f.description.hasError('message')">{{f.description.errors.message}}</span>
                    </div>
                </ng-container>
                <ng-container *ngIf="f.status.value !== 'COMPLETED'">
                    <div class="field col-12 md:col-6">
                      <span class="p-float-label">
                        <p-calendar appendTo="body" [showIcon]="false" showTime="true" hourFormat="24"  [minDate]="minDate"
                          dateFormat="mm-dd-yy" formControlName="reminderDate" [readonlyInput]="true"></p-calendar>
                        <label for="reminderDate">Reminder Date</label>
                      </span>
                      <span class="p-error" *ngIf="submitted &&  f.reminderDate.invalid && f.reminderDate.hasError('message')">{{f.reminderDate.errors.message}}</span>
                    </div>
                  </ng-container>            
                <ng-container *ngIf="f.dueDate && f.dueDate.value">
                <div class="field col-12 md:col-4">
                    <p-checkbox formControlName="reminderChecked" binary="reminderChecked" id="reminderChecked"
                        class="ml-2" pTooltip="{{('Reminder Checked ')}}" tooltipPosition="top"
                        label="Send Email to Assignee"></p-checkbox>
                </div>
                </ng-container>
            </div>
        </form>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hide()"></button>
        <button pButton pRipple label="Save" [loading]="loading" form="clform" icon="pi pi-check" class="p-button-text"
            (click)="save()"></button>
    </ng-template>
</p-dialog>
<p-dialog [(visible)]="showQuickEdit" [style]="{width: '65vw'}"  [header]="title" [modal]="true"
    (onHide)="hide()">
    <ng-template pTemplate="content" >
    <div class="p-fluid formgrid grid"  style="margin-top: 19px;">
        <div class="field col-12 md:col-6" (click)="editField('subject')">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right">Next Step: </div>
                <div *ngIf="!editingField.subject" class="col-12 md:col-8">
                    {{task?.subject}}
                </div>
                <div *ngIf="editingField.subject" class="col-12 md:col-8">
                    <input [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.subject.errors }" pInputText type="text" pInputText autofocus [(ngModel)]="task.subject"
                     (focusout)="updateField('subject', task?.subject)" [required]="true">
                  </div>
            </div>
        </div>

        <div class="field col-12 md:col-6" (click)="editField('assignedTo')">
            <div class="grid mt-1  dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right">Assigned to: </div>
                <div *ngIf="!editingField.assignedTo" class="col-12 md:col-8">
                    {{task?.assignedTo?.displayName}}
                </div>
                <div *ngIf="editingField.assignedTo" class="col-12 md:col-8">
                        <p-dropdown [options]="tbpEmployees"
                        tooltipPosition="top" optionLabel="displayName" [autoDisplayFirst]="false" dataKey="uuid"
                        [(ngModel)]="task.assignedTo"  (onChange)="updateField('assignedTo', task?.assignedTo)" [filter]="true">  
                </p-dropdown>
                  </div>
            </div>
        </div>
        <div class="field col-12 md:col-6" (click)="editField('dueDate')">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right">Due Date: </div>
                <div *ngIf="!editingField.dueDate" class="col-12 md:col-8">
                    {{task?.dueDate | date:'MM-dd-yyyy'}}
                </div>
                <div *ngIf="editingField.dueDate" class="col-12 md:col-8">
                    <p-calendar [showIcon]="false" dateFormat="mm-dd-yy" [(ngModel)]="task.dueDate"  inputId="icon" 
                    appendTo="body" (onClose)="updateField('dueDate', task.dueDate)"></p-calendar>
                  </div>
            </div>
        </div>

        <div class="field col-12 md:col-6" (click)="editField('priority')">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right">Priority: </div>
                <div *ngIf="!editingField.priority" class="col-12 md:col-8">
                    {{task?.priority == null ? "" : task?.priority.toString() | titlecase}}
                </div>
                <div *ngIf="editingField.priority" class="col-12 md:col-8">
                <p-dropdown [options]="priority" styleClass="form-control" [(ngModel)]="task.priority" optionLabel="name" optionValue="code"[required]="true"
                (onChange)="updateField('priority', task?.priority)" >  </p-dropdown>  
                </div>
            </div>
        </div>
        <div class="field col-12 md:col-6" (click)="updateCombineFields(task,'Status')">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right">Status: </div>
                <div *ngIf="!editingField.status" class="col-12 md:col-8">
                    {{task?.status == null ? "" : task?.status.toString().replace('_',' ') | titlecase}}
                </div>
            </div>
        </div>

        <div class="field col-12 md:col-6"  (click)="updateCombineFields(task,'Type')">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right">Type: </div>
                <div *ngIf="!editingField.relatedType" class="col-12 md:col-8">
                    {{ getRelatedType(task?.relatedType)}}
                </div>
            </div>
        </div>
        <ng-container *ngIf="task?.relatedType =='Spotlight'">
        <div class="field col-12 md:col-6"  (click)="updateCombineFields(task,'Type')">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right">Spotlight: </div>
                <div  *ngIf="!editingField.spotlight" class="col-12 md:col-8">
                    {{task?.spotlight?.title}}
                </div>
            </div>
        </div>
        </ng-container>
    <ng-container *ngIf="task?.relatedType =='BuySide Screen Outreach'">
        <div class="field col-12 md:col-6"  (click)="updateCombineFields(task,'Type')">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right">BuySide Screen Outreach: </div>
                <div  *ngIf="!editingField.buySideScreen" class="col-12 md:col-8">
                    {{task?.buySideScreen?.screenTitle}}
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="task?.relatedType =='SellSide Screen Outreach'">
         <div class="field col-12 md:col-6"  (click)="updateCombineFields(task,'Type')">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right">SellSide Screen Outreach: </div>
                <div  *ngIf="!editingField.sellSideScreen" class="col-12 md:col-8">
                    {{task?.sellSideScreen?.screenTitle}}
                </div>
            </div>
        </div> 
    </ng-container>
    <ng-container *ngIf="task?.relatedType =='Engagement Outreach'">
            <div class="field col-12 md:col-6"  (click)="updateCombineFields(task,'Type')"
                *ngIf="f.relatedType.value != null ">
                <div class="grid mt-1 limit-width-large dashed-bottom">
                    <div class="col-12 md:col-4 label md:text-right">Engagement: </div>
                    <div  *ngIf="!editingField.engagement" class="col-12 md:col-8">
                        {{task?.engagement?.name}}
                    </div>
                </div>
            </div>
    </ng-container>
        <div class="field col-12 md:col-6" (click)="editField('contact')">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right">Contact: </div>
                <div  *ngIf="!editingField.contact" class="col-12 md:col-8">
                    <span *ngFor="let contact of task?.contacts; let last = last;">
                        <a [routerLink]="['/contact/', contact.uuid]">{{contact.name}}</a>{{last?'':'; '}}
                    </span>
                </div>
                <div *ngIf="editingField.contact" class="col-12 md:col-8">
                    <p-autoComplete appendTo="body" [forceSelection]="true" multiple="true"  [items]="" 
                    [suggestions]="contactResults"  [(ngModel)]="task.contacts" [unique]="true" (onUnselect)="updateField('contacts',task?.contacts)"
                    (completeMethod)="searchContacts($event)" field="name" (onSelect)="updateField('contacts', task.contacts)"
                     > </p-autoComplete>
                </div>
            </div>
        </div>
        <div class="field col-12 md:col-6" (click)="editField('organization')">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right">Account: </div>
                <div *ngIf="!editingField.organization" class="col-12 md:col-8">
                    {{task?.organization?.name}}
                </div>
                <div *ngIf="editingField.organization" class="col-12 md:col-8">
                    <p-autoComplete appendTo="body" [unique]="true" dataKey="uuid" [forceSelection]="true"  [required]="true"
                            [suggestions]="organizationResults" [(ngModel)]="task.organization" (onSelect)="updateField('organization', task.organization)"
                            (completeMethod)="searchOrganizations($event)" field="name"> </p-autoComplete> 
                </div>
            </div>
        </div>

        <ng-container *ngIf="task?.status !='COMPLETED'">
        <div class="field col-12 md:col-6" (click)="editField('reminderDate')">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div  class="col-12 md:col-4 label md:text-right">Reminder Date: </div>
                <div  *ngIf="!editingField.reminderDate" class="col-12 md:col-8">
                    {{ task?.reminderDate | date:'MM-dd-yyyy HH:mm'}}
                </div>
                <div *ngIf="editingField.reminderDate" class="col-12 md:col-8">
                    <p-calendar appendTo="body" [showIcon]="false" dateFormat="mm-dd-yy" inputId="icon" showTime="true"  hourFormat="24"  [minDate]="minDate"
                     [(ngModel)]="task.reminderDate" (onClose)="updateField('reminderDate', task.reminderDate)" ></p-calendar>
 
                </div>
            </div>
        </div>    
        </ng-container>
      <ng-container *ngIf="task?.status =='COMPLETED'">
        <div class="field col-12 md:col-6" (click)="updateCombineFields(task,'Status')">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div  class="col-12 md:col-4 label md:text-right">Completion Date: </div>
                <div  *ngIf="!editingField.completionDate" class="col-12 md:col-8">
                    {{ task?.completionDate | date:'MM-dd-yyyy'}}
                </div> 
          </div>
        </div> 
        <div class="field col-12 md:col-6" (click)="updateCombineFields(task,'Status')">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div  class="col-12 md:col-4 label md:text-right">Completion Comment: </div>
                <div  *ngIf="!editingField.completionComment" class="col-12 md:col-8"  [innerHTML]="task?.completionComment">
                </div>
            </div>
        </div>
      </ng-container>
        <div class="field col-12 md:col-6" (click)="editField('description')">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div  class="col-12 md:col-4 label md:text-right">Comment: </div>
                <div  *ngIf="!editingField.description" class="col-12 md:col-8" [innerHTML]="task?.description">
                </div>
                <div *ngIf="editingField.description" class="col-12 md:col-6">
                     <p-dialog [visible]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}"
                     [closable]="false" [modal]="true">
                     <ng-template pTemplate="header">
                       <div class="p-fluid formgrid grid">
                         <div class="field col-12">
                           <strong>{{ task.subject }}:</strong>
                           {{ "Description" }}
                         </div>
                       </div>
                     </ng-template>
                     <ng-template pTemplate="content">
                       <editor name="description" ngDefaultControl [(ngModel)]="task.description" [init]="appUtil.getInitData(180,null)"></editor>
                       &nbsp;
                     </ng-template>
                     <ng-template pTemplate="footer">
                       <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
                         (click)="updateField('description', task.description)"></button>
                       <button pButton pRipple label="Close" class="p-button-text" (click)="closeDescriptionDialog('description' ,$event)"></button>
                     </ng-template>
                   </p-dialog>
                </div>
            </div>
        </div>
        <ng-container *ngIf="task?.status !='COMPLETED'">
        <div class="field col-12 md:col-6" (click)="editField('reminderChecked')">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right">Send Email to Assignee: </div>
                <div class="col-12 md:col-8" *ngIf="!editingField.reminderChecked">{{task?.reminderChecked==true?"Yes":"No"}}</div>
                <div class="col-12 md:col-6" *ngIf="editingField.reminderChecked">  
                <p-radioButton [value]="true" name="reminderChecked" ngDefaultControl 
                [(ngModel)]="task.reminderChecked" label="Y"  [(ngModel)]="task.reminderChecked" (ngModelChange)="updateField('reminderChecked', task.reminderChecked)"></p-radioButton>
                <p-radioButton [value]="false" name="reminderChecked" ngDefaultControl
                [(ngModel)]="task.reminderChecked" label="N" [(ngModel)]="task.reminderChecked" (ngModelChange)="updateField('reminderChecked', task.reminderChecked)" class="ml-2"></p-radioButton>
                </div>
            </div>
        </div>  
    </ng-container>
  </div>
</ng-template>
</p-dialog>

<p-dialog [(visible)]="showRelatedTypeDialog" styleClass="p-fluid" [style]="{width: '500px', height: '500px'}" [modal]="true">
    <ng-template pTemplate="header">
        Update {{ field || '' }}
    </ng-template>

    <ng-template pTemplate="input">
        <!-- Conditional section for relatedType -->
        <div *ngIf="field === 'Type'">
            <p-dropdown [options]="entityTypes" [appendTo]="body" styleClass="Genntrol" [(ngModel)]="f.relatedType.value" optionLabel="value"></p-dropdown>

            <div *ngIf="f.relatedType.value && relatedTypeLabels[f.relatedType.value.value]">
                <div style="margin: 30px 0 0 5px">{{ relatedTypeLabels[f.relatedType.value.value].label }}:</div>
                <div *ngIf="f.relatedType.value.value === 'Spotlight'">
                    <p-autoComplete [unique]="true" dataKey="uuid" [forceSelection]="true" [suggestions]="spotlightResults" (completeMethod)="searchSpotlights($event)" field="title" [(ngModel)]="task.spotlight"></p-autoComplete>
                </div>
                <div *ngIf="f.relatedType.value.value === 'BuySide Screen Outreach'">
                    <p-autoComplete [unique]="true" dataKey="uuid" [forceSelection]="true" [suggestions]="buySideResults" (completeMethod)="searchBuySide($event)" field="screenTitle" [(ngModel)]="task.buySideScreen"></p-autoComplete>
                </div>
                <div *ngIf="f.relatedType.value.value === 'SellSide Screen Outreach'">
                    <p-autoComplete [unique]="true" dataKey="uuid" [forceSelection]="true" [suggestions]="sellSideResults" (completeMethod)="searchSellSide($event)" field="screenTitle" [(ngModel)]="task.sellSideScreen"></p-autoComplete>
                </div>
                <div *ngIf="f.relatedType.value.value === 'Engagement Outreach'">
                    <p-autoComplete [unique]="true" dataKey="uuid" [forceSelection]="true" [suggestions]="engagementResults" (completeMethod)="searchEngagements($event)" field="name" [(ngModel)]="task.engagement"></p-autoComplete>
                </div>
            </div>
        </div>

        <!-- Conditional section for status -->
        <div *ngIf="field === 'Status'">
            <p-dropdown [options]="status" styleClass="form-control"  [(ngModel)]="task.status" [required]="true"
            optionLabel="name" optionValue="code"> </p-dropdown>
            <div  *ngIf="task.status==='COMPLETED'">
            <div style="margin: 25px 0 0 5px">
                <label for="completedDate">Completion Date</label>
                    <p-calendar [showIcon]="false" dateFormat="mm-dd-yy" inputId="icon" appendTo="body"  [minDate]="minDate"
                     [(ngModel)]="task.completionDate"></p-calendar>
            </div>
            <div style="margin: 25px 0 0 5px">
                <label for="completionComment">Completion comment</label>
                <editor #editor  [(ngModel)]="task.completionComment" [init]="appUtil.getInitData(180,null)"></editor>
            </div>
        </div>
        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <ng-container>
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="submitTask(task ,field ,f.relatedType.value.value)"></button>
        </ng-container>
    </ng-template>
</p-dialog>


<p-toast></p-toast>