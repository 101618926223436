import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountPopupAddComponent } from './account-popup.add.component';
import { MenuModule } from 'primeng/menu';
import { TableModule } from 'primeng/table';
import { PanelModule } from 'primeng/panel';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
@NgModule({
    declarations: [AccountPopupAddComponent],
    imports: [
        CommonModule,
        DialogModule,
        FormsModule,
        InputTextModule,
        ButtonModule,
        ReactiveFormsModule,
        ToastModule,
        PanelModule,
        TableModule,
        MenuModule,
        DropdownModule
    ],
    exports: [AccountPopupAddComponent]
})
export class AccountPopupModule { }
