import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ClassificationService } from "../classification/classification.service";
import { ConfigurationService } from "../configuration/configuration.service";
import { first } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
@Component({
    selector: 'add-global-lookups',
    templateUrl: './configuration.add.component.html',
    providers: [MessageService]
})
export class ConfigurationAddComponent implements OnInit {
    @Output("parentFuntion") parentFuntion: EventEmitter<any> = new EventEmitter();
    @Input() uuid: string;
    @Output() showChange = new EventEmitter<boolean>();
    @Input() show: boolean = false;
    @Input() type: string;
    @Input() inputTextvalue: string;
    @Input() entityType: string ;
    @Input() entityId: string;
    lookSpinner: boolean = false;
    lookSubmitted: boolean = false;
    keyList = [];
    formclass: UntypedFormGroup;
    selectedKey: any;
    formInitialValue: any = null;
    rangeError:boolean = false;
    constructor(private messageService: MessageService, private service: ConfigurationService, private formBuilder: UntypedFormBuilder, private classificationService: ClassificationService) {
        this.formclass = this.formBuilder.group({
            value: [''],
            min: [null,],
            max: [null,],
            uuid: [],
            keyId: [],
            name: [],
            iso3: ['']
        }, {
            validator: (formControl) => {
              let min:UntypedFormControl = formControl.controls.min;
              var max:UntypedFormControl = formControl.controls.max;
              if(min.enabled && max.enabled)
              {// its a range type so validdate
                min.setErrors(null);
                if(!min.value && !max.value)
                {
                    min.setErrors({ 'message': 'Value for one of Min or Max is required' });
                }
                else
                {
                    let patternErrors = false;
                    if(min.value && !/^\d+$/.test(min.value))
                    {
                        min.setErrors({ 'message': 'Only numbers are allowed' });
                        patternErrors = true;
                    }

                    if(max.value && !/^\d+$/.test(max.value))
                    {
                        max.setErrors({ 'message': 'Only numbers are allowed' });
                        patternErrors  =true;
                    }

                    if(!patternErrors && min.value != null && max.value != null && parseInt(min.value,10) >= parseInt(max.value,10))
                    {
                        min.setErrors({ 'message': 'Min value must be less than max value' });
                    }
                } 
              }
            }
      });
        this.formInitialValue = this.formclass.value;
    }

    ngOnInit(): void {
        this.service.getLookupKeys().subscribe(
            data => {
                this.keyList = (data as any);
                this.keyList.unshift({id:'COUNTRY', name:'Country'});
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        this.rangeError = false;
        if (this.type != undefined && this.type != '' && this.show) {
            this.selectedKey = this.keyList.find(field => field.id === this.type);
        }
        if (this.selectedKey != undefined)
        {
            this.prepareForm();
        }
            

        if (this.selectedKey != undefined && this.uuid != undefined && this.selectedKey.id != 'COUNTRY' && this.show) {
            this.service.getLookupValue(this.uuid).subscribe(data => {
                let objCls = data as any;
                this.formclass.setValue({ value: objCls.value, min: objCls.min, max: objCls.max, uuid: objCls.uuid, keyId: objCls.keyId, name: null, iso3: null });
            });
        }
        else if (this.selectedKey != undefined && this.uuid != undefined && this.selectedKey.id == 'COUNTRY' && this.show) {
            this.service.getCountry(this.uuid).subscribe(data => {
                let objCls = data as any;
                this.formclass.setValue({ value: '', min: '', max: '', uuid: objCls.uuid, keyId: this.selectedKey.id, name: objCls.name, iso3: objCls.iso3 });
            });
        }
    }

    isNumber(value: string | number): boolean {
        return ((value != null) &&
            (value !== '') &&
            !isNaN(Number(value.toString())));
    }

    prepareForm() {
        this.formclass.reset(this.formInitialValue);
        if (this.selectedKey.type == 'RANGE') {
            this.f.value.disable();
            this.f.name.disable();
            this.f.iso3.disable();
            this.f.min.enable();
            this.f.max.enable();
            this.formclass.controls["min"].setValidators([Validators.pattern("^[0-9]*$")]);
            this.formclass.controls["max"].setValidators([Validators.pattern("^[0-9]*$")]);
            if (this.inputTextvalue != undefined)
            {
                let textval = this.inputTextvalue;
                if(textval.startsWith("<"))
                {
                    let ar = textval.split("<");
                    if(ar.length > 1 && ar[1] && this.isNumber(ar[1].trim()))
                    {
                        this.f.min.setValue(null);
                        this.f.max.setValue(ar[1].trim());
                    }
                }
                else  if(textval.startsWith(">"))
                {
                    let ar = textval.split(">");
                    if(ar.length > 1 && ar[1] && this.isNumber(ar[1].trim()))
                    {
                        this.f.min.setValue(ar[1].trim());
                        this.f.max.setValue(null);
                    }
                }
                else
                {
                    let ar = textval.split("-");
                    if(ar[0] && this.isNumber(ar[0].trim()))
                    {
                        this.f.min.setValue(ar[0].trim())
                    }

                    if(ar.length > 1 && this.isNumber(ar[1].trim()))
                    {
                        this.f.max.setValue(ar[1].trim())
                    }
                }
            }
        }
        else if (this.selectedKey.id == 'COUNTRY') {
            this.f.value.disable();
            this.f.min.disable();
            this.f.max.disable();
            this.f.iso3.enable();
            this.f.name.enable();
            this.formclass.controls["name"].setValidators([Validators.required]);
            if (this.inputTextvalue != undefined)
                this.f.name.setValue(this.inputTextvalue);
        }
        else {
            this.f.name.disable();
            this.f.iso3.disable();
            this.f.value.enable();
            this.f.min.disable();
            this.f.max.disable();
            this.formclass.controls["value"].setValidators([Validators.required]);
            if (this.inputTextvalue != undefined)
                this.f.value.setValue(this.inputTextvalue);
        }
        this.f.keyId.setValue(this.selectedKey.id);
    }

    resetRangeError()
    {
        this.rangeError = false;
    }
    onSubmit() {
        /*
        if (this.selectedKey.type == 'RANGE') {
            if (this.f.min.value != null && this.f.max.value != null && this.f.min.value == this.f.max.value) {
                this.f.min.setErrors({ 'message': 'Min value must be less than max value' });
            }
            else
                if (this.f.min.value != null && this.f.max.value != null && this.f.min.value > this.f.max.value) {
                    this.f.min.setErrors({ 'message': 'Min value must be less than max value.' });
                }
                else {
                    this.f.min.setErrors(null);
                }
        }
        */
        this.lookSubmitted = true;
        this.rangeError = false;
        if (this.formclass.invalid) {
            return;
        }
        this.lookSpinner = true;
        let apiCall: any;
        if (this.f.uuid.value == null || this.f.uuid.value == '') {
            if (this.selectedKey.id == 'COUNTRY')
                apiCall = this.service.createCountry(this.formclass.value );
            else            
                apiCall = this.service.createLookupValue(this.formclass.value ,this.entityType,this.entityId);
        }
        else {
            if (this.selectedKey.id == 'COUNTRY')
                apiCall = this.service.updateCountry(this.formclass.value);
            else
                apiCall = this.service.updateLookupValue(this.formclass.value);
        }

        apiCall.pipe(first())
            .subscribe(
                data => {
                    let detail = 'Lookup Value Created';
                    if (this.formclass.controls['uuid'].value != null && this.formclass.controls['uuid'].value != '')
                        detail = 'Lookup Value Updated';
                    this.parentFuntion.emit(data);
                    this.hideDialog();
                    this.messageService.add({ severity: 'success', summary: 'Successful', detail: detail, life: 3000 });
                },
                error => {
                    this.lookSpinner = false;
                    let errors = error.error;
                    if (errors.code && errors.code == 'VALIDATION_ERROR') {
                        for (var i = 0; i < errors.subErrors.length; i++) {
                            if(this.selectedKey.type == 'RANGE')
                            {
                                this.rangeError = true;
                            }
                            else
                            {
                                this.f[errors.subErrors[i].field].setErrors({ 'message': errors.subErrors[i].message });
                            }
                           
                        }
                    }
                }
            );
    }

    get f() { return this.formclass.controls; }

    hideDialog() {
        this.show = false;
        this.lookSubmitted = false;
        this.lookSpinner = false;
        this.formclass.reset(this.formInitialValue);
        this.showChange.emit();
    }

}