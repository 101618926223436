<p-dialog [(visible)]="show" [style]="{width: '50vw'}" header="{{title}}" (onHide)="hideUploadpop()" [modal]="true">
    <ng-template pTemplate="content">
        <div class="grid">
            <div class="col-12 md:col-12">
                <div class="p-fluid formgrid grid">
                    <div class="field p-col col-12" *ngIf="showUpdateOption == true">
                        <label for="firstname2">
                            <p-checkbox (onChange)="checkBoxChange($event)" styleClass="form-control" [value]="true"
                                labelStyleClass="pr-2"></p-checkbox>&nbsp;&nbsp;&nbsp;<b>Update Existing</b>
                        </label>
                    </div>
                    <div class="field p-col col-12">
                        <p-fileUpload #form name="file" chooseLabel="File" customUpload="true" accept=".xlsx"
                        [maxFileSize]=20000000 (uploadHandler)="onUpload($event, form)"
                            (onSelect)="selectFile($event,form)"></p-fileUpload>
                    </div>
                    <p-progressSpinner *ngIf="loadingupload" [style]="{width: '50px', height: '50px'}"
                        styleClass="custom-spinner" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s">
                    </p-progressSpinner>
                </div>
            </div>
        </div>
        <div class="grid" *ngIf="showCount">
            <div class="col-12" style="text-align: center;">
                <h4>Upload Successful: {{fileName}}</h4>
                <h5 class="col-12" *ngIf="url.includes('prospects') && url.includes('accounts')">Accounts Detail</h5>
                <h5 class="col-12" style="background-color: #e0e5e8;" *ngIf="(url.includes('prospects') && url.includes('contacts')) || (url.includes('recipients'))">Contacts Detail</h5>
            </div>
            <div class="p-fluid formgrid grid col-12">
                <div class="col-4" style="text-align: center;"><b>Rows Created</b></div>
                <div class="col-4" style="text-align: center;"><b>Rows Updated</b></div>
                <div class="col-4" style="text-align: center;"><b>Rows Skipped</b></div><br>
                <div class="col-4" style="text-align: center;"><a href="javascript: void(0);"
                        (click)="successDataUpload(1)">{{createdCount}}</a></div>
                <div class="col-4" style="text-align: center;"><a href="javascript: void(0);"
                        (click)="successDataUpload(2)">{{updatedCount}}</a></div>
                <div class="col-4" style="text-align: center;"><a href="javascript: void(0);"
                        (click)="successDataUpload(3)">{{skippedCount}}</a></div>
            </div>
        </div>
        <div class="grid" *ngIf="showResult">
            <div class="col-12">
                <h5 style="background-color: #e0e5e8;">{{successTitle}}</h5>
                <p-table [value]="successDatas" [autoLayout]="true" [paginator]="true" [rows]="10"
                    styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Row Numbers</th>
                            <th>Status</th>
                            <th>Remarks</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-successData>
                        <tr>
                            <td class="wrap">{{successData.rowNumber}}</td>
                            <td class="wrap">{{successData.status}}</td>
                            <td class="wrap">{{successData.skipReason}}</td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="3">No record found</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
        <div class="grid" *ngIf="showError">
            <div class="col-12" style="text-align: center;">
                <h4>Uploaded data has following errors: {{fileName}}</h4>
            </div>
            <div class="col-12">
                <p-table [value]="errors" [paginator]="true" [autoLayout]="true" [rows]="10"
                    styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Row Numbers</th>
                            <th>Status</th>
                            <th>Missing Value</th>
                            <th>Invalid Value</th>
                            <th>Length Exceeded</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-error>
                        <tr>
                            <td class="wrap">{{error.rowNumber}}</td>
                            <td class="wrap">{{error.status}}</td>
                            <td class="wrap">{{error.missingValues}}</td>
                            <td class="wrap">{{error.invalidValues}}</td>
                            <td class="wrap">
                                <span
                                    *ngFor="let lengths of error.lengthExceeded; let last = last;">{{lengths.fieldName+"
                                    [Max: "+lengths.maxLengthAllowed+" Current: "+lengths.fieldLength+"]"}}{{last?'':';
                                    '}}</span>

                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
        <br>
        <div class="grid" *ngIf="showProspectDetail">
            <h5 class="col-12" style="text-align: center;">Prospects Detail</h5>
            <div class=" p-fluid formgrid grid col-12">
                <div class="col-4" style="text-align: center;"><b>Prospects Created</b></div>
                <div class="col-4" style="text-align: center;"><b>Rows Updated</b></div>
                <div class="col-4" style="text-align: center;"><b>Prospects Skipped</b></div>
                <div class="col-4" style="text-align: center;"><a href="javascript: void(0);"
                    (click)="successProspectUpload(1)">{{createdProspectCount}}</a></div>
               <div class="col-4" style="text-align: center;"><a href="javascript: void(0);"
                    (click)="successProspectUpload(2)">{{updatedProspectCount}}</a></div>
                <div class="col-4" style="text-align: center;"><a href="javascript: void(0);"
                    (click)="successProspectUpload(3)">{{skippedProspectCount}}</a></div>
            </div>
        </div>
        <div class="grid" *ngIf="showProspectResult">
            <div class="col-12">
                <h5>{{prospectSuccessTitle}}</h5>
                <p-table [value]="successProspectArray" [autoLayout]="true" [paginator]="true" [rows]="10"
                    styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Row Numbers</th>
                            <th>Status</th>
                            <th>Remarks</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-successProspect>
                        <tr>
                            <td class="wrap">{{successProspect.rowNumber}}</td>
                            <td class="wrap">{{successProspect.status}}</td>
                            <td class="wrap">{{successProspect.skipReason}}</td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="3">No record found</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
        <br>
        <div class="grid" *ngIf="showRecipientsDetail">
            <h5 class="col-12" style="text-align: center; background-color: #e0e5e8;">Recipients Detail</h5>
            <div class=" p-fluid formgrid grid col-12">
                <div class="col-6" style="text-align: center;"><b>Recipients Created</b></div>
                <div class="col-6" style="text-align: center;"><b>Recipients Skipped</b></div>
                <div class="col-6" style="text-align: center;"><a href="javascript: void(0);"
                    (click)="successRecipientsUpload(1)">{{createdRecipientsCount}}</a></div>
                <div class="col-6" style="text-align: center;"><a href="javascript: void(0);"
                    (click)="successRecipientsUpload(2)">{{skippedRecipientsCount}}</a></div>
            </div>
        </div>
        <div class="grid" *ngIf="showRecipientsResult">
            <div class="col-12">
                <h5 style="background-color: #e0e5e8;">{{recipientsSuccessTitle}}</h5>
                <p-table [value]="successRecipientsArray" [autoLayout]="true" [paginator]="true" [rows]="10"
                    styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Row Numbers</th>
                            <th>Status</th>
                            <th>Remarks</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-successRecipient>
                        <tr>
                            <td class="wrap">{{successRecipient.rowNumber}}</td>
                            <td class="wrap">{{successRecipient.status}}</td>
                            <td class="wrap">{{successRecipient.skipReason}}</td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="3">No record found</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <p-button label="Cancel" icon="pi pi-times" (click)="hideUploadpop()" iconPos="left"
            styleClass="p-button-secondary"></p-button>
    </ng-template>
</p-dialog>
<p-toast></p-toast>