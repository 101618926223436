import { BuySide } from "./buyside";
import { Engagement } from "./engagement";
import { SellSide } from "./sellside";
import { Spotlight } from "./spotlight";
import { User } from "./user";

export class EmailCampaign {
    uuid?: string
    title?: string;
    content?: string;
    type?: string;
    spotlight?: Spotlight;
    relatedTo?: string;
    relatedTag?: string;
    uploadedFiles?: []
    engagement?: Engagement;
    buySide?: BuySide;
    sellSide?: SellSide;
    mailChimpId?: string;
    createByUser?: User;
    createdDate?: User;
    modifiedByUser?: User;
    lastModifiedDate?: User;
}