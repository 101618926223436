<div class="grid table-demo">
    <div class="col-12">
        <div class="card">
            <p-toolbar styleClass="headerSticky">
                <div class="p-toolbar-group-left">
                </div>
                <div class="p-toolbar-group-right">
                    <button pButton pRipple label="Back" icon="pi pi-arrow-left" class="p-button-success mr-2" (click)="appUtil.return()"></button>
                    <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success mr-2"
                        (click)="openNew()"></button>
                    <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
                        fill="#EEEEEE" animationDuration=".5s" *ngIf="loadingSpinner"></p-progressSpinner>
                </div>
            </p-toolbar>&nbsp;
            <tbp-search #sec [fields]="fieldInfo" filterFor="EMAIL_CAMPAIGN" searchTermPlaceHolder="Search Email Campaigns"
                [searchURL]="searchUrl" (onSearch)="_search($event)" (onShowHideField)="_updateSelectedColumns($event)">
            </tbp-search>
            <p-table [autoLayout]="true" #dt [value]="emailCampaigns" [(selection)]="selectedEmailCampaigns" [columns]="selectedColumns"
                (onLazyLoad)="sec.loadData($event.first,$event.rows,$event.sortField,$event.sortOrder)" [lazy]="true" dataKey="uuid" 
                styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm" [rowHover]="true"  [lazyLoadOnInit]="false"
                [rows]="sec.pageSize" [paginator]="true" [rowsPerPageOptions]="[15,25,50,75,100]" [loading]="loading"
                [alwaysShowPaginator]="true" [showCurrentPageReport]="true" [first]="sec.firstRow" [sortField]="sec.sortField" [sortOrder]="sec.sortOrder"
                [showPageLinks]="appUtil.getPagination(totalRecords)" [showFirstLastIcon] = "appUtil.getPagination(totalRecords)"
                currentPageReportTemplate="Showing {first} to {last} of {{appUtil.getPagination(totalRecords) ? totalRecords :'many'}} entries" [totalRecords]="totalRecords">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <!-- <th style="width: 3rem" *ngIf="showCheckbox">
							<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
						</th> -->
                        <ng-container *ngFor="let column of columns;">
                            <th pSortableColumn="{{column.property}}" [pSortableColumnDisabled] ="!column.sortable" [ngStyle]="{'width': column.columnWidth}">{{column.label}}
                                <p-sortIcon field="{{column.property}}" *ngIf="column.sortable"></p-sortIcon>
                            </th>                            
                        </ng-container>
                        <th *ngIf="!showCheckbox">Action</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-emailCampaign let-columns="columns">
                    <tr>
                        <td (click)="$event.stopPropagation();"  *ngIf="showCheckbox">
							<p-tableCheckbox [value]="emailCampaign"></p-tableCheckbox>
						</td>
                        <ng-container *ngFor="let column of columns;">
                            <td class="wrap" [attr.width]="column.columnWidth">
                                
                                <ng-container *ngIf="column.property == 'relatedTo'">
                                    <span *ngIf="emailCampaign.buySide != null">
                                        <a [routerLink]="['/buy-side/', emailCampaign.buySide.uuid]">{{emailCampaign.buySide.screenTitle}}</a>
                                    </span>
                                    <span *ngIf="emailCampaign.sellSide != null">
                                        <a [routerLink]="['/sell-side/', emailCampaign.sellSide.uuid]">{{emailCampaign.sellSide.screenTitle}}</a>
                                    </span>
                                    <span *ngIf="emailCampaign.engagement != null">
                                        <a [routerLink]="['/engagement/', emailCampaign.engagement.uuid]">{{emailCampaign.engagement.name}}</a>
                                    </span>
                                    <span *ngIf="emailCampaign.spotlight != null">
                                        <a [routerLink]="['/spotlight/', emailCampaign.spotlight.uuid]">{{emailCampaign.spotlight.title}}</a>
                                    </span>
                                    <span *ngIf="emailCampaign.relatedTo != null || emailCampaign.relatedTo != ''">
                                        {{emailCampaign.relatedTo}}
                                    </span>
                                </ng-container>
                                <ng-container *ngIf="column.property != 'relatedTo' && column.property != 'uploadedFiles' && column.property != 'title'">
                                    <tbp-table-column [object]="emailCampaign" [fieldInfo]="column" (onPatch)="patchDeal($event)"></tbp-table-column>
                                </ng-container>
                                <ng-container *ngIf="column.property == 'title'">
                                    <a [routerLink]="['/email-campaign/', emailCampaign.uuid]" [innerHTML]="emailCampaign.title | highlight:term"></a>
                                </ng-container>
                            </td>
                        </ng-container>
                        <td *ngIf="!showCheckbox">
                            <span class="p-column-title">Action</span>
                            <button type="button" pButton icon="pi pi-list"
                                (click)="setCurrentItem(emailCampaign); menu.toggle($event)"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="20">
                            <span *ngIf="term != ''">Search did not return any result. Try relaxing the criteria or use
                                wildcard [*, ?]</span>
                            <span *ngIf="term == ''">No Email Campaigns found</span>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
<p-menu #menu [popup]="true" [model]="emailCampaignItems"></p-menu>
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
<p-toast></p-toast>