<div class="p-fluid formgrid grid p-mb-5">
    <div class="col-12">
    <h5>Screen Details</h5>    
    <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-6" *ngIf="accountService.role == 1">
                <div class="grid mt-1 limit-width-large dashed-bottom">
                    <div class="col-12 md:col-4 label md:text-right">Account: </div>
                    <div class="col-12 md:col-8 contact-info"  *ngIf="!editingField.organization"><a *ngIf="model!=null && model.organization!=null"
                        [routerLink]="['/organization/', model.organization.uuid]">{{model?.organization?.name}}</a>
                        <button pButton type="button" icon="pi pi-pencil" title="Edit" class="p-button-text edit-button"
                        (click)="editField('organization', model.organization)"></button>
                    </div>
                    <div class="col-12 md:col-6" *ngIf="editingField.organization">
                        <div class="input-container">
                        <p-autoComplete appendTo="body" [unique]="true" [forceSelection]="true" [required]="true"
                                  [suggestions]="organizationResults"  [(ngModel)]="model.organization"
                                  (completeMethod)="searchOrganizations($event)" field="name" > </p-autoComplete>
                                  <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                                  (click)="updateField('organization', model?.organization)"></button>
                                  <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                                  (click)="closeDialog('organization')"></button>
                      </div>
                    </div>
                 </div>
        </div>
        <div class="field col-12 md:col-6">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right">Contacts: </div>
                <div class="col-12 md:col-8 contact-info" *ngIf="!editingField.contacts">                    
                    <span *ngFor="let contact of model.contacts; let last = last;">
                        <a [routerLink]="['/contact/', contact.uuid]">{{contact.name}}</a>{{last?'':'; '}}
                    </span>
                    <button pButton type="button" icon="pi pi-pencil" title="Edit" class="p-button-text edit-button"
                        (click)="editField('contacts', model.contacts)"></button>
                </div>
                <div class="col-12 md:col-6" *ngIf="editingField.contacts">
                    <div class="input-container">
                    <p-autoComplete appendTo="body" [unique]="true" [forceSelection]="true" multiple="true"  [items]=""
                    [suggestions]="contactResults"  [(ngModel)]="model.contacts" [unique]="true"
                    (completeMethod)="searchOrganizations($event)" field="name"> </p-autoComplete>
                     <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                     (click)="updateField('contacts', model?.contacts)"></button>
                     <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                     (click)="closeDialog('contacts')"></button>
         </div>
                   
                </div>
             </div>
        </div>
        <div  (click)="editField('screenTitle', model.screenTitle)" class="field col-12 md:col-6">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right" >Screen Title:</div>
                <div class="col-12 md:col-8" *ngIf="!editingField.screenTitle">
                    {{model?.screenTitle}}
                </div>
                <div  class="col-12 md:col-6"  *ngIf="editingField.screenTitle">
                    <div class="input-container">
                    <input type="text" pInputText  [(ngModel)]="model.screenTitle" [required]="true"/>
                     <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                     (click)="updateField('screenTitle', model.screenTitle)"></button>
                     <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                     (click)="closeDialog('screenTitle')"></button>
                     </div>
                </div>
            </div>
        </div>
        <div class="field col-12 md:col-6" (click)="editField('externalHeadline', model.externalHeadline)" >
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right">External Headline: </div>
                <div class="col-12 md:col-8" *ngIf="!editingField.externalHeadline">{{model?.externalHeadline}}</div>
                <div class="col-12 md:col-6" *ngIf="editingField.externalHeadline">
                    <div class="input-container">
                    <input type="text"  pInputText  [(ngModel)]="model.externalHeadline" 
                     [required]="true"/>
                     <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                     (click)="updateField('externalHeadline', model.externalHeadline)"  ></button>
                     <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                     (click)="closeDialog('externalHeadline')"></button>
                     </div>
                </div>
            </div>
        </div>
        <div class="field col-12 md:col-6" (click)="editField('primaryObjectives',  model.primaryObjectives) ">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right">Primary objective of screen: </div>
                <div class="col-12 md:col-8" *ngIf="!editingField.primaryObjectives" [innerHTML]="model | sellsideobjective">
                </div>
                <div *ngIf="editingField.primaryObjectives">
                    <p-dialog [visible]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
                    [style]="{width: '50vw'}" [closable]="false" [modal]="true">
                    <ng-template pTemplate="header">
                        <div class="p-fluid formgrid grid">
                          <strong>Primary objective of screen:</strong>
                        </div>
                      </ng-template>
                    <ng-template pTemplate="content">
                        <form [formGroup]="form" #cform>
                        <div class=" mb-1">
                            <div class="mt-4 mb-3">
                                <p-checkbox [binary]="true" styleClass="form-control"   formControlName="strategicPartner"
                                     [value]="1" label="Strategic Partner"  [ngClass]="{ 'ng-invalid ng-dirty': formsubmited && f.strategicPartner.errors }"
                                    labelStyleClass="pr-2"  tooltipPosition="top" (onChange)="validatePrimaryObjective($event)"></p-checkbox>
                            </div>
                            <div class=" ml-5 mb-4" >
                            <div class="p-fluid grid">
                                <div class="col-2 mt-4" > Private Equity Partner </div>
                                <div class="field col-10 mt-4 ">
                                <p-multiSelect [options]="privateEquityPartner" [ngClass]="{ 'ng-invalid ng-dirty': formsubmited && f.equityPartnerType.errors }"
                                optionLabel="value" optionValue="value" appendTo="body" formControlName="equityPartnerType"
                               [autoDisplayFirst]="false" placeholder="Select" [showClear]="true" [dataKey]="value" 
                               tooltipPosition="top" (onChange)="validatePrimaryObjective($event)" >
                           </p-multiSelect>
                           </div>
                            </div>
                            </div>
                            <p-divider class="mt-3 mb-3" layout="horizontal" ></p-divider>
                            <div class=" font-bold ">Investment</div>
                            <div class=" ml-5">
                                <div class="p-fluid grid">
                                    <div class="col-2  mt-4" >
                                        Equity
                                    </div>
                                
                                    <div class="field col-10 mt-4 ">
                                        <p-multiSelect [options]="equity" [ngClass]="{ 'ng-invalid ng-dirty': formsubmited && f.equityType.errors }"
                                         optionLabel="value" optionValue="value" appendTo="body" formControlName="equityType"
                                        [autoDisplayFirst]="false" placeholder="Select" [showClear]="true" [dataKey]="value" 
                                        tooltipPosition="top" (onChange)="validatePrimaryObjective($event)" >
                                    </p-multiSelect>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-4 ml-5">
                                <div class="p-fluid grid">
                                    <div class="col-2 mt-4">
                                        Debt
                                    </div>
                                    <div class="field col-10 mt-4 ">
                                        <p-multiSelect [options]="debt" appendTo="body" [ngClass]="{ 'ng-invalid ng-dirty': formsubmited && f.debtType.errors }"
                                        optionLabel="value" optionValue="value"  formControlName="debtType"
                                        [autoDisplayFirst]="false" placeholder="Select" [showClear]="true" 
                                        tooltipPosition="top" (onChange)="validatePrimaryObjective($event)">
                                    </p-multiSelect>
                                    </div>
                                </div>
                            </div>
                        </div>
                     </form>
                    </ng-template>
                    <ng-template pTemplate="footer">
                        <button pButton pRipple label="Save" [loading]="loading" form="clform" icon="pi pi-check" class="p-button-text"
                          (click)="prepareModel('primaryObjectives',  model.primaryObjectives)"></button>
                     <button pButton pRipple class="p-button-text" label="Close" (click)="closeDialog('primaryObjectives')" > </button>
                    </ng-template>
                  </p-dialog> 
            </div>
            </div>
        </div>
        <div class="field col-12 md:col-6" (click)="editField('transactionObjective', model?.transactionObjective) ">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right">Transaction objective description: </div>
                <div class="col-12 md:col-8"  *ngIf="!editingField.transactionObjective" [innerHTML]="model?.transactionObjective"></div>
                <div *ngIf="editingField.transactionObjective">
                    <p-dialog [visible]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
                    [style]="{width: '50vw'}" [closable]="false" [modal]="true">
                    <ng-template pTemplate="header">
                      <div class="p-fluid formgrid grid">
                        <strong>{{model.screenTitle}}:</strong>Transaction objective description
                      
                      </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                      <editor [(ngModel)]="model.transactionObjective"></editor>
                    </ng-template>
                    <ng-template pTemplate="footer">
                      <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
                        (click)="updateField('transactionObjective', model?.transactionObjective )"></button>
                      <button pButton pRipple label="Close" class="p-button-text"
                        (click)="closeDialog('transactionObjective')" > </button>
                    </ng-template>
                  </p-dialog>
                </div>    
            </div>
        </div>
    </div>
    <h5>Company Information</h5>    
    <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-6" (click)="editField('targetRevenue', model.targetRevenue)">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right">Revenue ($m): </div>
                <div class="col-12 md:col-8"  *ngIf="!editingField.targetRevenue">{{getOutput(model.targetRevenue)}}</div>
                <div class="col-12 md:col-6" *ngIf="editingField.targetRevenue">
                    <div class="input-container">
                    <p-dropdown [multiple]="false" [(ngModel)]="model.targetRevenue"  [options]="lookupList.REVENUE"  
                    optionLabel="value"></p-dropdown>
                    <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                    (click)="updateField('targetRevenue', model.targetRevenue)"  ></button>
                    <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                    (click)="closeDialog('targetRevenue')"></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="field col-12 md:col-6" (click)="editField('targetEbitda', model?.targetEbitda)">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right">EBITDA ($m): </div>
                <div class="col-12 md:col-8" *ngIf="!editingField.targetEbitda">{{getOutput(model?.targetEbitda)}}</div>
                <div class="col-12 md:col-6" *ngIf="editingField.targetEbitda">
                    <div class="input-container">
                    <p-dropdown [multiple]="false" [(ngModel)]="model.targetEbitda"  [options]="lookupList.EBITDA"  
                    optionLabel="value"></p-dropdown>
                    <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                    (click)="updateField('targetEbitda', model.targetEbitda)"  ></button>
                    <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                    (click)="closeDialog('targetEbitda')"></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="field col-12 md:col-6" (click)="editField('timeline', model.timeline)">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right">Ideal timeline for executing transaction: </div>
                <div class="col-12 md:col-8" *ngIf="!editingField.timeline">{{model?.timeline}}</div>
                <div class="col-12 md:col-6" *ngIf="editingField.timeline">
                    <div class="input-container">
                    <p-dropdown [options]="timeline" styleClass="form-control" [(ngModel)]="model.timeline"
                       optionLabel="value" optionValue="value">
                    </p-dropdown>
                    <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                    (click)="updateField('timeline', model.timeline)"  ></button>
                    <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                    (click)="closeDialog('timeline')"></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="field col-12 md:col-6" (click)="editField('managementPreference', model.managementPreference)">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right">Management Preference:  </div>
                <div class="col-12 md:col-8"  *ngIf="!editingField.managementPreference">{{model?.managementPreference}}</div>
                <div class="col-12 md:col-6" *ngIf="editingField.managementPreference">
                    <div class="input-container">
                    <p-dropdown [options]="managementPreference" styleClass="form-control" [(ngModel)]="model.managementPreference"
                      optionLabel="value" optionValue="value">
                    </p-dropdown>
                    <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                    (click)="updateField('managementPreference', model.managementPreference)"></button>
                    <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                    (click)="closeDialog('managementPreference')"></button>
                    </div>
                </div>
            </div>
        </div>   
        <div class="field col-12 md:col-6" (click)="editField('turnaround', model.turnaround)">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right">Company undergoing a
                    turnaround/restructuring?: </div>
                <div class="col-12 md:col-8" *ngIf="!editingField.turnaround">{{model.turnaround==true?"Yes":"No"}}</div>
                <div class="col-12 md:col-6" *ngIf="editingField.turnaround">
                    <p-radioButton name="turnaround" [value]="true"
                    [(ngModel)]="model.turnaround" label="Y"  [(ngModel)]="model.turnaround" (ngModelChange)="updateField('turnaround', model.turnaround)"></p-radioButton>
                    <p-radioButton name="turnaround" [value]="false" 
                    [(ngModel)]="model.turnaround" label="N" [(ngModel)]="model.turnaround" (ngModelChange)="updateField('turnaround', model.turnaround)" class="ml-2"></p-radioButton>
                    </div>
            </div>
        </div>  
    </div>
    <h5>Classification</h5>   
    <div class="p-fluid formgrid grid">
        <ng-container *ngFor="let category of categories">
         <div class="field col-12 md:col-6" (click)="editField(category.label, model?.classification.level1)">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right">{{ category.label}}:</div>
                <div class="col-12 md:col-8" *ngIf="!editingField[category.label]">
                    <span  *ngFor="let classification of model?.classification[category.model];  let last = last;">
                        {{classification.name}}{{last?'':'; '}}
                    </span>
                </div>
                <div  *ngIf="editingField[category.label]" class="field col-12 md:col-8">
                    <div class="input-container">
                    <p-multiSelect [multiple]="false"[options]="classificationList[category.value]" [(ngModel)]="model?.classification[category.model]"
                     optionLabel="name" placeholder="Choose" [required]="true"
                    appendTo="body"  [type]="classification"> </p-multiSelect>
                    <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                    (click)="onSelectionChange(category.label)"></button>
                    <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                    (click)="closeDialog(category.label)"></button>
                    </div>
                </div>
            </div>
           </div>
        </ng-container>
        <!-- <div class="field col-12 md:col-6" (click)="editField('classificationLevel2', model.classification.level2) ">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right">Level2: </div>
                <div class="col-12 md:col-8"  *ngIf="!editingField.classificationLevel2">
                    <span *ngFor="let classification of model?.classification?.level2; let last = last;">
                        {{classification.name}}{{last?'':'; '}}
                    </span>
                </div>
                <div *ngIf="editingField.classificationLevel2">
                    <p-multiSelect [multiple]="false" [options]="classificationList?.CATEGORY_LEVEL2"  (ngModelChange)="onSelectionChange('classificationLevel2')"
                    [(ngModel)]="model?.classification.level2" optionLabel="name" placeholder="Choose" 
                    appendTo="body"  [type]="classification" ></p-multiSelect>
             
                </div>
            </div>
        </div>
        <div class="field col-12 md:col-6" (click)="editField('classificationLevel3', model.classification.level3) ">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right">Level3: </div>
                <div class="col-12 md:col-8" *ngIf="!editingField.classificationLevel3">
                    <span *ngFor="let classification of model?.classification?.level3; let last = last;">
                        {{classification.name}}{{last?'':'; '}}
                    </span>
                </div>
                <div *ngIf="editingField.classificationLevel3">
                    <p-multiSelect [multiple]="false" [options]="classificationList?.CATEGORY_LEVEL3"  (ngModelChange)="onSelectionChange('classificationLevel3')" 
                    [(ngModel)]="model?.classification.level3" optionLabel="name" placeholder="Choose" 
                    appendTo="body"  [type]="classification" (keydown)="disableNavigation($event)"></p-multiSelect>
             
                </div>
            </div>
        </div>
        <div class="field col-12 md:col-6" (click)="editField('classificationVertical', model.classification.vertical) ">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right">Vertical: </div>
                <div class="col-12 md:col-8"  *ngIf="!editingField.classificationVertical">
                    <span *ngFor="let classification of model?.classification?.vertical; let last = last;">
                        {{classification.name}}{{last?'':'; '}}
                    </span>
                </div>
                <div *ngIf="editingField.classificationVertical">
                    <p-multiSelect [multiple]="false" [options]="classificationList?.VERTICAL"  (ngModelChange)="onSelectionChange('classificationVertical')" 
                    [(ngModel)]="model?.classification.vertical" optionLabel="name" placeholder="Choose" 
                    appendTo="body"  [type]="classification" (keydown)="disableNavigation($event)"></p-multiSelect>
             
                </div>
            </div>
        </div>   
        <div class="field col-12 md:col-6" (click)="editField('classificationEcosystem', model.classification.ecosystem) ">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right">Ecosystem: </div>
                <div class="col-12 md:col-8" *ngIf="!editingField.classificationEcosystem">
                    <span *ngFor="let classification of model?.classification?.ecosystem; let last = last;">
                        {{classification.name}}{{last?'':'; '}}
                    </span>
                </div>
                <div *ngIf="editingField.classificationEcosystem">
                    <p-multiSelect [multiple]="false" [options]="classificationList?.ECOSYSTEM"  (ngModelChange)="onSelectionChange('classificationEcosystem')" 
                    [(ngModel)]="model?.classification.ecosystem" optionLabel="name" placeholder="Choose" 
                    appendTo="body"  [type]="classification" (keydown)="disableNavigation($event)"></p-multiSelect>
                </div>
            </div>
        </div>  -->
        <div class="field col-12 md:col-6" (click)="editField('companyDescription', model?.companyDescription) ">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right">Company Description: </div>
                <div class="col-12 md:col-8" *ngIf="!editingField.companyDescription" [innerHTML]="model?.companyDescription"></div>
                <div *ngIf="editingField.companyDescription">
                    <p-dialog [visible]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
                    [style]="{width: '50vw'}" [closable]="false" [modal]="true">
                    <ng-template pTemplate="header">
                      <div class="p-fluid formgrid grid">
                        <strong>{{model.screenTitle}}:</strong>Company Description
                      
                      </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                      <editor [(ngModel)]="model.companyDescription" ></editor>
                    </ng-template>
                    <ng-template pTemplate="footer">
                      <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
                        (click)="updateField('companyDescription', model?.companyDescription)"></button>
                      <button pButton pRipple label="Close" class="p-button-text"
                        (click)="closeDialog('companyDescription')" > </button>
                    </ng-template>
                  </p-dialog>
                </div>    
            </div>
        </div>  
    </div>
    <p-toast></p-toast>
</div>
</div>

