import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { NoteService } from './note.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LazyLoadEvent } from 'primeng/api';
import * as mimeType from 'mime'
import { HttpClient } from '@angular/common/http';
import { AppUtil } from './../_helpers';
import { Note } from '../_models';
import { BreadcrumbService } from '../app.breadcrumb.service';
import { OrganizationService } from '../organization/organization.service';
import { error, log } from 'console';
import { BuysideService } from '../buyside/buyside.service';
import { SellsideService } from '../sellside/sellside.service';
import { EmailCampaignService } from '../email-campaign/email-campaign.service';

@Component({
    selector: 'note-list',
    templateUrl: './note-list.component.html',
    styleUrls: ['./note-list.component.scss']
})
export class NoteListComponent implements OnInit {

    @Input() noteType: string = null; /* organization, contact */
    @Input() parentUuid = null;
    @Input() parent = null;
    @Output() noteUpdated = new EventEmitter();
    @Output() newContactCreated = new EventEmitter();
    notes: Note[];
    selectedNotes: Note[] = [];
    loading: boolean = true;
    totalRecords: number = 0;
    showForm: boolean = false;
    uuid: string = null;
    showListing: boolean = false;
    term: string;       
    CONTACT:any;
    ORGANIZATION:any;
    BUYSIDE:any;
    SELLIDE:any;

    constructor(
        private route: ActivatedRoute,
        private breadCrumbService: BreadcrumbService,
        private router: Router,
        private noteService: NoteService,
        public appUtil: AppUtil,
        private organizationService:OrganizationService,
        private buysideServcie:BuysideService,
        private sellsideService:SellsideService,
        private http: HttpClient) {
            if (this.router.url.indexOf("/notes") != -1) {
                let searchUrl = this.router.url.split("/")
                this.parentUuid = searchUrl[2]
                this.noteType = searchUrl[1]
                this.breadCrumbService.setItems([{ label: 'Notes Listing' }]);

            if(this.noteType==="contact"){
                this.noteService.getContact(this.parentUuid).subscribe(
                    (contactData) => {
                      this.CONTACT = contactData;
                      this.parent = this.CONTACT;
                    },
                    (error) => {
                      console.error("Error fetching contact:", error);
                    }
                  );
            }
            else if(this.noteType==="organization"){
                this.organizationService.getOrganization(this.parentUuid).subscribe(
                    (organizationData)=>{
                        this.ORGANIZATION=organizationData;
                        this.parent=this.ORGANIZATION.accountOwner;
                    },
                    (error)=>{
                        console.log("error fetching organization : " , error);
                    }
                )
            }
            else if(this.noteType==="buyside"){
                this.buysideServcie.getBuyside(this.parentUuid).subscribe(
                    (buysideData)=>{
                        this.BUYSIDE=buysideData;
                        this.parent=this.BUYSIDE;
                    },
                    (error)=>{
                        console.log("error fetching buyside : " , error)    
                    }
                )
            }
            else {
                this.noteType==="sellside";
                this.sellsideService.getSellside(this.parentUuid).subscribe(
                    (sellsideData)=>{
                        this.SELLIDE=sellsideData;
                        this.parent=this.SELLIDE;       
                    },
                    (error)=>{
                        console.log("error fetching sellside : " , error);
                    }
                )
            }               
            }
        this.term = '';
    }

    ngOnInit(): void {

        this.route.params.subscribe(data => {
            this.showListing = data.showListing == 'true'?true:false;
            if(this.showListing){
                this.search();
            }
        });
    }

    ngOnChanges(changes: SimpleChanges) {
    }

    new() {
        this.uuid = null;
        this.showForm = true;
    }

    edit(note: any) {
        this.uuid = note.uuid;
        this.showForm = true;
    }

    openDocument(event) {
        event.preventDefault();
        var filename = event.target.href;
        this.http.get(filename, { responseType: 'arraybuffer' }).subscribe(
            data => {
                var file = new Blob([data], { type: mimeType.getType(filename) });
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            });
    }

    search() {

        let e: LazyLoadEvent = { first: 0, rows: 5, sortField: 'lastModifiedDate', sortOrder: 1 }
        if(this.showListing){
            e.rows = -1;
        }
        let results : any;
        var searchParams = {};
        searchParams['page'] = (e.first / e.rows);
        searchParams['pageSize'] = e.rows;
        var sortBy = []
        var sortDir = e.sortOrder == 1 ? 'DESC' : 'ASC';
        if (e.sortField && e.sortField != '') {
            sortBy[sortBy.length] = { "property": e.sortField, "direction": sortDir };
        }
        searchParams['sortDetails'] = sortBy;
        if (this.term.trim() != '') {
            searchParams['rootRule'] = { 'condition': 'and', rules: [] };
            searchParams['rootRule'].rules.push({ "searchType": "textsearch", "field": "notes", "value": this.term, "operator": "matches" });
            searchParams['rootRule'].rules.push({ "searchType": "textsearch", "field": (this.noteType=='buyside'?'buysideScreen' : (this.noteType=='sellside' ? 'sellsideScreen' : (this.noteType=='contact' ? this.noteType+'s' : this.noteType)))+".uuid", "value": this.parentUuid, "operator": "matches" });
            results = this.noteService.searchNotes(searchParams);
            results.subscribe(data => {
                let response = data as any;
                    this.totalRecords = response.totalRecords;
                    this.notes = response.records;
                    this.loading = false;
                    this.appUtil.reinitializeShowMore();
                })
        } else {
            if (!this.loading)
                this.loading = true;
            results = this.noteService.getNotes(this.noteType, this.parentUuid, searchParams);
            results.subscribe(data => {
                let response = data as any;
                    this.totalRecords = response.totalRecords;
                    this.notes = response.records;
                    this.loading = false;
                    this.appUtil.reinitializeShowMore();
            })
        }
    }
    
}
