<div class="card">
    <div class="field col-12">
        <div class="field col-2">
            <tbp-query-builder [fields]="queryBuilderFieldsContact" (onSearch)="_searchContact($event)"
            [query]="queryBuilderQueryContact"></tbp-query-builder>
        </div>
        <p-table [autoLayout]="true" #dt [value]="contacts" [(selection)]="selectedContacts" dataKey="uuid"
            [lazy]="true" (onLazyLoad)="_search($event)"
            styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm" [rowHover]="true"
            [rows]="25" [paginator]="true" [rowsPerPageOptions]="[15,25,50,75,100,{ showAll: 'All' }]"
            [loading]="loading" [alwaysShowPaginator]="false" [showCurrentPageReport]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [totalRecords]="totalRecords" sortField="name">

            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 3rem">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th pSortableColumn="name">Name
                        <p-sortIcon field="name"></p-sortIcon>
                    </th>
                    <th pSortableColumn="phone">Phone
                        <p-sortIcon field="phone"></p-sortIcon>
                    </th>
                    <th pSortableColumn="mobile">Mobile
                        <p-sortIcon field="mobile"></p-sortIcon>
                    </th>
                    <th pSortableColumn="email">Email
                        <p-sortIcon field="email"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-contact>
                <tr>
                    <td>
                        <p-tableCheckbox [value]="contact"></p-tableCheckbox>
                    </td>
                    <td class="wrap">
                        <span class="p-column-title">Name</span>
                        {{contact.name}}
                    </td>
                    <td class="wrap">
                        <span class="p-column-title">Phone</span>
                        {{contact.phone}}
                    </td>
                    <td class="wrap">
                        <span class="p-column-title">Mobile</span>
                        {{contact.mobile}}
                    </td>
                    <td class="wrap">
                        <span class="p-column-title">Email</span>
                        {{contact.email}}
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="5">
                        No Contacts found
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>