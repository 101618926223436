import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
@Injectable({
    providedIn: 'root'
})
export class ConfigurationService {

    constructor(private http: HttpClient) { }

    getLookupKeys() {
        return this.http.get(environment.apiUrl + '/lookup-config/keys/ACTIVE');
    }

    getLookupValues(lookupKey: string) {
        return this.http.get(environment.apiUrl + '/lookup-config/values/' + lookupKey + '/ACTIVE');
    }

    createLookupValue(data: any, entityType: string, entityId: string) {
        const queryParams = {};
        if (entityType && entityType != '') {
            queryParams['entityType'] = entityType;
        }
        if (entityId && entityId != '') {
            queryParams['entityId'] = entityId;
        }
        return this.http.post(environment.apiUrl + '/lookup-config/value', data, { params: queryParams });
    }

    updateLookupValue(data: any) {
        return this.http.put(environment.apiUrl + '/lookup-config/value/' + data.uuid, data);
    }

    deleteLookupValue(uuid: string) {
        return this.http.delete(environment.apiUrl + '/lookup-config/value/' + uuid);
    }

    getCountries() {
        return this.http.get(environment.apiUrl + '/countries');
    }

    createCountry(data: any) {
        return this.http.post(environment.apiUrl + '/country', data);
    }

    updateCountry(data: any) {
        return this.http.post(environment.apiUrl + '/country', data);
    }
    createState(countryUuid: string, data: any) {
        return this.http.post(environment.apiUrl + '/country/' + countryUuid + '/state', data);
    }

    updateState(countryUuid: string, data: any) {
        return this.http.put(environment.apiUrl + '/country/' + countryUuid + '/state', data);
    }

    getLookupValue(uuid: string) {
        return this.http.get(environment.apiUrl + '/lookup-config/value/' + uuid);
    }

    getCountry(uuid: string) {
        return this.http.get(environment.apiUrl + '/country/' + uuid);
    }

    getState(uuid: string) {
        return this.http.get(environment.apiUrl + '/country/state/' + uuid);
    }

    mergeLookups(master: string, source1: string, source2: string) {
        return this.http.post(environment.apiUrl + '/lookup-config/lookup/' + master + "/merge/" + source1 + (source2 ? ("/" + source2) : ""), null);
    }

}
