<div class="grid table-demo">
    <div class="col-12">
        <div class="card">
            <p-toolbar styleClass="m-0 headerSticky">
                <ng-template pTemplate="left">
                </ng-template>

                <ng-template pTemplate="right">
                    <button pButton pRipple label="Back" icon="pi pi-arrow-left" class="p-button-success mr-2" (click)="appUtil.return()"></button>
                    <button pButton pRipple label="Merge" icon="pi pi-link" class="p-button-info  mr-2"
                        (click)="merge()"></button>
                </ng-template>
            </p-toolbar>
            <form [formGroup]="mergeForm">
                <p-table [autoLayout]="true" [value]="fields" [rowHover]="true"
                    styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="max-width:100px"></th>
                            <th>
                                {{contact1.displayName}}
                            </th>
                            <th>
                                <span class="mandate">*</span>
                                <p-autoComplete [suggestions]="suggestionList"
                                    (completeMethod)="searchOrg($event, true)" (onSelect)="selectSource($event, true)"
                                    (onUnselect)="unselectSource($event, true)" (onClear)="unselectSource($event, true)"
                                    field="displayName" dataKey="uuid" forceSelection="true" required="true"
                                    [ngClass]="{ 'ng-invalid ng-dirty': mergeForm.touched && !contact2 }">
                                </p-autoComplete>
                            </th>
                            <th>
                                <p-autoComplete [suggestions]="suggestionList"
                                    (completeMethod)="searchOrg($event, false)" (onSelect)="selectSource($event, false)"
                                    (onUnselect)="unselectSource($event, false)"
                                    (onClear)="unselectSource($event, false)" field="displayName" dataKey="uuid"
                                    forceSelection="true" appendTo="body">
                                </p-autoComplete>
                            </th>
                        </tr>
                        <tr>
                            <th class="wrap labelCol" style="max-width:100px">Is Master Record?</th>
                            <th>
                                <p-radioButton value="{{contact1.uuid}}" formControlName="masterId"></p-radioButton>
                            </th>
                            <th>
                                <p-radioButton value="{{contact2.uuid}}" formControlName="masterId" *ngIf="contact2">
                                </p-radioButton>
                            </th>
                            <th>
                                <p-radioButton value="{{contact3.uuid}}" formControlName="masterId" *ngIf="contact3">
                                </p-radioButton>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-field>
                        <tr>
                            <td class="wrap labelCol" style="max-width:100px">
                                <span class="p-column-title">Field Name</span>
                                {{field.label}}
                            </td>
                            <td class="wrap">
                                <span class="p-column-title">{{contact1.name}}</span>
                                <ng-container
                                    *ngIf="field.field =='createdBy' && field.field =='modifiedBy' && getValue(contact1,field.field)">
                                    {{getValue(contact1,field.field)}}</ng-container>
                                <p-radioButton value="{{contact1.uuid}}" [formControlName]="field.field"
                                    *ngIf="field.field !='createdBy' && field.field !='modifiedBy' && getValue(contact1,field.field)">
                                </p-radioButton>
                                <span innerHTML="{{getValue(contact1,field.field)}}" class="ml-2"></span>
                            </td>
                            <td class="wrap">
                                <span class="p-column-title">Source 1</span>
                                <ng-container
                                    *ngIf="field.field =='createdBy' && field.field =='modifiedBy' && getValue(contact2,field.field)">
                                    {{getValue(contact2,field.field)}}</ng-container>
                                <p-radioButton value="{{contact2.uuid}}" [formControlName]="field.field"
                                    *ngIf="field.field !='createdBy' && field.field !='modifiedBy' && getValue(contact2,field.field)">
                                </p-radioButton>
                                <span innerHTML="{{getValue(contact2,field.field)}}" class="ml-2"></span>
                            </td>
                            <td class="wrap">
                                <span class="p-column-title">Source 2</span>
                                <ng-container
                                    *ngIf="field.field =='createdBy' && field.field =='modifiedBy' && getValue(contact3,field.field)">
                                    {{getValue(contact3,field.field)}}</ng-container>
                                <p-radioButton value="{{contact3.uuid}}" [formControlName]="field.field"
                                    *ngIf="field.field !='createdBy' && field.field !='modifiedBy' && getValue(contact3,field.field)">
                                </p-radioButton>
                                <span innerHTML="{{getValue(contact3,field.field)}}" class="ml-2"></span>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </form>
        </div>
    </div>
</div>