import { AppUtil } from './../_helpers/app.util';
import { MessageService } from 'primeng/api';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { OrganizationService } from './organization.service';
import { Organization } from './../_models/organization';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from './../app.breadcrumb.service';
import { Component, OnInit } from '@angular/core';
import { TitleCasePipe, DatePipe } from '@angular/common';

@Component({
    selector: 'app-merge-organization',
    templateUrl: './merge-organization.component.html',
    styleUrls: ['./merge-organization.component.scss']
})
export class MergeOrganizationComponent implements OnInit {

    org1: Organization;
    org2: Organization;
    org3: Organization;
    fields: object[];
    suggestionList: any[];
    org2Uuid: string;
    org3Uuid: string;
    mergeForm: UntypedFormGroup;
    readOnlyFields:string[];
    editableFields:string[];
    companyTypes:any[];
    operatingTypes:any[];
    lookupList:any;
    excludedParents : any;
    excludedcorporateInvestorss : any;
    isMerging:boolean = false;

    constructor(
        private breadcrumbService: BreadcrumbService,
        private route: ActivatedRoute,
        private router: Router,
        private organizationService: OrganizationService,
        private messageService: MessageService,
        private formBuilder: UntypedFormBuilder,
        public appUtil: AppUtil) {

        this.breadcrumbService.setItems([
            { label: 'Merge Accounts' }
        ]);
        this.fields = [{ field: 'name', label: 'Name' }, { field: 'website', label: 'Website' },
        { field: 'accountOwner', label: 'Account Owner' }, { field: 'leadSource', label: 'Lead Source' },
        { field: 'revenue', label: 'Revenue Range ($m)' }, { field: 'numberOfEmployee', label: 'No. of Employees' },
        { field: 'capitaliqId', label: 'CapIQ Id' }, { field: 'pitchbookId', label: 'Pitchbook Id' },
        { field: 'linkedinPageId', label: 'Linked In Page' }, { field: 'companyType', label: 'Entity Type' },
        { field: 'customSubtypeValue', label: 'Company Type' },
        { field: 'isPubliclyTraded', label: 'Publicly Traded?' },
        { field: 'parentCompany', label: 'Parent' },
        { field: 'corporateInvestors', label: 'Corporate Investor' },
        { field: 'individualInvestors', label: 'Individual Investor' },
        { field: 'address', label: 'Street' },
        { field: 'city', label: 'City' }, { field: 'postalCode', label: 'Zip/Postal Code' },
        { field: 'phone', label: 'Phone' }, { field: 'country', label: 'Country' },
        { field: 'state', label: 'State/Province' }, { field: 'description', label: 'Description' },
        { field: 'createdBy', label: 'Created By' }, { field: 'modifiedBy', label: 'Last Modified By' },
        ];
        this.readOnlyFields = ['createdBy','modifiedBy'];
        this.editableFields = ['companyType', 'parentCompany','customSubtypeValue' ,'individualInvestors','corporateInvestors'];
        this.companyTypes =  [
            {name: 'Operating Company', uuid: 'OPERATING_COMPANY'},
            {name: 'Investor', uuid: 'INVESTOR'},
            {name: 'Lender', uuid: 'LENDER'},
            {name: 'Other', uuid: 'OTHER'}
        ];
        this.operatingTypes =  [
            {name: 'Large Company', uuid: 'true'},
            {name: 'Prospect', uuid: 'false'}
        ];
    }

    ngOnInit(): void {
        this.isMerging = false;
        this.lookupList = this.route.snapshot.data['lookupList'];
        this.route.paramMap.subscribe(params => {
            this.org1 = this.route.snapshot.data['organization'];
            this.createMergeForm();
            this.mf.masterId.setValue(this.org1.uuid)
            this.setFormValues(this.org1);
            this.org2 = null;
            this.org3 = null;
            this.org2Uuid = null;
            this.org3Uuid = null;
        });
        this.mf.masterId.valueChanges.subscribe(value => {
            let org = this.org1;
            if(this.org2 && this.org2.uuid == value)
            {
                org = this.org2;
            }
            else if(this.org3 && this.org3.uuid == value)
            {
                org = this.org3;
            }
            this.setFormValues(org);
        });
        this.mf.companyType.valueChanges.subscribe(value => {
            this.prepareIsPublicyTraded(value.uuid);
        });
    }

    prepareIsPublicyTraded(companyType: string)
    {
        //merge values based on selection
        // enable dispable isPublicyTraded
        if(companyType == 'OPERATING_COMPANY')
        {
            this.mf.isPubliclyTraded.enable();
            if(!this.mf.isPubliclyTraded.value || this.mf.isPubliclyTraded.value == null)
            {
                this.mf.isPubliclyTraded.setValue(this.mf.masterId.value);
            }
        }
        else
        {
            this.mf.isPubliclyTraded.disable();
            this.mf.isPubliclyTraded.setValue(null);
        }
    }

    getInvestors(org)
    {
        let corporateInvestors = [];
        let individualInvestors = [];
        if (org.investors != null) {
            org.investors.forEach(function (currentValue:any) {
                if(currentValue.type == 'CORPORATE')
                {
                    this.corporateInvestors.push({'uuid' : currentValue.uuid, 'name': currentValue.name});
                }
                else
                {
                    this.individualInvestors.push({'uuid' : currentValue.uuid, 'name': currentValue.name, 'displayName': currentValue.displayName});
                }
            }, {corporateInvestors, individualInvestors})
        }
        return {individualInvestors, corporateInvestors}
    }

    setFormValues(org)
    {
        let investors = this.getInvestors(org);
        this.mergeForm.patchValue({ name: org.uuid, website: org.uuid, accountOwner: org.uuid, leadSource: org.uuid,
            revenue: org.uuid, numberOfEmployee: org.uuid, capitaliqId: org.uuid, pitchbookId: org.uuid, linkedinPageId: org.uuid,
            companyType: this.appUtil.getObject(this.companyTypes,org.companyType), subTypes: org.subTypes,
            customSubtypeValue: this.appUtil.getObject(this.operatingTypes,org.customSubtypeValue),
            isPubliclyTraded: org.uuid, address: org.uuid, city: org.uuid, postalCode: org.uuid, phone: org.uuid, country: org.uuid,
            state: org.uuid, parentCompany: org.parentCompany, individualInvestors: investors.individualInvestors,
            corporateInvestors: investors.corporateInvestors, description: org.uuid});

        this.prepareIsPublicyTraded(this.mf.companyType.value.code);
        /*
        if(this.org1.uuid != this.mf.masterId.value.uuid)
        {
            this.cleanupParentAndInvestor(this.org1);
        }
        if(this.org2 && this.org2.uuid != this.mf.masterId.value.uuid)
        {
            this.cleanupParentAndInvestor(this.org2);
        }
        if(this.org3 && this.org3.uuid != this.mf.masterId.value.uuid)
        {
            this.cleanupParentAndInvestor(this.org3);
        }        
        */
        this.computeExcludedAccounts();
    }

    createMergeForm() {
        this.mergeForm = this.formBuilder.group({
            masterId: [], name: [], website: [], accountOwner: [], leadSource: [], revenue: [], numberOfEmployee: [],
            capitaliqId: [], pitchbookId: [], linkedinPageId: [], companyType: [], subTypes: [], customSubtypeValue: [],
            isPubliclyTraded: [], address: [], city: [], postalCode: [], phone: [], country: [], state: [],
            parentCompany: [], individualInvestors: [], corporateInvestors: [], description: []
        });

    }

    getValue(org, fieldName) {
        let value: any;
        if (org) {
            
            switch (fieldName) {
                case 'accountOwner':
                    value = org[fieldName] ? (org[fieldName].name) : '';
                    break;
                case 'createdBy':
                    value = org.createdBy.split("::");
                    value = (value.length > 1) ? value[1] : '-';
                    value = value + ', ' + new DatePipe("en-US").transform(org.createdDate, "MM-dd-YYYY hh:mm a");
                    break;
                case 'modifiedBy':
                    value = org.lastModifiedBy.split("::");
                    value = (value.length > 1) ? value[1] : '-';
                    value = value + ', ' + new DatePipe("en-US").transform(org.lastModifiedDate, "MM-dd-YYYY hh:mm a");
                    break;
                case 'companyType':
                    value = org[fieldName] ? new TitleCasePipe().transform(org[fieldName].replace('_', ' ')) : '-';
                    break;
                case 'customSubtypeValue':
                    value = (org[fieldName] == 'true') ? 'Large Company' : ((org[fieldName] == 'false') ? 'Prospect' : null);
                    break;
                case 'isPubliclyTraded':
                    value = org[fieldName] ? 'Yes' : 'No';
                    break;
                case 'parentCompany':
                    value = org[fieldName] ? org[fieldName].name : '';
                    break;
                case 'level1':
                case 'level2':
                case 'level3':
                case 'vertical':
                case 'ecosystem':
                    value = this.appUtil.getConcatValue(org['classification'][fieldName], 'name');
                    break;
                default:
                    value = org[fieldName];
            }
        }
        return value;
    }

    addParent(parent)
    {
        this.mf.parentCompany.setValue(parent);
    }

    addCorporate(investor)
    {
        if(!this.mf.corporateInvestors.value)
        {
            this.mf.corporateInvestors.setValue([investor]);
        }
        else
        {
            this.mf.corporateInvestors.setValue(this.mf.corporateInvestors.value.concat(investor));
        }
    }

    addIndividual(investor)
    {
        if(!this.mf.individualInvestors.value)
        {
            this.mf.individualInvestors.setValue([investor]);
        }
        else
        {
            this.mf.individualInvestors.setValue(this.mf.individualInvestors.value.concat(investor));
        }
    }

    addSubtype(subtype)
    {
        if(!this.mf.subTypes.value)
        {
            this.mf.subTypes.setValue([subtype]);
        }
        else
        {
            this.mf.subTypes.setValue(this.mf.subTypes.value.concat(subtype));
        }
    }


    get mf() {
        return this.mergeForm.controls;
    }

    resetSelections(olduuid) {
        if (olduuid && (this.mf.masterId.value == olduuid)) {
            this.mf.masterId.setValue(this.org1.uuid);
        }
        this.computeExcludedAccounts();
    }

    unselectSource($event, isOrg2) {
        if (isOrg2) {

            this.resetSelections(this.org2Uuid);
            this.org2 = null;
            this.org2Uuid = null;
        }
        else {
            this.resetSelections(this.org3Uuid);
            this.org3 = null;
            this.org3Uuid = null;
        }

    }

    getOrganizationData(data, isOrg2) {
        if (isOrg2) {
            this.org2 = data;
            this.org2Uuid = this.org2.uuid;
        }
        else {
            this.org3 = data;
            this.org3Uuid = this.org3.uuid;
        }
    }

    cleanupParentAndInvestor(org)
    {
        if(this.mf.parentCompany.value?.uuid == org.uuid)
        {
            this.mf.parentCompany.setValue(null);
        }
        if(this.appUtil.getObject(this.mf.corporateInvestors.value, org) != null)
        {
            this.appUtil.deleteItem(this.mf.corporateInvestors.value, org);
        }
    }

    selectSource($event, isOrg2) {

        this.organizationService.getOrganization($event.uuid).subscribe(
            data => {
                let org = (data as any);
                this.getOrganizationData(data, isOrg2);
                //this.cleanupParentAndInvestor(org);
                this.computeExcludedAccounts();
        });

    }

    searchOrg($event, isOrg2) {
        this.organizationService.lookupOrganization($event.query).subscribe(data => {
            let suggestions = [];
            let results = data as any[];
            results.forEach(function (currentValue, index) {
                if (currentValue.uuid != this.comp.org1.uuid) {
                    let add = true;
                    if (isOrg2) {
                        if (this.comp.org3 && currentValue.uuid == this.comp.org3.uuid) {
                            add = false;
                        }
                    }
                    else if (this.comp.org2 && currentValue.uuid == this.comp.org2.uuid) {
                        add = false;
                    }
                    if (add)
                        this.suggestions.push(currentValue);
                }
            }, { comp: this, suggestions });
            this.suggestionList = suggestions;
        });
    }

    merge() {
        this.mergeForm.markAllAsTouched();
        let dataToPost = { ...this.mergeForm.value };
        delete dataToPost.masterId;
        delete dataToPost.createdBy;
        delete dataToPost.modifedBy;
        dataToPost.companyType = dataToPost.companyType.uuid;
        dataToPost.subTypes = dataToPost.subTypes?dataToPost.subTypes.map(value => value.uuid):null;
        dataToPost.customSubtypeValue = dataToPost.customSubtypeValue?dataToPost.customSubtypeValue:null;
        dataToPost.corporateInvestors = dataToPost.corporateInvestors?dataToPost.corporateInvestors.map(value => value.uuid):null;
        dataToPost.individualInvestors = dataToPost.individualInvestors?dataToPost.individualInvestors.map(value => value.uuid):null;
        dataToPost.parentCompany = dataToPost.parentCompany?dataToPost.parentCompany.uuid:null;
        if(dataToPost.companyType == 'OPERATING_COMPANY')
        {
            dataToPost.subTypes = null;
        }
        else
        {
            dataToPost.customSubtypeValue = null;
            dataToPost.isPubliclyTraded = null;
        }

        if (this.org2 || this.org3) {
            let source1 = (this.org2Uuid && this.org2Uuid == this.mf.masterId.value) ? this.org1.uuid : this.org2Uuid;
            let source2 = (this.org3Uuid && this.org3Uuid == this.mf.masterId.value) ? this.org1.uuid : this.org3Uuid;
            this.isMerging = true;
            this.organizationService.mergeOrganizations(this.mf.masterId.value, source1, source2, dataToPost)
                .subscribe(
                    data => {
                        this.messageService.add({ severity: 'success', summary: 'Merge Successful', detail: "Merge was successfull.", life: 3000 });
                        this.appUtil.routeToViewURL('organization', this.mf.masterId.value);
                        this.isMerging = false;
                    },
                    error => {
                        let errors = error.error;
                        if (errors.code && errors.code == 'VALIDATION_ERROR') {
                            for (var i = 0; i < errors.subErrors.length; i++) {
                                this.messageService.add({ severity: 'error', summary: 'Merge UnSuccessful', detail: errors.subErrors[i].message , life: 4000 });
                            }
                        }   
                        else
                        {
                            this.messageService.add({ severity: 'error', summary: 'Merge UnSuccessful', detail: "Server encountered an unknown error while trying to perform merge operation.", life: 3000 });
                        }    
                        this.isMerging = false;                 
                     },
                );
        }
    }

    computeExcludedAccounts(){

        let excludedAccounts = [];
        if(this.org1)
        {
            excludedAccounts.push(this.org1);
        }
        if(this.org2)
        {
            excludedAccounts.push(this.org2);
        }
        if(this.org3)
        {
            excludedAccounts.push(this.org3);
        }
        if(this.mf.corporateInvestors.value != null){
            excludedAccounts = excludedAccounts.concat(this.mf.corporateInvestors.value);
        }
        this.excludedParents = [].concat(excludedAccounts)

        if(this.mf.parentCompany.value != null && this.mf.parentCompany.value!='')
        {
            excludedAccounts = excludedAccounts.concat(this.mf.parentCompany.value);
        }
        this.excludedcorporateInvestorss = [].concat(excludedAccounts);
    }

}
