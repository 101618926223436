import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, map, Observable, tap, throwError } from 'rxjs';
import { environment } from './../../environments/environment';
import { AuthUser } from './../_models';
@Injectable({ providedIn: 'root' })
export class AccountService {
    private userSubject: BehaviorSubject<AuthUser>;

    get role() {
        return this.userValue.role;
    }
    constructor(
        private router: Router,
        private http: HttpClient

    ) {
        const user = localStorage.getItem('user');
        if (user != null)
            this.userSubject = new BehaviorSubject<AuthUser>(JSON.parse(user));
        else
            this.userSubject = new BehaviorSubject<AuthUser>(null);
    }

    public get userValue(): AuthUser {
        return this.userSubject.getValue();
    }

     getProfile(): Observable<AuthUser> {

        return this.http.get<AuthUser>(`${environment.apiUrl}/profile`);
    }

    logout(): void {
        localStorage.removeItem('user');
        localStorage.clear();
        window.location.href = `${environment.apiUrl}/logout?returnUrl=${window.location.href}`;
    }
    login(returnUrl: string): void {
         localStorage.setItem('returnUrl', returnUrl);
         window.location.href = `${environment.apiUrl}/login`;
    }

    storeAuthUser(user: AuthUser) {
        user.storageTime = new Date().getTime();
        localStorage.setItem('user', JSON.stringify(user));
        this.userSubject.next(user);
    }

    forgotPassword(email: string) {
        return this.http.get<AuthUser>(`${environment.apiUrl}/forgot-password?email=${email}`);
    }

    resetPassword(formData) {
        return this.http.post(environment.apiUrl + '/reset-password', formData);
    }



    register(user: AuthUser) {
        return this.http.post(environment.apiUrl + '/register', user);
    }

    activate(actionUUID: string) {
        return this.http.get<AuthUser>(`${environment.apiUrl}/activate/${actionUUID}`);
    }


    getAll() {
        return this.http.get<AuthUser[]>(`${environment.apiUrl}/users`);
    }

    getById(id: string) {
        return this.http.get<AuthUser>(`${environment.apiUrl}/users/${id}`);
    }


    update(id, params) {
        return this.http.put(`${environment.apiUrl}/users/${id}`, params)
            .pipe(map(x => {
                // update stored user if the logged in user updated their own record
                if (id == this.userValue.uuid) {
                    // update local storage
                    const user = { ...this.userValue, ...params };
                    localStorage.setItem('user', JSON.stringify(user));

                    // publish updated user to subscribers
                    this.userSubject.next(user);
                }
                return x;
            }));
    }

    delete(id: string) {
        return this.http.delete(`${environment.apiUrl}/users/${id}`)
            .pipe(map(x => {
                // auto logout if the logged in user deleted their own record
                if (id == this.userValue.uuid) {
                    this.logout();
                }
                return x;
            }));
    }

    sendActivationEmail(id: string) {
        return this.http.get<boolean>(`${environment.apiUrl}/activationmail/${id}`);
    }


    refreshToken() {
        return this.http.get<any>(`${environment.apiUrl}/refresh-token?returnUrl=${window.location.href}`).pipe(tap((user: AuthUser) => {
            if (user == null) {
                this.logout();
            }
            else { 
                this.storeAuthUser(user);
            }
        }));
    }

    getJwtToken() {
        if (this.userSubject.value)
            return this.userSubject.value.accessToken;
    }

    private getRefreshToken() {
        if (this.userSubject.value != null && this.isRefreshTokenValid()) {
            return this.userSubject.value.refreshToken;
        }

    }

    isAccessTokenValid() {
        if (this.userSubject.value != null) {
            const user = this.userSubject.value;
            return (new Date().getTime() - user.storageTime) < ((user.expiresIn - 1) * 60 * 1000);
        }

    }

    isRefreshTokenValid() {
        if (this.userSubject.value != null) {
            const user = this.userSubject.value;
            return (new Date().getTime() - user.storageTime) < ((user.refreshTokenExpiresIn - 1) * 60 * 1000);
        }
    }
}