import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from '../app.breadcrumb.service';
import { User } from '../_models';
import { LazyLoadEvent, MenuItem } from 'primeng/api';
import { UsersService } from './../users/users.service';
import { Table } from 'primeng/table';
@Component({
    selector: 'app-email-campaign-participant',
    templateUrl: './email-campaign-participant.component.html',
    styleUrls: ['./email-campaign-participant.component.scss']
})
export class EmailCampaignParticipantComponent implements OnInit {
    @ViewChild('dt') table: Table;
    contacts: User[];
    selectedContacts: User[];
    selectedRadio: any = null;
    totalRecords: number = 0;
    loading: boolean;
    lookupList: any = {
        ACCOUNT_SOURCE: [], REVENUE: [], ORG_TYPE_INVESTOR: [], No_OF_EMPLOYEES: [],
        ORG_TYPE_LENDER: [], ORG_TYPE_OTHER: []
    };
    classificationList: any = {
        CATEGORY_LEVEL1: [], CATEGORY_LEVEL2: [], CATEGORY_LEVEL3: [],
        VERTICAL: [], ECOSYSTEM: []
    };
    countries = [];
    queryBuilderFieldsContact: any = {};
    queryBuilderQueryContact: any = {};
    queryBuilderFieldsAccount: any = {};
    queryBuilderQueryAccount: any = {};
    currentQuery: string = '';
    constructor(private breadcrumbService: BreadcrumbService,
        private route: ActivatedRoute, private userService: UsersService) { }

    ngOnInit(): void {
        this.selectedRadio = "Account";
        this.breadcrumbService.setItems([{ label: 'Add Participant' }]);
        this.classificationList = this.route.snapshot.data['classificationList'];
        this.lookupList = this.route.snapshot.data['lookupList'];
        this.countries = this.route.snapshot.data['countries'];
        let tmpQueryFields = {
            name: { name: 'Name', type: 'string', searchType: 'textsearch' },
            description: { name: 'Description', type: 'string', searchType: 'textsearch' },
            companyType: {
                name: 'Type', type: 'category', searchType: 'exactsearch',
                options: [
                    { name: 'Operating Company', value: 'OPERATING_COMPANY' },
                    { name: 'Investor', value: 'INVESTOR' },
                    { name: 'Lender', value: 'LENDER' },
                    { name: 'Other', value: 'OTHER' }
                ]
            },
            level1: {
                name: 'Level1', type: 'category', searchType: 'exactsearch',
                options: this.classificationList.CATEGORY_LEVEL1.map((cat) => {
                    return { name: cat.name, value: cat.uuid }
                })
            },
            level2: {
                name: 'Level2', type: 'category', searchType: 'exactsearch',
                options: this.classificationList.CATEGORY_LEVEL2.map((cat) => {
                    return { name: cat.name, value: cat.uuid }
                })
            },
            level3: {
                name: 'Level3', type: 'category', searchType: 'exactsearch',
                options: this.classificationList.CATEGORY_LEVEL3.map((cat) => {
                    return { name: cat.name, value: cat.uuid }
                })
            },
            vertical: {
                name: 'Vertical', type: 'category', searchType: 'exactsearch',
                options: this.classificationList.VERTICAL.map((cat) => {
                    return { name: cat.name, value: cat.uuid }
                })
            },
            ecosystem: {
                name: 'Ecosystem', type: 'category', searchType: 'exactsearch',
                options: this.classificationList.ECOSYSTEM.map((cat) => {
                    return { name: cat.name, value: cat.uuid }
                })
            },
            accountOwner: { name: 'Account Owner', type: 'string', searchType: 'textsearch' },
            leadSource: { name: 'Lead Source', type: 'string', searchType: 'textsearch' },
            revenueLookup: { name: 'Revenue', type: 'number', searchType: 'rangesearch' },
            numberOfEmployeeLookup: { name: 'No Of Employees', type: 'number', searchType: 'rangesearch' },
            isPubliclyTraded: { name: 'Is Publicly Traded', type: 'boolean', searchType: 'booleansearch' },
            notes: { name: 'Notes', type: 'string', searchType: 'textsearch' },
            parentCompany: { name: 'Parent Account', type: 'string', searchType: 'textsearch' },
        }
        this.queryBuilderFieldsAccount = Object.keys(tmpQueryFields).sort(function (a, b) {
            var nameA = tmpQueryFields[a].name.toUpperCase(); // ignore upper and lowercase
            var nameB = tmpQueryFields[b].name.toUpperCase(); // ignore upper and lowercase
            return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
        }).reduce(function (result, key) {
            result[key] = tmpQueryFields[key];
            return result;
        }, {});

        ///Contact Search
        let tmpQueryFieldsContact = {
            name: { name: 'Name', type: 'string', searchType: 'textsearch' },
            description: { name: 'Description', type: 'string', searchType: 'textsearch' },
            email: { name: 'Type', type: 'string', searchType: 'textsearch' },
            level1: {
                name: 'Level1', type: 'category', searchType: 'exactsearch',
                options: this.classificationList.CATEGORY_LEVEL1.map((cat) => {
                    return { name: cat.name, value: cat.uuid }
                })
            },
            level2: {
                name: 'Level2', type: 'category', searchType: 'exactsearch',
                options: this.classificationList.CATEGORY_LEVEL2.map((cat) => {
                    return { name: cat.name, value: cat.uuid }
                })
            },
            level3: {
                name: 'Level3', type: 'category', searchType: 'exactsearch',
                options: this.classificationList.CATEGORY_LEVEL3.map((cat) => {
                    return { name: cat.name, value: cat.uuid }
                })
            },
            vertical: {
                name: 'Vertical', type: 'category', searchType: 'exactsearch',
                options: this.classificationList.VERTICAL.map((cat) => {
                    return { name: cat.name, value: cat.uuid }
                })
            },
            ecosystem: {
                name: 'Ecosystem', type: 'category', searchType: 'exactsearch',
                options: this.classificationList.ECOSYSTEM.map((cat) => {
                    return { name: cat.name, value: cat.uuid }
                })
            },
            accountOwner: { name: 'Account Owner', type: 'string', searchType: 'textsearch' },
            leadSource: { name: 'Lead Source', type: 'string', searchType: 'textsearch' },
            notes: { name: 'Notes', type: 'string', searchType: 'textsearch' },
            organization: { name: 'Account', type: 'string', searchType: 'textsearch' },
        }
        this.queryBuilderFieldsContact = Object.keys(tmpQueryFieldsContact).sort(function (a, b) {
            var nameA = tmpQueryFieldsContact[a].name.toUpperCase(); // ignore upper and lowercase
            var nameB = tmpQueryFieldsContact[b].name.toUpperCase(); // ignore upper and lowercase
            return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
        }).reduce(function (result, key) {
            result[key] = tmpQueryFieldsContact[key];
            return result;
        }, {});

    }

    _searchAccount(event) {
        this.queryBuilderQueryAccount = event.query;
        this.currentQuery = event.queryString;
    }

    _searchContact(event) {
       
        event.query.rules.forEach(element => {
            element.searchType = 'exactsearch'
        });
        this.queryBuilderQueryContact = event.query;
        this.currentQuery = event.queryString;
        this.resetPagination();
        this._search();
    }

    resetPagination() {
        this.table.first = 0;
        this.table.rows = 25;
    }

    _search(e: LazyLoadEvent = { first: 0, rows: 25, sortField: 'name', sortOrder: 1 }) {
        var searchParams = {};
        searchParams['page'] = (e.first / e.rows);
        searchParams['pageSize'] = e.rows;
        var sortBy = []
        var sortDir = e.sortOrder == 1 ? 'ASC' : 'DESC';
        if (e.sortField && e.sortField != '') {
            sortBy[sortBy.length] = { "property": e.sortField, "direction": sortDir };
        }
        searchParams['sortDetails'] = sortBy;

        searchParams['rootRule'] = { 'condition': 'and', rules: [] }
        searchParams['rootRule'] = { ...this.queryBuilderQueryContact };
        this.loading = true;
        this.userService.searchContact(searchParams).subscribe(
            data => {
                let response = data as any;
                this.totalRecords = response.totalRecords;
                this.contacts = response.records;
                this.loading = false;
            });
    }


}
