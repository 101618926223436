import { EntityClassification } from "./entity-classification";
import { User } from "./user";

export class Spotlight {

    constructor() {
        this.classification = new EntityClassification;
    }

    uuid?: string;
    title?: string;
    spotlightDate?: string;
    status?: string;
    content?: string;
    metaKeywords?: string;
    metaDescription?: string;
    classification: EntityClassification;
    deals?: [];
    organizations?: [];
    leftLogoAttachment?: [];
    rightLogoAttachment?: [];
    createByUser?: User;
    createdDate?: User;
    modifiedByUser?: User;
    lastModifiedDate?: User;
}