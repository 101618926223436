<div class="grid table-demo">
    <div class="col-12">
        <div class="card">
            <p-toolbar styleClass="headerSticky">
                <div class="p-toolbar-group-left">
                    <div #srch>
                        <span class="p-input-icon-left p-input-icon-right">
                            <i class="pi pi-search"></i>
                            <input [(ngModel)]="term" pInputText type="text" placeholder="Search" class="table-search"
                                (keyup.enter)="search()" />
                            <i class="pi pi-bars"></i>
                        </span>
                    </div>
                </div>
                <div class="p-toolbar-group-right">
                    <button pButton pRipple label="Back" icon="pi pi-arrow-left" class="p-button-success mr-2" (click)="appUtil.return()"></button>
                    <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success mr-2"
                        (click)="openNew()"></button>
                </div>
            </p-toolbar><br>
            <p-table [autoLayout]="true" #dt [value]="users" [(selection)]="selectedUsers" dataKey="uuid" [lazy]="true"
                (onLazyLoad)="search($event)"
                styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm" [rowHover]="true"
                [rows]="15" [paginator]="true" [rowsPerPageOptions]="[15,25,50,75,100]" [loading]="loading"
                [alwaysShowPaginator]="true" [showCurrentPageReport]="true"
                [showPageLinks]="appUtil.getPagination(totalRecords)" [showFirstLastIcon] = "appUtil.getPagination(totalRecords)"
                currentPageReportTemplate="Showing {first} to {last} of {{appUtil.getPagination(totalRecords) ? totalRecords :'many'}} entries"
                [totalRecords]="totalRecords" sortField="name">
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="name">Name
                            <p-sortIcon field="name"></p-sortIcon>
                        </th>
                        <th pSortableColumn="phone">Phone
                            <p-sortIcon field="phone"></p-sortIcon>
                        </th>
                        <th pSortableColumn="mobile">Mobile
                            <p-sortIcon field="mobile"></p-sortIcon>
                        </th>
                        <th pSortableColumn="email">Email
                            <p-sortIcon field="email"></p-sortIcon>
                        </th>
                        <th pSortableColumn="title">Title
                            <p-sortIcon field="title"></p-sortIcon>
                        </th>
                        <th>Action</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-user>
                    <tr>
                        <td class="wrap capitalize">
                            <span class="p-column-title">Name</span>
                            <a [routerLink]="['/user', user.uuid]" [innerHTML]="user.name | highlight:term"></a>
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Phone</span>
                            {{user.phone}}
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Mobile</span>
                            {{user.mobile}}
                        </td>
                        <td class="wrap lowercase">
                            <span class="p-column-title">Email</span>
                            <a [routerLink]="['/user', user.uuid]"[innerHTML]="user.email | highlight:term"></a>
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Title</span>
                            {{user.title}}
                        </td>
                        <td>
                            <span class="p-column-title">Action</span>
                            <button type="button" pButton icon="pi pi-list"
                                (click)="setCurrentItem(user); menu.toggle($event)"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="20">
                            <span *ngIf="term != ''">Search did not return any result. Try relaxing the criteria or use
                                wildcard [*, ?]</span>
                            <span *ngIf="term == ''">No users found</span>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
<p-menu #menu [popup]="true" [model]="userItems"></p-menu>
<p-toast></p-toast>
<p-dialog header="Confirm" [(visible)]="visible" >
    <div class="p-fluid formgrid grid">
        <div class="field col-12">
            <div class="grid mt-1 limit-width-large">
                <div class="col-12 label">
                    New Contact Owner
                </div>
                <div class="field col-12">
                    <p-dropdown [options]="tbpUsers" optionLabel="name" [(ngModel)]="newContactOwner" [autoDisplayFirst]="false" [showClear]="true" appendTo="body"></p-dropdown>
                </div>
            </div>
        </div>
        <div class="field col-12">
            <div class="grid mt-1 limit-width-large">
                <div class="col-12 label">
                    New Account Owner
                </div>
                <div class="field col-12 capitalize">
                    <p-dropdown [options]="tbpUsers"  optionLabel="name" [(ngModel)]="newAccountOwner" [autoDisplayFirst]="false" [showClear]="true" appendTo="body"></p-dropdown>
                </div>
            </div>
        </div>
    </div>
    <div class="grid" style="margin-top: 5px;">
        <div class="col" style="text-align: right;">
            <button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-success mr-2" (click)="replaceOrDeleteUsers(uuid, newContactOwner, newAccountOwner)" [disabled]="false"></button>
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-success mr-2" (click)="onCancel()" [disabled]="false"></button>
        </div>
    </div>
</p-dialog>
