<div class="grid table-demo">
    <div class="col-12">
        <div class="card">
            <p-tabView (onChange)="handleChange($event)">
                <p-tabPanel header="Level 1">
                    <ng-template pTemplate="content" cache="false">
                        <ng-template [ngTemplateOutlet]="OtherType">
                        </ng-template>
                    </ng-template>
                </p-tabPanel>
                <p-tabPanel header="Level 2" cache="false">
                    <ng-template pTemplate="content">
                        <ng-template [ngTemplateOutlet]="OtherType">
                        </ng-template>
                    </ng-template>
                </p-tabPanel>
                <p-tabPanel header="Level 3" cache="false">
                    <ng-template pTemplate="content">
                        <ng-template [ngTemplateOutlet]="OtherType">
                        </ng-template>
                    </ng-template>
                </p-tabPanel>
                <p-tabPanel header="Verticals" cache="false">
                    <ng-template pTemplate="content">
                        <ng-template [ngTemplateOutlet]="OtherType">
                        </ng-template>
                    </ng-template>
                </p-tabPanel>
                <p-tabPanel header="Ecosystem" cache="false">
                    <ng-template pTemplate="content">
                        <ng-template [ngTemplateOutlet]="OtherType">
                        </ng-template>
                    </ng-template>
                </p-tabPanel>
            </p-tabView>
        </div>
    </div>
</div>
<p-overlayPanel #op [showCloseIcon]="true" [style]="{width: '450px'}">
    <ng-template pTemplate>
        <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search"
            class="p-inputtext-lg table-search" style="width: 220px" />
    </ng-template>
</p-overlayPanel>
<ng-template #OtherType>
    <p-toolbar styleClass="m-0">
        <ng-template pTemplate="left">
        </ng-template>

        <ng-template pTemplate="right">
            <button pButton pRipple label="Back" icon="pi pi-arrow-left"  class="p-button-success mr-2" (click)="appUtil.return()"></button>
            <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success mr-2"
                (click)="openNew()"></button>
            <button pButton pRipple label="Merge" icon="pi pi-link" class="p-button-info  mr-2"
                (click)="showMergeOptions()"></button>
            <button pButton pRipple label="Move" (click)="showMoveOptions()" icon="pi pi-arrows-h" [disabled]="selectedClassifications==null || selectedClassifications==''"></button>
        </ng-template>
    </p-toolbar>

    <p-table [autoLayout]="true" #dt [value]="classifications" [(selection)]="selectedClassifications" dataKey="uuid"
        [lazy]="true" (onLazyLoad)="fetchRecords($event)"
        styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm" [rowHover]="true"
        [loading]="loading" [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [totalRecords]="totalRecords"
        sortField="name">
        <ng-template pTemplate="caption">
            <div class="table-header">
                <div class="p-fluid formgrid grid">
                    <div class="field col-12 md:col-4">
                        <span class="p-input-icon-left p-input-icon-right">
                            <i class="pi pi-search"></i>
                            <input [(ngModel)]="term" pInputText type="text" placeholder="Search" class="table-search"
                                (keyup.enter)="fetchRecords()" />
                            <i class="pi pi-bars"></i>
                        </span>
                    </div>
                </div>

            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 3rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th pSortableColumn="name">Name
                    <p-sortIcon field="name"></p-sortIcon>
                </th>
                <th pSortableColumn="description">Description
                    <p-sortIcon field="description"></p-sortIcon>
                </th>
                <th pSortableColumn="status">Status
                    <p-sortIcon field="status"></p-sortIcon>
                </th>
                <th style="width: 8rem; text-align: center;">Action</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-classification let-ri="rowIndex">
            <tr class="p-selectable-row">
                <td (click)="$event.stopPropagation();" >
                    <p-tableCheckbox [value]="classification"></p-tableCheckbox>
                </td>
                <td>
                    <span class="p-column-title">Name</span>
                    <span [innerHTML]="classification.name | highlight:term"></span>
                </td>
                <td pEditableColumn>
                    <span class="p-column-title">Description</span>
                    <span class="limitedText limitText">
                        {{classification.description}}
                    </span>
                    <a href="javascript:" (click)="appUtil.showHideClampedContent($event)"
                        class="moreless hidden ml-2 p-button-danger" style="float:right;display:block;">More</a>
                </td>
                <td>
                    <span class="p-column-title">Status</span>
                    {{classification.status}}
                </td>
                <td style="text-align: center;">
                    <button type="button" pButton icon="pi pi-list"
                        (click)="setCurrentItem(classification); menu.toggle($event)"></button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="4">{{noRecordMessages[selectedTab]}}.</td>
            </tr>
        </ng-template>
    </p-table>

</ng-template>
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
<p-menu #menu [popup]="true" [model]="menuItems"></p-menu>
<add-classification [(show)]="classificationDialog" [type]="type" [(uuid)]="uuid" (parentFuntion)="fetchRecords()">
</add-classification>

<p-toast>
    <ng-template let-message pTemplate="message">
        <div>
            <h4 class="pi pi-times-circle" style="font-size: 1.2em"> {{message.summary}}</h4>
            <p>{{message.detail}}</p>
            <a href="javascript:void(0);" (click)="viewBuysides()" *ngIf="showOptionString === 'BUYSIDE' || showOptionString === 'BUY_SELL'">View Buysides</a>&nbsp;&nbsp;&nbsp;
            <a href="javascript:void(0);" (click)="viewSellsides()" *ngIf="showOptionString === 'SELLSIDE' || showOptionString === 'BUY_SELL'">View Sellsides</a>
        </div>
    </ng-template>
</p-toast>

<p-dialog [(visible)]="showMerge" header="Merge Classifications" styleClass="p-fluid" [style]="{width: '400px'}">
    <ng-template pTemplate="content">
        <form [formGroup]="mergeForm" #ngForm="ngForm">
            <div class="field">
                <label for="master">Master <span class="mandate">*</span></label>
                <p-autoComplete formControlName="master" [suggestions]="suggestionList"
                    (completeMethod)="searchItem($event,'master')" completeOnFocus="true" field="name" dataKey="uuid"
                    forceSelection="true" appendTo="body" [required]="true"
                    [ngClass]="{ 'ng-invalid ng-dirty': mf.master.touched && mf.master.errors }">
                </p-autoComplete>
            </div>
            <div class="field">
                <label for="source1">Source1 <span class="mandate">*</span></label>
                <p-autoComplete formControlName="source1" [suggestions]="suggestionList"
                    (completeMethod)="searchItem($event, 'source1')" completeOnFocus="true" field="name" dataKey="uuid"
                    forceSelection="true" appendTo="body" [required]="true"
                    [ngClass]="{ 'ng-invalid ng-dirty': mf.source1.touched && mf.source1.errors }">
                </p-autoComplete>
            </div>
            <div class="field">
                <label for="source2">Source 2</label>
                <p-autoComplete formControlName="source2" [suggestions]="suggestionList"
                    (completeMethod)="searchItem($event, 'source2')" completeOnFocus="true" field="name" dataKey="uuid"
                    forceSelection="true" appendTo="body">
                </p-autoComplete>
            </div>
        </form>
    </ng-template>
    <ng-template pTemplate="footer">
        <div *ngIf="clsSpinner">
            <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
                fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
        </div>
        <div *ngIf="clsSpinner == false">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
                (click)="showMerge = false;"></button>
            <button pButton pRipple label="Save" form="clform" icon="pi pi-check" (click)="mergeClassifications()"
                class="p-button-text"></button>
        </div>
    </ng-template>
</p-dialog>

<p-dialog [(visible)]="showMove" header="Move Classifications" styleClass="p-fluid" [style]="{width: '400px'}">
    <ng-template pTemplate="content">
        <form [formGroup]="moveForm" #ngForm="ngForm">
            <label style="font-weight:bold">Select Category</label>
            <div class="field col-12 md:col-12">    
                <p-radioButton [disabled]="classificationTypes[selectedTab] === 'CATEGORY_LEVEL1'"
                    styleClass="form-control p-mb-2" formControlName="category" name="category" value="CATEGORY_LEVEL1"
                    label="Category Level 1" labelStyleClass="pr-2">
                </p-radioButton><br><br>
                <p-radioButton [disabled]="classificationTypes[selectedTab] === 'CATEGORY_LEVEL2'"
                    styleClass="form-control p-mb-2" formControlName="category" name="category" value="CATEGORY_LEVEL2"
                    label="Category Level 2" labelStyleClass="pr-2">
                </p-radioButton><br><br>
                <p-radioButton [disabled]="classificationTypes[selectedTab] === 'CATEGORY_LEVEL3'"
                    styleClass="form-control p-mb-2" formControlName="category" name="category" value="CATEGORY_LEVEL3"
                    label="Category Level 3" labelStyleClass="pr-2">
                </p-radioButton><br><br>
                <p-radioButton [disabled]="classificationTypes[selectedTab] === 'VERTICAL'"
                    styleClass="form-control p-mb-2" formControlName="category" name="category" value="VERTICAL"
                    label="Vertical" labelStyleClass="pr-2">
                </p-radioButton><br><br>
                <p-radioButton [disabled]="classificationTypes[selectedTab] === 'ECOSYSTEM'"
                    styleClass="form-control p-mb-2" formControlName="category" name="category" value="ECOSYSTEM"
                    label="Ecosystem" labelStyleClass="pr-2">
                </p-radioButton>
            </div>
        </form>
    </ng-template>
    <ng-template pTemplate="footer">
        <div *ngIf="clsSpinner">
            <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
                fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
        </div>
        <div *ngIf="clsSpinner == false">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
                (click)="showMove = false; selectedClassifications = []; showOptionString = ''"></button>
            <button pButton pRipple label="Save" form="clform" icon="pi pi-check" (click)="moveClassifications()"
                class="p-button-text"></button>
        </div>
    </ng-template>
</p-dialog>