import { TbpColumnChooserModule } from './../_components/tbp-column-chooser/tbp-column-chooser.module';
import { InputNumberModule } from 'primeng/inputnumber';
import { UploadExcelModule } from './../upload-excel/upload-excel.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepsModule } from 'primeng/steps';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { FieldsetModule } from 'primeng/fieldset';
import { TableModule } from 'primeng/table';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ToolbarModule } from 'primeng/toolbar';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AddSellsideComponent } from './add.sellside.component';
import { SellsideRoutingModule } from './sellside-routing.module';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { ReactiveFormsModule } from '@angular/forms';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ViewSellsideComponent } from './view.sellside.component';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { MenuModule } from 'primeng/menu';
import { PanelModule } from 'primeng/panel';
import { TabViewModule } from 'primeng/tabview';
import { TaskModule } from './../task/task.module';
import { AccountPopupModule } from './../account-popup/account-popup.module';
import { DividerModule } from "primeng/divider";
import { NoteModule } from './../note/note.module';
import { TbpAutocompleteModule } from '../_components/tbp-autocomplete/tbp-autocomplete.module';
import { LookupAutocompleteModule } from '../_components/lookup/lookup.module';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { TooltipModule } from 'primeng/tooltip';
import { SellsideViewIncludeComponent } from './sellside-view-include.component';
import { SharedModule } from '../shared/shared.module';
import { SellsideProspectListComponent } from './sellside-prospect-list.component';
import { SellsideBlindProfileComponent } from './sellside-blind-profile.component';
import { InplaceModule } from 'primeng/inplace';
import { ImageModule } from 'primeng/image';
import { MessagesModule } from 'primeng/messages';
import { SellsideBlindProfileViewComponent } from './sellside-blind-profile-view.component';
import { SellsideListComponent } from './sellside-list.component';
import { TbpTableColumnModule } from '../_components/tbp-table-column/tbp-table-column.module';
import { TbpSearchModule } from '../_components/tbp-search/tbp-search.module';


@NgModule({
    declarations: [AddSellsideComponent, ViewSellsideComponent, SellsideViewIncludeComponent, SellsideProspectListComponent,
         SellsideBlindProfileComponent, SellsideBlindProfileViewComponent, SellsideListComponent],
    imports: [
        CommonModule,
        SellsideRoutingModule,
        FormsModule,
        StepsModule,
        ButtonModule,
        InputTextModule,
        RadioButtonModule,
        CheckboxModule,
        InputTextareaModule,
        MultiSelectModule,
        FieldsetModule,
        TableModule,
        OverlayPanelModule,
        ToolbarModule,
        AutoCompleteModule,
        DropdownModule,
        ReactiveFormsModule,
        ToastModule,
        ConfirmDialogModule,
        SplitButtonModule,
        ProgressSpinnerModule,
        CalendarModule,
        DialogModule,
        MenuModule,
        PanelModule,
        EditorModule,
        TabViewModule,
        TaskModule,
        AccountPopupModule,
        DividerModule,
        NoteModule,
        TbpAutocompleteModule,
        LookupAutocompleteModule,
        TooltipModule,
        MessagesModule,
        SharedModule,
        UploadExcelModule,
        InputNumberModule,
        TbpColumnChooserModule,
        InplaceModule,
        ImageModule,
        TbpSearchModule,
        TbpTableColumnModule
    ],
    providers: [
        { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
    ]
})
export class SellsideModule { }
