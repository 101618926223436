import { AccountService } from './../_services/account.service';
import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { OrganizationService } from '../organization/organization.service';
import { BuysideService } from './buyside.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppUtil, SearchHeler } from './../_helpers';
import { UntypedFormGroup,   UntypedFormBuilder, FormBuilder } from '@angular/forms';
import { ConfigurationService } from '../configuration/configuration.service';
import { Table } from 'primeng/table';
import { UsersService } from '../users/users.service';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { Location } from '@angular/common';
import { FieldInfo } from '../_models';
@Component({
  selector: 'buyside-view-include',
  templateUrl: './buyside.view.include.component.html',
  styleUrls: ['./buyside.view.include.component.scss']
})
export class BuysideViewIncludeComponent implements OnInit {
  @ViewChild('cform') formElement;
    form: UntypedFormGroup;
    @Input() model:any;
    lookupList: any = {  REVENUE: [], EBITDA: [], DOLLOR_VALUE_RANGE: [] };
    hasEquity:boolean = false;
    hasDebt:boolean = false;
    editMode: string | null = null;
    oldVal: any[] = [];
    oldValues: any;
    organizationResults: any;
    loading: boolean;
    debt: any[];
    equity:any[];
    pageSize: number;
    firstRow: number;
    sortField: string;
    sortOrder: number;
    fieldInfo: FieldInfo[];
    activeIndex: number;
    formsubmited: boolean = false;
    selectedEBITDA: string[] = [];
    selectedREV: string[] = [];
    selectedOrganization: string;
    classificationField: any;
    classificationList: any = {
      CATEGORY_LEVEL1: [], CATEGORY_LEVEL2: [], CATEGORY_LEVEL3: [],
      VERTICAL: [], ECOSYSTEM: []
  };
  tbpEmployees: any;
  helpList: any;
  contactResults: any;
  editingField: any ={};
  lookupList1: any = {  REVENUE: [], EBITDA: [], DOLLOR_VALUE_RANGE: [] };
  managementPreference: any[];
  
    
  constructor( 
    private organizationService : OrganizationService,
    public accountService:AccountService,
    private buysideService: BuysideService,
    private userService :UsersService,
    private route: ActivatedRoute,
    private appUtil:AppUtil,
    public searchHelper: SearchHeler,
    private _location: Location,
    private formBuilder: UntypedFormBuilder,
    private lookupService: ConfigurationService,
    private messageService: MessageService,) { 
      this.debt = [
        {value: 'A/R Financing'},
        {value: 'Junior Debt'},
        {value: 'Mezzanine Financing'},
        {value: 'Senior Debt'},
    ];
    this.equity = [
        {value: 'Growth Equity'},
        {value: 'Venture Capital'},
        {value: 'Angel Investment'},
        {value: 'Minority Recap'},
    ];
    this.managementPreference = [
      {value: 'Flexible'},
      {value: 'Management can exit'},
      {value: 'Management should stay'},
  ];
  }
 
  ngOnInit(): void {
    this.classificationList = this.route.snapshot.data['classificationList'];
    this.lookupList = this.route.snapshot.data['lookupList'];
    this.tbpEmployees = this.route.snapshot.data['tbpEmployees']; 
    this.loadBuySideForm(); 
    this.buysideService.getBuyside(this.route.snapshot.params.uuid).subscribe(data => {
      this.modelToForm(data as any);
    })
     
    
  }
  loadBuySideForm() {
    this.form = this.formBuilder.group({
        uuid: [''],
        isStrategicBuyout: [false],
        isPEBuyoutPlatform: [false],
        isPEBuyoutTuckin: [false],
        equityType: [],
        debtType: []
    });
}
  ngOnChanges(changes: SimpleChanges) {
    if (changes.model && (typeof changes.model.currentValue !== 'undefined') 
    && changes.model.currentValue.primaryObjectives!=null
    &&  (typeof changes.model.currentValue.primaryObjectives !== 'undefined')) {
    this.model = changes.model.currentValue;
    let isDebt = false;
    let debtVal = "";
    let sepearator = "";
    let debt = this.model.primaryObjectives.investment.debt;
    if(debt.arFinancing || debt.juniorDebt || debt.mezzanineFinancing || debt.seniorDebt)
    {
        this.hasDebt = true;
    }
    else
    {
        this.hasDebt = false;
    }
      let equity = this.model.primaryObjectives.investment.equity;
      if (equity.angelInvestment || equity.growthEquity || equity.minorityRecap || equity.ventureCapital) {
        this.hasEquity = true;
      }
      else {
        this.hasEquity = false;
      }
}
   
  }
  initEdit(field: string, data: any) {
    if ((field === 'organization') && data === null) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: "Account is Required", life: 3000 });
      return;
    }
    if (data === undefined) {
      this.oldValues = (data);
    } else {
      this.oldValues = JSON.parse(JSON.stringify(data));
    }
  }
  searchOrganizations(event) {
    this.organizationService.lookupOrganization(event.query).subscribe(data => {
        this.organizationResults = data as any;
    });
    this.userService.lookupContact(event.query).subscribe(data => {
      this.contactResults = data as any;
  });
}


splitAndCapitalize(field: string): string {
  return field
    .split(/(?=[A-Z])/)
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}
editField(oldVal: string, data: any){
  for (let key in this.editingField) {            
    this.editingField[key] = false;
  }
  this.initEdit(oldVal, data);
  this.editingField[oldVal] = true;
}
onOrganizationSelect() {
 
  this.model.organization = this.selectedOrganization;
}
updateField(fieldName: string, newValue: any){
    let dataToPost = { uuid: this.model.uuid };
    if(fieldName ==='classification' && newValue.level1.length === 0 ){
      this.messageService.add({ severity: 'error', summary: 'Error', detail: "Level1 is Required", life: 3000});
      return;
    } 
    else if((fieldName === 'screenTitle' || fieldName === 'externalHeadline'|| fieldName === 'organization') && newValue === "" ){
      const readableField = this.splitAndCapitalize(fieldName);
    this.messageService.add({ severity: 'error', summary: 'Error', detail: readableField+" is Required", life: 3000});
    return;  
  }
  if(this.oldValues === newValue){
    if(fieldName ==='companyDescription' || fieldName ==='transactionObjective'){
    this.closeDialog(fieldName);
    }
    else{
       this.closeDialog(fieldName);
    }
    return;
  }
  if(fieldName == 'contacts'){
   dataToPost[fieldName] = newValue.map(item => item.uuid);
    this.editingField[fieldName] = false;
  }
  else if(fieldName == 'targetEbitda' || fieldName == 'dollorValueRange' || fieldName == 'targetRevenue'){
    dataToPost[fieldName] = newValue.value;
  }
  else if (fieldName=='classification') {
    dataToPost[fieldName] = newValue ? newValue: null;
  }
  else if(fieldName=='organization'){
     dataToPost[fieldName] = newValue.uuid;
     this.editingField[fieldName] = false;
  }
  else{
    dataToPost[fieldName] = newValue;
  }
  this.model[fieldName] = newValue;
  this.buysideService.updateBuysideList([fieldName], dataToPost).subscribe(
    data => {
        this.loading = false;  
        if (fieldName === 'classification') {
          const relatedFields = ['Level1', 'Level2', 'Level3', 'Vertical/Industry', 'Ecosystem'];
          relatedFields.forEach(field => this.editingField[field] = false);
      } else {
          this.editingField[fieldName] = false;
      }
      this.messageService.add({ severity: 'success', summary: 'Successful', detail: `${this.formatFieldName(fieldName)} Updated Successfully`, life: 3000 });
        this.ngOnInit();
    })
}
formatFieldName(fieldName: string): string {

  const words = fieldName.split(/(?=[A-Z])/);
  return words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
} 
closeDialog(fieldName: string) {
  this.editingField[fieldName] = false;
  event.stopPropagation();
} 
get f() { return this.form.controls; }

onSelectionChange(field: string) {
  if(field === 'targetRevenue' || field === 'targetEbitda' || field === 'dollorValueRange'){
    this.updateField(field, this.model[field]);
  }
  else{
  this.updateField('classification', this.model?.classification);
}
}
getOutput( value: any) {
  if (value && typeof value === 'object') {
      return value.value;
  }
  return value;
}
modelToForm(model) {
  let debtTypeValues = [];
  if(model.primaryObjectives.investment.debt.arFinancing)
  {
      debtTypeValues.push(this.appUtil.findItemInList(this.debt, 'A/R Financing', 'value').value)
  }
  if(model.primaryObjectives.investment.debt.juniorDebt)
  {
      debtTypeValues.push(this.appUtil.findItemInList(this.debt, 'Junior Debt', 'value').value)
  }
  if(model.primaryObjectives.investment.debt.mezzanineFinancing)
  {
      debtTypeValues.push(this.appUtil.findItemInList(this.debt, 'Mezzanine Financing', 'value').value)
  }
  if(model.primaryObjectives.investment.debt.seniorDebt)
  {
      debtTypeValues.push(this.appUtil.findItemInList(this.debt, 'Senior Debt', 'value').value)
  }
  let equityTypeValues = [];
  if(model.primaryObjectives.investment.equity.growthEquity)
  {
      equityTypeValues.push(this.appUtil.findItemInList(this.equity, 'Growth Equity', 'value').value)
  }
  if(model.primaryObjectives.investment.equity.ventureCapital)
  {
      equityTypeValues.push(this.appUtil.findItemInList(this.equity, 'Venture Capital', 'value').value)
  }
  if(model.primaryObjectives.investment.equity.angelInvestment)
  {
      equityTypeValues.push(this.appUtil.findItemInList(this.equity, 'Angel Investment', 'value').value)
  }
  if(model.primaryObjectives.investment.equity.minorityRecap)
  {
      equityTypeValues.push(this.appUtil.findItemInList(this.equity, 'Minority Recap', 'value').value)
  }

  this.form.setValue({
      uuid: model.uuid,
      isPEBuyoutPlatform: model.primaryObjectives.peBuyout.platform,
      isPEBuyoutTuckin: model.primaryObjectives.peBuyout.tuckin,
      equityType: equityTypeValues,
      debtType: debtTypeValues,
      isStrategicBuyout: model.primaryObjectives.strategicBuyout
  });
}
validatePrimaryObjective(event: any) {
  let error = { 'message': 'Primary objective is required.' };
  if (this.f.isStrategicBuyout.value || this.f.isPEBuyoutPlatform.value || this.f.isPEBuyoutTuckin.value
      || (this.f.equityType.value !=null && this.f.equityType.value.length > 0)
      || (this.f.debtType.value !=null && this.f.debtType.value.length > 0))
  {
      error = null;
  }
  
  this.appUtil.setError(this.form, 'isStrategicBuyout', error);
  this.appUtil.setError(this.form, 'isPEBuyoutPlatform', error);
  this.appUtil.setError(this.form, 'isPEBuyoutTuckin', error);
  this.appUtil.setError(this.form, 'equityType', error);
  this.appUtil.setError(this.form, 'debtType', error);
  this.formsubmited = true;
}

getHelpText(key) {
  let test = this.helpList.filter(function (element) {
      return element.key == key;
  });
  if (test[0] != undefined)
      return test[0]['text'];
}
nextStep() {
  let hasError:boolean = false;
 
      this.validatePrimaryObjective(null);
      hasError = this.f.isStrategicBuyout.invalid|| this.f.isPEBuyoutPlatform.invalid
        || this.f.isPEBuyoutTuckin.invalid || this.f.debtType.invalid || this.f.equityType.invalid 

 

}
handleError(elemId) {
  this.messageService.add({ severity: 'error', summary: 'Form Errors', detail: "Please select at least one primary objective", life: 3000 });
  let invalidControl = this.formElement.nativeElement.querySelector('.ng-invalid');

  if (invalidControl) {
      invalidControl.focus();
  }
  else if (elemId != null) {
      invalidControl = this.formElement.nativeElement.querySelector('#' + elemId);
      if (invalidControl) {
          invalidControl.focus();
      }
  }
}
  prepareModel(fieldName: string, value: any) {
    this.nextStep();
    if (this.form.invalid) {
      this.handleError(null);
      return;
    }
    let arFinancing = this.appUtil.findItemInList(this.f.debtType.value, this.appUtil.findItemInList(this.debt, 'A/R Financing', 'value').value) ? true : false;
    let juniorDebt = this.appUtil.findItemInList(this.f.debtType.value, this.appUtil.findItemInList(this.debt, 'Junior Debt', 'value').value) ? true : false;
    let mezzanineFinancing = this.appUtil.findItemInList(this.f.debtType.value, this.appUtil.findItemInList(this.debt, 'Mezzanine Financing', 'value').value) ? true : false;
    let seniorDebt = this.appUtil.findItemInList(this.f.debtType.value, this.appUtil.findItemInList(this.debt, 'Senior Debt', 'value').value) ? true : false;
    let growthEquity = this.appUtil.findItemInList(this.f.equityType.value, this.appUtil.findItemInList(this.equity, 'Growth Equity', 'value').value) ? true : false;
    let ventureCapital = this.appUtil.findItemInList(this.f.equityType.value, this.appUtil.findItemInList(this.equity, 'Venture Capital', 'value').value) ? true : false;
    let angelInvestment = this.appUtil.findItemInList(this.f.equityType.value, this.appUtil.findItemInList(this.equity, 'Angel Investment', 'value').value) ? true : false;
    let minorityRecap = this.appUtil.findItemInList(this.f.equityType.value, this.appUtil.findItemInList(this.equity, 'Minority Recap', 'value').value) ? true : false;

    let debt = { arFinancing, juniorDebt, mezzanineFinancing, seniorDebt }
    let equity = { growthEquity, ventureCapital, angelInvestment, minorityRecap }
    let peBuyout = { tuckin: this.f.isPEBuyoutTuckin.value, platform: this.f.isPEBuyoutPlatform.value };

    let dataToPost: any = { uuid: this.model.uuid, primaryObjectives: { strategicBuyout: this.f.isStrategicBuyout.value, peBuyout, investment: { debt, equity } } };

    this.model = dataToPost;
    this.buysideService.updateBuysideList([fieldName], dataToPost).subscribe(
      data => {
        this.loading = false;
        this.editingField[fieldName] = false;
        window.location.reload();
      })

  }

  categories = [
    { label: 'Level1', value: 'CATEGORY_LEVEL1', model: 'level1' },
    { label: 'Level2', value: 'CATEGORY_LEVEL2', model: 'level2' },
    { label: 'Level3', value: 'CATEGORY_LEVEL3', model: 'level3' },
    { label: 'Vertical/Industry', value: 'VERTICAL', model: 'vertical' },
    { label: 'Ecosystem', value: 'ECOSYSTEM', model: 'ecosystem' }
  ];
}

