import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UserPopupService {

    constructor(private http: HttpClient) { }

    getContacts(uuid: string, searchParams: any) {
        return this.http.post(environment.apiUrl + '/organization/' + uuid + '/contacts', searchParams);
    }

    addContact(data) {
        return this.http.post(environment.apiUrl + '/contact', data);
    }

    private _listners = new Subject<any>();
    listen(): Observable<any> {
        return this._listners.asObservable();
    }

    filter(filterBy: string) {
        this._listners.next(filterBy);
    }

}
