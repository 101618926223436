import { Blindprofile } from './blind-profile';
import { User } from './user';
export class Engagement {

    uuid?: string;
    name?: string;
    startDate?: string;
    endDate?: string;
    actualCloseDate?: string;
    status?: string;
    engagementType?: string;
    progress?: string;
    nextMilestone?: string;
    client?: string;
    dealTeam?: string;
    blindProfile: Blindprofile;
    createByUser?: User;
    createdDate?: User;
    modifiedByUser?: User;
    lastModifiedDate?: User;
}