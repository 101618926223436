<div class="grid table-demo">
    <div class="col-12">
        <div class="card divider-card">
            <p-toolbar styleClass="headerSticky" *ngIf="user.status !== 'DELETED'">
                <ng-template pTemplate="left">
                </ng-template>
                <ng-container  >
                <ng-template pTemplate="right">
                    <button pButton pRipple label="Back" icon="pi pi-arrow-left" class="p-button-success mr-2" (click)="appUtil.return()"></button>
                   <div *ngIf="this.accountService.userValue.role==1" >
                    <button pButton pRipple label="Edit" icon="pi pi-pencil" class="p-button-success mr-2"
                      (click)="edit()"></button>
                    
                    <button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-success mr-2"
                      (click)="showDialog()" ></button>
                    
                    <button  pButton pRipple label="{{labelStatus}}" class="p-button-success mr-2"
                      (click)="changeStatus()" ></button>
                    </div>
                  </ng-template>
                </ng-container>
            </p-toolbar>
            <div class="p-fluid formgrid grid">
                <div class="field col-12">
                    <div class="grid mt-1">
                        <div class="col-12 md:col-6">
                            <div class="inline label">Created By:</div>
                             {{user.createByUser}} on {{user.createdDate | date:"MM-dd-yyyy HH:mm:ss"}}
                        </div>
                        <div class="col-12 md:col-6 text-right">
                            <div class="inline label">Modified By:</div>
                             {{user.modifiedByUser}} on {{user.lastModifiedDate | date:"MM-dd-yyyy HH:mm:ss"}}
                        </div>
                    </div>
                    <p-divider type="dashed"></p-divider>
                </div>
            </div>
            <h5>User Information </h5>
            <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-4">
                    <div class="grid mt-1 limit-width-large dashed-bottom">
                        <div class="col-12 md:col-4 label md:text-right">
                            First Name
                        </div>
                        <div class="field col-12 md:col-8 capitalize">
                            {{user.firstName}}
                        </div>
                    </div>
                </div>
                <div class="field col-12 md:col-4">
                    <div class="grid mt-1 limit-width-large dashed-bottom">
                        <div class="col-12 md:col-4 label md:text-right">
                            Last Name
                        </div>
                        <div class="field col-12 md:col-8 capitalize">
                            {{user.lastName}}
                        </div>
                    </div>
                </div>
                <div class="field col-12 md:col-4">
                    <div class="grid mt-1 limit-width-large dashed-bottom">
                        <div class="col-12 md:col-4 label md:text-right">
                            Email
                        </div>
                        <div class="field col-12 md:col-8 lowercase">
                            {{user.email}}
                        </div>
                    </div>
                </div>
                <div class="field col-12 md:col-4">
                    <div class="grid mt-1 limit-width-large dashed-bottom">
                        <div class="col-12 md:col-4 label md:text-right">
                            Phone
                        </div>
                        <div class="field col-12 md:col-8">
                            {{user.phone}}
                        </div>
                    </div>
                </div>
                <div class="field col-12 md:col-4">
                    <div class="grid mt-1 limit-width-large dashed-bottom">
                        <div class="col-12 md:col-4 label md:text-right">
                            Mobile No
                        </div>
                        <div class="field col-12 md:col-8">
                            {{user.mobile}}
                        </div>
                    </div>
                </div>
                <div class="field col-12 md:col-4">
                    <div class="grid mt-1 limit-width-large dashed-bottom">
                        <div class="col-12 md:col-4 label md:text-right">
                            Tiile
                        </div>
                        <div class="field col-12 md:col-8">
                            {{user.title}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<p-toast></p-toast>

<p-dialog header="Confirm" [(visible)]="visible" >
    <div class="p-fluid formgrid grid">
        <div class="field col-12">
            <div class="grid mt-1 limit-width-large">
                <div class="col-12 label">
                    New Contact Owner
                </div>
                <div class="field col-12">
                    <p-dropdown [options]="tbpUsers" optionLabel="name" [(ngModel)]="newContactOwner" [autoDisplayFirst]="false" [showClear]="true" appendTo="body"></p-dropdown>
                </div>
            </div>
        </div>
        <div class="field col-12">
            <div class="grid mt-1 limit-width-large">
                <div class="col-12 label">
                    New Account Owner
                </div>
                <div class="field col-12 capitalize">
                    <p-dropdown [options]="tbpUsers"  optionLabel="name" [(ngModel)]="newAccountOwner" [autoDisplayFirst]="false" [showClear]="true" appendTo="body"></p-dropdown>
                </div>
            </div>
        </div>
    </div>
    <div class="grid" style="margin-top: 5px;">
        <div class="col" style="text-align: right;">
            <button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-success mr-2" (click)="replaceOrDeleteUsers(uuid, newContactOwner, newAccountOwner)" [disabled]="false"></button>
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-success mr-2" (click)="onCancel()" [disabled]="false"></button>
        </div>
    </div>
</p-dialog>
