import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from '../app.breadcrumb.service';
import { AccountService } from '../_services';
import { HttpClient } from '@angular/common/http';
import { DataService } from '../_services/data.service';
import { MessageService, ConfirmationService } from 'primeng/api';
import { Engagement, UserOrganization, Organization } from '../_models';
import { EngagementService } from './engagement.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LazyLoadEvent, MenuItem } from 'primeng/api';
import { UsersService } from '../users/users.service';
import { OrganizationService } from '../organization/organization.service';
import { AppUtil } from '../_helpers';

@Component({
    selector: 'app-engagement.view',
    templateUrl: './engagement.view.component.html',
    styleUrls: ['./engagement.view.component.scss'],
    providers: [MessageService, ConfirmationService]
})
export class EngagementViewComponent implements OnInit {
    @ViewChild('cform') formElement;
    uuid: string;
    engagement: Engagement;

    contacts: UserOrganization[];
    selectedContacts: UserOrganization[] = [];
    contactLoading: boolean = true;
    contactTotalRecords: number = 0;
    contactTitle: string;
    contactDisplay: boolean = false;
    contactForm: UntypedFormGroup;
    cfSubmitted: boolean;
    contactDialog: boolean;
    contactResults: [];
    lebelName: string;

    masters: Organization[];
    selectedMasters: Organization[] = [];
    masterLoading: boolean = true;
    masterTotalRecords: number = 0;
    masterTitle: string;
    masterDisplay: boolean = false;
    masterForm: UntypedFormGroup;
    mfSubmitted: boolean;
    masterDialog: boolean;
    masterResults: [];
    currentMenuData: any;
    masterActionItems: MenuItem[];

    // Campaign  List
    campaigns: Engagement[];
    selectedCampaign: Engagement[] = [];
    campaignLoading: boolean = true;
    campaignsTotalRecords: number = 0;
    term: string;

    constructor(private organizationService: OrganizationService, private usersService: UsersService, private formBuilder: UntypedFormBuilder,
         private engagementService: EngagementService, private messageService: MessageService, private dataService: DataService,
          private http: HttpClient, private breadcrumbService: BreadcrumbService,private appUtil:AppUtil,
        private router: Router, private route: ActivatedRoute, public accountService: AccountService) {
        this.breadcrumbService.setItems([
            { label: ' View Engagement' }
        ]);
        this.term = '';
    }

    ngOnInit(): void {
        this.lebelName = "Create Blind Profile";
        this.loadContactForm();
        this.loadMasterForm();
        this.engagement = new Engagement;
        this.uuid = this.route.snapshot.params.uuid;
        this.engagementService.getEngagement(this.route.snapshot.params.uuid).subscribe(data => {
            this.engagement = data as any;
            if (this.engagement.blindProfile != null)
                this.lebelName = "Edit Blind Profile";
        });

        let message = this.dataService.getDataByKey('message');
        if (message != undefined) {
            setTimeout(() => { this.messageService.add({ severity: 'success', summary: 'Successful', detail: message, life: 3000 }); })
            this.dataService.setData('message', undefined);
        }


        this.currentMenuData = { id: '' };
        this.masterActionItems =
            [
                {
                    label: 'Pass',
                    command: (event) => { }
                },
                {
                    label: 'Send Email',
                    command: (event) => { }
                },
                {
                    label: 'Comments',
                    command: () => { }
                },
                {
                    label: 'Sign NDA',
                    command: () => { }
                },
                {
                    label: 'Download Signed NDA',
                    command: () => { }
                }
            ];

    }

    edit() {
        this.router.navigate(['/engagement/edit/' + this.engagement.uuid]);
    }

    searchParticipant(event) {
        this.usersService.lookupContact(event.query).subscribe(data => {
            this.contactResults = data as any;
        });
    }

    searchCompanies(event) {
        this.organizationService.lookupOrganization(event.query).subscribe(data => {
            this.masterResults = data as any;
        });
    }

    loadContacts(e: LazyLoadEvent = { first: 0, rows: 10, sortField: 'name', sortOrder: 1 }) {
        var searchParams = [];
        searchParams['pageNo'] = (e.first / e.rows);
        searchParams['pageSize'] = e.rows;
        if (e.sortField && e.sortField != '')
            searchParams['sortBy'] = e.sortField;

        searchParams['sortDir'] = e.sortOrder == 0 ? 'asc' : 'desc';
        if (!this.contactLoading)
            this.contactLoading = true;

        this.engagementService.getContact(this.uuid, searchParams).subscribe(
            data => {
                let response = data as any;
                this.contactTotalRecords = response.totalRecords;
                this.contacts = response.records;
                this.contactLoading = false;
            });
    }

    loadCampaigns(e: LazyLoadEvent = { first: 0, rows: 5, sortField: 'title', sortOrder: 1 }) {
        var searchParams = {};
        searchParams['page'] = (e.first / e.rows);
        searchParams['pageSize'] = e.rows;
        var sortBy = []
        var sortDir = e.sortOrder == 1 ? 'DESC' : 'ASC';
        if (e.sortField && e.sortField != '') {
            sortBy[sortBy.length] = { "property": e.sortField, "direction": sortDir };
        }
        searchParams['sortDetails'] = sortBy;

        if (this.term != '') {
            searchParams['query'] = this.term;
        }
        this.engagementService.getCampaign(this.uuid, searchParams).subscribe(
            data => {
                let response = data as any;
                this.campaignsTotalRecords = response.totalRecords;
                this.campaigns = response.records;
                this.campaignLoading = false;
            });
    }

    get cf() { return this.contactForm.controls; }

    loadContactForm() {
        this.contactForm = this.formBuilder.group({
            dealTeam: [null, Validators.required]
        })
    }

    newContact() {
        this.contactForm.reset();
        this.contactTitle = "New Deal Team";
        this.contactDialog = true;
    }

    hideDialogContact() {
        this.contactForm.reset();
        this.cfSubmitted = false;
        this.contactDialog = false;
    }
    saveContact() {
        this.cfSubmitted = true;
        if (this.contactForm.invalid) {
            this.handleError(null);
            return;
        }
        let dataToPost = this.cf.dealTeam.value;
        let apiToCall: any;
        apiToCall = this.engagementService.updateDealTeam(this.uuid, dataToPost);
        apiToCall.subscribe(
            data => {
                this.contactDialog = false;
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: "Deal Team Updated", life: 3000 });
                this.loadContacts();
                this.cfSubmitted = false;
            },
            error => {
                let errors = error.error;
                if (errors.code && errors.code == 'VALIDATION_ERROR') {
                    for (var i = 0; i < errors.subErrors.length; i++) {
                        this.cf[errors.subErrors[i].field].setErrors({ 'message': errors.subErrors[i].message });
                    }
                    this.handleError(errors.subErrors[0].field);
                }
            }
        );
    }

    handleError(elemId) {
        this.messageService.add({ severity: 'error', summary: 'Form Errors', detail: "Form has errors. Please fix them first", life: 3000 });
        let invalidControl = this.formElement.nativeElement.querySelector('.ng-invalid');
        if (invalidControl) {
            invalidControl.focus();
        }
        else if (elemId != null) {
            invalidControl = this.formElement.nativeElement.querySelector('#' + elemId);
            if (invalidControl) {
                invalidControl.focus();
            }
        }
    }

    blindProfile() {
        if (this.engagement.blindProfile == null)
            this.router.navigate(['/engagement/' + this.engagement.uuid + '/blind-profile/new']);
        else
            this.router.navigate(['/engagement/' + this.engagement.uuid + '/blind-profile/edit/' + this.engagement['blindProfile']['uuid']]);
    }

    loadMasters(e: LazyLoadEvent = { first: 0, rows: 10, sortField: 'name', sortOrder: 1 }) {
        var searchParams = [];
        searchParams['pageNo'] = (e.first / e.rows);
        searchParams['pageSize'] = e.rows;
        if (e.sortField && e.sortField != '')
            searchParams['sortBy'] = e.sortField;

        searchParams['sortDir'] = e.sortOrder == 0 ? 'asc' : 'desc';
        if (!this.contactLoading)
            this.masterLoading = true;

        this.engagementService.getMasterProspect(this.uuid, searchParams).subscribe(
            data => {
                let response = data as any;
                this.masterTotalRecords = response.totalRecords;
                this.masters = response.records;
                this.masterLoading = false;
            });
    }

    get mf() { return this.masterForm.controls; }

    loadMasterForm() {
        this.masterForm = this.formBuilder.group({
            organization: [null, Validators.required]
        })
    }

    newMaster() {
        this.masterForm.reset();
        this.masterTitle = "New Master Prospects";
        this.masterDialog = true;
    }

    hideDialogMaster() {
        this.masterForm.reset();
        this.mfSubmitted = false;
        this.masterDialog = false;
    }

    saveMaster() {
        this.mfSubmitted = true;
        if (this.masterForm.invalid) {
            this.handleError(null);
            return;
        }
        let dataToPost = this.mf.organization.value;
        let apiToCall: any;
        apiToCall = this.engagementService.masterProspects(this.uuid, dataToPost);
        apiToCall.subscribe(
            data => {
                this.masterDialog = false;
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: "Master Prospects Updated", life: 3000 });
                this.loadMasters();
                this.mfSubmitted = false;
            },
            error => {
                let errors = error.error;
                if (errors.code && errors.code == 'VALIDATION_ERROR') {
                    for (var i = 0; i < errors.subErrors.length; i++) {
                        this.mf[errors.subErrors[i].field].setErrors({ 'message': errors.subErrors[i].message });
                    }
                    this.handleError(errors.subErrors[0].field);
                }
            }
        );
    }

    setCurrentItem(master) {
        this.currentMenuData = master;
    }

}
