import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout.component';
import { LoginComponent } from './login.component';
import { RegisterComponent } from './register.component';
import { VerifyComponent } from './verify.component';
import { ForgotpasswordComponent } from './forgotpassword.component';
import { ResetpasswordComponent } from './resetpassword.component';


const routes: Routes = [
    {
        path: '', component: LayoutComponent,
        children: [
            // { path: 'login', component: LoginComponent },
            // { path: 'logout', component: RegisterComponent },
            // { path: 'register', component: RegisterComponent },
            // { path: 'activate/:actionUUID', component: VerifyComponent },
            // { path: 'forgot-password', component: ForgotpasswordComponent },
            // { path: 'reset-password/:actionUUID', component: ResetpasswordComponent },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AccountRoutingModule { }