import { Pipe, PipeTransform } from '@angular/core';
import { TitleCasePipe as AngularTitleCasePipe } from '@angular/common';
@Pipe({ name: 'dealPipe' })
export class DealPipe implements PipeTransform {

    angularTitleCase = new AngularTitleCasePipe();

    transform(value: string): string {

        if (value != '' && value != null && value != undefined) {
            let inputString = value.replace("__", "/");
            inputString = inputString.replace("_", '/');
            inputString = inputString.replace("/", ' ');
            return this.angularTitleCase.transform(inputString);
        }

    }
}