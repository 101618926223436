<p-panel [toggleable]="true" [collapsed]="false" expandIcon="pi pi-chevron-left" collapseIcon="pi pi-chevron-down" *ngIf="!showListing" >
    <p-header>
        <span class="p-panel-title text-teal">
            <i class="pi pi-bars"></i> Task

        </span>
    </p-header>
    
    <ng-template pTemplate="icons" >
        <button *ngIf="parent.status !== 'DELETED'" pButton class="p-panel-header-icon p-link" (click)="new()" label="" icon="pi pi-plus"></button>
    </ng-template>
    
    <p-table [autoLayout]="true" [value]="tasks" [(selection)]="selectedTasks" dataKey="userId" [lazy]="true"
        (onLazyLoad)="search()" [lazyLoadOnInit]="false" 
        styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm" [rowHover]="true"
        [rows]="5" [paginator]="false" [loading]="loading" [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [totalRecords]="totalRecords"
        [alwaysShowPaginator]="false">
        <ng-template pTemplate="header">
            <tr>
                <th>Subject</th>
                <th>Priority</th>
                <th>Status</th>
                <th>Modified By</th>
                <th>Completion Date</th>
                <th>Action</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-task >
            <tr>
                <td class="wrap"><ng-container *ngIf="parent.status !== 'DELETED'; else deletedStatus">
               <!-- // <td class="wrap"><ng-container> -->
                    <a href="javascript:void(0)" (click)="edit(task)">{{ task.subject }}</a>
                </ng-container>
                <ng-template #deletedStatus>
                    {{ task.subject }}
                </ng-template>
            </td>
                <td class="wrap">{{task.priority | titlecase}}</td>
                <!-- <td class="wrap">{{task.status.toString().replace('_',' ') | titlecase}}</td> -->
                <td class="wrap">{{task.status == null ? "" : task.status.toString().replace('_',' ') | titlecase}}</td>
                <td class="wrap">{{task.modifiedByUser}}</td>
                <td class="wrap">{{ task.completionDate | date:'MM/dd/yyyy' }}</td>
                <td>
                    <button  pButton pRipple icon="pi pi-pencil" class="p-button-success mr-2"
                        (click)="edit(task)"></button>
                    <!--<button pButton pRipple [label]="Delete" icon="pi pi-trash" class="p-button-danger  mr-2" ></button> -->
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="6">No tasks have been created yet.</td>
            </tr>
        </ng-template>
        <ng-template pTemplate="footer">
            <tr *ngIf="totalRecords>0">
                <td colspan="6" class="text-right"><a href="./tasks/{{parentUuid}}/{{taskType}}">View All</a></td>
            </tr>
        </ng-template>
    </p-table>
</p-panel>
<div class="grid table-demo" *ngIf="showListing">
    <div class="col-12">
        <div class="card">
            <p-toolbar styleClass="headerSticky" style="display: flex; justify-content: space-between;">
                <div class="p-toolbar-group-left" style="display: flex;">
                    <div #srch>
                        <span class="p-input-icon-left p-input-icon-right">
                            <i class="pi pi-search"></i>
                            <input [(ngModel)]="term" pInputText type="text" placeholder="Search" class="table-search"
                                (keyup.enter)="search()" />
                            <i class="pi pi-bars"></i>
                        </span>
                    </div>
                </div>
                <div class="p-toolbar-group-right" style="display: flex;"  > 
                    <p-dropdown  class="p-button-success mr-2 mb-3" [options]="tbpEmployees"
                 optionLabel="displayName" tooltipPosition="top" [filter]="true"    [(ngModel)]="selectedUser" [showClear]="true" [disabled]="disable" [autoDisplayFirst]="false"   (onChange)="changeUser($event)"   >
                  </p-dropdown>
                    <p-dropdown   class="p-button-success mr-2 mb-3" [(ngModel)]="selectedType" tooltipPosition="top" [autoDisplayFirst]="false" 
                    [options]="quickFilterOptions"  optionLabel="label"  (onChange)="changeUser($event)">     
                </p-dropdown>
                     <button pButton pRipple label="Back" icon="pi pi-arrow-left" class="p-button-success mr-2 mb-3" (click)="appUtil.return()"></button>
                </div>
            </p-toolbar>
            &nbsp;&nbsp;
            <p-table [autoLayout]="true" [value]="tasks" [(selection)]="selectedTasks" dataKey="userId" [lazy]="true"
                (onLazyLoad)="search()" [lazyLoadOnInit]="false"
                styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm" [rowHover]="true"
                [paginator]="ture" [loading]="loading" [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [totalRecords]="totalRecords"
                [alwaysShowPaginator]="true">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Subject</th>
                        <th>Priority</th>
                        <th>Status</th>
                        <th>Contacts</th>
                        <th>Type</th>
                        <th>Assigned To</th>
                        <th>Due Date</th>
                        <th>Action</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-task  >
                    <tr>
                        <td class="wrap"><ng-container >
                            <a href="javascript:void(0)" (click)="edit(task)" [innerHTML]="task.subject | highlight:term"></a>
                        </ng-container>
                        <ng-template #deletedStatus>
                            {{ task.subject }}
                        </ng-template></td>
                        <td class="wrap">{{task.priority | titlecase}}</td>
                        <!-- <td class="wrap">{{task.status.toString().replace('_',' ') | titlecase}}</td> -->
                        <td class="wrap">{{task.status == null ? "" : task.status.toString().replace('_',' ') | titlecase}}</td>
                        <td class="wrap"><a [routerLink]="['/contact', task.contacts !=null ?  task.contacts[0].uuid: null]">{{task.contacts !=null ? task.contacts[0].name: null}}</a></td> 
                        <td class="wrap"> {{task.relatedType}}</td>
                        <td class="wrap"> {{task.assignedTo == null ? "":task.assignedTo.name}}</td>
                        <td class="wrap">{{ task.dueDate | date:'MM/dd/yyyy' }}</td>
                        <td>
                           <button  pButton pRipple icon="pi pi-pencil" class="p-button-success mr-2"
                            (click)="edit(task)"></button>
                                
                            <!--<button pButton pRipple [label]="Delete" icon="pi pi-trash" class="p-button-danger  mr-2" ></button> -->
                        </td>
                    </tr>
                </ng-template>
            
                
            </p-table>
        </div>
    </div>
</div>
<!-- <p-menu #searchmenu [popup]="true" [model]="searchMenuItems"></p-menu> -->
<task-edit [(show)]="showForm" taskType="{{taskType}}" (afterSave)="search()" [parentUuid]="parentUuid"
    [parent]="parent" [(taskUuid)]="uuid" [contact]="contact"></task-edit>