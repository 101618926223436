import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Product } from '../_models/product';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class OrganizationService {
    addBuysideSelside(uuid: string, buysideIds: any[], sellsideIds: any[]) {
        return this.http.post(environment.apiUrl + '/organization/' + uuid + '/screens',
            { "buysides": buysideIds, "sellsides": sellsideIds });
    }
    constructor(private http: HttpClient) { }

    searchOrganization(searchParams: any) {
        return this.http.post(environment.apiUrl + '/organizations', searchParams);
    }

    addOrganization(data) {
        return this.http.post(environment.apiUrl + '/organization', data);
    }

    updateOrganization(data) {
        return this.http.put(environment.apiUrl + '/organization/' + data.uuid, data);
    }

    getOrganization(uuid: string) {
        return this.http.get(environment.apiUrl + '/organization/' + uuid);
    }

    lookupOrganization(query) {
        return this.http.get(environment.apiUrl + '/organizations/lookup', { params: { query } });
    }

    getContacts(uuid: string, searchParams: any) {
        return this.http.post(environment.apiUrl + '/organization/' + uuid + '/contacts', searchParams);
    }

    getSpotlights(uuid: string, searchParams: any) {
        return this.http.post(environment.apiUrl + '/organization/' + uuid + '/spotlights', searchParams);
    }

    getDeals(uuid: string, searchParams: any) {
        return this.http.post(environment.apiUrl + '/organization/' + uuid + '/deals', searchParams);
    }

    getBuySides(uuid: string, searchParams: any) {
        return this.http.post(environment.apiUrl + '/organization/' + uuid + '/buysides', searchParams);
    }

    getSellSides(uuid: string, searchParams: any) {
        return this.http.post(environment.apiUrl + '/organization/' + uuid + '/sellsides', searchParams);
    }

    getEmailCampaigns(uuid: string, searchParams: any) {
        return this.http.post(environment.apiUrl + '/organization/' + uuid + '/email-campaigns', searchParams);
    }

    getTasks(uuid: string, searchParams: any) {
        return this.http.get(environment.apiUrl + '/organization/' + uuid + '/tasks', { params: searchParams });
    }

    getParents(uuid: string, searchParams: any) {
        return this.http.post(environment.apiUrl + '/organization/' + uuid + '/suboffices', searchParams);
    }

    getInvestors(uuid: string, searchParams: any) {
        return this.http.post(environment.apiUrl + '/organization/' + uuid + '/investors', searchParams);
    }

    getPortfolioCompanies(uuid: string, searchParams: any) {
        return this.http.post(environment.apiUrl + '/organization/' + uuid + '/portfolio-companies', searchParams);
    }

    addAddress(data) {
        return this.http.post(environment.apiUrl + '/organization/address', data);
    }

    updateAddress(data) {
        return this.http.put(environment.apiUrl + '/organization/address/' + data.uuid, data);
    }

    getAllAddress(uuid: string) {
        return this.http.get(environment.apiUrl + '/organization/' + uuid + '/address');
    }
    getAssociateContact(uuid: string, data) {
        return this.http.post(environment.apiUrl + '/organization/' + uuid + '/related-contacts', data);
    }

    getRelatedContact(uuid: string) {
        return this.http.get(environment.apiUrl + '/organization/' + uuid + '/related-contacts');
    }

    private _listners = new Subject<any>();

    listen(): Observable<any> {
        return this._listners.asObservable();
    }

    refershTable(filterBy: string) {
        this._listners.next(filterBy);
    }

    mergeOrganizations(master: string, source1: string, source2: string, data: any) {
        let url = '/organization/' + master + "/merge";
        if (source1) {
            url = url + "/" + source1;
        }
        if (source2) {
            url = url + "/" + source2;
        }
        return this.http.post(environment.apiUrl + url, data);
    }

    updateOrganizationList(fieldsToBeUpdated: string[], data: any) {
        return this.http.patch(environment.apiUrl + '/organizations/' + data.uuid,
            { "fieldsToBeUpdated": fieldsToBeUpdated, "model": data });
    }
    getRelatedContactForOrganization(uuid: string) {
        return this.http.get(environment.apiUrl + '/organization/' + uuid + '/associated-contacts');
    }
}
