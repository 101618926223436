import { AccountService } from './../_services/account.service';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, SimpleChanges } from '@angular/core';
import { AccountPopupService } from './account-popup.service';
import { AppUtil } from './../_helpers';
import { ConfigurationService } from '../configuration/configuration.service';
import { UsersService } from '../users/users.service';

@Component({
    selector: 'account-popup',
    templateUrl: './account-popup.add.component.html',
    styleUrls: ['./account-popup.add.component.scss']
})
export class AccountPopupAddComponent implements OnInit {


    @Input() show: boolean = false;
    @Input() type: string = null;
    @Output() showChange = new EventEmitter<boolean>();
    @Input() closeAfterSave: boolean = true;
    @Output() afterSave = new EventEmitter<any>();
    @ViewChild('accountform') formElement;
    @Output() private onFormGroupChange = new EventEmitter<any>();
    title: string = "New Account";
    @Input() inputTextvalue: string;
    form: UntypedFormGroup;
    submitted: boolean = false;
    formInitialValue: any = null;
    lookupList: any = {
        ACCOUNT_SOURCE: [], REVENUE: [], ORG_TYPE_INVESTOR: [], No_OF_EMPLOYEES: [],
        ORG_TYPE_LENDER: [], ORG_TYPE_OTHER: [], TAG: []
    };
    tbpEmployees = [];
    constructor(private route: ActivatedRoute,
        private messageService: MessageService,
        private formBuilder: UntypedFormBuilder,
        private accountPopupService: AccountPopupService,
        private accountService: AccountService,
        private lookupService: ConfigurationService,
        private tbpUser: UsersService,
        private appUtil:AppUtil
    ) {
        this.form = this.formBuilder.group({
            uuid: [''],
            name: ['', [Validators.required]],
            website: [null, [Validators.pattern, Validators.pattern(this.appUtil.urlPattern)]],
            accountOwner: [this.accountService.userValue.uuid],
            leadSource: [''],
            revenue: [''],
            numberOfEmployee: [''],
            linkedinPageId: [''],
            capitaliqId: ['']
        });
        this.formInitialValue = this.form.value;
    }

    ngOnInit(): void {

        this.lookupList = this.route.snapshot.data['lookupList'];
        this.tbpEmployees = this.route.snapshot.data['tbpEmployees'];
        if (this.lookupList == undefined || this.tbpEmployees == undefined) {
            this.tbpUser.getTBPEmployees().subscribe(
                data => {
                    this.tbpEmployees = data as any;
                });

            this.lookupService.getLookupValues('all').subscribe(
                data => {
                    this.lookupList =data;
                });
        }

    }

    ngOnChanges(changes: SimpleChanges) {
        this.form.reset(this.formInitialValue);
        this.f.name.setValue(this.inputTextvalue);
    }

    get f() { return this.form.controls; }

    handleError(elemId) {
        this.messageService.add({ severity: 'error', summary: 'Form Errors', detail: "Form has errors. Please fix them first", life: 3000 });
        let invalidControl = this.formElement.nativeElement.querySelector('.ng-invalid');
        if (invalidControl) {
            invalidControl.focus();
        }
        else if (elemId != null) {
            invalidControl = this.formElement.nativeElement.querySelector('#' + elemId);
            if (invalidControl) {
                invalidControl.focus();
            }
        }
    }

    hide() {
        this.form.reset(this.formInitialValue);
        this.submitted = false;
        this.show = false;
        this.showChange.emit(false);
    }

    save() {
        this.submitted = true;
        if (this.form.invalid) {
            this.handleError(null);
            return;
        }
        let { uuid, name, website, companyType } = this.form.value;
        let dataToPost: any = { uuid, name, website, companyType };
        if (this.f.accountOwner.value) {
            dataToPost.accountOwner = this.appUtil.getObject(this.tbpEmployees, this.f.accountOwner.value);
        }
        if (this.f.leadSource.value) {
            dataToPost.leadSource = this.f.leadSource.value;
        }
        if (this.f.numberOfEmployee.value) {
            dataToPost.numberOfEmployee = this.f.numberOfEmployee.value;
        }
        if (this.f.revenue.value) {
            dataToPost.revenue = this.f.revenue.value;
        }
        if(this.f.linkedinPageId.value) {
            dataToPost.linkedinPageId = this.f.linkedinPageId.value;
        }
        if(this.f.capitaliqId.value) {
            dataToPost.capitaliqId = this.f.capitaliqId.value;
        }
        if (this.type == "deal" || this.type == "spotlight") {
            dataToPost['status'] = "PENDING_VERIFICATION";
        }
        dataToPost.companyType = 'OPERATING_COMPANY';
        let apiToCall: any;
        apiToCall = this.accountPopupService.addOrganization(dataToPost);
        apiToCall.subscribe(
            data => {
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: "Save Successfully", life: 3000 });
                this.submitted = false;
                this.onFormGroupChange.emit(data);
                this.afterSave.emit(data);
                if (this.closeAfterSave) {
                    this.hide();
                }
            },
            error => {
                let errors = error.error;
                if (errors.code && errors.code == 'VALIDATION_ERROR') {
                    for (var i = 0; i < errors.subErrors.length; i++) {
                        this.f[errors.subErrors[i].field].setErrors({ 'message': errors.subErrors[i].message });
                    }
                    this.handleError(errors.subErrors[0].field);
                }
            }
        );


    }

}


