<div>
    <form [formGroup]="form" #cform>
        <div class="card">
            <div class="formgrid grid headerSticky">
                <div class="field col-12 md:col-12 text-right" *ngIf="loading == false">
                    <button pButton label="Save" icon="pi pi-check" iconPos="left" class="mr-2"
                        (click)="save()"></button>
                    <p-button label="Cancel" icon="pi pi-times" class="p-button-help mr-2" (click)="cancel()">
                    </p-button>
                    <p-button label="Preview" icon="pi pi-print" iconPos="left" class="p-button-success mr-2"
                        (click)="preview()"></p-button>
                </div>
                <div class="field col-12 md:col-12 text-right" *ngIf="loading">
                    <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner"
                        strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                </div>
            </div>
            <h5 class="custom-class">Create Spotlights </h5>
            <div class="p-fluid  grid">
                <div class="field col-12 md:col-4">
                    <span class="p-float-label">
                        <input id="title" formControlName="title" type="text" maxlength="150" pInputText
                            [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.title.errors }" pTooltip="{{getHelpText('Title')}}" tooltipPosition="top">
                        <label for="Title">Title <span class="mandate">*</span> <span
                                class="p-float-label"></span></label>
                    </span>
                    <span class="p-error" *ngIf="submitted && f.title.invalid && f.title.hasError('required')">Title is
                        required.</span>
                    <span class="p-error"
                        *ngIf="submitted && f.title.invalid && f.title.hasError('message')">{{f.title.errors.message}}</span>
                </div>
                <div class="field col-12 md:col-4">
                    <span class="p-float-label">

                        <p-calendar [showIcon]="true" dateFormat="mm-dd-yy" formControlName="spotlightDate"
                            inputId="icon" [readonlyInput]="true"
                            [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.spotlightDate.errors }" pTooltip="{{getHelpText('Spotlight Date')}}" tooltipPosition="top"></p-calendar>
                        <label for="spotlightDate">Spotlight Date <span class="mandate">*</span> </label>
                    </span>
                    <span class="p-error"
                        *ngIf="submitted && f.spotlightDate.invalid && f.spotlightDate.hasError('required')">Spotlight
                        is required.</span>
                    <span class="p-error"
                        *ngIf="submitted && f.spotlightDate.invalid && f.spotlightDate.hasError('message')">{{f.spotlightDate.errors.message}}</span>

                </div>

                <div class="field col-12 md:col-4">
                    <span class="p-float-label">
                        <p-dropdown [options]="status" styleClass="form-control" formControlName="status" name="status"
                            optionLabel="name" optionValue="code" pTooltip="{{getHelpText('Status')}}" tooltipPosition="top">
                        </p-dropdown>
                        <label for="status">Status <span class="mandate">*</span></label>
                    </span>
                    <span class="p-error" *ngIf="submitted && f.status.invalid && f.title.hasError('required')">Status
                        is required.</span>
                    <span class="p-error"
                        *ngIf="submitted && f.status.invalid && f.title.hasError('message')">{{f.status.errors.message}}</span>
                </div>
                <div class="field col-12 md:col-4">
                    <span class="p-float-label">
                        <input id="urlKey" formControlName="urlKey" type="text" maxlength="150" pInputText
                            [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.urlKey.errors }" pTooltip="{{('Url Key')}}" tooltipPosition="top">
                        <label for="urlKey">Url Key</label>
                    </span>
                    <span class="p-error" *ngIf="submitted && f.urlKey.invalid && f.urlKey.hasError('required')">Url Key
                        is required.</span>
                    <span class="p-error"
                        *ngIf="submitted && f.urlKey.invalid && f.urlKey.hasError('message')">{{f.urlKey.errors.message}}</span>
                    <span class="p-error" *ngIf="submitted && f.urlKey.invalid && f.urlKey.hasError('pattern')">UrlKey
                        is invalid</span>
                </div>
                <div class="field col-12 md:col-4">
                    <span class="p-fluid">
                        <span class="p-float-label">
                            <tbp-autocomplete [formControl]="f.organizations" [items]="" [type]="'Account'"
                                [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.organizations.errors }" pTooltip="{{getHelpText('Companies Involved')}}" tooltipPosition="top">
                            </tbp-autocomplete>
                            <label for="organizations">Companies Involved <span class="mandate">*</span></label>
                        </span>
                        <span class="p-error"
                            *ngIf="submitted && f.organizations.invalid && f.organizations.hasError('required')">Companies
                            Involved is required.</span>
                        <span class="p-error"
                            *ngIf="submitted && f.organizations.invalid && f.organizations.hasError('message')">{{f.organizations.errors.message}}</span>
                    </span>
                </div>
                <div class="field col-12 md:col-4">
                    <span class="p-float-label">
                        <input id="metaKeywords" formControlName="metaKeywords" type="text" pInputText
                            [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.metaKeywords.errors }" pTooltip="{{('Meta Keywords')}}" tooltipPosition="top">
                        <label for="metaKeywords">Meta Keywords</label>
                    </span>
                    <span class="p-error"
                        *ngIf="submitted && f.metaKeywords.invalid && f.metaKeywords.hasError('required')">Meta Keywords
                        is required.</span>
                    <span class="p-error"
                        *ngIf="submitted && f.metaKeywords.invalid && f.metaKeywords.hasError('message')">{{f.metaKeywords.errors.message}}</span>
                </div>
                <div class="field col-12 md:col-4">
                    <span class="p-float-label">
                        <input id="title" formControlName="metaDescription" type="text" pInputText
                            [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.metaDescription.errors }" pTooltip="{{('Meta Description')}}" tooltipPosition="top">
                        <label for="Title">Meta Description </label>
                    </span>
                    <span class="p-error"
                        *ngIf="submitted && f.metaDescription.invalid && f.metaDescription.hasError('required')">Meta
                        Description is required.</span>
                    <span class="p-error"
                        *ngIf="submitted && f.metaDescription.invalid && f.metaDescription.hasError('message')">{{f.metaDescription.errors.message}}</span>
                </div>
                <div class="field col-12 md:col-4 ">
                    <button type="button" (click)="lookupMedia('left')" pButton pRipple label="Left Logo"
                        class="media-selector "></button>
                    <span class="p-error block"
                        *ngIf="submitted && f.leftLogo.invalid && f.leftLogo.hasError('required')">Left
                        Logo is required.</span>
                    <span class="p-error block"
                        *ngIf="submitted && f.leftLogo.invalid && f.leftLogo.hasError('message')">{{f.leftLogo.errors.message}}</span>
                    <img *ngIf="leftLogoUrl != null && leftLogoUrl !=''" src="{{leftLogoUrl}}" style="height: 60px;" />

                </div>
                <div class="field col-12 md:col-4">
                    <button type="button" (click)="lookupMedia('right')" pButton pRipple label="Right Logo"
                        class="media-selector"></button>
                    <span class="p-error block"
                        *ngIf="submitted && f.rightLogo.invalid && f.rightLogo.hasError('required')">Right
                        Logo is required.</span>
                    <span class="p-error block"
                        *ngIf="submitted && f.rightLogo.invalid && f.rightLogo.hasError('message')">{{f.rightLogo.errors.message}}</span>
                    <img *ngIf="rightLogoUrl != null && rightLogoUrl !=''" src="{{rightLogoUrl}}"
                        style="height: 60px;" />
                </div>
            </div>


            <h5 class="custom-class" style="height: 100%;">Content <span class="mandate">*</span></h5>
            <div class="grid">
                <div class="field col-12" style="max-width:980px">
                    <div class="p-mb-1">
                        <button type="button" (click)="lookupMedia('other')" pButton pRipple label="Insert Image" class="media-selector"></button>
                        <button type="button" (click)="f.content.setValue('')" pButton pRipple label="Clear Content" class="media-selector"></button>
                        <button type="button" (click)="f.content.setValue(sampleContent)" pButton pRipple label="Reset Content" class="media-selector"></button>
                    </div>
                    <span class="p-error block"
                        *ngIf="submitted && f.content.invalid && f.content.hasError('required')">Content is
                        required.</span>
                    <span class="p-error block"
                        *ngIf="submitted && f.content.invalid && f.content.hasError('message')">{{f.content.errors.message}}</span>
                    <editor #editor formControlName="content" [init]="getInitData()" [required]="true" pTooltip="{{getHelpText('Content')}}" tooltipPosition="top"></editor>


                </div>
            </div>
            <!-- <div class="p-fluid  grid">
                <div class="field col-12">
                    <p-panel [toggleable]="true" [collapsed]="false" expandIcon="pi pi-chevron-left"
                        collapseIcon="pi pi-chevron-down">
                        <p-header>
                            <span class="p-panel-title text-teal">
                                <i class="pi pi-bars"></i> Associate Deals
                            </span>
                        </p-header>
                        <ng-template pTemplate="icons">
                            <p-button class="p-panel-header-icon p-link" (click)="addDeal()" label="" icon="pi pi-plus">
                            </p-button>
                        </ng-template>
                        <p-table sortField="dealDate" sortOrder="-1" [value]="deals" [autoLayout]="true"
                            styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Primary Deal</th>
                                    <th>Date</th>
                                    <th>Buyers/Investors</th>
                                    <th>Target/Issuer</th>
                                    <th>Deal Type</th>
                                    <th>Transaction Value ($m)</th>
                                    <th>EV/Revenue ($m)</th>
                                    <th>EV/EBITDA</th>
                                    <th>Level1</th>
                                    <th>Level2</th>
                                    <th>Level3</th>
                                    <th>Vertical/Industry</th>
                                    <th>Ecosystem</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-deal>
                                <tr>
                                    <td>
                                        <p-radioButton formControlName="primaryDeal" value="{{deal.uuid}}">
                                        </p-radioButton>
                                    </td>
                                    <td class="wrap" style="width: 7rem"><a
                                            [routerLink]="['/deal/', deal.uuid]">{{deal.dealDate |
                                            date:'MM-dd-yyyy'}}</a></td>
                                    <td class="wrap">
                                        <span class="limitedText limitText">
                                            <span
                                                *ngFor="let organization of deal.buyerOrganizations; let last = last;"><a
                                                    [routerLink]="['/organization/', organization.uuid]">{{organization.name}}</a>{{last?'':';
                                                '}}</span>
                                        </span>
                                        <a href="javascript:" (click)="appUtil.showHideClampedContent($event)"
                                            class="moreless hidden ml-2 p-button-danger"
                                            style="float:right;display:block;">More</a>
                                    </td>
                                    <td class="wrap"><a *ngIf="deal.targetOrganization"
                                            [routerLink]="['/organization/', deal.targetOrganization.uuid]">{{deal.targetOrganization?.name}}</a>
                                    </td>
                                    <td class="wrap">{{deal.dealType | dealPipe}}</td>
                                    <td class="wrap">
                                        {{deal.transactionValue==null?'-':deal.transactionValue==0?'-':deal.transactionValue}}
                                    </td>
                                    <td class="wrap">{{deal.evRev==null?'-':deal.evRev==0?'-':deal.evRev}}</td>
                                    <td class="wrap">{{deal.evEbitda==null?'-':deal.evEbitda==0?'-':deal.evEbitda}}</td>
                                    <td class="wrap">
                                        <span class="limitedText limitText">
                                            <span
                                                *ngFor="let classification of deal.classification.level1; let last = last;">{{classification.name}}{{last?'':';
                                                '}}</span>
                                        </span>
                                        <a href="javascript:" (click)="appUtil.showHideClampedContent($event)"
                                            class="moreless hidden ml-2 p-button-danger"
                                            style="float:right;display:block;">More</a>
                                    </td>
                                    <td class="wrap">
                                        <span class="limitedText limitText">
                                            <span
                                                *ngFor="let classification of deal.classification.level2; let last = last;">{{classification.name}}{{last?'':';
                                                '}}</span>
                                        </span>
                                        <a href="javascript:" (click)="appUtil.showHideClampedContent($event)"
                                            class="moreless hidden ml-2 p-button-danger"
                                            style="float:right;display:block;">More</a>
                                    </td>
                                    <td class="wrap">
                                        <span class="limitedText limitText">
                                            <span
                                                *ngFor="let classification of deal.classification.level3; let last = last;">{{classification.name}}{{last?'':';
                                                '}}</span>
                                        </span>
                                        <a href="javascript:" (click)="appUtil.showHideClampedContent($event)"
                                            class="moreless hidden ml-2 p-button-danger"
                                            style="float:right;display:block;">More</a>
                                    </td>
                                    <td class="wrap">
                                        <span class="limitedText limitText">
                                            <span
                                                *ngFor="let classification of deal.classification.vertical; let last = last;">{{classification.name}}{{last?'':';
                                                '}}</span>
                                        </span>
                                        <a href="javascript:" (click)="appUtil.showHideClampedContent($event)"
                                            class="moreless hidden ml-2 p-button-danger"
                                            style="float:right;display:block;">More</a>
                                    </td>
                                    <td class="wrap">
                                        <span class="limitedText limitText">
                                            <span
                                                *ngFor="let classification of deal.classification.ecosystem; let last = last;">{{classification.name}}{{last?'':';
                                                '}}</span>
                                        </span>
                                        <a href="javascript:" (click)="appUtil.showHideClampedContent($event)"
                                            class="moreless hidden ml-2 p-button-danger"
                                            style="float:right;display:block;">More</a>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="13">No deals found to be associated with spotlight</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </p-panel>
                </div>
            </div> -->
        </div>
    </form>
</div>
<p-toast></p-toast>
<tbp-spotlight-preview [(show)]="previewDialog" [spotlight]="spotlight"></tbp-spotlight-preview>
<tbp-media-lookup [(show)]="showMediaLookupDialog" [title]="mediaLookupDialogTitle" [allowAddFolder]="false"
    [allowAddDocument]="true" startAtFolder="/spotlight/" (onSelect)="mediaSelected($event)" [closeOnSelect]="true" [onlyPublicImages]="true">
</tbp-media-lookup>