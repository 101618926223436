import { TbpAutocompleteModule } from './../_components/tbp-autocomplete/tbp-autocomplete.module';
import { MenuModule } from 'primeng/menu';
import { TableModule } from 'primeng/table';
import { PanelModule } from 'primeng/panel';
import { ToastModule } from 'primeng/toast';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadModule } from 'primeng/fileupload';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DealSpotlightContactListComponent } from './deal-spotlight-contact.list.component';
import { DealSpotlightContactEditComponent } from './deal-spotlight-contact.edit.component';


@NgModule({
    declarations: [DealSpotlightContactListComponent, DealSpotlightContactEditComponent],
    imports: [
        CommonModule,
        DialogModule,
        DropdownModule,
        FormsModule,
        InputTextModule,
        ButtonModule,
        InputTextareaModule,
        ReactiveFormsModule,
        CalendarModule,
        AutoCompleteModule,
        ToastModule,
        PanelModule,
        TableModule,
        MenuModule,
        FileUploadModule,
        TbpAutocompleteModule
    ],
    exports: [
        DealSpotlightContactListComponent,
        DealSpotlightContactEditComponent
    ]
})
export class DealSpotlightContactModule { }
