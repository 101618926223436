import { UploadExcelModule } from './../upload-excel/upload-excel.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ToolbarModule } from 'primeng/toolbar';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SplitButtonModule } from 'primeng/splitbutton';
import { MenuModule } from 'primeng/menu';
import { TabViewModule } from 'primeng/tabview';
import { TreeTableModule } from 'primeng/treetable';
import { DialogModule } from 'primeng/dialog';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { EmailCampaignRoutingModule } from './email-campaign-routing.module';
import { EmailCampaignAddComponent } from './email-campaign-add.component';
import { EmailCampaignViewComponent } from './email-campaign-view.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CheckboxModule } from 'primeng/checkbox';
import { EmailCampaignParticipantComponent } from './email-campaign-participant.component';
import { TbpQueryBuilderModule } from '../_components/tbp-query-builder/tbp-query-builder.module';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RecipientListComponent } from './recipient-list.component';
import { TooltipModule } from 'primeng/tooltip';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { DividerModule } from 'primeng/divider';
import { TbpAutocompleteModule } from '../_components/tbp-autocomplete/tbp-autocomplete.module';
import { EmailCampaignsListComponent } from './email-campaigns-list.component';
import { TbpSearchModule } from '../_components/tbp-search/tbp-search.module';
import { TbpTableColumnModule } from '../_components/tbp-table-column/tbp-table-column.module';
import { SharedModule } from '../shared/shared.module';
@NgModule({
    declarations: [
        EmailCampaignAddComponent,
        EmailCampaignViewComponent,
        EmailCampaignParticipantComponent,
        RecipientListComponent,
        EmailCampaignsListComponent
    ],
    imports: [
        CommonModule,
        EmailCampaignRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        TableModule,
        ButtonModule,
        OverlayPanelModule,
        ToolbarModule,
        FileUploadModule,
        InputTextModule,
        DropdownModule,
        InputTextareaModule,
        SplitButtonModule,
        MenuModule,
        TabViewModule,
        TreeTableModule,
        DialogModule,
        MessageModule,
        MessagesModule,
        ConfirmDialogModule,
        ToastModule,
        EditorModule,
        ProgressSpinnerModule,
        AutoCompleteModule,
        CheckboxModule,
        TbpQueryBuilderModule,
        RadioButtonModule,
        UploadExcelModule,
        TooltipModule,
        DividerModule,
        TbpAutocompleteModule,
        TbpSearchModule,
        SharedModule,
        TbpTableColumnModule
    ],
    providers: [
        { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
    ]
})
export class EmailCampaignModule { }
