
import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment } from '@angular/router';
import { AddBuysideComponent } from './add.buyside.component';
import { ViewBuysideComponent } from './view.buyside.component';
import { ClassificationResolver } from './../classification/classification.resolver';
import { ConfigurationResolver } from '../configuration/configuration.resolver';
import { UserResolver } from './../users/user.resolver';
import { HelpResolver } from '../help/help.resolver';
import { BuysideListComponent } from './buyside-list.component';

const routes: Routes = [
    {
        matcher: (url) => {// urls like buy-side, organization/:uuid/buy-side, contact/:uuid/buy-side, etc
            if (url.length == 1 && url[0].path == 'buysides'|| (url.length >= 3 && (url[2].path == 'buysides'))) {
                return {
                    consumed: url,
                    posParams: {
                        source: url.length !=1?url[0]:new UrlSegment('', {}),
                        sourceuuid: url.length !=1?url[1]:new UrlSegment('', {}),
                        showCheckbox: new UrlSegment(url.length ==4?'true':'false', {}),
                    }
                };
            }
            return null;
        },
       
        component: BuysideListComponent,
        resolve: {
            classificationList: ClassificationResolver,
            lookupList: ConfigurationResolver,
        }
    },
    {
        path: 'buy-side/new', component: AddBuysideComponent,
        resolve: {
            classificationList: ClassificationResolver,
            lookupList: ConfigurationResolver,
            tbpEmployees: UserResolver,
            helpList: HelpResolver
        }
    },
    {
        path: 'buy-side/edit/:uuid', component: AddBuysideComponent,
        resolve: {
            lookupList: ConfigurationResolver,
            classificationList: ClassificationResolver,
            tbpEmployees: UserResolver,
            helpList: HelpResolver
        }
    },
    {
        matcher: (url) => {
            if ((url.length > 1 && url[0].path == 'buy-side') && (url.length > 2 && (url[2].path == 'blind-profile' || url[2].path == 'prospects'))) {
                return {
                    consumed: url,
                    posParams: {
                        uuid: url[1],
                        filterFor:new UrlSegment('BUYSIDE_PROSPECT',{})
                    }
                };
            }
            return null;
        },
        component: ViewBuysideComponent,
        resolve: {
            tbpEmployees: UserResolver,
            lookupList: ConfigurationResolver,
            classificationList: ClassificationResolver,
        }
    },
    {
        path: 'buy-side/:uuid', component: ViewBuysideComponent,
        resolve: {
            tbpEmployees: UserResolver,
            lookupList: ConfigurationResolver,
            classificationList: ClassificationResolver,
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [ClassificationResolver, ConfigurationResolver, UserResolver, HelpResolver,]
})
export class BuysideRoutingModule { }                                     