<div class="grid-nogutter">
    <div class="col-12">
        <p-toolbar>
            <div class="p-toolbar-group-left">
                <div class="grid">
                    <div class="col-12">
                        <span class="p-input-icon-left mr-2">
                            <input [(ngModel)]="searchTerm" pInputText type="text" class="table-search" (keyup.enter)="search()" id="act_term" [placeholder]="searchTermPlaceHolder"/>
                            <i class="pi pi-search"></i>
                        </span>
                        <button pButton type="button" label="Search" class="mr-2" (click)="search()"></button>
                    </div>
                </div>
            </div>
        
            <div class="p-toolbar-group-right">
                <div class="grid">
                    <div class="col-12">
                        <p-dropdown [options]="sortableFields" [(ngModel)]="sortField" (onChange)="changeSortField($event)"
                            styleClass="form-control" optionLabel="label" optionValue="property">
                            <ng-template pTemplate="selectedItem" let-item>
                                Sort By:  {{appUtil.getCustomLabel(item,
                                    customLabels)}}
                            </ng-template>
                            <ng-template let-item pTemplate="item">
                                <ng-container *ngIf="item.sortable">
                                    {{appUtil.getCustomLabel(item,
                                        customLabels)}}
                                </ng-container>
                            </ng-template>
                        </p-dropdown>
                        <button pButton pRipple type="button" icon="pi pi-sort-amount-down" class="p-button-text" (click)="toggleSortOrder()" *ngIf="sortOrder == -1"></button>
                        <button pButton pRipple type="button" icon="pi pi-sort-amount-up" class="p-button-text" (click)="toggleSortOrder()" *ngIf="sortOrder == 1" ></button>
                        <button pButton pRipple pTooltip="Show/Hide Fields" tooltipPosition="left" icon="pi pi-sliders-v" class="p-button-text mr-2" (click)="op.toggle($event)" *ngIf="showHideField"></button>
                        <button pButton pRipple pTooltip="Update Column Names" tooltipPosition="left" icon="pi pi-pencil" class="p-button-text mr-2" (click)="prepareColumnNames();ct.toggle($event)" *ngIf="allowCustomLabels"></button>
                    </div>
                </div>
            </div>
        </p-toolbar>
    </div>
    <ng-container *ngIf="showAdvanceSearch">
    <div class="col-12 field headerbgcolor p-1">
        <label><i class="pi pi-filter inline ml-2 cursor-pointer"></i> </label>
        <p-dropdown [options]="predefinedFilters" [(ngModel)]="selectedFilter" [showClear]="true" (onClear)="clearFilters()"
           optionLabel="name" optionValue="uuid"  [autoDisplayFirst]="false" styleClass="ml-2 sm:w-20rem w-13rem" (onChange)="changeFilter()"> </p-dropdown>
       <button pButton pRipple type="button" icon="pi pi-plus" class="p-button-text ml-1" label="Save" (click)="prepareForSave();sv.toggle($event)"></button>
       <button pButton pRipple type="button" icon="pi pi-filter-slash" class="p-button-text ml-1" label="Clear" (click)="clearFilters()"  [disabled]="!isValidFilter()"></button>
    </div>
    <div class="col-12">
        <div class="grid">
            <div class="col-12">
                <div class="border-round inline mr-1 px-2 py-1 border-1 surface-border filter" *ngFor="let rule of rootRule.rules"
                    [ngClass] = "{'active' : currentRuleSet == rootRule && currentRule == rule}" (click)="processField($event, rootRule, rule);">
                    <span class="text-overflow-ellipsis overflow-hidden white-space-nowrap inline-block max-w-15rem vertical-align-bottom">
                    {{rule.field.label}} {{ getOperator(rule)}} {{rule.label}}
                    </span>
                    <i class="pi pi-chevron-down inline ml-2 cursor-pointer"></i> 
                    <i class="pi pi-times-circle inline ml-1 cursor-pointer" (click)="removeRule(rootRule,rule)"></i>
                </div>
                <button pButton pRipple type="button" icon="pi pi-plus" class="p-button-text py-2" (click)="setCurrentRuleSet(rootRule);fp.toggle($event)" label="Rule"></button>
                <!--<button pButton pRipple type="button" icon="pi pi-plus" class="p-button-text" (click)="setCurrentRuleSet(rootRule);addRuleSet();" label="RuleSet" ></button>-->
            </div>
        </div>
        <div class="p-fluid  grid" *ngFor="let rule of rootRule.ruleSets; let index = index;">
            <div class="col-12">
                {{index}}
            </div>
        </div>
        
    </div>
    </ng-container>
</div>
<p-overlayPanel #sv [style]="{width:'300px'}">
    <ng-template pTemplate>
        <div class="grid formgrid p-fluid">
            <div class="field col-12">
                <label>Save Filter As</label>
                <p-dropdown [options]="existingFiltersForSave" [(ngModel)]="filterSelectedForSave" optionLabel="name" optionValue="uuid"  class="ml-2" (onChange)="filterForSaveChanged()"> </p-dropdown>
            </div>
            <div class="field col-12">
                <label>Filter Name</label>
                <input [(ngModel)]="filterName" type="text" pInputText  [disabled]="!canChangeName"/>
            </div>
            <div class="field col-12">
                <label>Visible To</label>
                <p-dropdown [options]="filterVisibilityOptions" [(ngModel)]="filterVisibility" optionLabel="name" optionValue="uuid"  class="ml-2" [disabled]="!canChooseVisibility"> </p-dropdown>
            </div>
            <div class="field col-12">
                <button pButton pRipple type="button" label="Save" (click)="saveFilter()" [disabled]="((filterName != null && filterName.trim() =='') || filterName.length == 0)"></button>
            </div>
        </div>
    </ng-template>
</p-overlayPanel>
<p-overlayPanel #op>
    <ng-template pTemplate>
        <div class="grid formgrid p-fluid">
            <div class="field col-12">
                <div class="p-listbox p-component p-listbox-striped">
                    <div class="p-listbox-header p-bold-text">Visible Fields</div>
                    <div class="p-listbox-list-wrapper">
                    <ul class="p-listbox-list" cdkDropList (cdkDropListDropped)="onDrop($event)">
                        <ng-container *ngFor="let fieldInfo of visibleFields ">
                            <li cdkDrag class="p-ripple p-element p-listbox-item">
                                <div style="width:100%;display: inline;">
                                    
                                    <p-checkbox [value]="fieldInfo" [(ngModel)]="innerVisibleColumns" [disabled]="fieldInfo.visible == 'A'"></p-checkbox>{{appUtil.getCustomLabel(fieldInfo,
                                        customLabels)}}
                                </div>
                                <div cdkDragHandle  style="cursor: move;float: right;"><i class="pi pi-arrows-v" ></i></div> 
                            </li>
                        </ng-container>
                    </ul>
                    </div>
                </div>
            </div>
            <div class="field col-12">
                <div class="p-listbox p-component p-listbox-striped">
                    <div class="p-listbox-header p-bold-text">Available Fields</div>
                    <div class="p-listbox-list-wrapper">
                    <ul class="p-listbox-list">
                        <ng-container *ngFor="let fieldInfo of fieldsForColumnChooser ">
                            <li class="p-ripple p-element p-listbox-item">
                                <div style="width:100%">
                                    <p-checkbox [value]="fieldInfo" [(ngModel)]="innerVisibleColumns" [disabled]="fieldInfo.visible == 'A'"></p-checkbox>{{appUtil.getCustomLabel(fieldInfo,
                                        customLabels)}}
                                </div>
                            </li>
                        </ng-container>
                    </ul>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</p-overlayPanel>
<p-overlayPanel #op>
    <ng-template pTemplate>
        <div class="grid formgrid p-fluid">
            <div class="field col-12">
                <div class="p-listbox p-component p-listbox-striped">
                    <div class="p-listbox-header p-bold-text">Visible Fields</div>
                    <div class="p-listbox-list-wrapper">
                    <ul class="p-listbox-list" cdkDropList (cdkDropListDropped)="onDrop($event)">
                        <ng-container *ngFor="let fieldInfo of visibleFields">
                            <li cdkDrag class="p-ripple p-element p-listbox-item">
                                <div style="width:100%;display: inline;">
                                    
                                    <p-checkbox [value]="fieldInfo" [(ngModel)]="innerVisibleColumns" [label]="fieldInfo.label" [disabled]="fieldInfo.visible == 'A'"></p-checkbox>
                                </div>
                                <div cdkDragHandle  style="cursor: move;float: right;"><i class="pi pi-arrows-v" ></i></div> 
                            </li>
                        </ng-container>
                    </ul>
                    </div>
                </div>
            </div>
            <div class="field col-12">
                <div class="p-listbox p-component p-listbox-striped">
                    <div class="p-listbox-header p-bold-text">Available Fields</div>
                    <div class="p-listbox-list-wrapper">
                    <ul class="p-listbox-list">
                        <ng-container *ngFor="let fieldInfo of fieldsForColumnChooser">
                            <li class="p-ripple p-element p-listbox-item">
                                <div style="width:100%">
                                    <p-checkbox [value]="fieldInfo" [(ngModel)]="innerVisibleColumns" [label]="fieldInfo.label" [disabled]="fieldInfo.visible == 'A'"></p-checkbox>
                                </div>
                            </li>
                        </ng-container>
                    </ul>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</p-overlayPanel>
<p-overlayPanel #df>
    <ng-template pTemplate>
        <div class="grid formgrid p-fluid">
            <div class="field col-12">
                <div class="p-listbox p-component p-listbox-striped">
                    <div class="p-listbox-header p-bold-text">Set Default View</div>
                    <div class="p-listbox-list-wrapper">
                    <ul class="p-listbox-list">
                        <li class="p-ripple p-element p-listbox-item">
                            <div class="grid formgrid p-fluid">
                                <div class="field col-12 mb-0">
                                    <p-checkbox [value]="fieldInfo" [(ngModel)]="innerVisibleColumns" label="Use System Default"></p-checkbox>
                                </div>
                            </div>
                        </li>
                        <li class="p-ripple p-element p-listbox-item">
                            <div class="grid formgrid p-fluid">
                                <div class="field col-12 mb-0">
                                    <p-checkbox [value]="fieldInfo" [(ngModel)]="innerVisibleColumns" label="Use Object Default"></p-checkbox>
                                </div> 
                            </div>
                        </li>
                        <li class="p-ripple p-element p-listbox-item">
                            <div class="grid formgrid p-fluid">
                                <div class="field col-12">
                                    <p-checkbox [value]="fieldInfo" [(ngModel)]="innerVisibleColumns" label="Use Current View"></p-checkbox>
                                </div>  
                                <div class="field col-12 mb-0">
                                    <div class="grid formgrid p-fluid ml-6">
                                        <div class="field col-12">
                                            <p-checkbox [value]="fieldInfo" [(ngModel)]="innerVisibleColumns" label="include current query"></p-checkbox>
                                        </div>
                                        <div class="field col-12">
                                            <p-checkbox [value]="fieldInfo" [(ngModel)]="innerVisibleColumns" label="Set as Object Default"></p-checkbox>
                                        </div>    
                                    </div>
                                </div>
                                                          
                            </div>
                        </li>
                    </ul>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</p-overlayPanel>
<p-overlayPanel #ct>
    <ng-template pTemplate>
        <div class="grid formgrid p-fluid">
            <div class="field col-12">
                <div class="p-listbox p-component p-listbox-striped">
                    <div class="p-listbox-header p-bold-text">Update Column Names</div>
                    <div class="p-listbox-list-wrapper">
                    <ul class="p-listbox-list">
                        <ng-container *ngFor="let fieldInfo of fields">
                            <li class="p-ripple p-element p-listbox-item">
                                <div class="grid formgrid p-fluid">
                                    <div class="field col-6 mb-0">
                                        {{fieldInfo.label}}
                                    </div>
                                    <div class="field col-6 mb-0">
                                        <input [(ngModel)]="columnNames[fieldInfo.property]" pInputText type="text" class="table-search"/>
                                    </div>
                                </div>
                            </li>
                        </ng-container>
                        <li class="p-ripple p-element p-listbox-item">
                            <div class="grid formgrid p-fluid">
                                <div class="field col-6 mb-0">
                                    <button pButton (click)="updateCustomLabels()" label="Update" class="mr-2"></button>
                                </div>
                                <div class="field col-6 mb-0">
                                    <button pButton (click)="ct.hide()" label="Cancel"></button>
                                </div>
                            </div>
                        </li>
                    </ul>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</p-overlayPanel>
<p-overlayPanel #fp [style]="{width:'300px'}">
    <div class="card">
        <h5>Saved Filters</h5>
        <div class="p-fluid formgrid grid">
            <div class="field col-12">
                 <p-dropdown [options]="predefinedFilters" dataKey="uuid" name="predefined"
                    optionLabel="name" optionValue="uuid"  [autoDisplayFirst]="false"> </p-dropdown>
            </div>
        </div>
        <p-divider [attr.align]="'center'"></p-divider>
        <h5>Add Filter</h5>
        <div class="formgrid grid">
            <ng-container *ngFor="let fieldInfo of fields">
                <div class="field col-12" *ngIf="fieldInfo.searchable">
                    <p-checkbox name="field" [label]="fieldInfo.label" [value]="fieldInfo.property" [(ngModel)]="selectedSearchFields" (onChange)="addRule(fieldInfo)" ></p-checkbox>
                </div>
            </ng-container>
        </div>
    </div>
</p-overlayPanel>
<p-overlayPanel #fo appendTo="body" [style]="{width:'300px'}">
    <div class="formgrid grid">
        <div class="field col-12 surface-200 text-2xl">
            {{currentRule?.field.label}}
        </div>
        <ng-container *ngIf="currentRule?.field.searchType == 'exists'">
            <div class="field col-12">
                <label class="block">Condition</label>
                <p-radioButton name="condition" value="exists" [(ngModel)]="filterOperator" class="active m-1 p-1 pr-2 border-round-sm " label="Assigned"></p-radioButton>
                <p-radioButton name="condition" value="notexists" [(ngModel)]="filterOperator" class="active m-1 p-1 pr-2 border-round-sm " label="Not Assigned"></p-radioButton>
            </div>
        </ng-container>
        <ng-container *ngIf="currentRule?.field.searchType == 'text'">
            <div class="field col-12">
                <label class="block">Condition</label>
                <p-radioButton name="condition" value="exists" [(ngModel)]="filterOperator" class="active m-1 p-1 pr-2 border-round-sm " label="Assigned"></p-radioButton>
                <p-radioButton name="condition" value="notexists" [(ngModel)]="filterOperator" class="active m-1 p-1 pr-2 border-round-sm " label="Not Assigned"></p-radioButton>
                <p-radioButton name="condition" value="matches" [(ngModel)]="filterOperator" class="active m-1 p-1 pr-2 border-round-sm " label="Matches"></p-radioButton>
            </div>
            <div class="field col-12 p-fluid" *ngIf="filterOperator == 'matches'">
                <label>Text</label>
                <input [(ngModel)]="filterValue" type="text"  pInputText/>
            </div>
         </ng-container>
         <ng-container *ngIf="currentRule?.field.searchType == 'double'">
            <div class="field col-12">
                <label class="block">Condition</label>
                <p-radioButton name="condition" value="exists" [(ngModel)]="filterOperator" class="active m-1 p-1 pr-2 border-round-sm " label="Assigned"></p-radioButton>
                <p-radioButton name="condition" value="notexists" [(ngModel)]="filterOperator" class="active m-1 p-1 pr-2 border-round-sm " label="Not Assigned"></p-radioButton>
                <p-radioButton name="condition" value="=" [(ngModel)]="filterOperator" class="active m-1 p-1 pr-2 border-round-sm " label="="></p-radioButton>
                <p-radioButton name="condition" value="!=" [(ngModel)]="filterOperator" class="active m-1 p-1 pr-2 border-round-sm " label="!="></p-radioButton>
            </div>
            <div class="field col-12 p-fluid" *ngIf="filterOperator == '=' || filterOperator == '!='">
                <label>Value</label>
                <input [(ngModel)]="filterValue" type="double"  pInputText/>
            </div>
         </ng-container>
        <ng-container *ngIf="currentRule?.field.searchType == 'boolean'">
            <div class="field col-12">
                <p-radioButton name="condition" [value]=true [(ngModel)]="filterValue" class="active m-1 p-1 pr-2 border-round-sm " label="Yes"></p-radioButton>
                <p-radioButton name="condition" [value]=false [(ngModel)]="filterValue" class="active m-1 p-1 pr-2 border-round-sm " label="No"></p-radioButton>
            </div>
        </ng-container>
        <ng-container *ngIf="currentRule?.field.searchType == 'number'">
            <div class="field col-12">
                <label class="block">Condition</label>
                <p-radioButton name="condition" value="exists" [(ngModel)]="filterOperator" class="active m-1 p-1 pr-2 border-round-sm " label="Assigned"></p-radioButton>
                <p-radioButton name="condition" value="notexists" [(ngModel)]="filterOperator" class="active m-1 p-1 pr-2 border-round-sm " label="Not Assigned"></p-radioButton>
                <p-radioButton name="condition" value="=" [(ngModel)]="filterOperator" class="active m-1 p-1 pr-2 border-round-sm " label="=" (onClick)="filterShowMinMax = false"></p-radioButton>
                <p-radioButton name="condition" value="!=" [(ngModel)]="filterOperator"  class="active m-1 p-1 pr-2 border-round-sm " label="!=" (onClick)="filterShowMinMax = false"></p-radioButton>
                <p-radioButton name="condition" value="between" [(ngModel)]="filterOperator"  class="active m-1 p-1 pr-2 border-round-sm " label="Range" (onClick)="filterShowMinMax = true"></p-radioButton>
            </div>
            <div class="field col-12 p-fluid" *ngIf="filterOperator != null && !(filterOperator.indexOf('exists') > -1)">
                <div class="field col-13 p-fluid" *ngIf="!filterShowMinMax">
                    <label>Value</label>
                    <input [(ngModel)]="filterValue" type="text"  pInputText />
                </div>
                <div class="field col-13 p-fluid"  *ngIf="filterShowMinMax">
                    <label>Min</label>
                    <input [(ngModel)]="filterValue" type="text"  pInputText/>
                </div>
                <div class="field col-13 p-fluid"  *ngIf="filterShowMinMax">
                    <label>Max</label>
                    <input [(ngModel)]="filterValueMax" type="text"  pInputText/>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="currentRule?.field.searchType == 'exact'">
            <div class="field col-12">
                <label class="block">Condition</label>
                <p-radioButton name="condition" value="exists" [(ngModel)]="filterOperator" class="active m-1 p-1 pr-2 border-round-sm " label="Assigned"></p-radioButton>
                <p-radioButton name="condition" value="notexists" [(ngModel)]="filterOperator" class="active m-1 p-1 pr-2 border-round-sm " label="Not Assigned"></p-radioButton>
                <p-radioButton name="condition" value="in" [(ngModel)]="filterOperator" class="active m-1 p-1 pr-2 border-round-sm " label="="></p-radioButton>
                <p-radioButton name="condition" value="notin" [(ngModel)]="filterOperator"  class="active m-1 p-1 pr-2 border-round-sm " label="!="></p-radioButton>
            </div>
            <div class="field col-12 p-fluid" *ngIf="filterOperator != null && !(filterOperator.indexOf('exists') > -1)">
                <label>Value</label>
                <p-multiSelect [(ngModel)]="filterValue" [options]="currentRule.field.searchOptions" [optionLabel]="currentRule.field.labelProperty" [optionValue]="currentRule.field.valueProperty"></p-multiSelect>
            </div>
        </ng-container>
        <ng-container *ngIf="currentRule?.field.searchType == 'date'">
            <div class="field col-12">
                <label class="block">Condition</label>
                <p-radioButton name="condition" value="exists" [(ngModel)]="filterOperator" class="active m-1 p-1 pr-2 border-round-sm " label="Assigned"></p-radioButton>
                <p-radioButton name="condition" value="notexists" [(ngModel)]="filterOperator" class="active m-1 p-1 pr-2 border-round-sm " label="Not Assigned"></p-radioButton>
                <p-radioButton name="condition" value="=" [(ngModel)]="filterOperator" class="active m-1 p-1 pr-2 border-round-sm " label="=" (onClick)="filterShowMinMax = false"></p-radioButton>
                <p-radioButton name="condition" value="!=" [(ngModel)]="filterOperator"  class="active m-1 p-1 pr-2 border-round-sm " label="!=" (onClick)="filterShowMinMax = false"></p-radioButton>
                <p-radioButton name="condition" value="between" [(ngModel)]="filterOperator"  class="active m-1 p-1 pr-2 border-round-sm " label="Range" (onClick)="filterShowMinMax = true"></p-radioButton>
            </div>
            <div class="field col-12 p-fluid" *ngIf="filterOperator != null && !(filterOperator.indexOf('exists') > -1)">
                <div class="field col-13 p-fluid" *ngIf="!filterShowMinMax">
                    <label>Date</label>
                    <p-calendar [(ngModel)]="filterValue" dateFormat="mm-dd-yy"></p-calendar>
                </div>
                <div class="field col-13 p-fluid"  *ngIf="filterShowMinMax">
                    <label>After</label>
                    <p-calendar [(ngModel)]="filterValue" dateFormat="mm-dd-yy"></p-calendar>
                </div>
                <div class="field col-13 p-fluid"  *ngIf="filterShowMinMax">
                    <label>Before</label>
                    <p-calendar [(ngModel)]="filterValueMax" dateFormat="mm-dd-yy"></p-calendar>
                </div>
            </div>
        </ng-container>
        <div class="field col-12 border-300 border-top-1 text-right p-2">
            <button pButton (click)="updateRule()" label="Update" class="mr-2"></button> <button pButton (click)="fo.hide()" label="Cancel"></button>
        </div>
    </div>
</p-overlayPanel>

