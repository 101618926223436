import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from '../app.breadcrumb.service';
import { Location } from '@angular/common';
import { AccountService } from 'src/app/_services';
import { OrganizationService } from 'src/app/organization/organization.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MessageService, ConfirmationService } from 'primeng/api';
import { AppUtil } from './../_helpers';
import { BuysideService } from './buyside.service';
import { DataService } from "../_services/data.service";
import { UsersService } from '../users/users.service';

@Component({
    selector: 'app-add.buyside',
    templateUrl: './add.buyside.component.html',
    styleUrls: ['./add.buyside.component.scss'],
    providers: [MessageService, ConfirmationService]
})
export class AddBuysideComponent implements OnInit {
    @ViewChild('cform') formElement;
    form: UntypedFormGroup;
    isEdit: boolean = false;
    formsubmited: boolean = false;
    submitted = false;
    items: MenuItem[];
    activeIndex: number;
    debt: any[];
    equity: any[];
    targetRevenue: any[];
    targetEbitda: any[];
    ownership: any[];
    dollerValue: any[];
    managementPreference: any[];
    classificationList: any = {
        CATEGORY_LEVEL1: [], CATEGORY_LEVEL2: [], CATEGORY_LEVEL3: [],
        VERTICAL: [], ECOSYSTEM: []
    };
    loadingSpinner: boolean = false;
    companyResults: [];
    organizations: any[] = [];
    contacts: any[] = [];
    participantResults: [];
    participants: [];
    selectedEBITDA: string[] = [];
    selectedREV: string[] = [];
    accountForm: boolean = false;
    tbpEmployees = [];
    helpList: any = {BUYSIDE:[]};
    lookupList: any = { REVENUE: [], EBITDA: [], DOLLOR_VALUE_RANGE: [] };
    model:any;

    constructor(private usersService: UsersService, private dataService: DataService, private buysideService: BuysideService, 
        private confirmationService: ConfirmationService, private messageService: MessageService, private router: Router, private formBuilder: UntypedFormBuilder, 
        private organizationService: OrganizationService, private _location: Location, public accountService: AccountService, 
        private route: ActivatedRoute, private breadcrumbService: BreadcrumbService, private appUtil:AppUtil) {

        if (this.router.url.indexOf("/edit/") != -1) {
            this.isEdit = true;
        }
        if (this.isEdit) {
            this.buysideService.getBuyside(this.route.snapshot.params.uuid).subscribe(data => {
                this.modelToForm(data as any);
                if (data['targetEbitda'] != null)
                    this.selectedEBITDA = data['targetEbitda'].split(',');
                if (data['targetRevenue'] != null)
                    this.selectedREV = data['targetRevenue'].split(',');
            });
            this.breadcrumbService.setItems([
                { label: 'Edit Buy Side' }
            ]);
        } else {
            this.breadcrumbService.setItems([
                { label: 'New Buy Side Screen' }
            ]);
        }


        this.debt = [
            {value: 'A/R Financing'},
            {value: 'Junior Debt'},
            {value: 'Mezzanine Financing'},
            {value: 'Senior Debt'},
        ];
        this.equity = [
            {value: 'Growth Equity'},
            {value: 'Venture Capital'},
            {value: 'Angel Investment'},
            {value: 'Minority Recap'},
        ];
        // this.targetRevenue = [
        //     {value: '<10'},
        //     {value: '>100'},
        //     {value: '10-20'},
        //     {value: '50-100'}, 
        // ];
        // this.targetEbitda = [
        //     {value: '<1'},
        //     {value: '>20'},
        //     {value: '1-5'},
        //     {value: '5-10'},
        //     {value: '10-20'}, 
        // ];
        // this.ownership = [
        //     {value: '<10'},
        //     {value: '>50'},
        //     {value: '10-19'},
        //     {value: '20-29'},
        //     {value: '30-39'},
        //     {value: '40-49'}, 
        // ];
        // this.dollerValue = [
        //     {value: '>50'},
        //     {value: '1-5'},
        //     {value: '10-20'},
        //     {value: '20-50'},
        //     {value: '5-10'}, 
        // ];
        this.managementPreference = [
            {value: 'Flexible'},
            {value: 'Management can exit'},
            {value: 'Management should stay'},
        ];
    }

    ngOnInit(): void {
        this.classificationList = this.route.snapshot.data['classificationList'];
        this.lookupList = this.route.snapshot.data['lookupList'];
        this.tbpEmployees = this.route.snapshot.data['tbpEmployees'];
        this.helpList = this.route.snapshot.data['helpList']['BUYSIDE'];
        this.loadBuySideForm();
        this.activeIndex = 0;
        this.items = [{
            label: 'Screen Details',
            command: (event: any) => {
                this.activeIndex = 0;
            }
        },
        {
            label: 'Target Requirements',
            command: (event: any) => {
                this.activeIndex = 1;
            }
        },
        {
            label: 'Preview',
            command: (event: any) => {
                this.activeIndex = 2;
            }
        }];

        if (this.isEdit == false) {
            const org = this.dataService.getDataByKey('org-');
            const user = this.dataService.getDataByKey('user-');
            if (org != undefined) {
                this.f.organization.setValue(org);
            }
            if (user != undefined) {
                this.f.contacts.setValue([user]);
            }
        }
    }

    loadBuySideForm() {
        this.form = this.formBuilder.group({
            uuid: [''],
            contacts: [],
            organization: ['', [Validators.required]],
            screenTitle: ['', [Validators.required]],
            externalHeadline: ['', [Validators.required]],
            dollorValueRange: [],
            targetRevenue: [],
            targetEbitda: [],
            managementPreference: ['Flexible'],
            turnaround: [true],
            transactionObjective: [],
            companyDescription: [],
            level1: [],
            level2: [],
            level3: [],
            vertical: [],
            ecosystem: [],
            isStrategicBuyout: [false],
            isPEBuyoutPlatform: [false],
            isPEBuyoutTuckin: [false],
            equityType: [],
            debtType: [],
            hasBlindProfile: [false]
        });
    }

    searchParticipant(event) {
        this.usersService.lookupContact(event.query).subscribe(data => {
            this.participantResults = data as any;
        });

    }

    modelToForm(model) {
        let debtTypeValues = [];
        if(model.primaryObjectives.investment.debt.arFinancing)
        {
            debtTypeValues.push(this.appUtil.findItemInList(this.debt, 'A/R Financing', 'value').value)
        }
        if(model.primaryObjectives.investment.debt.juniorDebt)
        {
            debtTypeValues.push(this.appUtil.findItemInList(this.debt, 'Junior Debt', 'value').value)
        }
        if(model.primaryObjectives.investment.debt.mezzanineFinancing)
        {
            debtTypeValues.push(this.appUtil.findItemInList(this.debt, 'Mezzanine Financing', 'value').value)
        }
        if(model.primaryObjectives.investment.debt.seniorDebt)
        {
            debtTypeValues.push(this.appUtil.findItemInList(this.debt, 'Senior Debt', 'value').value)
        }
        let equityTypeValues = [];
        if(model.primaryObjectives.investment.equity.growthEquity)
        {
            equityTypeValues.push(this.appUtil.findItemInList(this.equity, 'Growth Equity', 'value').value)
        }
        if(model.primaryObjectives.investment.equity.ventureCapital)
        {
            equityTypeValues.push(this.appUtil.findItemInList(this.equity, 'Venture Capital', 'value').value)
        }
        if(model.primaryObjectives.investment.equity.angelInvestment)
        {
            equityTypeValues.push(this.appUtil.findItemInList(this.equity, 'Angel Investment', 'value').value)
        }
        if(model.primaryObjectives.investment.equity.minorityRecap)
        {
            equityTypeValues.push(this.appUtil.findItemInList(this.equity, 'Minority Recap', 'value').value)
        }

        this.checkClassifications(model.classification.level1, 'CATEGORY_LEVEL1');
        this.checkClassifications(model.classification.level2, 'CATEGORY_LEVEL2');
        this.checkClassifications(model.classification.level3, 'CATEGORY_LEVEL3');
        this.checkClassifications(model.classification.vertical, 'VERTICAL');
        this.checkClassifications(model.classification.ecosystem, 'ECOSYSTEM');
        this.form.setValue({
            uuid: model.uuid,
            contacts: model.contacts,
            organization: model.organization,
            screenTitle: model.screenTitle,
            externalHeadline: model.externalHeadline,
            dollorValueRange: (model.dollorValueRange == null || model.dollorValueRange == '') ? null : this.lookupList['DOLLOR_VALUE_RANGE'].find(field => field.value === model['dollorValueRange']),
            targetRevenue: (model.targetRevenue == null || model.targetRevenue == '') ? null : this.lookupList['REVENUE'].find(field => field.value === model['targetRevenue']),
            targetEbitda: (model.targetEbitda == null || model.targetEbitda == '') ? null : this.lookupList['EBITDA'].find(field => field.value === model['targetEbitda']),
            managementPreference: model.managementPreference,
            turnaround: model.turnaround,
            transactionObjective: model.transactionObjective,
            companyDescription: model.companyDescription,
            level1: (model.classification.level1 == null) ? null : model.classification.level1,
            level2: (model.classification.level2 == null) ? null : model.classification.level2,
            level3: (model.classification.level3 == null) ? null : model.classification.level3,
            vertical: (model.classification.vertical == null) ? null : model.classification.vertical,
            ecosystem: (model.classification.ecosystem == null) ? null : model.classification.ecosystem,
            isPEBuyoutPlatform: model.primaryObjectives.peBuyout.platform,
            isPEBuyoutTuckin: model.primaryObjectives.peBuyout.tuckin,
            equityType: equityTypeValues,
            debtType: debtTypeValues,
            isStrategicBuyout: model.primaryObjectives.strategicBuyout,
            hasBlindProfile: model.hasBlindProfile
        });
    }

    checkClassifications(dbList, currentList) {
        if (dbList) {
            const temp = [...this.classificationList[currentList]];
            for (let i = 0; i < dbList.length; i++) {
                if (dbList[i].uuid == null) {
                    dbList[i].uuid = '-1' + dbList[i].name;
                    temp.push(dbList[i]);
                }
            }
            this.classificationList[currentList] = temp;
        }
    }
    get f() { return this.form.controls; }

    searchCompanies(event) {
        this.organizationService.lookupOrganization(event.query).subscribe(data => {
            this.companyResults = data as any;
        });
    }

    nextStep() {
        let hasError:boolean = false;
        if(this.activeIndex == 0) {
            this.validatePrimaryObjective(null);
            hasError = this.f.organization.invalid || this.f.isStrategicBuyout.invalid
                || this.f.isPEBuyoutPlatform.invalid || this.f.isPEBuyoutTuckin.invalid
                || this.f.debtType.invalid || this.f.equityType.invalid || this.f.screenTitle.invalid 
                || this.f.externalHeadline.invalid;
        }
        else if(this.activeIndex == 1) {
            hasError = this.f.level1.invalid;
            this.prepareModel();
        }
        else if(this.activeIndex == 2) {
            
            
        }
        if(hasError)
        {
          this.formsubmited = true;
          this.messageService.add({severity: 'error', summary: 'Form Errors', detail: "Form has errors ", life: 3000});
        }
        else
        {
            this.formsubmited = false;
            this.activeIndex++;           
        }

    }

    preStep() {
        this.activeIndex--;
    }

    cancel() {
        this._location.back();
    }

    get role() {
        return this.accountService.userValue.role;
    }

    selectAccount(value) {
        if(value != null) {
            if (value.classification.level1 != null)
                this.f.level1.setValue(value.classification.level1);
            if (value.classification.level2 != null)
                this.f.level2.setValue(value.classification.level2);
            if (value.classification.level3 != null)
                this.f.level3.setValue(value.classification.level3);
            if (value.classification.vertical != null)
                this.f.vertical.setValue(value.classification.vertical);
            if (value.classification.ecosystem != null)
                this.f.ecosystem.setValue(value.classification.ecosystem);
        }
    }

    prepareModel(){
        let arFinancing =  this.appUtil.findItemInList(this.f.debtType.value, this.appUtil.findItemInList(this.debt, 'A/R Financing', 'value').value)?true:false;
        let juniorDebt =  this.appUtil.findItemInList(this.f.debtType.value, this.appUtil.findItemInList(this.debt, 'Junior Debt', 'value').value)?true:false;
        let mezzanineFinancing =  this.appUtil.findItemInList(this.f.debtType.value, this.appUtil.findItemInList(this.debt, 'Mezzanine Financing', 'value').value)?true:false;
        let seniorDebt =  this.appUtil.findItemInList(this.f.debtType.value, this.appUtil.findItemInList(this.debt, 'Senior Debt', 'value').value)?true:false;
        let growthEquity =  this.appUtil.findItemInList(this.f.equityType.value, this.appUtil.findItemInList(this.equity, 'Growth Equity', 'value').value)?true:false;
        let ventureCapital =  this.appUtil.findItemInList(this.f.equityType.value, this.appUtil.findItemInList(this.equity, 'Venture Capital', 'value').value)?true:false;
        let angelInvestment =  this.appUtil.findItemInList(this.f.equityType.value, this.appUtil.findItemInList(this.equity, 'Angel Investment', 'value').value)?true:false;
        let minorityRecap =  this.appUtil.findItemInList(this.f.equityType.value, this.appUtil.findItemInList(this.equity, 'Minority Recap', 'value').value)?true:false;

        let debt = {arFinancing, juniorDebt,mezzanineFinancing,seniorDebt}
        let equity = {growthEquity, ventureCapital, angelInvestment, minorityRecap}
        let peBuyout = { tuckin: this.f.isPEBuyoutTuckin.value, platform : this.f.isPEBuyoutPlatform.value};

        let { uuid, organization, contacts, screenTitle, targetRevenue, targetEbitda, dollorValueRange, externalHeadline, 
            managementPreference, turnaround, transactionObjective, companyDescription, level1, level2, level3, vertical,
            ecosystem, hasBlindProfile } = this.form.value;

        let dataToPost: any = { uuid, organization, contacts, screenTitle, targetRevenue, targetEbitda, dollorValueRange, externalHeadline, 
            managementPreference, turnaround, transactionObjective, companyDescription, hasBlindProfile,
            classification: { level1, level2, level3, vertical, ecosystem }, primaryObjectives: {strategicBuyout: this.f.isStrategicBuyout.value, peBuyout, investment: {debt, equity}} };
            
        if (this.f.targetRevenue.value) {
            dataToPost.targetRevenue = this.f.targetRevenue.value.value;
        }
        if (this.f.targetEbitda.value) {
            dataToPost.targetEbitda = this.f.targetEbitda.value.value;
        }
        if (this.f.dollorValueRange.value) {
           dataToPost.dollorValueRange = this.f.dollorValueRange.value.value;
        }
        if(this.f.hasBlindProfile.value){
            dataToPost.hasBlindProfile = this.f.hasBlindProfile.value;
        }
        this.model = dataToPost;
    }

    save() {
        this.submitted = true;
        
        if (this.form.invalid) {
            this.handleError(null);
            return;
        }
        
        this.loadingSpinner = true;
        this.prepareModel();
        let dataToPost = JSON.parse(JSON.stringify(this.model));
        dataToPost.organization = dataToPost.organization.uuid;
        if(dataToPost.contacts != null && dataToPost.contacts.length > 0)
        {
            let temp = [];
            dataToPost.contacts.forEach(function(currentValue) {
                temp.push(currentValue.uuid);
            })
            dataToPost.contacts = temp;
        }
        let apiToCall: any;
        if (this.isEdit) {
            apiToCall = this.buysideService.updateBuyside(dataToPost);
        }
        else {
            apiToCall = this.buysideService.addBuyside(dataToPost);
        }
        apiToCall.subscribe(
            data => {
                let buyside = data as any;
                let detail = 'Buy Side Created';
                if (this.isEdit) {
                    detail = 'Buy Side Updated';
                }
                this.dataService.setData('message', detail);
                this.router.navigate(['/buy-side/' + buyside.uuid]);
                this.submitted = false;
            },
            error => {
                this.loadingSpinner = false;
                let errors = error.error;
                if (errors.code && errors.code == 'VALIDATION_ERROR') {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: errors.subErrors[0].message, life: 3000 });
    
                }
    
            }
            
            // error => {
                
            //     this.loadingSpinner = false;
            //     let errors = error.error;
            //     if (errors.code && errors.code == 'VALIDATION_ERROR') {
            //         for (var i = 0; i < errors.subErrors.length; i++) {
            //             this.f[errors.subErrors[i].field].setErrors({ 'message': errors.subErrors[i].message });
            //         }
            //         this.handleError(errors.subErrors[0].field);
            //     }
            // }
        );

    }

    handleError(elemId) {
        this.messageService.add({ severity: 'error', summary: 'Form Errors', detail: "Form has errors. Please fix them first", life: 3000 });
        let invalidControl = this.formElement.nativeElement.querySelector('.ng-invalid');

        if (invalidControl) {
            invalidControl.focus();
        }
        else if (elemId != null) {
            invalidControl = this.formElement.nativeElement.querySelector('#' + elemId);
            if (invalidControl) {
                invalidControl.focus();
            }
        }
    }

    selectEBITA(event, val) {
        var index = this.selectedEBITDA.indexOf(val);
        if (event.checked && index === -1)
            this.selectedEBITDA.push(val);
        else
            this.selectedEBITDA.splice(index, 1);
        this.f.targetEbitda.setValue(this.selectedEBITDA);
    }

    selectREVENUE(event, val) {
        var index = this.selectedREV.indexOf(val);
        if (event.checked && index === -1)
            this.selectedREV.push(val);
        else
            this.selectedREV.splice(index, 1);
        this.f.targetRevenue.setValue(this.selectedREV);
    }

    createAccount() {
        this.accountForm = true;
    }

    public onFormGroupChangeEvent(_event) {
        this.f.organization.setValue(_event);
    }


    onPEBuyoutChange(event: any) {
        if (this.f.isPEBuyoutPlatform.value == true || this.f.isPEBuyoutTuckin.value == true) {
            this.f.peBuyout.setValue(true);
        }
        else
        {
            this.f.peBuyout.setValue(false);
        }
    }


    validatePrimaryObjective(event: any) {
        let error = { 'message': 'Primary objective is required.' };
        if (this.f.isStrategicBuyout.value || this.f.isPEBuyoutPlatform.value || this.f.isPEBuyoutTuckin.value
            || (this.f.equityType.value !=null && this.f.equityType.value.length > 0)
            || (this.f.debtType.value !=null && this.f.debtType.value.length > 0))
        {
            error = null;
        }
        
        this.appUtil.setError(this.form, 'isStrategicBuyout', error);
        this.appUtil.setError(this.form, 'isPEBuyoutPlatform', error);
        this.appUtil.setError(this.form, 'isPEBuyoutTuckin', error);
        this.appUtil.setError(this.form, 'equityType', error);
        this.appUtil.setError(this.form, 'debtType', error);
        this.submitted = true;
    }

    getHelpText(key) {
        let test = this.helpList.filter(function (element) {
            return element.key == key;
        });
        if (test[0] != undefined)
            return test[0]['text'];
    }
}
