
export class UserOrganization {

    userId: string;
    name: string;
    email: string;
    phone: string;
    mobile: string;
    organizationName: string;
    organizationPhone: string;
    organizationId: string;
    userRole: string;
    isEmployee: string;
    primaryContact: boolean;
    isAdmin: boolean;
}