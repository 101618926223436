import { map } from 'rxjs/operators';
import { LookupValue } from './../_models/lookup-value';
import { ConfigurationService } from './../configuration/configuration.service';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';


@Injectable()
export class ConfigurationResolver implements Resolve<any> {

    constructor(private lookupService: ConfigurationService) { }

    resolve(): Observable<any> {
        return this.lookupService.getLookupValues('all').pipe(
            map((result: any[]) => {
                return result;
            })
        );
    }
}