import { Component, OnInit } from '@angular/core';
import { DealService } from './deal.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Deal, Spotlight } from "../_models";
import { BreadcrumbService } from '../app.breadcrumb.service';
import { AccountService } from 'src/app/_services';
import { DataService } from '../_services/data.service';
import { MessageService } from 'primeng/api';
import { AppUtil } from '../_helpers';


@Component({
    selector: 'app-deal-view',
    templateUrl: './deal.view.component.html',
    styleUrls: ['./deal.view.component.scss'],
    providers: [MessageService],
})
export class DealViewComponent implements OnInit {
    uuid: string;
    deal: Deal;
    previewDialog: boolean;
    dialogTitle: string;
    spotlight: Spotlight;
    constructor(private dealService: DealService, private breadcrumbService: BreadcrumbService,public appUtil: AppUtil,
        private router: Router, private route: ActivatedRoute, public accountService: AccountService,
        private dataService: DataService, private messageService: MessageService) {
        this.breadcrumbService.setItems([
            { label: ' View Deal' }
        ]);

    }

    ngOnInit(): void {
        this.previewDialog = false;
        this.deal = new Deal;
        let data = this.dataService.getDataByKey('vdeal-' + this.route.snapshot.params.uuid);
        let message = this.dataService.getDataByKey('message');
        if (message != undefined) {
            setTimeout(() => { this.messageService.add({ severity: 'success', summary: 'Successful', detail: message, life: 3000 }); })
            this.dataService.setData('message', undefined);
        }
        if (data) {
            this.deal = data as any;
            this.uuid = this.deal.uuid;
        }
        else {
            this.uuid = this.route.snapshot.params.uuid;
            this.dealService.getDeal(this.route.snapshot.params.uuid).subscribe(data => {
                this.deal = data as any;
            });
        }

    }

    get role() {
        return this.accountService.userValue.role;
    }


    edit() {
        this.dataService.setData('edeal-' + this.deal.uuid, this.deal);
        this.router.navigate(['/deal/edit/' + this.deal.uuid]);
    }

    preview(spotlight: any) {

        this.spotlight = spotlight;
        this.dialogTitle = "PREVIEW SPOTLIGHT";
        this.previewDialog = true;
    }

    hideDialog() {
        this.previewDialog = false;
    }

}
