export class PredefinedFilter {
    uuid?:string;
    name:string;
    relatedTo:string;
    visibility:string;
    query:any;
    fields:any;
    filterType:string;
    entityId:string;
}

export class SearchRequest {

}