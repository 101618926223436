import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'tbp-spotlight-preview',
    templateUrl: './spotlight.preview.component.html',
    styleUrls: ['./spotlight.preview.component.scss']
})
export class SpotlightPreviewComponent implements OnInit {

    @Input() show: boolean = false;
    @Input() spotlight: any;

    @Output() showChange = new EventEmitter<boolean>();

    constructor() { }

    ngOnInit(): void {
    }

    hide() {
        this.show = false;
        this.showChange.emit(this.show);
    }

}
