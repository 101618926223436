<div class="grid table-demo">
  <div class="col-12">
    <div class="card">
      <p-toolbar styleClass="headerSticky">
        <div class="p-toolbar-group-left">
          <div class="grid">
            <div class="col-12"></div>
          </div>
        </div>
        <div class="p-toolbar-group-right">
          <div class="grid">
            <div class="col-12">
              <button pButton pRipple label="Back" icon="pi pi-arrow-left" class="p-button-success mr-2 mb-1"
                (click)="appUtil.return()"></button>

            </div>
          </div>
        </div>
      </p-toolbar>
      <tbp-search #sc [fields]="fieldInfo" filterFor="USER_ACTIVITY" searchTermPlaceHolder="Search Activity By Name"
        [searchURL]="searchUrl" (onSearch)="_search($event)" (onShowHideField)="_updateSelectedColumns($event)">
      </tbp-search>
      <p-table [autoLayout]="true" #dt [value]="activities" dataKey="uuid" [columns]="selectedColumns" [lazy]="true"
        (onLazyLoad)="
              sc.loadData(
                $event.first,
                $event.rows,
                $event.sortField,
                $event.sortOrder
              )
            " [lazyLoadOnInit]="false"
        styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm" [rowHover]="true"
        [rows]="sc.pageSize" [paginator]="true" [rowsPerPageOptions]="[15,25,50,75,100]" [loading]="loading"[first]="sc.firstRow"
        [alwaysShowPaginator]="false" [showCurrentPageReport]="true" [showPageLinks]="appUtil.getPagination(totalRecords)" [showFirstLastIcon] ="appUtil.getPagination(totalRecords)"
        currentPageReportTemplate="Showing {first} to {last} of {{appUtil.getPagination(totalRecords) ? totalRecords :'many'}} entries" [totalRecords]="totalRecords"
        sortField="activityTime">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <ng-container *ngFor="let column of columns">
              <th pSortableColumn="{{ column.property }}" [pSortableColumnDisabled]="!column.sortable">
                {{ column.label }}
                <p-sortIcon field="{{ column.property }}" *ngIf="column.sortable"></p-sortIcon>
              </th>
            </ng-container>
            <th>Action</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-activity let-columns="columns">
          <tr>
            <ng-container *ngFor="let column of columns; let colIndex = index">
              <td class="wrap">
                <ng-container *ngIf="column.property === 'activityTime'; else otherColumns">
                  {{ activity.activityTime | date:'short' }}
                </ng-container>
                <ng-template #otherColumns>
                  <tbp-table-column [object]="activity" [fieldInfo]="column"
                    (onPatch)="patchDeal($event)"></tbp-table-column>
                </ng-template>
              </td>
            </ng-container>
            <td>
              <span class="p-column-title">Action</span>
              <button type="button" pButton icon="pi pi-list"
                (click)="setCurrentItem(activity); menu.toggle($event)"></button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="6">No Activity found.</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="20">
              <span *ngIf="term != ''">Search did not return any result. Try relaxing the criteria or use
                wildcard [*, ?]</span>
              <span *ngIf="term == ''">No users found</span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
<p-menu #menu [popup]="true" [model]="userItems"></p-menu>
<p-toast></p-toast>