<div class="grid table-demo">
    <div class="col-12">
        <div class="card">
            <p-toolbar styleClass="headerSticky">
                <ng-template pTemplate="left">
               <span><b>Screen Title - </b> {{buyside.screenTitle}} </span> 
                </ng-template>
                <ng-template pTemplate="right">
                    <button pButton pRipple label="Back" icon="pi pi-arrow-left"  class="p-button-success mr-2 mb-3" (click)="appUtil.return()"></button>
                    <button pButton pRipple label="Search Prospects" icon="pi pi-search"
                        class="p-button-success mr-2 mb-3" (click)="searchmenu.show($event)"></button>
                    <button pButton pRipple label="Download" class="p-button-success mr-2 mb-3"
                        (click)="downloadProspect()"></button>
                    <button pButton pRipple label="Upload" icon="pi pi-upload" class=" mr-2 mb-3"
                        (click)="uploadmenu.show($event)"></button>
                    <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner"
                        strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"
                        *ngIf="loadingSpinner"></p-progressSpinner>
                </ng-template>
            </p-toolbar>
            <tbp-search #so [fields]="fieldInfo" searchTermPlaceHolder="Search Prospects By Name" [pageId]="buysideProspectPageId"
                filterFor="BUYSIDE_PROSPECT" [searchURL]="searchUrl" (onSearch)="_search($event)"
                (onShowHideField)="_updateSelectedColumns($event)" defaultSortField="sortOrder" defaultSortOrder="1"
                defaultPageSize="-1" [entityID]="buyside.uuid" [entityDefaulOptionAvailable]="true"
                [allowCustomLabels]="true" (onCustomLabel)="setCustomLabel($event)">
            </tbp-search>
            <div  [style]="{'text-align':'right' ,'color' :'#2196F3 '}">
                <p-button  label="Number Of Prospects" styleClass="p-button-warning font-size mr-2" badge="{{totalRecords}}" badgeClass="p-badge-info"></p-button>
                <button type="button" (click)="showDialog()" pButton icon="pi pi-external-link" label="View Fullscreen"></button>
            </div>
            <p-table [autoLayout]="true" #dt [value]="prospects" dataKey="uuid" [columns]="selectedColumns"
                [lazy]="true" (onLazyLoad)="so.loadData($event.first,$event.rows,$event.sortField,$event.sortOrder)"
                [lazyLoadOnInit]="false"
                styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm" [rowHover]="true"
                [loading]="loading" [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [totalRecords]="totalRecords" [sortField]="so.sortField" [sortOrder]="so.sortOrder"
                (onEditInit)="initEdit($event)" (onEditComplete)="editCompleted($event)" 
                (onEditCancel)="editCancelled($event)" >
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th></th>
                        <ng-container *ngFor="let column of columns;">
                            <th class="iconCell" pSortableColumn="{{column.property}}" [pSortableColumnDisabled]="!column.sortable">
                                {{appUtil.getCustomLabel(column, customLabels)}}
                                <p-sortIcon field="{{column.property}}" *ngIf="column.sortable"></p-sortIcon>
                            </th>
                        </ng-container>
                        <th>Action</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-prospect let-rowIndex="rowIndex" let-columns="columns"
                    let-expanded="expanded">
                    <tr>
                        <td style="text-align: center;" [pReordableRowDrop]="rowIndex"
                            (dropRow)="reorderProspect($event)">
                            <span class="pi pi-arrows-v" pReorderableRowHandle style="vertical-align: super;"></span>
                            <button type="button" class="p-button-text p-button-rounded p-button-plain" pButton pRipple
                                [pRowToggler]="prospect"
                                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                                *ngIf="prospect.organization != null"></button>
                        </td>
                        <ng-container *ngFor="let column of columns; let colIndex = index">
                            <ng-container *ngIf="column.property == 'prospectName';else notProspectField">
                                <td id="Mona" class="wrap capitalize">
                                    <span class="p-column-title">{{appUtil.getCustomLabel(column, customLabels)}}</span>
                                    <span *ngIf="prospect.organization != null">
                                        <a 
                                            [routerLink]="['/organization/', prospect.organization.uuid]" [innerHTML]="prospect.organization.name | highlight :term"></a>
                                    </span>
                                    <span *ngIf="prospect.organization == null && prospect.contacts != null">
                                        <a [routerLink]="['/contact/', prospect.contacts[0].uuid]"[innerHTML]="prospect.contacts[0].name | highlight:term"></a>
                                    </span>
                                </td>
                            </ng-container>
                            <ng-template #notProspectField>
                                <ng-container *ngIf="column.property == 'linkedinPageId';else notLinkedinPageId">
                                    <td class="wrap" [pEditableColumn]="prospect" [pEditableColumnField]="'linkedInProfile'" [pEditableColumnRowIndex]="index">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <span class="p-column-title">{{appUtil.getCustomLabel(column,
                                                    customLabels)}}</span>
                                                    <span *ngIf="prospect.organization != null">
                                                <input pInputText type="text" mode="edit" (keydown)="disableNavigation($event)"
                                                    [(ngModel)]="prospect.linkedInProfile" (focus)="isContactLinkedIn = false">
                                                    </span>
                                                    <span *ngIf="prospect.organization == null && prospect.contacts != null">
                                                        <input pInputText type="text" mode="edit" (keydown)="disableNavigation($event)" (focus)="isContactLinkedIn = true"
                                                            [(ngModel)]="prospect.contacts[0].linkedInProfile">
                                                            </span>
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                        <span class="p-column-title">{{appUtil.getCustomLabel(column, customLabels)}}</span>
                                        <span *ngIf="prospect.organization != null">
                                            {{prospect.organization.linkedinPageId}}
                                        </span>
                                        <span *ngIf="prospect.organization == null && prospect.contacts != null">
                                            {{prospect.contacts[0].linkedInProfile }}
                                        </span>
                                        </ng-template>
                                        </p-cellEditor>
                                    </td>
                                </ng-container>
                              </ng-template>
                                <ng-template #notLinkedinPageId>
    
                                <ng-container *ngIf="column.property == 'task'; else notTask">
                                  <td class="wrap" [pEditableColumn]="prospect" [pEditableColumnField]="'task'" [pEditableColumnRowIndex]="index">
                                    <tr *ngIf="prospect.taskDTO !== null">
                                      <th>Date</th>
                                      <th>Subject</th>
                                      <th>Done</th>
                                    </tr>
                                    <ng-container *ngFor="let task of prospect.taskDTO">
                                      <tr>
                                        <td >{{ task.dueDate | date:'MM/dd' }}</td>
                                        <td >
                                          <a href="javascript:void(0)" (click)="editTask(task)">{{ task.subject }}</a>
                                        </td>
                                        <td>{{ task.status == 'COMPLETED' ? 'Yes' : 'No' }}</td>
                                      </tr>
                                    </ng-container>
                                    <tr>
                                        <td colspan="3">
                                            <button pButton title="Add Task" (click)="addTask(prospect)" label="" icon="pi pi-plus" class="p-button-text mr-2"></button>
                                        </td>
                                    </tr>
                                  </td>
                                </ng-container>
                              </ng-template>            
                <ng-template #notTask>
                    <ng-container
                        *ngIf="(prospect.organization != null && column.property == 'website');else notWebsiteField">
                        <td class="wrap" [pEditableColumn]="prospect" [pEditableColumnField]="'website'"
                            [pEditableColumnRowIndex]="index">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <span class="p-column-title">{{appUtil.getCustomLabel(column,
                                        customLabels)}}</span>
                                    <input pInputText type="text" mode="edit" (keydown)="disableNavigation($event)"
                                        [(ngModel)]="prospect.website">
                                </ng-template>
                                <ng-template pTemplate="output">
                                    <button icon="pi pi-external-link" type="button"
                                        class="p-button-text p-button-plain mr-2" pButton pRipple
                                        (click)="appUtil.externalLink(prospect.organization.website || prospect.website, 'url')"
                                        *ngIf="prospect.organization.website != null || prospect.website != null"></button>
                                    <span class="p-column-title">{{ appUtil.getCustomLabel(column,
                                        customLabels) }}</span>
                                    {{ prospect.organization.website}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                    </ng-container>
                </ng-template>
                <ng-template #notWebsiteField>
                    <ng-container *ngIf="column.property == 'employees';else notEmployeesField">
                        <td class="wrap" [pEditableColumn]="prospect" [pEditableColumnField]="'employees'"
                            [pEditableColumnRowIndex]="index">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <span class="p-column-title">{{appUtil.getCustomLabel(column,
                                        customLabels)}}</span>
                                    <p-multiSelect [multiple]="false" [options]="getTBPEmployees(prospect)"
                                        [(ngModel)]="prospect.employees" optionLabel="name" placeholder="Choose"
                                        appendTo="body" dataKey="uuid"
                                        (keydown)="disableNavigation($event)"></p-multiSelect>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    <span class="p-column-title">{{appUtil.getCustomLabel(column,
                                        customLabels)}}</span>
                                    <span *ngFor="let emp of prospect.employees; let last = last;"
                                        pTooltip="{{emp.name}}" tooltipPosition="bottom">
                                        {{appUtil.convertNameToInitials(emp.name) }}{{last?'':','}}
                                    </span>
                                </ng-template>
                            </p-cellEditor>
                        </td>
                    </ng-container>
                </ng-template>
                <ng-template #notEmployeesField>
                    <ng-container  *ngIf="column.property == 'portfolioCompanies';else notPortfolioCompanyNotes">
                        <td class="wrap" [pEditableColumn]="prospect" [pEditableColumnField]="'portfolioCompanies'" [pEditableColumnRowIndex]="index">
                            <ng-container>
                                <span class="p-column-title">{{appUtil.getCustomLabel(column, customLabels)}}</span>
                                <span *ngFor="let ppc of prospect.portfolioCompanies">
                                    <ul>
                                        <li>
                                            <a [routerLink]="['/organization/', ppc.uuid]">{{ppc.name}}</a>
                                        </li>
                                    </ul> 
                                </span>
                                <tr>
                                    <td colspan="3">
                                        <button pButton title="Add Portfolio" (click)="portfolioDialog(prospect)" label="" icon="pi pi-plus" class="p-button-text mr-2"></button>
                                    </td>
                                </tr>
                            </ng-container>    
                        </td>
                    </ng-container>
                </ng-template>
                <ng-template #notPortfolioCompanyNotes>
                    <ng-container
                        *ngIf="column.property == 'status' || column.property =='customLookupField1' || column.property =='customLookupField2' ; else notStatusField ">
                        <td class="wrap" [pEditableColumn]="prospect" [pEditableColumnField]="column.property"
                            [pEditableColumnRowIndex]="index">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <span class="p-column-title">{{ appUtil.getCustomLabel(column,
                                        customLabels) }}</span>
                                    <look-autocomplete [multiple]="false" [type]="getLookupType(column.property)"
                                        [(ngModel)]="prospect[column.property]"  [entityType]="'BUYSIDE'" [entityId]="buyside.uuid"
                                        [items]="getLookupItems(column.property)" tooltipPosition="top"
                                        (keydown)="disableNavigation($event)">
                                    </look-autocomplete>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    <span class="p-column-title">{{ appUtil.getCustomLabel(column,
                                        customLabels) }}</span>
                                    {{ getOutputValue(column.property, prospect) }}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                    </ng-container>
                </ng-template>
                <ng-template #notStatusField>
                    <td class="wrap" [pEditableColumn]="prospect" [pEditableColumnField]="column.property"
                        [pEditableColumnRowIndex]="index">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <span class="p-column-title">{{appUtil.getCustomLabel(column,
                                    customLabels)}}</span>
                                <ng-container
                                    *ngIf="column.property == 'sendProcessLetter'; else notSendProcessLetterField">
                                    <p-radioButton name="sendprocessletter" [value]="true"
                                        (keydown)="disableNavigation($event)" [(ngModel)]="prospect[column.property]"
                                        label="Y"></p-radioButton>
                                    <p-radioButton name="sendprocessletter" [value]="false"
                                        (keydown)="disableNavigation($event)" [(ngModel)]="prospect[column.property]"
                                        label="N" class="ml-2"></p-radioButton>
                                </ng-container>
                                <ng-template #notSendProcessLetterField>
                                    <ng-container
                                        *ngIf="(column.property == 'tier' || column.property == 'cimVersion'|| column.property == 'customIntField1'|| column.property == 'customIntField2' ); else notTierField">
                                        <p-inputNumber [(ngModel)]="prospect[column.property]"
                                            (keydown)="disableNavigation($event)" maxlength="12"></p-inputNumber>
                                    </ng-container>
                                </ng-template>
                                <ng-template #notTierField>
                                    <ng-container
                                        *ngIf="(column.property == 'customNumberField1' || column.property == 'customNumberField2'  ); else notCustomNumberField">
                                        <p-inputNumber [(ngModel)]="prospect[column.property]" mode="decimal"
                                            [minFractionDigits]="2" (keydown)="disableNavigation($event)"
                                            maxlength="12"></p-inputNumber>
                                    </ng-container>
                                </ng-template>
                                <ng-template #notCustomNumberField>
                                    <ng-container
                                    *ngIf="(column.label.indexOf('Notes') != -1 || column.property=='internalNotes' || column.property=='processNotes' || column.property=='thesis' || column.property=='description'); else notNoteField">
                                        <p-dialog [visible]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
                                            [style]="{width: '50vw'}" [closable]="false" [modal]="true">
                                            <ng-template pTemplate="header">
                                                <div class="p-fluid formgrid grid">
                                                    <div class="field col-12">
                                                        <strong>{{prospect.organization!=null?prospect.organization.name:prospect.contacts[0].name}}:</strong>
                                                        {{column.label}}
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template pTemplate="content">
                                                <editor [(ngModel)]="prospect[column.property]"
                                                    [init]="appUtil.getInitData(180,null)"></editor>
                                            </ng-template>
                                            <ng-template pTemplate="footer">
                                                <button pButton pRipple label="Save" icon="pi pi-check"
                                                    class="p-button-text"
                                                    (click)="editorAction($event, prospect, column.property, false)"></button>
                                                <button pButton pRipple label="Close" class="p-button-text"
                                                    (click)="editorAction($event, prospect, column.property, true)"></button>
                                            </ng-template>
                                        </p-dialog>
                                    </ng-container>
                                </ng-template>
                                <ng-template #notNoteField>
                                    <input pInputText type="text" mode="text" (keydown)="disableNavigation($event)"
                                        [(ngModel)]="prospect[column.property]" *ngIf=" column.property != 'website'">
                                </ng-template>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span class="p-column-title">{{appUtil.getCustomLabel(column,
                                    customLabels)}}</span>
                                <ng-container *ngIf="column.property == 'sendProcessLetter'">
                                    {{prospect[column.property] === true?'Y':(prospect[column.property] ===
                                    false?'N':'')}}
                                </ng-container>
                                <ng-container
                                *ngIf="column.label.indexOf('Notes') != -1 || column.property=='internalNotes' || column.property=='processNotes' || column.property == 'portfolioCompanyNotes' || column.property=='thesis' || column.property=='description'">
                                    <span class="limitedText limitText" [innerHTML]="prospect[column.property]"></span>
                                    <a href="javascript:" (click)="appUtil.showHideClampedContent($event)"
                                        class="moreless hidden ml-2 p-button-danger"
                                        style="float:right;display:block;">More</a>
                                </ng-container>
                                <ng-container
                                *ngIf="column.property != 'sendProcessLetter' && !(column.label.indexOf('Notes') != -1 || column.property=='internalNotes' || column.property=='processNotes'  || column.property=='thesis' || column.property=='description')">
                                    {{prospect[column.property]}}
                                </ng-container>
                            </ng-template>
                        </p-cellEditor>
                    </td>
                </ng-template>
                </ng-container>
                <td style="text-align: center;">
                    <button type="button" pButton icon="pi pi-list"
                        (click)="setCurrentItem(prospect, null); menu.toggle($event)"></button>
                </td>
                </tr>
                </ng-template>
                <ng-template pTemplate="rowexpansion" let-prospect>
                    <tr>
                        <td></td>
                        <td [attr.colspan]="selectedColumns.length" style="background-color:#64748B">  
                          <div style="text-align: left;"  class="p-1">
                                <button  pButton title="Add associated contacts"
                                (click)="togglePanelAndGetValue($event,prospect)"
                                icon="pi pi-search-plus"></button>
                          </div>
                          <div class="p-1">
                            <p-table [value]="prospect.contacts" dataKey="uuid" editMode="row"   (onEditInit)="initEdit1($event)" (onEditComplete)="editCompleted1($event)" >
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="name">Name</th>
                                        <th pSortableColumn="Email">Email</th>
                                        <th pSortableColumn="Title">Title</th>
                                        <th pSortableColumn="Notes">LinkedIn Profile</th>
                                        <th pSortableColumn="primaryContact">Primary Contact</th>
                                        <th pSortableColumn="Notes">Notes</th>
                                        <th pSortableColumn="Tasks">Tasks</th>
                                        <th>Action</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-contact  let-ri="rowIndex">
                                    <tr>
                                        <td class="wrap" [pEditableColumn]="contact"
                                            [pEditableColumnField]="'name'" [pEditableColumnRowIndex]="index">
                                            <ng-container *ngIf="!isEditing(contact.uuid); else editMode">
                                                <div class="contact-info">
                                                <a [routerLink]="['/contact', contact.uuid]">{{ contact.name }}</a>
                                                <sup *ngIf=" contact.status == 'PENDING_ACTIVATION' || contact.status == 'PENDING_PROFILE' || contact.status == 'REVOKED' || !contact.verified">
                                                    <p-badge class="small-p-badge" [pTooltip]="appUtil.getUserStatus(contact)"
                                                    [ngClass]="{'pendingactivation': contact.status == 'PENDING_ACTIVATION','pendingprofile': contact.status == 'PENDING_PROFILE',
                                                    'pendingverification': (!contact.verified && !contact.selfSignUp),
                                                    'pendingverificationself': (!contact.verified && contact.selfSignUp),'revoked': contact.status == 'REVOKED'}"></p-badge>
                                                </sup>
                                                <button pButton type="button" icon="pi pi-pencil" title="Edit" class="p-button-text edit-button"
                                                    (click)="toggleEditingMode(contact.uuid)"></button>
                                                </div>
                                            </ng-container>
                                            <ng-template #editMode>
                                                <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input pInputText type="text" [(ngModel)]="contact.name"
                                                    (keydown)="disableNavigation($event)" />
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    <a [routerLink]="['/contact', contact.uuid]">{{ contact.name }}</a>
                                                </ng-template>
                                                </p-cellEditor>
                                            </ng-template>
                                        </td>
                                        <td [pEditableColumn]="contact" [pEditableColumnField]="'email'">
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input pInputText [(ngModel)]="contact.email">
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{contact.email}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td [pEditableColumn]="contact" [pEditableColumnField]="'title'">
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input pInputText [(ngModel)]="contact.title">
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{contact.title}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td [pEditableColumn]="contact" [pEditableColumnField]="'linkedInProfile'">
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input pInputText type="text" mode="text" [(ngModel)]="contact.linkedInProfile " appendTo="body" dataKey="uuid">
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{contact.linkedInProfile}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td [pEditableColumn]="contact" [pEditableColumnField]="'primaryContact'">
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <p-checkbox [(ngModel)]="contact.primaryContact" binary="true"></p-checkbox>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{contact.primaryContact ? 'Yes' : 'No'}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <td style="position: relative;">
                                            <ng-container *ngFor="let note of contact.noteDTO">
                                                <tr>
                                                    <li></li>
                                                    <td>  
                                                        <div [innerHTML]="note.notes"></div>
                                                    </td>                                      
                                                </tr>
                                            </ng-container>
                                            <button pButton title="Add Note" (click)="addNote(prospect, contact)" label="" icon="pi pi-plus" 
                                            class="p-button-text" style="position: absolute; top: 0; right: 0;"></button>
                                        </td> 
                                        <td style="position: relative;">
                                            <ng-container *ngFor="let task of contact.taskDTO">
                                                <tr>
                                                    <li></li>
                                                    <td>  
                                                        {{ task.subject}}
                                                    </td>                                      
                                                </tr>
                                            </ng-container>
                                            <button pButton title="Add Task" (click)="addTask(prospect, contact)" label="" icon="pi pi-plus" 
                                            class="p-button-text" style="position: absolute; top: 0; right: 0;"></button>
                                        </td>
                                        <td style="text-align: center;">
                                            <button type="button" pButton icon="pi pi-list" (click)="setCurrentItem(prospect, contact); menu.toggle($event)"></button>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="8">There are no contacts for this organization yet.</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                        </td>
                        <td></td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="25">
                            <span>No prospects found</span>
                        </td>
                    </tr>
                </ng-template>      
            </p-table>
    </div>
</div>
</div>
<app-upload-excel (onSuccess)="afterUpload()" [(show)]="showUpload" [url]="uploadUrl" [title]="uploadTitle"></app-upload-excel>
<task-edit [(show)]="tasksDialog" taskType="buyside-prospects" (afterSave)="reload()" [prospectContact] = "prospectContacts" [parent]="selectedProspect" [(taskUuid)]="taskUuid"></task-edit>
<note-edit [(show)]="noteDialog" noteType="buyside-prospects"(afterSave)="reload()" [prospectContact] = "prospectContacts"  [parent]="selectedProspect" [(noteUuid)]="noteUuid"></note-edit>
<p-menu #searchmenu [popup]="true" [model]="searchMenuItems"></p-menu>
<p-menu #uploadmenu [popup]="true" [model]="uploadMenuItems"></p-menu>
<p-menu #menu [popup]="true" [model]="menuItems"></p-menu>
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
<p-toast></p-toast>
<p-dialog [(visible)]="showCampaignList" header="Email Campaigns" styleClass="p-fluid" [style]="{width: '400px'}">
    <ng-template pTemplate="content">
        <ng-container *ngFor="let camp of campaignsForAssociation; let last = last">
            <p-checkbox name="campaignsForAssocaiation" [(ngModel)]="selectedCampaigns" [value]="camp"
                styleClass="form-control p-mb-2" label="{{camp.title}}">
            </p-checkbox><br><br>
        </ng-container>
    </ng-template>
    <ng-template pTemplate="footer">
        <ng-container>
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
                (click)="showCampaignList = false"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
                (click)="addProspectAsRecipient()"></button>
        </ng-container>
    </ng-template>
</p-dialog>
<p-overlayPanel #op [dismissable]="true" (onHide)="resetSearchTerm()">
    <ng-template pTemplate="content" let-organization> 
        <ng-container  *ngIf="allContacts && allContacts.length > 0">
        <div class="field col-12">
            <i class="pi pi-exclamation-triangle mr-2"></i>Are you sure that you want to add the contacts listed below?
            <span class="p-input-icon-right mr-2" style="margin-left: 200px;">
                <input [(ngModel)]="searchTermForAssocited" pInputText type="text" class="table-search" (keyup.enter)="searchContacts()" id="act_term" [placeholder]="'Search Contacts'"/>
                <i class="pi pi-search"></i>
            </span>
            <button pButton type="button" label="Search" class="mr-2" (click)="searchContacts()"></button>
        </div>      
        </ng-container>
           <tr>
                <div class="p-1">
                    <p-table [value]="filteredContacts" dataKey="uuid" [autoLayout]="true"  sortField="pSortableColumn" sortMode="single"  
                    [lazy]="true" 
                    styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm" [rowHover]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 3rem"></th>
                                <th pSortableColumn="name">Name<p-sortIcon field="name"></p-sortIcon></th>
                                <th pSortableColumn="email">Email<p-sortIcon field="email"></p-sortIcon></th>
                                <th pSortableColumn="title">Title<p-sortIcon field="title"></p-sortIcon></th>
                                <th pSortableColumn="primaryContact">Primary Contact</th>    
                                <th pSortableColumn="primaryContact">Is Associated Contacts?</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-contact of filteredContacts>
                            <tr>
                                <td>
                                    <p-checkbox  [value]="contact.uuid"  [(ngModel)]="selectedValues"></p-checkbox>
                                </td>
                                <td><a [routerLink]="['/contact', contact.uuid]">{{contact.name}}
                                    <sup
                                        *ngIf=" contact.status == 'PENDING_ACTIVATION' || contact.status == 'PENDING_PROFILE'  || contact.status == 'REVOKED' || !contact.verified">
                                        <p-badge class="small-p-badge" [pTooltip]="appUtil.getUserStatus(contact)"
                                            [ngClass]="{'pendingactivation': contact.status == 'PENDING_ACTIVATION','pendingprofile': contact.status == 'PENDING_PROFILE',
                                                    'pendingverification': (!contact.verified && !contact.selfSignUp),
                                                    'pendingverificationself': (!contact.verified && contact.selfSignUp),'revoked': contact.status == 'REVOKED'}"></p-badge>
                                    </sup>
                                </a></td>
                                <td>{{contact.email}}</td>
                                <td>{{contact.title}}</td>
                                <td>{{contact.primaryContact? 'Yes':'No'}}</td>
                                <td> {{ contact && associatedContacts.includes(contact) ? 'No' : 'Yes' }}</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="6">There are no contacts for this organization yet.</td>
                            </tr>
                        </ng-template>
                           
                    </p-table>
                   <div  *ngIf="allContacts && allContacts.length > 0" style="align-items: right; margin-left: 750px;">
                        <button pButton pRipple label="Add" icon="pi pi-check" class="p-button-text"
                        (click)="addToSource()"></button>
                        <button pButton pRipple label="Close" class="p-button-text"
                        (click)="resetSearchTerm()"></button>
                    </div>
                </div>
        </tr>
    </ng-template>
  </p-overlayPanel>
  <p-dialog [(visible)]="showPortfolioDialog" header="Add Portfolio" styleClass="p-fluid" [style]="{width: '400px'}">
        <ng-template pTemplate="input">
            <tbp-autocomplete [multiple]="true"  [type]="'Account'" [items]="" [(ngModel)] ="prospectValue.portfolioCompanies" tooltipPosition="top"> </tbp-autocomplete>             
        </ng-template>
    <ng-template pTemplate="footer">
        <ng-container>
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
                (click)="hidePortfolioDialog()"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
                (click)="addPortfolioToProspect()"></button>
        </ng-container>
    </ng-template>
</p-dialog>

<p-dialog header="{{buyside.screenTitle}}" #dialog (onShow)="dialog.maximize()" [resizable]="false" [modal]="true" [(visible)]="dialogVisible" [baseZIndex]="10000">
    <p-table #dt [value]="prospects" [resizableColumns]="true" [scrollable]="true" scrollHeight="flex" dataKey="uuid" [columns]="selectedColumns"
                    [lazy]="true" (onLazyLoad)="so.loadData($event.first,$event.rows,$event.sortField,$event.sortOrder)" [lazyLoadOnInit]="false"  
                    styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm" [rowHover]="true"
                    [loading]="loading" [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    [totalRecords]="totalRecords" [sortField]="so.sortField" [sortOrder]="so.sortOrder"
                    (onEditInit)="initEdit($event)" (onEditComplete)="editCompleted($event)" (onEditCancel)="editCancelled($event)" stateStorage="session" stateKey="statedemo-session">
                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th pResizableColumn></th>
                            <ng-container *ngFor="let column of columns;">
                                <th pResizableColumn pSortableColumn="{{column.property}}" [pSortableColumnDisabled] ="!column.sortable">{{appUtil.getCustomLabel(column, customLabels)}}
                                    <p-sortIcon field="{{column.property}}"  *ngIf="column.sortable"></p-sortIcon>
                                </th>
                            </ng-container>
                           
                            <th pResizableColumn>Action</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-prospect let-rowIndex="rowIndex" let-columns="columns" let-expanded="expanded">
                        <tr>
                            <td style="text-align: center;" [pReordableRowDrop]="rowIndex" (dropRow)="reorderProspect($event)">
                                <span class="pi pi-arrows-v" pReorderableRowHandle style="vertical-align: super;"></span>
                                <button type="button" class="p-button-text p-button-rounded p-button-plain" pButton pRipple [pRowToggler]="prospect"
                                    [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'" *ngIf="prospect.organization != null"></button>
                            </td>
                             <ng-container *ngFor="let column of columns; let colIndex = index">
                                <ng-container  *ngIf="column.property == 'prospectName';else notProspectField">
                                    <td class="wrap capitalize">
                                        <span class="p-column-title">{{appUtil.getCustomLabel(column, customLabels)}}</span>
                                        <span *ngIf="prospect.organization != null">
                                            <a [routerLink]="['/organization/', prospect.organization.uuid]" [innerHTML]="prospect.organization.name | highlight :term"></a>
                                        </span>
                                        <span *ngIf="prospect.organization == null && prospect.contacts != null">
                                            <a [routerLink]="['/contact/', prospect.contacts[0].uuid]" [innerHTML]="prospect.contacts[0].name | highlight:term"></a>
                                        </span>
                                    </td>
                                </ng-container>
                                <ng-template #notProspectField>
                                    <ng-container *ngIf="column.property == 'linkedinPageId';else notLinkedinPageId">
                                        <td class="wrap" [pEditableColumn]="prospect" [pEditableColumnField]="'linkedInProfile'" [pEditableColumnRowIndex]="index">
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <span class="p-column-title">{{appUtil.getCustomLabel(column,
                                                        customLabels)}}</span>
                                                        <span *ngIf="prospect.organization != null">
                                                    <input pInputText type="text" mode="edit" (keydown)="disableNavigation($event)"
                                                        [(ngModel)]="prospect.linkedInProfile" (focus)="isContactLinkedIn = false">
                                                        </span>
                                                        <span *ngIf="prospect.organization == null && prospect.contacts != null">
                                                            <input pInputText type="text" mode="edit" (keydown)="disableNavigation($event)" (focus)="isContactLinkedIn = true"
                                                                [(ngModel)]="prospect.contacts[0].linkedInProfile">
                                                                </span>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                            <span class="p-column-title">{{appUtil.getCustomLabel(column, customLabels)}}</span>
                                            <span *ngIf="prospect.organization != null">
                                                {{prospect.organization.linkedinPageId}}
                                            </span>
                                            <span *ngIf="prospect.organization == null && prospect.contacts != null">
                                                {{prospect.contacts[0].linkedInProfile }}
                                            </span>
                                            </ng-template>
                                            </p-cellEditor>
                                        </td>
                                    </ng-container>
                                  </ng-template>
                                    <ng-template #notLinkedinPageId>
                                    <ng-container *ngIf="column.property == 'task'; else notTask">
                                      <td class="wrap" [pEditableColumn]="prospect" [pEditableColumnField]="'task'" [pEditableColumnRowIndex]="index">
    <table width="100%">
                                        <tr *ngIf="prospect.taskDTO !== null">
                                          <th align="center">Date</th>
                                          <th align="center">Subject</th>
                                          <th align="center">Done</th>
                                        </tr>
                                        <ng-container *ngFor="let task of prospect.taskDTO">
                                          <tr>
                                            <td align="center">{{ task.dueDate | date:'MM/dd' }}</td>
                                            <td align="center">
                                              <a href="javascript:void(0)" (click)="editTask(task)">{{ task.subject }}</a>
                                            </td>
                                            <td align="center">{{ task.status == 'COMPLETED' ? 'Yes' : 'No' }}</td>
                                          </tr>
                                        </ng-container>
                                        <tr>
                                          <td colspan="3" align="center">
                                            <button pButton title="Add Task" (click)="addTask(prospect)" label="" icon="pi pi-plus" class="p-button-text mr-2"></button>
                                          </td>
                                        </tr>
     </table>
                                      </td>
                                    </ng-container>
                                  </ng-template>          
                                 <ng-template #notTask>
                                <ng-container  *ngIf="(prospect.organization != null && column.property == 'website') ;else notWebsiteField">
                                    <td class="wrap" [pEditableColumn]="prospect" [pEditableColumnField]="'website'" [pEditableColumnRowIndex]="index">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                    <span class="p-column-title">{{appUtil.getCustomLabel(column, customLabels)}}</span>
                                                    <input pInputText type="text" mode="text" [(ngModel)]="prospect.website " (keydown)="disableNavigation($event)" appendTo="body" dataKey="uuid">
                                            </ng-template>
                                            <ng-template pTemplate="output">  
                                                    <button  icon= "pi pi-external-link" type="button" class="p-button-text p-button-plain mr-2"  pButton pRipple  (click)="appUtil.externalLink(prospect.organization.website || prospect.website, 'url')"
                                                    *ngIf="(  prospect.organization.website || prospect.website != null)"></button>
                                                    <span class="p-column-title" >{{ appUtil.getCustomLabel(column, customLabels) }} </span>
                                                 {{ prospect.website }}
                                        </ng-template>
                                        </p-cellEditor>
                                    </td>
                                  </ng-container>
                                </ng-template>  
                                <ng-template #notWebsiteField>
                                    <ng-container  *ngIf="column.property == 'employees';else notEmployeesField">
                                        <td class="wrap" [pEditableColumn]="prospect" [pEditableColumnField]="column.property" [pEditableColumnRowIndex]="index">
                                             <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <span class="p-column-title">{{appUtil.getCustomLabel(column, customLabels)}}</span>
                                                    <p-multiSelect [options]="getTBPEmployees(prospect)" (keydown)="disableNavigation($event)" [(ngModel)]="prospect.employees" optionLabel="name" placeholder="Choose" appendTo="body" dataKey="uuid"></p-multiSelect>
                                                   </ng-template>
                                                <ng-template pTemplate="output">
                                                    <span class="p-column-title">{{appUtil.getCustomLabel(column, customLabels)}}</span>
                                                    <span *ngFor="let emp of prospect.employees; let last = last;" pTooltip="{{emp.name}}" tooltipPosition="bottom">
                                                        {{appUtil.convertNameToInitials(emp.name) }}{{last?'':','}}
                                                    </span>
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                    </ng-container>
                                </ng-template>
                                <ng-template #notEmployeesField>
                                    <ng-container  *ngIf="column.property == 'portfolioCompanies';else notPortfolioCompanyNotes">
                                        <td class="wrap" [pEditableColumn]="prospect" [pEditableColumnField]="'portfolioCompanies'" [pEditableColumnRowIndex]="index">
                                            <ng-container>
                                                <span class="p-column-title">{{appUtil.getCustomLabel(column, customLabels)}}</span>
                                                <span *ngFor="let ppc of prospect.portfolioCompanies">
                                                    <ul>
                                                        <li>
                                                            <a [routerLink]="['/organization/', ppc.uuid]">{{ppc.name}}</a>
                                                        </li>
                                                    </ul>
                                                </span>
                                                <tr>
                                                    <td colspan="3">
                                                        <button pButton title="Add Portfolio" (click)="portfolioDialog(prospect)" label="" icon="pi pi-plus" class="p-button-text mr-2"></button>
                                                    </td>
                                                </tr>
                                            </ng-container>    
                                        </td>
                                    </ng-container>
                                </ng-template>
                                <ng-template #notPortfolioCompanyNotes>
                                    <ng-container  *ngIf="column.property == 'status' || column.property =='customLookupField1' || column.property =='customLookupField2' ; else notStatusField ">
                                        <td class="wrap" [pEditableColumn]="prospect" [pEditableColumnField]="column.property" [pEditableColumnRowIndex]="index">
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <span class="p-column-title">{{ appUtil.getCustomLabel(column, customLabels) }}</span>
                                                    <look-autocomplete [multiple]="false" (keydown)="disableNavigation($event)" [type]="getLookupType(column.property)" [(ngModel)]="prospect[column.property]"
                                                     [items]="getLookupItems(column.property)" tooltipPosition="top">
                                                    </look-autocomplete>
                                                </ng-template>  
                                                <ng-template pTemplate="output">
                                                    <span class="p-column-title">{{ appUtil.getCustomLabel(column, customLabels) }}</span>
                                                    {{ getOutputValue(column.property, prospect) }}
                                                </ng-template>
                                            </p-cellEditor>                        
                                       </td>
                                    </ng-container>
                                </ng-template>
                                <ng-template #notStatusField>
                                    <td class="wrap" [pEditableColumn]="prospect" [pEditableColumnField]="column.property" [pEditableColumnRowIndex]="index">
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <span class="p-column-title">{{appUtil.getCustomLabel(column, customLabels)}}</span>
                                                <ng-container  *ngIf="column.property == 'sendProcessLetter'; else notSendProcessLetterField">
                                                    <p-radioButton name="sendprocessletter" [value]="true" [(ngModel)]="prospect[column.property]" (keydown)="disableNavigation($event)" label="Y"></p-radioButton>
                                                    <p-radioButton name="sendprocessletter" [value]="false" [(ngModel)]="prospect[column.property]"  (keydown)="disableNavigation($event)" label="N" class="ml-2"></p-radioButton>
                                                </ng-container>
                                                <ng-template #notSendProcessLetterField>
                                                    <ng-container  *ngIf="(column.property == 'tier' || column.property == 'cimVersion'|| column.property == 'customIntField1'|| column.property == 'customIntField2' ); else notTierField">
                                                        <p-inputNumber [(ngModel)]="prospect[column.property]" maxlength="12" (keydown)="disableNavigation($event)" ></p-inputNumber>
                                                    </ng-container>
                                                </ng-template>
                                                <ng-template #notTierField>
                                                    <ng-container  *ngIf="(column.property == 'customNumberField1' || column.property == 'customNumberField2'  ); else notCustomNumberField">
                                                        <p-inputNumber [(ngModel)]="prospect[column.property]"  mode="decimal" [minFractionDigits]="2" maxlength="12" (keydown)="disableNavigation($event)"></p-inputNumber>
                                                    </ng-container>
                                                </ng-template>
                                                <ng-template #notCustomNumberField>
                                                    <ng-container *ngIf="(column.label.indexOf('Notes') != -1 || column.property=='internalNotes' || column.property=='processNotes' || column.property=='thesis' || column.property=='description'); else notNoteField">
                                                        <p-dialog [visible]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}" [closable]="false" [modal]="true" appendTo="body">
                                                            <ng-template pTemplate="header">
                                                                <div class="p-fluid formgrid grid">
                                                                    <div class="field col-12"><strong>{{prospect.organization!=null?prospect.organization.name:prospect.contacts[0].name}}:</strong> {{column.label}}
                                                                    </div>
                                                                </div>
                                                            </ng-template>
                                                            <ng-template pTemplate="content">
                                                                <editor [(ngModel)]="prospect[column.property]" [init]="appUtil.getInitData(180,null)"></editor>
                                                            </ng-template>
                                                            <ng-template pTemplate="footer">
                                                                <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
                                                                (click)="editorAction($event, prospect, column.property, false)"></button>
                                                                <button pButton pRipple label="Close" class="p-button-text"
                                                                (click)="editorAction($event, prospect, column.property, true)"></button>
                                                            </ng-template>
                                                        </p-dialog>
                                                    </ng-container>
                                                </ng-template>
                                                <ng-template #notNoteField>
                                                    <input pInputText type="text" (keydown)="disableNavigation($event)" mode="text" [(ngModel)]="prospect[column.property]" *ngIf=" column.property != 'website'">
                                                </ng-template>
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                <span class="p-column-title">{{appUtil.getCustomLabel(column, customLabels)}}</span>
                                                <ng-container *ngIf="column.property == 'sendProcessLetter'">
                                                    {{prospect[column.property] === true?'Y':(prospect[column.property] === false?'N':'')}}
                                                </ng-container>
                                                <ng-container *ngIf="column.label.indexOf('Notes') != -1 || column.property=='internalNotes' || column.property=='processNotes' || column.property == 'portfolioCompanyNotes' || column.property=='thesis' || column.property=='description'">
    
                                                    <span class="limitedText limitText" [innerHTML]="prospect[column.property]"></span>
                                                    <a href="javascript:" (click)="appUtil.showHideClampedContent($event)" class="moreless hidden ml-2 p-button-danger" style="float:right;display:block;">More</a>
                                                </ng-container>
                                                <ng-container *ngIf="column.property != 'sendProcessLetter' && !(column.label.indexOf('Notes') != -1 || column.property=='internalNotes' || column.property=='processNotes'  || column.property=='thesis' || column.property=='description')">
                                                    {{prospect[column.property]}}
                                                </ng-container>
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                </ng-template>
                            </ng-container>
                            <td style="text-align: center;">
                                <button type="button" pButton icon="pi pi-list"
                                    (click)="setCurrentItem(prospect, null); menu.toggle($event)"></button>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="rowexpansion" let-prospect>
                        <tr>
                            <td [attr.colspan]="selectedColumns.length" style="background-color:#64748B">
                                <div style="text-align: left;"  class="p-1">
                                    <button  pButton title="Add associated contacts"
                                    (click)="togglePanelAndGetValue($event,prospect)"
                                    icon="pi pi-search-plus"></button>
                              </div>
                              <div class="p-1">
                                <p-table [value]="prospect.contacts" dataKey="uuid" editMode="row" (onEditInit)="initEdit1($event)" (onEditComplete)="editCompleted1($event)">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th pSortableColumn="name">Name</th>
                                            <th pSortableColumn="Email">Email</th>
                                            <th pSortableColumn="Title">Title</th>
                                            <th pSortableColumn="Notes">LinkedIn Profile</th>
                                            <th pSortableColumn="primaryContact">Primary Contact</th>
                                            <th pSortableColumn="Notes">Notes</th>
                                            <th pSortableColumn="Tasks">Tasks</th>
                                            <th>Action</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-contact  let-ri="rowIndex">
                                        <tr>
                                            <td class="wrap" [pEditableColumn]="contact"
                                                [pEditableColumnField]="'name'" [pEditableColumnRowIndex]="index">
                                                <ng-container *ngIf="!isEditing(contact.uuid); else editMode">
                                                    <div class="contact-info">
                                                    <a [routerLink]="['/contact', contact.uuid]">{{ contact.name }}</a>
                                                    <sup *ngIf=" contact.status == 'PENDING_ACTIVATION' || contact.status == 'PENDING_PROFILE' || contact.status == 'REVOKED' || !contact.verified">
                                                        <p-badge class="small-p-badge" [pTooltip]="appUtil.getUserStatus(contact)"
                                                        [ngClass]="{'pendingactivation': contact.status == 'PENDING_ACTIVATION','pendingprofile': contact.status == 'PENDING_PROFILE',
                                                        'pendingverification': (!contact.verified && !contact.selfSignUp),
                                                        'pendingverificationself': (!contact.verified && contact.selfSignUp),'revoked': contact.status == 'REVOKED'}"></p-badge>
                                                    </sup>
                                                    <button pButton type="button" icon="pi pi-pencil" title="Edit" class="p-button-text edit-button"
                                                        (click)="toggleEditingMode(contact.uuid)"></button>
                                                    </div>
                                                </ng-container>
                                                <ng-template #editMode>
                                                    <p-cellEditor>
                                                    <ng-template pTemplate="input">
                                                        <input pInputText type="text" [(ngModel)]="contact.name"
                                                        (keydown)="disableNavigation($event)" />
                                                    </ng-template>
                                                    <ng-template pTemplate="output">
                                                        <a [routerLink]="['/contact', contact.uuid]">{{ contact.name }}</a>
                                                    </ng-template>
                                                    </p-cellEditor>
                                                </ng-template>
                                            </td>
                                            <td [pEditableColumn]="contact" [pEditableColumnField]="'email'">
                                                <p-cellEditor>
                                                    <ng-template pTemplate="input">
                                                        <input pInputText [(ngModel)]="contact.email">
                                                    </ng-template>
                                                    <ng-template pTemplate="output">
                                                        {{contact.email}}
                                                    </ng-template>
                                                </p-cellEditor>
                                            </td>
                                            <td [pEditableColumn]="contact" [pEditableColumnField]="'title'">
                                                <p-cellEditor>
                                                    <ng-template pTemplate="input">
                                                        <input pInputText [(ngModel)]="contact.title">
                                                    </ng-template>
                                                    <ng-template pTemplate="output">
                                                        {{contact.title}}
                                                    </ng-template>
                                                </p-cellEditor>
                                            </td>
                                            <td [pEditableColumn]="contact" [pEditableColumnField]="'linkedInProfile'">
                                                <p-cellEditor>
                                                    <ng-template pTemplate="input">
                                                        <input pInputText type="text" mode="text" [(ngModel)]="contact.linkedInProfile " appendTo="body" dataKey="uuid">
                                                    </ng-template>
                                                    <ng-template pTemplate="output">
                                                        {{contact.linkedInProfile}}
                                                    </ng-template>
                                                </p-cellEditor>
                                            </td>
                                            <td [pEditableColumn]="contact" [pEditableColumnField]="'primaryContact'">
                                                <p-cellEditor>
                                                    <ng-template pTemplate="input">
                                                        <p-checkbox [(ngModel)]="contact.primaryContact" binary="true"></p-checkbox>
                                                    </ng-template>
                                                    <ng-template pTemplate="output">
                                                        {{contact.primaryContact ? 'Yes' : 'No'}}
                                                    </ng-template>
                                                </p-cellEditor>
                                            </td>
                                            <td style="position: relative;">
                                                <ng-container *ngFor="let note of contact.noteDTO">
                                                    <tr>
                                                        <li></li>
                                                        <td>  
                                                            <div [innerHTML]="note.notes"></div>
                                                        </td>                                      
                                                    </tr>
                                                </ng-container>
                                                <button pButton title="Add Note" (click)="addNote(prospect, contact)" label="" icon="pi pi-plus"
                                                class="p-button-text" style="position: absolute; top: 0; right: 0;"></button>
                                            </td>
                                            <td style="position: relative;">
                                                <ng-container *ngFor="let task of contact.taskDTO">
                                                    <tr>
                                                        <li></li>
                                                        <td>  
                                                            {{ task.subject}}
                                                        </td>                                      
                                                    </tr>
                                                </ng-container>
                                                <button pButton title="Add Task" (click)="addTask(prospect, contact)" label="" icon="pi pi-plus"
                                                class="p-button-text" style="position: absolute; top: 0; right: 0;"></button>
                                            </td>
                                            <td style="text-align: center;">
                                                <button type="button" pButton icon="pi pi-list" (click)="setCurrentItem(prospect, contact); menu.toggle($event)"></button>
                                            </td>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="emptymessage">
                                        <tr>
                                            <td colspan="8">There are no contacts for this organization yet.</td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                            </td>
                            <td></td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="25">
                                <span>No prospects found</span>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
    </p-dialog>
