<div class="grid table-demo">
  <div class="col-12">
    <div class="card">
      <p-toolbar styleClass="headerSticky">
        <div class="p-toolbar-group-left">
        </div>
        <div class="p-toolbar-group-right">
          <button pButton pRipple label="Back" icon="pi pi-arrow-left" class="p-button-success mr-2" (click)="appUtil.return()"></button>
          <button pButton type="button" class="p-button-success mr-2" (click)="confirmAdd()" *ngIf="showCheckbox"
            [disabled]="!appUtil.isArray(selectedSellSide) || selectedSellSide.length == 0 "> Add Selected ({{
            appUtil.isArray(selectedSellSide) ? selectedSellSide.length : 0 }})
          </button>
          <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success mr-2" (click)="new()"></button>
          <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
            fill="#EEEEEE" animationDuration=".5s" *ngIf="loadingSpinner"></p-progressSpinner>
        </div>
      </p-toolbar><br>
      <tbp-search #ss [fields]="fieldInfo" filterFor="SELLSIDE" searchTermPlaceHolder="Search Sell Side Screen"
        [searchURL]="searchUrl" (onSearch)="search($event)" (onShowHideField)="_updateSelectedColumns($event)">
      </tbp-search>
      <p-table [autoLayout]="true" #dt [value]="sellsides" [(selection)]="selectedSellSide" dataKey="uuid"
        [columns]="selectedColumns"
        (onLazyLoad)="ss.loadData($event.first,$event.rows,$event.sortField,$event.sortOrder)" [lazyLoadOnInit]="false"
        [lazy]="true" styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm"
        [rows]="ss.pageSize" [paginator]="true" [rowsPerPageOptions]="[15,25,50,75,100]"
        [loading]="loading" [alwaysShowPaginator]="true" [showCurrentPageReport]="true" 
        [showPageLinks]="appUtil.getPagination(totalRecords)" [showFirstLastIcon]="appUtil.getPagination(totalRecords)" [totalRecords]="totalRecords"
        currentPageReportTemplate="Showing {first} to {last} of {{appUtil.getPagination(totalRecords) ? totalRecords :'many'}} entries"
        [sortField]="ss.sortField" [first]="ss.firstRow" [sortOrder]="ss.sortOrder" (onEditInit)="initEdit($event)" (onEditComplete)="editCompleted($event)"
        (onEditCancel)="editCancelled($event)">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th style="width: 3rem" *ngIf="showCheckbox">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <ng-container *ngFor="let column of columns;">
              <th pSortableColumn="{{column.property}}" [pSortableColumnDisabled]="!column.sortable">{{column.label}}
                <p-sortIcon field="{{column.property}}" *ngIf="column.sortable"></p-sortIcon>
              </th>
            </ng-container>
            <th *ngIf="!showCheckbox">Action</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-sellside let-columns="columns">
          <tr>
            <td (click)="$event.stopPropagation();" *ngIf="showCheckbox">
              <p-tableCheckbox [value]="sellside"></p-tableCheckbox>
            </td>
            <ng-container *ngFor="let column of columns; let colIndex = index">
              <ng-container *ngIf="( column.property == 'screenTitle');else notScreenTitle">
                <td class="wrap" [pEditableColumn]="sellside" [pEditableColumnField]="'screenTitle'"
                  [pEditableColumnRowIndex]="index">
                  <ng-container *ngIf="!isEditing(sellside.uuid); else editMode">
                    <div class="sellside-info">
                      <span *ngIf="sellside.screenTitle != null">
                        <a [routerLink]="['/sell-side', sellside.uuid, 'prospects']" [innerHTML]="sellside.screenTitle | highlight:term"></a>
                      </span>
                      <button type="button" pButton (click)="toggleEditingMode(sellside.uuid)" icon="pi pi-pencil" title="Edit" class="p-button-text edit-button"></button>
                    </div>
                  </ng-container>
                  <ng-template #editMode>
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <span class="p-column-title">{{appUtil.getCustomLabel(column,
                          customLabels)}}</span>
                        <input pInputText type="text" mode="edit"  [required]="true"  [ngClass]="{ 'ng-invalid ng-dirty': formsubmited && f.screenTitle.errors }" [(ngModel)]="sellside.screenTitle">
                      </ng-template>
                      <ng-template pTemplate="output">
                        <span class="p-column-title">{{ appUtil.getCustomLabel(column,
                          customLabels) }}</span>
                        <a [routerLink]="['/sell-side/'+sellside.uuid] + '/prospects'"[innerHTML]="sellside.screenTitle | highlight:term"></a>

                      </ng-template>
                    </p-cellEditor>
                  </ng-template>
                </td>
              </ng-container>
              <ng-template #notScreenTitle>
                <ng-container *ngIf="column.property == 'organization'; else notOrganization">
                  <td class="wrap" [pEditableColumn]="sellside" [pEditableColumnField]="'organization'" [pEditableColumnRowIndex]="index">
                      <p-cellEditor>
                          <ng-template pTemplate="input">
                              <span class="p-column-title">{{ appUtil.getCustomLabel(column, customLabels) }}</span>
                              <tbp-autocomplete [multiple]="false"  [required]="true" [(ngModel)]="sellside.organization"
                                 [items]="" [type]="'Account'" field="name" tooltipPosition="top">
                              </tbp-autocomplete>
                          </ng-template>
                          <ng-template pTemplate="output">
                              <span class="p-column-title">{{ appUtil.getCustomLabel(column, customLabels) }}</span>
                              <a [routerLink]="['/organization/', sellside.organization?.uuid]">{{ getOutputOrganization(sellside.organization) }}</a>
                          </ng-template>
                      </p-cellEditor>
                  </td>
              </ng-container>
              </ng-template>
              <ng-template #notOrganization>
                <ng-container *ngIf="column.property == 'contacts';else notContacts">
                  <td class="wrap" [attr.width]="column.columnWidth" [pEditableColumn]="sellside"
                    [pEditableColumnField]="'contacts'" [pEditableColumnRowIndex]="index">
                      <p-cellEditor>
                        <ng-template pTemplate="input">
                          <tbp-autocomplete [items]="" [type]="'Contact'" field="displayName" tooltipPosition="top"
                            [(ngModel)]="sellside.contacts">
                          </tbp-autocomplete>
                        </ng-template>
                        <ng-template pTemplate="output">
                          <span *ngFor="let contact of sellside.contacts; let last = last;">
                            <a [routerLink]="['/contact/', contact.uuid]">{{contact.name}}</a>{{last?'':'; '}}
                        </span>
                        </ng-template>
                      </p-cellEditor>
                  </td>
                </ng-container>
              </ng-template>
              <ng-template #notContacts>
                <ng-container
                  *ngIf="( column.property == 'createdDate' || column.property == 'lastModifiedDate') ; else notLastModifiedDate">
                  <td class="wrap">
                    {{ sellside[column.property] | date: 'yyyy-MM-dd' }}
                  </td>
                </ng-container>
              </ng-template>
              <ng-template #notLastModifiedDate>
                <ng-container *ngIf="( column.property == 'status' ) ; else notStatus">
                  <td class="wrap">
                    {{ sellside[column.property] }}
                  </td>
                </ng-container>
              </ng-template>
            <ng-template #notStatus>
              <ng-container *ngIf="column.property == 'managementPreference';else notManagementPreference">
                <td class="wrap" [attr.width]="column.columnWidth" [pEditableColumn]="sellside"
                  [pEditableColumnField]="'managementPreference'" [pEditableColumnRowIndex]="index">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <p-dropdown [options]="managementPreference" styleClass="form-control" [(ngModel)]="sellside.managementPreference"
                               optionLabel="value" optionValue="value" tooltipPosition="top"  [showClear]="true">
                        </p-dropdown>
                      </ng-template>
                      <ng-template pTemplate="output">
                        <span class="p-column-title">{{appUtil.getCustomLabel(column,
                          customLabels)}}</span>
                        {{ sellside[column.property] }}
                      </ng-template>
                    </p-cellEditor>
                </td>
              </ng-container>
            </ng-template>
            <ng-template #notManagementPreference>
              <ng-container *ngIf="column.property == 'timeline';else notTimeline">
                <td class="wrap" [attr.width]="column.columnWidth" [pEditableColumn]="sellside"
                    [pEditableColumnField]="'timeline'" [pEditableColumnRowIndex]="index">
                 <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-dropdown [options]="timeline" styleClass="form-control" [(ngModel)]="sellside.timeline"
                        optionLabel="value" optionValue="value" tooltipPosition="top"  [showClear]="true">
                   </p-dropdown>
                  </ng-template>
                 <ng-template pTemplate="output">
                     <span class="p-column-title">{{appUtil.getCustomLabel(column, customLabels)}}</span>
                          {{ sellside[column.property] }}
                 </ng-template>
                </p-cellEditor>
               </td>
              </ng-container>
            </ng-template>
              <ng-template #notTimeline>
                <ng-container
                  *ngIf=" column.property == 'level2' || column.property == 'level3' || column.property == 'ecosystem' || column.property == 'vertical'; else notLevel2">
                  <td class="wrap" [pEditableColumn]="sellside" [pEditableColumnField]="column.property"
                    [pEditableColumnRowIndex]="index">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <span class="p-column-title">{{appUtil.getCustomLabel(column, customLabels)}}</span>
                        <tbp-autocomplete [type]="getType(column.property)" [(ngModel)]="sellside.classification[column.property]"
                        [items]="getOptionsForProperty(column.property)" 
                        tooltipPosition="top">
                    </tbp-autocomplete>
                        <!-- <p-multiSelect [multiple]="false" [options]="getOptionsForProperty(column.property)"
                          [(ngModel)]="sellside.classification[column.property]" optionLabel="name" placeholder="Choose"
                          appendTo="body" dataKey="uuid" (keydown)="disableNavigation($event)"></p-multiSelect> -->
                      </ng-template>
                      <ng-template pTemplate="output">
                        <span class="p-column-title">{{appUtil.getCustomLabel(column, customLabels)}}</span>
                        <span *ngFor="let classi of sellside.classification[column.property]; let last = last;"
                          pTooltip="{{classi.name}}" tooltipPosition="bottom">
                          {{classi.name }}{{last ? '' : ','}}
                        </span>
                      </ng-template>
                    </p-cellEditor>
                  </td>
                </ng-container>
              </ng-template>
              <ng-template #notLevel2>
                <ng-container
                  *ngIf="column.property == 'level1'; else notLevel1">
                  <td class="wrap" [pEditableColumn]="sellside" [pEditableColumnField]="column.property"
                    [pEditableColumnRowIndex]="index">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <span class="p-column-title">{{appUtil.getCustomLabel(column, customLabels)}}</span>
                        <tbp-autocomplete [type]="'CATEGORY_LEVEL1'" [(ngModel)]="sellside.classification[column.property]"
                        [items]="getOptionsForProperty(column.property)" [required]="true"  [ngClass]="{ 'ng-invalid ng-dirty': formsubmited && f.level1.errors }"
                        tooltipPosition="top">
                    </tbp-autocomplete>
                        <!-- <p-multiSelect [multiple]="false" [options]="getOptionsForProperty(column.property)"
                          [(ngModel)]="buyside.classification[column.property]" optionLabel="name" placeholder="Choose"
                          appendTo="body" dataKey="uuid" (keydown)="disableNavigation($event)"></p-multiSelect> -->
                      </ng-template>
                      <ng-template pTemplate="output">
                        <span class="p-column-title">{{appUtil.getCustomLabel(column, customLabels)}}</span>
                        <span *ngFor="let classi of sellside.classification[column.property]; let last = last;"
                          pTooltip="{{classi.name}}" tooltipPosition="bottom">
                          {{classi.name }}{{last ? '' : ','}}
                        </span>
                      </ng-template>
                    </p-cellEditor>
                  </td>
                </ng-container>
              </ng-template>
              <ng-template #notLevel1>
                <ng-container
                  *ngIf="( column.property=='transactionObjective' || column.property=='companyDescription' ); else notTransactionObjective">
                  <td class="wrap" #dt [pEditableColumn]="sellside" [pEditableColumnField]="'column.property'"
                    [pEditableColumnRowIndex]="index">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <p-dialog [visible]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
                          [style]="{width: '50vw'}" [closable]="false" [modal]="true">
                          <ng-template pTemplate="header">
                            <div class="p-fluid formgrid grid">
                              <strong>{{sellside.screenTitle}}:</strong>
                              {{column.label}}
                            </div>
                          </ng-template>
                          <ng-template pTemplate="content">
                            <editor [(ngModel)]="sellside[column.property]"></editor>
                          </ng-template>
                          <ng-template pTemplate="footer">
                            <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
                              (click)="editorAction($event, sellside, column.property, false)"></button>
                            <button pButton pRipple label="Close" class="p-button-text"
                              (click)="editorAction($event, sellside, column.property, true)"></button>
                          </ng-template>
                        </p-dialog>
                      </ng-template>
                      <ng-template pTemplate="output">
                        <ng-container
                          *ngIf=" column.property=='transactionObjective' || column.property=='companyDescription' ">
                          <span class="limitedText limitText" [innerHTML]="sellside[column.property]"></span>
                          <a href="javascript:" (click)="appUtil.showHideClampedContent($event)"
                            class="moreless hidden ml-2 p-button-danger" style="float:right;display:block;">More</a>
                        </ng-container>
                      </ng-template>
                    </p-cellEditor>
                  </td>
                </ng-container>
              </ng-template>
              <ng-template #notTransactionObjective>
                <ng-container *ngIf="( column.property == 'primaryObjective');else notPrimaryObjective">
                  <td class="wrap" [pEditableColumn]="sellside" [pEditableColumnField]="'primaryObjective'"
                    [pEditableColumnRowIndex]="index">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <p-dialog [visible]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
                        [style]="{width: '50vw'}" [closable]="false" [modal]="true">
                        <ng-template pTemplate="header">
                          <div class="p-fluid formgrid grid">
                            <strong>Primary objective of screen:</strong>
                          </div>
                        </ng-template>
                      <ng-template pTemplate="content">
                          <form [formGroup]="form" #cform>
                          <div class=" mb-1">
                              <div class=" col-12 mt-1">
                                  <p-checkbox [binary]="true" styleClass="form-control"   formControlName="strategicPartner"
                                       [value]="1" label="Strategic Partner"  [ngClass]="{ 'ng-invalid ng-dirty': formsubmited && f.strategicPartner.errors }"
                                      labelStyleClass="pr-2"  tooltipPosition="top" (onChange)="validatePrimaryObjective($event)"></p-checkbox>
                              </div>
                              <div class=" ml-5  mb-4">
                                  <div class="p-fluid grid">
                                    <div class="col-2 mt-4" >
                                      Private Equity Partner
                                  </div>
                                  <div class="field col-10 mt-4 ">
                                  <p-multiSelect [options]="privateEquityPartner" [ngClass]="{ 'ng-invalid ng-dirty': formsubmited && f.equityPartnerType.errors }"
                                  optionLabel="value" optionValue="value" appendTo="body" formControlName="equityPartnerType"
                                 [autoDisplayFirst]="false" placeholder="Select" [showClear]="true" [dataKey]="value" 
                                 tooltipPosition="top" (onChange)="validatePrimaryObjective($event)" >
                             </p-multiSelect>
                             </div>
                             </div>
                              </div>
                              <p-divider layout="horizontal" class=""></p-divider>
                              <div class=" font-bold mt-4">Investment</div>
                              <div class=" ml-5">
                                    <div class="p-fluid grid">
                                      <div class="col-2 mt-4">
                                          Equity
                                        </div>
                                        <div class="field col-10 mt-4 ">
                                          <p-multiSelect [options]="equity" [ngClass]="{ 'ng-invalid ng-dirty': formsubmited && f.equityType.errors }"
                                           optionLabel="value" optionValue="value" appendTo="body" formControlName="equityType"
                                          [autoDisplayFirst]="false" placeholder="Select" [showClear]="true" [dataKey]="value" 
                                          tooltipPosition="top" (onChange)="validatePrimaryObjective($event)" >
                                      </p-multiSelect>
                                      </div>
                                  </div>
                              </div>
                              <div class="mt-1 ml-5">
                                <div class="p-fluid grid">
                                  <div class="col-2 mt-4">
                                      Debt
                                  </div>
                                  <div class="field col-10 mt-4 ">

                                          <p-multiSelect [options]="debt" appendTo="body" [ngClass]="{ 'ng-invalid ng-dirty': formsubmited && f.debtType.errors }"
                                          optionLabel="value" optionValue="value"  formControlName="debtType"
                                          [autoDisplayFirst]="false" placeholder="Select" [showClear]="true" 
                                          tooltipPosition="top" (onChange)="validatePrimaryObjective($event)">
                                      </p-multiSelect>
                                      </div>
                                  </div>
                              </div>
                          </div>
                       </form>
                      </ng-template>
                      <ng-template pTemplate="footer">
                          <button pButton pRipple label="Save" [loading]="loading" form="clform" icon="pi pi-check" class="p-button-text"
                            (click)="prepareModel('primaryObjectives',  $event)"></button>
                       <button pButton pRipple label="Close" class="p-button-text" (click)="closeDialog()" > </button>
                      </ng-template>
                      </p-dialog>
                        </ng-template>
                        <ng-template pTemplate="output">
                          <span class="p-column-title">{{appUtil.getCustomLabel(column,
                            customLabels)}}</span>
                  <div class="col-12 md:col-8" [innerHTML]="sellside | sellsideobjective"></div>
                  </ng-template>
                </p-cellEditor>
                </td>
                </ng-container>
              </ng-template>
              <ng-template #notPrimaryObjective>
                <ng-container *ngIf="column.property == 'targetRevenueLookup'   ;else notTargetRevenueLookup">
                  <td class="wrap" [pEditableColumn]="sellside" [pEditableColumnField]="'targetRevenue'"
                    [pEditableColumnRowIndex]="index">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <span class="p-column-title">{{appUtil.getCustomLabel(column,
                          customLabels)}}</span>
                        <look-autocomplete [multiple]="false" [type]="'REVENUE'" [(ngModel)]="sellside.targetRevenue"
                          [items]="lookupList.REVENUE" placeholder="NA" tooltipPosition="top"></look-autocomplete>
                      </ng-template>
                      <ng-template pTemplate="output">
                        <span class="p-column-title">{{appUtil.getCustomLabel(column,
                          customLabels)}}</span>
                          {{(getOutput(sellside.targetRevenue))}}
                        <!-- {{sellside.targetRevenue? sellside.targetRevenue.value : null}} -->
                      </ng-template>
                    </p-cellEditor>
                  </td>
                </ng-container>
              </ng-template>

              <ng-template #notTargetRevenueLookup>
                <ng-container *ngIf="column.property == 'targetEbitdaLookup' ;else notTargetEbitdaLookup">
                  <td class="wrap" [pEditableColumn]="sellside" [pEditableColumnField]="'targetEbitda'"
                    [pEditableColumnRowIndex]="index">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <span class="p-column-title">{{appUtil.getCustomLabel(column,
                          customLabels)}}</span>
                        <look-autocomplete [multiple]="false" [type]="'EBITDA'" [(ngModel)]="sellside.targetEbitda"
                          [items]="lookupList.EBITDA" tooltipPosition="top"></look-autocomplete>
                      </ng-template>
                      <ng-template pTemplate="output">
                        <span class="p-column-title">{{appUtil.getCustomLabel(column,
                          customLabels)}}</span>
                          {{(getOutput(sellside.targetEbitda))}}
                        <!-- {{sellside.targetEbitda? sellside.targetEbitda : null}} -->
                      </ng-template>
                    </p-cellEditor>
                  </td>
                </ng-container>
              </ng-template>
              <ng-template #notTargetEbitdaLookup>
                <ng-container *ngIf="column.property == 'dollorValueRangeLookup'  ;else notDollorValueRangeLookup">
                  <td class="wrap" [pEditableColumn]="sellside" [pEditableColumnField]="'dollorValueRange'"
                    [pEditableColumnRowIndex]="index">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <span class="p-column-title">{{appUtil.getCustomLabel(column,
                          customLabels)}}</span>
                        <look-autocomplete [multiple]="false" [type]="'DOLLOR_VALUE_RANGE'"
                          [(ngModel)]="sellside.dollorValueRange" [items]="lookupList.DOLLOR_VALUE_RANGE"
                          placeholder="NA" tooltipPosition="top">
                        </look-autocomplete>
                      </ng-template>
                      <ng-template pTemplate="output">
                        <span class="p-column-title">{{appUtil.getCustomLabel(column,
                          customLabels)}}</span>
                          {{(getOutput(sellside.dollorValueRange))}}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                </ng-container>
              </ng-template>
              <ng-template #notDollorValueRangeLookup>
                <ng-container *ngIf="column.property == 'turnaround'  ;else notTurnaround">
                  <td class="wrap" [pEditableColumn]="sellside" [pEditableColumnField]="'turnaround'"
                    [pEditableColumnRowIndex]="index">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                          <p-radioButton name="turnaround" [value]="true" (keydown)="disableNavigation($event)"
                            [(ngModel)]="sellside[column.property]" label="Y"></p-radioButton>
                          <p-radioButton name="turnaround" [value]="false" (keydown)="disableNavigation($event)"
                            [(ngModel)]="sellside[column.property]" label="N" class="ml-2"></p-radioButton>
                      </ng-template>
                      <ng-template pTemplate="output">
                        <ng-container *ngIf="column.property == 'turnaround' ">
                          {{sellside[column.property] === true?'Y':(sellside[column.property] ===
                          false?'N':'')}}
                        </ng-container>
                      </ng-template>
                    </p-cellEditor>
                  </td>
                </ng-container>
              </ng-template>
              <ng-template #notTurnaround>
                <ng-container
                  *ngIf="column.property != 'turnaround' ">
                  <td class="wrap" [pEditableColumn]="sellside" [pEditableColumnField]="column.property"
                    [pEditableColumnRowIndex]="index">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <span class="p-column-title">{{appUtil.getCustomLabel(column,
                          customLabels)}}</span>
                        <input pInputText type="text" mode="text" (keydown)="disableNavigation($event)"
                          [(ngModel)]="sellside[column.property]" [required]="true"  [ngClass]="{ 'ng-invalid ng-dirty': formsubmited && f.externalHeadline.errors }">
                      </ng-template>
                      <ng-template pTemplate="output">

                        <span class="p-column-title">{{ appUtil.getCustomLabel(column,
                          customLabels) }}</span>
                        {{ sellside[column.property]}}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                </ng-container>
                </ng-template>

              <!-- <td class="wrap" [attr.width]="column.columnWidth">
                <ng-container *ngIf="column.property == 'primaryObjective'">
                  <span [innerHTML]="sellside | sellsideobjective"></span>
                </ng-container>
                <ng-container *ngIf="column.property != 'primaryObjective'">
                  <tbp-table-column [object]="sellside" [fieldInfo]="column"
                    (onPatch)="patchDeal($event)"></tbp-table-column>
                </ng-container>
              </td> -->
            </ng-container>
            <td *ngIf="!showCheckbox">
              <span class="p-column-title">Action</span>
              <button type="button" pButton icon="pi pi-list"
                (click)="setCurrentItem(sellside); menu.toggle($event)"></button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="20">
              <span *ngIf="term != ''">Search did not return any result. Try relaxing the criteria or use
                wildcard [*, ?]</span>
              <span *ngIf="term == ''">No Sell Side Screen found</span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
<p-dialog [(visible)]="showAddDialog" [breakpoints]="{ '960px': '75vw', '640px': '100vw' }" [style]="{ width: '50vw' }">
  <ng-template pTemplate="header">
    <div class="p-fluid formgrid grid">
      <div class="field col-12">
        <i class="pi pi-exclamation-triangle mr-2"></i>Are you sure that you
        want to add the sellside listed below?
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="p-fluid formgrid grid">
      <div class="field col-12">
        <p-table [autoLayout]="true" [value]="selectedSellSide"
          styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm" [rowHover]="true">
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 3rem" *ngIf="!showCheckbox">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
              </th>
              <th pSortableColumn="name">
                Screen Title
                <p-sortIcon field="name"></p-sortIcon>
              </th>
              <th pSortableColumn="organization">
                Account
                <p-sortIcon field="organization"></p-sortIcon>
              </th>
              <th pSortableColumn="Objectives">
                Primary Objectives
                <p-sortIcon field="Objectives"></p-sortIcon>
              </th>
              <th pSortableColumn="Headlines">
                External Headlines
                <p-sortIcon field="Headlines"></p-sortIcon>
              </th>
              <th>Action</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-sellsideList>
            <tr>
              <td (click)="$event.stopPropagation()" *ngIf="!showCheckbox">
                <p-tableCheckbox [value]="sellsideList"></p-tableCheckbox>
              </td>
              <td class="wrap capitalize">
                <span class="p-column-title">Name</span>{{ sellsideList.screenTitle }}
              </td>
              <td class="wrap">
                <span class="p-column-title">Account Name</span>{{ sellsideList.organization.name
                }}
              </td>
              <td class="wrap" [innerHTML]="sellsideList | sellsideobjective">
                <span class="p-column-title">Objectives</span>
              </td>
              <td class="wrap">
                <span class="p-column-title">Headlines</span>
                {{ sellsideList.externalHeadline }}
              </td>
              <td>
                <span class="p-column-title">Action</span>
                <button type="button" pButton icon="pi pi-minus-circle" (click)="remove(sellsideList)"></button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Add" icon="pi pi-check" class="p-button-text" (click)="addToSource()"></button>
    <button pButton pRipple label="Close" class="p-button-text" (click)="showAddDialog = false"></button>
  </ng-template>
</p-dialog>
<p-toast></p-toast>
<p-menu #menu [popup]="true" [model]="sellsideItems"></p-menu>
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>