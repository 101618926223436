<div class="card">
    <form [formGroup]="form" #cform>
        <div class="formgrid grid headerSticky">
            <div class="field col-12 md:col-12 text-right" *ngIf="loadingSpinner == false">
                <p-button label="Save" icon="pi pi-check" iconPos="left" (click)="save()" styleClass="mr-2">
                </p-button>
                <p-button label="Cancel" icon="pi pi-times" (click)="cancel()" iconPos="left"
                    styleClass="p-button-secondary"></p-button>
            </div>
            <div class="field col-12 md:col-12 text-right" *ngIf="loadingSpinner">
                <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
                    fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
            </div>
        </div>
        <div class="p-fluid  grid" *ngIf="status == 'PENDING_PROFILE' ">
            <div class="field col-11 md:col-11 ">
                Now that you have registered, you can Create a Profile and a Buy-Side Screen or Sell Side Screen. <i
                    class="pi pi-info-circle"></i><br>
                <span style="font-weight:bold"> Would you like to create a Profile as an ... </span>
            </div>
            <div class="field col-1 md:col-1 ">
                <p-button label="Skip" (click)="skip()" iconPos="left" styleClass="p-button-text"></p-button>
            </div>
        </div>
        <h5 class="custom-class">Contact Information</h5>
        <div class="p-fluid  grid">
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <input id="firstName" formControlName="firstName"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.firstName.errors }" maxlength="40"
                        type="text" pInputText>
                    <label for="firstName">First Name <span class="mandate">*</span></label>
                </span>
                <span class="p-error"
                    *ngIf="submitted && f.firstName.invalid && f.firstName.hasError('message')">{{f.firstName.errors.message}}</span>
                <span class="p-error" *ngIf="form.get('firstName').hasError('required') && submitted">First Name is
                    Required</span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <input id="lastName" [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.lastName.errors }"
                        maxlength="75" formControlName="lastName" type="text" pInputText>
                    <label for="lastName">Last Name <span class="mandate">*</span></label>
                </span>
                <span class="p-error" *ngIf="form.get('lastName').hasError('required') && submitted">Last Name is
                    Required</span>
                <span class="p-error"
                    *ngIf="submitted && f.lastName.invalid && f.lastName.hasError('message')">{{f.lastName.errors.message}}</span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <input id="contactPhone" [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.contactPhone.errors }"
                        maxlength="16" formControlName="contactPhone" type="text" pInputText>
                    <label for="contactPhone">Contact Phone <span class="mandate">*</span></label>
                </span>
                <span class="p-error" *ngIf="form.get('contactPhone').hasError('required') && submitted">Phone is
                    Required</span>
                <span class="p-error"
                    *ngIf="submitted && f.contactPhone.invalid && f.contactPhone.hasError('message')">{{f.contactPhone.errors.message}}</span>
            </div>
            <div class="field col-12 md:col-4" *ngIf="status != 'PENDING_PROFILE' ">
                <span class="p-float-label">
                    <input id="email" [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.email.errors }"
                        formControlName="email" maxlength="175" type="text" pInputText>
                    <label for="email">Email </label>
                </span>
                <span class="p-error" *ngIf="form.get('email').hasError('required') && submitted">Email is
                    Required</span>
                <span class="p-error"
                    *ngIf="submitted && f.email.invalid && f.email.hasError('message')">{{f.email.errors.message}}</span>
            </div>
        </div>
        <h5 class="custom-class">Target Requirements</h5>
        <div class="p-fluid  grid">
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <input id="name" type="text" [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.name.errors }"
                        maxlength="95" formControlName="name" pInputText>
                    <label for="name">Company Name <span class="mandate">*</span></label>
                </span>
                <span class="p-error" *ngIf="form.get('name').hasError('required') && submitted">Company Name is
                    Required</span>
                <span class="p-error"
                    *ngIf="submitted && f.name.invalid && f.name.hasError('message')">{{f.name.errors.message}}</span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <input id="phone" formControlName="phone"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.phone.errors }" maxlength="16" type="text"
                        pInputText>
                    <label for="phone">Company Phone<span class="mandate">*</span></label>
                </span>
                <span class="p-error" *ngIf="form.get('phone').hasError('required') && submitted">Company Phone is
                    Required</span>
                <span class="p-error"
                    *ngIf="submitted && f.phone.invalid && f.phone.hasError('message')">{{f.phone.errors.message}}</span>
            </div>

            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <p-dropdown [options]="lookupList.REVENUE" styleClass="form-control" [filter]="true" maxlength="19"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.revenue.errors }" formControlName="revenue"
                        #ddRevenue id="revenue" name="revenue" optionLabel="value" optionValue="value"
                        [autoDisplayFirst]="true" editable="false">
                    </p-dropdown>
                    <label for="revenue">Revenue</label>
                </span>
            </div>
        </div>
        <h5>Email Preferences </h5>
        <div class="p-fluid formgrid grid">
            <div class="field col-12 md:col-4">
                <p-checkbox formControlName="sendSpotlightEmails" binary="sendSpotlightEmails" id="nse"
                    label="Send Spotlights"></p-checkbox>
            </div>
        </div>
        <h5 class="custom-class">Address Information</h5>
        <div class="p-fluid  grid">
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <input id="address" type="text" [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.address.errors }"
                        maxlength="250" formControlName="address" pInputText>
                    <label for="address">Street</label>
                    <span class="p-error"
                        *ngIf="submitted && f.address.invalid && f.address.hasError('message')">{{f.address.errors.message}}</span>
                </span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <input id="city" type="text" formControlName="city"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.city.errors }" maxlength="45" pInputText>
                    <label for="city">City </label>
                    <span class="p-error" *ngIf="form.get('city').hasError('required') && submitted">City is
                        Required</span>
                    <span class="p-error"
                        *ngIf="submitted && f.city.invalid && f.city.hasError('message')">{{f.city.errors.message}}</span>
                </span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <input id="postalCode" type="text"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.postalCode.errors }"
                        formControlName="postalCode" maxlength="9" pInputText>
                    <label for="postalCode">Zip/Postal Code</label>
                    <span class="p-error" *ngIf="form.get('postalCode').hasError('required') && submitted">Zip/Postal
                        Code is Required</span>
                    <span class="p-error"
                        *ngIf="submitted && f.postalCode.invalid && f.postalCode.hasError('message')">{{f.postalCode.errors.message}}</span>
                </span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <p-dropdown [options]="countries" styleClass="form-control" formControlName="country" id="country"
                        name="country" optionLabel="name" optionValue="name" [filter]="true" (onChange)="getStates()">
                    </p-dropdown>
                    <label for="country">Country</label>
                </span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <p-dropdown [options]="states" [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.state.errors }"
                        styleClass="form-control" formControlName="state" id="state" name="state" optionLabel="name"
                        optionValue="name" [filter]="true" *ngIf="states !=null && states.length > 0"
                        [autoDisplayFirst]="false">
                    </p-dropdown>
                    <input id="state" [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.state.errors }" type="text"
                        maxlength="45" formControlName="state" pInputText *ngIf="states ==null || states.length == 0">
                    <label for="state">State</label>
                </span>
                <small class="p-error" *ngIf="submitted && f.state.hasError('required')">Please Select State</small>
                <span class="p-error"
                    *ngIf="submitted && f.state.invalid && f.state.hasError('message')">{{f.state.errors.message}}</span>
            </div>
        </div>
        <h5 class="custom-class">Classification</h5>
        <div class="p-fluid  grid">
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <p-multiSelect [options]="classificationList.CATEGORY_LEVEL1" [filter]="true"
                        styleClass="form-control" formControlName="level1" id="level1" name="level1" optionLabel="name"
                        optionValue="uuid">
                    </p-multiSelect>
                    <label for="level1">Level1 </label>
                </span>
                <span class="p-error" *ngIf="form.get('level1').hasError('required') && submitted">Level1 is
                    Required</span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <p-multiSelect [options]="classificationList.CATEGORY_LEVEL2" [filter]="true"
                        styleClass="form-control" formControlName="level2" id="level2" name="level2" optionLabel="name"
                        optionValue="uuid">
                    </p-multiSelect>
                    <label for="level1">Level2 </label>
                </span>
                <span class="p-error" *ngIf="form.get('level2').hasError('required') && submitted">Level2 is
                    Required</span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <p-multiSelect [options]="classificationList.CATEGORY_LEVEL3" [filter]="true"
                        styleClass="form-control" formControlName="level3" id="level3" name="level3" optionLabel="name"
                        optionValue="uuid">
                    </p-multiSelect>
                    <label for="level1">Level3 </label>
                </span>
                <span class="p-error" *ngIf="form.get('level3').hasError('required') && submitted">Level3 is
                    Required</span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <p-multiSelect [options]="classificationList.VERTICAL" [filter]="true" styleClass="form-control"
                        formControlName="vertical" id="vertical" name="vertical" optionLabel="name" optionValue="uuid">
                    </p-multiSelect>
                    <label for="level1">Vertical/Industry </label>
                </span>
                <span class="p-error" *ngIf="form.get('vertical').hasError('required') && submitted">Vertical/Industry
                    is Required</span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <p-multiSelect [options]="classificationList.ECOSYSTEM" [filter]="true" styleClass="form-control"
                        formControlName="ecosystem" id="ecosystem" name="ecosystem" optionLabel="name"
                        optionValue="uuid">
                    </p-multiSelect>
                    <label for="level1">Ecosystem </label>
                </span>
                <span class="p-error" *ngIf="form.get('ecosystem').hasError('required') && submitted">Ecosystem is
                    Required</span>
            </div>
        </div>
    </form>
</div>
<p-toast></p-toast>