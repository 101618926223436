import { AccountService } from './../_services/account.service';
import { AppUtil } from './../_helpers';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from '../app.breadcrumb.service';
import { OrganizationService } from './organization.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DataService } from "../_services/data.service";
import { MessageService } from 'primeng/api';
import { Location } from '@angular/common';
import { capIQValidator } from '../_helpers/capIQ.validator';
import { pitchbookValidator } from '../_helpers/pitchbook.validator';
import { linkedInValidator } from '../_helpers/linkedIn.validator';


@Component({
    selector: 'app-organization.add',
    templateUrl: './organization.add.component.html',
    styleUrls: ['./organization.add.component.scss'],
    providers: [MessageService]
})

export class OrganizationAddComponent implements OnInit {

    @ViewChild('cform') formElement;
    form: UntypedFormGroup;
    submitted = false;
    lookupList: any = {
        ACCOUNT_SOURCE: [], REVENUE: [], ORG_TYPE_INVESTOR: [], No_OF_EMPLOYEES: [],
        ORG_TYPE_LENDER: [], ORG_TYPE_OTHER: [], TAG: []
    };
    tbpEmployees = [];
    classificationList: any = {
        CATEGORY_LEVEL1: [], CATEGORY_LEVEL2: [], CATEGORY_LEVEL3: [],
        VERTICAL: [], ECOSYSTEM: []
    };
    isEdit: boolean = false;
    loadingSpinner: boolean = false;
    countries = [];
    states = [];
    helpList: any = { ACCOUNT: [] };
    accountType: string = null;
    autoCompleteResults: any[] = null;
    modelState = '';
    currentItem : any;
    excludedParents : any;
    excludedCorporateInvestors : any;
    stateDisabled:boolean = false;

    constructor(
        private _location: Location,
        private organizationService: OrganizationService,
        private breadcrumbService: BreadcrumbService,
        private router: Router,
        private formBuilder: UntypedFormBuilder,
        private dataService: DataService,
        private route: ActivatedRoute,
        private messageService: MessageService,
        private accountService: AccountService,
        public appUtil: AppUtil) {
        if (this.router.url.indexOf("/edit/") != -1) {
            this.isEdit = true;
            this.breadcrumbService.setItems([
                { label: 'Edit Account' }
            ]);
        }
        else {
            this.breadcrumbService.setItems([
                { label: 'New Account' }
            ]);
        }


    }

    ngOnInit(): void {
        this.classificationList = this.route.snapshot.data['classificationList'];
        this.lookupList = this.route.snapshot.data['lookupList'];
        this.countries = [...this.route.snapshot.data['countries']];
        this.tbpEmployees = this.route.snapshot.data['tbpEmployees'];
        this.helpList = this.route.snapshot.data['helpList']['ACCOUNT'];
        this.loadform();
        if (this.isEdit) {
            this.modelToForm(this.route.snapshot.data['organization'])
        }
        else {
            let country = this.countries.find(field => field.name === "United States");
            this.f.country.setValue(country);
            this.getStates();
        }
        this.checkState;
    }

    checkState()
    {
        if(this.f.country.value && this.f.country.value !=null && this.f.country.value !='' )
        {
            this.stateDisabled = false;
        }
        else
        {
            this.stateDisabled = true;
            this.f.state.setValue(null);
            this.modelState = null;
            this.states = [];
        }
    }

    loadform() {
        this.form = this.formBuilder.group({
            uuid: [''],
            name: ['', [Validators.required]],
            companyType: ['OPERATING_COMPANY'],
            customSubtypeValue: [],
            isPubliclyTraded: [[]],
            website: [null, [Validators.pattern, Validators.pattern(this.appUtil.urlPattern)]],
            phone: [''],
            pitchbookId: ['', [pitchbookValidator()]],
            linkedinPageId: ['', [linkedInValidator()]],
            capitaliqId: ['', [capIQValidator()]],
            accountOwner: [this.accountService.userValue.uuid],
            address: [],
            city: [''],
            state: [],
            postalCode: [''],
            country: [],
            revenue: [],
            leadSource: [],
            description: [''],
            numberOfEmployee: [],
            subTypes: [],
            level1: [],
            level2: [],
            level3: [],
            vertical: [],
            ecosystem: [],
            investors: [],
            parentCompany: [],
            portfolioCompanies: [],
            tags: [],
            individualInvestor: [],
            corporateInvestor: []
        });

        this.f.companyType.valueChanges.subscribe(e => {
            this.f.subTypes.reset();
            this.f.companyType.setValue(e, { emitEvent: false });
        });

        this.f.customSubtypeValue.valueChanges.subscribe(e => {
            this.f.customSubtypeValue.setValue(e, { emitEvent: false });
        });
    }

    modelToForm(model) {
        this.currentItem = model;
        let corporateInvestor = [];
        let individualInvestor = [];
        if (model.investors != null) {
            model.investors.forEach(function (currentValue:any) {
                if(currentValue.type == 'CORPORATE')
                {
                    this.corporateInvestor.push({'uuid' : currentValue.uuid, 'name': currentValue.name});
                }
                else
                {
                    this.individualInvestor.push({'uuid' : currentValue.uuid, 'name': currentValue.name, 'displayName': currentValue.displayName});
                }
            }, {corporateInvestor, individualInvestor})
        }
        this.form.setValue({
            uuid: model.uuid,
            name: model.name,
            companyType: model.companyType,
            customSubtypeValue: model.customSubtypeValue,
            isPubliclyTraded: (model.isPubliclyTraded == true ? [true] : []),
            website: model.website,
            pitchbookId: model.pitchbookId,
            linkedinPageId: model.linkedinPageId,
            capitaliqId: model.capitaliqId,
            phone: model.phone,
            accountOwner: (model.accountOwner == null) ? null : model.accountOwner?.uuid,
            address: model.address,
            city: model.city,
            postalCode: model.postalCode,
            country: null, //country value is set later below
            revenue: (model.revenue == null || model.revenue == '') ? null : this.lookupList['REVENUE'].find(field => field.value === model['revenue']),
            leadSource: (model.leadSource == null || model.leadSource == '') ? null : this.lookupList['ACCOUNT_SOURCE'].find(field => field.value === model['leadSource']),
            numberOfEmployee: (model.numberOfEmployee == null || model.numberOfEmployee == '') ? null : this.lookupList['No_OF_EMPLOYEES'].find(field => field.value === model['numberOfEmployee']),
            description: model.description,
            investors: model.investors,
            parentCompany: model.parentCompany,
            portfolioCompanies: model.portfolioCompanies,
            subTypes: model.subTypes,
            level1: (model.classification.level1 == null) ? null : model.classification.level1,
            level2: (model.classification.level2 == null) ? null : model.classification.level2,
            level3: (model.classification.level3 == null) ? null : model.classification.level3,
            vertical: (model.classification.vertical == null) ? null : model.classification.vertical,
            ecosystem: (model.classification.ecosystem == null) ? null : model.classification.ecosystem,
            tags: (model.tags == null) ? null : model.tags.map(a => a.uuid),
            state: null, // state value will be set later via call to getState
            individualInvestor: individualInvestor,
            corporateInvestor: corporateInvestor

        });
        let tempCnt = [];
        if (model.country != "" && model.country != null)
            tempCnt = this.countries.find(field => field.name === model.country);
        this.f.country.setValue(tempCnt);
        this.modelState = model.state;
        this.getStates();
        this.computeExcludedAccounts();
    }

    get f() { return this.form.controls; }

    get subTypeOptions() {

        if (this.f.companyType.value == 'INVESTOR')
            return this.lookupList.ORG_TYPE_INVESTOR;
        if (this.f.companyType.value == 'LENDER')
            return this.lookupList.ORG_TYPE_LENDER;
        if (this.f.companyType.value == 'OTHER')
            return this.lookupList.ORG_TYPE_OTHER;
        return [];
    }


    save() {
        this.submitted = true;
        if (this.form.invalid) {
            this.handleError(null);
            return;
        }
        this.loadingSpinner = true;
        let { uuid, type, name, website, capitaliqId, pitchbookId, linkedinPageId, phone, description, companyType, address, city, state, postalCode, country, customSubtypeValue, parentCompany, portfolioCompanies, tags } = this.form.value;
        let investors = [];
        if (this.f.corporateInvestor.value != null) {
            this.f.corporateInvestor.value.forEach(function (currentValue) {
                investors.push({'uuid': currentValue.uuid, 'type': 'CORPORATE'});
            }, investors)
        }
        if (this.f.individualInvestor.value != null) {
            this.f.individualInvestor.value.forEach(function (currentValue) {
                investors.push({'uuid':currentValue.uuid, 'type': 'INDIVIDUAL'});
            }, investors)
        }
        let dataToPost: any = { uuid,type, name, website, pitchbookId, linkedinPageId, capitaliqId, phone, description, companyType, address, city, state, postalCode, country, investors, parentCompany, portfolioCompanies, tags, classification: {}, customSubtypeValue };
        if (this.f.accountOwner.value) {
            dataToPost.accountOwner = this.appUtil.getObject(this.tbpEmployees, this.f.accountOwner.value);
        }
        if(this.f.parentCompany.value == ""){
            dataToPost.parentCompany = null;
        }
        if (this.f.leadSource.value) {
            dataToPost.leadSource = this.f.leadSource.value.value;
        }
        if (this.f.numberOfEmployee.value) {
            dataToPost.numberOfEmployee = this.f.numberOfEmployee.value.value;
        }
        if (this.f.revenue.value) {
            dataToPost.revenue = this.f.revenue.value.value;
        }
        if (this.f.country.value) {
            dataToPost.country = this.f.country.value.name;
        }
        if (this.f.state.value) {
            if (typeof (this.f.state.value) === 'string')
                dataToPost.state = this.f.state.value;
            else
                dataToPost.state = this.f.state.value.name;
        }

        if (this.f.companyType.value == 'INVESTOR' && this.f.subTypes.value != null && this.f.subTypes.value.length > 0) {
            dataToPost.subTypes = this.appUtil.getObjects(this.lookupList.ORG_TYPE_INVESTOR, this.f.subTypes.value);
        }
        if (this.f.companyType.value == 'LENDER' && this.f.subTypes.value != null && this.f.subTypes.value.length > 0) {
            dataToPost.subTypes = this.appUtil.getObjects(this.lookupList.ORG_TYPE_LENDER, this.f.subTypes.value);
        }
        if (this.f.companyType.value == 'OTHER' && this.f.subTypes.value != null && this.f.subTypes.value.length > 0) {
            dataToPost.subTypes = this.appUtil.getObjects(this.lookupList.ORG_TYPE_OTHER, this.f.subTypes.value);
        }

        if (this.f.level1.value) {
            dataToPost.classification.level1 = this.f.level1.value;
        }
        if (this.f.level2.value) {
            dataToPost.classification.level2 = this.f.level2.value;
        }
        if (this.f.level3.value) {
            dataToPost.classification.level3 = this.f.level3.value;
        }
        if (this.f.vertical.value) {
            dataToPost.classification.vertical = this.f.vertical.value;
        }
        if (this.f.ecosystem.value) {
            dataToPost.classification.ecosystem = this.f.ecosystem.value;
        }
        if (this.f.tags.value) {
            dataToPost.tags = this.appUtil.getObjects(this.lookupList.TAG, this.f.tags.value);
        }
        if (this.f.isPubliclyTraded.value.length == 1)
            dataToPost.isPubliclyTraded = true;
        else
            dataToPost.isPubliclyTraded = false;




        let apiToCall: any;
        if (this.isEdit) {
            apiToCall = this.organizationService.updateOrganization(dataToPost);
        }
        else {
            apiToCall = this.organizationService.addOrganization(dataToPost);
        }
        apiToCall.subscribe(
            data => {
                let org = data as any;
                let detail = 'Account Created';
                if (this.isEdit) {
                    detail = 'Account Updated';
                }
                this.dataService.setData('vorg-' + org.uuid, org);
                this.router.navigate(['/organization/' + org.uuid]);
                this.dataService.setData('message', detail);
                this.submitted = false;
            },
            error => {
                this.loadingSpinner = false;
                let errors = error.error;
                if (errors.code && errors.code == 'VALIDATION_ERROR') {
                    for (var i = 0; i < errors.subErrors.length; i++) {
                        this.f[errors.subErrors[i].field].setErrors({ 'message': errors.subErrors[i].message });
                    }
                    this.handleError(errors.subErrors[0].field);
                }
            }
        );
    }

    handleError(elemId) {
        this.messageService.add({ severity: 'error', summary: 'Form Errors', detail: "Form has errors. Please fix them first", life: 3000 });
        let invalidControl = this.formElement.nativeElement.querySelector('.ng-invalid');
        if (invalidControl) {
            invalidControl.focus();
        }
        else if (elemId != null) {
            invalidControl = this.formElement.nativeElement.querySelector('#' + elemId);
            if (invalidControl) {
                invalidControl.focus();
            }
        }
    }

    getStates() {
        this.checkState();
        if(this.countries != null)
        {
            if(this.f.country && this.f.country.value != '' && this.f.country.value != null)
            {
                let states = this.appUtil.getStates(this.countries, this.f.country.value.name);
                if(states == null)
                {
                    states = []
                }
                this.states = states;

                if (this.modelState != null && this.modelState != '') {
                    let tempState = this.states.find(field => field.name === this.modelState);
                    if (tempState != undefined)
                        this.f.state.setValue(tempState);
                    else
                        this.f.state.setValue(this.modelState);
                }
            }
        }
    }

    cancel() {
        this._location.back();
    }

    getHelpLabel(key) {
        let test = this.helpList.filter(function (element) {
            return element.key == key;
        });
        return test[0]['label'];
    }

    getHelpText(key) {
        let test = this.helpList.filter(function (element) {
            return element.key == key;
        });
        if (test[0] != undefined)
            return test[0]['text'];
    }

    goToLink(website) {
        let url = website;
        let chString = "https";
        if (url.indexOf(chString) !== -1)
            window.open(url, "_blank");
        else
            window.open('https://' + url, "_blank");
    }

    processCompanyType(event)
    {
        if(this.f.companyType.value != 'OPERATING_COMPANY')
        {
            this.f.isPubliclyTraded.setValue(false);
        }
    }

    computeExcludedAccounts(){

        let excludedAccounts = [];
        if(this.currentItem != null)
        {
            excludedAccounts.push(this.currentItem);
        }
        if(this.f.corporateInvestor.value != null){
            excludedAccounts = excludedAccounts.concat(this.f.corporateInvestor.value);
        }
        this.excludedParents = [].concat(excludedAccounts)

        if(this.f.parentCompany.value != null && this.f.parentCompany.value!='')
        {
            excludedAccounts = excludedAccounts.concat(this.f.parentCompany.value);
        }
        this.excludedCorporateInvestors = [].concat(excludedAccounts);
    }
}
