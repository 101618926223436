import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class HelpService {

    private previousUrl: string | null = null;
  private currentUrl: string | null = null;
    constructor(private http: HttpClient ,private router: Router) {  this.currentUrl = this.router.url;
        router.events
          .pipe(filter(event => event instanceof NavigationEnd))
          .subscribe((event: NavigationEnd) => {
            this.previousUrl = this.currentUrl;
            this.currentUrl = event.url;
          });
      }
      public getPreviousUrl(): string | null {
        return this.previousUrl;
      }
      public getCurrentUrl(): string | null {
        return this.currentUrl;
      }
    getHelpKeys() {
        return this.http.get(environment.apiUrl + '/help-config/keys/ACTIVE');
    }

    getHelpValues(helpKey: string) {
        return this.http.get(environment.apiUrl + '/help-config/values/' + helpKey + '/ACTIVE');
    }

    updateHelpValue(data: any) {
        return this.http.put(environment.apiUrl + '/help-config/value/' + data.uuid, data);
    }
}
