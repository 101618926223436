import { TbpTableColumnModule } from './../_components/tbp-table-column/tbp-table-column.module';
import { TbpColumnChooserModule } from './../_components/tbp-column-chooser/tbp-column-chooser.module';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DealRoutingModule } from './deal-routing.module';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ToolbarModule } from 'primeng/toolbar';
import { FileUploadModule } from 'primeng/fileupload';
import { CalendarModule } from 'primeng/calendar';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CheckboxModule } from 'primeng/checkbox';
import { DealAddComponent } from "./deal.add.component";
import { ToastModule } from 'primeng/toast';
import { MultiSelectModule } from 'primeng/multiselect';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { SplitButtonModule } from 'primeng/splitbutton';
import { DealViewComponent } from './deal.view.component';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import {RatingModule} from 'primeng/rating';
import { TooltipModule } from 'primeng/tooltip';
import { MenuModule } from 'primeng/menu';
import { DealSpotlightContactModule } from './../deal-spotlight-contact/deal-spotlight-contact.module';
import { FormsModule } from '@angular/forms';
import { AccountPopupModule } from './../account-popup/account-popup.module';
import { TbpQueryBuilderModule } from '../_components/tbp-query-builder/tbp-query-builder.module';
import { UploadExcelModule } from './../upload-excel/upload-excel.module';
import { SharedModule } from '../shared/shared.module';
import { DividerModule } from "primeng/divider";
import { TbpAutocompleteModule } from '../_components/tbp-autocomplete/tbp-autocomplete.module';
import { LookupAutocompleteModule } from '../_components/lookup/lookup.module';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { TbpFieldValueModule } from '../_components/tbp-field-value/tbp-field-value.module';
import { MessagesModule } from 'primeng/messages';
import { DealsListComponent } from './deals-list.component';
import { TbpSearchModule } from '../_components/tbp-search/tbp-search.module';
@NgModule({
    declarations: [DealAddComponent, DealViewComponent, DealsListComponent],
    imports: [
        CommonModule,
        DealRoutingModule,
        CommonModule,
        ReactiveFormsModule,
        TableModule,
        ButtonModule,
        OverlayPanelModule,
        ToolbarModule,
        FileUploadModule,
        InputTextModule,
        DropdownModule,
        InputTextareaModule,
        CheckboxModule,
        CalendarModule,
        EditorModule,
        ToastModule,
        MultiSelectModule,
        AutoCompleteModule,
        SplitButtonModule,
        DialogModule,
        ConfirmDialogModule,
        InputNumberModule,
        ProgressSpinnerModule,
        TooltipModule,
        MenuModule,
        DealSpotlightContactModule,
        FormsModule,
        AccountPopupModule,
        TbpQueryBuilderModule,
        UploadExcelModule,
        SharedModule,
        DividerModule,
        TbpAutocompleteModule,
        LookupAutocompleteModule,
        RatingModule,
        TbpColumnChooserModule,
        TbpTableColumnModule,
        TbpFieldValueModule,
        MessagesModule,
        TbpSearchModule
    ],
    providers: [
        { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
    ]
})
export class DealModule { }
