import { AppUtil } from 'src/app/_helpers';
import { Component, OnInit, Input, SimpleChanges, ViewChild, QueryList, ViewChildren } from '@angular/core';
import { Inplace } from 'primeng/inplace';
import { SellsideService } from './sellside.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
    selector: 'sellside-blind-profile-view',
    templateUrl: './sellside-blind-profile-view.component.html',
    styleUrls: ['./sellside-blind-profile-view.component.scss'],
    styles: [`
  :host ::ng-deep .p-divider.p-divider-vertical::before {
		border-left: 2px #dee2e6 dashed !important;
	}
  `]
})
export class SellsideBlindProfileViewComponent implements OnInit {

    @ViewChildren(Inplace) inplaceEditors:QueryList<Inplace>;
    financials: any[];
    years: any[];
    @Input() screenId: string;
    loadingSpinner: boolean = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private sellsideService: SellsideService,
        public appUtil: AppUtil
    ) { }

    @Input() blindProfile: any;
    fieldData: any = {
        title: { loading: false, value: null, error: null }, headline: { loading: false, value: null, error: null },
        services: { loading: false, value: null, error: null }, customers: { loading: false, value: null, error: null },
        business: { loading: false, value: null, error: null }, investmentConsiderations: { loading: false, value: null, error: null }
    };

    ngOnInit() {
        this.financials = [{ 'dummy': 'Revenue ($m)' }, { 'dummy': 'EBITDA ($m)' }, { 'dummy': 'YOY Growth' }, { 'dummy': 'Revenue Projections' }];
        this.years = ['dummy'];
    }

    ngOnChanges(changes: SimpleChanges)
    {
        if(changes.screenId)
        {
            this.sellsideService.getBlindProfile(this.screenId).subscribe(data => {
                this.blindProfile = data as any;
                for (const [key, value] of Object.entries(this.fieldData)) {
                    this.fieldData[key].value = this.blindProfile[key];
                }
                if(this.blindProfile.financials && this.blindProfile.financials.length > 0)
                {
                    let financials = this.blindProfile.financials;
                    financials.forEach(element => {
                        this.financials[0][element.year] = element.revenue;
                        this.financials[1][element.year] = element.ebitda;
                        this.financials[2][element.year] = element.yoyGrowth;
                        this.financials[3][element.year] = element.revenueProjection;
                        this.years.push(element.year);
                    });
                }

            }).add( () => {
                this.loadingSpinner = false;
            });
        }
    }

    patch(selectedEditor: Inplace, fieldName: string) {
        let data = this.fieldData[fieldName];
        if (this.blindProfile[fieldName] != data.value) {
            let dataToPost = { uuid: this.blindProfile.uuid };
            dataToPost[fieldName] = data.value;
            data.loading = true;
            this.sellsideService.patchBlindProfile([fieldName], dataToPost).subscribe(
                {
                    next: (v) => {
                        this.blindProfile[fieldName] = data.value;
                        data.error = null;
                        selectedEditor.deactivate();
                    },
                    error: (e) => {
                        let errors = e.error;
                        if (errors.code && errors.code == 'VALIDATION_ERROR') {
                            data.error = errors.subErrors[0].message;
                        }
                    }
                }
            ).add(() => {
                data.loading = false;
            });
        }
        else {
            selectedEditor.deactivate();
        }
    }

    close(selectedEditor: Inplace, fieldName: string) {
        let data = this.fieldData[fieldName];
        data.value = this.blindProfile[fieldName];
        data.loading = false;
        data.error = null;
        selectedEditor.deactivate();
    }

    editBlindProfile()
    {
        this.router.navigate(['/sell-side/' + this.screenId + '/blind-profile/edit']);
    }

    
  editCompleted($event)
  {
    let financials:any[] =[];
    this.years.forEach((element) =>{
        if(element != 'dummy')
        {
            financials[financials.length] = { year: element, revenue: this.financials[0][element],
                ebitda: this.financials[1][element], yoyGrowth: this.financials[2][element], 
                revenueProjection: this.financials[3][element]}
        }
    });
    let dataToPost = { uuid: this.blindProfile.uuid };
    dataToPost['financials'] = financials;
    this.sellsideService.patchBlindProfile(['financials'], dataToPost).subscribe(
        {
            next: (v) => {
                this.blindProfile['financials'] = financials;
            },
            error: (e) => {
                let errors = e.error;
                if (errors.code && errors.code == 'VALIDATION_ERROR') {
                   // data.error = errors.subErrors[0].message;
                }
            }
        }
    )
  }



}
