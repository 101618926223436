import { ContactListComponent } from './contact-list.component';
import { MergeContactComponent } from './merge-contact.component';
import { UnrecognizedListComponent } from './unrecognized-list.component';
import { ClassificationResolver } from './../classification/classification.resolver';
import { UserResolver } from './../users/user.resolver';
import { CountryResolver } from './../configuration/country.resolver';
import { ConfigurationResolver } from '../configuration/configuration.resolver';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment } from '@angular/router';
import { UserAddComponent } from './user.add.component';
import { UserViewComponent } from './user.view.component';
import { UserProfileComponent } from './user.profile.component';
import { ProfileResolver } from './profile.resolver';
import { TbpuserListComponent } from './tbpuser.list.component';
import { TbpuserViewComponent } from './tbpuser.view.component';
import { TbpuserAddComponent } from './tbpuser.add.component';
import { HelpResolver } from '../help/help.resolver';

const routes: Routes = [
    { 
        matcher: (url) => { // urls like contact, organization/:uuid/contact, buyside/:uuid/contact/add
            if ( ((url.length == 1 && url[0].path == 'contacts') || (url.length >=3 && url[2].path == 'contacts')) && url.length <=4) {
                return {
                    consumed: url,
                    posParams: {
                        source: url.length !=1?url[0]:new UrlSegment('', {}),
                        sourceuuid: url.length !=1?url[1]:new UrlSegment('', {}),
                        showCheckbox: new UrlSegment(url.length ==4?'true':'false', {})
                    }
                };
            }
            return null;
        },
        component: ContactListComponent,
        resolve: {
            lookupList: ConfigurationResolver,
            countries: CountryResolver,
            tbpEmployees: UserResolver,
            classificationList: ClassificationResolver
        }
    },
    { path: 'contact/unrecognized-email-logs', component: UnrecognizedListComponent },
    {
        path: 'contact/new', component: UserAddComponent,
        resolve: {
            lookupList: ConfigurationResolver,
            countries: CountryResolver,
            tbpEmployees: UserResolver,
            classificationList: ClassificationResolver,
            helpList: HelpResolver
        }
    },
    {
        path: 'contact/:uuid', component: UserViewComponent,
        resolve: {
            tbpEmployees: UserResolver,
            lookupList: ConfigurationResolver,
            classificationList: ClassificationResolver,
            countries: CountryResolver,
        }
    },
    {
        path: 'profile/:uuid', component: UserProfileComponent,
        resolve: {
            lookupList: ConfigurationResolver,
            countries: CountryResolver,
            classificationList: ClassificationResolver,
            profile: ProfileResolver
        }
    },
    {
        path: 'contact/edit/:uuid', component: UserAddComponent,
        resolve: {
            lookupList: ConfigurationResolver,
            countries: CountryResolver,
            tbpEmployees: UserResolver,
            classificationList: ClassificationResolver,
             helpList: HelpResolver
        }
    },
    {
        path: 'contact/merge/:uuid', component: MergeContactComponent,
        resolve: {
            contact: ProfileResolver
        }
    },
    { path: 'user', component: TbpuserListComponent },
    { path: 'user/new', component: TbpuserAddComponent,
    resolve: {
        helpList: HelpResolver,
   }

},
    { path: 'user/edit/:uuid', component: TbpuserAddComponent,
    resolve: {
         helpList: HelpResolver
    }
},
    { path: 'user/:uuid', component: TbpuserViewComponent }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [ClassificationResolver, UserResolver, CountryResolver, ConfigurationResolver, ProfileResolver, HelpResolver]
})
export class UsersRoutingModule { }
