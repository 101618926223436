export const environment = {
  production: false,
  apiUrl: 'http://networktest.truebluepartners.com/api/v1',
  appName: 'Demo:TBP Network',
  defaultPageSize: 25,
  defaultTotalRecords:  10000,
  customTotalRecords:50000,
  'passphrase': 'L3dmip37+NWEi57rSnFFypTG7ZI25Kdz9tyvpRMrL5E=',
  'salt': 'a3636b16beff20769f21f18a6b6ccf2b',
  'pitchbook.url': 'https://my.pitchbook.com/profile/:pitchbookId/company/profile',
  'capiq.url': 'https://www.capitaliq.com/CIQDotNet/company.aspx?companyId=:capiqId',
  'linkedin.url': 'https://www.linkedin.com/company/:linkedinId/',
  'google.url': 'https://www.google.com/search?q=:text',
  'authserver': "https://dev-auth.truebluepartners.com/",
  'authserverRealm': "tbp-network",
  /*
  'resetpassword.url' :'http://test.truebluepartners.com/network-reset-password.html?token=:token',
  'activation.url'    :'http://test.truebluepartners.com/network-activate-profile.html?token=:token',
  'login.url'         :'http://test.truebluepartners.com/network-login.html',
  'register.url'      :'http://test.truebluepartners.com/network-signup.html'
  */

};
