import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TbpFieldValueComponent } from './tbp-field-value.component';
import { RatingModule } from 'primeng/rating';

@NgModule({
  imports: [
    CommonModule,
    RatingModule,
    FormsModule
  ],
  declarations: [TbpFieldValueComponent],
  exports:[TbpFieldValueComponent]
})
export class TbpFieldValueModule { }
