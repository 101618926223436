import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClassificationListComponent } from './classification.list.component';
import { ClassificationAddComponent } from './classification.add.component';


const routes: Routes = [
    { path: '', component: ClassificationListComponent },
    { path: 'new', component: ClassificationAddComponent },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ClassificationRoutingModule { }
