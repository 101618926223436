import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UploadExcelService } from './upload-excel.service';
import { MessageService } from 'primeng/api';
import { OrganizationService } from './../organization/organization.service';

@Component({
    selector: 'app-upload-excel',
    templateUrl: './upload-excel.component.html',
    styleUrls: ['./upload-excel.component.scss'],
    providers: [MessageService]
})
export class UploadExcelComponent implements OnInit {
    @Output() onSuccess: EventEmitter<any> = new EventEmitter();
    @Input() show: boolean = false;
    @Output() showChange = new EventEmitter<boolean>();
    @Input() closeAfterSave: boolean = true;
    @Input() showUpdateOption: boolean = true;
    @Output() afterSave = new EventEmitter<any>();
    @Input() url: string = null; /* organization, contact */
    @Input() title: string = null;
    checkbox: boolean = false;
    successDatas: [];
    errors: [];
    updatedCount = 0;
    createdCount = 0;
    skippedCount = 0;
    createArray = [];
    updateArray = [];
    skipArray = [];
    showResult: boolean = false;
    showCount: boolean = false;
    showError: boolean = false;
    successTitle: string;
    fileName: string;
    loadingupload: boolean = false;

    // While uploading Prospects
    showProspectDetail : boolean = false;
    createdProspectCount = 0;
    skippedProspectCount = 0;
    updatedProspectCount = 0;
    createdProspectsArray: any[];
    skippedProspectsArray: any[];
    updatedProspectsArray: any[];
    showProspectResult: boolean = false;
    prospectSuccessTitle: string;
    successProspectArray: any[];

    // While Uploading Recipients
    showRecipientsDetail: boolean = false;
    createdRecipientsCount = 0;
    skippedRecipientsCount = 0;
    createdRecipientsArray: any[];
    skippedRecipientsArray: any[];
    showRecipientsResult: boolean = false;
    recipientsSuccessTitle: string;
    successRecipientsArray: any[];

    constructor(private organizationService: OrganizationService,
        private uploadExcelService: UploadExcelService,
        private messageService: MessageService) { }

    ngOnInit(): void {

    }

    onUpload(event, fileUpload) {
        this.fileName = '';
        this.createdCount = 0;
        this.updatedCount = 0;
        this.skippedCount = 0;
        this.loadingupload = true;
        let file: File = event.files[0];
        let formData: FormData = new FormData();
        formData.append("file", file);
        let apiToCall: any;
        apiToCall = this.uploadExcelService.uploadExcel(formData, (this.showUpdateOption == true)?this.checkbox:null, this.url);
        apiToCall.subscribe(
            data => {
                this.fileName = event.files[0].name;
                this._afterSave(data);
            },
            error => {
                if (error.status == 400)
                    this.messageService.add({ severity: 'error', summary: error.error.message, detail: error.error.subErrors[0].message, life: 3000 });
                this.loadingupload = false;
            });
        fileUpload.clear();
    }

    private _afterSave(data) {
        
        if(this.url.includes('prospects')){
            this.createdProspectsArray = []
            this.skippedProspectsArray = []
            this.updatedProspectsArray = []
            let prospectInfo = data['prospect-info'];
            if(prospectInfo != null){
                for (var i = 0; i < prospectInfo.rowStatus.length; i++) {
                    if (prospectInfo.rowStatus[i].status == "Created")
                        this.createdProspectsArray.push(prospectInfo.rowStatus[i]);
                    if (prospectInfo.rowStatus[i].status == "Skipped")
                        this.skippedProspectsArray.push(prospectInfo.rowStatus[i]);
                    if (prospectInfo.rowStatus[i].status == "Updated")
                        this.updatedProspectsArray.push(prospectInfo.rowStatus[i]);
                }
            }
            data = data['upload-info']
        }
        if(this.url.includes('recipients')){
            this.createdRecipientsArray = [];
            this.skippedRecipientsArray = [];
            let recipientsInfo = data['recipients-info'];
            if(recipientsInfo != null){
                for (var i = 0; i < recipientsInfo.rowStatus.length; i++) {
                    if (recipientsInfo.rowStatus[i].status == "Created")
                        this.createdRecipientsArray.push(recipientsInfo.rowStatus[i]);
                    if (recipientsInfo.rowStatus[i].status == "Skipped")
                        this.skippedRecipientsArray.push(recipientsInfo.rowStatus[i]);
                }
            }
            data = data['upload-info'];
        }
        if (data.error == false) {
            this.showCount = true;
            this.showResult = false;
            this.showError = false;
            this.createArray = [];
            this.updateArray = [];
            this.skipArray = [];
            for (var i = 0; i < data.rowStatus.length; i++) {

                if (data.rowStatus[i].status == "Created")
                    this.createArray.push(data.rowStatus[i]);
                if (data.rowStatus[i].status == "Updated")
                    this.updateArray.push(data.rowStatus[i]);
                if (data.rowStatus[i].status == "Skipped")
                    this.skipArray.push(data.rowStatus[i]);
            }
            this.createdCount = data.rowsCreated;
            this.updatedCount = data.rowsUpdated;
            this.skippedCount = data.rowsSkipped;
            this.loadingupload = false;
            this.onSuccess.emit();
        }
        if (data.error == true) {
            this.showError = true;
            this.showResult = false;
            this.showCount = false;
            this.errors = data.rowStatus;
            this.loadingupload = false;
        }
        if((this.createdProspectsArray != null && this.createdProspectsArray.length > 0) ||
             (this.skippedProspectsArray != null && this.skippedProspectsArray.length > 0) ||    (this.updatedProspectsArray != null && this.updatedProspectsArray.length > 0)) {
            this.showProspectDetail = true;
            this.createdProspectCount = this.createdProspectsArray.length;
            this.skippedProspectCount= this.skippedProspectsArray.length;
            this.updatedProspectCount = this.updatedProspectsArray.length;
        }
        if((this.createdRecipientsArray != null && this.createdRecipientsArray.length > 0) ||
            (this.skippedRecipientsArray != null && this.skippedRecipientsArray.length > 0)) {
            this.showRecipientsDetail = true;
            this.createdRecipientsCount = this.createdRecipientsArray.length;
            this.skippedRecipientsCount = this.skippedRecipientsArray.length;
        }
    }

   successDataUpload(type) {
        this.showResult = true;
        this.successTitle = "";
        if (type == 1) {
            this.successTitle = "Rows Created";
            this.successDatas = this.createArray as any;
        }
        if (type == 2) {
            this.successTitle = "Rows Updated";
            this.successDatas = this.updateArray as any;
        }
        if (type == 3) {
            this.successTitle = "Rows Skipped";
            this.successDatas = this.skipArray as any;
        }
    }

    successProspectUpload(type) {

        this.showProspectResult = true;
        this.prospectSuccessTitle = "";
        if(type == 1) {
            this.prospectSuccessTitle = "Rows Created";
            this.successProspectArray = this.createdProspectsArray;
        } else if(type == 3){
            this.prospectSuccessTitle = "Rows Skipped";
            this.successProspectArray = this.skippedProspectsArray;
        }else{
            this.prospectSuccessTitle = "Rows Updated";
            this.successProspectArray = this.updatedProspectsArray;
        }
    }

    successRecipientsUpload(type) {

        this.showRecipientsResult = true;
        this.recipientsSuccessTitle = "";
        if(type == 1) {
            this.recipientsSuccessTitle = "Rows Created";
            this.successRecipientsArray = this.createdRecipientsArray;
        } else if(type == 2){
            this.recipientsSuccessTitle = "Rows Skipped";
            this.successRecipientsArray = this.skippedRecipientsArray;
        }
    }

    hideUploadpop() {
        this.showError = false;
        this.showResult = false;
        this.showCount = false;
        this.show = false;
        this.showProspectDetail = false;
        this.showProspectResult = false;
        this.showRecipientsDetail = false;
        this.showRecipientsResult =false;
        this.showChange.emit(false);
    }

    checkBoxChange(event) {
        this.checkbox = (event.checked[0] ? event.checked : false);
    }

    selectFile(event: any, fileUpload) {
        this.showError = false;
        this.showResult = false;
        this.showCount = false;
        this.showProspectDetail = false;
        this.showRecipientsDetail = false;
    }
}
