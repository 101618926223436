export class LookupValue {
    uuid?: string
    value?: string;
    status?: string;
    min?: number;
    max?: number;
    text?: string;
    keyId?: string;
}

