
<ng-container *ngIf="fieldInfo.linkPrefix !=null && fieldInfo.linkPrefix !=''">
    <span *ngFor="let val of innerValue; let last = last;">
        <a [href]="getUrl(val)">
            {{getValue(val)}}{{last?'':';'}}
        </a>
    </span>&nbsp;
</ng-container>
<ng-container *ngIf="fieldInfo.linkPrefix == null || fieldInfo.linkPrefix == ''">
    <ng-container *ngIf="fieldInfo.type == 'rating'">
        <p-rating [(ngModel)]="object[fieldInfo.property]" (onRate)="patchObject({data:object,field:fieldInfo.property})" (onCancel)="patchObject({data:object,field:fieldInfo.property})"></p-rating>
    </ng-container>
    <ng-container *ngIf="fieldInfo.type == 'email'">
        <ng-container *ngFor="let val of innerValue; let last = last;">
            <a href="mailto:{{getValue(val)}}">{{getValue(val)}}</a>{{last?'':'; '}}
        </ng-container>&nbsp;
    </ng-container>
    <ng-container *ngIf="fieldInfo.type != 'rating' && fieldInfo.type != 'email'">
        <ng-container *ngFor="let val of innerValue; let last = last;">
            <span [innerHTML]="getValue(val)"></span>{{last?'':'; '}}
        </ng-container>&nbsp;
    </ng-container>    
</ng-container>