import { DealPipe } from './../../shared/deal.pipe';
import { FieldInfo } from './../../_models/field-info';
import { Component, Input, Output, OnInit, SimpleChanges, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'tbp-field-value',
  templateUrl: './tbp-field-value.component.html',
  styleUrls: ['./tbp-field-value.component.css']
})
export class TbpFieldValueComponent implements OnInit {

    @Input() fieldInfo:FieldInfo = null;
    @Input() object:any = null;
    innerValue:any[] = null;
    @Output() onPatch:EventEmitter<any> = new EventEmitter();

    constructor(private router: Router) { }

    ngOnInit() {
    }

    ngOnChanges(changes:SimpleChanges)
    {
        if(changes.hasOwnProperty('fieldInfo'))
        {
            let propTree:string[] = this.fieldInfo.property.split(".");
            if(this.fieldInfo.type == 'classification')
            {
                propTree = ['classification', this.fieldInfo.property]
            }
            this.innerValue = this.object;
            for(let i=0;i<propTree.length;i++)
            {
                this.innerValue = this.innerValue[propTree[i]];
                if(typeof this.innerValue == 'undefined' || this.innerValue == null)
                {
                    break;
                }
            }
            if(typeof this.innerValue == 'undefined' || this.innerValue == null)
            {
                this.innerValue = [];
                if(this.fieldInfo.type == 'numeric_emptydash')
                    this.innerValue[0] = '0';
            }
            else if(!Array.isArray(this.innerValue))
            {
                if(typeof this.innerValue == 'boolean')
                {
                    this.innerValue = [this.innerValue?'Y':'N'];
                }
                else 
                {
                    this.innerValue = [this.innerValue];
                }
            }
        }
    }

    getValue(val)
    {
        if(this.fieldInfo.type == 'numeric_emptydash' && val == 0)
        {
            return '-';
        }
        else if(val != null)
        {
            if(this.fieldInfo.type == 'date')
            {
                return new DatePipe('en-US').transform(val, 'MM-dd-yyyy');
            }
            else if(this.fieldInfo.type == 'enum')
            {
                return new DealPipe().transform(val);
            }
            else if(typeof val === 'object')
            {
                return val[this.fieldInfo.labelProperty];
            }
        }
        return val;
    }

    getUrl(val)
    {
        let suffix = '';
        if (this.fieldInfo.linkSuffix !== null) 
        {
           suffix = this.fieldInfo.linkSuffix;
        }
       return this.fieldInfo.linkPrefix + (this.fieldInfo.linkType == 'p'?this.object[this.fieldInfo.valueProperty]:val[this.fieldInfo.valueProperty]) + suffix;
    }

    patchObject($data)
    {
        
        this.onPatch.emit($data);
    }
}
