<div class="card" *ngIf="role == 1">
    <div class="grid">
        <div class="col-12 md:col-6">
            <p-table [autoLayout]="true" [value]="upCommingTasks" [(selection)]="selectedTasks" dataKey="userId" [lazy]="true"
                (onLazyLoad)="loadTasks($event, 'upComingTask')"
                styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm" [rowHover]="true"
                [rows]="5" [paginator]="false" [loading]="taskLoading" [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [totalRecords]="upCommingTaskTotalRecords" [alwaysShowPaginator]="false">
                <ng-template pTemplate="caption">
                    Upcoming Tasks
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="subject">Subject</th>
                        <th SortableColumn="priority">Priority</th>
                        <th pSortableColumn="status">Status</th>
                        <th pSortableColumn="lastModifiedDate">Contacts</th>
                        <th pSortableColumn="dueDate">Type</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-task>
                    <tr>
                        <td class="wrap capitalize">
                            <span class="p-column-title"></span>
                            <a href="javascript:void(0)" (click)="edit(task)">{{task.subject}} </a>
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Priority</span>
                            {{task?.priority == null ? "" : task?.priority.toString() | titlecase}}
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Status</span>
                            {{task.status == null ? "" : task.status.toString().replace('_',' ') | titlecase}}
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Contacts</span>
                            <a [routerLink]="['/contact', task.contacts !=null ? task.contacts[0].uuid: null ]">{{task.contacts !=null ? task.contacts[0].name: null}}</a>
                        </td>

                        <td class="wrap">
                            <span class="p-column-title">Due Date</span>
                            {{task.relatedType}}
                        </td>

                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="6">No Tasks found.</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="footer">
                    <tr *ngIf="upCommingTaskTotalRecords > 4">
                        <td colspan="5" class="text-right"><a href="/upComingTask/{{profileUuid}}/tasks">View More</a></td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <div class="col-12 md:col-6">
            <p-table [autoLayout]="true" [value]="dueDateTasks" [(selection)]="selectedTasks" dataKey="userId" [lazy]="true"
                (onLazyLoad)="loadTasks($event, 'overDueTask')"
                styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm" [rowHover]="true"
                [rows]="5" [paginator]="false" [loading]="taskLoading" [showCurrentPageReport]="true" [taskType]="due"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [totalRecords]="dueDateTaskTotalRecords" [alwaysShowPaginator]="false">
                <ng-template pTemplate="caption">
                    Overdue Tasks
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="subject">Subject</th>
                        <th SortableColumn="priority">Priority</th>
                        <th pSortableColumn="status">Status</th>
                        <th pSortableColumn="lastModifiedDate">Contacts</th>
                        <th pSortableColumn="dueDate">Type</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-task>
                    <tr>
                        <td class="wrap capitalize">
                            <span class="p-column-title"></span>
                            <a href="javascript:void(0)" (click)="edit(task)">{{task.subject}} </a>
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Priority</span>
                            {{task?.priority == null ? "" : task?.priority.toString() | titlecase}}
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Status</span>
                            {{task.status == null ? "" : task.status.toString().replace('_',' ') | titlecase}}
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Contacts</span>
                            <a [routerLink]="['/contact', task.contacts !=null ? task.contacts[0].uuid: null ]">{{task.contacts !=null ? task.contacts[0].name: null}}</a>
                        </td>

                        <td class="wrap">
                            <span class="p-column-title">Due Date</span>
                            {{task.relatedType}}
                        </td>

                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="6">No Tasks found.</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="footer">
                    <tr *ngIf="dueDateTaskTotalRecords > 4">
                        <td colspan="5" class="text-right"><a href="/overDueTask/{{profileUuid}}/tasks">View More</a></td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
       
        <div class="col-12 md:col-6">
            <p-table [autoLayout]="true" [value]="tasks" [(selection)]="selectedTasks" dataKey="userId" [lazy]="true"
                (onLazyLoad)="loadTasks($event, 'allTask')"
                styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm" [rowHover]="true"
                [rows]="5" [paginator]="false" [loading]="taskLoading" [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [totalRecords]="taskTotalRecords" [alwaysShowPaginator]="false">
                <ng-template pTemplate="caption">
                    Other Users Task
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="subject">Subject</th>
                        <th SortableColumn="priority">Priority</th>
                        <th pSortableColumn="status">Status</th>
                        <th pSortableColumn="lastModifiedDate">Assigned To</th>
                        <th pSortableColumn="dueDate">Due Date</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-task>
                    <tr>
                        <td class="wrap capitalize">
                            <span class="p-column-title"></span>
                            <a href="javascript:void(0)" (click)="edit(task)">{{task.subject}} </a>
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Priority</span>
                            {{task?.priority == null ? "" : task?.priority.toString() | titlecase}}
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Status</span>
                            {{task.status == null ? "" : task.status.toString().replace('_',' ') | titlecase}}
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Assigned To</span>
                            {{task.assignedTo == null ? "" : task.assignedTo.name}}
                        </td>

                        <td class="wrap">
                            <span class="p-column-title">Due Date</span>
                            {{task.dueDate | date:"MM-dd-yyyy"}}
                        </td>

                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="6">No Tasks found.</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="footer">
                    <tr *ngIf="taskTotalRecords > 5">
                        <td colspan="5" class="text-right"><a href="/allTask/{{profileUuid}}/tasks">View More</a></td>
                    </tr>
                </ng-template>
            </p-table>
        </div>

        <div class="col-12 md:col-6">
            <p-table [autoLayout]="true" [value]="contacts" [(selection)]="selectedContacts" dataKey="userId"
                [lazy]="true" (onLazyLoad)="loadContacts($event)"
                styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm" [rowHover]="true"
                [rows]="5" [paginator]="false" [loading]="contactLoading" [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [totalRecords]="contactTotalRecords" [alwaysShowPaginator]="false">
                <ng-template pTemplate="caption">
                    Recently Updated Contacts
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="name">Name</th>
                        <th SortableColumn="organization">Account Name</th>
                        <th pSortableColumn="email">Email</th>
                        <th pSortableColumn="phone">Phone</th>
                        <th pSortableColumn="lastModifiedDate">Modified On</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-contact>
                    <tr>
                        <td class="wrap capitalize">
                            <span class="p-column-title">Name</span>
                            <a [routerLink]="['/contact', contact.uuid]">{{contact.name}}
                                <sup
                                    *ngIf=" contact.status == 'PENDING_ACTIVATION' || contact.status == 'PENDING_PROFILE'  || contact.status == 'REVOKED' || !contact.verified">
                                    <p-badge class="small-p-badge" [pTooltip]="appUtil.getUserStatus(contact)"
                                        [ngClass]="{'pendingactivation': contact.status == 'PENDING_ACTIVATION','pendingprofile': contact.status == 'PENDING_PROFILE',
                                    'pendingverification': (!contact.verified && !contact.selfSignUp),
                                    'pendingverificationself': (!contact.verified && contact.selfSignUp),'revoked': contact.status == 'REVOKED'}"></p-badge>
                                </sup>
                            </a>
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Account Name</span>
                            <a *ngIf="contact.organization"
                                [routerLink]="['/organization/', contact.organization.uuid]">{{contact.organization?contact.organization.name:''}}</a>
                        </td>
                        <td class="wrap lowercase">
                            <span class="p-column-title">Email</span>
                            <a [routerLink]="['/contact', contact.uuid]">{{contact.email}}</a>
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Phone</span>
                            {{contact.phone}}
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Modified On</span>
                            {{contact.lastModifiedDate | date:"MM-dd-yyyy HH:mm:ss"}}
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="6">No Contacts found.</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="footer">
                    <tr *ngIf="contactTotalRecords > 5">
                        <td colspan="6" class="text-right"><a href="./contacts/">View More</a></td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <div class="col-12 md:col-6">
            <p-table [autoLayout]="true" [value]="organizations" [(selection)]="selectedOrg" dataKey="userId"
                [lazy]="true" (onLazyLoad)="loadOrganizations($event)"
                styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm" [rowHover]="true"
                [rows]="5" [paginator]="false" [loading]="orgLoading" [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [totalRecords]="orgTotalRecords" [alwaysShowPaginator]="false">
                <ng-template pTemplate="caption">
                    Recently Updated Accounts
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="name">Name</th>
                        <th pSortableColumn="companyType">Type</th>
                        <th pSortableColumn="website">Website</th>
                        <th pSortableColumn="primaryContact">Primary Contact</th>
                        <th pSortableColumn="status">Status</th>
                        <th pSortableColumn="lastModifiedDate">Modified On</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-organization>
                    <tr>
                        <td class="wrap">
                            <span class="p-column-title">Name</span>
                            <a [routerLink]="['/organization', organization.uuid]">{{organization.name}}</a>
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Type</span>
                            {{organization.companyType.replace('_',' ') | titlecase}}
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Website</span>
                            {{organization.website}}
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Primary Contact</span>
                            <span *ngFor="let primaryContact of organization.primaryContact; let last = last;"><a
                                    [routerLink]="['/contact/', primaryContact.uuid]">{{primaryContact.email}}&nbsp;{{primaryContact.phone}}{{last?'':';
                                    '}}</a></span>
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Status</span>
                            {{appUtil.statusToTitleCase(organization.status.replace('_',' '))}}
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Modified On</span>
                            {{organization.lastModifiedDate | date:"MM-dd-yyyy HH:mm:ss"}}
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="6">No Accounts found.</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="footer">
                    <tr *ngIf="orgTotalRecords > 5">
                        <td colspan="6" class="text-right"><a href="./organizations/">View More</a></td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <div class="col-12 md:col-6">
            <p-table [autoLayout]="true" [value]="deals" [(selection)]="selectedDeal" dataKey="userId" [lazy]="true"
                (onLazyLoad)="loadDeals($event)"
                styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm" [rowHover]="true"
                [rows]="5" [paginator]="false" [loading]="dealLoading" [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [totalRecords]="dealTotalRecords" [alwaysShowPaginator]="false">
                <ng-template pTemplate="caption">
                    Recent Deals
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="dealDate">Date</th>
                        <th pSortableColumn="buyerOrganizations">Buyers/Investors</th>
                        <th pSortableColumn="targetOrganization">Target/Issuer</th>
                        <th pSortableColumn="dealType">Deal Type</th>
                        <th pSortableColumn="transactionValue">Transaction Value ($m)</th>
                        <th pSortableColumn="lastModifiedDate">Modified On</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-deal>
                    <tr>
                        <td class="wrap">
                            <span class="p-column-title">Date</span>
                            <a [routerLink]="['/deal/', deal.uuid]">{{deal.dealDate |
                                date:'MM-dd-yyyy'}}</a>
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Buyers/Investors</span>
                            <span *ngFor="let organization of deal.buyerOrganizations; let last = last;"><a
                                    [routerLink]="['/organization/', organization.uuid]">{{organization.name}}</a>{{last?'':';
                                '}}</span>
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Target/Issuer</span>
                            <a *ngIf="deal.targetOrganization"
                                [routerLink]="['/organization/', deal.targetOrganization.uuid]">{{deal.targetOrganization?.name}}</a>
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Deal Type</span>
                            {{deal.dealType | dealPipe}}
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Transaction Value ($m)</span>
                            {{deal.transactionValue==null?'-':deal.transactionValue==0?'-':deal.transactionValue}}
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Modified On</span>
                            {{deal.lastModifiedDate | date:"MM-dd-yyyy HH:mm:ss"}}
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="6">No Deals found.</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="footer">
                    <tr *ngIf="dealTotalRecords > 5">
                        <td colspan="6" class="text-right"><a href="./deals">View More</a></td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <div class="col-12 md:col-6"></div>
    </div>
</div>
<task-edit [(show)]="showForm" taskType="dashboard" (afterSave)="ngOnChange($event)" [(taskUuid)]="uuid"></task-edit>
