import { MediaResolver } from './media.resolver';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MediaListComponent } from './media.list.component';

const routes: Routes = [
    {
        matcher: (url) => {
            if (url[0].path == 'media') {
                return {
                    consumed: url
                };
            }
            return null;
        },
        component: MediaListComponent,
        resolve: {
            pathSegments: MediaResolver
        }
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [MediaResolver]
})
export class MediaRoutingModule { }
