<p-panel [toggleable]="true" [collapsed]="false" expandIcon="pi pi-chevron-left" collapseIcon="pi pi-chevron-down">
    <p-header>
        <span class="p-panel-title text-teal">
            <i class="pi pi-user"></i> Contact(s)
        </span>
    </p-header>
    <ng-template pTemplate="icons">
        <button pButton class="p-panel-header-icon p-link" (click)="new()" label="" icon="pi pi-plus"></button>
    </ng-template>
    <p-table [autoLayout]="true" [value]="users" [(selection)]="selectedUsers" dataKey="userId" [lazy]="true"
        (onLazyLoad)="load($event)" [lazyLoadOnInit]="false"
        styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm" [rowHover]="true"
        [rows]="5" [paginator]="false" [loading]="userLoading" [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [totalRecords]="totalRecords"
        [alwaysShowPaginator]="false">
        <ng-template pTemplate="header">
            <tr>
                <th>Contact Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Title</th>
                <th>Action</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-user>
            <tr>
                <td class="wrap capitalize"><a [routerLink]="['/contact', user.uuid]">{{user.name}}</a></td>
                <td class="wrap lowercase"><a [routerLink]="['/contact', user.uuid]">{{user.email}}</a></td>
                <td class="wrap">{{user.phone || user.mobile}}</td>
                <td class="wrap">{{user.title}}</td>
                <td>
                    <p-button [routerLink]="['/contact', user.uuid]" icon="pi pi-eye" class="p-button-success mr-2">
                    </p-button>
                    <a [routerLink]="['/contact/edit/', user.uuid]" pButton pRipple icon="pi pi-pencil"
                        class="p-button-success mr-2"></a>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="5">No contact found to be associated with account.</td>
            </tr>
        </ng-template>
        <ng-template pTemplate="footer">
            <tr *ngIf="totalRecords > 5">
                <td colspan="5" class="text-right"><a href="./organization/{{parent.uuid}}/contacts">View More</a></td>
            </tr>
        </ng-template>
    </p-table>

</p-panel>
<user-popup-add [(show)]="showForm" [organization]="parent" (afterSave)="load()" [canChangeOrganization]="canChangeOrganization"></user-popup-add>