import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function linkedInValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        let value = control.value;
        if (!value)
            return null;
        let valid = true;
        try {
            let urlsegment = (new URL(value)).pathname;
            if (urlsegment.startsWith("/company/")) {
                value = urlsegment.substring("/company/".length);
                if (value.indexOf("/") != -1) {
                    value = value.substring(0, value.indexOf("/"));
                }
            }
            else {
                value = null;
            }
        }
        catch (err) {
            if (value.indexOf('/') != -1 || value.indexOf('http:') != -1 || value.indexOf('https:') != -1)
                valid = false;
        }

        if (valid) {
            if (value == null) {
                valid = false;
            }
            else if (value != control.value) {
                control.setValue(value);
            }
        }

        return valid ? null : { linkedInValidator: true };
    }
}