import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbService } from '../app.breadcrumb.service';
import { environment } from 'src/environments/environment';
import * as mimeType from 'mime'
import { EmailCampaignService } from './email-campaign.service';
import { DataService } from "../_services/data.service";
import { SpotlightService } from '../spotlight/spotlight.service';
import { EngagementService } from '../engagement/engagement.service';
import { BuysideService } from '../buyside/buyside.service';
import { SellsideService } from '../sellside/sellside.service';
import { AppUtil } from '../_helpers';

@Component({
    selector: 'app-email-campaign-add',
    templateUrl: './email-campaign-add.component.html',
    styleUrls: ['./email-campaign-add.component.scss'],
    providers: [MessageService, ConfirmationService]
})
export class EmailCampaignAddComponent implements OnInit {

    @ViewChild('cform') formElement;
    form: UntypedFormGroup;
    submitted = false;
    loadingSpinner: boolean = false;
    selectedMulti: string[] = [];
    campaignType = [{ value: 'Spotlight' }, { value: 'Industry Report' }, { value: 'Marketing' }, { value: 'Engagement Outreach' }, { value: 'BuySide Screen Outreach' }, { value: 'SellSide Screen Outreach' }];
    lookupList: any = { EMAIL_PREFERENCES: [] };
    isEdit: boolean = false;
    uploadURL: string;
    previewDialog: boolean;
    dialogTitle: string;
    spotlightResults: [];
    engagementResults: [];
    buySideResults: [];
    sellSideResults: [];
    emailPreferences: string[] = [];
    helpList: any = {EMAIL_CAMPAIGN:[]};
    toCopy: boolean = false;
    parentUuid: string;

    constructor(
        private _location: Location,
        private breadcrumbService: BreadcrumbService,
        private router: Router,
        private formBuilder: UntypedFormBuilder,
        private emailCampaignService: EmailCampaignService,
        private http: HttpClient,
        private route: ActivatedRoute,
        private dataService: DataService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        private spotlightService: SpotlightService,
        private engagementService: EngagementService,
        private buySideService: BuysideService,
        private sellSideService: SellsideService,
        public appUtil: AppUtil) {

        if (this.router.url.indexOf("/edit/") != -1) {
            this.isEdit = true;
        }
        if(this.router.url.indexOf("/copy") != -1) {
            this.toCopy = true;
        }
        if (this.isEdit) {
            this.breadcrumbService.setItems([
                { label: 'Edit Email Campaign' }
            ]);
        } else if(this.toCopy) {
            this.breadcrumbService.setItems([
                { label: 'Copy Email Campaign' }
            ]);
        } else {
            this.breadcrumbService.setItems([
                { label: 'Create Email Campaign' }
            ]);
        }
    }

    ngOnInit(): void {

        this.previewDialog = false;
        this.lookupList = this.route.snapshot.data['lookupList'];
        this.helpList = this.route.snapshot.data['helpList']['EMAIL_CAMPAIGN'];
        if (this.isEdit) {
            this.emailCampaignService.getEmailCampaign(this.route.snapshot.params.uuid).subscribe(data => {
                this.modelToForm(data as any);
            });
        }
        if (this.toCopy) {
            this.parentUuid = this.route.snapshot.params.uuid;
            this.emailCampaignService.getEmailCampaign(this.route.snapshot.params.uuid).subscribe(data => {
                this.modelToForm(data as any);
            });
        }
        this.loadForm();
        this.uploadURL = environment.apiUrl + '/upload-multi/organization_notes'

    }

    loadForm() {
        this.form = this.formBuilder.group({
            uuid: [''],
            title: ['', Validators.required],
            relatedTo: [],
            relatedTag: [],
            content: ['', Validators.required],
            type: ['', Validators.required],
            status: [],
            uploadedFiles: [null, Validators.required, []],
            spotlight: [],
            engagement: [],
            buySide: [],
            sellSide: [],
            mailchimpId: ['test', Validators.required],
            lookupValue: []
        });
        this.f.type.valueChanges.subscribe(e => {
            if(this.f.type.value.value === 'BuySide Screen Outreach') {
                this.f.spotlight.reset();
                this.f.engagement.reset();
                this.f.sellSide.reset();
            } else if(this.f.type.value.value === 'SellSide Screen Outreach') {
                this.f.spotlight.reset();
                this.f.engagement.reset();
                this.f.buySide.reset();
            } else if(this.f.type.value.value === 'Spotlight') {
                this.f.buySide.reset();
                this.f.engagement.reset();
                this.f.sellSide.reset();
            } else if(this.f.type.value.value === 'engagement') {
                this.f.buySide.reset();
                this.f.spotlight.reset();
                this.f.sellSide.reset();
            } else {
                this.f.buySide.reset();
                this.f.spotlight.reset();
                this.f.sellSide.reset();
                this.f.engagement.reset();
            }
        });
    }

    get f() { return this.form.controls; }

    cancel() {
        this._location.back();
    }

    onUploadAttachment(event) {
        let ar: any[] = this.f.uploadedFiles.value;
        if (ar == null) {
            ar = [];
        }
        for (var i = 0; i < event.originalEvent.body.length; i++) {
            ar.push(event.originalEvent.body[i]);
        }
        this.f.uploadedFiles.setValue(ar);
    }

    save() {
        
        this.submitted = true;
        if (this.form.invalid) {
            this.handleError(null);
            return;
        }
        this.loadingSpinner = true;
        let { uuid, title, content, lookupValue, type, relatedTo, relatedTag, uploadedFiles, spotlight, engagement, buySide, sellSide, mailchimpId } = this.form.value;
        let dataToPost: any = { uuid, title, lookupValue, content, type, relatedTo, relatedTag, uploadedFiles, spotlight, engagement, buySide, sellSide, mailchimpId };
        dataToPost['type'] = this.f.type.value.value;
        if(dataToPost['type'] === 'BuySide Screen Outreach') {
            if(dataToPost['buySide'] == "")
                dataToPost['buySide'] = null;
        } else if(dataToPost['type'] === 'Spotlight') {
            if(dataToPost['spotlight'] == "")
                dataToPost['spotlight'] = null;
        } else if(dataToPost['type'] === 'SellSide Screen Outreach') {
            if(dataToPost['sellSide'] == "")
                dataToPost['sellSide'] = null;
        } else if(dataToPost['type'] === 'Engagement Outreach') {
            if(dataToPost['engagement'] == "")
                dataToPost['engagement'] = null;
        }
        if (this.f.lookupValue.value != null) {
            const temp = [];
            let lookval = this.f.lookupValue.value;
            for (var i = 0; i < lookval.length; i++) {
                var searchdata = this.lookupList['EMAIL_PREFERENCES'].find(obj => obj.uuid === lookval[i]);
                temp.push(searchdata);
            }
            dataToPost['lookupValue'] = temp;
        }
        let apiToCall: any;
        if (this.isEdit) {
            apiToCall = this.emailCampaignService.updateEmailCampaign(dataToPost);
        } 
        else if(this.toCopy) {
            dataToPost['uuid'] = null;
            apiToCall = this.emailCampaignService.copyCampaign(this.parentUuid, dataToPost);
        } 
        else {
            apiToCall = this.emailCampaignService.addEmailCampaign(dataToPost);
        }
        apiToCall.subscribe(
            data => {
                let campaign = data as any;
                let detail;
                if (this.isEdit) {
                    detail = 'Email Campaign Updated';
                } else if(this.toCopy) {
                    detail = 'Email Campaign Copied';
                } else {
                    detail = 'Email Campaign Created'
                }
                if(this.toCopy) {
                    this.router.navigate(['/email-campaign/' + campaign.uuid + '/recipients/'+ this.parentUuid +'/show']);
                } else {
                    if(campaign.buySide != null || campaign.sellSide != null) {
                        if(this.isEdit) {
                            this.router.navigate(['/email-campaign/' + campaign.uuid + "/sync-screen/edit"]);
                        } else {
                            this.router.navigate(['/email-campaign/' + campaign.uuid + "/sync-screen/add"]);
                        }
                    } else {
                        this.router.navigate(['/email-campaign/' + campaign.uuid]);
                    }
                    
                }
                this.dataService.setData('message', detail);
                this.submitted = false;
            },
            error => {
                this.loadingSpinner = false;
                let errors = error.error;
                if (errors.code && errors.code == 'VALIDATION_ERROR') {
                    for (var i = 0; i < errors.subErrors.length; i++) {
                        this.f[errors.subErrors[i].field].setErrors({ 'message': errors.subErrors[i].message });
                    }
                    this.handleError(errors.subErrors[0].field);
                }
            }
        );
    }

    deleteDocument(event, uuid) {
        event.preventDefault();
        let arNew: any[] = [];
        let ar: any[] = this.f.uploadedFiles.value;
        for (var i = 0; i < ar.length; i++) {
            if (uuid != ar[i].uuid)
                arNew.push(ar[i]);
        }
        this.f.uploadedFiles.setValue(arNew);
    }

    openDocument(event) {
        // Prevent default behavior when clicking a link
        event.preventDefault();

        // Get filename from href
        var filename = event.target.href;

        this.http.get(filename, { responseType: 'arraybuffer' }).subscribe(
            data => {
                var file = new Blob([data], { type: mimeType.getType(filename) });
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            });
    }

    handleError(elemId) {
        this.messageService.add({ severity: 'error', summary: 'Form Errors', detail: "Form has errors. Please fix them first", life: 3000 });
        let invalidControl = this.formElement.nativeElement.querySelector('.ng-invalid');
        if (invalidControl) {
            invalidControl.focus();
        }
        else if (elemId != null) {
            invalidControl = this.formElement.nativeElement.querySelector('#' + elemId);
            if (invalidControl) {
                invalidControl.focus();
            }
        }
    }

    modelToForm(model) {
        this.form.setValue({
            uuid: model.uuid,
            title: model.title,
            content: model.content,
            status: { 'value': model.status },
            relatedTo: model.relatedTo,
            relatedTag: model.relatedTag,
            type: { 'value': model.type },
            uploadedFiles: model.uploadedFiles,
            spotlight: model.spotlight,
            engagement: model.engagement,
            buySide: model.buySide,
            sellSide: model.sellSide,
            mailchimpId: model.mailchimpId,
            lookupValue: this.getUUid(model.lookupValue)
        });
    }

    searchSpotlights(event) {
        this.spotlightService.lookupSpotlight(event.query).subscribe(data => {
            this.spotlightResults = data as any;
        });
    }

    searchEngagements(event) {
        this.engagementService.lookupEngagement(event.query).subscribe(data => {
            this.engagementResults = data as any;
        });
    }

    searchBuySide(event) {
        this.buySideService.lookupBuySide(event.query).subscribe(data => {
            this.buySideResults = data as any;
        });
    }

    searchSellSide(event) {
        this.sellSideService.lookupSellSide(event.query).subscribe(data => {
            this.sellSideResults = data as any;
        });
    }

    onChangeCheckbox(event, val) {
        var index = this.emailPreferences.indexOf(val);
        if (event.checked && index === -1)
            this.emailPreferences.push(val);
        else
            this.emailPreferences.splice(index, 1);
        this.f.lookupValue.setValue(this.emailPreferences);
    }

    protected getUUid(data) {
        const uuid = [];
        if (data != null) {
            for (var i = 0; i < data.length; i++) {
                uuid.push(data[i].uuid);
            }
            this.emailPreferences = uuid;
        }

        return uuid;
    }

    getHelpText(key) {
        let test = this.helpList.filter(function (element) {
            return element.key == key;
        });
        if (test[0] != undefined)
            return test[0]['text'];
    }

}
