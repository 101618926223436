import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment } from '@angular/router';
import { ClassificationResolver } from '../classification/classification.resolver';
import { ConfigurationResolver } from '../configuration/configuration.resolver';
import { CountryResolver } from '../configuration/country.resolver';
import { UserResolver } from '../users/user.resolver';
import { EmailCampaignAddComponent } from './email-campaign-add.component';
import { EmailCampaignViewComponent } from './email-campaign-view.component';
import { HelpResolver } from '../help/help.resolver';
import { RecipientListComponent } from './recipient-list.component';
import { EmailCampaignsListComponent } from './email-campaigns-list.component';

const routes: Routes = [

    {
        matcher: (url) => {
            if ((url.length == 1 && url[0].path == 'email-campaign') || ((url.length == 3 && url[2].path == 'campaigns'))) {
                return {
                    consumed: url,
                    posParams: {
                        source: url.length > 1 ? url[0]:new UrlSegment('', {}),
                        sourceuuid: url.length > 2 ? url[1]:new UrlSegment('', {})
                    }
                };  
            }
            return null;
        },
        component: EmailCampaignsListComponent
    },
    {
        path: 'email-campaign/new', component: EmailCampaignAddComponent,
        resolve: {
            lookupList: ConfigurationResolver,
            helpList: HelpResolver,
        }
    },
    {
        matcher: (url) => {
            if ((url.length > 0 && url[0].path == 'email-campaign') && (url.length == 6 && url[5].path == 'checkbox')) {
                return {
                    consumed: url,
                    posParams: {
                        previousCampaignUuid: url[1],
                        uuid: url[3],
                        checkbox: new UrlSegment((url[2].path == 'checkbox')?'true':'false', {})
                    }
                };  
            }
            return null;
        },
        component: RecipientListComponent
    },
    {
        matcher: (url) => {
            if ((url.length > 0 && url[0].path == 'email-campaign') && (url.length == 2 || (url.length ==3 && url[2].path == 'recipients') 
                || (url.length == 5 && url[4].path == "show") || (url.length == 4 && url[2].path == "sync-screen"))) {
                return {
                    consumed: url,
                    posParams: {
                        uuid: url[1]
                    }
                };  
            }
            return null;
        },
        component: EmailCampaignViewComponent,
        resolve: {
            lookupList: ConfigurationResolver,
        }
    },
    {
        matcher: (url) => {
            if ((url.length > 0 && url[0].path == 'email-campaign') && (url.length ==3) && (url[1].path == 'edit' || url[1].path == 'copy'))  {
                return {
                    consumed: url,
                    posParams: {
                        uuid: url[2]
                    }
                };  
            }
            return null;
        },
        component: EmailCampaignAddComponent,
        resolve: {
            lookupList: ConfigurationResolver,
            helpList: HelpResolver,
        }
    }
];



@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [ClassificationResolver, ConfigurationResolver, UserResolver, CountryResolver, HelpResolver]
})
export class EmailCampaignRoutingModule { }
