<div class="login-body">
    <div class="login-wrapper">
        <div class="login-panel">
            <img src="assets/layout/images/tbp-logo.png" class="logo" alt="TruebBlue Partners" />
            <div class="login-form">
                <div class="p-fluid formgrid grid">
                    <div class="field col-12 md:col-12">
                        <h2>Activate Profile</h2>
                    </div>
                    <div class="field col-12 md:col-12">
                        <p-messages severity="error" *ngIf="errorCode=='INVALID_TOKEN'">
                            <ng-template pTemplate>
                                <div class="ml-2 text-left">Email verification link you used is either invalid or
                                    expired.<br />
                                    Click <strong><a [routerLink]="[]" (click)="showLinkForm()">here</a></strong> to get
                                    new verification link.</div>
                            </ng-template>
                        </p-messages>
                        <p-messages severity="success" *ngIf="errorCode=='VERIFIED'">
                            <ng-template pTemplate>
                                <div class="ml-2 text-left">
                                    <p>
                                        Thank you for verifying your account. Your account is now active.
                                        Please click on the button below to login and start using {{appUtil.appName}}.
                                        Redirecting to application.
                                    </p>
                                </div>
                            </ng-template>
                        </p-messages>
                        <p-messages severity="info" *ngIf="errorCode=='PROFILE_ALREADY_ACTIVE'">
                            <ng-template pTemplate>
                                <div class="ml-2 text-left">
                                    <p>
                                        Your account is already marked as active.
                                        Please click on the button below to login and start using {{appUtil.appName}}.
                                    </p>
                                    <br />
                                    <div class="field col-12 md:col-12">
                                        <button pButton pRipple label="Login" (click)="goToLogin()"></button>
                                    </div>
                                </div>
                            </ng-template>
                        </p-messages>
                        <p-messages severity="error" *ngIf="errorCode=='PROFILE_LOCKED'">
                            <ng-template pTemplate>
                                <div class="ml-2 text-left">
                                    <p>
                                        Your profile is marked as locked in our system. You need to contact the support
                                        team at support@tbp.com, in order to get your profile unlocked.
                                    </p>
                                    <br />
                                    <div class="field col-12 md:col-12">
                                        <button pButton pRipple label="Login" (click)="goToLogin()"></button>
                                    </div>
                                    <div class="field col-12 md:col-12">
                                        <button pButton pRipple label="SignUp" (click)="goToSignUp()"></button>
                                    </div>
                                </div>
                            </ng-template>
                        </p-messages>
                        <p-messages [closable]="false"></p-messages>
                    </div>

                </div>
            </div>

            <p>A problem? <a href="#">Click here</a> and let us help you.</p>
        </div>
        <div class="login-image">
            <div class="login-image-content">
                <h1>Benefits of {{appUtil.appName}}</h1>
                <h3>Lorem ipsum dolor sit amet, consectetur
                    <br /> adipiscing elit. Donec posuere velit nec enim
                    <br /> sodales, nec placerat erat tincidunt.
                </h3>
            </div>
            <div class="image-footer">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                <div class="icons">
                    <i class="pi pi-github"></i>
                    <i class="pi pi-twitter"></i>
                </div>
            </div>
        </div>
    </div>
</div>
<p-dialog [(visible)]="showDialog" (onHide)="hideDialog()" header="Generate Activation Link" [modal]="true"
    styleClass="p-fluid">
    <ng-template pTemplate="content">
        <form [formGroup]="form">
            <div class="login-form" style="max-width: '500px'">
                <div class="p-fluid formgrid grid">
                    <div class="field col-12 md:col-12">
                        <h2>Forgot Password</h2>
                        <p>Do not have an account? <a routerLink="../register">Sign Up</a></p>
                    </div>
                    <div class="field col-12 md:col-12">
                        <p-messages severity="error" *ngIf="linkErrorCode=='PROFILE_NOT_FOUND'">
                            <ng-template pTemplate>
                                <div class="ml-2 text-left">Could not find profile matching email within the
                                    system.<br />
                                    Click <strong><a [routerLink]="[]" (click)="goToSignUp()">here</a></strong> to
                                    register.</div>
                            </ng-template>
                        </p-messages>
                        <p-messages severity="error" *ngIf="linkErrorCode=='PROFILE_LOCKED'">
                            <ng-template pTemplate>
                                <div class="ml-2 text-left">Your profile is marked as locked in our system. You need
                                    to contact the support team at support@tbp.com, in order to get your profile
                                    unlocked.</div>
                            </ng-template>
                        </p-messages>
                        <p-messages severity="info" *ngIf="linkErrorCode=='PROFILE_ALREADY_ACTIVE'">
                            <ng-template pTemplate>
                                <div class="ml-2 text-left">
                                    <p>
                                        Your account is already marked as active.
                                        Please click on the button below to login and start using {{appUtil.appName}}.
                                    </p>
                                    <br />
                                    <div class="field col-12 md:col-12">
                                        <button pButton pRipple label="Login" (click)="goToLogin()"></button>
                                    </div>
                                </div>
                            </ng-template>
                        </p-messages>
                        <p-messages severity="success" *ngIf="linkErrorCode=='SUCCESS'">
                            <ng-template pTemplate>
                                <div class="ml-2 text-left">
                                    <p>
                                        An email with activation link has been sent to the registered email.Please open
                                        the email and click on the link to activate your profile.
                                    </p>
                                    <div class="field col-12 md:col-12">
                                        <button pButton pRipple label="Login" (click)="goToLogin()"></button>
                                    </div>
                                </div>
                            </ng-template>
                        </p-messages>
                    </div>
                    <div class="field col-12 md:col-12 text-left">
                        <span class="p-input-icon-right">
                            <i class="pi pi-envelope"></i>
                            <input pInputText id="email" formControlName="email" placeholder="Email"
                                [ngClass]="{ 'ng-invalid ng-dirty': hasError(form,'email',submitted) }" required
                                autofocus />
                        </span>
                        <ng-container *ngIf="hasError(form,'email',submitted)">
                            <small class="p-error p-align-left" *ngIf="f.email.errors?.required">Email is
                                required.</small>
                            <small class="p-error p-align-left" *ngIf="f.email.errors?.pattern">Email is not
                                valid.</small>
                            <small class="p-error" *ngIf="f.email.errors?.message">{{f.email.errors.message}}</small>
                        </ng-container>
                    </div>
                </div>
            </div>
        </form>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
        <button pButton pRipple label="Send Link" form="clform" icon="pi pi-check" class="p-button-text"
            (click)="sendActivationEmail()"></button>
    </ng-template>
</p-dialog>