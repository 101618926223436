<div class="card">
    <form [formGroup]="form" #cform>
        <div class="formgrid grid headerSticky">
            <div class="field col-12 md:col-12 text-right" *ngIf=" loadingSpinner == false">
                <p-button label="Save" icon="pi pi-check" iconPos="left" (click)="save()" styleClass="mr-2">
                </p-button>
                <p-button label="Cancel" icon="pi pi-times" (click)="cancel()" iconPos="left"
                    styleClass="p-button-secondary"></p-button>
            </div>
            <div class="field col-12 md:col-12 text-right" *ngIf="loadingSpinner">
                <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
                    fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
            </div>
        </div>
        <h5 class="custom-class">Create Engagement</h5>
        <div class="p-fluid  grid">
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <input [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.name.errors }" id="name"
                        formControlName="name" type="text" maxlength="40" pInputText pTooltip="{{getHelpText('Engagement Name')}}" tooltipPosition="top">
                    <label for="name">Engagement Name <span class="mandate">*</span></label>
                </span>
                <small class="p-error"
                    *ngIf="submitted && f.name.invalid && f.name.hasError('message')">{{f.name.errors.message}}</small>
                <span class="p-error" *ngIf="form.get('name').hasError('required') && submitted">Engagement Name is
                    Required</span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <p-autoComplete [forceSelection]="true"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.client.errors }" formControlName="client"
                        [suggestions]="companyResults" (completeMethod)="searchCompanies($event)" field="name" pTooltip="{{getHelpText('Client')}}" tooltipPosition="top">
                    </p-autoComplete>
                    <label for="client">Client <span class="mandate">*</span></label>
                    <span class="p-error" *ngIf="form.get('client').hasError('required') && submitted">Client is
                        Required</span>
                </span>
                <a href="javascript: void(0);" (click)="createAccount()">New Account</a>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <p-dropdown [options]="lookupList.ENGAGEMENT_TYPE"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.engagementType.errors }"
                        styleClass="form-control" formControlName="engagementType" id="engagementType"
                        name="engagementType" optionLabel="value" optionValue="value" [autoDisplayFirst]="false" pTooltip="{{getHelpText('Engagement Type')}}" tooltipPosition="top">
                    </p-dropdown>
                    <label for="dealType">Engagement Type <span class="mandate">*</span></label>
                </span>
                <span class="p-error" *ngIf="form.get('engagementType').hasError('required') && submitted">Engagement
                    Type is Required</span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <tbp-autocomplete [forceSelection]="true" [items]="" [formControl]="f.dealTeam" [multiple]="true"
                        [type]="'Contact'" field="displayName"
                        pTooltip="{{getHelpText('Deal Team')}}" tooltipPosition="top">
                    </tbp-autocomplete>
                    <label for="dealTeam">Deal Team </label>
                </span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <p-calendar [showIcon]="true" dateFormat="mm-dd-yy" formControlName="startDate" inputId="icon"
                        [readonlyInput]="true" [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.startDate.errors }" pTooltip="{{getHelpText('Start Date')}}" tooltipPosition="top">
                    </p-calendar>
                    <label for="startDate">Start Date <span class="mandate">*</span> </label>
                </span>
                <small class="p-error"
                    *ngIf="submitted && f.startDate.invalid && f.startDate.hasError('required')">Start Date is
                    required.</small>

            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <p-calendar [showIcon]="true" dateFormat="mm-dd-yy" formControlName="endDate" inputId="icon"
                        [readonlyInput]="true" [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.endDate.errors }" pTooltip="{{getHelpText('End Date')}}" tooltipPosition="top">
                    </p-calendar>
                    <label for="endDate">End Date <span class="mandate">*</span> </label>
                </span>
                <small class="p-error" *ngIf="submitted && f.endDate.invalid && f.endDate.hasError('required')">End Date
                    is required.</small>
                <small class="p-error"
                    *ngIf="submitted && f.endDate.invalid && f.endDate.hasError('message')">{{f.endDate.errors.message}}</small>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <p-dropdown [options]="status" styleClass="form-control" formControlName="status"
                        [autoDisplayFirst]="false" name="status" optionLabel="value" pTooltip="{{getHelpText('Status')}}" tooltipPosition="top">
                    </p-dropdown>
                    <label for="status">Status<span class="mandate">*</span></label>
                </span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <p-dropdown [options]="lookupList.PROGRESS" styleClass="form-control" formControlName="progress"
                        #ddprogress id="progress" name="progress" optionLabel="value" optionValue="value"
                        [autoDisplayFirst]="false" pTooltip="{{getHelpText('Progress')}}" tooltipPosition="top" [showClear]="true">
                    </p-dropdown>
                    <label for="progress">Progress</label>
                </span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <p-calendar [showIcon]="true" dateFormat="mm-dd-yy" formControlName="actualCloseDate" inputId="icon"
                        [readonlyInput]="true"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.actualCloseDate.errors }" pTooltip="{{getHelpText('Actual Close Date')}}" tooltipPosition="top"></p-calendar>
                    <label for="actualCloseDate">Actual Close Date</label>
                    <small class="p-error"
                        *ngIf="submitted && f.actualCloseDate.invalid && f.actualCloseDate.hasError('message')">{{f.actualCloseDate.errors.message}}</small>
                </span>
            </div>
            <div class="field col-12 md:col-12">
                <label for="nextMilestone">Next Milestone <span class="mandate">*</span></label>
                <editor #editor formControlName="nextMilestone" [init]="appUtil.getInitData" pTooltip="{{('Next Milestone')}}" tooltipPosition="top"></editor>
                <small class="p-error"
                    *ngIf="submitted && f.nextMilestone.invalid && f.nextMilestone.hasError('required')">Next Milestone
                    is required.</small>
            </div>
        </div>

    </form>
</div>
<account-popup (onFormGroupChange)="onFormGroupChangeEvent($event)" [(show)]="accountForm" type="engagement">
</account-popup>