import { DataService } from './../_services/data.service';
import { AppUtil } from 'src/app/_helpers';
import { SellsideService } from './sellside.service';
import { ConfirmationService, LazyLoadEvent, MenuItem, MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, OnInit, SimpleChanges, ViewChild, NgZone, OnDestroy, ViewEncapsulation } from '@angular/core';
import { DomHandler } from 'primeng/dom';
import { Table } from 'primeng/table';
import { UsersService } from '../users/users.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { EmailCampaignService } from '../email-campaign/email-campaign.service';
import { makeFieldInfo, RuleSet, Rule, makeRule, FieldInfo } from '../_models';
import { SearchHeler } from '../_helpers';
import { __values } from 'tslib';
import { saveAs } from "file-saver";
import { OrganizationService } from '../organization/organization.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Subscription } from 'rxjs';

@Component({
    selector: 'sellside-prospect-list',
    templateUrl: './sellside-prospect-list.component.html',
    styleUrls: ['./sellside-prospect-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [MessageService, ConfirmationService]
})
export class SellsideProspectListComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription = new Subscription();
    @ViewChild('dt') dataTable: Table;
    @ViewChild('op') op: OverlayPanel;
    @Input() sellside: any;
    form: UntypedFormGroup;
    scrollTop: string;
    campaigns: any;
    showUpload: boolean = false;
    uploadUrl: string = null;
    uploadTitle: string = null;
    sortField: string;
    sortOrder: number;
    currentMenuData: any;
    prospectExcel: {};
    firstRow: number;
    pageSize: number;
    menuItems: MenuItem[];
    totalRecords: number = 0;
    prospects: any[];
    loadingSpinner: boolean = false;
    loading: boolean;
    searchUrl: string = '/sellside-pospects';
    searchMenuItems: MenuItem[];
    uploadMenuItems: MenuItem[];
    availableColumns: any[];
    fieldInfo: FieldInfo[];
    oldVal: any[] = [];
    tbpEmployees: any[] = [];
    selectedColumns: any[];
    showCampaignList: boolean = false;
    selectedProspectForRecipient: string;
    selectedCampaigns: any[];
    campaignRecipientsMap: any = new Map<string, []>();
    campaignsForAssociation: any = [];
    initialFieldVisibility: any = null;
    lookupList: any = {
        PROSPECTS_STATUS: [],
        SELLSIDE_PROSPECT_LOOKUP_1: [],
        SELLSIDE_PROSPECT_LOOKUP_2: []
    };
    customLabels: Map<string, string> = null;
    tasksDialog: boolean = false;
    selectedProspect: any;
    taskUuid: string[] = [];
    noteUuid: string[] = [];
    prospectContacts: any;
    relatedContact: any;
    associatedContacts: any;
    selectedUserUUIDs: string[] = [];
    prospectValue: any;
    allContacts: any[] = [];
    noteDialog: boolean = false;
    searchTermForAssocited: string;
    filteredContacts: any[] = [];
    selectedValues: string[] = [];
    organizations: any[] = [];
    showPortfolioDialog: boolean = false;
    prospectLenght: number = 0;
    isContactLinkedIn: boolean = false;
    dialogVisible: boolean = false;
    editingModes: { [key: string]: boolean } = {};
    term:string;
    constructor(
        private route: ActivatedRoute,
        private sellsideService: SellsideService,
        private userService: UsersService,
        private router: Router,
        public appUtil: AppUtil,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        private formBuilder: UntypedFormBuilder,
        private emailCampaignService: EmailCampaignService,
        private searchHelper: SearchHeler,
        private organizationService: OrganizationService,
        private dataService: DataService,
        private ngZone: NgZone) {
        this.sortField = "sortOrder";
        this.firstRow = 0;
        this.pageSize = -1;
        this.sortOrder = 1;

    }
    ngOnInit(): void {
        window.addEventListener('scroll', this.saveScrollPosition);
        this.tbpEmployees = this.route.snapshot.data['tbpEmployees'];
        this.lookupList = this.route.snapshot.data['lookupList'];
        this.currentMenuData = { id: '' };
        this.menuItems = [];
        //      this.getAllOrganizations();
        // this.initialFieldVisibility = this.route.snapshot.data['filterResolver'].fields;
        let i = 0;
        this.fieldInfo = [
            makeFieldInfo({ property: "sortOrder", label: 'Sort Order', type: 'string', searchable: false, searchType: 'text', sortable: true, columnIndex: 0, visible: 'H' }),
            makeFieldInfo({ property: "prospectName", label: 'Prospect Name', type: 'string', searchable: false, searchType: 'text', sortable: true, columnIndex: 0, visible: 'A' }),
            makeFieldInfo({ property: "partnershipOutreach", label: 'Partnership Outreach', type: 'string', searchable: false, searchType: 'text', sortable: true, columnIndex: 1 }),
            makeFieldInfo({ property: "task", label: 'Task', type: 'string', searchable: false, searchType: 'text', sortable: false, columnIndex: 24 }),
            makeFieldInfo({ property: "employees", label: 'Initials', type: 'string', searchable: false, searchType: 'text', sortable: false, columnIndex: 2 }),
            makeFieldInfo({ property: "status", label: 'Status', type: 'string', searchable: false, searchType: 'text', sortable: true, columnIndex: 3 }),
            makeFieldInfo({ property: "sendProcessLetter", label: 'Send Process Letter', type: 'boolean', searchable: false, searchType: 'text', sortable: true, columnIndex: 4 }),
            makeFieldInfo({ property: "website", label: 'Website', type: 'string', searchable: false, searchType: 'text', sortable: true, columnIndex: 5 }),
            makeFieldInfo({ property: "tier", label: 'Tier', type: 'string', searchable: false, searchType: 'text', sortable: true, columnIndex: 6 }),
            makeFieldInfo({ property: "firmType", label: 'Firm Type', type: 'string', searchable: false, searchType: 'text', sortable: true, columnIndex: 7 }),
            makeFieldInfo({ property: "cimVersion", label: 'CIM Version', type: 'string', searchable: false, searchType: 'text', sortable: true, columnIndex: 8 }),
            makeFieldInfo({ property: "processNotes", label: 'Process Notes', type: 'string', searchable: false, searchType: 'text', sortable: true, columnIndex: 9 }),
            makeFieldInfo({ property: "notesForClient", label: 'Notes for Client', type: 'string', searchable: false, searchType: 'text', sortable: true, columnIndex: 10 }),
            makeFieldInfo({ property: "internalNotes", label: 'Internal Notes', type: 'string', searchable: false, searchType: 'text', sortable: true, columnIndex: 11 }),
            makeFieldInfo({ property: "notesForPL", label: 'Notes for PL', type: 'string', searchable: false, searchType: 'text', sortable: true, columnIndex: 12 }),
            makeFieldInfo({ property: "portfolioCompanies", label: 'Portfolio Company ', type: 'string', searchable: false, searchType: 'text', sortable: false, columnIndex: 13 }),
            makeFieldInfo({ property: "thesis", label: 'Thesis', type: 'string', searchable: false, searchType: 'text', sortable: true, columnIndex: 14 }),
            makeFieldInfo({ property: "customTextField1", label: 'Custom Text Field-1', type: 'string', searchable: false, searchType: 'text', sortable: true, columnIndex: 15, visible: 'F' }),
            makeFieldInfo({ property: "customTextField2", label: 'Custom Text Field-2', type: 'string', searchable: false, searchType: 'text', sortable: true, columnIndex: 16, visible: 'F' }),
            makeFieldInfo({ property: "customIntField1", label: 'Custom Int Field-1', type: 'integer', searchable: false, searchType: 'numeric', sortable: true, columnIndex: 17, visible: 'F' }),
            makeFieldInfo({ property: "customIntField2", label: 'Custom Int Field-2', type: 'string', searchable: false, searchType: 'text', sortable: true, columnIndex: 18, visible: 'F' }),
            makeFieldInfo({ property: "customNumberField1", label: 'Custom Number Field-1', type: 'string', searchable: false, searchType: 'text', sortable: true, columnIndex: 19, visible: 'F' }),
            makeFieldInfo({ property: "customNumberField2", label: 'Custom Number Field-2', type: 'string', searchable: false, searchType: 'text', sortable: true, columnIndex: 20, visible: 'F' }),
            makeFieldInfo({ property: "customLookupField1", label: 'Custom Lookup Field-1', type: 'string', searchable: false, searchType: 'text', sortable: true, columnIndex: 21, visible: 'F' }),
            makeFieldInfo({ property: "customLookupField2", label: 'Custom Lookup Field-2', type: 'string', searchable: false, searchType: 'text', sortable: true, columnIndex: 22, visible: 'F' }),
            makeFieldInfo({ property: "description", label: 'Description', type: "string", searchType: 'text', sortable: true, columnIndex: 23, visible: 'F' }),
            makeFieldInfo({ property: "linkedinPageId", label: 'LinkedIn Profile', type: 'string', searchable: false, searchType: 'text', sortable: false, columnIndex: 24 }),
        ];

        this.searchMenuItems = [{
            label: 'Contact',
            command: () => {
                this.processSearchCommand('contacts');
            }
        },
        {
            label: 'Account',
            command: () => {
                this.processSearchCommand('organization');
            }
        }, {
            label: 'Deal',
            command: () => {
                this.processSearchCommand('deals');
            }
        }];
        this.uploadMenuItems = [{
            label: 'Upload',
            items: [{
                label: 'Contact', icon: 'pi pi-upload',
                command: () => {
                    this.uploadUrl = 'sellside/' + this.sellside.uuid + '/upload-prospects/contacts';
                    this.uploadTitle = "Upload Contacts as Prospect";
                    this.showUpload = true;
                }
            },
            {
                label: 'Account', icon: 'pi pi-upload',
                command: () => {
                    this.uploadUrl = 'sellside/' + this.sellside.uuid + '/upload-prospects/accounts';
                    this.uploadTitle = "Upload Accounts as Prospect";
                    this.showUpload = true;
                }
            }]
        },
        {
            label: 'Templates',
            items: [
                { label: 'Contact', icon: 'pi pi-download', command: () => { this.appUtil.downloadTemplate('prospect-contact-template.xlsx'); } },
                { label: 'Account', icon: 'pi pi-download', command: () => { this.appUtil.downloadTemplate('prospect-account-template.xlsx'); } },
            ]
        }];
        if (this.sellside.uuid == undefined) {
            this.route.url.subscribe((url) => {
                this.searchUrl = "sell-side/" + url[1].path + "/prospects";
                this.sellsideService.getSellside(url[1].path).subscribe(
                    data => {
                        this.sellside = data as any;
                        this.findCampaignsWithRecipients(this.sellside.uuid);
                    })
            })
        }
        else {
            this.searchUrl = "sell-side/" + this.sellside.uuid + "/prospects";
            this.findCampaignsWithRecipients(this.sellside.uuid);
        }
    }
    loadform() {
        this.form = this.formBuilder.group({
            prospectsStatus: []
        });
    }
    modelToForm(model) {
        this.form.setValue({
            prospectsStatus: (model.prospectsStatus == null || model.prospectsStatus == '') ? null : this.lookupList['PROSPECTS_STATUS'].find(field => field.value === model['prospectsStaus']),
            sellsideprospectLookup1: (model.sellsideprospectLookup1 == null || model.sellsideprospectLookup1 == '') ? null : this.lookupList['SELLSIDE_PROSPECT_LOOKUP_1'].find(field => field.value === model['sellsideprospectLookup1']),
            sellsideprospectLookup2: (model.sellsideprospectLookup2 == null || model.sellsideprospectLookup2 == '') ? null : this.lookupList['SELLSIDE_PROSPECT_LOOKUP_2'].find(field => field.value === model['sellsideprospectLookup2']),
        })
    }

    getTBPEmployees(prospect) {
        let missingEmployees: any[] = [];
        if (prospect.employees) {
            prospect.employees.forEach(element => {
                const emp = this.appUtil.findItemInList(this.tbpEmployees, element, 'uuid');
                if (emp === undefined) {
                    missingEmployees.push(element);
                }
            })
        }
        if (missingEmployees.length > 0) {
            return [...this.tbpEmployees, ...missingEmployees];
        }
        return this.tbpEmployees;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.sellside && changes.sellside.currentValue && changes.sellside.currentValue.uuid) {
            let selectedData = this.dataService.getDataByKey('contacts-sellside-' + this.sellside.uuid);
            let contactsToBeAdded = [];
            let accountsToBeAdded = [];
            if (selectedData && Array.isArray(selectedData)) {
                this.dataService.deleteData('contacts-sellside-' + this.sellside.uuid);
                contactsToBeAdded = selectedData.map(a => a.uuid);
            }
            else {
                selectedData = this.dataService.getDataByKey('organizations-sellside-' + this.sellside.uuid);
                if (selectedData && Array.isArray(selectedData)) {
                    this.dataService.deleteData('organizations-sellside-' + this.sellside.uuid);
                    selectedData.forEach(org => {
                        if (org.selectedUsers && Array.isArray(org.selectedUsers) && org.selectedUsers.length > 0) {
                            contactsToBeAdded = contactsToBeAdded.concat(org.selectedUsers.map(a => a.uuid));
                        }
                        else {
                            accountsToBeAdded.push(org.uuid);
                        }
                    });
                }
            }
            if (contactsToBeAdded.length > 0 || accountsToBeAdded.length > 0) {
                this.loading = true;
                this.sellsideService.addProspects(this.sellside.uuid, contactsToBeAdded, accountsToBeAdded).subscribe(
                    data => {
                        this.messageService.add({ severity: 'success', summary: 'Successful', detail: this.appUtil.prepareMessageForProspects(data as any), life: 3000 });
                        this.reload();
                    },
                    error => {
                        this.reload();
                    });
            }
            else {
                this.reload();
            }
        }
    }

    processSearchCommand(type) {
        let rootRule: RuleSet = { "condition": "or", ruleSets: [], rules: [] };

        //"rules": [makeRule({ property: "level1", searchType: 'exact' }, this.sellside.classification.level1.map((l1) => l1.uuid), "in")]
        // if (this.sellside.classification.level2) {
        //     rootRule.rules.push(makeRule({ property: "level2", searchType: 'exact' }, this.sellside.classification.level2.map((l1) => l1.uuid), "in"));
        // }
        // if (this.sellside.classification.level3) {
        //     rootRule.rules.push(makeRule({ property: "level3", searchType: 'exact' }, this.sellside.classification.level3.map((l1) => l1.uuid), "in"));
        // }
        // if (this.sellside.classification.vertical) {
        //     rootRule.rules.push(makeRule({ property: "vertical", searchType: 'exact' }, this.sellside.classification.vertical.map((l1) => l1.uuid), "in"));
        // }
        // if (this.sellside.classification.ecosystem) {
        //     rootRule.rules.push(makeRule({ property: "ecosystem", searchType: 'exact' }, this.sellside.classification.ecosystem.map((l1) => l1.uuid), "in"));
        // }
        if (type == 'organization') {

        }
        this.searchHelper._search(rootRule, '/sellside/' + this.sellside.uuid + '/' + type + '/add', null, "relevance", -1, 0, this.appUtil.pageSize, true);
    }

    _search(e) {
        if (this.sellside && this.sellside.uuid) {
            var searchParams = this.searchHelper.prepareSearchParams(
                e.searchTerm,
                e.filter,
                e.sortField,
                e.sortOrder,
                e.first,
                e.rows,
                this.fieldInfo
            );
            this.term =  e.searchTerm;
            this.prospectExcel = searchParams;
            searchParams['page'] = 0;
            searchParams['pageSize'] = -1;
            var sortBy = []
            var sortDir = e.sortOrder == 1 ? 'ASC' : 'DESC';
            if (e.sortField && e.sortField != '') {
                sortBy[sortBy.length] = { "property": e.sortField, "direction": sortDir };
            }
            searchParams['sortDetails'] = sortBy;
            searchParams['rootRule'] = { 'condition': 'and', rules: [] };
            searchParams['rootRule'].rules.push({ "searchType": "exactsearch", "field": "sellside.uuid", "value": this.sellside.uuid, "operator": "=" });
            const event: any = e;
            const searchTerm = event?.searchTerm;
            if (searchTerm) {
                searchParams['rootRule'].rules.push({ "searchType": "text", "field": "prospectName", "values": [searchTerm], "operator": "in" });
            }
            this.loading = true;
            this.sellsideService.getProspects(this.sellside.uuid, searchParams).subscribe(
                data => {
                    let response = data as any;
                    this.totalRecords = response.totalRecords;
                    this.prospectExcel["pageSize"] = this.totalRecords;
                    this.prospects = response.records;
                    this.loading = false;
                    if (e.searchTerm == null) {
                        const localeCompareKey = localStorage.getItem(this.sellside.uuid + 'prospectScroll');
                        if (localeCompareKey) {
                            this.ngZone.runOutsideAngular(() => {
                                setTimeout(() => {
                                    window.scrollTo(0, parseInt(localeCompareKey));
                                }, 0);
                            });
                        }
                    }
                });
            error => {
                this.loading = false;
            }
        }
    }

    afterUpload() {
        let e: LazyLoadEvent = { first: 0, rows: -1, sortField: this.sortField, sortOrder: this.sortOrder }
        this._search(e);
    }
    ngOnDestroy() {
        window.removeEventListener('scroll', this.saveScrollPosition);
        localStorage.setItem(this.sellside.uuid + 'prospectScroll', this.scrollTop);
    }

    saveScrollPosition = (): void => {
        this.scrollTop = window.scrollY.toString();
    }
    initEdit($event) {
        let oldValues = this.oldVal[$event.data.uuid];
        if (!oldValues) {
            oldValues = [];
            this.oldVal[$event.data.uuid] = oldValues;
        }
        oldValues[$event.field] = $event.data[$event.field];
    }

    editCompleted($event) {
        let oldValues = this.oldVal[$event.data.uuid];
        let dataToPost = { uuid: $event.data.uuid };
        if (this.isContactLinkedIn) {
            $event.field = "contacts"
            dataToPost[$event.field] = $event.data[$event.field];
        }
        else {
            dataToPost[$event.field] = $event.data[$event.field];
        }
        if ($event.field == 'status') {
            dataToPost[$event.field] = $event.data[$event.field] ? $event.data[$event.field].value : null;
        }
        if ($event.field == 'customLookupField1') {
            dataToPost[$event.field] = $event.data[$event.field] ? $event.data[$event.field].value : null;
        }
        if ($event.field == 'customLookupField2') {
            dataToPost[$event.field] = $event.data[$event.field] ? $event.data[$event.field].value : null;
        }
        if (oldValues[$event.field] != $event.data[$event.field]) {
            this.sellsideService.patchProspect([$event.field], dataToPost).subscribe(
                data => {
                    this.loading = false;
                    this.messageService.add({ severity: 'success', summary: 'Successful', detail: "Prospect updated successfully", life: 3000 });
                    this.reload();
                    this.showPortfolioDialog = false;
                },
                error => {
                    $event.data[$event.field] = oldValues[$event.field]
                    let errors = error.error;
                    if (errors.code && errors.code == 'VALIDATION_ERROR') {
                        this.messageService.add({ severity: 'error', summary: 'Error', detail: errors.subErrors[0].message, life: 3000 });
                    }
                }
            )
        }
        delete oldValues[$event.field];
    }

    downloadProspect() {
        this.sellsideService
            .downloadProspect(this.prospectExcel, this.sellside.uuid)
            .subscribe((blob) => saveAs(blob, "SellsideProspect.xlsx"));
    }


    editCancelled($event) {
        let oldValues = this.oldVal[$event.data.uuid];
        delete oldValues[$event.field];
    }

    editorAction(event, prospect: any, field: string, cancelled: boolean) {
        if (cancelled) {
            this.editCancelled({ field, data: prospect });
        }
        else {
            this.editCompleted({ field, data: prospect });
        }

        DomHandler.removeClass(this.dataTable.editingCell, 'p-cell-editing');
        this.dataTable.editingCell = null;
        this.dataTable.editingCellData = null;
        this.dataTable.editingCellField = null;
        this.dataTable.unbindDocumentEditListener();
        event.preventDefault();
        event.cancelBubble = true;
        event.stopPropagation();
        this.appUtil.reinitializeShowMore();

    }
    setCurrentItem(prospect, contact) {
        let items = [
            {
                label: 'Delete',
                icon: 'pi pi-trash',
                command: (event) => {
                    if (contact != null)
                        this.deleteProspectContact(contact.uuid, prospect);
                    else
                        this.delete(prospect);
                }
            }
        ];
        let show = false;
        if (this.campaigns != null && this.campaigns.length > 0 && (contact != null || prospect.organization == null)) {
            if (contact != null) {
                this.selectedProspectForRecipient = contact.uuid;
            } else {
                this.selectedProspectForRecipient = prospect.contacts[0].uuid;
            }
            this.campaignsForAssociation = []
            this.campaigns.forEach((campaign) => {
                let recipients = this.campaignRecipientsMap[campaign.title];
                if (recipients != undefined) {
                    show = true;
                    recipients.forEach(recipient => {
                        if (((contact != null && recipient.uuid === contact.uuid) ||
                            (prospect.organization == null && recipient.uuid === prospect.contacts[0].uuid))) {
                            show = false;
                        }
                    });
                    if (show)
                        this.campaignsForAssociation.push(campaign)
                } else {
                    this.campaignsForAssociation.push(campaign)
                }
            });
        }
        if (this.campaignsForAssociation.length > 0 && (contact != null || prospect.organization == null)) {
            items.push({
                label: 'Add as recipient',
                icon: 'pi pi-plus',
                command: (event) => {
                    this.showCampaignList = true;
                }
            })
        }
        this.currentMenuData = prospect;
        this.menuItems = items;
    }
    disableNavigation(event: any) {
        if (event.key === "ArrowRight" || event.key === "Right"
            || event.key === "ArrowLeft" || event.key === "Left"
            || event.key === "ArrowUp" || event.key === "Up"
            || event.key === "ArrowDown" || event.key === "Down") {
            event.stopPropagation();
        }

    }

    reload() {
        let e: LazyLoadEvent = { first: this.firstRow, rows: this.pageSize, sortField: this.sortField, sortOrder: this.sortOrder };
        this._search(e);
    }

    delete(prospect: any) {
        this.confirmationService.confirm({
            message: 'Are you sure you want to delete prospect?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.loadingSpinner = true;
                let { prospectUuids } = prospect.uuid;
                let dataToPost: any = { prospectUuids };
                dataToPost.prospectUuids = [prospect.uuid];
                this.sellsideService.deleteProspects(prospect.sellside.uuid, dataToPost).subscribe(
                    data => {
                        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Prospect Deleted', life: 3000 });
                        this.reload();
                        this.loadingSpinner = false;
                    },
                    error => {
                        let errors = error.error;
                        if (errors.code && errors.code == 'VALIDATION_ERROR') {
                            this.messageService.add({ severity: 'error', summary: 'Error', detail: errors.subErrors[0].message, life: 3000 });
                        }
                        this.loadingSpinner = false;
                    }
                )
            }
        });
    }
    newTask() {
        //this.tasksDialog = true;
    }

    deleteProspectContact(contactUuid: string, prospect: any) {
        this.confirmationService.confirm({
            message: 'Are you sure you want to delete contact?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.loadingSpinner = true;
                let { contactUuids } = prospect.uuid;
                let dataToPost: any = { contactUuids };
                dataToPost.contactUuids = [contactUuid];
                this.sellsideService.deleteProspects(prospect.sellside.uuid, dataToPost).subscribe(
                    data => {
                        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Contact Deleted', life: 3000 });
                        this.reload();
                        this.loadingSpinner = false;
                    },
                    error => {
                        let errors = error.error;
                        if (errors.code && errors.code == 'VALIDATION_ERROR') {
                            this.messageService.add({ severity: 'error', summary: 'Error', detail: errors.subErrors[0].message, life: 3000 });
                        }
                        this.loadingSpinner = false;
                    }
                )
            }
        });
    }

    // getContacts(prospect: any) {
    //     if (prospect.organization) {
    //         if (prospect.contacts != null && prospect.contacts.length > 0) {
    //             let contactIds = [];
    //             prospect.contacts.forEach(function (currentValue) {
    //                 contactIds.push(currentValue.uuid);
    //             })
    //             var searchParams = {};
    //             searchParams['page'] = 0;
    //             searchParams['pageSize'] = -1;
    //             var sortBy = []
    //             var sortDir = 'ASC';
    //             searchParams['sortDetails'] = sortBy;
    //             searchParams['rootRule'] = { 'condition': 'and', rules: [] };
    //             searchParams['rootRule'].rules.push({ "searchType": "exactsearch", "field": "uuid", "values": contactIds, "operator": "in" });
    //             this.userService.searchContact(searchParams).subscribe(
    //                 data => {
    //                     let response = data as any;
    //                  //   prospect.contacts = response.records;
    //                 }
    //             );
    //         }
    //     }
    // }

    addProspectAsRecipient() {
        let campaignuuids = []
        if (this.selectedCampaigns != null && this.selectedCampaigns.length > 0) {
            this.selectedCampaigns.forEach((campaign) => {
                campaignuuids.push(campaign.uuid)
            });
            this.sellsideService.addProspectAsRecipients(campaignuuids, this.selectedProspectForRecipient).subscribe(
                data => {
                    this.showCampaignList = false;
                    this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Prospect added as recipient successfully', life: 3000 });
                    window.location.reload()
                }
            )
        } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Select Email Campaign First', life: 3000 });
        }
        this.selectedCampaigns = [];
    }

    findCampaignsWithRecipients(uuid: string) {
        this.sellsideService.getCampaign(this.sellside.uuid, null).subscribe(
            data => {
                this.campaigns = data as any;
                var campaignRecipientsMap = new Map<string, []>();
                let campaignUuids = [];
                this.campaigns.forEach(campaign => {
                    campaignUuids.push(campaign.uuid);
                });
                this.emailCampaignService.findRecipientsForCampaigns(campaignUuids).subscribe(
                    data => {
                        campaignRecipientsMap = data as any;
                        this.campaignRecipientsMap = campaignRecipientsMap;
                    }
                )
            }
        )
    }

    _updateSelectedColumns($event) {
        this.selectedColumns = $event;
    }

    reorderProspect(event) {
        const source = this.prospects[event.draggedRowIndex];
        const target: any = this.prospects[event.droppedRowIndex];
        this.sellsideService.updateProspectSortOrder(source.uuid, target.sortOrder).subscribe(
            data => {
                event.sender.acceptDrop();
            },
            error => {
                event.sender.rejectDrop();
            }
        )

    }

    setCustomLabel(event) {
        this.customLabels = event;
    }

    getLookupType(columnProperty: string): string {
        if (columnProperty === 'status') {
            return 'PROSPECTS_STATUS';
        }
        if (columnProperty === 'customLookupField1') {
            return 'SELLSIDE_PROSPECT_LOOKUP_1';
        }
        if (columnProperty === 'customLookupField2') {
            return 'SELLSIDE_PROSPECT_LOOKUP_2';
        }
    }

    getLookupItems(columnProperty: string): any[] {
        if (columnProperty === 'status') {
            return this.lookupList.PROSPECTS_STATUS;
        }
        if (columnProperty === 'customLookupField1') {
            return this.lookupList.SELLSIDE_PROSPECT_LOOKUP_1.filter(item => item.entityId == this.sellside.documentId && item.entityType == "SELLSIDE");
        }
        if (columnProperty === 'customLookupField2') {
            return this.lookupList.SELLSIDE_PROSPECT_LOOKUP_2.filter(item => item.entityId == this.sellside.documentId && item.entityType == "SELLSIDE");
        }
    }

    getOutputValue(columnProperty, prospect) {
        const columnValue = prospect[columnProperty];
        if (columnValue && typeof columnValue === 'object') {
            return columnValue.value;
        }
        return columnValue;
    }
    addTask(prospect: any, contact: any) {
        this.prospectContacts = contact;
        this.selectedProspect = prospect;
        this.tasksDialog = true;
    }
    addNote(prospect: any, contact: any) {
        this.prospectContacts = contact;
        this.selectedProspect = prospect;
        this.noteDialog = true;
    }
    editTask(task: any) {
        this.taskUuid = task.uuid;
        this.tasksDialog = true;
    }
    editNotes(note: any) {
        this.noteUuid = note.uuid;
        this.noteDialog = true;
    }
    togglePanelAndGetValue(event, prospect: any) {
        let orgUuid = prospect.organization.uuid
        this.prospectValue = prospect;
        this.op.toggle(event);
        this.organizationService.getRelatedContactForOrganization(orgUuid).subscribe(
            relatedContacts => {
                this.relatedContact = relatedContacts as any;
                var searchParams = {};
                searchParams['page'] = 0;
                searchParams['pageSize'] = 25;
                var sortBy = []
                sortBy[sortBy.length] = { "property": "primaryContact", "direction": "Desc" };
                searchParams['sortDetails'] = sortBy;
                this.organizationService.getContacts(orgUuid, searchParams).subscribe(data => {
                    let response = data as any;
                    this.associatedContacts = response.records;
                    this.allContacts = [...this.relatedContact, ...this.associatedContacts];
                    this.filteredContacts = this.allContacts;
                });
            }
        );
    }

    addToSource() {

        if (this.selectedValues.length > 0) {
            this.loading = true;
            this.sellsideService.addAssociatedContactsAsProspects(this.sellside.uuid, this.selectedValues, this.prospectValue.uuid).subscribe(
                data => {
                    this.messageService.add({ severity: 'success', summary: 'Successful', detail: this.appUtil.prepareMessageForProspects(data as any), life: 3000 });
                    this.reload();
                    this.op.hide();
                    this.selectedUserUUIDs = []
                    this.searchTermForAssocited = "";
                },
                error => {
                    this.reload();
                });
        }
        else {
            //  this.reload();
            this.messageService.add({ severity: 'error', summary: 'Error', detail: "Please select any contact first!", life: 3000 });
        }
    }
    searchContacts() {

        if (!this.searchTermForAssocited) {
            this.filteredContacts = this.allContacts;
        } else {
            const searchTerm = this.searchTermForAssocited.toLowerCase();
            this.filteredContacts = this.allContacts.filter(contact =>
                contact.name.toLowerCase().includes(searchTerm)
            );
        }
    }
    resetSearchTerm() {
        this.op.hide();
        this.selectedValues = []
        this.searchTermForAssocited = "";
        this.filteredContacts = this.allContacts;
    }

    getAllOrganizations() {
        var searchParams = {};
        searchParams['page'] = 0;
        searchParams['pageSize'] = -1;
        var sortBy = []
        sortBy[sortBy.length] = { "property": "lastModifiedDate", "direction": "Desc" };
        searchParams['sortDetails'] = sortBy;
        this.organizationService.searchOrganization(searchParams).subscribe(
            data => {
                let response = data as any;
                this.organizations = response.records;
            },
            error => {
                this.organizations = [];
            }
        )
    }
    portfolioDialog(prospect: any) {
        this.prospectValue = prospect;
        this.prospectLenght = prospect.portfolioCompanies ? prospect.portfolioCompanies.length : 0;
        this.showPortfolioDialog = true;
    }
    hidePortfolioDialog() {
        this.showPortfolioDialog = false;
    }
    addPortfolioToProspect() {
        var field = 'portfolioCompanies'
        if (this.prospectValue.portfolioCompanies) {
            if (this.prospectLenght != this.prospectValue.portfolioCompanies.length) {
                this.editCompleted({ field, data: this.prospectValue })
            } else {
                this.hidePortfolioDialog();
            }
        } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: "Please select any portfolio first!", life: 3000 });
        }
    }
    initEdit1($event) {
        let oldValues = this.oldVal[$event.data.uuid];
        if (!oldValues) {
            oldValues = [];
            this.oldVal[$event.data.uuid] = oldValues;
        }
        oldValues[$event.field] = $event.data[$event.field];
    }
    editCompleted1($event) {
        let oldValues = this.oldVal[$event.data.uuid];
        let dataToPost = { uuid: $event.data.uuid };

        if ($event.field === 'name' && !$event.data.name.includes(' ')) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Both first and last name are required for the contact.', life: 3000 });
            this.reload();
            return false;
        }
        if ($event.field == 'email' && this.isInvalidEmail($event.data[$event.field])) {
            this.messageService.add({ severity: 'error', summary: 'Invalid Email', detail: 'Please enter a valid email address', life: 3000 });
            this.reload();
            return false;

        }
        dataToPost[$event.field] = $event.data[$event.field];
        if (oldValues[$event.field] != $event.data[$event.field]) {
           this.userService.updateContactList([$event.field], dataToPost).subscribe(
               data => {
                   this.loading = false;
                   this.messageService.add({ severity: 'success', summary: 'Successful', detail: "Contact updated successfully", life: 3000 });
               },
               error => {
                   $event.data[$event.field] = oldValues[$event.field]
                   let errors = error.error;
                   if (errors.code && errors.code == 'VALIDATION_ERROR') {
                       this.messageService.add({ severity: 'error', summary: 'Error', detail: errors.subErrors[0].message, life: 3000 });
                   }
               }
           )
        }

        delete oldValues[$event.field];
    }
    toggleEditingMode(contactUUID: string) {
        this.editingModes[contactUUID] = !this.editingModes[contactUUID];
    }

    isEditing(contactUUID: string): boolean {
        return this.editingModes[contactUUID] || false;
    }
    isInvalidEmail(email: string): boolean {
        if (email === '' || email === undefined) {
            return false;
        }
        return !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }
    showDialog() {
        this.dialogVisible = true;
     }
}