import { filter } from 'rxjs/operators';
import { AppUtil } from './../../_helpers/app.util';
import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AutoComplete } from 'primeng/autocomplete';
import { UsersService } from '../../users/users.service';
import { OrganizationService } from '../../organization/organization.service';
import { EmailCampaignService } from 'src/app/email-campaign/email-campaign.service';
export const TBP_AUTOCOMPLETE_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TbpAutocompleteComponent),
    multi: true
};

@Component({
    selector: 'tbp-autocomplete',
    templateUrl: './tbp-autocomplete.component.html',
    styleUrls: ['./tbp-autocomplete.component.scss'],
    providers: [TBP_AUTOCOMPLETE_VALUE_ACCESSOR],
    host: {
        '[class.p-inputwrapper-filled]': 'filled',
        '[class.p-inputwrapper-focus]': '(focused && !disabled) || overlayVisible'
    },
})
export class TbpAutocompleteComponent implements OnInit {

    @Output("onChange") onChange: EventEmitter<any> = new EventEmitter();
    @Output("onSelect") onSelect: EventEmitter<any> = new EventEmitter();
    @Output("onUnselect") onUnselect: EventEmitter<any> = new EventEmitter();
    @Output("onClear") onClear: EventEmitter<any> = new EventEmitter();
    @Output("afterNewCreated") afterNewCreated: EventEmitter<any> = new EventEmitter();
    @Input() multiple: boolean = true;
    @Input() placeholder: string;
    @Input() completeOnFocus: boolean = true;
    @Input() field: string = "name";
    @Input() dataKey: string = "uuid";
    @Input() items: any[];
    @Input() exclude: any;
    @Input() forceSelection: boolean = true;
    @Input() disabled: boolean = false;
    @Input() type: string; // one of LEVEL1, LEVEL2, LEVEL3, VERTICAL, ECOSYSTEM, ACCOUNT, SPOTLIGHT, AN OTHER LOOKUP VALUES Like COUNTRY, STATE, LEAD_SOURCE, REVENUE, NUM_OF_EMPLOYEES
    //lookup and create services will  be decided based on type and also the pop-up that needs to be shown.
    @Input() cntUuid: string;
    @Input() cntName: string;
    @Input() cntType: string;
    @Input() required:boolean;
    @Input() additionalData: any;
    @Input() showSearch: boolean = false;
    @Input() showClear: boolean = true;
    @Input() additionalParam: string;
    @ViewChild('ac') autocomplete: AutoComplete;
    autoCompleteResults: any[];
    autocompleteValue: any;
    filled: boolean = false;
    focused: boolean = false;
    overlayVisible: boolean = false;
    private onTouched!: Function;
    private onChanged!: Function;
    classificationDialog: boolean = false;
    lookupDialog: boolean = false;
    accountForm: boolean = false;
    inputTextvalue: string;
    contactForm: boolean = false;
    stateDialog: boolean = false;
    constructor(private organizationService: OrganizationService, private usersService: UsersService, private campaignService: EmailCampaignService, public appUtil:AppUtil) {
        this.onChanged = () => { };
        this.onTouched = () => { };
    }

    ngOnInit(): void {
        this.checkFilled();
    }


    private _showOption(result, exactFound, term) {
        if (this.items == undefined) {
            this.items = [];
        }
        if (term && term != '') {
            result = this.items.filter((currentValue, index) => {
                if (currentValue[this.field].toLowerCase() == term.toLowerCase()) {
                    exactFound = true;
                }
                return currentValue[this.field].toLowerCase().indexOf(term.toLowerCase()) != -1;
            });
            if (!exactFound && this.type != 'Email Campaign') {
                var toadd = { uuid: 'tmp-' + new Date().getTime(), tempName: term };
                toadd[this.field] = '';
                result.unshift(toadd);
            }
        }
        else {
            result = [...this.items];
        }
        return result;
    }

    searchItem($event) {
        let result = [];
        let exactFound = false;
        let term = $event.query;
        if (this.type == "CATEGORY_LEVEL1" || this.type == "CATEGORY_LEVEL2" || this.type == "CATEGORY_LEVEL3" || this.type == "VERTICAL" || this.type == "ECOSYSTEM") {
            this.autoCompleteResults = this._showOption(result, exactFound, term) as any;
        }
        else if (this.type == "COUNTRY") {
            this.autoCompleteResults = this._showOption(result, exactFound, term) as any;
        }
        else if (this.type == "Account") {
            this.organizationService.lookupOrganization(term).subscribe(data => {
                let results = data as any;
                this.excludeSource(results)
                this.autoCompleteResults = this._showOption(result, exactFound, term) as any;
            });
        }
        else if (this.type == "Contact") {
            this.usersService.lookupContact(term,this.additionalParam).subscribe(data => {
                this.items = data as any;
                this.autoCompleteResults = this._showOption(result, exactFound, term) as any;
            });
        }
        else if (this.type == "Email Campaign") {
            this.campaignService.lookupCampaign(term).subscribe(data => {
                let results = data as any;
                this.excludeSource(results);
                this.autoCompleteResults = this._showOption(result, exactFound, term) as any;
            });
        }
        else if (this.cntType == "STATE") {
            this.autoCompleteResults = this._showOption(result, exactFound, term) as any;
        }
    }

    excludeSource(results: any) {

        if (this.exclude != null && this.exclude != undefined) {
            let toBeExcluded = [];
            if(!Array.isArray(this.exclude))
            {
                toBeExcluded.push(this.exclude)
            }
            else
            {
                toBeExcluded = this.exclude;
            }
            if(toBeExcluded.length != 0)
            {
                this.items = results.filter((item) => {return (this.appUtil.getObject(toBeExcluded,item) == null)});
            }
            else
            {
                this.items = results;
            }
        }
        else
        {
            this.items = results;
        }
    }

    focus() {
        this.focused = true;
    }

    blur() {
        this.focused = false;
        this.onTouched();
    }

    show() {
        this.overlayVisible = true;
    }

    hide() {
        this.overlayVisible = false;
    }

    select($event) {
        if ($event.uuid.includes('tmp-')) {
            if (this.multiple == true) {
                this.autocompleteValue = this.autocompleteValue.filter((currentValue, index) => {
                    return (currentValue.uuid != $event.uuid);
                });
            }
            if (this.multiple == false) {
                this.autocompleteValue = null;
            }
            if (this.type == "CATEGORY_LEVEL1" || this.type == "CATEGORY_LEVEL2" || this.type == "CATEGORY_LEVEL3" || this.type == "VERTICAL" || this.type == "ECOSYSTEM") {
                this.classificationDialog = true;
            }
            else if (this.type == "Account") {
                this.accountForm = true;
            }
            else if (this.type == "Contact") {
                this.contactForm = true;
            }
            else if (this.type == "COUNTRY") {
                this.lookupDialog = true;
            }
            else if (this.cntType == "STATE") {
                this.stateDialog = true;
            }
            else if (this.type == "Email Campaign") {
                // Provide Add New For Campaign
            }
            this.inputTextvalue = $event['tempName'];
        }
        this.onSelect.emit(this.autocompleteValue);
        this.checkFilled();
    }

    clear($eevnt) {
        this.autocompleteValue = null;
        this.onClear.emit(this.autocompleteValue);
        this.checkFilled();
    }
    
    unselect($eevnt) {
        this.autocompleteValue = this.autocompleteValue.filter((current) => $eevnt[this.dataKey] != current[this.dataKey]);
        if(this.autocompleteValue.lenght == 0)
            this.autocompleteValue = null;

        this.onUnselect.emit(this.autocompleteValue);
        this.checkFilled();
    }

    checkFilled() {
        if(this.autocompleteValue === undefined)
            this.autocompleteValue = null;
        if(this.autocompleteValue!= null && (typeof this.autocompleteValue === 'string') && this.autocompleteValue.trim() == '')
        {
            this.autocompleteValue = null;
        }
        this.onChanged(this.autocompleteValue);
        if (this.autocompleteValue && this.autocompleteValue != null
            && (    (Array.isArray(this.autocompleteValue) && this.autocompleteValue.length > 0)
                || (!Array.isArray(this.autocompleteValue) && 
                (   ((typeof this.autocompleteValue === 'string') && this.autocompleteValue.length > 0))
                    || (typeof this.autocompleteValue === 'object')
                ))) {
            this.filled = true;
        }
        else {
            this.filled = false;
        }
        this.onChange.emit(this.autocompleteValue);
    }

    writeValue(value) {
        this.autocompleteValue = value;
        this.checkFilled();

    }

    registerOnChange(fn) {
        this.onChanged = fn;
    }
    registerOnTouched(fn) {
        this.onTouched = fn;
    }
    setDisabledState(val) {
        this.disabled = val;
    }

    onFormGroupChangeEvent($event) {
        if (this.multiple == true)
            this.autocompleteValue = [...this.autocompleteValue, $event];
        else
            this.autocompleteValue = $event;

        if(this.items && (this.type == 'COUNTRY' || this.type == 'STATE'))
        {
            this.items.push($event);
            this.appUtil.sortByStringProperty(this.items, this.field);

        }
        this.checkFilled();
        this.onChanged(this.autocompleteValue);
        this.onChange.emit();
        this.afterNewCreated.emit($event);
    }

    openSearch(event)
    {
        //console.log("Search Clicked");
    }

    /* below will needed if a pop-up needs to be opened on addNew


  addItem($event)
  {
//console.log($event)
  }
  */
}
