import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppMainComponent } from './app.main.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppHelpComponent } from './pages/app.help.component';

import { DashboardComponent } from './pages/dashboard.component';
import { OrganizationModule } from './organization/organization.module';
import { ClassificationModule } from './classification/classification.module';
import { UsersModule } from "./users/users.module";
import { AccountModule } from './account/account.module';
import { ConfigurationModule } from "../app/configuration/configuration.module";
import { AuthGuard, RedirectGuard } from './_helpers';
import { SpotlightModule } from "./spotlight/spotlight.module";
import { DealModule } from "./deal/deal.module";
import { EmailCampaignModule } from "./email-campaign/email-campaign.module";
import { BuysideModule } from './buyside/buyside.module';
import { SellsideModule } from './sellside/sellside.module';
import { EngagementModule } from './engagement/engagement.module';
import { HelpModule } from './help/help.module';
import { MediaModule } from './media/media.module';
import { GlobalSerachComponenet } from './pages/global-search.component';
import { UsersActivityModule } from './users-activity/users-activity.module';

export function loadAccountModule() { return AccountModule; }
export function loadOrganizationModule() { return OrganizationModule; }
export function loadClassificationModule() { return ClassificationModule; }
export function loadUsersModule() { return UsersModule; }
export function loadConfigurationModule() { return ConfigurationModule; }
export function loadSpotlightModule() { return SpotlightModule; }
export function loadDealModule() { return DealModule; }
export function loadEmailCampaignModule() { return EmailCampaignModule; }
export function loadBuysideModule() { return BuysideModule; }
export function loadSellsideModule() { return SellsideModule; }
export function loadEngagementModule() { return EngagementModule; }
export function loadHelpModule() { return HelpModule; }
export function loadMediaModule() { return MediaModule; }
export function loadUsersActivityModule() { return UsersActivityModule; }

const routes: Routes = [
    {
        path: '', component: AppMainComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', component: DashboardComponent },
            { path: 'global-search', component: GlobalSerachComponenet },
            { path: 'pages/help', component: AppHelpComponent },
            { path: '', loadChildren: () => import('./organization/organization.module').then(m => m.OrganizationModule) },
            { path: 'classification', loadChildren: () => import('./classification/classification.module').then(m => m.ClassificationModule) },
            { path: '', loadChildren: () => import('./users/users.module').then(m => m.UsersModule) },
            { path: 'configuration', loadChildren: () => import('./configuration/configuration.module').then(m => m.ConfigurationModule) },
            { path: '', loadChildren: () => import('./spotlight/spotlight.module').then(m => m.SpotlightModule) },
            { path: '', loadChildren: () => import('./deal/deal.module').then(m => m.DealModule) },
            { path: '', loadChildren: () => import('./buyside/buyside.module').then(m => m.BuysideModule) },
            { path: '', loadChildren: () => import('./sellside/sellside.module').then(m => m.SellsideModule) },
            { path: 'engagement', loadChildren: () => import('./engagement/engagement.module').then(m => m.EngagementModule) },
            { path: '', loadChildren: () => import('./email-campaign/email-campaign.module').then(m => m.EmailCampaignModule) },
            { path: 'help', loadChildren: () => import('./help/help.module').then(m => m.HelpModule) },
            { path: 'media', loadChildren: () => import('./media/media.module').then(m => m.MediaModule) },
            { path: 'user-activity', loadChildren: () => import('./users-activity/users-activity.module').then(m => m.UsersActivityModule) },
        ]
    },
    {
        path: 'account',
        canActivate: [RedirectGuard],
        loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
    },


    { path: 'error', component: AppErrorComponent },
    { path: 'access', component: AppAccessdeniedComponent },
    { path: 'notfound', component: AppNotfoundComponent },
    // otherwise redirect to notfound
    { path: '**', redirectTo: '/notfound' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', useHash: false })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
