import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ClassificationService } from './classification.service';
import { MessageService } from 'primeng/api';
@Component({
    selector: 'add-classification',
    templateUrl: './classification.add.component.html',
    styleUrls: ['./classification.add.component.scss'],
    providers: [MessageService]
})
export class ClassificationAddComponent implements OnInit {
    @Output("parentFuntion") parentFuntion: EventEmitter<any> = new EventEmitter();
    @ViewChild('clsForm') formElement;
    @Input() show: boolean = false;
    @Input() type: string;
    @Input() inputTextvalue: string;
    dialogTitles: any = [{ key: 'CATEGORY_LEVEL1', value: 'New Level1 Category' }, { key: 'CATEGORY_LEVEL2', value: 'New Level2 Category' },
    { key: 'CATEGORY_LEVEL3', value: 'New Level3 Category' },
    { key: 'VERTICAL', value: 'New Vertical' },
    { key: 'ECOSYSTEM', value: 'New Ecosystem' }];
    formclass: UntypedFormGroup;
    clsSpinner: boolean = false;
    clsSubmitted: boolean;
    @Output() showChange = new EventEmitter<boolean>();
    classificationTypes: string[];
    formInitialValue: any = null;
    @Input() uuid: string;
    constructor(private messageService: MessageService, private formBuilder: UntypedFormBuilder, private classificationService: ClassificationService) {
        this.formclass = this.formBuilder.group({
            name: ['', Validators.required],
            description: [''],
            type: [''],
            uuid: ['']
        });
        this.formInitialValue = this.formclass.value;
    }

    ngOnInit(): void {

    }

    ngOnChanges(changes: SimpleChanges) {
        const result = this.dialogTitles.find(({ key }) => key === this.type);
        if (result != undefined)
            this.type = result['value'];
        this.formclass.reset(this.formInitialValue);
        this.fc.name.setValue(this.inputTextvalue);
        if (this.uuid != undefined && this.uuid != null && this.uuid != "") {
            this.classificationService.getClassification(this.uuid).subscribe(data => {
                let objCls = data as any;
                this.formclass.setValue({ name: objCls.name, description: objCls.description, type: objCls.type, uuid: objCls.uuid });
            });
        }
    }

    get fc() { return this.formclass.controls; }

    hideDialogCls() {
        this.show = false;
        this.clsSubmitted = false;
        this.clsSpinner = false;
        this.formclass.reset(this.formInitialValue);
        this.showChange.emit();
    }

    saveClassification() {
        this.clsSubmitted = true;
        if (this.formclass.invalid) {
            return;
        }
        let apiCall: any;
        this.clsSpinner = true;
        let { uuid, name, description, type } = this.formclass.value;
        let dataToPost: any = { uuid, name, description, type };
        const result = this.dialogTitles.find(({ value }) => value === this.type);
        dataToPost['type'] = result['key'];
        if (this.formclass.controls['uuid'].value == null || this.formclass.controls['uuid'].value == '') {
            apiCall = this.classificationService.createClassification(dataToPost);
        }
        else {
            apiCall = this.classificationService.patchClassification(dataToPost);
        }
        apiCall.pipe(first())
            .subscribe(
                data => {
                    let details = 'Classification Created';
                    if (this.uuid != null && this.uuid != '')
                        details = 'Classification Updated';
                    this.messageService.add({ severity: 'success', summary: 'Successful', detail: details, life: 3000 });
                    this.parentFuntion.emit(data);
                    this.hideDialogCls();          
                },
                error => {
                    this.clsSpinner = false;
                    let errors = error.error;
                    if (errors.code && errors.code == 'VALIDATION_ERROR') {
                        for (var i = 0; i < errors.subErrors.length; i++) {
                            this.fc[errors.subErrors[i].field].setErrors({ 'message': errors.subErrors[i].message });
                        }
                    }
                }
            );
    }



}
