<div class="grid table-demo">
    <div class="col-12">

        <div class="card divider-card">
            <p-toolbar styleClass="m-0 headerSticky" *ngIf="role == 1">
                <ng-template pTemplate="left">
                </ng-template>

                <ng-template pTemplate="right">
                    <button pButton pRipple label="Back" icon="pi pi-arrow-left" class="p-button-success mr-2" (click)="appUtil.return()"></button>
                    <button pButton pRipple label="Edit" icon="pi pi-pencil" class="p-button-success mr-2"
                        (click)="appUtil.routeToEditURL('organization', organization.uuid)"></button>
                    <button pButton pRipple label="Merge" icon="pi pi-link" class="p-button-info  mr-2"
                        (click)="appUtil.routeToMergeURL('organization', organization.uuid)"></button>
                     <!-- <button pButton pRipple [label]="Delete" icon="pi pi-trash" class="p-button-danger  mr-2" (click)="deleteSelectedProducts()" [disabled]="!selectedProducts || !selectedProducts.length"></button> -->
                </ng-template>
            </p-toolbar>
            <div class="p-fluid formgrid grid">
                <div class="field col-12">
                    <div class="grid mt-1">
                        <div class="col-12 md:col-6">
                            <div class="inline label">Created By:</div>
                            {{organization.createByUser}} on {{organization.createdDate | date:"MM-dd-yyyy HH:mm:ss"}}
                        </div>
                        <div class="col-12 md:col-6 text-right">
                            <div class="inline label">Modified By:</div>
                            {{organization.modifiedByUser}} on {{organization.lastModifiedDate | date:"MM-dd-yyyy HH:mm:ss"}}
                        </div>
                    </div>
                    <p-divider type="dashed"></p-divider>
                </div>
            </div>
            <h5>Account Information</h5>
            <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-4" (click)="editField('name')">
                    <div class="grid mt-1 limit-width-large dashed-bottom">
                        <div class="col-12 md:col-4 label md:text-right">Name</div>
                        <div *ngIf="!editingField.name" class="field col-12 md:col-8 capitalize">
                            {{organization.name}}
                            <i class="pi pi-google link" (click)="appUtil.externalLink(organization.name,'google')"
                                style="float:right"></i>
                        </div>
                        <div *ngIf="editingField.name" class="field col-12 md:col-8 capitalize">
                            <div class="input-container">
                                <input pInputText type="text" [(ngModel)]="organization.name">
                                <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                                  (click)="saveField('name', organization.name)"></button>
                                <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                                  (click)="closeDescriptionDialog('name')"></button>
                              </div>
                        </div>
                    </div>
                </div>
                <div class="field col-12 md:col-4">
                    <div class="grid mt-1 limit-width-large dashed-bottom">
                        <div class="col-12 md:col-4 label md:text-right">Website</div>
                        <div *ngIf="!editingField.website" class="field col-12 md:col-8 contact-info">
                            <a href="javascript: void(0);"
                                (click)="appUtil.externalLink(organization.website, 'url')">{{organization.website}}</a>&nbsp;
                                <button pButton type="button" icon="pi pi-pencil" title="Edit" class="p-button-text edit-button"
                                (click)="editField('website')"></button>
                        </div>
                        <div *ngIf="editingField.website" class="field col-12 md:col-8 ">
                            <div class="input-container">
                                <input pInputText type="text" [(ngModel)]="organization.website">
                                <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                                  (click)="saveField('website', organization.website)"></button>
                                <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                                  (click)="closeDescriptionDialog('website')"></button>
                              </div>
                            <div *ngIf="!isValidUrl(organization.website) && organization.website" style="color: red;"   class="invalid-feedback">Invalid website URL(http://www.example.com)
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field col-12 md:col-4">
                    <div class="grid mt-1 limit-width-large dashed-bottom">
                        <div class="col-12 md:col-4 label md:text-right">Account Owner</div>
                        <div *ngIf="!editingField.accountOwner" class="field col-12 md:col-8 contact-info">
                            <a *ngIf="organization.accountOwner != null"
                                [routerLink]="['/user/', organization.accountOwner.uuid]">{{organization.accountOwner?organization.accountOwner.name:''}}</a>&nbsp;
                                <button pButton type="button" icon="pi pi-pencil" title="Edit" class="p-button-text edit-button"
                                (click)="editField('accountOwner')"></button>
                        </div>
                        <div *ngIf="editingField.accountOwner" class="field col-12 md:col-8 contact-info">
                            <div class="input-container">
                            <p-autoComplete appendTo="body" [unique]="true" [forceSelection]="true"
                                [suggestions]="filteredValues" [(ngModel)]="organization.accountOwner"
                                (completeMethod)="searchLookupValue($event ,'accountOwner')" field="name"> </p-autoComplete>
                                <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                                (click)="saveField('accountOwner', organization.accountOwner)"></button>
                                <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                                (click)="closeDescriptionDialog('accountOwner')"></button>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container *ngFor="let category of lookupCategoies">
                    <div class="field col-12 md:col-4" (click)="editField(category.fieldName)">
                        <div class="grid mt-1 limit-width-large dashed-bottom">
                            <div class="col-12 md:col-4 label md:text-right">
                                <div>{{ category.label }}</div>
                            </div>
                            <div *ngIf="!editingField[category.fieldName]" class="field col-12 md:col-8">
                                {{ getLookupOutput(organization[category.fieldName]) }}
                            </div>
                            <div *ngIf="editingField[category.fieldName]" class="field col-12 md:col-8">
                                <div class="input-container">
                                  <p-dropdown [options]="getOptions(category.fieldName)" optionLabel="value"
                                    [(ngModel)]="organization[category.fieldName]" dataKey="value"></p-dropdown>
                                    <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon" (click)="saveField(category.fieldName,organization[category.fieldName])"></button>
                                    <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon" (click)="closeDescriptionDialog(category.fieldName)"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngFor="let category of textInputCategories">
                    <div class="field col-12 md:col-4">
                        <div class="grid mt-1 limit-width-large dashed-bottom">
                            <div class="col-12 md:col-4 label md:text-right">
                                <div>{{ category.label }}</div>
                            </div>
                            <div *ngIf="!editingField[category.fieldName]" class="field col-12 md:col-8 contact-info">
                                <a href="javascript: void(0);"
                                    *ngIf="organization[category.fieldName] != null && organization[category.fieldName] != ''"
                                    (click)="appUtil.externalLink(organization[category.fieldName], category.url)">
                                    {{ organization[category.fieldName] }}
                                </a>
                                <button pButton type="button" icon="pi pi-pencil" title="Edit" class="p-button-text edit-button"
                                (click)="editField(category.fieldName)"></button>
                                <i class="pi pi-google link" style="float:right"
                                    (click)="appUtil.externalLink(organization.name, 'google', '{category.url}.com:')"
                                    *ngIf="organization[category.fieldName] == null || organization[category.fieldName] == ''">
                                </i>

                            </div>
                            <div *ngIf="editingField[category.fieldName]" class="field col-12 md:col-8">
                                    <div class="input-container">
                                        <input pInputText type="text"  [(ngModel)]="organization[category.fieldName]">
                                        <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                                          (click)="saveField(category.fieldName, organization[category.fieldName])"></button>
                                        <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                                          (click)="closeDescriptionDialog(category.fieldName)"></button>
                                      </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

            </div>
            <h5>Description</h5>
            <div class="p-fluid formgrid grid" (click)="editField('description')">
                <div *ngIf="!editingField.description" class="field col-12 md:col-8">
                    <div *ngIf="organization.description != ''" class="mt-1 mt-1"
                        innerHTML="{{ organization.description }}">
                    </div>
                </div>
                <div class="col-12" *ngIf="organization.description == null || organization.description == ''">
                    <div class="mt-1 mt-1 dashed-bottom">Not Applicable</div>
                </div>
                <div *ngIf="editingField.description" class="field col-12 md:col-8">
                    <p-dialog [visible]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
                        [style]="{width: '50vw'}" [closable]="false" [modal]="true">
                        <ng-template pTemplate="header">
                            <div class="p-fluid formgrid grid">
                                <div class="field col-12">
                                    <strong>{{ organization.name }}:</strong>
                                    {{ "Description" }}
                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <editor [(ngModel)]="organization.description" [init]="appUtil.getInitData(180,null)">
                            </editor>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
                                (click)="saveField('description', organization.description)"></button>
                            <button pButton pRipple label="Close" class="p-button-text"
                                (click)="closeDescriptionDialog('description' ,$event)"></button>
                        </ng-template>
                    </p-dialog>
                </div>
            </div>
            <h5>Account Type</h5>
            <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-4" (click)="editField('companyType')">
                    <div class="grid mt-1 limit-width-large dashed-bottom">
                        <div class="col-12 md:col-4 label md:text-right">
                            <div>Entity Type</div>
                        </div>
                        <div *ngIf="!editingField.companyType" class="field col-12 md:col-8"> 
                            {{getCompanyType(organization.companyType)}}
                        </div>
                        <div *ngIf="editingField.companyType" class="field col-12 md:col-8">
                            <div class="input-container">
                            <p-dropdown [options]="searchOptions" optionLabel="name" [filter]="false" [(ngModel)]="organization.companyType" dataKey="uuid"
                                [showClear]="false">
                            </p-dropdown>
                            <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                            (click)="saveField('companyType', organization.companyType)"></button>
                            <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                            (click)="closeDescriptionDialog('companyType')"></button>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="field col-12 md:col-4" *ngIf="organization.companyType !='OPERATING_COMPANY'"
                    (click)="editField('subTypes')">
                    <div class="grid mt-1 limit-width-large dashed-bottom">
                        <div class="col-12 md:col-4 label md:text-right">
                            <div>Company Type</div>
                        </div>
                        <div class="field col-12 md:col-8" *ngIf="!editingField.subTypes">
                            <ng-container *ngIf="organization.subTypes && organization.subTypes.length > 0">
                                <ng-container *ngFor="let type of organization.subTypes; let last = last;">
                                    <span>
                                        {{type.value}}{{last?'':'; '}}
                                    </span>&nbsp;
                                </ng-container>
                            </ng-container>
                        </div>
                        <div *ngIf="editingField.subTypes && organization.companyType == 'INVESTOR'"
                            class="field col-12 md:col-8">
                            <div class="input-container">
                            <p-multiSelect [options]="lookupList.ORG_TYPE_INVESTOR" optionLabel="value" [filter]="true"
                                placeholder="Select" [(ngModel)]="organization.subTypes" dataKey="uuid">
                            </p-multiSelect>
                            <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                            (click)="saveField('subTypes', organization.subTypes)"></button>
                            <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                            (click)="closeDescriptionDialog('subTypes')"></button>
                        </div>
                        </div>
                        <div *ngIf="editingField.subTypes && organization.companyType == 'LENDER'"
                            class="field col-12 md:col-8">
                            <div class="input-container">
                            <p-multiSelect [options]="lookupList.ORG_TYPE_LENDER" optionLabel="value" [filter]="true"
                                placeholder="Select" [(ngModel)]="organization.subTypes" dataKey="uuid">
                            </p-multiSelect>
                            <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                            (click)="saveField('subTypes', organization.subTypes)"></button>
                            <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                            (click)="closeDescriptionDialog('subTypes')"></button>
                            </div>
                        </div>
                        <div *ngIf="editingField.subTypes && organization.companyType == 'OTHER'"
                            class="field col-12 md:col-8">
                            <div class="input-container">
                            <p-multiSelect [options]="lookupList.ORG_TYPE_OTHER" optionLabel="value" [filter]="true"
                                placeholder="Select" [(ngModel)]="organization.subTypes" dataKey="uuid">
                            </p-multiSelect>
                            <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                            (click)="saveField('subTypes', organization.subTypes)"></button>
                            <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                            (click)="closeDescriptionDialog('subTypes')"></button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field col-12 md:col-4" *ngIf="organization.companyType =='OPERATING_COMPANY'"
                    (click)="editField('customSubtypeValue')">
                    <div class="grid mt-1 limit-width-large dashed-bottom">
                        <div class="col-12 md:col-4 label md:text-right">
                            <div>Company Type</div>
                        </div>
                        <div *ngIf="!editingField.customSubtypeValue && organization.customSubtypeValue!=null"
                            class="field col-12 md:col-8">
                            {{organization.customSubtypeValue=='true'?'Large Company':'Prospect'}}
                        </div>
                        <div *ngIf="editingField.customSubtypeValue" class="field col-12 md:col-8">
                            <p-radioButton styleClass="form-control p-mb-2" value="true" label="Large Company"
                                labelStyleClass="pr-2" [(ngModel)]="organization.customSubtypeValue"
                                (onClick)="saveField('customSubtypeValue', organization.customSubtypeValue)"></p-radioButton>
                            <p-radioButton styleClass="form-control p-mb-2" value="false" label="Prospect"
                                labelStyleClass="pr-2" [(ngModel)]="organization.customSubtypeValue"
                                (onClick)="saveField('customSubtypeValue', organization.customSubtypeValue)"></p-radioButton>
                        </div>
                    </div>
                </div>
                <div class="field col-12 md:col-4" (click)="editField('isPubliclyTraded')">
                    <div class="grid mt-1 limit-width-large dashed-bottom">
                        <div class="col-12 md:col-4 label md:text-right">
                            <div>Publicly Traded?</div>
                        </div>
                        <div *ngIf="!editingField.isPubliclyTraded" class="field col-12 md:col-8">
                            {{organization.companyType=='OPERATING_COMPANY'?(organization.isPubliclyTraded?'Yes':'No'):'N.A.'}}
                        </div>
                        <div *ngIf="editingField.isPubliclyTraded" class="field col-12 md:col-8">
                            <p-radioButton name="isPubliclyTraded" [value]="true" (keydown)="disableNavigation($event)"
                                [(ngModel)]="organization.isPubliclyTraded" label="Y"
                                (onClick)="saveField('isPubliclyTraded', organization.isPubliclyTraded)"></p-radioButton>
                            <p-radioButton name="isPubliclyTraded" [value]="false" (keydown)="disableNavigation($event)"
                                [(ngModel)]="organization.isPubliclyTraded" label="N"
                                (onClick)="saveField('isPubliclyTraded', organization.isPubliclyTraded)"></p-radioButton>
                        </div>
                    </div>

                </div>

                <div class="field col-12 md:col-4">
                    <div class="grid mt-1 limit-width-large dashed-bottom">
                        <div class="col-12 md:col-4 label md:text-right">Parent Company</div>
                        <div *ngIf="!editingField.parentCompany" class="field col-12 md:col-8 contact-info">
                            <a *ngIf="organization.parentCompany !=null"
                                [routerLink]="['/organization/', organization.parentCompany.uuid]">{{organization.parentCompany.name}}</a>&nbsp;
                                <button pButton type="button" icon="pi pi-pencil" title="Edit" class="p-button-text edit-button"
                                (click)="editField('parentCompany')"></button>
                        </div>
                        <div *ngIf="editingField.parentCompany" class="field col-12 md:col-8">
                            <div class="input-container">
                              <p-autoComplete appendTo="body" [unique]="true" [forceSelection]="true"
                                [suggestions]="organizationResults" [(ngModel)]="organization.parentCompany"
                                (completeMethod)="searchOrganizations($event)" field="name"> </p-autoComplete>
                                <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                                (click)="saveField('parentCompany', organization.parentCompany)"></button>
                                <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                                (click)="closeDescriptionDialog('parentCompany')"></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <h5>Classification</h5>
            <div class="p-fluid formgrid grid">
                <ng-container *ngFor="let category of categories">
                    <div class="field col-12 md:col-4" (click)="editField(category.label)">
                        <div class="grid mt-1 limit-width-large dashed-bottom">
                            <div class="col-12 md:col-4 label md:text-right">{{ category.label }}</div>
                            <div *ngIf="!editingField[category.label]" class="field col-12 md:col-8">
                                <span
                                    *ngFor="let classification of organization.classification[category.model]; let last = last">
                                    {{ classification.name }}{{ last ? '' : ', ' }}
                                </span>
                                &nbsp;
                            </div>
                            <div *ngIf="editingField[category.label]" class="field col-12 md:col-8">
                                <div class="input-container" >
                                <p-multiSelect [options]="classificationList[category.value]"
                                    [(ngModel)]="organization.classification[category.model]" optionLabel="name"
                                    placeholder="Choose" appendTo="body" dataKey="uuid"
                                    (keydown)="disableNavigation($event)">
                                </p-multiSelect>
                                <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                                (click)="saveField('classification', organization.classification)"></button>
                                <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                                (click)="closeDescriptionDialog('classification')"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <h5>Primary Address Information </h5>
            <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-4" (click)="editField('address')">
                    <div class="grid mt-1 limit-width-large dashed-bottom">
                        <div class="col-12 md:col-4 label md:text-right">Street</div>
                        <div *ngIf="!editingField.address" class="field col-12 md:col-8 capitalize">
                            {{ organization.address }}
                        </div>
                        <div *ngIf="editingField.address" class="field col-12 md:col-8 capitalize">
                            <div class="input-container">
                            <input pInputText type="text" [(ngModel)]="organization.address" maxlength="100">
                                <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                                (click)="saveField('address', organization.address)"></button>
                                <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                                (click)="closeDescriptionDialog('address')"></button>
                                </div>
                        </div>
                    </div>
                </div>
                <div class="field col-12 md:col-4" (click)="editField('city')">
                    <div class="grid mt-1 limit-width-large dashed-bottom">
                        <div class="col-12 md:col-4 label md:text-right">City</div>
                        <div *ngIf="!editingField.city" class="field col-12 md:col-8 capitalize">
                            {{ organization.city }}
                        </div>
                        <div *ngIf="editingField.city" class="field col-12 md:col-8 capitalize">
                            <div class="input-container">
                            <input pInputText type="text" [(ngModel)]="organization.city" maxlength="100">
                                <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                                (click)="saveField('city', organization.city)"></button>
                                <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                                (click)="closeDescriptionDialog('city')"></button>
                                </div>
                        </div>
                    </div>
                </div>
                <div class="field col-12 md:col-4" (click)="editField('postalCode')">
                    <div class="grid mt-1 limit-width-large dashed-bottom">
                        <div class="col-12 md:col-4 label md:text-right">Zip/Postal Code</div>
                        <div *ngIf="!editingField.postalCode" class="field col-12 md:col-8 capitalize">
                            {{ organization.postalCode }}
                        </div>
                        <div *ngIf="editingField.postalCode" class="field col-12 md:col-8 capitalize">
                            <div class="input-container">
                            <input pInputText type="text" [(ngModel)]="organization.postalCode" maxlength="100">
                                <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                                (click)="saveField('postalCode', organization.postalCode)"></button>
                                <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                                (click)="closeDescriptionDialog('postalCode')"></button>
                                </div>
                        </div>
                    </div>
                </div>
                <div class="field col-12 md:col-4" (click)="editField('phone')">
                    <div class="grid mt-1 limit-width-large dashed-bottom">
                        <div class="col-12 md:col-4 label md:text-right">Phone</div>
                        <div *ngIf="!editingField.phone" class="field col-12 md:col-8 capitalize">
                            {{organization.phone}}&nbsp;
                        </div>
                        <div *ngIf="editingField.phone" class="field col-12 md:col-8 capitalize">
                            <div class="input-container">
                            <p-inputNumber mode="decimal" [useGrouping]="false" [(ngModel)]="organization.phone"></p-inputNumber>
                            <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                            (click)="saveField('phone', organization.phone)"></button>
                            <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                            (click)="closeDescriptionDialog('phone')"></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="field col-12 md:col-4" (click)="editField('country')">
                    <div class="grid mt-1 limit-width-large dashed-bottom">
                        <div class="col-12 md:col-4 label md:text-right">Country</div>
                        <div *ngIf="!editingField.country" class="field col-12 md:col-8">
                            {{ organization.country?organization.country.name:'' }}
                        </div>
                        <div *ngIf="editingField.country" class="field col-12 md:col-8 capitalize">
                            <div class="input-container">
                                <tbp-autocomplete [multiple]="false" [type]="'COUNTRY'" [items]="countries" [(ngModel)]="organization.country">
                            </tbp-autocomplete>
                                <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                                (click)="saveField('country', organization.country)"></button>
                                <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                                (click)="closeDescriptionDialog('country')"></button>
                                </div>
                        </div>
                    </div>
                </div>

                <div class="field col-12 md:col-4" (click)="editField('state')">
                    <div class="grid mt-1 limit-width-large dashed-bottom">
                        <div class="col-12 md:col-4 label md:text-right">State</div>
                        <div *ngIf="!editingField.state" class="field col-12 md:col-8 capitalize">
                            {{ organization.state?organization.state.name:''}}
                        </div>
                        <div *ngIf="editingField.state && !stateDisabled" class="field col-12 md:col-8 capitalize">
                            <div class="input-container">
                                <tbp-autocomplete [multiple]="false" [cntType]="'STATE'" [cntUuid]="organization.country?.uuid"
                               [cntName]="organization.country?.name"  [disabled]="stateDisabled" [(ngModel)]="organization.state"
                        [type]="'STATE'" [items]="stateSuggestions"></tbp-autocomplete>
                                <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                                (click)="saveField('state', organization.state)"></button>
                                <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                                (click)="closeDescriptionDialog('state')"></button>
                                </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngFor="let address of addresses;  let i = index">
                <div *ngIf="address.uuid != null">
                    <h5>{{address.title}}&nbsp;<a href="javascript: void(0);" (click)="editAddress(address)"><i
                                class="pi pi-pencil"></i></a></h5>
                    <div class="p-fluid formgrid grid">
                        <div class="col-12"></div>
                        <div class="field col-12 md:col-4">
                            <div class="grid mt-1 limit-width-large dashed-bottom">
                                <div class="col-12 md:col-4 label md:text-right">
                                    <div>Street</div>
                                </div>
                                <div class="field col-12 md:col-8">
                                    {{address.address}}&nbsp;
                                </div>
                            </div>

                        </div>
                        <div class="field col-12 md:col-4">
                            <div class="grid mt-1 limit-width-large dashed-bottom">
                                <div class="col-12 md:col-4 label md:text-right">
                                    <div>City</div>
                                </div>
                                <div class="field col-12 md:col-8">
                                    {{address.city}}&nbsp;
                                </div>
                            </div>

                        </div>

                        <div class="field col-12 md:col-4">
                            <div class="grid mt-1 limit-width-large dashed-bottom">
                                <div class="col-12 md:col-4 label md:text-right">
                                    <div>Zip/Postal Code</div>
                                </div>
                                <div class="field col-12 md:col-8">
                                    {{address.postalCode}}&nbsp;
                                </div>
                            </div>

                        </div>
                        <div class="field col-12 md:col-4">
                            <div class="grid mt-1 limit-width-large dashed-bottom">
                                <div class="col-12 md:col-4 label md:text-right">
                                    <div>Phone</div>
                                </div>
                                <div class="field col-12 md:col-8">
                                    {{address.phone}}&nbsp;
                                </div>
                            </div>

                        </div>
                        <div class="field col-12 md:col-4">
                            <div class="grid mt-1 limit-width-large dashed-bottom">
                                <div class="col-12 md:col-4 label md:text-right">
                                    <div>Country</div>
                                </div>
                                <div class="field col-12 md:col-8">
                                    {{address.country}}&nbsp;
                                </div>
                            </div>

                        </div>
                        <div class="field col-12 md:col-4">
                            <div class="grid mt-1 limit-width-large dashed-bottom">
                                <div class="col-12 md:col-4 label md:text-right">
                                    <div>State/Province</div>
                                </div>
                                <div class="field col-12 md:col-8">
                                    {{address.state}}&nbsp;
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="formgrid grid mb-4" *ngIf="role == 1">
                <div class="field col-12 md:col-4 mt-4">
                    <p-button label="Add New Address" icon="pi pi-plus" (click)="newAddress()"></p-button>
                </div>
            </div>


            <div class="grid" *ngIf="role == 1">
                <div class="col-12 md:col-12">
                    <user-popup-list #contactlist [parent]="organization"
                        [canChangeOrganization]="false"></user-popup-list>
                </div>
            </div>
            <p-panel [toggleable]="true" [collapsed]="false" expandIcon="pi pi-chevron-left"
                collapseIcon="pi pi-chevron-down">
                <p-header>
                    <span class="p-panel-title text-teal">
                        <i class="pi pi-user"></i> Associated Contact(s)
                    </span>
                </p-header>
                <ng-template pTemplate="icons">
                    <button pButton title="Delete" class="p-panel-header-icon p-link" (click)="deleteAssContact()"
                        label="" icon="pi pi-trash"></button>
                    <button pButton title="Add" class="p-panel-header-icon p-link" (click)="associateContact()" label=""
                        icon="pi pi-plus"></button>
                </ng-template>
                <p-table [value]="relatedContact" [(selection)]="selectedContacts" [autoLayout]="true"
                    [paginator]="false"
                    styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 3rem">
                                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                            </th>
                            <th>Contact Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Role</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-relatedContact>
                        <tr>
                            <td>
                                <p-tableCheckbox [value]="relatedContact"></p-tableCheckbox>
                            </td>
                            <td class="wrap capitalize"><a
                                    [routerLink]="['/contact', relatedContact.uuid]">{{relatedContact.name}}</a></td>
                            <td class="wrap lowercase"><a
                                    [routerLink]="['/contact', relatedContact.uuid]">{{relatedContact.email}}</a></td>
                            <td class="wrap">{{relatedContact.phone}}</td>
                            <td class="wrap">{{relatedContact.role}}</td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="5">No contact found to be associated with account.</td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-panel>
            &nbsp;
            <div class="grid" *ngIf="role == 1">
                <div class="col-12 md:col-12">
                    <note-list noteType="organization" [parent]="organization" [parentUuid]="organization.uuid"
                        (newContactCreated)="contactList.load()"></note-list>
                </div>
            </div>

            <div class="grid" *ngIf="role == 1">
                <div class="col-12 md:col-12">
                    <p-panel [toggleable]="true" [collapsed]="false" expandIcon="pi pi-chevron-left"
                        collapseIcon="pi pi-chevron-down">
                        <p-header>
                            <span class="p-panel-title text-teal">
                                <i class="pi pi-bars"></i> Deals
                            </span>
                        </p-header>
                        <p-table [autoLayout]="true" [value]="deals" [(selection)]="selectedDeals" dataKey="userId"
                            [lazy]="true" (onLazyLoad)="loadDeals($event)" [lazyLoadOnInit]="false"
                            styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm"
                            [rowHover]="true" [rows]="5" [paginator]="false" [loading]="dealLoading"
                            [showCurrentPageReport]="true"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            [totalRecords]="dealTotalRecords" [alwaysShowPaginator]="false">
                            {{dealTotalRecords}}
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Date</th>
                                    <th>Deal Type</th>
                                    <th>Buyers/Investors</th>
                                    <th>Target/Issuer</th>
                                    <th>Transaction Value ($m)</th>
                                    <th>EV/Revenue</th>
                                    <th>EV/EBITDA</th>
                                    <th>Action</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-deal>
                                <tr>
                                    <td><a [routerLink]="['/deal/', deal.uuid]">{{deal.dealDate |
                                            date:'MM-dd-yyyy'}}</a></td>
                                    <td>{{deal.dealType | dealPipe}}</td>
                                    <td class="wrap">
                                        <span class="limitedText limitText">
                                            <span
                                                *ngFor="let organization of deal.buyerOrganizations; let last = last;">
                                                <a
                                                    [routerLink]="['/organization/', organization.uuid]">{{organization.name}}</a>{{last?'':';'}}
                                            </span>
                                        </span>
                                        <a href="javascript:" (click)="appUtil.showHideClampedContent($event)"
                                            class="moreless hidden ml-2 p-button-danger"
                                            style="float:right;display:block;">More</a>
                                    </td>
                                    <td class="wrap">
                                        <a *ngIf="deal.targetOrganization"
                                            [routerLink]="['/organization/', deal.targetOrganization.uuid]">{{deal.targetOrganization?.name}}</a>
                                    </td>
                                    <td class="wrap">
                                        {{deal.transactionValue==null?'-':deal.transactionValue==0?'-':deal.transactionValue}}
                                    </td>
                                    <td class="wrap">{{deal.evRev==null?'-':deal.evRev==0?'-':deal.evRev}}</td>
                                    <td class="wrap">{{deal.evEbitda==null?'-':deal.evEbitda==0?'-':deal.evEbitda}}</td>
                                    <td>
                                        <p-button [routerLink]="['/deal/', deal.uuid]" icon="pi pi-eye"
                                            class="p-button-success mr-2"></p-button>
                                        <button *ngIf="role== 1 " pButton pRipple
                                            [routerLink]="['/deal/edit/', deal.uuid]" icon="pi pi-pencil"
                                            class="p-button-success mr-2"></button>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="8">No Deal found to be associated with account.</td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="footer">
                                <tr *ngIf="dealTotalRecords > 5">
                                    <td colspan="8" class="text-right"><a href="./organization/{{uuid}}/deals">View
                                            More</a></td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </p-panel>
                </div>
            </div>
            <div class="grid" *ngIf="role == 1">
                <div class="col-12 md:col-12">
                    <p-panel [toggleable]="true" [collapsed]="false" expandIcon="pi pi-chevron-left"
                        collapseIcon="pi pi-chevron-down">
                        <p-header>
                            <span class="p-panel-title text-teal">
                                <i class="pi pi-bars"></i> Buy Side
                            </span>
                        </p-header>
                        <ng-template pTemplate="icons">
                            <button pButton title="Preview" class="p-panel-header-icon p-link"
                                (click)="processSearchCommand('buysides')" label="" icon="pi pi-search-plus"></button>
                            <button pButton title="Add" class="p-panel-header-icon p-link" (click)="setCurrentBuyside()"
                                label="" icon="pi pi-plus"></button>
                        </ng-template>
                        <p-table [autoLayout]="true" [value]="buysides" [(selection)]="selectedBuySides"
                            dataKey="userId" [lazy]="true" (onLazyLoad)="loadBuySides($event)" [lazyLoadOnInit]="false"
                            styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm"
                            [rowHover]="true" [rows]="5" [paginator]="false" [loading]="buysideLoading"
                            [showCurrentPageReport]="true"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            [totalRecords]="buysideTotalRecords" [alwaysShowPaginator]="false">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Screen Title</th>
                                    <th>Account</th>
                                    <th>Association Type</th>
                                    <th>External Headline</th>
                                    <th>Primary Objective</th>
                                    <th>Target Revenue</th>
                                    <th>Action</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-buyside>
                                <tr>
                                    <td class="wrap"><a
                                            [routerLink]="['/buy-side/', buyside.uuid,'prospects']">{{buyside.screenTitle}}</a>
                                    </td>
                                    <td class="wrap">{{organization.name}} </td>
                                    <td class="wrap">{{getAssociationTypeForBuyside(organization,buyside)}}</td>
                                    <td class="wrap">{{buyside.externalHeadline}}</td>
                                    <td class="wrap" [innerHTML]="buyside | buysideobjective"></td>
                                    <td class="wrap">{{buyside.targetRevenue}}</td>
                                    <td>
                                        <button *ngIf="role== 1 " pButton pRipple
                                            [routerLink]="['/buy-side/edit/', buyside.uuid]" icon="pi pi-pencil"
                                            class="p-button-success mr-2"></button>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="7">No Buy side found to be associated with account.</td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="footer">
                                <tr *ngIf="buysideTotalRecords > 5">
                                    <td colspan="8" class="text-right"><a href="./organization/{{uuid}}/buysides">View
                                            More</a></td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </p-panel>
                </div>
            </div>

            <div class="grid" *ngIf="role == 1">
                <div class="col-12 md:col-12">
                    <p-panel [toggleable]="true" [collapsed]="false" expandIcon="pi pi-chevron-left"
                        collapseIcon="pi pi-chevron-down">
                        <p-header>
                            <span class="p-panel-title text-teal">
                                <i class="pi pi-bars"></i> Sell Side
                            </span>
                        </p-header>
                        <ng-template pTemplate="icons">
                            <button pButton title="Preview" class="p-panel-header-icon p-link"
                                (click)="processSearchCommand('sellsides')" label=""
                                icon="pi pi-search-plus"></button>
                            <button pButton title="Add" class="p-panel-header-icon p-link"
                                (click)="setCurrentSellside()" label="" icon="pi pi-plus"></button>
                        </ng-template>
                        <p-table [autoLayout]="true" [value]="sellsides" [(selection)]="selectedSellSides"
                            dataKey="userId" [lazy]="true" (onLazyLoad)="loadSellSides($event)" [lazyLoadOnInit]="false"
                            styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm"
                            [rowHover]="true" [rows]="5" [paginator]="false" [loading]="sellsideLoading"
                            [showCurrentPageReport]="true"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            [totalRecords]="sellsideTotalRecords" [alwaysShowPaginator]="false">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Screen Title</th>
                                    <th>Account</th>
                                    <th>Association Type</th>
                                    <th>External Headline</th>
                                    <th>Primary Objective</th>
                                    <th>Revenue</th>
                                    <th>Action</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-sellside>
                                <tr>
                                    <td class="wrap"><a
                                            [routerLink]="['/sell-side/', sellside.uuid,'prospects']">{{sellside.screenTitle}}</a>
                                    </td>
                                    <td class="wrap">{{organization.name}}</td>
                                    <td class="wrap">{{getAssociationTypeForSellside(organization, sellside)}}
                                    </td>
                                    <td class="wrap">{{sellside.externalHeadline}}</td>
                                    <td class="wrap" [innerHTML]="sellside | sellsideobjective">
                                    </td>
                                    <td class="wrap">{{sellside.targetRevenue}}</td>
                                    <td>
                                        <button *ngIf="role== 1 " pButton pRipple
                                            [routerLink]="['/sell-side/edit/', sellside.uuid]" icon="pi pi-pencil"
                                            class="p-button-success mr-2"></button>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="7">No Sell side found to be associated with account.</td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="footer">
                                <tr *ngIf="sellsideTotalRecords > 5">
                                    <td colspan="8" class="text-right"><a href="./organization/{{uuid}}/sellsides">View
                                            More</a></td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </p-panel>
                </div>
            </div>

            <div class="grid" *ngIf="role == 1">
                <div class="col-12 md:col-12">
                    <p-panel [toggleable]="true" [collapsed]="false" expandIcon="pi pi-chevron-left"
                        collapseIcon="pi pi-chevron-down">
                        <p-header>
                            <span class="p-panel-title text-teal">
                                <i class="pi pi-bars"></i> Spotlights
                            </span>
                        </p-header>
                        <p-table [autoLayout]="true" [value]="spotlights" [(selection)]="selectedSpotlight"
                            dataKey="userId" [lazy]="true" (onLazyLoad)="loadSpotlights($event)"
                            [lazyLoadOnInit]="false"
                            styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm"
                            [rowHover]="true" [rows]="5" [paginator]="false" [loading]="spotlightLoading"
                            [showCurrentPageReport]="true"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            [totalRecords]="spotlightTotalRecords" [alwaysShowPaginator]="false">
                            <ng-template pTemplate="header">
                                <tr>
                                    <!--<th style="width: 3rem">
                  <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>-->
                                    <th>Date</th>
                                    <th>Title</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-spotlight>
                                <tr>
                                    <!--<td class="wrap">
                  <p-tableCheckbox [value]="spotlight"></p-tableCheckbox>
                </td>-->
                                    <td>{{spotlight.spotlightDate | date:'MM-dd-yyyy'}}</td>
                                    <td class="wrap"><a
                                            [routerLink]="['/spotlight/', spotlight.uuid]">{{spotlight.title}}</a></td>
                                    <td class="wrap">{{spotlight.status | titlecase}}</td>
                                    <td>
                                        <p-button [routerLink]="['/spotlight/', spotlight.uuid]" icon="pi pi-eye"
                                            class="p-button-success mr-2"></p-button>
                                        <button *ngIf="role== 1 " pButton pRipple
                                            [routerLink]="['/spotlight/edit/', spotlight.uuid]" icon="pi pi-pencil"
                                            class="p-button-success mr-2"></button>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="4">No Spotlight found to be associated with account.</td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="footer">
                                <tr *ngIf="spotlightTotalRecords > 5">
                                    <td colspan="4" class="text-right"><a href="./organization/{{uuid}}/spotlight">View
                                            More</a></td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </p-panel>
                </div>
            </div>
            <div class="grid" *ngIf="role == 1">
                <div class="col-12 md:col-12">
                    <task-toggle-list taskType="organization" [parentUuid]="uuid"
                        [parent]="organization"></task-toggle-list>
                </div>
            </div>

            <div class="grid" *ngIf="role == 1">
                <div class="col-12 md:col-12">
                    <p-panel [toggleable]="true" [collapsed]="false" expandIcon="pi pi-chevron-left"
                        collapseIcon="pi pi-chevron-down">
                        <p-header>
                            <span class="p-panel-title text-teal">
                                <i class="pi pi-bars"></i> Email Campaign
                            </span>
                        </p-header>
                        <ng-template pTemplate="icons">
                            <button pButton title="Add" class="p-panel-header-icon p-link"
                                (click)="setCurrentCampaign()" label="" icon="pi pi-plus"></button>
                        </ng-template>
                        <p-table [autoLayout]="true" [value]="emailCampaigns" [(selection)]="selectedEmailCampaigns"
                            dataKey="userId" [lazy]="true" (onLazyLoad)="loadEmailCampaigns($event)"
                            styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm"
                            [rowHover]="true" [rows]="5" [paginator]="false" [loading]="emailCampaignLoading"
                            [showCurrentPageReport]="true"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            [totalRecords]="emailCampaignTotalRecords" [alwaysShowPaginator]="false">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Title</th>
                                    <th>Type</th>
                                    <th>Related To</th>
                                    <th>Related Tag</th>
                                    <th>Attachments</th>
                                    <th>Action</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-emailCampaign>
                                <tr>
                                    <td class="wrap"><a
                                            [routerLink]="['/email-campaign/', emailCampaign.uuid]">{{emailCampaign.title}}</a>
                                    </td>
                                    <td class="wrap">{{emailCampaign.type}}</td>
                                    <td class="wrap">
                                        <span *ngIf="emailCampaign.buySide != null">
                                            <a
                                                [routerLink]="['/buy-side/', emailCampaign.buySide.uuid]">{{emailCampaign.buySide.screenTitle}}</a>
                                        </span>
                                        <span *ngIf="emailCampaign.sellSide != null">
                                            <a
                                                [routerLink]="['/sell-side/', emailCampaign.sellSide.uuid]">{{emailCampaign.sellSide.screenTitle}}</a>
                                        </span>
                                        <span *ngIf="emailCampaign.engagement != null">
                                            <a
                                                [routerLink]="['/engagement/', emailCampaign.engagement.uuid]">{{emailCampaign.engagement.name}}</a>
                                        </span>
                                        <span *ngIf="emailCampaign.spotlight != null">
                                            <a
                                                [routerLink]="['/spotlight/', emailCampaign.spotlight.uuid]">{{emailCampaign.spotlight.title}}</a>
                                        </span>
                                    </td>
                                    <td class="wrap">{{emailCampaign.relatedTag}}</td>
                                    <td class="wrap">
                                        <span *ngFor="let file of emailCampaign.uploadedFiles; let last = last;">
                                            <a href="{{file.url}}" (click)="openDocument($event)">{{file.name}}</a><br>
                                        </span>
                                    </td>
                                    <td>
                                        <button pButton pRipple
                                            [routerLink]="['/email-campaign/edit/', emailCampaign.uuid]"
                                            icon="pi pi-pencil" class="p-button-success mr-2"></button>
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="footer">
                                <tr *ngIf="emailCampaignTotalRecords > 5">
                                    <td colspan="6" class="text-right"><a
                                            href="./organization/{{organization.uuid}}/campaigns">View More</a></td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="6">No Email Campaign found to be associated with contact.</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </p-panel>
                </div>
            </div>

            <div class="grid" *ngIf="role == 1">
                <div class="col-12 md:col-12">
                    <p-panel [toggleable]="true" [collapsed]="false" expandIcon="pi pi-chevron-left"
                        collapseIcon="pi pi-chevron-down">
                        <p-header>
                            <span class="p-panel-title text-teal">
                                <i class="pi pi-bars"></i> Sub offices
                            </span>
                        </p-header>
                        <p-table [autoLayout]="true" [value]="parents" [(selection)]="selectedParent" dataKey="userId"
                            [lazy]="true" (onLazyLoad)="loadParents($event)" [lazyLoadOnInit]="false"
                            styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm"
                            [rowHover]="true" [rows]="5" [paginator]="true" [loading]="parentLoading"
                            [showCurrentPageReport]="true"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            [totalRecords]="parentTotalRecords" [alwaysShowPaginator]="false">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Location</th>
                                    <th>Website</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-parent>
                                <tr>
                                    <td><a [routerLink]="['/organization/', parent.uuid]">{{parent.name}}</a></td>
                                    <td>{{parent.companyType.replace('_',' ') | titlecase}}</td>
                                    <td>
                                        <span *ngIf="parent.state != null">{{parent.state | titlecase}}, </span>
                                        <span *ngIf="parent.country != null">{{parent.country | titlecase}}</span>
                                    </td>
                                    <td>{{parent.website}}</td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="4">No organization found where this organization is set-up as parent.
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </p-panel>
                </div>
            </div>
            <div class="grid" *ngIf="role == 1">
                <div class="col-12 md:col-12">
                    <p-panel [toggleable]="true" [collapsed]="false" expandIcon="pi pi-chevron-left"
                        collapseIcon="pi pi-chevron-down">
                        <p-header>
                            <span class="p-panel-title text-teal">
                                <i class="pi pi-bars"></i> Investors
                            </span>
                        </p-header>
                        <p-table [autoLayout]="true" [value]="investors" [(selection)]="selectedInvestor"
                            dataKey="userId" [lazy]="true" (onLazyLoad)="loadinvestors($event)" [lazyLoadOnInit]="false"
                            styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm"
                            [rowHover]="true" [rows]="5" [paginator]="true" [loading]="investorLoading"
                            [showCurrentPageReport]="true"[rowsPerPageOptions]="[5,15,25,50,75,100, { showAll: 'All' }]"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            [totalRecords]="investorTotalRecords" [alwaysShowPaginator]="false">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Phone</th>
                                    <th>Location</th>
                                    <th>Website</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-parent>
                                <tr>
                                    <td>
                                        <a *ngIf="parent.type == 'CORPORATE'"
                                            [routerLink]="['/organization/', parent.uuid]">{{parent.name}}</a>
                                        <a *ngIf="parent.type == 'INDIVIDUAL'"
                                            [routerLink]="['/contact/', parent.uuid]">{{parent.name}}</a>
                                    </td>
                                    <td>{{parent.type.replace('_',' ') | titlecase}}</td>
                                    <td>{{parent.phone}}</td>
                                    <td>
                                        <span *ngIf="parent.state != null">{{parent.state | titlecase}}, </span>
                                        <span *ngIf="parent.country != null">{{parent.country | titlecase}}</span>
                                    </td>
                                    <td>{{parent.website}}</td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="5">Selected organization does not have any investors listed.</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </p-panel>
                </div>
            </div>
            <div class="grid" *ngIf="role == 1">
                <div class="col-12 md:col-12">
                    <p-panel [toggleable]="true" [collapsed]="false" expandIcon="pi pi-chevron-left"
                        collapseIcon="pi pi-chevron-down">
                        <p-header>
                            <span class="p-panel-title text-teal">
                                <i class="pi pi-bars"></i> Portfolio Companies
                            </span>
                        </p-header>
                        <p-table [autoLayout]="true" [value]="portfolio" [(selection)]="selectedPortfolio"
                            dataKey="userId" [lazy]="true" (onLazyLoad)="loadportfolio($event)" [lazyLoadOnInit]="false"
                            styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm"
                            [rowHover]="true" [rows]="5" [paginator]="true" [loading]="portfolioLoading"
                            [showCurrentPageReport]="true" [rowsPerPageOptions]="[5,15,25,50,75,100, { showAll: 'All' }]"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            [totalRecords]="portfolioTotalRecords" [alwaysShowPaginator]="false">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Location</th>
                                    <th>Website</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-parent>
                                <tr>
                                    <td>
                                        <a [routerLink]="['/organization/', parent.uuid]">{{parent.name}}</a>
                                    </td>
                                    <td>{{parent.companyType.replace('_',' ') | titlecase}}</td>
                                    <td>
                                        <span *ngIf="parent.state != null">{{parent.state | titlecase}}, </span>
                                        <span *ngIf="parent.country != null">{{parent.country | titlecase}}</span>
                                    </td>
                                    <td>{{parent.website}}</td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="4">Selected organization does not have any Portfolio Companies listed.
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </p-panel>
                </div>
            </div>

        </div>
    </div>
</div>
<p-dialog [(visible)]="orgAddress" (onHide)="hideDialogAddress()" [style]="{width: '35vw'}"
    [header]="dialogTitleAddress" [modal]="true">
    <ng-template pTemplate="content">
        <form [formGroup]="addressForm" #aform>
            <div class="card">
                <div class="p-fluid formgrid grid">
                    <div class="field col-12 ">
                        <span class="p-float-label">
                            <input id="title" type="text"
                                [ngClass]="{ 'ng-invalid ng-dirty': afSubmitted && af.title.errors }" maxlength="30"
                                formControlName="title" pInputText>
                            <label for="title">Title <span class="mandate">*</span></label>
                        </span>
                        <span class="p-error" *ngIf="afSubmitted && af.title.hasError('required')">Title is
                            required</span>
                        <span class="p-error"
                            *ngIf="afSubmitted && af.title.invalid && af.title.hasError('message')">{{af.title.errors.message}}</span>
                    </div>
                    <div class="field col-12 spacing">
                        <span class="p-float-label">
                            <tbp-autocomplete [multiple]="false" (onChange)="getStates()" [formControl]="af.country"
                                [type]="'COUNTRY'" [items]="countries">
                            </tbp-autocomplete>
                            <label for="country">Country</label>
                        </span>
                    </div>
                    <div class="field col-12 spacing ">
                        <span class="p-float-label">
                            <tbp-autocomplete [multiple]="false" [cntType]="'STATE'" [cntUuid]="af.country.value?.uuid"
                                [cntName]="af.country.value?.name" [disabled]="stateDisabled" [formControl]="af.state"
                                [type]="'STATE'" [items]="states"></tbp-autocomplete>
                            <label for="state">State</label>
                        </span>
                        <span class="p-error"
                            *ngIf="afSubmitted && af.state.invalid && af.state.hasError('message')">{{af.state.errors.message}}</span>
                        <span class="p-error" *ngIf="afSubmitted && af.state.hasError('required')">Please Select
                            State</span>
                    </div>
                    <div class="field col-12 spacing">
                        <span class="p-float-label">
                            <input id="address" type="text"
                                [ngClass]="{ 'ng-invalid ng-dirty': afSubmitted && af.address.errors }" maxlength="220"
                                formControlName="address" pInputText>
                            <label for="address">Street</label>
                        </span>
                        <span class="p-error"
                            *ngIf="afSubmitted && af.address.invalid && af.address.hasError('message')">{{af.address.errors.message}}</span>
                    </div>
                    <div class="field col-12 spacing">
                        <span class="p-float-label">
                            <input id="city" type="text"
                                [ngClass]="{ 'ng-invalid ng-dirty': afSubmitted && af.city.errors }" maxlength="45"
                                formControlName="city" pInputText>
                            <label for="city">City </label>
                        </span>
                        <span class="p-error"
                            *ngIf="afSubmitted && af.city.invalid && af.city.hasError('message')">{{af.city.errors.message}}</span>
                    </div>
                    <div class="field col-12 spacing">
                        <span class="p-float-label">
                            <input id="postalCode" type="text" maxlength="9"
                                [ngClass]="{ 'ng-invalid ng-dirty': afSubmitted && af.postalCode.errors }"
                                formControlName="postalCode" pInputText>
                            <label for="postalCode">Zip/Postal Code</label>
                        </span>
                        <span class="p-error"
                            *ngIf="afSubmitted && af.postalCode.invalid && af.postalCode.hasError('message')">{{af.postalCode.errors.message}}</span>
                    </div>
                    <div class="field col-12 spacing">
                        <span class="p-float-label">
                            <input id="phone" type="text" maxlength="40"
                                [ngClass]="{ 'ng-invalid ng-dirty': afSubmitted && af.phone.errors }"
                                formControlName="phone" pInputText (blur)="appUtil.formatPhone(af.phone)">

                            <!--
                            <p-inputMask *ngIf="af.country.value.name == 'United States' " mask="(999) 999-9999? x99999"
                                formControlName="phone"
                                [ngClass]="{ 'ng-invalid ng-dirty': afSubmitted && af.phone.errors }"></p-inputMask>
                            <input id="phone" *ngIf="af.country.value.name != 'United States' " formControlName="phone"
                                type="text" maxlength="40" pInputText
                                [ngClass]="{ 'ng-invalid ng-dirty': afSubmitted && af.phone.errors }">
                            -->
                            <label for="phone">Phone </label>
                        </span>
                        <span class="p-error" *ngIf="afSubmitted && af.phone.hasError('pattern')">Please enter valid
                            phone number</span>
                        <span class="p-error"
                            *ngIf="afSubmitted && af.phone.invalid && af.phone.hasError('message')">{{af.phone.errors.message}}</span>
                    </div>
                </div>
            </div>

        </form>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
            (click)="hideDialogAddress()"></button>
        <button pButton pRipple label="Save" form="clform" icon="pi pi-check" class="p-button-text"
            (click)="saveAddress()"></button>
    </ng-template>
</p-dialog>

<p-dialog [(visible)]="diaContactShow" (onHide)="associateContactHide()" [style]="{width: '42vw'}"
    [header]="diaTitleContact" [modal]="true">
    <ng-template pTemplate="content">
        <form [formGroup]="associateContacts" [style]="{height: '42vh'}">
            <ng-container formArrayName="membersToAdd">

                <div class="p-fluid formgrid grid" *ngFor="let c of documentsControl.controls; let index = index"
                    [formGroupName]='index'>
                    <div class="field col-8 " style="margin-top: 20px;">
                        <span class="p-float-label">
                            <tbp-autocomplete [forceSelection]="true" [items]=""
                                [formControl]="documentsControl.at(index).get('uuid')" [multiple]="false"
                                [type]="'Contact'" field="displayName"
                               >
                            </tbp-autocomplete>
                            <label for="name">Contact</label>
                        </span>
                        <span class="p-error" *ngIf="acfSubmitted && acf.name.hasError('required')">contact is
                            required.</span>
                        <span class="p-error"
                            *ngIf="acfsubmitted && acf.name.invalid && acf.name.hasError('message')">{{acf.name.errors.message}}</span>
                    </div>
                    <div class="field col-4 " style="margin-top: 20px;">
                        <span class="p-float-label">
                            <input id="role" type="text" maxlength="30" formControlName="role" pInputText
                                [ngClass]="{ 'ng-invalid ng-dirty': acfSubmitted && acf.role.errors }">
                            <label for="role">Role</label>
                            <span class="p-error" *ngIf="acfSubmitted && acf.role.hasError('required')">Role is
                                required.</span>
                            <span class="p-error"
                                *ngIf="acfsubmitted && acf.role.invalid && acf.role.hasError('message')">{{acf.role.errors.message}}</span>
                        </span>
                    </div>
                </div>
            </ng-container>
        </form>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
            (click)="associateContactHide()"></button>
        <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
            (click)="saveAssociateContact()"></button>
    </ng-template>
</p-dialog>
<p-toast></p-toast>