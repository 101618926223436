import { first } from 'rxjs/operators';
import { AppUtil } from 'src/app/_helpers';
import { FieldInfo } from './../_models/field-info';
import { Router, UrlSerializer } from '@angular/router';
import { DatePipe, DOCUMENT, Location, LowerCasePipe, TitleCasePipe } from '@angular/common'
import { environment } from '../../environments/environment';

import { Injectable, Inject } from '@angular/core';
import { Rule, RuleSet } from '../_models';
import { HttpClient } from '@angular/common/http';


@Injectable({
    providedIn: 'root'
})

export class SearchHeler {

    constructor(private router: Router,
        @Inject(DOCUMENT) private _document: Document,
        private location: Location,
        private serializer: UrlSerializer,
        private appUtil: AppUtil,
        private http: HttpClient) {

    }

    public get pageSize() {
        if (environment.defaultPageSize)
            return environment.defaultPageSize;

        return 25;
    }

    public keysMap = {
        condition: 'c',
        rules: 'r',
        field: 'f',
        operator: 'o',
        value: 'v',
        searchType: 's',
        matches: 'm'
    };


    public convertForQueryParam(rules) {
        if (rules == null)
            return '';
        var str = JSON.stringify(rules);
        for (const key in this.keysMap) {
            str = str.replace(new RegExp('"' + key + '":', 'g'), '"' + this.keysMap[key] + '":');
        }
        return str;
    }

    public convertFromQueryParam(rules) {
        if (rules == null)
            return null;
        var str = rules;

        for (const key in this.keysMap) {
            str = str.replace(new RegExp('"' + this.keysMap[key] + '":', 'g'), '"' + key + '":');
        }
        return JSON.parse(str);
    }

    public initializeSearch(component) {
        component.searchTerm = component.route.snapshot.queryParams['searchTerm'] || null;
        component.firstRow = component.route.snapshot.queryParams['first'] ? Number(component.route.snapshot.queryParams['first']) : 0;
        component.pageSize = component.route.snapshot.queryParams['pagesize'] ? Number(component.route.snapshot.queryParams['pagesize']) : component.defaultPageSize;
        component.sortField = component.route.snapshot.queryParams['sortfield'] ? component.route.snapshot.queryParams['sortfield'] : component.defaultSortField;
        component.sortOrder = component.route.snapshot.queryParams['sortorder'] ? Number(component.route.snapshot.queryParams['sortorder']) : component.defaultSortOrder;
        let filter = component.route.snapshot.queryParams['filter'] || null;
        if (filter != null) {
            filter = this.convertFromQueryParam(filter);
            let tmpRootRule: RuleSet = { 'condition': filter.condition, rules: [], ruleSets: [] };
            if(localStorage.length!=1){
            this.prepareRulesFromFilter(component, tmpRootRule, filter.rules);
            }
            component.rootRule = tmpRootRule;
        }
        let e = {
            searchTerm: component.searchTerm, filter: filter, first: component.firstRow, rows: component.pageSize,
            sortField: component.sortField, sortOrder: component.sortOrder
        };
        component.onSearch.emit(e);
    }


    public _search(rootRule: RuleSet, searchURL: string, searchTerm: string, sortField: string, sortOrder: number, firstRow: number, pageSize: number, navigate: boolean) {
        let filter = this.prepareFilter(rootRule);
        let urlTree = this.router.createUrlTree([searchURL], {
            queryParams: {
                'searchTerm': searchTerm,
                'filter': this.convertForQueryParam(filter), 'first': firstRow, 'pagesize': pageSize,
                'sortfield': sortField, 'sortorder': sortOrder
            }
        });
        if (navigate) {
            this.router.navigateByUrl(this.serializer.serialize(urlTree));
        }
        else {
            this.location.go(this.serializer.serialize(urlTree));
        }

        return filter;
    }

    public search(component, refreshData) {
        const { rootRule, searchURL, searchTerm, sortField, sortOrder, firstRow, pageSize } = component;
        const filter = this._search(rootRule, searchURL, searchTerm, sortField, sortOrder, firstRow, pageSize, false);
        if (refreshData) {
            let e = {
                searchTerm: component.searchTerm, filter: filter, first: component.firstRow, rows: component.pageSize,
                sortField: component.sortField, sortOrder: component.sortOrder
            };
            component.onSearch.emit(e);
        }
    }

    public prepareRuleSetForQuery(ruleset: any, fields: any) {
        if (ruleset && ruleset.rules && ruleset.rules.length > 0) {
            ruleset.rules.forEach((item) => {
                if (item.rules) {
                    return this.prepareRuleSetForQuery(item, fields);
                } else if (item.field) {
                    const field = fields[item.field];
                    item.searchType = field.searchType;
                    if (Array.isArray(item.value)) {

                        item.values = item.value;
                        item.value = null;
                    }
                    if (field.type == 'date') {
                        item.value = new DatePipe('en-US').transform(item.value, 'MM-dd-yyyy');
                    }
                }
            });
        }
        return ruleset;
    }

    prepareFilter(rootRule): any {
        let query: any = { condition: rootRule.condition, rules: [] };
        rootRule.rules.forEach((rule: Rule) => {
            let qrule = {
                field: rule.field.property, operator: rule.operator, value: rule.value,
                searchType: rule.field.searchType, values: null
            };
            if (rule.field.searchType == 'exact') {
                qrule.value = null;
                qrule.values = rule.value;
            }
            else if (rule.operator == 'between') {
                qrule.value = null;
                if (rule.field.searchType == 'date')
                    qrule.values = [this.appUtil.calendarToDbDateFormat(rule.value, false),
                    this.appUtil.calendarToDbDateFormat(rule.maxValue, false)];
                else
                    qrule.values = [rule.value, rule.maxValue];

            }
            else if (rule.field.searchType == 'date' && (rule.operator == '=' || rule.operator == '!=')) {
                qrule.value = this.appUtil.calendarToDbDateFormat(rule.value, false)
            }
            query.rules.push(qrule)
        });
        return query;
    }

    private _prepareRulesForSearch(rules: any[]): any[] {
        let toRet: any[] = [];
        rules.forEach((rule) => {
            if (rule.condition) {
                let rsetRules = this._prepareRulesForSearch(rule.rules);
                if (rsetRules.length > 1) {
                    toRet.push({ 'condition': rule.condition, rules: rsetRules });
                }
            }
            else if (rule.value != null || (rule.values != null && rule.values.length > 0)
                || (rule.operator == 'exists') || (rule.operator == 'notexists')) {
                toRet.push({ field: rule.field, operator: rule.operator, value: rule.value, values: rule.values, searchType: rule.searchType });
            }
        });
        return toRet;
    }

    public prepareSearchParams(searchTerm: string, filter: RuleSet, sortBy: string = "lastModifiedDate",
        sortDirection: number = 1, first: number = 0, pageSize: number = 25, fields: FieldInfo[]): any {
        var searchParams = {};
        searchParams['page'] = (first / pageSize);
        searchParams['pageSize'] = pageSize;
        var sortDetails = []
        var sortDir = sortDirection == 1 ? 'ASC' : 'DESC';
        if (sortBy === 'location') {
            sortDetails[sortDetails.length] = { "property": 'country', "direction": sortDir };
            sortDetails[sortDetails.length] = { "property": 'state', "direction": sortDir };
            sortDetails[sortDetails.length] = { "property": 'city', "direction": sortDir };
        }
        else if (sortBy && sortBy != '') {
            sortDetails[sortDetails.length] = { "property": sortBy, "direction": sortDir };
            sortDetails[sortDetails.length] = { "property": "id", "direction": sortDir };
        }
        searchParams['sortDetails'] = sortDetails;
        let filterForQuery = null;
        if (filter != null) {
            let rules = this._prepareRulesForSearch(filter.rules);
            if (rules.length > 0) {
                filterForQuery = { condition: filter.condition, rules: rules }
            }
        }
        if (searchTerm && searchTerm != '') {
            let ruleSet = null;
            if (filterForQuery == null) {
                ruleSet = searchParams['rootRule'] = { 'condition': 'or', rules: [] }
            }
            else {
                ruleSet = { 'condition': 'or', rules: [] };
                searchParams['rootRule'] = { 'condition': 'and', rules: [ruleSet, filterForQuery] }
            }

            fields.forEach((x) => {
                if (x.forSearchTermQuery) {
                    ruleSet.rules.push({ 'searchType': 'text', 'field': x.property, 'operator': 'in', 'values': [searchTerm] })
                }
            });
        }
        else {
            searchParams['rootRule'] = filterForQuery == null ? { 'condition': 'and', rules: [] } : filterForQuery;
        }
        return searchParams;
    }

    prepareRulesFromFilter(component, ruleSet: RuleSet, rules: any) {
        rules.forEach((rule) => {
            if (rule.condition) {
                let rset = { 'condition': rule.condition, rules: [], ruleSets: [] };
                ruleSet.ruleSets.push(rset);
                this.prepareRulesFromFilter(component, rset, rule.rules);
            }
            else {
                let r: Rule = new Rule(component.fields.find((fieldInfo) => (fieldInfo.property == rule.field)), null);
                r.operator = rule.operator;
                r.value = rule.value === undefined ? null : rule.value;
                if (rule.searchType == 'exact') {
                    r.value = rule.values;
                }
                else if (rule.operator == 'between') {
                    if (r.field.searchType == 'date') {
                        r.value = component.appUtil.DbToCalendarDateFormat(rule.values[0]);
                        r.maxValue = component.appUtil.DbToCalendarDateFormat(rule.values[1]);
                    }
                    else {
                        r.value = rule.values[0];
                        r.maxValue = rule.values[1];
                    }
                }
                else if (rule.searchType == 'boolean' && r.value != null) {
                    r.value = (r.value === 'true') ? true : false;
                }
                else if (r.value == null && rule.values !== undefined && rule.values != null && rule.values.length > 0) {
                    if (rule.searchType == 'boolean')
                        r.value = (rule.values[0] === 'true') ? true : false;
                    else
                        r.value = rule.values[0];
                }
                r.prepareLabel();
                ruleSet.rules.push(r)
            }
        });
    }



    /////////////////////////////////////////////////////////////
    /*
    private _prepareQuery(rules, queryStr, condition, fields): string {
        for (var i = 0; i < rules.length; i++) {
            let rule = rules[i];
            if (Array.isArray(rule.rules)) {
                if (queryStr != '') {
                    queryStr = queryStr + ' ' + condition + ' ';
                }
                let tmp = this._prepareQuery(rule.rules, '', rule.condition, fields);
                if (tmp != '') {
                    queryStr = queryStr + "(";
                    queryStr = queryStr + tmp;
                    queryStr = queryStr + ")";
                }
            }
            else if ((typeof rule.value != 'undefined') && rule.value != null
                && ((typeof rule.value != 'string') || rule.value.trim() != '')) {
                if (queryStr != '') {
                    queryStr = queryStr + ' ' + condition + ' ';;
                }

                queryStr = queryStr + "(";
                let field = fields[rule.field];
                queryStr = queryStr + field.name + " " + this._getOperator(rule.operator) + " " + this._getValue(field, rule.operator, rule.value);
                queryStr = queryStr + ")";
            }

        }
        return queryStr;
    }

    private _getOperator(operator) {
        if (operator == 'equals') {
            return '=';
        }
        else if (operator == 'not equals') {
            return '!=';
        }
        else if (operator == 'equals') {
            return '=';
        }
        return operator;
    }

    private _getValue(field, operator, value) {
        let retVal = '';
        if (operator == 'in' || operator == 'not in') {
            retVal = ' (';
        }
        if (field.type == 'category') {
            let val;
            if (Array.isArray(value)) {
                val = value;
            }
            else {
                val = [value];
            }
            let finalVal = '';
            for (var i = 0; i < val.length; i++) {
                if(typeof val[i] !== 'undefined')
                {
                    if (finalVal != '') {
                        finalVal = finalVal + ', ';
                    }
                    finalVal = finalVal + field.options.filter(obj => { return obj.value === val[i] })[0].name;
                }
            }
            retVal = retVal + finalVal;
        }
        else if (field.type == 'date') {
            retVal = retVal + new DatePipe('en-US').transform(value, 'MM-dd-yyyy');
        }
        else {
            retVal = retVal + value;
        }

        if (operator == 'in' || operator == 'not in') {
            retVal = retVal + ') ';
        }
        return retVal;
    }

    public convertToQueryString(query, fields) {
        return this._prepareQuery(query.rules, '', query.condition, fields);
    }
    */



    getCustomColumnNames(customLabelUrl: string) {
        return this.http.get(environment.apiUrl + customLabelUrl);
    }

    saveCustomColumnNames(customLabelUrl: string, customLabels: any) {
        return this.http.post(environment.apiUrl + customLabelUrl, customLabels);
    }
}