import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AccordionModule } from 'primeng/accordion';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { ToastModule } from 'primeng/toast';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { AppConfigComponent } from './app.config.component';
import { AppRightmenuComponent } from './app.rightmenu.component';
import { AppMenuComponent } from './app.menu.component';
import { AppMenuitemComponent } from './app.menuitem.component';
import { AppTopBarComponent } from './app.topbar.component';
import { AppSearchComponent } from './app.search.component';
import { AppFooterComponent } from './app.footer.component';
import { AppHelpComponent } from './pages/app.help.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { DashboardComponent } from './pages/dashboard.component';
import { BreadcrumbService } from './app.breadcrumb.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ConfigurationModule } from "../app/configuration/configuration.module";
import { MessageService } from 'primeng/api';
import { MenuService } from './app.menu.service';
import { BadgeModule } from 'primeng/badge';
import { TableModule } from 'primeng/table';
import { SharedModule } from './shared/shared.module';
import { TbpAutocompleteModule } from './_components/tbp-autocomplete/tbp-autocomplete.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CheckboxModule } from 'primeng/checkbox';
import { GlobalSerachComponenet } from './pages/global-search.component';
import { FileUploadModule } from 'primeng/fileupload';
import { TaskModule } from './task/task.module';
import { APIInterceptor } from './_helpers/api.interceptor';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    AccordionModule,
    BreadcrumbModule,
    ConfirmDialogModule,
    DialogModule,
    DropdownModule,
    ToastModule,
    ConfigurationModule,
    BrowserAnimationsModule,
    BadgeModule,
    TableModule,
    SharedModule,
    TbpAutocompleteModule,
    ReactiveFormsModule,
    AutoCompleteModule,
    EditorModule,
    CalendarModule,
    InputTextModule,
    ProgressSpinnerModule,
    CheckboxModule,
    FileUploadModule,
    TaskModule
  ],
  declarations: [
    AppComponent,
    AppMainComponent,
    AppRightmenuComponent,
    AppMenuComponent,
    AppMenuitemComponent,
    AppConfigComponent,
    AppTopBarComponent,
    AppSearchComponent,
    AppFooterComponent,
    AppHelpComponent,
    AppNotfoundComponent,
    AppErrorComponent,
    AppAccessdeniedComponent,
    DashboardComponent,
    GlobalSerachComponenet,

  ],
  providers: [
    MessageService, MenuService, BreadcrumbService, { provide: HTTP_INTERCEPTORS, useClass: APIInterceptor, multi: true },
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
  ],
  bootstrap: [AppComponent],
  exports: [
  ]
})
export class AppModule {
}
