import { OverlayPanelModule } from 'primeng/overlaypanel';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TbpColumnChooserComponent } from './tbp-column-chooser.component';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    OverlayPanelModule,
    ButtonModule,
    CheckboxModule,
    FormsModule
  ],
  declarations: [TbpColumnChooserComponent],
  exports:[TbpColumnChooserComponent]
})
export class TbpColumnChooserModule { }
