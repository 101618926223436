import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Pipe({ name: 'buysideobjective' })
export class BuySidePrimaryObjective implements PipeTransform {
    constructor(private _sanitizer: DomSanitizer) { }  
    transform(value: any, prefix?: string): SafeHtml {
        let retValue = '';
        if(value.primaryObjectives)
        {
            if(value.primaryObjectives.strategicBuyout)
            {
                retValue += '<span class="block">Strategic Buyout</span>'
            }
            let peBuyoutValue = "";
            let peBuyout = value.primaryObjectives.peBuyout;
            let sepearator = "";
            if(peBuyout.tuckin)
            {
                peBuyoutValue = "Tuck-in (Majority or 100%)";
                sepearator = "; ";
            }
            if(peBuyout.platform)
            {
                peBuyoutValue = peBuyoutValue + sepearator + "Platform";
            } 
            if(peBuyoutValue != '')
                retValue += '<span class="block">'+ 'PE Buyout - [' + peBuyoutValue + ']</span>'

            let equity = value.primaryObjectives.investment.equity;
            let eqtVal = "";
            sepearator = "";
            if(equity.angelInvestment)
            {
                eqtVal = eqtVal + "Angel Investment";
                sepearator = "; ";
            }
            if(equity.growthEquity)
            {
                eqtVal = eqtVal + sepearator + "Growth Equity";
                sepearator = "; ";
            }
            if(equity.minorityRecap)
            {
                eqtVal = eqtVal + sepearator + "Minority Recap";
                sepearator = "; ";
            }
            if(equity.ventureCapital)
            {
                eqtVal = eqtVal + sepearator +  "Venture Capital";
                sepearator = "; ";
            }
            if(eqtVal != '')
                retValue += '<span class="block">'+ 'Investment (Equity) - [' + eqtVal + ']</span>'
            
            let debt = value.primaryObjectives.investment.debt;
            let debtVal = "";
            sepearator = "";
            if(debt.arFinancing)
            {
                debtVal = debtVal + "A/R Financing";
                sepearator = "; ";
            }
            if(debt.juniorDebt)
            {
                debtVal = debtVal + sepearator + "Junior Debt";
                sepearator = "; ";
            }
            if(debt.mezzanineFinancing)
            {
                debtVal = debtVal + sepearator +  "Mezannine Financing";
                sepearator = "; ";
            }
            if(debt.seniorDebt)
            {
                debtVal = debtVal + sepearator + "Senior Debt";
                sepearator = "; ";
            }
            if(debtVal != '')
                retValue += '<span class="block">'+ 'Investment (Debt) - [' + debtVal + ']</span>'  
            
        }
  
        return this._sanitizer.bypassSecurityTrustHtml(retValue);
    }
}