import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { MenuModule } from 'primeng/menu';
import { TableModule } from 'primeng/table';
import { PanelModule } from 'primeng/panel';
import { ToastModule } from 'primeng/toast';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadModule } from 'primeng/fileupload';
import { NoteEditComponent } from './note-edit.component';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { NoteListComponent } from './note-list.component';
import { TbpAutocompleteModule } from '../_components/tbp-autocomplete/tbp-autocomplete.module';
import { NoteRoutingModule } from './note-routing.module';

@NgModule({
    declarations: [NoteEditComponent, NoteListComponent],
    imports: [
        CommonModule,
        DialogModule,
        DropdownModule,
        FormsModule,
        InputTextModule,
        ButtonModule,
        InputTextareaModule,
        ReactiveFormsModule,
        CalendarModule,
        AutoCompleteModule,
        ToastModule,
        PanelModule,
        TableModule,
        MenuModule,
        FileUploadModule,
        TbpAutocompleteModule,
        EditorModule,
        NoteRoutingModule,
        
    ],
    exports: [
        NoteEditComponent,
        NoteListComponent
    ],
    providers: [
        { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
    ]
})
export class NoteModule { }
