import { Pipe, PipeTransform } from '@angular/core';


@Pipe({ name: 'address' })
export class AddressPipe implements PipeTransform {
    transform(value: number, prefix?: string): string {
        let prefixToUse = '';
        if (prefix) {
            prefixToUse = prefix;
        }
        let street = value['address' + prefix] || '';
        let city = value['city' + prefix] || '';
        let state = value['state' + prefix] || '';
        let postalCode = value['postalCode' + prefix] || '';
        let country = value['country' + prefix] || '';
        if (!(country instanceof String)) {
            country = country.value || '';
        }
        if (street != '') {
            street = street + ((city != '' || state != '' || country != '' || postalCode != '') ? '<br/>' : '');
        }
        if (city != '') {
            street = street + city + ((state != '' || country != '' || postalCode != '') ? ', ' : '');
        }
        if (state != '') {
            street = street + state + ((country != '' || postalCode != '') ? ', ' : '');
        }
        if (country != '') {
            street = street + country + ((postalCode != '') ? ' - ' : '');
        }
        if (postalCode != '') {
            street = street + postalCode;
        }

        return street;
    }
}