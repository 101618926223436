import { Component, OnInit, ViewChild } from '@angular/core';
import { UserActivityService } from './users-activity.service';
import { BreadcrumbService } from '../app.breadcrumb.service';
import { UserActivity } from '../_models/users-activity';
import { LazyLoadEvent, MenuItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { AppUtil, SearchHeler } from '../_helpers';
import { FieldInfo, makeFieldInfo } from '../_models';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'users-activity',
  templateUrl: './users-activity.component.html',
  styleUrls: ['./users-activity.component.scss'],
})
export class UsersActivityComponent implements OnInit {
  @ViewChild('dt') table: Table;
  userItems: MenuItem[];
  currentMenuData: any;

  term: string;
  activities: UserActivity;
  activity: any;
  loading: boolean = false;
  totalRecords: number;
  selectedUser: any;
  users: any[] = [];
  filterdUsers: any[];
  fromDate: Date;
  fieldInfo: FieldInfo[];
  toDate: Date;
  pageId: number;
  selectedColumns: any[];
  searchUrl: string = "/user-activity";
  searchTerm: string | null = null;
  nextSearchAfter: number | null = null;
  nextFieldId: number | null = null;
  firstValue: number | null = null;
  searchAfterStack: { searchAfter: number; fieldId: number }[] = [];
  constructor(private userActivityService: UserActivityService, public searchHelper: SearchHeler,
    private route: ActivatedRoute, private breadcrumbService: BreadcrumbService,
    public appUtil: AppUtil,
  ) {
    this.breadcrumbService.setItems([{ label: 'Users Activity' }]);

    let tmpFieldInfo: FieldInfo[] = [
      makeFieldInfo({ property: "entityName", label: "Entity Name", type: "string", searchType: "text", forSearchTermQuery: true, visible: "A", columnIndex: 1 }),
      makeFieldInfo({ property: "description", label: "Description", type: "string", searchType: "text", sortable: false, visible: "A", columnIndex: 3 }),
      makeFieldInfo({ property: "type", label: "Type", type: "string", searchType: "text", columnIndex: 2, visible: "T" }),
      makeFieldInfo({ property: "activityTime", label: "Activity Time", type: "date", searchType: "date", visible: "A", columnIndex: 4 }),
      makeFieldInfo({ property: "userName", label: "User Name", type: "string", searchType: "text", visible: "T", columnIndex: 5 })
    ];
    this.fieldInfo = tmpFieldInfo;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.searchTerm = params['searchTerm'] || null;

    });
  }
  setCurrentItem(activity) {
    let items = [
      {
        label: 'Delete',
        icon: 'pi pi-trash',
        command: (event) => {
          console.log("to be deletd...");

        }
      }
    ];
    this.currentMenuData = activity;
    this.userItems = items;
  }


  _search(e) {
    let searchParams = this.searchHelper.prepareSearchParams(
      e.searchTerm,
      e.filter,
      e.sortField,
      e.sortOrder,
      e.first,
      e.rows,
      this.fieldInfo
    );
    this.loading = true;

    if (this.searchTerm != '' || this.searchTerm != null) {
      searchParams['query'] = this.searchTerm;
    }
    if (!this.appUtil.getPagination(this.totalRecords)) {
      let isGoingForward = e.first > this.firstValue;
      if (isGoingForward && this.term == null && e.first - this.firstValue == e.rows) {
        if (this.searchAfterStack.length === 0 || this.searchAfterStack[this.searchAfterStack.length - 1].searchAfter !== this.nextSearchAfter) {
          this.searchAfterStack.push({ searchAfter: this.nextSearchAfter, fieldId: this.nextFieldId });
        }
        searchParams['nextSearchAfter'] = this.nextSearchAfter;
        searchParams['nextFieldId'] = this.nextFieldId;
      } else if (!isGoingForward && this.searchAfterStack.length > 0 && this.term == null && e.first - this.firstValue == e.rows) {
        this.searchAfterStack.pop();
        const prevSearchAfter = this.searchAfterStack.length > 0 ? this.searchAfterStack[this.searchAfterStack.length - 1] : null;
        if (prevSearchAfter) {
          searchParams['nextSearchAfter'] = prevSearchAfter.searchAfter;
          searchParams['nextFieldId'] = prevSearchAfter.fieldId;
        }
      }
      this.firstValue = e.first;
    }
    this.userActivityService.getAnyUserActivity(searchParams).subscribe(
      data => {
        let response = data as any;
        if (!this.appUtil.getPagination(response.totalRecords)) {
          this.totalRecords = environment.customTotalRecords;
        } else {
          this.totalRecords = response.totalRecords;
        }
        this.activities = response.records;
        this.nextSearchAfter = response.nextSearchAfter;
        this.nextFieldId = response.nextFieldId;
        this.loading = false;
      });
  }
  _updateSelectedColumns($event) {
    this.selectedColumns = $event;
  }
}