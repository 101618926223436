import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError, of, EMPTY } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { AccountService } from '../_services';
import { AuthUser } from '../_models';
import { state } from '@angular/animations';
import { Router, RouterStateSnapshot } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable()
export class APIInterceptor implements HttpInterceptor {
    constructor(private messageService: MessageService, private accountService: AccountService, private router: Router) { }

    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // if (this.accountService.getJwtToken()) {
        //     request = this.addToken(request, this.accountService.getJwtToken());
        // }
        request = this.setCredentials(request);

        return next.handle(request).pipe(catchError(error => {
            if (error instanceof HttpErrorResponse && (error.status === 401 || error.status === 500 || error.status === 409)) {
                return this.handle401Error(request, next, error);
            } else {
                if (error.status == 0) {
                    this.messageService.add({ key: 'notification', severity: 'error', summary: "Unable to contact server", });
                    return EMPTY;
                } else {
                    return throwError(error);
                }
            }
        }));
    }

    // private addToken(request: HttpRequest<any>, token: string) {
    //     return request.clone({
    //         setHeaders: {
    //             'Authorization': `Bearer ${token}`
    //         }
    //     });
    // }

    private setCredentials(request: HttpRequest<any>) {
        return request.clone({
            withCredentials: true
        });
    }
    private handle401Error(request: HttpRequest<any>, next: HttpHandler, error) {
        if (error.status === 500 || error.status === 409) {
            this.messageService.add({ key: 'notification', severity: 'error', summary: "Internal Server Error", });
        } 
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);
        
            return this.accountService.refreshToken().pipe(
                switchMap((user: AuthUser) => {
                    this.isRefreshing = false; 
                   this.refreshTokenSubject.next(null);
                    return next.handle(this.setCredentials(request));
                }));

        } else {
            this.accountService.logout();
            return next.handle(this.setCredentials(request));
        }
    }
}