import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DealService {

    constructor(private http: HttpClient) { }

    searchDeal(searchParams: any) {
            return this.http.post(environment.apiUrl + '/deals', searchParams);
    }

    addDeal(data) {
        return this.http.post(environment.apiUrl + '/deal', data);
    }

    updateDeal(data) {
        return this.http.put(environment.apiUrl + '/deal/' + data.uuid, data);
    }

    getDeal(uuid: string) {
        return this.http.get(environment.apiUrl + '/deal/' + uuid);
    }

    updateDealBuyer(dealuuid: string, data: any) {
        return this.http.post(environment.apiUrl + '/deal/' + dealuuid + '/buyer', data);
    }

    updateDealTarget(dealuuid: string, data: any) {
        return this.http.post(environment.apiUrl + '/deal/' + dealuuid + '/target', data);
    }

    updateDealLevel1(dealuuid: string, data: any) {
        return this.http.post(environment.apiUrl + '/deal/' + dealuuid + '/classifications/CATEGORY_LEVEL1', data);
    }

    updateDealLevel2(dealuuid: string, data: any) {
        return this.http.post(environment.apiUrl + '/deal/' + dealuuid + '/classifications/CATEGORY_LEVEL2', data);
    }

    updateDealLevel3(dealuuid: string, data: any) {
        return this.http.post(environment.apiUrl + '/deal/' + dealuuid + '/classifications/CATEGORY_LEVEL3', data);
    }

    updateDealVertical(dealuuid: string, data: any) {
        return this.http.post(environment.apiUrl + '/deal/' + dealuuid + '/classifications/VERTICAL', data);
    }

    updateDealEcosystem(dealuuid: string, data: any) {
        return this.http.post(environment.apiUrl + '/deal/' + dealuuid + '/classifications/ECOSYSTEM', data);
    }

    private _listners = new Subject<any>();

    listen(): Observable<any> {
        return this._listners.asObservable();
    }

    refershTable(filterBy: string) {
        this._listners.next(filterBy);
    }
           
    patchProspect(fieldsToBeUpdated:string[], data:any) {
        return this.http.patch(environment.apiUrl + '/deal/' + data.uuid , 
                {"fieldsToBeUpdated": fieldsToBeUpdated,"model": data});
    }

    deleteDeals(deals:any):Observable<any>{
        return this.http.delete(environment.apiUrl + '/deals', { params: { deals } });
    }

    downloadDeals(searchParams: any) {
        return this.http.post(environment.apiUrl + '/deals/download/xlsx', searchParams, {
            responseType: 'blob'
        });
    }

}
