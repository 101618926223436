import { Component, OnInit, ViewChild, Input, Type, Output, EventEmitter ,NgZone, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Table } from 'primeng/table';
import { DomHandler } from 'primeng/dom';
import { MenuItem, ConfirmationService, MessageService, LazyLoadEvent, PrimeNGConfig } from 'primeng/api';
import { BuySide } from '../_models/buyside';
import { BuysideService } from './buyside.service';
import { BreadcrumbService } from '../app.breadcrumb.service';
import { AccountService } from 'src/app/_services';
import { DataService } from '../_services/data.service';
import { AppUtil } from '../_helpers/app.util';
import { FieldInfo, makeFieldInfo } from '../_models';
import { SearchHeler } from '../_helpers';
import { log } from 'console';
import { UsersService } from '../users/users.service';
import { OrganizationService } from '../organization/organization.service';
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";

  
import { Subscription } from 'rxjs';

@Component({
    selector: 'buyside-list',
    templateUrl: './buyside-list.component.html',
    styleUrls: ['./buyside-list.component.scss'],
    encapsulation: ViewEncapsulation.None,  
    providers: [ConfirmationService]
})
export class BuysideListComponent implements OnInit {
    @ViewChild('cform') formElement;
    @ViewChild('dt') dataTable: Table;
    form: UntypedFormGroup;
    helpList: any = {BUYSIDE:[]};
    private subscriptions: Subscription = new Subscription();
    buysides: BuySide[];
    @Input() type: string;
    @ViewChild('dt') table: Table;
    @Output() onSelect = new EventEmitter<any>();
    selectedBuySide: BuySide[];
    loading: boolean;
    scrollTop: string;
    buysideItems: MenuItem[];
    totalRecords: number;
    rowStatus: { [s: string]: Object; } = {};
    noRecordMessages: string[];
    buyside: BuySide;
    term: string;
    managementPreference: any[];
    currentMenuData: any;
    loadingSpinner: boolean = false;
    classificationList: any = {
        CATEGORY_LEVEL1: [], CATEGORY_LEVEL2: [], CATEGORY_LEVEL3: [],
        VERTICAL: [], ECOSYSTEM: []
    };
    lookupList: any = { ACCOUNT_SOURCE: [] };
    showCheckbox: boolean = false;
    selectedColumns: any[];
    source: string;
    debt: any[];
    equity:any[];
    formsubmited: boolean = false;
    primaryObjectiveUuid: string;
    oldVal: any[] = [];
    pageSize: number;
    firstRow: number;
    sortField: string;
    sortOrder: number;
    organizationResults: any;
    primaryObjectivefield: string;
    sourceuuid: string;
    fieldInfo: FieldInfo[];
    searchUrl: string = '/buy-side/buyside';
    showAddDialog: boolean = false;
    editingMode: boolean = false;
    editingModes: { [key: string] : boolean}={};
    editingField: any ={}; 
buysideData:any;
    constructor(
        private router: Router,
        public appUtil: AppUtil,
        private dataService: DataService,
        private messageService: MessageService,
        private buysideService: BuysideService,
        private accountService: AccountService,
        private route: ActivatedRoute,
        public searchHelper: SearchHeler,
        private usersService: UsersService,
        private formBuilder: UntypedFormBuilder,
        private organizationService: OrganizationService,
        private confirmationService: ConfirmationService,
        private breadcrumbService: BreadcrumbService,
        private primengConfig: PrimeNGConfig,
        private ngZone: NgZone) {
        this.breadcrumbService.setItems([
            { label: 'Buy Side Listing' }
        ]);
        this.term = '';
        this.debt = [
            {value: 'A/R Financing'},
            {value: 'Junior Debt'},
            {value: 'Mezzanine Financing'},
            {value: 'Senior Debt'},
        ];
        this.equity = [
            {value: 'Growth Equity'},
            {value: 'Venture Capital'},
            {value: 'Angel Investment'},
            {value: 'Minority Recap'},
        ];
        this.managementPreference = [
            {value: 'Flexible'},
            {value: 'Management Can Exit'},
            {value: 'Management Should Stay'},
          ];
    }
    loadBuySideForm() {
        this.form = this.formBuilder.group({
            uuid: [''],
            isStrategicBuyout: [false],
            isPEBuyoutPlatform: [false],
            isPEBuyoutTuckin: [false],
            equityType: [],
            debtType: []
        });
    }
    ngOnInit(): void {
        this.loadBuySideForm(); 
        const storedScrollTop = localStorage.getItem('BUYSIDESCROLL');
        if (storedScrollTop) {
            window.scrollTo(0, parseInt(storedScrollTop));
        }
        window.addEventListener('scroll', this.saveScrollPosition);
        let message = this.dataService.getDataByKey('message');
        this.primengConfig.ripple = true;
        if (message != undefined) {
            setTimeout(() => { this.messageService.add({ severity: 'success', summary: 'Successful', detail: message, life: 3000 }); })
            this.dataService.setData('message', undefined);
        }
        this.route.params.subscribe(data => {
            this.source = data.source == '' ? null : data.source;
            this.sourceuuid = data.sourceuuid == '' ? null : data.sourceuuid;
            this.searchUrl = (data.source == '' ? '' : '/' + data.source) + (data.sourceuuid == '' ? '' : '/' + data.sourceuuid) + '/buysides/';
            this.showCheckbox = data.showCheckbox == 'true' ? true : false;
        })

        this.classificationList = this.route.snapshot.data['classificationList'];
        this.lookupList = this.route.snapshot.data['lookupList'];
        let tmpFieldInfo: FieldInfo[] = [
            makeFieldInfo({ property: "screenTitle", label: 'Screen Title', type: 'string', searchType: 'text', forSearchTermQuery: true, columnIndex: 1, visible: 'A', sortable: true, linkPrefix: '/buy-side/', linkSuffix: '/prospects/', linkType: "p" }),
            makeFieldInfo({ property: "externalHeadline", label: 'External Headline', type: 'string', searchType: 'text', columnIndex: 3, visible: 'A', sortable: true }),
            makeFieldInfo({ property: "primaryObjective", label: 'Primary Objective', type: 'boolean', searchType: 'boolean', searchable: false, columnIndex: 4, visible: 'A', sortable: false }),
            makeFieldInfo({ property: "targetRevenueLookup", label: 'Target Revenue ($m)', type: 'range', searchType: 'exact', valueProperty: "value", labelProperty: "value", searchOptions: this.lookupList.REVENUE, sortable: false, columnIndex: 12 }),
            makeFieldInfo({ property: "targetEbitdaLookup", label: 'Target EBITDA ($m)', type: 'range', searchType: 'exact', valueProperty: "value", labelProperty: "value", searchOptions: this.lookupList.EBITDA, sortable: false, columnIndex: 5 }),
            makeFieldInfo({ property: "dollorValueRangeLookup", label: 'Dollor Value For Investment ($m)', type: 'range', searchType: 'exact', valueProperty: "value", labelProperty: "value", searchOptions: this.lookupList.DOLLOR_VALUE_RANGE, sortable: false, columnIndex: 6 }),
            makeFieldInfo({ property: "level1", label: 'Level1', type: 'classification', searchType: 'exact', searchOptions: this.classificationList.CATEGORY_LEVEL1, sortable: false, columnIndex: 7 }),
            makeFieldInfo({ property: "level2", label: 'Level2', type: 'classification', searchType: 'exact', searchOptions: this.classificationList.CATEGORY_LEVEL2, sortable: false, columnIndex: 8 }),
            makeFieldInfo({ property: "level3", label: 'Level3', type: 'classification', searchType: 'exact', searchOptions: this.classificationList.CATEGORY_LEVEL3, sortable: false, columnIndex: 9 }),
            makeFieldInfo({ property: "vertical", label: 'Vertical', type: 'classification', searchType: 'exact', searchOptions: this.classificationList.VERTICAL, sortable: false, columnIndex: 10 }),
            makeFieldInfo({ property: "ecosystem", label: 'Ecosystem', type: 'classification', searchType: 'exact', searchOptions: this.classificationList.ECOSYSTEM, sortable: false, columnIndex: 11 }),
            makeFieldInfo({ property: "organization", label: 'Account', type: 'string', searchType: 'text', visible: 'A', sortable: false, linkPrefix: '/organization/', linkType: "s", columnIndex: 2 }),
            makeFieldInfo({ property: "contacts", label: 'Contacts', type: 'string', searchType: 'text', visible: 'F', sortable: false, linkPrefix: '/contact/', linkType: "s" }),
            makeFieldInfo({ property: "managementPreference", label: 'Management Preference', type: 'string', searchType: 'text', visible: 'F', sortable: false }),
            makeFieldInfo({ property: "turnaround", label: 'Turnaround', type: 'boolean', searchType: 'boolean', visible: 'F', sortable: false }),
            makeFieldInfo({ property: "strategicBuyout", label: 'Strategic Buyout', type: 'boolean', searchType: 'boolean', visible: 'H', sortable: false }),
            makeFieldInfo({ property: "tuckin", label: 'PE Buyout Tuckin', type: 'boolean', searchType: 'boolean', visible: 'H', sortable: false }),
            makeFieldInfo({ property: "platform", label: 'PE Buyout Platform', type: 'boolean', searchType: 'boolean', visible: 'H', sortable: false }),
            makeFieldInfo({ property: "arFinancing", label: 'A/R Financing', type: 'boolean', searchType: 'boolean', visible: 'H', sortable: false }),
            makeFieldInfo({ property: "juniorDebt", label: 'Junior Debt', type: 'boolean', searchType: 'boolean', visible: 'H', sortable: false }),
            makeFieldInfo({ property: "mezzanineFinancing", label: 'Mezzanine Financing', type: 'boolean', searchType: 'boolean', visible: 'H', sortable: false }),
            makeFieldInfo({ property: "seniorDebt", label: 'Senior Debt', type: 'boolean', searchType: 'boolean', visible: 'H', sortable: false }),
            makeFieldInfo({ property: "growthEquity", label: 'Growth Equity', type: 'boolean', searchType: 'boolean', visible: 'H', sortable: false }),
            makeFieldInfo({ property: "ventureCapital", label: 'Venture Capital', type: 'boolean', searchType: 'boolean', visible: 'H', sortable: false }),
            makeFieldInfo({ property: "angelInvestment", label: 'Angel Investment', type: 'boolean', searchType: 'boolean', visible: 'H', sortable: false }),
            makeFieldInfo({ property: "minorityRecap", label: 'Minority Recap', type: 'boolean', searchType: 'boolean', visible: 'H', sortable: false }),
            makeFieldInfo({ property: "transactionObjective", label: 'Transaction Objective Description', type: 'string', visible: 'F', searchType: 'text', sortable: false, searchable: false }),
            makeFieldInfo({ property: "companyDescription", label: 'Target Description', type: 'string', searchType: 'text', visible: 'F', sortable: false, searchable: false }),
            makeFieldInfo({ property: "lastModifiedDate", label: "Modification Date", type: "date", searchType: "date", visible: 'T' }),
            makeFieldInfo({ property: "createdDate", label: "Creation Date", type: "date", searchType: "date", visible: 'F' }),
            makeFieldInfo({ property: "relevance", label: "Relevance", type: "date", searchable: false, visible: 'H' }),
            makeFieldInfo({ property: "status", label: "Status", type: "enum", searchType: "exact", visible: 'F', searchable: false }),
        ]
        this.fieldInfo = tmpFieldInfo;
        this.loading = true;
        this.totalRecords = 0;
        this.buysides = [];
        this.currentMenuData = { id: '' };
        this.buysideItems = [];
    }

    get role() {
        return this.accountService.userValue.role;
    }

    search(e) {
        this.term = e.searchTerm;
        let searchParams = this.searchHelper.prepareSearchParams(e.searchTerm, e.filter, e.sortField, e.sortOrder, e.first, e.rows, this.fieldInfo);
        if (this.source != null && this.sourceuuid != null && !this.showCheckbox && this.source == 'contact') {
            searchParams['pageSize'] = -1;
            searchParams['rootRule']['rules'].push({ 'searchType': 'exact', 'field': 'contacts', 'operator': 'equals', 'value': this.sourceuuid });
            this.usersService.getBuySides(this.sourceuuid, searchParams).subscribe(
                data => {
                    let response = data as any;
                    this.totalRecords = response.totalRecords;
                    this.buysides = response.records;
                    this.loading = false;
                    this.appUtil.reinitializeShowMore();
                    
                });
            }
            else if(this.source != null && this.sourceuuid != null &&  !this.showCheckbox && this.source == 'organization'){    
                searchParams['pageSize'] = -1;
                searchParams['rootRule']['rules'].push({ 'searchType': 'exact', 'field': 'organization', 'operator': 'equals', 'value': this.sourceuuid });
                 this.organizationService.getBuySides(this.sourceuuid, searchParams).subscribe(
                  data => {
                    let response = data as any;
                    this.totalRecords = response.totalRecords;
                    this.buysides = response.records;
                    this.loading = false;
                    this.appUtil.reinitializeShowMore();
                });
        }
        else {
            this.buysideService.searchBuySides(searchParams).subscribe(
                data => {
                    let response = data as any;
                    this.totalRecords = response.totalRecords;
                    this.buysides = response.records;
                    this.loading = false;
                    this.appUtil.reinitializeShowMore();
                    if(e.searchTerm==null){
                    const localeCompareKey = localStorage.getItem('BUYSIDESCROLL');
                    if (localeCompareKey) {
                      this.ngZone.runOutsideAngular(() => {
                        setTimeout(() => {
                          window.scrollTo(0, parseInt(localeCompareKey));
                        }, 0);
                      });
                    }
                }
                });
        }

    }
    ngOnDestroy() {
        this.subscriptions.unsubscribe();
         window.removeEventListener('scroll', this.saveScrollPosition);
         localStorage.setItem('BUYSIDESCROLL', this.scrollTop); 
     }
     
     saveScrollPosition = (): void => {
         this.scrollTop = window.scrollY.toString();  
 
     }
    new() {
        this.router.navigate(['/buy-side/new']);
    }

    edit() {
        this.router.navigate(['/buy-side/edit/' + this.currentMenuData.uuid]);
    }

    setCurrentItem(buyside) {
        let items = [
            {
                label: 'Edit',
                icon: 'pi pi-plus',
                command: (event) => {
                    this.edit();
                }
            },
            {
                label: 'Delete',
                icon: 'pi pi-trash',
                command: (event) => {
                    let buysides = [];
                    buysides.push(buyside);
                    this.delete(buysides);
                }
            }
        ];
        this.currentMenuData = buyside;
        this.buysideItems = items;
    }

    confirmAdd() {
        this.showAddDialog = true;
    }
    addToSource() {
        this.dataService.setData("buyside-" + this.source + "-" + this.sourceuuid, this.selectedBuySide);
        let data = this.dataService.getDataByKey("buyside-contacts-" + this.sourceuuid);
        switch (this.source) {
            case "contacts":
                this.router.navigate(["/contact/" + this.sourceuuid]);
                break;
            case "organizations":
                this.router.navigate(["/organization/" + this.sourceuuid]);
                break;
        }
    }

    remove(contact) {
        this.selectedBuySide = this.appUtil.deleteItem(
            this.selectedBuySide,
            contact
        );
        if (this.selectedBuySide.length == 0) {
            this.showAddDialog = false;
        }
    }


    delete(buysides: any) {

        let buysideUuids = [];
        for (let i = 0; i < buysides.length; i++) {
            buysideUuids.push(buysides[i].uuid);
        }
        this.confirmationService.confirm({
            message: 'Are you sure you want to delete this Buyside Screen?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.loadingSpinner = true;
                this.buysideService.deleteBuysideScreens(buysideUuids).subscribe(
                    data => {
                        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Buy Side Deleted', life: 3000 });
                        this.search({ first: 0, rows: 25, sortField: 'screenTitle', sortOrder: 1 });
                        this.loadingSpinner = false;
                    },
                    error => {
                        this.loadingSpinner = false;
                        let errors = error.error;
                        if (errors.code && errors.code == 'VALIDATION_ERROR') {
                            this.messageService.add({ severity: 'error', summary: 'Error', detail: errors.subErrors[0].message, life: 3000 });

                        }
                    }
                )
            }
        });
    }

    disableNavigation(event: any) {
        if (event.key === "ArrowRight" || event.key === "Right"
            || event.key === "ArrowLeft" || event.key === "Left"
            || event.key === "ArrowUp" || event.key === "Up"
            || event.key === "ArrowDown" || event.key === "Down") {
            event.stopPropagation();
        }

    }

    initEdit($event) {
        this.primaryObjectiveUuid =$event.data.uuid;
        this.buysideService.getBuyside($event.data.uuid).subscribe(data => {
            this.modelToForm(data as any);
          })
        this.buysideData = $event.data;
        this.onSelect.emit($event.data);
        let oldValues = this.oldVal[$event.data.uuid];
      
        if (!oldValues) {
            oldValues = [];
            this.oldVal[$event.data.uuid] = oldValues;
        }
        
        if ([ 'level1', 'level2', 'level3', 'vertical', 'ecosystem' ].includes($event.field)) {
          oldValues[$event.field] = $event.data.classification[$event.field];
         
        
      } else {
        oldValues[$event.field] = $event.data[$event.field];
      }
      }
      editCancelled($event) {
        let oldValues = this.oldVal[$event.data.uuid];
        delete oldValues[$event.field];
      }
      
      editorAction(event, buyside: any, field: string, cancelled: boolean) {
        if (cancelled) {
            this.editCancelled({ field, data: buyside });
            location.reload();
        }
        else {
            this.editCompleted({ field, data: buyside });
        }
        DomHandler.removeClass(this.dataTable.editingCell, 'p-cell-editing');
        this.dataTable.editingCell = null;
        this.dataTable.editingCellData = null;
        this.dataTable.editingCellField = null;
        this.dataTable.unbindDocumentEditListener();
        event.preventDefault();
        event.cancelBubble = true;
        event.stopPropagation();
        this.appUtil.reinitializeShowMore();
      }
      splitAndCapitalize(field: string): string {
        return field
          .split(/(?=[A-Z])/)
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      }
      editCompleted($event) {
        let oldValues = this.oldVal[$event.data.uuid];
      
        let dataToPost  = { uuid: $event.data.uuid };

        if(($event.field === 'screenTitle' || $event.field === 'externalHeadline' || $event.field === 'level1') && $event.data[$event.field] === ""){
            const readableField = this.splitAndCapitalize($event.field);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: readableField+" is Required", life: 3000});
          this.reload(); 
          this.editingModes[$event.data.uuid] = false; 
          return; 
        }
            
        if ($event.field == 'level1' || $event.field == 'level2' || $event.field == 'level3' || $event.field == 'vertical' || $event.field == 'ecosystem' ) {
            dataToPost['classification'] = $event.data.classification ? $event.data.classification: null; 
            $event.field = 'classification';
        } 
         else { 
             dataToPost[$event.field] = $event.data[$event.field];  
        }
        if (Array.isArray($event.data[$event.field]) && $event.data[$event.field].length > 0) {
           
            dataToPost[$event.field] = $event.data[$event.field].map(item => item.uuid);
        }
      
        if($event.field == 'targetEbitda' || $event.field == 'dollorValueRange' || $event.field == 'targetRevenue'){
          
            dataToPost[$event.field] = $event.data[$event.field]?.value;
        }
        if($event.field == 'organization'){
            
            dataToPost[$event.field] = $event.data[$event.field].uuid;
        }
       if(oldValues[$event.field] !== $event.data[$event.field]){
        this.buysideService.updateBuysideList([$event.field], dataToPost).subscribe(
            data => {
            this.loading = false; 
            this.editingModes[$event.data.uuid] = false;  
         },
        error => {
             $event.data[$event.field] = oldValues[$event.field]
                          
            } )
        }
          delete oldValues[$event.field];
            }
            reload() {
                let e: LazyLoadEvent  = { first: this.firstRow, rows: this.pageSize, sortField: this.sortField, sortOrder: 0 };
                    this.search(e);   
                    
              }  
      
    getOutputOrganization( organization: any) {
        if (organization && typeof organization === 'object' ) {
            return organization.name;
        }
        return organization;
      }
      getOutput( value: any) {
        if (value && typeof value === 'object' ) {
            return value.value;
        }
        return value;
      }
      
      toggleEditingMode(buysideUuid: string) {
        this.editingModes[buysideUuid] = !this.editingModes[buysideUuid];
      }
      isEditing( buysideUuid: string): boolean{
        return this.editingModes[buysideUuid] || false;

      }

    _updateSelectedColumns($event) {
        this.selectedColumns = $event;
    }
    getOptionsForProperty(property: string): any[] {
        if (property === 'level1') {
          return this.classificationList.CATEGORY_LEVEL1;
        } else if (property === 'level2') {
          return this.classificationList.CATEGORY_LEVEL2;
        }  else if (property === 'level3') {
            return this.classificationList.CATEGORY_LEVEL3;
        } else if (property === 'vertical') {
            return this.classificationList.VERTICAL;
        } else if (property === 'ecosystem') {
        return this.classificationList.ECOSYSTEM;
        }
       
      }
      getType(columnName: string){
        if(columnName === 'level2'){
            return "CATEGORY_LEVEL2";
        }
        else if(columnName === 'level3'){
            return  "CATEGORY_LEVEL3";
        }
        else if(columnName === 'vertical'){
            return  "VERTICAL";
        }
       else if(columnName === 'ecosystem'){
            return  "ECOSYSTEM";
        }
    }
      searchOrganizations(event) {
        this.organizationService.lookupOrganization(event.query).subscribe(data => {
            this.organizationResults = data as any;
        });
    }
    closeDialog() {
        this.reload();
      } 
    get f() { return this.form.controls; }
    modelToForm(model) {
        let debtTypeValues = [];
        if(model.primaryObjectives.investment.debt.arFinancing)
        {
            debtTypeValues.push(this.appUtil.findItemInList(this.debt, 'A/R Financing', 'value').value)
        }
        if(model.primaryObjectives.investment.debt.juniorDebt)
        {
            debtTypeValues.push(this.appUtil.findItemInList(this.debt, 'Junior Debt', 'value').value)
        }
        if(model.primaryObjectives.investment.debt.mezzanineFinancing)
        {
            debtTypeValues.push(this.appUtil.findItemInList(this.debt, 'Mezzanine Financing', 'value').value)
        }
        if(model.primaryObjectives.investment.debt.seniorDebt)
        {
            debtTypeValues.push(this.appUtil.findItemInList(this.debt, 'Senior Debt', 'value').value)
        }
        let equityTypeValues = [];
        if(model.primaryObjectives.investment.equity.growthEquity)
        {
            equityTypeValues.push(this.appUtil.findItemInList(this.equity, 'Growth Equity', 'value').value)
        }
        if(model.primaryObjectives.investment.equity.ventureCapital)
        {
            equityTypeValues.push(this.appUtil.findItemInList(this.equity, 'Venture Capital', 'value').value)
        }
        if(model.primaryObjectives.investment.equity.angelInvestment)
        {
            equityTypeValues.push(this.appUtil.findItemInList(this.equity, 'Angel Investment', 'value').value)
        }
        if(model.primaryObjectives.investment.equity.minorityRecap)
        {
            equityTypeValues.push(this.appUtil.findItemInList(this.equity, 'Minority Recap', 'value').value)
        }
      
        this.form.setValue({
            uuid: model.uuid,
            isPEBuyoutPlatform: model.primaryObjectives.peBuyout.platform,
            isPEBuyoutTuckin: model.primaryObjectives.peBuyout.tuckin,
            equityType: equityTypeValues,
            debtType: debtTypeValues,
            isStrategicBuyout: model.primaryObjectives.strategicBuyout
        });
      }
      validatePrimaryObjective(event: any) {
        let error = { 'message': 'Primary objective is required.' };
        if (this.f.isStrategicBuyout.value || this.f.isPEBuyoutPlatform.value || this.f.isPEBuyoutTuckin.value
            || (this.f.equityType.value !=null && this.f.equityType.value.length > 0)
            || (this.f.debtType.value !=null && this.f.debtType.value.length > 0))
        {
            error = null;
        }
        
        this.appUtil.setError(this.form, 'isStrategicBuyout', error);
        this.appUtil.setError(this.form, 'isPEBuyoutPlatform', error);
        this.appUtil.setError(this.form, 'isPEBuyoutTuckin', error);
        this.appUtil.setError(this.form, 'equityType', error);
        this.appUtil.setError(this.form, 'debtType', error);
        this.formsubmited = true;
      }
      
      nextStep() {
        let hasError:boolean = false;
       
            this.validatePrimaryObjective(null);
            hasError = this.f.isStrategicBuyout.invalid|| this.f.isPEBuyoutPlatform.invalid 
              || this.f.isPEBuyoutTuckin.invalid || this.f.debtType.invalid || this.f.equityType.invalid 
      
       
      
      }
      handleError(elemId) {
        this.messageService.add({ severity: 'error', summary: 'Form Errors', detail: "Please select at least one primary objective", life: 3000 });
        let invalidControl = this.formElement.nativeElement.querySelector('.ng-invalid');
      
        if (invalidControl) {
            invalidControl.focus();
        }
        else if (elemId != null) {
            invalidControl = this.formElement.nativeElement.querySelector('#' + elemId);
            if (invalidControl) {
                invalidControl.focus();
            }
        }
      }
      prepareModel(fieldName:string,  value:any){
        this.nextStep();
        if (this.form.invalid) {
          this.handleError(null);
          return;
      }
        let arFinancing =  this.appUtil.findItemInList(this.f.debtType.value, this.appUtil.findItemInList(this.debt, 'A/R Financing', 'value').value)?true:false;
        let juniorDebt =  this.appUtil.findItemInList(this.f.debtType.value, this.appUtil.findItemInList(this.debt, 'Junior Debt', 'value').value)?true:false;
        let mezzanineFinancing =  this.appUtil.findItemInList(this.f.debtType.value, this.appUtil.findItemInList(this.debt, 'Mezzanine Financing', 'value').value)?true:false;
        let seniorDebt =  this.appUtil.findItemInList(this.f.debtType.value, this.appUtil.findItemInList(this.debt, 'Senior Debt', 'value').value)?true:false;
        let growthEquity =  this.appUtil.findItemInList(this.f.equityType.value, this.appUtil.findItemInList(this.equity, 'Growth Equity', 'value').value)?true:false;
        let ventureCapital =  this.appUtil.findItemInList(this.f.equityType.value, this.appUtil.findItemInList(this.equity, 'Venture Capital', 'value').value)?true:false;
        let angelInvestment =  this.appUtil.findItemInList(this.f.equityType.value, this.appUtil.findItemInList(this.equity, 'Angel Investment', 'value').value)?true:false;
        let minorityRecap =  this.appUtil.findItemInList(this.f.equityType.value, this.appUtil.findItemInList(this.equity, 'Minority Recap', 'value').value)?true:false;
      
        let debt = {arFinancing, juniorDebt,mezzanineFinancing,seniorDebt}
        let equity = {growthEquity, ventureCapital, angelInvestment, minorityRecap}
        let peBuyout = { tuckin: this.f.isPEBuyoutTuckin.value, platform : this.f.isPEBuyoutPlatform.value};
      
        let dataToPost :any= { uuid: this.primaryObjectiveUuid, primaryObjectives: {strategicBuyout: this.f.isStrategicBuyout.value, peBuyout, investment: {debt, equity}} };
      
        this.buysideService.updateBuysideList([fieldName], dataToPost).subscribe(
          data => {
              this.loading = false;  
              this.reload();
          })
          
      }

}
