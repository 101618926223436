import { Component, OnInit, ViewChild, Input, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import { MessageService, ConfirmationService } from 'primeng/api';
import { BuysideService } from './buyside.service';
import { DataService } from "../_services/data.service";
import { Location } from '@angular/common';
import { BreadcrumbService } from '../app.breadcrumb.service';
import { BuySide } from '../_models';

@Component({
  selector: 'buyside-blind-profile',
  templateUrl: './buyside-blind-profile.component.html',
  styleUrls: ['./buyside-blind-profile.component.scss'],
  styles: [`
:host ::ng-deep .p-divider.p-divider-vertical::before {
      border-left: 2px #dee2e6 dashed !important;
  }
:host ::ng-deep .p-inplace .p-inplace-display {
      display: block !important;
  }
`],
  providers: [MessageService, ConfirmationService]
})
export class BuysideBlindProfileComponent implements OnInit {

  @ViewChild('cform') formElement;
  form: UntypedFormGroup;
  loadingSpinner: boolean = false;
  submitted = false;
  @Input() screenId: string;
  blindProfileId: string;
  @Input() isEdit: boolean = false;
  @Input() buyside: BuySide;
  formInitialValue: any = null;
  constructor(
    private buysideService: BuysideService,
    private messageService: MessageService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private dataService: DataService,
    private breadcrumbService: BreadcrumbService,
    private router: Router,
    private location: Location
  ) {
      this.loadBlindProfileForm();
      this.formInitialValue = this.form.value;
    }

  ngOnInit(): void {
  }
  
  ngOnChanges(changes: SimpleChanges)
  {
      if(this.isEdit)
      {
          this.breadcrumbService.setItems([{ label: 'Edit Blind Profile' }]);
          this.loadingSpinner = true;
          this.buysideService.getBlindProfile(this.screenId).subscribe(data => {
              let buyside = data as any;
              this.blindProfileId = buyside.uuid;
              this.modelToForm(buyside);
          }).add(()=>{
              this.loadingSpinner = false;
          });
      }
      else {
          this.blindProfileId = null;
          this.breadcrumbService.setItems([{ label: 'New Blind Profile' }]);
          this.formInitialValue = { title: this.buyside.screenTitle, headline: this.buyside.externalHeadline};
          this.form.reset(this.formInitialValue);
      }
  }

  loadBlindProfileForm() {
    this.form = this.formBuilder.group({
      title: [],
      headline: [],
      capabilities: [''],
      customers: [''],
      business: [''],
      businessBuyer: [''],
      investmentStrategy: ['']
      });
  }

modelToForm(model) {
  this.form.setValue({
      title: model.title,
      headline: model.headline,
      capabilities: model.capabilities,
      customers: model.customers,
      business: model.business,
      businessBuyer: model.businessBuyer,
      investmentStrategy: model.investmentStrategy
  });
}

  save() {
        this.submitted = true;
        if (this.form.invalid) {
            this.handleError(null);
            return;
        }
        let { title, headline, capabilities, customers, business, businessBuyer, investmentStrategy} = this.form.value;
        let dataToPost: any = { title, headline, capabilities, customers, business, businessBuyer, investmentStrategy};
        this.loadingSpinner = true;
        let apiToCall: any;
        if (this.isEdit) {
            apiToCall = this.buysideService.UpdateBlindProfile(this.screenId, this.blindProfileId, dataToPost);
        } else {
            apiToCall = this.buysideService.addBlindProfile(this.screenId, dataToPost);
        }
        apiToCall.subscribe(
            data => {
                let detail = 'Buyside Blind Profile Created';
                if (this.isEdit) {
                    detail = 'Buyside Blind Profile Updated';
                }
                this.dataService.setData('message', detail);
                this.router.navigate(['/buy-side/' + this.screenId + '/blind-profile']);
            },
            error => {
                let errors = error.error;
                if (errors.code && errors.code == 'VALIDATION_ERROR') {
                    for (var i = 0; i < errors.subErrors.length; i++) {
                        this.f[errors.subErrors[i].field].setErrors({ 'message': errors.subErrors[i].message });
                    }
                    this.handleError(errors.subErrors[0].field);
                }
            }
        ).add(() =>{
            this.loadingSpinner = false;
        });
    }

    get f() {
       return this.form.controls;
    }

    handleError(elemId) {
      this.messageService.add({ severity: 'error', summary: 'Form Errors', detail: "Form has errors. Please fix them first", life: 3000 });
      let invalidControl = this.formElement.nativeElement.querySelector('.ng-invalid');

      if (invalidControl) {
          invalidControl.focus();
        }
      else if (elemId != null) {
          invalidControl = this.formElement.nativeElement.querySelector('#' + elemId);
          if (invalidControl) {
              invalidControl.focus();
            }
        }
    }

  cancel() {
    this.router.navigate(['/buy-side/' + this.screenId + '/blind-profile']);
  }

}
