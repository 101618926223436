import { AutoCompleteModule } from 'primeng/autocomplete';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ToolbarModule } from 'primeng/toolbar';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SplitButtonModule } from 'primeng/splitbutton';
import { MenuModule } from 'primeng/menu';
import { TabViewModule } from 'primeng/tabview';
import { TreeTableModule } from 'primeng/treetable';
import { DialogModule } from 'primeng/dialog';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { ClassificationRoutingModule } from './classification-routing.module';
import { ClassificationListComponent } from './classification.list.component';
import { ClassificationAddComponent } from './classification.add.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    declarations: [ClassificationListComponent, ClassificationAddComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        ClassificationRoutingModule,
        TableModule,
        ButtonModule,
        OverlayPanelModule,
        ToolbarModule,
        FileUploadModule,
        InputTextModule,
        DropdownModule,
        InputTextareaModule,
        SplitButtonModule,
        MenuModule,
        TabViewModule,
        TreeTableModule,
        DialogModule,
        MessageModule,
        MessagesModule,
        ConfirmDialogModule,
        ToastModule,
        ProgressSpinnerModule,
        AutoCompleteModule,
        RadioButtonModule,
        CheckboxModule,
        SharedModule
        
    ],
    exports: [
        ClassificationAddComponent,
    ]
})
export class ClassificationModule { }
