import { RippleModule } from 'primeng/ripple';
import { TooltipModule } from 'primeng/tooltip';
import { CalendarModule } from 'primeng/calendar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CommonModule } from '@angular/common';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ToolbarModule } from 'primeng/toolbar';
import { NgModule } from "@angular/core";
import { TbpSearchComponent } from "./tbp-search.component";
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import {FieldsetModule} from 'primeng/fieldset';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
    declarations: [
        TbpSearchComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ToolbarModule,
        DropdownModule,
        OverlayPanelModule,
        MultiSelectModule,
        DividerModule,
        InputTextModule,
        ButtonModule,
        CheckboxModule,
        RadioButtonModule,
        CalendarModule,
        TooltipModule,
        DragDropModule,
        FieldsetModule,
        RippleModule
    ],
    exports: [
        TbpSearchComponent,
    ]
})
export class TbpSearchModule { }
