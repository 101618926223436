import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BreadcrumbService } from '../app.breadcrumb.service';
import { LazyLoadEvent, MenuItem } from 'primeng/api';
import { User, Organization, Spotlight, Deal } from './../_models/index';
import { UsersService } from './../users/users.service';
import { OrganizationService } from './../organization/organization.service';
import { DealService } from './../deal/deal.service';
import { AccountService } from 'src/app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { AppUtil } from '../_helpers/app.util';
@Component({
    selector: 'global-search',
    templateUrl: './global-search.component.html',
    styleUrls: ['./global-search.component.scss'],
    encapsulation: ViewEncapsulation.None  
})

export class GlobalSerachComponenet implements OnInit {

    contacts: User[];
    selectedContacts: User[] = [];
    contactLoading: boolean = true;
    contactTotalRecords: number = 0;

    organizations: Organization[];
    selectedOrg: Organization[] = [];
    orgLoading: boolean = true;
    orgTotalRecords: number = 0;

    deals: Deal[];
    selectedDeal: Deal[] = [];
    dealLoading: boolean = true;
    dealTotalRecords: number = 0;
    term: string ='';
    highlightTerm: string = '';
    constructor(private router: Router, public appUtil: AppUtil, private route: ActivatedRoute, private breadcrumbService: BreadcrumbService, private usersService: UsersService,
        private organizationService: OrganizationService,
        private dealService: DealService, public accountService: AccountService,) {
        route.queryParams.subscribe(term => {
            this.term = term.term;
            this.loadContacts();
            this.loadOrganizations();
            this.loadDeals();
            this.breadcrumbService.setItems([{ label: 'search String: ' + this.term }]);
        });
    }
    ngOnInit() {

    }
  
    makeWildCard(tempTerm:string):string{
        if (!/\s/.test(tempTerm)) {
            tempTerm = `${tempTerm}*`;
        }
        return tempTerm;
    }
    loadContacts(e: LazyLoadEvent = { first: 0, rows: 5, sortField: 'name', sortOrder: 1 }) {
        this.highlightTerm =    this.term;
        var searchParams = {};
        searchParams['page'] = (e.first / e.rows);
        searchParams['pageSize'] = e.rows;
        var sortBy = []
        sortBy[sortBy.length] = { "property": "id", "direction": "DESC" };
        searchParams['sortDetails'] = sortBy;
        var tempTerm = this.term;
        tempTerm =  this.makeWildCard(tempTerm);
        if (this.term != '') {
            searchParams['query'] = tempTerm;
        }
        this.usersService.searchContact(searchParams).subscribe(
            data => {
                let response = data as any;
                this.contactTotalRecords = response.totalRecords;
                this.contacts = response.records;
                this.contactLoading = false;
                this.appUtil.reinitializeShowMore();
            });
    }

    loadOrganizations(e: LazyLoadEvent = { first: 0, rows: 5, sortField: 'name', sortOrder: 1 }) {
        this.highlightTerm =    this.term;   
        var searchParams = {};
        searchParams['page'] = (e.first / e.rows);
        searchParams['pageSize'] = e.rows;
        var sortBy = []
        sortBy[sortBy.length] = { "property": "id", "direction": "DESC" };
        searchParams['sortDetails'] = sortBy;
        var tempTerm = this.term;
        tempTerm =  this.makeWildCard(tempTerm);
        if (this.term != '') {
            searchParams['query'] = tempTerm;
        }
        this.organizationService.searchOrganization(searchParams).subscribe(
            data => {
                let response = data as any;
                this.orgTotalRecords = response.totalRecords;
                this.organizations = response.records;
                this.orgLoading = false;
                this.appUtil.reinitializeShowMore();
            });
    }

    loadDeals(e: LazyLoadEvent = { first: 0, rows: 5, sortField: 'name', sortOrder: 1 }) {
        this.highlightTerm =    this.term;
        var searchParams = {};
        searchParams['page'] = (e.first / e.rows);
        searchParams['pageSize'] = e.rows;
        var sortBy = []
        sortBy[sortBy.length] = { "property": "id", "direction": "DESC" };
        searchParams['sortDetails'] = sortBy;
        var tempTerm = this.term;
        tempTerm =  this.makeWildCard(tempTerm);
        if (this.term != '') {
            searchParams['query'] = tempTerm;
        }
        this.dealService.searchDeal(searchParams).subscribe(
            data => {
                let response = data as any;
                this.dealTotalRecords = response.totalRecords;
                this.deals = response.records;
                this.dealLoading = false;
                this.appUtil.reinitializeShowMore();
            });
    }

    get role() {
        return this.accountService.userValue.role;
    }

    ViewMore(type: string) {
        if (type === "Account") {
            this.router.navigate(['organization'], { queryParams: { search_term: this.term } });
        }
        else if (type === "Contact") {
            this.router.navigate(['contacts'], { queryParams: { search_term: this.term } });
        }
        else if (type === "Deal") {
            this.router.navigate(['deals'], { queryParams: { search_term: this.term } });
        }
    }

}