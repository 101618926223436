<div class="grid table-demo">
    <div class="col-12">
        <div class="card">
            <p-table [autoLayout]="true" #dt [value]="mailLogs" dataKey="uuid" [lazy]="true"
                (onLazyLoad)="_search($event)"
                styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm" [rowHover]="true"
                [rows]="25" [paginator]="true" [rowsPerPageOptions]="[15,25,50,75,100,{ showAll: 'All' }]"
                [loading]="loading" [alwaysShowPaginator]="false" [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [totalRecords]="totalRecords" sortField="receivedOn">
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="email">Email
                            <p-sortIcon field="email"></p-sortIcon>
                        </th>
                        <th pSortableColumn="name">Name
                            <p-sortIcon field="name"></p-sortIcon>
                        </th>
                        <th pSortableColumn="subject">Subject
                            <p-sortIcon field="subject"></p-sortIcon>
                        </th>
                        <th>Content</th>
                        <th pSortableColumn="receivedOn">Received On
                            <p-sortIcon field="receivedOn"></p-sortIcon>
                        </th>
                        <th>Attachments</th>
                        <th>Action</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-mailog>
                    <tr>
                        <td class="wrap lowercase">
                            <span class="p-column-title">Email</span>
                            {{mailog.email}}
                        </td>
                        <td class="wrap capitalize">
                            <span class="p-column-title">Name</span>
                            {{mailog.name}}
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Subject</span>
                            {{mailog.emailLog.subject}}
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Content</span>
                            <span class="limitedText limitText">
                                <p innerHtml="{{mailog.emailLog.content}}"></p>
                            </span>
                            <a href="javascript:" (click)="appUtil.showHideClampedContent($event)"
                                class="moreless hidden ml-2 p-button-danger"
                                style="float:right;display:block;">More</a>
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Received On</span>
                            {{mailog.emailLog.receivedOn | date:'M-d-yyyy h:mm a'}}
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Attachments</span>
                            <div *ngFor="let attachment of mailog.emailLog.attachments">
                                <a href="{{attachment.url}}" (click)="openDocument($event)"
                                    *ngIf="attachment != null">{{attachment.nameWithExtension}}</a>
                            </div>
                        </td>
                        <td>
                            <span class="p-column-title">Action</span>
                            <button type="button" pButton icon="pi pi-list"
                                (click)="setCurrentItem(mailog); menu.toggle($event)"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="7">
                            <span>Hurrah! There are no emails from unrecognized contacts</span>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
<p-menu #menu [popup]="true" [model]="menuItems"></p-menu>
<user-popup-add [(show)]="showForm" [contactData]="selectedLog" (afterSave)="refreshData($event)"></user-popup-add>