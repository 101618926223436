<div class="grid table-demo">
    <div class="col-12">
        <div class="card divider-card">
            <p-tabView  (onChange)="changeTab($event)" [activeIndex]="activeIndex">
                <p-tabPanel header=" Screen Details">
                    <ng-template pTemplate="content">
                        <p-toolbar styleClass="headerSticky" *ngIf="role == 1">
                            <ng-template pTemplate="left">
                            </ng-template>
                            <ng-template pTemplate="right">
                                <button pButton pRipple label="Back" icon="pi pi-arrow-left" class="p-button-success mr-2" (click)="appUtil.return()"></button>
                                <button pButton pRipple label="Edit" icon="pi pi-pencil" class="p-button-success mr-2"
                                    (click)="edit()"></button>
                            </ng-template>
                        </p-toolbar>                    
                        <div class="p-fluid formgrid grid">
                            <div class="field col-12">
                                <div class="grid mt-1">
                                    <div class="col-12 md:col-6">
                                        <div class="inline label">Created By:</div>
                                         {{sellSide.createByUser}} on {{sellSide.createdDate | date:"MM-dd-yyyy HH:mm:ss"}}
                                    </div>
                                    <div class="col-12 md:col-6 text-right">
                                        <div class="inline label">Modified By:</div>
                                         {{sellSide.modifiedByUser}} on {{sellSide.lastModifiedDate | date:"MM-dd-yyyy HH:mm:ss"}}
                                    </div>
                                </div>
                                <p-divider type="dashed"></p-divider>
                            </div>
                        </div>
                        <sellside-view-include [model]="sellSide"></sellside-view-include><br>
                        <note-list *ngIf="sellSide.uuid != undefined" noteType="sellside" [parent]="sellSide" [parentUuid]="sellSide.uuid"></note-list><br>
                        <task-toggle-list taskType="sellside" [parentUuid]="uuid" [parent]="sellSide" *ngIf="role == 1"></task-toggle-list><br>
                        <p-panel [toggleable]="true" [collapsed]="false" expandIcon="pi pi-chevron-left"
                            collapseIcon="pi pi-chevron-down">
                            <p-header>
                                <span class="p-panel-title text-teal">
                                    <i class="pi pi-bars"></i> Campaigns
                                </span>
                            </p-header>
                            <p-table [autoLayout]="true" [value]="campaigns" [(selection)]="selectedCampaign"
                                dataKey="userId" [lazy]="true" (onLazyLoad)="loadCampaigns($event)"
                                styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm"
                                [rowHover]="true" [rows]="5" [paginator]="false" [loading]="campaignLoading"
                                [showCurrentPageReport]="true"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [totalRecords]="campaignsTotalRecords" [alwaysShowPaginator]="false">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>Title</th>
                                        <th>Type</th>
                                        <th>Related To</th>
                                        <th>Related Tag</th>
                                        <th>Action</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-campaign>
                                    <tr>
                                        <td class="wrap">
                                            <a [routerLink]="['/email-campaign/', campaign.uuid]">{{campaign.title}}</a>
                                        </td>
                                        <td class="wrap">{{campaign.type}}</td>
                                        <td class="wrap">
                                            <span *ngIf="campaign.sellSide != null">
                                                <a [routerLink]="['/sell-side/', campaign.sellSide.uuid]">{{campaign.sellSide.screenTitle}}</a>
                                            </span>
                                        </td>
                                        <td class="wrap"> {{campaign.relatedTag}}</td>
                                        <td>
                                            <button pButton pRipple [routerLink]="['/email-campaign/edit/', campaign.uuid]"
                                                icon="pi pi-pencil" class="p-button-success mr-2"></button>
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="footer">
                                    <tr *ngIf="campaignsTotalRecords > 5">
                                        <td colspan="5" class="text-right"><a href="./sellside/{{sellSide.uuid}}/campaigns">View More</a></td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="5">No Sell Side found to be associated with Campaign.</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </p-panel>
                    </ng-template>
                </p-tabPanel>
                <p-tabPanel header="Blind Profile" [selected]="false">
                    <ng-template pTemplate="content">
                        <ng-container *ngIf="sellSide != null && sellSide.uuid!=null">
                            <button style="float: right;margin-top: -12px;" pButton pRipple class="p-button-success mr-2" *ngIf="!sellSide.hasBlindProfile && blindProfileVew != 'new'"
                            (click)="blindProfile()">{{lebelName}}</button>
                            <ng-container *ngIf="sellSide.hasBlindProfile || blindProfileVew == 'new'">
                                <sellside-blind-profile-view *ngIf="blindProfileVew == 'view'" [screenId]="uuid"></sellside-blind-profile-view>
                                <sellside-blind-profile *ngIf="blindProfileVew != 'view'" [screenId]="uuid" [isEdit]="blindProfileVew == 'edit'" [sellside]="sellSide"></sellside-blind-profile>
                            </ng-container>
                        </ng-container>
                   </ng-template>
                </p-tabPanel>
                <p-tabPanel header="Prospects" [selected]="false">
                    <ng-template pTemplate="content">
                        <sellside-prospect-list [sellside]="sellSide"></sellside-prospect-list>
                    </ng-template>
                </p-tabPanel>
             </p-tabView>
        </div>
    </div>
</div>
<!-- <p-toast></p-toast> -->