import { Router } from '@angular/router';
import { User } from './../_models/user';
import { UnrecognizedMailLog } from './../_models/unrecognized-maillog';
import { Table } from 'primeng/table';
import { BreadcrumbService } from './../app.breadcrumb.service';
import { UsersService } from './users.service';
import { MessageService, LazyLoadEvent, MenuItem } from 'primeng/api';
import { Component, OnInit, ViewChild, NgZone, OnDestroy } from '@angular/core';
import { AppUtil } from '../_helpers';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-unrecognized-list',
    templateUrl: './unrecognized-list.component.html',
    styleUrls: ['./unrecognized-list.component.scss']
})
export class UnrecognizedListComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription = new Subscription();
    @ViewChild('dt') table: Table;
    mailLogs: UnrecognizedMailLog[];
    currentMenuData: UnrecognizedMailLog;
    selectedLog: User = null;
    loading: boolean;
    scrollTop: string;
    totalRecords: number = 0;
    mailLogUuid: string;
    menuItems: MenuItem[];
    showForm: boolean = false;
    constructor(private router: Router, private ngZone: NgZone,
        private messageService: MessageService,
        private userService: UsersService, public appUtil: AppUtil, private breadcrumbService: BreadcrumbService) {
        this.breadcrumbService.setItems([
            { label: 'Unrecognized Contacts' }
        ]);
    }


    ngOnInit(): void {
        const storedScrollTop = localStorage.getItem('unrecognizedScroll');
        if (storedScrollTop) {
            window.scrollTo(0, parseInt(storedScrollTop));
        }
        window.addEventListener('scroll', this.saveScrollPosition);
        this.loading = true;
        this.mailLogs = [];
        this.currentMenuData = null;
        this.menuItems = [
            {
                label: 'Add As Contact',
                icon: 'pi pi-user-plus',
                command: (event) => {
                    this.addContact();
                }
            },
            {
                label: 'Discard',
                icon: 'pi pi-trash',
                command: (event) => {
                    this.discard();
                }
            }
        ]
    }

    refreshData($event) {
        this.router.navigate(['/contact/' + $event.uuid])
        this._search({ first: this.table.first, rows: this.table.rows, sortField: this.table.sortField, sortOrder: this.table.sortOrder });
    }

    addContact() {
        this.showForm = true;
    }

    discard() {
        this.userService.discardUnrecognizedEmailLog(this.currentMenuData['uuid']).subscribe(
            data => {
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: "Email log has been discarded successfully.", life: 3000 });
                this.refreshData(null);
            });
    }

    setCurrentItem($data) {
        this.currentMenuData = $data;
        let names: string[] = ($data.name == null) ? ['', ''] : $data.name.split(' ');
        if (names.length == 1) {
            names.push('');
        }
        let firstName = names[0];
        if(firstName)
        {
            firstName = firstName.replace("\"","").replace(",","");
        }
        names.splice(0,1);

        let lastName = names.join(' ');
        if(lastName)
        {
            lastName = lastName.replace("\"","").replace(",","");
        }

        let det: User = { 'uuid': '', 'firstName': firstName, 'lastName': lastName, 'email': $data.email, 'title': '', 'phone': '' };
        this.selectedLog = det;
    }

    resetPagination() {
        this.table.first = 0;
        this.table.rows = 25;
    }

    search() {
        this.resetPagination();
        this._search();
    }

    _search(e: LazyLoadEvent = { first: 0, rows: 25, sortField: 'receivedOn', sortOrder: 1 }) {
        this.loading = true;
        var searchParams = {};
        searchParams['page'] = (e.first / e.rows);
        searchParams['pageSize'] = e.rows;
        var sortBy = []
        var sortDir = e.sortOrder == 1 ? 'ASC' : 'DESC';
        if (e.sortField && e.sortField != '') {
            sortBy[sortBy.length] = { "property": e.sortField, "direction": sortDir };
        }
        searchParams['sortDetails'] = sortBy;

        searchParams['rootRule'] = { 'condition': 'and', rules: [] }
        this.loading = true;
        this.userService.searchUnrecognizedEmailLogs(searchParams).subscribe(
            data => {
                let response = data as any;
                this.totalRecords = response.totalRecords;
                this.mailLogs = response.records;
                this.loading = false;
                this.appUtil.reinitializeShowMore();
                const data1 = localStorage.getItem('unrecognizedScroll');
                if (data1) {
                  this.ngZone.runOutsideAngular(() => {
                    setTimeout(() => {
                      window.scrollTo(0, parseInt(data1));
                    }, 0);
                  });
                }
            });
    }
    ngOnDestroy() {
        this.subscriptions.unsubscribe();
         window.removeEventListener('scroll', this.saveScrollPosition);
         localStorage.setItem('unrecognizedScroll', this.scrollTop); 
     }
     
     saveScrollPosition = (): void => {
         this.scrollTop = window.scrollY.toString();  
    
     }
}
