<div class="grid table-demo">
    <p-toast></p-toast>
    <div class="col-12">
        <div class="card">
            <p-toolbar styleClass="m-0">
                <div class="field col-12 md:col-10">
                    <p-dropdown [(ngModel)]="selectedKeyId" (ngModelChange)="onKeyChange($event)" [options]="keyList" optionLabel="name"
                        optionValue="id"></p-dropdown>
                </div>
                <div class="field">
                    <button pButton pRipple label="Back" icon="pi pi-arrow-left" class="p-button-success mr-2" (click)="return()"></button>
                    <button pButton pRipple label="Add" *ngIf=showAddButton() icon="pi pi-plus" class="p-button-success mr-2"
                        (click)="openNew()"></button>
                    <p-button label="Merge" icon="pi pi-link" class="p-button-success mr-2" *ngIf="selectedKeyId != 'COUNTRY' && showAddButton()" [disabled]="lookupValues == undefined || lookupValues.length<2"
                        (click)="showMergeOptions()"></p-button>
                </div>
                <!--<div class="field col-12 md:col-1">
			 <button pButton pRipple label="Delete" icon="pi pi-trash" class="p-button-danger  mr-2" ></button>
	   </div>-->
            </p-toolbar>
            <p-table dataKey="uuid" [autoLayout]="true" #dt [value]="lookupValues" [(selection)]="selectedValues"
                styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm" [rowHover]="true"
                [rows]="10" [paginator]="false" [filterDelay]="0" [globalFilterFields]="['name']"
                [showCurrentPageReport]="true" [loading]="loading"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
                <ng-template pTemplate="header">
                    <tr>
                        <th *ngIf="selectedKey.name =='Country'" style="width: 3rem"></th>
                        <th style="width: 3rem" *ngIf="selectedKey.name !='Country'">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th pSortableColumn="value" *ngIf="selectedKey.type != 'RANGE' && selectedKey.name !='Country'">
                            Value
                            <p-sortIcon field="value"></p-sortIcon>
                        </th>
                        <th pSortableColumn="name" *ngIf="selectedKey.type != 'RANGE' && selectedKey.name =='Country'">
                            Name
                            <p-sortIcon field="name"></p-sortIcon>
                        </th>
                        <th pSortableColumn="entityName" *ngIf="selectedKey.id == 'BUYSIDE_PROSPECT_LOOKUP_1' || selectedKey.id == 'BUYSIDE_PROSPECT_LOOKUP_2'">Buyside Screen
                            <p-sortIcon field="entityName"></p-sortIcon>
                        </th>
                        <th pSortableColumn="entityName" *ngIf="selectedKey.id == 'SELLSIDE_PROSPECT_LOOKUP_1' || selectedKey.id == 'SELLSIDE_PROSPECT_LOOKUP_2'">Sellside Screen
                            <p-sortIcon field="entityName"></p-sortIcon>
                        </th>
                        <th pSortableColumn="min" *ngIf="selectedKey.type == 'RANGE'">Min. Value
                            <p-sortIcon field="min"></p-sortIcon>
                        </th>
                        <th pSortableColumn="max" *ngIf="selectedKey.type == 'RANGE'">Max. Value
                            <p-sortIcon field="max"></p-sortIcon>
                        </th>
                        <!-- <th pSortableColumn="text">Text
							<p-sortIcon field="text"></p-sortIcon>
            </th>    -->
                        <th pSortableColumn="status">Status
                            <p-sortIcon field="status"></p-sortIcon>
                        </th>
                        <th style="text-align: center;">Action</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-lookupvalue let-expanded="expanded">
                    <tr class="p-selectable-row">
                        <td *ngIf="selectedKey.name =='Country'">
                            <button type="button" pButton pRipple [pRowToggler]="lookupvalue"
                                class="p-button-text p-button-rounded p-button-plain"
                                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                        </td>
                        <td *ngIf="selectedKey.name !='Country'">
                            <p-tableCheckbox [value]="lookupvalue"></p-tableCheckbox>
                        </td>
                        <td *ngIf="selectedKey.type != 'RANGE' && selectedKey.name !='Country'">
                            <span class="p-column-title">{{lookupvalue.value}}</span>
                        </td>
                        <td *ngIf="selectedKey.type != 'RANGE' && selectedKey.name =='Country'">
                            <span class="p-column-title">{{lookupvalue.name}}</span>
                        </td>
                        <td *ngIf="selectedKey.id == 'BUYSIDE_PROSPECT_LOOKUP_1' || selectedKey.id == 'BUYSIDE_PROSPECT_LOOKUP_2'">
                            <span class="p-column-title"> <a [routerLink]="['/buy-side', lookupvalue.entityUuid, 'prospects']">{{ lookupvalue.entityName }}</a></span>
                        </td>
                        <td *ngIf="selectedKey.id == 'SELLSIDE_PROSPECT_LOOKUP_1' || selectedKey.id == 'SELLSIDE_PROSPECT_LOOKUP_2'">
                            <span class="p-column-title"> <a [routerLink]="['/sell-side', lookupvalue.entityUuid, 'prospects']">{{ lookupvalue.entityName }}</a></span>
                        </td>
                        <td *ngIf="selectedKey.type == 'RANGE'">
                            <span class="p-column-title">{{lookupvalue.min}}</span>
                        </td>
                        <td *ngIf="selectedKey.type == 'RANGE'">
                            <span class="p-column-title">{{lookupvalue.max}}</span>
                        </td>
                        <!-- <td>
							<span class="p-column-title">{{lookupvalue.text}}</span>
						</td>          -->
                        <td>
                            <span class="p-column-title">{{lookupvalue.status}}</span>
                        </td>
                        <td style="text-align: center;">
                            <button type="button" pButton icon="pi pi-list"
                                (click)="setCurrentItem(lookupvalue); menu.toggle($event)"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="rowexpansion" let-lookupvalue>
                    <button style="margin: 10px;" pButton pRipple label="ADD" icon="pi pi-plus"
                        class="p-button-success mr-2" (click)="newState(lookupvalue)"></button>
                    <tr>
                        <td colspan="7">
                            <div class="p-3">
                                <p-table [autoLayout]="true" [value]="lookupvalue.states" dataKey="uuid" [rows]="10"
                                    [paginator]="true">
                                    <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="name">Name <p-sortIcon field="name"></p-sortIcon>
                        </th>
                        <th pSortableColumn="status">Status <p-sortIcon field="status"></p-sortIcon>
                        </th>
                        <th style="text-align: center;">Action</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-state>
                    <tr>
                        <td>{{state.name}}</td>
                        <td>{{state.status}}</td>
                        <td style="text-align: center;">
                            <button pButton pRipple icon="pi pi-pencil" class="p-button-success mr-2"
                                (click)="editState(state,lookupvalue);"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="3">There are no State for this country yet.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        </td>
        </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="5">No records found.</td>
            </tr>
        </ng-template>
        </p-table>
    </div>
</div>
</div>
<p-overlayPanel #op [showCloseIcon]="true" [style]="{width: '450px'}">
    <ng-template pTemplate>
        <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search"
            class="p-inputtext-lg table-search" style="width: 220px" />
    </ng-template>
</p-overlayPanel>
<p-menu #menu [popup]="true" [model]="menuItems"></p-menu>
<add-state-lookups *ngIf="selectedKeyId == 'COUNTRY'" [(show)]="stateDialog" [type]="stateTitle"
    [countryUuid]="cUuid" [(uuid)]="uuid" (parentFuntion)="fetchLookupValues()"></add-state-lookups>
<add-global-lookups [(show)]="lookupDialog" [type]="selectedKeyId" [(uuid)]="uuid"
    (parentFuntion)="fetchLookupValues()"></add-global-lookups>
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>

<p-dialog [(visible)]="showMerge" header="Merge Lookup Values" styleClass="p-fluid" [style]="{width: '400px'}">
    <ng-template pTemplate="content">
        <form [formGroup]="mergeForm" #ngForm="ngForm">
            <div class="field">
                <label for="master">Master <span class="mandate">*</span></label>
                <p-autoComplete formControlName="master" [suggestions]="suggestionList"
                    (completeMethod)="searchItem($event,'master')" completeOnFocus="true" field="value" dataKey="uuid"
                    forceSelection="true" appendTo="body" [required]="true"
                    [ngClass]="{ 'ng-invalid ng-dirty': mf.master.touched && mf.master.errors }">
                </p-autoComplete>
            </div>
            <div class="field">
                <label for="source1">Source1 <span class="mandate">*</span></label>
                <p-autoComplete formControlName="source1" [suggestions]="suggestionList"
                    (completeMethod)="searchItem($event, 'source1')" completeOnFocus="true" field="value" dataKey="uuid"
                    forceSelection="true" appendTo="body" [required]="true"
                    [ngClass]="{ 'ng-invalid ng-dirty': mf.source1.touched && mf.source1.errors }">
                </p-autoComplete>
            </div>
            <div class="field">
                <label for="source2">Source 2</label>
                <p-autoComplete formControlName="source2" [suggestions]="suggestionList"
                    (completeMethod)="searchItem($event, 'source2')" completeOnFocus="true" field="value" dataKey="uuid"
                    forceSelection="true" appendTo="body">
                </p-autoComplete>
            </div>
        </form>
    </ng-template>
    <ng-template pTemplate="footer">
        <div *ngIf="clsSpinner">
            <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
                fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
        </div>
        <div *ngIf="clsSpinner == false">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
                (click)="showMerge = false;"></button>
            <button pButton pRipple label="Save" form="clform" icon="pi pi-check" (click)="mergeLookups()"
                class="p-button-text"></button>
        </div>
    </ng-template>
</p-dialog>