import { SearchHeler } from './../../_helpers/search-helper';
import { PredefinedFilterService } from './predefined-filter.service';
import { filter } from 'rxjs/operators';
import { FieldInfo, makeFieldInfo } from './../../_models/field-info';
import { Component, OnInit, ViewChild, SimpleChanges, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { AppUtil } from 'src/app/_helpers';
import { PredefinedFilter, Rule, RuleSet } from 'src/app/_models';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AccountService } from 'src/app/_services';

@Component({
    selector: 'tbp-search',
    templateUrl: './tbp-search.component.html',
    styleUrls: ['./tbp-search.component.scss']
})
export class TbpSearchComponent implements OnInit,OnChanges {

    @ViewChild('fo') fieldOptions: OverlayPanel;
    @ViewChild('sv') saveFilterPanel: OverlayPanel;
    @ViewChild('ct') customLabelPanel: OverlayPanel;

    @Input() filterFor: string = null;
    @Input() showFilter: boolean = false;
    @Input() searchURL: string = null;
    @Input() pageId: number[];
    @Input() fields: FieldInfo[];
    @Input() searchTermPlaceHolder: string;
    @Input() useSortedFields: boolean = false;
    @Input() defaultSortField: string = "lastModifiedDate";
    @Input() defaultSortOrder: number = -1;
    @Input() defaultPageSize: number = null;
    @Input() showAdvanceSearch: boolean = true;
    @Input() showHideField: boolean = true;
    @Output() onShowHideField: EventEmitter<any> = new EventEmitter();
    @Input() entityDefaulOptionAvailable:boolean = false;
    @Input() entityID: string = null;
    @Output() onSearch: EventEmitter<any> = new EventEmitter();
    @Input() allowCustomLabels: boolean = false;
    @Output() onCustomLabel: EventEmitter<any> = new EventEmitter();

    sortField: string;
    sortOrder: number;
    sortableFields: FieldInfo[];
    searchTerm: string;
    firstRow:number = 0;
    pageSize:number = 50;
    searchProperty: string = null;
    searchCondition: string = null;
    searchValue: any = null;
    predefinedFilters: PredefinedFilter[] = null;
    selectedFilter:string = null;
    selectedSearchFields: string[] = null;
    rootRule: RuleSet = null;
    currentRuleSet: RuleSet;
    currentRule: Rule;
    filterValue: any;
    filterValueMax: any;
    filterOperator: string;
    filterShowMinMax: boolean = false;
    visibleFields:FieldInfo[] = null;
    existingFiltersForSave:PredefinedFilter[] = null;
    filterSelectedForSave:string = null;
    filterName:string = null;
    filterVisibility:string = null;
    filterVisibilityOptions:any[] = [{uuid:"PRIVATE", name:"Private"},{uuid:"TBP_EMPLOYEE", name:"All TBP Employees"},{uuid:"CONTACT", name:"All Contacts"},{uuid:"ALL_USERS", name:"Anyone"}];
    fieldsForColumnChooser = [];
    fieldNames:any = [];
    systemDefault: PredefinedFilter = null;
    entityDefault: PredefinedFilter = null;
    canChooseVisibility: boolean = true;
    canChangeName: boolean = true;
    customLabelUrl:string = null;
    customLabels: Map<string, string> = null;
    columnNames: any = {};
    previousData: any;
    clearColumnsFilter: boolean = false;
    clearFilterBoolean: boolean=false;
    profileUuid = this.accountService.userValue.uuid;


    constructor(private route: ActivatedRoute,
        private messageService: MessageService,
        private router: Router, private formBuilder: FormBuilder,
        public filterService: PredefinedFilterService,
        public appUtil: AppUtil,
        public searchHelper: SearchHeler,
        public accountService: AccountService) {
        this.rootRule = { 'condition': 'and', rules: [], ruleSets: [] };
        if(this.defaultPageSize == null)
            this.defaultPageSize = this.appUtil.pageSize;
    }

    ngOnInit(): void {   
        
        this.route.params.subscribe(data => {
            this.fields = this.fields.sort(function (a, b) {
                var nameA = a.label.toUpperCase(); // ignore upper and lowercase
                var nameB = b.label.toUpperCase(); // ignore upper and lowercase
                return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
            });
            this.sortableFields = this.fields.filter((field) => field.sortable);
            this.fieldsForColumnChooser = this.fields.filter(value => value.visible == 'F');
            this.fieldsForColumnChooser.forEach((value, index, array) => { value.columnIndex = 999});
            this.fieldsForColumnChooser.sort(function(a, b){return a.label.localeCompare(b.label)});
            this.searchHelper.initializeSearch(this);
            this.visibleFields = this.fields.filter(col => col.visible == 'T' || col.visible == 'A');
            this.visibleFields.sort(function(a, b){return a.columnIndex == b.columnIndex?a.label.localeCompare(b.label):a.columnIndex-b.columnIndex});
            this.visibleFields.forEach((value, index, array) => { value.columnIndex = index+1});
            this.onShowHideField.emit(this.visibleFields);
            this.appUtil.reinitializeShowMore();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes?.filterFor)
        {
            if(this.filterFor == 'BUYSIDE_PROSPECT')
            {
                this.customLabelUrl = "/buyside/:uuid/propsect-field-labels";
            } else {
                this.customLabelUrl = "/sellside/:uuid/propsect-field-labels";
            }
        }

        if(changes.entityID && changes.entityID.currentValue)
        {
            this.searchHelper.getCustomColumnNames(this.customLabelUrl.replace(":uuid", this.entityID)).subscribe(
                data => {
                    if(data)
                    {
                        let dt = [];
                        (data as any[]).forEach((value) => {dt[value.name] = (value.label);});
                        this.customLabels = new Map<string, string>(Object.entries(dt));
                        this.customLabels.forEach((value, key) => {
                        this.columnNames[key] = value});  
                    }
                    else
                        this.customLabels = new Map<string, string>();
                        this.notifyCustomLabelChange();
                }
            );
            this._getFilters(this.filterFor, this.entityID);
        }
        else if(changes.filterFor && !this.entityDefaulOptionAvailable)
        {
            this._getFilters(this.filterFor, null);
        }

       
    }

    _getFilters(filterFor:string, entityID:string)
    {
        this.filterService.getFilters(filterFor, entityID).subscribe(
            data => {
                this.predefinedFilters = data as PredefinedFilter[];
                this.predefinedFilters.forEach( (filter:PredefinedFilter) => {
                    if(filter.filterType == 'SYSTEM_DEFAULT')
                    {
                        this.systemDefault = filter;
                    }
                    else if(filter.filterType == 'ENTITY_DEFAULT')
                    {
                        this.entityDefault = filter;
                    }
                }, this);

                if(this.rootRule.rules.length == 0 && this.rootRule.ruleSets.length == 0 && (this.searchTerm == null || this.searchTerm==""))
                {
                    if(this.entityDefault != null && this.entityDefaulOptionAvailable)
                    {
                        this.selectedFilter = this.entityDefault.uuid;
                    }
                    else if(this.systemDefault != null)
                    {
                        this.selectedFilter = this.systemDefault.uuid;
                    }
                    if(this.selectedFilter != null)
                    {
                        this.changeFilter();
                    }
                    this.search();
                }
            },
           error => {
           }
        )
    }
   getFilterFromPreDefinedFilter(){
    for (let i = 0; i < this.predefinedFilters.length; i++) {
        if (this.selectedFilter == this.predefinedFilters[i].uuid) {
            this._updateFieldVisibility(this.predefinedFilters[i].fields);
        }
    }
   }
   ngOnDestroy(){
  
   }
    search() {
        if(this.searchTerm == null || this.searchTerm == ""){
             this.searchTerm =   this.route.snapshot.fragment;
        } 
        this.firstRow = 0;
        const prospectUuid = this.extractUUIDFromURL(this.searchURL);
        if(this.clearColumnsFilter){
            localStorage.removeItem(this.filterFor+"SELECTEDFILTER");
            localStorage.removeItem(this.entityID+"SELECTEDFILTER");
            localStorage.removeItem("prospects"+prospectUuid+"SELECTEDFILTER");
          
            }
        if(!this.searchURL.includes("prospects")){
            if(this.searchURL.includes("add")){
                if(JSON.parse(localStorage.getItem("prospects"+this.filterFor))!=null){
              
                    if(  this.rootRule.rules.length == 0 && (this.filterFor=="CONTACT" || this.filterFor=="ORGANIZATION" || this.filterFor=="DEAL_INFO" 
                    ) ){
                        if(!this.clearFilterBoolean){
                        const data = JSON.parse(localStorage.getItem("prospects"+this.filterFor));
                        this.rootRule=data;
                        }
                        this.selectedFilter=JSON.parse(localStorage.getItem("prospects"+prospectUuid+"SELECTEDFILTER")); 
                        if(this.selectedFilter!=null){
                            this.getFilterFromPreDefinedFilter();
                        }
                
                    }
                   }
                   if(this.filterFor=="CONTACT" || this.filterFor=="ORGANIZATION" || this.filterFor=="DEAL_INFO"){
                      localStorage.setItem("prospects"+this.filterFor, JSON.stringify(this.rootRule));
          
                  }
            }
            else{
               
            if(JSON.parse(localStorage.getItem(this.filterFor))!=null){
              
            if(  this.rootRule.rules.length == 0 && (this.filterFor=="CONTACT" || this.filterFor=="ORGANIZATION" || this.filterFor=="DEAL_INFO" || this.filterFor=="BUYSIDE" 
             || this.filterFor=="SELLSIDE" ) ){ 
                if(!this.clearFilterBoolean){
                const data = JSON.parse(localStorage.getItem(this.filterFor));
                this.rootRule=data;
                }
                 this.selectedFilter=JSON.parse(localStorage.getItem(this.filterFor+"SELECTEDFILTER"))
                 if(this.selectedFilter!=null){
                    this.getFilterFromPreDefinedFilter();
                }
            }
           }
           
            if(this.filterFor=="CONTACT" || this.filterFor=="ORGANIZATION" || this.filterFor=="DEAL_INFO" || this.filterFor=="BUYSIDE"  
            || this.filterFor=="SELLSIDE"){
               localStorage.setItem(this.filterFor, JSON.stringify(this.rootRule));
   
           }
        }
      }
      else{
        if(JSON.parse(localStorage.getItem(this.entityID+"prospects"))!=null){
        if(  this.rootRule.rules.length == 0  && (this.filterFor=="BUYSIDE_PROSPECT" || this.filterFor=="SELLSIDE_PROSPECT")){
            if(!this.clearFilterBoolean){
            const data = JSON.parse(localStorage.getItem(this.entityID+"prospects"));
            this.rootRule=data;
            }
            this.selectedFilter=JSON.parse(localStorage.getItem(this.entityID+"SELECTEDFILTER"));
            if(this.selectedFilter!=null){
                this.getFilterFromPreDefinedFilter();
            }
        }
    }
        if(this.searchURL.includes("prospects")){
            localStorage.setItem(this.entityID+"prospects", JSON.stringify(this.rootRule));
        }
      }
        this.searchHelper.search(this, true);
    }

    loadData(first:number,rows:number,sortField:string,sortOrder:number)
    {
        if(this.firstRow != first || this.pageSize != rows || this.sortField != sortField || this.sortOrder != sortOrder)
        {
            this.firstRow = first;
            this.pageSize = rows;
            this.sortField = sortField;
            this.sortOrder = sortOrder;
            this.searchHelper.search(this, true);
        }
    }
 

    setCurrentRuleSet(ruleSet: RuleSet) {
        this.currentRuleSet = ruleSet;
        this.selectedSearchFields = this.currentRuleSet.rules.map(rule => rule.field.property);
    }

    addRule(fieldInfo: FieldInfo) {
        let refreshData:boolean = true;
        if (this.appUtil.getObject(this.selectedSearchFields, fieldInfo.property)) {
            this.currentRule = new Rule(fieldInfo, null);
            if(fieldInfo.searchType == 'exact') {
                this.currentRule.operator = "in";
            } else if(fieldInfo.searchType == 'integer' || fieldInfo.searchType == 'date') {
                this.currentRule.operator = "between";
            }
            this.currentRule.prepareLabel();
            this.currentRuleSet.rules.push(this.currentRule);
            refreshData = (this.currentRule.value != null);
        }
        else {
            this.currentRuleSet.rules = this.currentRuleSet.rules.filter((current, index) => {
                return !(current.field.property == fieldInfo.property);
            });
        }
        if(refreshData)
        {
            this.search();
        }
        else
        { 
            this.searchHelper.search(this,false);
        }
        
    }

    addRuleSet() {
        this.currentRuleSet.ruleSets.push({ 'condition': 'and', rules: [], ruleSets: [] });
    }

    setCurrentRule(ruleSet: RuleSet, rule: Rule) {
        this.currentRuleSet = ruleSet;
        this.currentRule = rule;
        this.filterValue = this.currentRule.value;
        this.filterValueMax = this.currentRule.maxValue;
        this.filterOperator = this.currentRule.operator;
        if (this.currentRule.operator == 'between')
            this.filterShowMinMax = true;
        else
            this.filterShowMinMax = false;
    if(this.searchURL.includes("prospects")){
        localStorage.setItem(this.entityID+"prospectScroll",JSON.stringify("0"));
    }
    else{
     localStorage.setItem(this.filterFor+"SCROLL",JSON.stringify("0"));
    }   
    }

    removeRule(ruleSet: RuleSet, rule: Rule) {
        this.currentRuleSet = ruleSet;
        this.currentRuleSet.rules = this.currentRuleSet.rules.filter((current, index) => {
            return !(current.field.property == rule.field.property);
        });
        let refreshData = (rule.value != null || rule.operator == 'exists'  || rule.operator == 'notexists' );
        if(refreshData)
        {
            if(this.searchURL.includes("prospects")){
                localStorage.setItem(this.entityID+"prospectScroll",JSON.stringify("0"));
            }
            else{
             localStorage.setItem(this.filterFor+"SCROLL",JSON.stringify("0"));
            }
            this.clearFilterBoolean=true;
            this.search();
        }
        else
        {
            this.searchHelper.search(this,false);
        }
    }

    processField($event, ruleSet: RuleSet, rule: Rule) {
        this.setCurrentRule(ruleSet, rule);
        var isVisible = this.fieldOptions.overlayVisible;
        if (isVisible) {
            this.fieldOptions.hide();
        }
        this.fieldOptions.show($event);
        if (isVisible)
            this.fieldOptions.align();
    }

    updateRule() {
        let oldValue = this.currentRule.value;
        let oldMaxValue = this.currentRule.maxValue;
        let oldOperator = this.currentRule.operator;
        if (this.filterValue === undefined || (Array.isArray(this.filterValue) && this.filterValue.length == 0)
            || (!Array.isArray(this.filterValue) && this.filterValue === '')) {
            this.currentRule.value = null;   
        }
        else {
            this.currentRule.value = this.filterValue;
        } 
        if (this.filterValueMax === undefined || this.filterValueMax == '') {
            this.currentRule.maxValue = null;
        }        
        else {
            this.currentRule.maxValue = this.filterValueMax;
        }
        this.currentRule.operator = this.filterOperator;
        if(this.currentRule.value != oldValue || this.currentRule.maxValue != oldMaxValue || this.currentRule.operator != oldOperator) {
            this.currentRule.prepareLabel();
            this.search();
        }
        this.fieldOptions.hide();
    }

    toggleSortOrder() {
        this.sortOrder = (this.sortOrder == -1) ? 1 : -1;
        this.searchHelper.search(this,true);
    }

    changeSortField($event) {
        this.sortField = $event.value;
        this.searchHelper.search(this,true);
    }

    getOperator(rule:Rule):string
    {
        let op = ':';
        if(rule.operator == 'notin')
        {
            op = '!=';
        }
        
        return op;
    }

    @Input() get innerVisibleColumns(): any[] {
        return this.visibleFields;
    }
    
    set innerVisibleColumns(val: FieldInfo[]) {
        this._updateVisibleRows(val)
    }
    
    _updateVisibleRows(val: FieldInfo[])
    {
        //restore original order
        let addedVisibleItems:FieldInfo[] = this.fieldsForColumnChooser.filter(col => val.includes(col));
        let removedVisibleItems:FieldInfo[] = this.visibleFields.filter(col => !val.includes(col));
        //remove addedVisibleItems from fieldChooser and removedVisibleItems from visibleFields
        let newFieldsForColumnChooser:FieldInfo[] = this.fieldsForColumnChooser.filter(col => !val.includes(col));
        let newVisibleFields:FieldInfo[] = this.visibleFields.filter(col => val.includes(col));
        // add addedVisibleItems to visibleFields and removedVisibleItems to fieldChooser
        newFieldsForColumnChooser = newFieldsForColumnChooser.concat(removedVisibleItems);
        newVisibleFields = newVisibleFields.concat(addedVisibleItems);
        // update index of new visibleFields list and reset for new fieldChooser
        newFieldsForColumnChooser.forEach(element => {element.columnIndex = 999});
        newVisibleFields.forEach((element,index) => {element.columnIndex = index+1});
        newVisibleFields.sort(function(a, b){return a.columnIndex-b.columnIndex});
        newFieldsForColumnChooser.sort(function(a, b){return a.label.localeCompare(b.label)});
        this.visibleFields = newVisibleFields;
        this.fieldsForColumnChooser = newFieldsForColumnChooser;
        this.onShowHideField.emit(this.visibleFields);  
       // this.onShowHideField.emit(this.customLabels);  
        this.appUtil.reinitializeShowMore();      
    }

    prepareForSave()
    {
        const filters: PredefinedFilter[] = [{uuid:'new',name:'Create New Filter',visibility:'PRIVATE',query:null,relatedTo:this.filterFor,fields:null, entityId:null, filterType:"GENERAL"},
            this.systemDefault == null?{uuid:'sd',name:'System Default',visibility:'ALL_USERS',query:null,relatedTo:this.filterFor,fields:null, entityId:null, filterType:"SYSTEM_DEFAULT"}:this.systemDefault];
        if(this.entityDefaulOptionAvailable)
        {
            filters.push(this.entityDefault == null?{uuid:'ed',name:'Entity Default',visibility:'ALL_USERS',query:null,relatedTo:this.filterFor,fields:null, entityId:this.entityID, filterType:"ENTITY_DEFAULT"}:this.entityDefault)
        }

        if(this.selectedFilter !== undefined && this.selectedFilter != null)
        {
            let tmpFilter = this.appUtil.getObject(this.predefinedFilters, this.selectedFilter);
            if(tmpFilter.filterType == "GENERAL")
            {
                this.existingFiltersForSave = [filters, tmpFilter]
            }
            else
            {
                this.existingFiltersForSave = filters;
            }
            this.filterSelectedForSave = this.selectedFilter;
        }
        else
        {
           this.existingFiltersForSave = filters;
           this.filterSelectedForSave = this.existingFiltersForSave[0].uuid;
        }
        this.filterForSaveChanged();
    }

    filterForSaveChanged()
    {
        let curFilter = this.appUtil.getObject(this.existingFiltersForSave,this.filterSelectedForSave);
        this.canChangeName = true;
        this.canChooseVisibility = true;
        if(curFilter.uuid == 'new')
        {
            this.filterName = '';
            this.filterVisibility = 'PRIVATE';
        }
        else if(curFilter.filterType == 'SYSTEM_DEFAULT' || curFilter.filterType == 'ENTITY_DEFAULT')
        {
            this.filterName = curFilter.name;
            this.filterVisibility = "ALL_USERS";
            this.canChangeName = false;
            this.canChooseVisibility = false;
        }
        else
        {
            this.filterName = curFilter.name;
            this.filterVisibility = curFilter.visibility;
        }
    }

    isValidFilter()
    {  
        let isFilterValid:boolean = (this.rootRule.ruleSets.length > 0) || (this.rootRule.rules.length > 0);
        return isFilterValid;
    }

    saveFilter()    
    {
        let apiToCall: any;
        var sortDetails = []
        if (this.sortField && this.sortField != '') {
            sortDetails[sortDetails.length] = { "property": this.sortField, "direction": (this.sortOrder == 1 ? 'ASC' : 'DESC') };
        }
        let data: any = {name:this.filterName,visibility:this.filterVisibility,
            query:{sortDetails:sortDetails,rootRule:this.searchHelper.prepareFilter(this.rootRule)},filterFor:this.filterFor,
            sortDir:(this.sortOrder == 1 ? 'ASC' : 'DESC'), sortBy:this.sortField, fields: this.visibleFields.map( (value, index, array)=> { return {name:value.property,position: value.columnIndex};}),
            filterType: "GENERAL", entityUuid:null};
        if(this.filterSelectedForSave == 'new' || this.filterSelectedForSave == 'sd' || this.filterSelectedForSave == 'ed')
        {
            if(this.filterSelectedForSave != 'new')
            {
                data.filterType = this.filterSelectedForSave == 'sd'?"SYSTEM_DEFAULT":"ENTITY_DEFAULT";
            }
            apiToCall = this.filterService.createFilter(data);
        }
        else
        {
            let curFilter = this.appUtil.getObject(this.existingFiltersForSave,this.filterSelectedForSave);
            data.filterType = curFilter.filterType;
            apiToCall = this.filterService.updateFilter(data, this.filterSelectedForSave);
        }
        if(data.filterType == 'ENTITY_DEFAULT')
        {
            data.entityUuid = this.entityID;
        }
        apiToCall.subscribe(
            data => {
                let index = this.predefinedFilters.findIndex(val => val.uuid == data.uuid);
                if(index < 0)
                {
                    if(this.filterSelectedForSave == 'sd')
                    {
                        this.systemDefault = data;
                    }
                    else if(this.filterSelectedForSave == 'ed')
                    {
                        this.entityDefault = data;
                    }
                    this.predefinedFilters[this.predefinedFilters.length] = data;
                }
                else
                {
                    this.predefinedFilters[index] = data;
                }
                this.predefinedFilters.sort((a,b) => { return a.name.localeCompare(b.name)});
                this.saveFilterPanel.hide();
             },
            error => {
                let errors = error.error;
                if (errors.code && errors.code == 'VALIDATION_ERROR') {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: errors.subErrors[0].message, life: 3000 });
                }
            }
        );
    }

    _updateFieldVisibility(filterFields:any)
    {
        if(filterFields != null && filterFields.length > 0)
        {
            const filterMap = new Map();
            filterFields.reduce( (filterMap, current) => {
                filterMap.set(current.name, current);
                return filterMap;
            }, filterMap);

            this.fieldsForColumnChooser = this.fields.filter(value => ((value.visible == 'F' || value.visible == 'T') && !filterMap.has(value.property)) );
            this.fieldsForColumnChooser.forEach((value, index, array) => { value.columnIndex = 999});
            this.fieldsForColumnChooser.sort(function(a, b){return a.label.localeCompare(b.label)});
            this.visibleFields = this.fields.reduce((visibleFields, current) => { 
                if(filterMap.has(current.property))
                {
                    let tmpF = filterMap.get(current.property);
                    current.columnIndex = tmpF.position;
                    visibleFields.push(current);
                }
                else if(current.visible == 'A')
                {
                    current.columnIndex = 999;
                    visibleFields.push(current);
                }
                return visibleFields;
            },[]);

            this.visibleFields.sort(function(a, b){return a.columnIndex == b.columnIndex?a.label.localeCompare(b.label):a.columnIndex-b.columnIndex});
            this.visibleFields.forEach((value, index, array) => { value.columnIndex = index+1});
            this.onShowHideField.emit(this.visibleFields);
            this.appUtil.reinitializeShowMore(); 
        }
    }

    changeFilter()
    {
        const prospectUuid = this.extractUUIDFromURL(this.searchURL);
        let tmpFilter = this.appUtil.getObject(this.predefinedFilters, this.selectedFilter);
        if(tmpFilter == null) {
            this.rootRule = { 'condition': 'and', rules: [], ruleSets: [] };
        }
        else
        {
            let tmpRootRule:RuleSet = { 'condition': tmpFilter.query.rootRule.condition, rules: [], ruleSets: [] };
          
            if(!(tmpFilter.name ==="System Default" || tmpFilter.name ==="Entity Default")){
                if(this.searchURL.includes("prospects")){
                    localStorage.setItem(this.entityID+"SELECTEDFILTER", JSON.stringify(this.selectedFilter));
                } else if(this.searchURL.includes("add")){
                localStorage.setItem("prospects"+prospectUuid+"SELECTEDFILTER", JSON.stringify(this.selectedFilter));
                }
                else{
                   localStorage.setItem(this.filterFor+"SELECTEDFILTER", JSON.stringify(this.selectedFilter));
                }
            }
            this._updateFieldVisibility(tmpFilter.fields);
            
            this.searchHelper.prepareRulesFromFilter(this, tmpRootRule, tmpFilter.query.rootRule.rules);
            this.rootRule = tmpRootRule;
            this.searchTerm = null;
            if(tmpFilter.sortDetails != null && tmpFilter.sortDetails.length > 0)
            {
                this.sortField = tmpFilter.sortDetails[0].property;
                this.sortOrder = tmpFilter.sortDetails[0].direction=='ASC'?1:-1;
            }
            this.search();
            if(tmpFilter.filterType != 'GENERAL')
            {
                this.selectedFilter = null;
            }
        } 
    }
    extractUUIDFromURL(url: string): string | null {
        const uuidPattern = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
        const match = url.match(uuidPattern);
        if (match) {
          return match[0];
        } else {
          return null;
        }
      }
    clearFilters()
    { 
        this.rootRule = { 'condition': 'and', rules: [], ruleSets: [] };
        this.selectedFilter = null;
        this.clearColumnsFilter=true;
        this.clearFilterBoolean= true;
        if(this.searchURL.includes("prospects")){
            localStorage.setItem(this.entityID+"prospectScroll",JSON.stringify("0"));
        }
        else{
         localStorage.setItem(this.filterFor+"SCROLL",JSON.stringify("0"));
        }  
        this.search();
        window.location.reload();
    }

    onDrop(event: CdkDragDrop<string[]>) {
        let previousIndex = event.previousIndex;
        let currentIndex = event.currentIndex;
        if (previousIndex !== currentIndex) {
            moveItemInArray(this.visibleFields, previousIndex, currentIndex);
            this.visibleFields.forEach((element,index) => {element.columnIndex = index+1});
            this.onShowHideField.emit(this.visibleFields);
            this.appUtil.reinitializeShowMore();
        }
    }

    prepareColumnNames()
    {
       // this.columnNames = {...this.customLabels};
      if (this.customLabels.size >0) {
        this.customLabels.forEach((value, key) => {
            this.columnNames[key] = value.trim()});
      }
    }

    
    updateCustomLabels()
    {
        const labels = [];
       this.customLabels = new Map<string, string>(Object.entries(this.columnNames));
            if(this.columnNames)
                {
                    Object.keys(this.columnNames).forEach((key) => labels.push({"name":key, "label":(this.columnNames[key].trim())}));
                }
        this.searchHelper.saveCustomColumnNames(this.customLabelUrl.replace(":uuid", this.entityID), 
            labels).subscribe(
            data => {
                this.customLabels.forEach((value, key) => {
                    this.columnNames[key] = value});
                this.notifyCustomLabelChange();
                this.customLabelPanel.hide();
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: "Column Name Updated Successfully", life: 3000 });
            },
            error => {
                let errors = error.error;
            }
        );
        
    }

    notifyCustomLabelChange()
    {
        const retMap = new Map();
        if(this.customLabels)
        {
        Object.keys(this.columnNames).forEach((key) => retMap.set(key, (this.columnNames[key].trim())));
        }
        this.onCustomLabel.emit(this.customLabels);
    }

}
