import { element } from 'protractor';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Directive, ElementRef, Input } from "@angular/core";

@Directive({
    selector: '[imgSrc]'
})
export class ImageSrcDirective {

    @Input() imgSrc = '';

    constructor(private el: ElementRef, private http: HttpClient) {

    }

    ngOnInit() {

        this.http.get(this.imgSrc, { responseType: 'arraybuffer' }).subscribe(
            data => {
                var file = new Blob([data]);
                var fileURL = URL.createObjectURL(file);
                let reader = new FileReader();
                reader.readAsDataURL(file); // converts the blob to base64 and calls onload
                let element = this.el.nativeElement;
                reader.onload = function () {
                    element.src = reader.result; // data url
                };
            });

    }

}