import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Component, Input, OnInit, ViewChild, NgZone, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Table } from 'primeng/table';
import { MenuItem, LazyLoadEvent } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { ClassificationService } from './classification.service';
import { Classification } from '../_models/classification';
import { BreadcrumbService } from '../app.breadcrumb.service';
import { AppUtil } from '../_helpers/app.util';
import { Router } from '@angular/router';
import { Organization, RuleSet, makeRule } from '../_models';
import { SearchHeler } from '../_helpers';
import { log } from 'console';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-classification.list',
    templateUrl: './classification.list.component.html',
    styleUrls: ['./classification.list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [MessageService, ConfirmationService]
})
export class ClassificationListComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription = new Subscription();
    selectedTab: number;
    type: string;
    scrollTop: string;
    classifications: Classification[];
    @ViewChild('dt') table: Table;
    @Input() organization:any
    loading: boolean;
    menuItems: MenuItem[];
    classificationTypes: string[];
    totalRecords: number;
    dialogTitle: string;
    classificationDialog: boolean;
    tabsLoaded: boolean[];
    clonedClassifications: { [s: string]: Classification; } = {};
    rowStatus: { [s: string]: Object; } = {};
    dialogTitles: string[];
    noRecordMessages: string[];
    classification: Classification;
    term: string;
    loadingSpinner: boolean = false;
    currentMenuData: any;
    uuid: string = null;
    mergeForm: UntypedFormGroup;
    moveForm: UntypedFormGroup;
    showMerge: boolean;
    showMove: boolean;
    suggestionList: Classification[];
    clsSpinner: boolean;
    selectedClassifications: Classification[];
    classificationUuids: string[];
    showOptionString: string = '';

    constructor(public appUtil: AppUtil, private classificationService: ClassificationService, private formBuilder: UntypedFormBuilder,
        private messageService: MessageService, private confirmationService: ConfirmationService, private router: Router, 
        private breadcrumbService: BreadcrumbService,
        private searchHelper: SearchHeler,private ngZone: NgZone,
        ) {
        this.classificationTypes = ['CATEGORY_LEVEL1', 'CATEGORY_LEVEL2', 'CATEGORY_LEVEL3', 'VERTICAL', 'ECOSYSTEM'];
        this.dialogTitles = ["New Level1 Category", "New Level2 Category", "New Level3 Category", "New Vertical", "New Ecosystem"];
        this.noRecordMessages = ['No Level 1 Categories Found', 'No Level 2 Categories Found', 'No Level 3 Categories Found',
            'No Verticals Found', 'No Ecosystems Found'];
        this.breadcrumbService.setItems([
            { label: 'Classification List' }
        ]);

        this.loadMergeForm();
        this.loadMoveForm();
        this.showMerge = false;
        this.clsSpinner = false;
        this.showMove = false;
    }

    ngOnInit(): void {
        const storedScrollTop = localStorage.getItem('classificationScroll');
        if (storedScrollTop) {
            window.scrollTo(0, parseInt(storedScrollTop));
        }
        window.addEventListener('scroll', this.saveScrollPosition);
        this.term = '';
        this.classificationDialog = false;
        this.loading = true;
        this.selectedTab = 0;
        this.totalRecords = 0;
        this.classifications = [];
        this.tabsLoaded = [false, false, false, false, false, false];
        this.currentMenuData = { id: '' };
        this.menuItems = [];
    }

    openNew() {
        this.uuid = null;
        this.type = this.classificationTypes[this.selectedTab];
        this.classificationDialog = true;
    }

    fetchRecords(e: LazyLoadEvent = { first: 0, rows: -1, sortField: 'name', sortOrder: 1 }) {
        var pageParams = [];
        pageParams['pageNo'] = 0;
        pageParams['pageSize'] = -1;
        if (e.sortField && e.sortField != '')
            pageParams['sortBy'] = e.sortField;
        pageParams['sortDir'] = e.sortOrder == -1 ? 'desc' : 'asc';
        if (!this.tabsLoaded[this.selectedTab]) {
            this.tabsLoaded[this.selectedTab] = true;
        }
        if (this.term != '') {
            pageParams['query'] = this.term;
        }

        this.classificationService.getClassifications(this.classificationTypes[this.selectedTab], pageParams).subscribe(
            data => {
                this.classifications = data.classifications as any;
                this.totalRecords = data.totalItems;
                this.loading = false;
                this.appUtil.reinitializeShowMore();
                const data1 = localStorage.getItem('classificationScroll');
                if (data1) {
                  this.ngZone.runOutsideAngular(() => {
                    setTimeout(() => {
                      window.scrollTo(0, parseInt(data1));
                    }, 0);
                  });
                }
            })
    }

    handleChange(e) {
        this.selectedTab = e.index;
        const evnt: LazyLoadEvent = { first: 0, rows: 25, sortField: 'name', sortOrder: 1 };
        if (this.tabsLoaded[e.index]) {
            this.fetchRecords(evnt);
        }
        else {
            this.tabsLoaded[e.index] = true;
        }
    }

    edit(classification) {
        this.uuid = classification.uuid;
        this.type = this.classificationTypes[this.selectedTab]
        this.classificationDialog = true;
    }


    delete(classification: Classification) {
        this.confirmationService.confirm({
            message: 'Are you sure you want to delete ' + classification.name + '?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.classificationService.deleteClassification(classification.uuid).subscribe(
                    data => {
                        this.classifications = this.classifications.filter(val => val.uuid !== classification.uuid);
                        this.classification = {};
                        this.totalRecords = this.totalRecords - 1;
                        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Classification Deleted', life: 3000 });
                    },
                    error => {
                        this.loadingSpinner = false;
                        let errors = error.error;
                        if (errors.code && errors.code == 'VALIDATION_ERROR') {
                            this.messageService.add({ severity: 'error', summary: 'Error', detail: errors.subErrors[0].message, life: 3000 });
            
                        }
            
                    }
                    )

            }
        });

    }

    setCurrentItem(classification) {
        let items = [
            {
                label: 'Edit',
                icon: 'pi pi-plus',
                command: (event) => {
                    this.edit(classification);
                }
            },
            {
                label: 'Delete',
                icon: 'pi pi-trash',
                command: () => {
                    this.delete(classification);
                }
            }, 
            {
                label: 'Contact',
                icon: 'pi pi-external-link',
                command: () => {
                    this.processSearchCommand('contacts');
                
                }
            },
            {
                label: 'Organization',
                icon: 'pi pi-external-link',
                command: () => {
                   this.processSearchCommand('organizations');
                }
            } ,
            {
                label: 'Deal Tracker',
                icon: 'pi pi-external-link',
                command: () => {
                    this.processSearchCommand('deals');
                }
            },
            {
                label: 'Buy Side Screens',
                icon: 'pi pi-external-link',
                command: () => {
                    this.processSearchCommand('buysides');
                }
            },
             {
                label: 'Sell Side Screens',
                icon: 'pi pi-external-link',
                command: () => {
                    this.processSearchCommand('sellsides');
                }
            }   
        ];
        this.currentMenuData = classification;
        this.menuItems = items;
    }
    processSearchCommand(type)
    {  
      if (this.currentMenuData.type === 'CATEGORY_LEVEL1' || this.currentMenuData.type === 'CATEGORY_LEVEL2' || this.currentMenuData.type === 'CATEGORY_LEVEL3' ||
        this.currentMenuData.type === 'VERTICAL' || this.currentMenuData.type === 'ECOSYSTEM') 
        {
          const propertyMapping: Record<string, string> = {'CATEGORY_LEVEL1' : 'level1', 'CATEGORY_LEVEL2' : 'level2', 'CATEGORY_LEVEL3' : 'level3', 'VERTICAL' : 'vertical', 'ECOSYSTEM' : 'ecosystem'};
          const property = propertyMapping[this.currentMenuData.type];
          let rootRule: RuleSet = {"condition": "and",ruleSets: [],"rules": [makeRule({ property: property, searchType: 'exact' }, [this.currentMenuData.uuid], "in")]};
          this.searchHelper._search(rootRule, '/' + type, null, "relevance", -1, 0, this.appUtil.pageSize, true);
        }
    }
    ngOnDestroy() {
        this.subscriptions.unsubscribe();
         window.removeEventListener('scroll', this.saveScrollPosition);
         localStorage.setItem('classificationScroll', this.scrollTop); 
     }
     
     saveScrollPosition = (): void => {
         this.scrollTop = window.scrollY.toString();  
 
     }
    loadMergeForm() {
        this.mergeForm = this.formBuilder.group({
            master: ['', [Validators.required]],
            source1: ['', [Validators.required]],
            source2: []
        });
    }

    loadMoveForm() {
        this.moveForm = this.formBuilder.group({
            category: []
        })
    }

    get mf() {
        return this.mergeForm.controls;
    }

    get movef(){
        return this.moveForm.controls;
    }

    searchItem($event, field) {
        this.suggestionList = this.classifications.filter((currentValue, index) => {
            let item = (currentValue.name.toLowerCase().indexOf($event.query.toLowerCase()) != -1);
            let isMasterValue = (!this.mf.master.value || this.mf.master.value.uuid != currentValue.uuid);
            let isSource1Value = (!this.mf.source1.value || this.mf.source1.value.uuid != currentValue.uuid);
            let isSource2Value = (!this.mf.source2.value || this.mf.source2.value.uuid != currentValue.uuid)
            return item && ((field == 'master') ? (isSource1Value && isSource2Value) : (isMasterValue && ((field == 'source1') ? isSource2Value : isSource1Value)));
        });
    }

    showMergeOptions() {
        this.showMerge = true;
        this.mergeForm.reset();

    }

    mergeClassifications() {
        this.mergeForm.markAllAsTouched();
        if (!this.mergeForm.invalid) {
            this.clsSpinner = true;
            this.classificationService.mergeClassifications(this.mf.master.value.uuid, this.mf.source1.value.uuid, this.mf.source2.value ? this.mf.source2.value.uuid : null)
                .subscribe(
                    data => {
                        this.showMerge = false;
                        this.fetchRecords();
                        this.messageService.add({ severity: 'success', summary: 'Merge Successful', detail: "Merge was successfull.", life: 3000 });
                    },
                    error => {
                        let errors = error.error;
                        if (errors.code && errors.code == 'VALIDATION_ERROR') {
                            for (var i = 0; i < errors.subErrors.length; i++) {
                                this.mf[errors.subErrors[i].field].setErrors({ 'message': errors.subErrors[i].message });
                            }
                        }
                    },
                    () => {
                        this.clsSpinner = false;
                    }
                );
        }

    }

    showMoveOptions(){
        this.showMove = true;
        this.moveForm.reset();
    }

    moveClassifications(){

        this.showOptionString = '';
        if(this.movef.category.value != null && this.movef.category.value != "") {
            let uuids = []
            for(let i=0;i<this.selectedClassifications.length;i++){
                uuids.push(this.selectedClassifications[i].uuid)
            }
            if (!this.moveForm.invalid) {
                this.clsSpinner = true;
                this.classificationUuids = uuids;
                this.classificationService.moveClassifications(this.movef.category.value, uuids).
                subscribe(
                    data => {
                        this.showMove = false;
                        this.fetchRecords();
                        this.messageService.add({ severity: 'success', summary: 'Move Successful', 
                                                    detail: (this.classificationUuids.length>1?"Classifications":"Classification")+" Moved Successfully",life: 3000 });
                        this.selectedClassifications=[];
                    },
                    error => {
                        this.clsSpinner = false;
                        let errors = error.error;
                        if (errors.code && errors.code == 'VALIDATION_ERROR') {
                            if(errors.subErrors[0].field != 'name')
                                this.showOptionString = errors.subErrors[0].rejectedValue;
                            this.messageService.add({ severity: 'error', summary: 'Error', detail: errors.subErrors[0].message+'\n', life: 3000 });
                        }
                    },
                    () => {
                        this.clsSpinner = false;
                    }
                )
            }
        } else {
            this.messageService.add({ severity: 'error', summary: 'Form Errors', detail: "Please select at least one category to move.", life: 3000 });
        }
    }

    viewBuysides() {
        this.router.navigate(['/buy-side/'], { queryParams: { 'classifications': this.classificationUuids}});
    }

    viewSellsides() {
        this.router.navigate(['/sell-side/'], { queryParams: { 'classifications': this.classificationUuids}});
    }

}
