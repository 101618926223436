import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UploadExcelService {

    constructor(private http: HttpClient) { }


    uploadExcel(data, checkbox, type: string) {
        if(checkbox == null)
        {
            return this.http.post(environment.apiUrl + '/' + type, data);
        }
        return this.http.post(environment.apiUrl + '/' + type + '/' + checkbox, data);
    }
}
