import { map } from 'rxjs/operators';
import { HelpService } from './../help/help.service';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';


@Injectable()
export class HelpResolver implements Resolve<any> {

    constructor(private helpService: HelpService) { }

    resolve(): Observable<any> {
        return this.helpService.getHelpValues('all').pipe(
            map((result: any[]) => {
                let temp = {
                    ACCOUNT: [], CONTACT: [], DEAL: [], SPOTLIGHT: [], BUYSIDE: [], SELLSIDE: [], ENGAGEMENT: [], EMAIL_CAMPAIGN: [], TBP_USERS: [],
                };

                for (let i = 0; i < result.length; i++) {
                    if (result[i].keyId in temp) {
                        temp[result[i].keyId].push(result[i]);
                    }
                }

                return temp;
            })
        );
    }
}