import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AccountService } from './../_services';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { AppUtil } from '../_helpers';

@Component({
    templateUrl: 'login.component.html',
    providers: [MessageService, ConfirmationService]
})
export class LoginComponent implements OnInit {
    form: UntypedFormGroup;
    loading = false;
    submitted = false;
    showPassword = false;
    unverifiedProfile: string;
    linkForm: UntypedFormGroup;
    linkFormSubmitted = false;
    linkErrorCode: string;
    showDialog: boolean = false;
    hasError:Function;
    token: string;
    forcefullyPassword: boolean = false

    constructor(
        private formBuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public appUtil:AppUtil
    ) { }

    ngOnInit() {
        this.hasError = this.appUtil.hasError;
        this.unverifiedProfile = '';
        this.form = this.formBuilder.group({
            email: ['', [Validators.required, Validators.pattern(this.appUtil.emailPattern)]],
            password: ['', Validators.required]
        });
        this.linkForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.pattern(this.appUtil.emailPattern)]]
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        this.submitted = true;
        this.messageService.clear();
        this.unverifiedProfile = '';
        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        // this.accountService.login(this.f.email.value, this.appUtil.passwordEncrypt(this.f.password.value))
        //     .pipe(first())
        //     .subscribe(
        //         data => {
        //             // get return url from query parameters or default to home page
        //             this.form.reset();
        //             this.loading = false;
        //             if (data.status == "PENDING_PROFILE") {
        //                 this.router.navigate(['/profile/' + data.uuid]);
        //             }
        //             else {
        //                 const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        //                 this.router.navigateByUrl(returnUrl);
        //             }
        //         },
        //         error => {
        //             let errors = error.error;
        //             if (errors.code) {
        //                 if (errors.code == 'VALIDATION_ERROR' || errors.code == 'PROFILE_LOCKED') {
        //                     for (var i = 0; i < errors.subErrors.length; i++) {
        //                         this.f[errors.subErrors[i].field].setErrors({ 'message': errors.subErrors[i].message });
        //                     }
        //                 }
        //                 if (errors.code == 'UNVERIFIED_PROFILE') {
        //                     this.unverifiedProfile = errors.subErrors[0].rejectedValue;
        //                 }
        //                 if (errors.code == 'RESET_PASSWORD_FORCEFULLY') {
        //                     this.forcefullyPassword = true;
        //                     this.token = errors.token;
        //                 }
        //             }
        //             this.loading = false;
        //         }
        //     );
    }


    showHidePassword() {
        this.showPassword = !(this.showPassword);
    }

    showLinkForm() {
        this.linkFormSubmitted = false;
        this.linkErrorCode = '';
        this.linkForm.controls.email.reset();
        this.form.controls.email.setValue(this.f.email.value);
        this.showDialog = true;
    }

    hideDialog() {
        this.linkErrorCode = '';
        this.linkForm.controls.email.reset();
        this.showDialog = false;
    }

    sendActivationEmail() {
        this.linkFormSubmitted = true;
        this.linkErrorCode = '';
        if (this.linkForm.invalid) {
            return;
        }
        this.messageService.clear();
        this.accountService.sendActivationEmail(this.linkForm.controls.email.value).subscribe(
            data => {
                this.linkErrorCode = 'SUCCESS';
            },
            error => {
                let errors = error.error;
                if (errors.code) {
                    this.linkErrorCode = errors.code;
                }
            }
        );
    }

    goToSignUp() {
        this.router.navigate(['/account/register']);
    }
}