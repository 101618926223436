<p-panel [toggleable]="true" [collapsed]="false" expandIcon="pi pi-chevron-left" collapseIcon="pi pi-chevron-down" *ngIf="!showListing">
    <p-header>
        <span class="p-panel-title text-teal">
            <i class="pi pi-bars"></i> Notes & Attachments
        </span>
    </p-header>
    <ng-template pTemplate="icons" *ngIf="parent.status!=='DELETED'" >
        <button pButton class="p-panel-header-icon p-link" (click)="new();" label="" icon="pi pi-plus"></button>
    </ng-template>
    <p-table [autoLayout]="true" [value]="notes" [(selection)]="selectedNotes" dataKey="userId" [lazy]="true"
        (onLazyLoad)="search()"
        styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm" [rowHover]="true"
        [rows]="5" [paginator]="false" [loading]="loading" [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [totalRecords]="totalRecords"
        [alwaysShowPaginator]="false">
        <ng-template pTemplate="header">
            <tr>
                <th>Notes</th>
                <th>Attachment</th>
                <th>Modified On</th>
                <th>Modified By</th>
                <th>Action</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-note >
            <tr>
                <td class="wrap">
                    <span class="limitedText limitText">
                        <p [innerHTML]="note.notes"></p>
                    </span>
                    <a href="javascript:" (click)="appUtil.showHideClampedContent($event)"
                        class="moreless hidden ml-2 p-button-danger" style="float:right;display:block;">More</a>
                </td>
                <td class="wrap">
                    <a href="{{note.attachment.url}}" (click)="openDocument($event)"
                        *ngIf="note.attachment != null">{{note.attachment.name}}</a>
                </td>
                <td class="wrap">{{note.lastModifiedDate | date:"MM-dd-yyyy"}}</td>
                <td class="wrap">{{note.modifiedByUser}}</td>
                <td >
                    <button  pButton pRipple icon="pi pi-pencil" class="p-button-success mr-2"
                        (click)="edit(note)"></button>
                    <!--<button pButton pRipple [label]="Delete" icon="pi pi-trash" class="p-button-danger  mr-2" ></button> -->
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="footer" >
            <tr *ngIf="totalRecords>0">
                <td colspan="5" class="text-right"><a href="./{{noteType}}/{{parent.uuid}}/notes">View All</a></td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="5">No notes have been created yet.</td>
            </tr>
        </ng-template>
    </p-table>
</p-panel>

<div class="grid table-demo" *ngIf="showListing">
    <div class="col-12">
        <div class="card">
            <p-toolbar styleClass="headerSticky" style="display: flex; justify-content: space-between;">
                <div class="p-toolbar-group-left" style="display: flex;">
                    <div #srch>
                        <span class="p-input-icon-left p-input-icon-right">
                            <i class="pi pi-search"></i>
                            <input [(ngModel)]="term" pInputText type="text" placeholder="Search" class="table-search"
                                (keyup.enter)="search()" />
                            <i class="pi pi-bars"></i>
                        </span>
                    </div>
                </div>
                <div class="p-toolbar-group-right" style="display: flex;"><button pButton pRipple label="Back" icon="pi pi-arrow-left" class="p-button-success mr-2 mb-3" (click)="appUtil.return()"></button></div>
            </p-toolbar>
            &nbsp;&nbsp;
            <p-table [autoLayout]="true" [value]="notes" [(selection)]="selectedNotes" dataKey="userId" [lazy]="true" (onLazyLoad)="search()" [lazyLoadOnInit]="false"
                styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm" [rowHover]="true"
                [paginator]="false" [loading]="loading" [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [totalRecords]="totalRecords"
                [alwaysShowPaginator]="false">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Notes</th>
                        <th>Attachment</th>
                        <th>Modified On</th>
                        <th>Modified By</th>
                        <th>Action</th>
                    </tr>
                </ng-template>
              
                <ng-template pTemplate="body" let-note>
                    <tr>
                        <td class="wrap">
                            <span class="limitedText limitText">
                                <p [innerHTML]="note.notes"></p>
                            </span>
                            <a href="javascript:" (click)="appUtil.showHideClampedContent($event)"
                                class="moreless hidden ml-2 p-button-danger" style="float:right;display:block;">More</a>
                        </td>
                        <td class="wrap">
                            <a href="{{note.attachment.url}}" (click)="openDocument($event)"
                                *ngIf="note.attachment != null">{{note.attachment.name}}</a>
                        </td>
                        <td class="wrap">{{note.lastModifiedDate | date:"MM-dd-yyyy"}}</td>
                        <td class="wrap">{{note.modifiedByUser}}</td>
                        <td >
                            <button pButton pRipple icon="pi pi-pencil" class="p-button-success mr-2"
                                (click)="edit(note)"></button>
                            <!--<button pButton pRipple [label]="Delete" icon="pi pi-trash" class="p-button-danger  mr-2" ></button> -->
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
<note-edit [(show)]="showForm" noteType="{{noteType}}" (afterSave)="search()" [parent]="parent" [(noteUuid)]="uuid" (newContactCreated)="newContactCreated.emit($event)">
</note-edit>