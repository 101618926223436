import { Component, OnInit, ViewChild, NgZone, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../app.breadcrumb.service';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { first } from 'rxjs/operators';
import { MenuItem } from 'primeng/api';
import { HelpValue } from '../_models/help-value';
import { HelpService } from './help.service';
import { Subscription } from 'rxjs';
import { AppUtil } from '../_helpers';
@Component({
    selector: 'app-help-list',
    templateUrl: './help.list.component.html',
    styleUrls: ['./help.list.component.scss'],
    providers: [MessageService, ConfirmationService]
})
export class HelpListComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription = new Subscription();
    keyList = [];
    selectedKey: any;
    scrollTop: string;
    helpValues: HelpValue[];
    selectedValue: HelpValue;
    selectedValues: HelpValue[];
    form: UntypedFormGroup;
    @ViewChild('dt') table: Table;
    loading: boolean;
    helpDialog: boolean = false;
    submitted: boolean;
    loadingSpinner: boolean = false;
    currentMenuData: any;
    menuItems: MenuItem[];

    constructor(
        private service: HelpService,
        private formBuilder: UntypedFormBuilder,
        private breadcrumbService: BreadcrumbService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        private ngZone: NgZone,
        public appUtil: AppUtil,
    ) {
        this.breadcrumbService.setItems([
            { label: 'Help Text' }
        ]);
    }

    ngOnInit(): void {
        const storedScrollTop = localStorage.getItem('helpListScroll');
        if (storedScrollTop) {
            window.scrollTo(0, parseInt(storedScrollTop));
        }
        window.addEventListener('scroll', this.saveScrollPosition);
        this.form = this.formBuilder.group({
            //  label:['',Validators.required],
            key: ['', Validators.required],
            text: [],
            uuid: [],
            keyId: []
        });

        this.selectedKey = { name: '' };
        this.service.getHelpKeys().subscribe(
            data => {
                this.keyList = (data as any);
                this.selectedKey = this.keyList[0];
                this.fetchLookupValues();
            });
        this.currentMenuData = { id: '' };
        this.menuItems = [];
    }
    ngOnDestroy() {
        this.subscriptions.unsubscribe();
         window.removeEventListener('scroll', this.saveScrollPosition);
         localStorage.setItem('helpListScroll', this.scrollTop); 
     }
     
     saveScrollPosition = (): void => {
         this.scrollTop = window.scrollY.toString();  
 
     }
    fetchLookupValues() {
        this.service.getHelpValues(this.selectedKey.id).subscribe(
            data => {
                this.helpValues = data as any;
                const data1 = localStorage.getItem('helpListScroll');
                if (data1) {
                  this.ngZone.runOutsideAngular(() => {
                    setTimeout(() => {
                      window.scrollTo(0, parseInt(data1));
                    }, 0);
                  });
                }
            });
    }

    setCurrentItem(helpValue) {
        let items = [
            {
                label: 'Edit',
                icon: 'pi pi-plus',
                command: (event) => {
                    this.edit(helpValue);
                }
            }
        ];
        this.currentMenuData = helpValue;
        this.menuItems = items;
    }

    onKeyChange(event: any) {
        let result: any[] = this.keyList as any[];
        for (let i = 0; i < result.length; i++) {
            if (event.value == result[i].id) {
                this.selectedKey = result[i];
            }
        }
        this.fetchLookupValues();
    }

    edit(helpValue: HelpValue): void {
        this.form.reset();
        this.selectedValue = helpValue;
        this.helpDialog = true;
        this.f.keyId.setValue(this.selectedKey.id);
        this.form.setValue({ keyId: helpValue.keyId, text: helpValue['text'], key: helpValue['key'], uuid: helpValue.uuid });
    }

    get f() { return this.form.controls; }

    hideDialog() {
        this.submitted = false;
        this.helpDialog = false;
    }

    onSubmit() {
        this.submitted = true;
        if (this.form.invalid) {
            return;
        }
        this.loadingSpinner = true;
        let apiCall: any;
        apiCall = this.service.updateHelpValue(this.form.value);
        apiCall.pipe(first())
            .subscribe(
                data => {
                    let detail = 'Help Text Updated';
                    this.fetchLookupValues();
                    this.messageService.add({ severity: 'success', summary: 'Successful', detail: detail, life: 3000 });
                    this.hideDialog();
                    this.loadingSpinner = false;
                },
                error => {
                    this.loadingSpinner = false;
                    let errors = error.error;
                    if (errors.code && errors.code == 'VALIDATION_ERROR') {
                        for (var i = 0; i < errors.subErrors.length; i++) {
                            this.f[errors.subErrors[i].field].setErrors({ 'message': errors.subErrors[i].message });
                        }
                    }
                }
            );
    }

}
