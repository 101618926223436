import { Component, OnInit, ViewChild, NgZone, OnDestroy, ViewEncapsulation } from '@angular/core';
import { BreadcrumbService } from '../app.breadcrumb.service';
import { Router } from '@angular/router';
import { ConfirmationService, LazyLoadEvent, MenuItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { UsersService } from "./users.service";
import { User } from '../_models';
import { AccountService } from '../_services';
import { MessageService } from 'primeng/api';
import { AppUtil } from '../_helpers';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-tbpuser.list',
    templateUrl: './tbpuser.list.component.html',
    styleUrls: ['./tbpuser.list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [MessageService, ConfirmationService]
})
export class TbpuserListComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription = new Subscription();
    @ViewChild('dt') table: Table;
    users: User[];
    selectedUsers: User[];
    scrollTop: string;
    loading: boolean;
    loadingSpinner: boolean = false;
    userItems: MenuItem[];
    currentMenuData: any;
    user: UsersService;
    term: string;
    totalRecords: number = 0
    uuid:string;
    tbpUsers:any[];
    newContactOwner:any;
    newAccountOwner:any;   

    visible:boolean=false;
    constructor(private userService: UsersService, private breadcrumbService: BreadcrumbService,
        private router: Router, private accountService: AccountService, private confirmationService: ConfirmationService,
        public appUtil:AppUtil, private messageService: MessageService,private ngZone: NgZone) {
        this.breadcrumbService.setItems([
            { label: 'Tbp User Listing' }
        ]);
    }

    ngOnInit(): void {
        const storedScrollTop = localStorage.getItem('tbpUserScroll');
        if (storedScrollTop) {
            window.scrollTo(0, parseInt(storedScrollTop));
        }
        window.addEventListener('scroll', this.saveScrollPosition);
        this.term = '';
        this.loading = true;
        this.currentMenuData = { id: '' };
        this.userItems = [];
        this.userService.getTBPEmployees().subscribe(
            (users)=>{
                this.tbpUsers=users;      
             }
        ),
        (error)=>{
            console.error('error fetching contact ',error);
        }
    }

    search(e: LazyLoadEvent = { first: 0, rows: 25, sortField: 'name', sortOrder: 1 }) {

        var searchParams = {};
        searchParams['page'] = (e.first / e.rows);
        searchParams['pageSize'] = e.rows;
        var sortBy = []
        var sortDir = e.sortOrder == 1 ? 'ASC' : 'DESC';
        if (e.sortField && e.sortField != '') {
            sortBy[sortBy.length] = { "property": e.sortField, "direction": sortDir };
        }
        searchParams['sortDetails'] = sortBy;

        if (this.term != '') {
            searchParams['query'] = this.term;
        }
        this.loading = true;
        this.userService.searchTbpUSer(searchParams).subscribe(
            data => {
                let response = data as any;
                this.totalRecords = response.totalRecords;
                this.users = response.records;
                this.loading = false;
                const data1 = localStorage.getItem('tbpUserScroll');
                    if (data1) {
                      this.ngZone.runOutsideAngular(() => {
                        setTimeout(() => {
                          window.scrollTo(0, parseInt(data1));
                        }, 0);
                      });
                    }
            });
    }
    ngOnDestroy() {
        this.subscriptions.unsubscribe();
         window.removeEventListener('scroll', this.saveScrollPosition);
         localStorage.setItem('tbpUserScroll', this.scrollTop); 
     }
     
     saveScrollPosition = (): void => {
         this.scrollTop = window.scrollY.toString();  
 
     }
    showDialog() {
        this.visible = true;
    }

    setCurrentItem(user) {
        let items = [
            {
                label: 'Edit',
                icon: 'pi pi-plus',
                command: (event) => {
                    this.edit();
                }
            },
            {
                label: 'Delete',
                icon: 'pi pi-trash',
                command: (event) => {
                 this.showDialog();
                }
            }
        ];

        if (user.hasCredentials && user.status == "ACTIVE") {
            items.push(
                {
                    label: 'Reset Password',
                    icon: 'pi pi-key',
                    command: () => {
                        this.resetPassword();
                    }
                });
        }

        this.currentMenuData = user;
        this.userItems = items;
    }
    replaceOrDeleteUsers(uuid: string, newContactOwner?:any, newAccountOwner?: any){
        this.userService.deleteEmployee(this.currentMenuData.uuid, newContactOwner, newAccountOwner)
        .subscribe(
            success => {
                if (success) {
                    this.messageService.add({ severity: 'success', summary: 'Successful', detail: "Account updated successfully", life: 3000 });
                    this.search(); 
                    this.router.navigate(['/user']);
                    this.visible=false;
                }
            },
            error => {
                if (error.code && error.code == 'VALIDATION_ERROR') {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: error.subErrors[0].message, life: 3000 });

                }
            }
        );
    }
    onCancel() {
     this.visible=false;
      }

    edit() {
        this.router.navigate(['/user/edit/' + this.currentMenuData.uuid]);
    }

    openNew() {
        this.router.navigate(['/user/new/']);
    }

    resetPassword() {
        this.loading = true;
        let apiToCall = this.accountService.forgotPassword(this.currentMenuData.email);
        apiToCall.subscribe(
            data => {
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: "Reset password email has been sent successfully", life: 3000 });
                this.search();
            },
            error => {
                let errors = error.error;
                this.messageService.add({ severity: 'error', summary: 'Error', detail: errors.subErrors[0].message, life: 3000 });
            }
        );
    }

}
