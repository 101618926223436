import { FieldInfo } from './../../_models/field-info';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AppUtil } from 'src/app/_helpers';
import { Router } from '@angular/router';

@Component({
  selector: 'tbp-table-column',
  templateUrl: './tbp-table-column.component.html',
  styleUrls: ['./tbp-table-column.component.css']
})
export class TbpTableColumnComponent implements OnInit {

   // @Input() limitClass:string = null;
    @Input() fieldInfo:FieldInfo = null;
    @Input() object:any = null;
    @Input() patchMethod:Function = null;
    @Output() onPatch:EventEmitter<any> = new EventEmitter();

    constructor( private router: Router,
        public appUtil: AppUtil) { }

    ngOnInit() {
    }

    navigate() {
        this.router.navigate(["/" + this.fieldInfo.type +"/", this.object[this.fieldInfo.valueProperty]]);
    }

    patchObject($event)
    {
        this.onPatch.emit($event);
    }

}
