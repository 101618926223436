import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment } from '@angular/router';
import { AddSellsideComponent } from './add.sellside.component';
import { ClassificationResolver } from './../classification/classification.resolver';
import { ConfigurationResolver } from '../configuration/configuration.resolver';
import { UserResolver } from './../users/user.resolver';
import { ViewSellsideComponent } from './view.sellside.component'
import { HelpResolver } from '../help/help.resolver';
import { SellsideListComponent } from './sellside-list.component';
const routes: Routes = [
    
    {
        matcher: (url) => {// urls like buy-side, organization/:uuid/buy-side, contact/:uuid/buy-side, etc
            if ((url.length == 1 && url[0].path == 'sellsides') || (url.length >= 3 && (url[2].path == 'sellsides'))) {
                return {
                    consumed: url,
                    posParams: {
                        source: url.length !=1?url[0]:new UrlSegment('', {}),
                        sourceuuid: url.length !=1?url[1]:new UrlSegment('', {}),
                        showCheckbox: new UrlSegment(url.length ==4?'true':'false', {})
                    }
                };
            }
            return null;
        },
        component: SellsideListComponent,
        resolve: {
            classificationList: ClassificationResolver,
            lookupList: ConfigurationResolver
        }
    },
     {
        path: 'sell-side/new', component: AddSellsideComponent,
        resolve: {
            classificationList: ClassificationResolver,
            lookupList: ConfigurationResolver,
            tbpEmployees: UserResolver,
            helpList: HelpResolver,
        }
    },
    {
        path: 'sell-side/edit/:uuid', component: AddSellsideComponent,
        resolve: {
            lookupList: ConfigurationResolver,
            classificationList: ClassificationResolver,
            tbpEmployees: UserResolver,
            helpList: HelpResolver,
        }
    },
    {
        matcher: (url) => {
            if ((url.length > 1 && url[0].path == 'sell-side') && (url.length > 2 && (url[2].path == 'blind-profile' || url[2].path == 'prospects'))) {
                return {
                    consumed: url,
                    posParams: {
                        uuid: url[1],
                        filterFor:new UrlSegment('SELLSIDE_PROSPECT',{})
                    }
                };
            }
            return null;
        },
        component: ViewSellsideComponent,
        resolve: {
            classificationList: ClassificationResolver,
            tbpEmployees: UserResolver,
            lookupList: ConfigurationResolver
        }
    },
    {
        path: 'sell-side/:uuid', component: ViewSellsideComponent,
        resolve: {
            tbpEmployees: UserResolver,
            lookupList: ConfigurationResolver,
            classificationList: ClassificationResolver,
        }
    },
    {
        path: 'sell-side/:uuid/blind-profile', component: ViewSellsideComponent,
        resolve: {
            tbpEmployees: UserResolver
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [ClassificationResolver, ConfigurationResolver, UserResolver, HelpResolver]
})
export class SellsideRoutingModule { }
