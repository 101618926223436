export class Blindprofile {
    title?: string;
    headline?: string;
    customers?: string;
    business?: string;
    services?: string;
    investmentConsiderations?: string;
    investmentStrategy?: string;
    businessBuyer?: string;

    companyDescription?: string;
    serviceOfferings?: string;
    offices?: string;
    employees?: string;
    financials?: string;
    headquarter?: string;
    buyside?: string;
    sellside?: string;


}