<div class="card">
    <form [formGroup]="form" #cform>
        <div class="formgrid grid headerSticky">
            <div class="field col-12 md:col-12 text-right" *ngIf=" loadingSpinner == false">
                <p-button label="Save" icon="pi pi-check" iconPos="left" (click)="save()" styleClass="mr-2">
                </p-button>
                <p-button label="Cancel" icon="pi pi-times" (click)="cancel()" iconPos="left"
                    styleClass="p-button-secondary"></p-button>
            </div>
            <div class="field col-12 md:col-12 text-right" *ngIf="loadingSpinner">
                <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
                    fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
            </div>
        </div>
        <h5 class="custom-class">Deal Information</h5>
        <div class="p-fluid  grid">
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <p-calendar [showIcon]="true" [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.dealDate.errors }"
                        dateFormat="mm-dd-yy" formControlName="dealDate" inputId="icon" [readonlyInput]="true" pTooltip="{{getHelpText('Date')}}" tooltipPosition="top">
                    </p-calendar>
                    <label for="dealDate">Date <span class="mandate">*</span></label>
                </span>
                <span class="p-error" *ngIf="form.get('dealDate').hasError('required') && submitted">Date is
                    Required</span>
                <span class="p-error"
                    *ngIf="submitted && f.dealDate.invalid && f.dealDate.hasError('message')">{{f.dealDate.errors.message}}</span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <tbp-autocomplete [multiple]="true" [formControl]="f.buyerOrganizations"
                        [exclude]="f.targetOrganization.value" [type]="'Account'" pTooltip="{{getHelpText('Buyers/Investors')}}" tooltipPosition="top">
                    </tbp-autocomplete>
                    <label for="organizations">Buyers/Investors</label>
                    <span class="p-error"
                        *ngIf="form.get('buyerOrganizations').hasError('required') && submitted">Buyers/Investors is
                        Required</span>
                    <span class="p-error"
                        *ngIf="submitted && f.buyerOrganizations.invalid && f.buyerOrganizations.hasError('message')">{{f.buyerOrganizations.errors.message}}</span>
                </span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <tbp-autocomplete [multiple]="false" [formControl]="f.targetOrganization"
                        [exclude]="f.buyerOrganizations.value" [type]="'Account'" pTooltip="{{getHelpText('Target/Issuers')}}" tooltipPosition="top">
                    </tbp-autocomplete>
                    <label for="targetOrganization">Target/Issuer</label>
                    <span class="p-error"
                        *ngIf="form.get('targetOrganization').hasError('required') && submitted">Target/Issuer is
                        Required</span>
                    <span class="p-error"
                        *ngIf="submitted && f.targetOrganization.invalid && f.targetOrganization.hasError('message')">{{f.targetOrganization.errors.message}}</span>
                </span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <p-dropdown [options]="DEAL_TYPE" styleClass="form-control" maxlength="45"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.dealType.errors }" formControlName="dealType"
                        #ddDealType id="dealType" name="dealType" optionLabel="name" optionValue="code"
                        [autoDisplayFirst]="false" pTooltip="{{getHelpText('Deal Type')}}" tooltipPosition="top">
                    </p-dropdown>
                    <label for="dealType"
                        [ngClass]="{'datafilled': ddDealType.value != null && ddDealType.value !=''}">Deal Type <span
                            class="mandate">*</span></label>
                </span>
                <span class="p-error" *ngIf="form.get('dealType').hasError('required') && submitted">Deal Type is
                    Required</span>
                <span class="p-error"
                    *ngIf="submitted && f.dealType.invalid && f.dealType.hasError('message')">{{f.dealType.errors.message}}</span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <p-inputNumber mode="decimal" [minFractionDigits]="2" maxlength="10" id="transactionValue"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.transactionValue.errors }"
                        formControlName="transactionValue" [useGrouping]="false" pTooltip="{{getHelpText('Trans Value')}}" tooltipPosition="top"></p-inputNumber>
                    <label for="transactionValue">Transaction Value ($m)</label>
                </span>
                <span class="p-error" *ngIf="form.get('transactionValue').hasError('required') && submitted">Transaction
                    Value ($m) is Required</span>
                <span class="p-error"
                    *ngIf="submitted && f.transactionValue.invalid && f.transactionValue.hasError('message')">{{f.transactionValue.errors.message}}</span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <p-inputNumber mode="decimal" [minFractionDigits]="2" maxlength="10" id="evRev"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.evRev.errors }" formControlName="evRev"
                        [useGrouping]="false" pTooltip="{{getHelpText('EV/REV')}}" tooltipPosition="top"></p-inputNumber>
                    <label for="evRev">EV/Revenue ($m)</label>
                </span>
                <span class="p-error" *ngIf="form.get('evRev').hasError('required') && submitted">EV/Revenue ($m) is
                    Required</span>
                <span class="p-error"
                    *ngIf="submitted && f.evRev.invalid && f.evRev.hasError('message')">{{f.evRev.errors.message}}</span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <p-inputNumber mode="decimal" [minFractionDigits]="2" maxlength="10" id="evEbitda"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.evEbitda.errors }" formControlName="evEbitda"
                        [useGrouping]="false" pTooltip="{{getHelpText('EV/EBITDA')}}" tooltipPosition="top"></p-inputNumber>
                    <label for="evEbitda">EV/EBITDA</label>
                </span>
                <span class="p-error" *ngIf="form.get('evEbitda').hasError('required') && submitted">EV/EBITDA is
                    Required</span>
                <span class="p-error"
                    *ngIf="submitted && f.evEbitda.invalid && f.evEbitda.hasError('message')">{{f.evEbitda.errors.message}}</span>
            </div>
            <div class="field col-12 md:col-12">
                <label for="targetDescription">Business Description [Target/Issuer] </label><span
                    class="mandate">*</span>
                <editor #editor formControlName="targetDescription" [required]="true" [init]="appUtil.getInitData" pTooltip="{{('Business Description')}}" tooltipPosition="top"></editor>
                <span class="p-error" *ngIf="form.get('targetDescription').hasError('required') && submitted">Business
                    Description of Target/Issuer is Required</span>
                <span class="p-error"
                    *ngIf="submitted && f.targetDescription.invalid && f.targetDescription.hasError('message')">{{f.targetDescription.errors.message}}</span>
            </div>
            <div class="field col-12 md:col-12">
                <label for="targetDescription">Notes </label>
                <editor #editor formControlName="notes" [init]="appUtil.getInitData" pTooltip="{{('Business Description')}}" tooltipPosition="top"></editor>
            </div>
        </div>

        <h5 class="custom-class">Classification</h5>
        <div class="p-fluid  grid">
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <tbp-autocomplete [formControl]="f.level1" [type]="'CATEGORY_LEVEL1'"
                        [items]="classificationList.CATEGORY_LEVEL1" pTooltip="{{getHelpText('Level1')}}" tooltipPosition="top">
                    </tbp-autocomplete>
                    <label for="level1">Level1 </label>
                </span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <tbp-autocomplete [formControl]="f.level2" [type]="'CATEGORY_LEVEL2'"
                        [items]="classificationList.CATEGORY_LEVEL2" pTooltip="{{getHelpText('Level2')}}" tooltipPosition="top">
                    </tbp-autocomplete>
                    <label for="level2">Level2 </label>
                </span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <tbp-autocomplete [formControl]="f.level3" [type]="'CATEGORY_LEVEL3'"
                        [items]="classificationList.CATEGORY_LEVEL3" pTooltip="{{getHelpText('Level3')}}" tooltipPosition="top">
                    </tbp-autocomplete>
                    <label for="level3">Level3 </label>
                </span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <tbp-autocomplete [formControl]="f.vertical" [type]="'VERTICAL'"
                        [items]="classificationList.VERTICAL" pTooltip="{{getHelpText('Vertical/Industry')}}" tooltipPosition="top">
                    </tbp-autocomplete>
                    <label for="vertical">Vertical/Industry </label>
                </span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <tbp-autocomplete [formControl]="f.ecosystem" [type]="'ECOSYSTEM'"
                        [items]="classificationList.ECOSYSTEM" pTooltip="{{'Ecosystem'}}" tooltipPosition="top">
                    </tbp-autocomplete>
                    <label for="ecosystem">Ecosystem </label>
                </span>
            </div>
        </div>
    </form>
</div>
<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>