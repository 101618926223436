export class Task {
    uuid?: string;
    dueDate?: string;
    priority?: string;
    reminderChecked?: string;
    subject?: string;
    reminderDate?: string;
    assignedTo?: string;
    description?: string;
    relatedType?: string;
    spotlight?: string;
    contact?: string;
    sellSideScreen?:string;
    buySideScreen?:string;
    engagement?:string;
    organization?: string;
    status?: string;
    relatedTo?: string;
    completionDate?: string;
    completionComment?: string;
}