import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from '../app.breadcrumb.service';
import { UsersService } from "./users.service";
import { User } from '../_models/user';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DataService } from '../_services/data.service';
import { AccountService } from '../_services';
import { AuthUser } from '../_models';
import { AppUtil } from '../_helpers';
@Component({
    selector: 'app-tbpuser.view',
    templateUrl: './tbpuser.view.component.html',
    styleUrls: ['./tbpuser.view.component.scss'],
    providers: [MessageService, ConfirmationService]
})
export class TbpuserViewComponent implements OnInit {
    user: User;
    uuid: string;
    userStatus: boolean = false;
    labelStatus: string;
    currntStatus: string;
    currentMenuData: any;
    searchParams: any;
    tbpUsers: any[];
    newContactOwner: any;
    newAccountOwner: any;
    userUuid: any;
    users: any;

    visible: boolean = false;

    constructor(private messageService: MessageService,
        private confirmationService: ConfirmationService,
        private breadcrumbService: BreadcrumbService,
        private usersService: UsersService,
        private route: ActivatedRoute,
        private accountService: AccountService,
        public appUtil: AppUtil,
        private router: Router, private dataService: DataService,) {
        this.breadcrumbService.setItems([
            { label: 'View Tbp User' }
        ]);
    }
    ngOnInit(): void {
        this.user = new User;
        this.uuid = this.route.snapshot.params.uuid;
        this.users = new AuthUser;
        this.users = this.accountService.getById(this.uuid);
        this.usersService.getTbpUser(this.route.snapshot.params.uuid).subscribe(data => {
            this.user = data as any;
            this.currntStatus = this.user.status;
            if (this.user.status == "REVOKED" && this.user.hasCredentials) {
                this.userStatus = true;
                this.labelStatus = 'Activate Profile';
            }
            if (this.user.status == "ACTIVE" && this.user.hasCredentials) {
                this.userStatus = true;
                this.labelStatus = 'Revoke Profile';
            }
        });
        let message = this.dataService.getDataByKey('message');
        if (message != undefined) {
            setTimeout(() => { this.messageService.add({ severity: 'success', summary: 'Successful', detail: message, life: 3000 }); })
            this.dataService.setData('message', undefined);
        }
        this.usersService.getTBPEmployees().subscribe(
            (users) => {
                this.tbpUsers = users;
            }
        ),
            (error) => {
                console.error('error fetching contact ', error);
            }
        this.route.paramMap.subscribe(params => {
            this.userUuid = params.get('uuid');
        });

    }

    showDialog() {
        this.visible = true;
    }
    edit() {
        this.router.navigate(['/user/edit/' + this.uuid]);
    }
    replaceOrDeleteUsers(uuid: string, newContactOwner?:any, newAccountOwner?: any){
        let profileUuid =  this.accountService.userValue.uuid;
        if(profileUuid == uuid){
            this.messageService.add({ severity: 'error', summary: 'Error', detail: "You cannot delete yourself", life: 3000 });
            return;
        }
        this.usersService.deleteEmployee(uuid, newContactOwner, newAccountOwner)
        .subscribe(
          success => {
            if (success) {
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: "Account owner updated", life: 3000 });
                setTimeout(() => {
                    this.router.navigate(['/user']);
                }, 600);
            }
          },
          error => {
            let errors = error.error;
            if (errors.code && errors.code == 'VALIDATION_ERROR') {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: errors.subErrors[0].message, life: 3000 });
            }
        }
            );
    }
    onCancel() {
        this.visible = false;
    }
    changeStatus() {
        let st = '';
        if (this.currntStatus === "ACTIVE") {
            this.labelStatus = "Activate Profile";
            st = 'REVOKED';
        }
        if (this.currntStatus === "REVOKED") {
            this.labelStatus = "Revoke Profile";
            st = 'ACTIVE';
        }
        let dataToPost: any = { status: st };
        let apiToCall = this.usersService.updateStatus(this.user.uuid, dataToPost);
        apiToCall.subscribe(
            data => {
                this.user = data as any;
                this.currntStatus = this.user.status;
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: "Status has been changed successfully", life: 3000 });
                this.userStatus = true;
            },
            error => {
                let errors = error.error;
                this.userStatus = true;
                if (errors.code && errors.code == 'VALIDATION_ERROR') {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: errors.subErrors[0].message, life: 3000 });
                }
            }
        );
    }
}
