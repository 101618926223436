<div>
    <form [formGroup]="form" (ngSubmit)="save()" #cform>
        <div class="card">
            <div class="formgrid grid headerSticky">
                <div class="field col-12 md:col-12 text-right" *ngIf="loadingSpinner == false">
                    <button pButton label="Save" icon="pi pi-check" iconPos="left" class="mr-2"></button>
                    <p-button label="Cancel" icon="pi pi-times" iconPos="left" class="mr-2" (click)="cancel()"
                        styleClass="p-button-secondary"></p-button>
                </div>
                <div class="field col-12 md:col-12 text-right" *ngIf="loadingSpinner">
                    <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner"
                        strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                </div>
            </div>
            <h5 class="custom-class">Email Campaign</h5>
            <div class="p-fluid  grid">
                <div class="field col-12 md:col-4">
                    <span class="p-float-label">
                        <input id="title" formControlName="title" type="text" maxlength="40" pInputText
                            [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.title.errors }" pTooltip="{{getHelpText('Title')}}" tooltipPosition="top">
                        <label for="Title">Title <span class="mandate">*</span> <span
                                class="p-float-label"></span></label>
                        <span class="p-error" *ngIf="submitted && f.title.invalid && f.title.hasError('required')">Title
                            is Required.</span>
                        <span class="p-error"
                            *ngIf="submitted && f.title.invalid && f.title.hasError('message')">{{f.title.errors.message}}</span>
                    </span>
                </div>
                <div class="field col-12 md:col-4">
                    <span class="p-float-label">
                        <p-dropdown [options]="campaignType" styleClass="form-control" [autoDisplayFirst]="false"
                            [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.type.errors }" formControlName="type"
                            name="type" optionLabel="value" id="type" pTooltip="{{getHelpText('Type')}}" tooltipPosition="top">
                        </p-dropdown>
                        <label for="type">Type <span class="mandate">*</span></label>
                    </span>
                    <span class="p-error" *ngIf="submitted && f.type.invalid && f.type.hasError('required')">Type is
                        Required.</span>
                </div>
                <div class="field col-12 md:col-4"
                    *ngIf="f.type.value.value === 'Industry Report' || f.type.value.value === 'Marketing' || f.type.value.value === undefined"
                    id="commonType">
                    <span class="p-float-label">
                        <input id="relatedTo" formControlName="relatedTo" type="text" maxlength="49" pInputText
                            [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.relatedTo.errors }" pTooltip="{{getHelpText('Related To')}}" tooltipPosition="top">
                        <label for="relatedTo">Related To</label>
                    </span>
                    <span class="p-error"
                        *ngIf="submitted && f.relatedTo.invalid && f.relatedTo.hasError('message')">{{f.relatedTo.errors.message}}</span>
                    <span class="p-error"
                        *ngIf="submitted && f.relatedTo.invalid && f.relatedTo.hasError('required')">Related To is
                        Required.</span>
                </div>
                <div class="field col-12 md:col-4" *ngIf="f.type.value.value === 'Spotlight'">
                    <span class="p-float-label">
                        <p-autoComplete formControlName="spotlight" [suggestions]="spotlightResults"
                            (completeMethod)="searchSpotlights($event)" field="title">
                        </p-autoComplete>
                        <label for="spotlight">Spotlight </label>

                    </span>
                    <span class="p-error"
                        *ngIf="submitted && f.spotlight.invalid && f.spotlight.hasError('required')">Spotlight is
                        Required.</span>
                    <span class="p-error"
                        *ngIf="submitted && f.spotlight.invalid && f.spotlight.hasError('message')">{{f.spotlight.errors.message}}</span>
                </div>

                <div class="field col-12 md:col-4" *ngIf=" f.type.value.value === 'Engagement Outreach'">
                    <span class="p-float-label">
                        <p-autoComplete formControlName="engagement" [suggestions]="engagementResults"
                            (completeMethod)="searchEngagements($event)" field="name">
                        </p-autoComplete>
                        <label for="engagement">Engagement </label>

                    </span>
                    <span class="p-error"
                        *ngIf="submitted && f.engagement.invalid && f.engagement.hasError('required')">Engagement is
                        Required.</span>
                    <span class="p-error"
                        *ngIf="submitted && f.engagement.invalid && f.engagement.hasError('message')">{{f.engagement.errors.message}}</span>
                </div>

                <div class="field col-12 md:col-4" *ngIf="f.type.value.value === 'BuySide Screen Outreach'">
                    <span class="p-float-label">
                        <p-autoComplete formControlName="buySide" [suggestions]="buySideResults"
                            (completeMethod)="searchBuySide($event)" field="screenTitle">
                        </p-autoComplete>
                        <label for="buySide">BuySide Screen Outreach </label>

                    </span>
                    <small class="p-error"
                        *ngIf="submitted && f.buySide.invalid && f.buySide.hasError('required')">BuySide Screen is
                        required.</small>
                    <span class="p-error"
                        *ngIf="submitted && f.buySide.invalid && f.buySide.hasError('message')">{{f.buySide.errors.message}}</span>
                </div>

                <div class="field col-12 md:col-4" *ngIf="f.type.value.value === 'SellSide Screen Outreach'">
                    <span class="p-float-label">
                        <p-autoComplete formControlName="sellSide" [suggestions]="sellSideResults"
                            (completeMethod)="searchSellSide($event)" field="screenTitle">
                        </p-autoComplete>
                        <label for="sellSide">SellSide Screen Outreach </label>

                    </span>
                    <small class="p-error"
                        *ngIf="submitted && f.sellSide.invalid && f.sellSide.hasError('required')">SellSide Screen is
                        required.</small>
                    <span class="p-error"
                        *ngIf="submitted && f.sellSide.invalid && f.sellSide.hasError('message')">{{f.sellSide.errors.message}}</span>
                </div>
                <div class="field col-12 md:col-4">
                    <span class="p-float-label">
                        <input id="relatedTag" formControlName="relatedTag" type="text" maxlength="256" pInputText
                           pTooltip="{{getHelpText('Related Tag')}}" tooltipPosition="top">
                        <label for="relatedTag">Related Tag</label>
                    </span>
                </div>
                <div>
                </div>
            </div>

            <div class="p-fluid  grid">
                <div class="field col-12 md:col-4">
                    <label for="pdfFile">Attachments</label> <span class="mandate">*</span>
                    <p-fileUpload name="file" [url]="uploadURL" (onUpload)="onUploadAttachment($event)"
                    [maxFileSize]="this.appUtil.maxFileSize" [auto]="true" chooseLabel="Browse" [multiple]="true" pTooltip="{{getHelpText('Attachments')}}" tooltipPosition="top">
                        <ng-template pTemplate="content">
                            <ul *ngIf="f.uploadedFiles.value!=null && f.uploadedFiles.value.length > 0">
                                <li *ngFor="let file of f.uploadedFiles.value">
                                    <a href="{{file.url}}" (click)="openDocument($event)">{{file.nameWithExtension}}</a>
                                    <span title="Remove Attachment"><a href="#"
                                            (click)="deleteDocument($event,file.uuid)"><i class="pi pi-trash"
                                                style="font-size: .75rem;color:red;margin-left: 20px;font-weight: bold;font-size: bold;"></i></a></span>
                                </li>
                            </ul>
                        </ng-template>
                    </p-fileUpload>
                    <span class="p-error"
                        *ngIf="submitted && f.uploadedFiles.invalid && f.uploadedFiles.hasError('required')">Attachments
                        is Required.</span>
                </div>
            </div>
            <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-4" *ngFor="let lookupValue of lookupList.EMAIL_PREFERENCES">
                    <p-checkbox formControlName="lookupValue" (onChange)="onChangeCheckbox($event,lookupValue.uuid)"
                        [value]="lookupValue.uuid" label="{{lookupValue.value}}"></p-checkbox>
                </div>
            </div>
            <div class="p-fluid  grid">
                <div class="field col-12">
                    <label for="content">Content </label><span class="mandate">*</span>
                    <editor #editor formControlName="content" [init]="appUtil.getInitData" pTooltip="{{('Content')}}" tooltipPosition="top"></editor>
                    <span class="p-error"
                        *ngIf="submitted && f.content.invalid && f.content.hasError('required')">Content is
                        Required.</span>
                    <span class="p-error"
                        *ngIf="submitted && f.content.invalid && f.content.hasError('message')">{{f.content.errors.message}}</span>
                </div>
            </div>

        </div>
    </form>
</div>
<p-toast></p-toast>