import { Component, OnInit, ViewChild, NgZone, OnDestroy, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, LazyLoadEvent, MenuItem, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { BreadcrumbService } from '../app.breadcrumb.service';
import { EmailCampaign } from '../_models/emailcampaign';
import { AccountService } from '../_services';
import { EmailCampaignService } from './email-campaign.service';
import { FieldInfo, makeFieldInfo } from '../_models';
import { AppUtil, SearchHeler } from '../_helpers';
import * as mimeType from 'mime'
import { HttpClient } from '@angular/common/http';
import { UsersService } from '../users/users.service';
import { OrganizationService } from '../organization/organization.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'email-campaigns-list',
  templateUrl: './email-campaigns-list.component.html',
  styleUrls: ['./email-campaigns-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MessageService, ConfirmationService]
})
export class EmailCampaignsListComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  @ViewChild('cform') formElement;
  form: UntypedFormGroup;
  scrollTop: string;
  emailCampaigns: EmailCampaign[];
  selectedEmailCampaigns: EmailCampaign[];
  @ViewChild('dt') table: Table;
  term: string;
  loading: boolean = false;
  currentMenuData: any;
  totalRecords: number = 0;
  emailCampaignItems: MenuItem[];
  uploadURL: string;
  emailCampaign: EmailCampaign;
  selectedUuids: [];
  viewRecipients: boolean = false;
  selectedColumns:any[];
  loadingSpinner: boolean = false;
  sourceuuid: string = "";
  source: string = "";
  searchUrl:string = '/email-campaign';
  fieldInfo:FieldInfo[];
  showCheckbox: boolean = false;

  constructor(
      private emailCampaignService: EmailCampaignService,
      private breadcrumbService: BreadcrumbService,
      private router: Router,
      public accountService: AccountService,
      private confirmationService: ConfirmationService,
      private searchHelper: SearchHeler,
      private http: HttpClient,
      private messageService: MessageService,
      private userService: UsersService,
      private organizationService: OrganizationService,
      private route: ActivatedRoute,
      public appUtil:AppUtil,
      private ngZone: NgZone,
  ) {
      this.breadcrumbService.setItems([
          { label: 'Email Campaign Listing' }
      ]);
      this.term = '';
  }

  ngOnInit(): void {
    const storedScrollTop = localStorage.getItem('emailCampaignScroll');
    if (storedScrollTop) {
        window.scrollTo(0, parseInt(storedScrollTop));
    }
    window.addEventListener('scroll', this.saveScrollPosition);
    this.route.params.subscribe(
        data => {
            this.source = data.source == '' ? "" : data.source;
            this.sourceuuid = data.sourceuuid == '' ? "" : data.sourceuuid;
            this.searchUrl = this.source != "" ? ((data.source == ''?'':'/'+data.source) + (data.sourceuuid == ''?'':'/' + data.sourceuuid) + '/campaigns/') : this.searchUrl;
        }
    )
    let tmpFieldInfo:FieldInfo[] = 
    [
        makeFieldInfo({property:"title", label: 'Title', type: 'string', searchType: 'text', forSearchTermQuery:true, columnIndex:1, linkPrefix:'/email-campaign/', linkType:"p", visible:'A', sortable:true}),
        makeFieldInfo({property:"type", label: 'Type', type: 'string', searchType: 'text', columnIndex:2, visible:'A', sortable:true, columnWidth:"15rem"}),
        makeFieldInfo({property:"relatedTo", label: 'Related To', type: 'string', searchType: 'text', columnIndex:3, visible:'A', sortable:false}),
        makeFieldInfo({property:"relatedTag", label: 'Related Tag', type: 'string', searchType: 'text', columnIndex:4, sortable:true}),
        makeFieldInfo({property:"status", label: "Status", type: "enum", searchType: "exact", columnIndex:5, searchable:false}),
        makeFieldInfo({property:"", label: 'Participants', type: 'string', sortable:false, searchable:false, columnIndex:6}),
        makeFieldInfo({property:"", label: 'Emails Sent', type: 'number', sortable:false, searchable:false}),
        makeFieldInfo({property:"", label: 'Opened', type: 'number', sortable:false, searchable:false}),
        makeFieldInfo({property:"", label: 'Clicks', type: 'number', sortable:false, searchable:false}),
    //    makeFieldInfo({property:"uploadedFiles", label: "Attachments", type: "array", visible:'F', searchable:false, sortable:false}),
        makeFieldInfo({property:"createdDate", label: "Creation Date", type: "date", searchType: "date", visible:'F'}),
        makeFieldInfo({property:"relevance", label: "Relevance", type: "date", searchable:false, visible:'H'}),
        makeFieldInfo({property:"lastModifiedDate", label: "Modification Date", type: "date", searchType: "date", visible:'T', columnWidth:'10rem'}),
        makeFieldInfo({property:"buySide", label: "BuySide Screen", type: "string", searchType: "text", visible:'H', sortable:false}),
        makeFieldInfo({property:"sellSide", label: "SellSide Screen", type: "string", searchType: "text", visible:'H', sortable:false}),
        makeFieldInfo({property:"engagement", label: "Engagement Outreach", type: "string", searchType: "text", visible:'H', sortable:false}),
        makeFieldInfo({property:"spotlight", label: "Spotlight", type: "string", searchType: "text", visible:'H', sortable:false}),
        makeFieldInfo({property:"content", label: 'Content', type: 'string',sortable:true, searchable:true, visible:'F'}),
    ]
    this.fieldInfo = tmpFieldInfo;
    this.currentMenuData = { id: '' }
    this.emailCampaignItems = [];
  }

  _search(e) {
    let searchParams = this.searchHelper.prepareSearchParams(e.searchTerm, e.filter,e.sortField,e.sortOrder,e.first,e.rows, this.fieldInfo);
           this.term = e.searchTerm;
        if(this.source == "" || (this.source == "buyside") || (this.source == "sellside")){
            if(this.source != "" && this.sourceuuid != "") {
                searchParams['rootRule'].condition = 'and';
                searchParams['rootRule']['rules'] ==  undefined ? [] : searchParams['rootRule']['rules'];
                searchParams['rootRule'].rules.push({ "searchType": "exactsearch",
                            "field": (this.source=='buyside'? 'buySide' : (this.source == 'sellside' ? 'sellSide' : this.source))+".uuid", "value": this.sourceuuid, "operator": "in"});
                }
                this.emailCampaignService.searchEmailCampaign(searchParams).subscribe(
                    data => {
                        this.setResponseDetails(data as any);
                });
        } else {
            if(this.source == 'contact') {
                this.userService.getEmailCampaigns(this.sourceuuid, searchParams).subscribe(
                    data => {
                        this.setResponseDetails(data as any);
                    }
                )
            }
            if(this.source == 'organization'){
                this.organizationService.getEmailCampaigns(this.sourceuuid, searchParams).subscribe(
                    data => {
                        this.setResponseDetails(data as any);
                    }
                )
            }
        }
  }

  setResponseDetails(response: any) {
    this.totalRecords = response.totalRecords;
    this.emailCampaigns = response.records;
    this.loading = false;
    this.loadingSpinner = false;
    const data1 = localStorage.getItem('emailCampaignScroll');
    if (data1) {
      this.ngZone.runOutsideAngular(() => {
        setTimeout(() => {
          window.scrollTo(0, parseInt(data1));
        }, 0);
      });
    }
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
     window.removeEventListener('scroll', this.saveScrollPosition);
     localStorage.setItem('emailCampaignScroll', this.scrollTop); 
 }
 
 saveScrollPosition = (): void => {
     this.scrollTop = window.scrollY.toString();  

 }
  openDocument(event) {
    // Prevent default behavior when clicking a link
    event.preventDefault();

    // Get filename from href
    var filename = event.target.href;
    this.http.get(filename, { responseType: 'arraybuffer' }).subscribe(
        data => {
            var file = new Blob([data], { type: mimeType.getType(filename) });
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        });
}

  openNew() {
      this.router.navigate(['/email-campaign/new']);
  }

  setCurrentItem(emailCampaign) {
      let items = [];

      if (emailCampaign.mailchimpUrl != null) {
          items.push({
              label: 'View',
              icon: 'pi pi-file',
              command: (event) => {
                  window.open(emailCampaign.mailchimpUrl, "_blank");
              }
          });
      }
      items.push({
          label: 'View Recipients',
          icon: 'pi pi-users',
          command: (event) => {
              this.router.navigate(['/email-campaign/' + this.currentMenuData.uuid + "/recipients"]);
          }
      });

      if (emailCampaign.status != 'SENT') {
          items.push({
              label: 'Edit',
              icon: 'pi pi-plus',
              command: (event) => {
                  this.router.navigate(['/email-campaign/edit/' + this.currentMenuData.uuid]);
              }
          });

      }
      else {
          items.push({
              label: 'Copy',
              icon: 'pi pi-copy',
              command: (event) => {
                  alert("coming soon")
              }
          });
      }
      items.push({
          label: 'Create a copy',
          icon: 'pi pi-copy',
          command: (event) => {
            this.router.navigate(['/email-campaign/copy/' + this.currentMenuData.uuid]);
          }
      });
      if (emailCampaign.status != 'SENDING' || emailCampaign.status != 'CANCELING') {
          items.push({
              label: 'Delete',
              icon: 'pi pi-trash',
              command: (event) => {
                  this.deleteCampaign(emailCampaign);
              }
          });
      }
      this.currentMenuData = emailCampaign;
      this.emailCampaignItems = items;
  }

  viewRecipientsList(emailCampaign : any) {
      this.emailCampaign = emailCampaign;
      this.viewRecipients=true;
  }

  deleteCampaign(emailCampaign : EmailCampaign) {

      let campaignsToDelete = []
      campaignsToDelete.push(emailCampaign.uuid)
      this.confirmationService.confirm({
          message: 'Are you sure want to delete this Campaign?',
          header: 'Confirm',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
              this.loadingSpinner = true;
              this.emailCampaignService.deleteCampaigns(campaignsToDelete).subscribe(
                  data => {
                      this.messageService.add({ severity: 'success', summary: 'Delete Successful', detail: 'Email Campaign Deleted.', life: 3000 });
                      this._search({ first: 0, rows: 25, sortField: 'lastModifiedDate', sortOrder: -1 });
                  },
                  error => {
                      let errors = error.error;
                      if (errors.code && errors.code == 'VALIDATION_ERROR') {
                          this.messageService.add({ severity: 'error', summary: 'Error', detail: errors.subErrors[0].message, life: 3000 });
                      }
                      this.loadingSpinner = false;
                  }
              )
          }
      });
  }

  _updateSelectedColumns($event) {
    this.selectedColumns = $event;
  }
}
