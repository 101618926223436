import { AppUtil } from './../_helpers/app.util';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from '../app.breadcrumb.service';
import { UsersService } from "../users/users.service";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Location } from '@angular/common';
import { DataService } from "../_services/data.service";

@Component({
    selector: 'app-user-profile',
    templateUrl: './user.profile.component.html',
    styleUrls: ['./user.profile.component.scss'],
    providers: [MessageService, ConfirmationService]
})
export class UserProfileComponent implements OnInit {

    @ViewChild('cform') formElement;
    form: UntypedFormGroup;
    submitted = false;
    lookupList: any = { REVENUE: [] };
    classificationList: any = {
        CATEGORY_LEVEL1: [], CATEGORY_LEVEL2: [], CATEGORY_LEVEL3: [],
        VERTICAL: [], ECOSYSTEM: []
    };
    countries = [];
    states = [];
    loadingSpinner: boolean = false;
    uuid: string;
    status: string;
    constructor(
        private _location: Location,
        private usersService: UsersService,
        private breadcrumbService: BreadcrumbService,
        private formBuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private dataService: DataService,
        private messageService: MessageService,
        public appUtil: AppUtil) {
        this.breadcrumbService.setItems([
            { label: 'CREATE PROFILE' }
        ]);
    }

    ngOnInit(): void {
        this.classificationList = this.route.snapshot.data['classificationList'];
        this.lookupList = this.route.snapshot.data['lookupList'];
        this.countries = this.route.snapshot.data['countries'];
        this.loadform();
        this.uuid = this.route.snapshot.params.uuid;
        this.usersService.getContact(this.route.snapshot.params.uuid).subscribe(data => {
            this.modelToForm(data as any);
        });
    }

    loadform() {
        this.form = this.formBuilder.group({
            //   profileType: ['ORGANIZATION'],
            uuid: [],
            orgUuid: [],
            name: [],
            phone: [],
            email: [],
            contactPhone: ['', Validators.required],
            firstName: [null, [Validators.required, Validators.maxLength(40)]],
            lastName: [null, [Validators.required, Validators.maxLength(80)]],
            revenue: [],
            address: [],
            city: [],
            state: [],
            postalCode: [],
            country: [],
            sendSpotlightEmails: [true],
            level1: [],
            level2: [],
            level3: [],
            vertical: [],
            ecosystem: []
        });
        this.f.email.disable();
        // this.f.profileType.valueChanges.subscribe(e => {
        //     if (this.f.profileType.value == 'ORGANIZATION') {
        //         this.form.controls["name"].setValidators([Validators.required]);
        //         this.form.controls["phone"].setValidators([Validators.required]);
        //     }
        //     else {
        //         this.form.controls['name'].clearValidators();
        //         this.form.controls['phone'].clearValidators();
        //         this.form.controls['name'].updateValueAndValidity();
        //         this.form.controls['phone'].updateValueAndValidity();
        //     }
        //     this.f.profileType.setValue(e, { emitEvent: false });
        // });
    }

    modelToForm(model) {
        this.status = model.status;
        var cntry = this.appUtil.getCountry(this.countries, model.country);
        if (cntry == null || model.state == null) {
            cntry = this.appUtil.getCountry(this.countries, "United States");
            model.state = this.appUtil.getState(this.states, "California");
        }
        this.f.country.setValue(cntry);
        this.getStates();
        this.form.setValue({
            //  profileType: model.profileType,
            uuid: model.uuid,
            orgUuid: (model.organization == null) ? null : model.organization.uuid,
            firstName: model.firstName,
            lastName: model.lastName,
            email: model.email,
            name: (model.organization == null) ? null : model.organization.name,
            phone: (model.organization == null) ? null : model.organization.phone,
            contactPhone: model.phone,
            address: (model.organization == null) ? null : model.organization.address,
            city: model.city,
            state: this.appUtil.getState(this.states, model.state),
            postalCode: model.postalCode,
            country: cntry,
            sendSpotlightEmails: model.sendSpotlightEmails,
            revenue: (model.organization == null) ? null : model.organization.revenue,
            level1: (model.classification.level1 == null) ? null : model.classification.level1.map(a => a.uuid),
            level2: (model.classification.level2 == null) ? null : model.classification.level2.map(a => a.uuid),
            level3: (model.classification.level3 == null) ? null : model.classification.level3.map(a => a.uuid),
            vertical: (model.classification.vertical == null) ? null : model.classification.vertical.map(a => a.uuid),
            ecosystem: (model.classification.ecosystem == null) ? null : model.classification.ecosystem.map(a => a.uuid),
        });
    }

    get f() { return this.form.controls; }

    save() {
        this.submitted = true;
        if (this.form.invalid) {
            this.handleError(null);
            return;
        }
        this.loadingSpinner = true;
        let { profileType, firstName, lastName, city, state, address, postalCode, country, contactPhone, sendSpotlightEmails } = this.form.value;
        let dataToPost: any = { profileType, firstName, lastName, city, state, address, postalCode, country, contactPhone, sendSpotlightEmails, organization: {}, classification: {} };
        if (this.f.level1.value) {
            dataToPost.classification.level1 = this.appUtil.getObjects(this.classificationList.CATEGORY_LEVEL1, this.f.level1.value);
        }
        if (this.f.level2.value) {
            dataToPost.classification.level2 = this.appUtil.getObjects(this.classificationList.CATEGORY_LEVEL2, this.f.level2.value);
        }
        if (this.f.level3.value) {
            dataToPost.classification.level3 = this.appUtil.getObjects(this.classificationList.CATEGORY_LEVEL3, this.f.level3.value);
        }
        if (this.f.vertical.value) {
            dataToPost.classification.vertical = this.appUtil.getObjects(this.classificationList.VERTICAL, this.f.vertical.value);
        }
        if (this.f.ecosystem.value) {
            dataToPost.classification.ecosystem = this.appUtil.getObjects(this.classificationList.ECOSYSTEM, this.f.ecosystem.value);
        }
        if (this.f.revenue.value) {
            dataToPost.organization.revenue = this.f.revenue.value;
        }
        if (this.f.name.value) {
            dataToPost.organization.name = this.f.name.value;
        }
        if (this.f.phone.value) {
            dataToPost.organization.phone = this.f.phone.value;
        }
        if (this.f.address.value) {
            dataToPost.organization.address = this.f.address.value;
        }
        if (this.f.orgUuid.value) {
            dataToPost.organization.uuid = this.f.orgUuid.value;
        }
        let apiToCall: any;
        apiToCall = this.usersService.createProfile(this.f.uuid.value, dataToPost);
        apiToCall.subscribe(
            data => {

                this.dataService.setData('message', "Profile Updated");
                this.submitted = false;
                this.router.navigate(['/contact/' + this.f.uuid.value]);
            },
            error => {
                let errors = error.error;
                this.loadingSpinner = false;
                if (errors.code && errors.code == 'VALIDATION_ERROR') {
                    for (var i = 0; i < errors.subErrors.length; i++) {
                        this.f[errors.subErrors[i].field].setErrors({ 'message': errors.subErrors[i].message });
                    }
                    this.handleError(errors.subErrors[0].field);
                }
            }
        );
    }

    handleError(elemId) {
        this.messageService.add({ severity: 'error', summary: 'Form Errors', detail: "Form has errors. Please fix them first", life: 3000 });
        let invalidControl = this.formElement.nativeElement.querySelector('.ng-invalid');
        if (invalidControl) {
            invalidControl.focus();
        }
        else if (elemId != null) {
            invalidControl = this.formElement.nativeElement.querySelector('#' + elemId);
            if (invalidControl) {
                invalidControl.focus();
            }
        }
    }

    getStates() {
        if (this.countries != null && this.f.country.value != '') {
            this.states = this.appUtil.getStates(this.countries, this.f.country.value);
            this.f.state.reset();
        }
    }

    skip() {
        this.router.navigate(['/']);
    }

    cancel() {
        this._location.back();
    }

}
