<p-dialog [(visible)]="show" [style]="{width: '450px'}" [header]="selectedKey?.name" [modal]="true" styleClass="p-fluid" 
    (onHide)="hideDialog()" >
    <ng-template pTemplate="content">
        <form [formGroup]="formclass">
            <div class="field" *ngIf="selectedKey.type != 'RANGE' && type !='COUNTRY'">
                <label for="value" class="label-set">Value</label><span class="mandate">*</span>
                <input type="text" maxlength="100" pInputText id="value" formControlName="value"
                    [ngClass]="{ 'ng-invalid ng-dirty': lookSubmitted && f.value.errors }" required autofocus />
                <small class="p-error" *ngIf="lookSubmitted && f.value.invalid && f.value.hasError('required')">Value is
                    required.</small>
                <small class="p-error"
                    *ngIf="lookSubmitted && f.value.invalid && f.value.hasError('message')">{{f.value.errors.message}}</small>
            </div>
            <div class="field" *ngIf="selectedKey.type == 'RANGE' && type !='COUNTRY'">
                <label for="value" class="label-set">Min</label>
                <p-inputNumber id="min" autofocus formControlName="min"
                    [ngClass]="{ 'ng-invalid ng-dirty': lookSubmitted && f.min.errors }" (onInput)="resetRangeError()"></p-inputNumber>
                <small class="p-error"
                    *ngIf="lookSubmitted && f.min.invalid && f.min.hasError('message')">{{f.min.errors.message}}</small>
                <small class="p-error"
                    *ngIf="lookSubmitted && rangeError">Range already exists</small>
            </div>
            <div class="field" *ngIf="selectedKey.type == 'RANGE' && type !='COUNTRY'">
                <label for="value" class="label-set">Max</label>
                <p-inputNumber id="max" formControlName="max"
                    [ngClass]="{ 'ng-invalid ng-dirty': lookSubmitted && f.max.errors }" autofocus  (onInput)="resetRangeError()"></p-inputNumber>
                <small class="p-error"
                    *ngIf="lookSubmitted && f.max.invalid && f.max.hasError('message')">{{f.max.errors.message}}</small>
                <small class="p-error" *ngIf="lookSubmitted && f.max.invalid && f.max.hasError('required')">Max is
                    required.</small>
            </div>
            <div class="field" *ngIf="type =='COUNTRY'">
                <label for="name" class="label-set">Name</label><span class="mandate">*</span>
                <input type="text" maxlength="100" pInputText id="name" formControlName="name"
                    [ngClass]="{ 'ng-invalid ng-dirty': lookSubmitted && f.name.errors }" required autofocus />
                <small class="p-error" *ngIf="lookSubmitted && f.name.invalid && f.name.hasError('required')">Name is
                    required.</small>
                <small class="p-error"
                    *ngIf="lookSubmitted && f.name.invalid && f.name.hasError('message')">{{f.name.errors.message}}</small>
            </div>
            <div class="field" *ngIf="type =='COUNTRY'">
                <label for="iso3" class="label-set">Country Code (ISO 3)</label>
                <input type="text" maxlength="3" pInputText id="iso3" formControlName="iso3"
                    [ngClass]="{ 'ng-invalid ng-dirty': lookSubmitted && f.iso3.errors }" />
                <small class="p-error" *ngIf="lookSubmitted && f.iso3.invalid && f.iso3.hasError('required')">Country
                    code is required.</small>
                <small class="p-error"
                    *ngIf="lookSubmitted && f.iso3.invalid && f.iso3.hasError('message')">{{f.iso3.errors.message}}</small>
            </div>
        </form>
    </ng-template>

    <ng-template pTemplate="footer">
        <div *ngIf="lookSpinner">
            <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
                fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
        </div>
        <div *ngIf="lookSpinner == false">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
                (click)="hideDialog()"></button>
            <button pButton pRipple label="Save" form="clform" icon="pi pi-check" (click)="onSubmit()"
                class="p-button-text"></button>
        </div>
    </ng-template>
</p-dialog>
<p-toast></p-toast>