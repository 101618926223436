import { TagModule } from 'primeng/tag';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TooltipModule } from 'primeng/tooltip';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LookupcompleteComponent } from './lookup.component';
import { DialogModule } from 'primeng/dialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { ConfigurationModule } from '../../configuration/configuration.module';
@NgModule({
    declarations: [
        LookupcompleteComponent
    ],
    imports: [
        CommonModule,
        AutoCompleteModule,
        TooltipModule,
        ReactiveFormsModule,
        TagModule,
        FormsModule,
        DialogModule,
        ProgressSpinnerModule,
        InputTextareaModule,
        ButtonModule,
        SplitButtonModule,
        InputTextModule,
        ConfigurationModule,
    ],
    exports: [
        LookupcompleteComponent,
    ]
})
export class LookupAutocompleteModule { }
