import { AppUtil } from './../_helpers/app.util';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from '../app.breadcrumb.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Location } from '@angular/common';
import { UsersService } from "./users.service";
import { confirmValidator } from './../_helpers/confirmed.validator';
import { DataService } from '../_services/data.service';

@Component({
    selector: 'app-tbpuser.add',
    templateUrl: './tbpuser.add.component.html',
    styleUrls: ['./tbpuser.add.component.scss'],
    providers: [MessageService]
})
export class TbpuserAddComponent implements OnInit {
    @ViewChild('cform') formElement;
    form: UntypedFormGroup;
    submitted = false;
    loadingSpinner: boolean = false;
    isEdit: boolean = false;
    helpList: any = {TBP_USERS:[]};
    constructor(
        private _location: Location,
        private breadcrumbService: BreadcrumbService,
        private router: Router,
        private formBuilder: UntypedFormBuilder,
        private usersService: UsersService,
        private route: ActivatedRoute,
        private messageService: MessageService,
        private dataService: DataService,
        public appUtil: AppUtil) {

        if (this.router.url.indexOf("/edit/") != -1) {
            this.isEdit = true;
        }
        if (this.isEdit) {
            this.breadcrumbService.setItems([
                { label: 'Edit Tbp User' }
            ]);
        } else {
            this.breadcrumbService.setItems([
                { label: 'New Tbp User' }
            ]);
        }

    }

    ngOnInit(): void {
        this.helpList = this.route.snapshot.data['helpList']['TBP_USERS'];
        if (this.isEdit) {
            this.usersService.getTbpUser(this.route.snapshot.params.uuid).subscribe(data => {
                this.modelToForm(data as any);
            });
            this.loadTbpForms();
        } else {
            this.loadTbpForm();
        }
    }

    save() {
        this.submitted = true;
        if (this.form.invalid) {
            this.handleError(null);
            return;
        }
        this.loadingSpinner = true;
        let { uuid, firstName, lastName, email, phone, mobile, title, password, confirmPassword, role } = this.form.value;
        let dataToPost: any = { uuid, firstName, lastName, email, phone, mobile, title, password, confirmPassword, role };
        let apiToCall: any;
        dataToPost['role'] = 'USER';
        if (this.isEdit) {
            apiToCall = this.usersService.updateTbpUser(dataToPost);
        }
        else {
            if (this.f.password.value != this.f.confirmPassword.value) {
                this.f.confirmPassword.setErrors({ 'message': 'Passwords do not match' });
                return
            }
            else {
                dataToPost.password = this.appUtil.passwordEncrypt(this.f.password.value);
                dataToPost.confirmPassword = dataToPost.password;
            }
            apiToCall = this.usersService.addTbpUser(dataToPost);
        }

        apiToCall.subscribe(
            data => {
                let user = data as any;
                let detail = 'TBP User Created';
                if (this.isEdit) {
                    detail = 'TBP User Updated';
                }
                this.dataService.setData('message', detail);
                this.router.navigate(['user/' + user.uuid]);
                this.submitted = false;
            },
            error => {
                this.loadingSpinner = false;
                let errors = error.error;
                if (errors.code && errors.code == 'VALIDATION_ERROR') {
                    for (var i = 0; i < errors.subErrors.length; i++) {
                        this.f[errors.subErrors[i].field].setErrors({ 'message': errors.subErrors[i].message });
                    }
                    this.handleError(errors.subErrors[0].field);
                }
            }
        );
    }

    loadTbpForms() {
        this.form = this.formBuilder.group({
            uuid: [''],
            firstName: [null, [Validators.required, Validators.maxLength(40)]],
            lastName: [null, [Validators.required, Validators.maxLength(74)]],
            email: [null, [Validators.required, Validators.pattern, Validators.pattern(this.appUtil.emailPattern)]],
            phone: [],
            mobile: [],
            title: []
        });
    }

    loadTbpForm() {
        this.form = this.formBuilder.group({
            uuid: [''],
            firstName: [null, [Validators.required, Validators.maxLength(40)]],
            lastName: [null, [Validators.required, Validators.maxLength(74)]],
            email: [null, [Validators.required, Validators.pattern, Validators.pattern(this.appUtil.emailPattern)]],
            phone: [],
            mobile: [],
            title: [],
            password: ['', [Validators.required, Validators.minLength(6)]],
            confirmPassword: ['']
        }, {
            validator: confirmValidator('password', 'confirmPassword')
        });
    }
    modelToForm(model) {
        this.form.setValue({
            uuid: model.uuid,
            firstName: model.firstName,
            lastName: model.lastName,
            email: model.email,
            phone: model.phone,
            mobile: model.mobile,
            title: model.title
        });
    }

    get f() { return this.form.controls; }

    handleError(elemId) {
        this.messageService.add({ severity: 'error', summary: 'Form Errors', detail: "Form has errors. Please fix them first", life: 3000 });
        let invalidControl = this.formElement.nativeElement.querySelector('.ng-invalid');
        if (invalidControl) {
            invalidControl.focus();
        }
        else if (elemId != null) {
            invalidControl = this.formElement.nativeElement.querySelector('#' + elemId);
            if (invalidControl) {
                invalidControl.focus();
            }
        }
    }

    cancel() {
        this._location.back();
    }

    getHelpText(key) {
        let test = this.helpList.filter(function (element) {
            return element.key == key;
        });
        if (test[0] != undefined)
            return test[0]['text'];
    }
}
