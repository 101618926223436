import { TbpAutocompleteModule } from '../_components/tbp-autocomplete/tbp-autocomplete.module';
import { TbpSearchModule } from './../_components/tbp-search/tbp-search.module';
import { LookupAutocompleteModule } from '../_components/lookup/lookup.module';
import { TaskModule } from './../task/task.module';
import { NoteModule } from './../note/note.module';
import { UploadExcelModule } from './../upload-excel/upload-excel.module';
import { UserPopupModule } from './../user-popup/user-popup.module';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { OrganizationRoutingModule } from './organization-routing.module';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ToolbarModule } from 'primeng/toolbar';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CheckboxModule } from 'primeng/checkbox';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { RadioButtonModule } from 'primeng/radiobutton';
import { OrganizationAddComponent } from './organization.add.component'
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import { MenuModule } from 'primeng/menu';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { OrganizationViewComponent } from './organization.view.component';
import { SplitButtonModule } from 'primeng/splitbutton';
import { SharedModule } from '../shared/shared.module';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CalendarModule } from 'primeng/calendar';
import { TbpQueryBuilderModule } from '../_components/tbp-query-builder/tbp-query-builder.module';
import { TooltipModule } from 'primeng/tooltip';
import { FieldsetModule } from 'primeng/fieldset';
import { DividerModule } from "primeng/divider";
import { InputMaskModule } from 'primeng/inputmask';
import { TagModule } from 'primeng/tag';
import { MergeOrganizationComponent } from './merge-organization.component';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { OrganizationsListComponent } from './organizations-list.component';
import { TbpTableColumnModule } from '../_components/tbp-table-column/tbp-table-column.module';
import { InputNumberModule } from 'primeng/inputnumber';

@NgModule({
    declarations: [
        OrganizationAddComponent,
        OrganizationViewComponent,
        MergeOrganizationComponent,
        OrganizationsListComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        OrganizationRoutingModule,
        TableModule,
        ButtonModule,
        OverlayPanelModule,
        ToolbarModule,
        FileUploadModule,
        InputTextModule,
        DropdownModule,
        InputTextareaModule,
        CheckboxModule,
        AutoCompleteModule,
        RadioButtonModule,
        MultiSelectModule,
        PanelModule,
        MenuModule,
        InputNumberModule,
        SplitButtonModule,
        MessageModule,
        MessagesModule,
        ToastModule,
        SharedModule,
        FormsModule,
        DialogModule,
        ConfirmDialogModule,
        ProgressSpinnerModule,
        CalendarModule,
        TaskModule,
        NoteModule,
        TbpQueryBuilderModule,
        TooltipModule,
        UserPopupModule,
        UploadExcelModule,
        FieldsetModule,
        DividerModule,
        InputMaskModule,
        TagModule,
        TbpAutocompleteModule,
        LookupAutocompleteModule,
        EditorModule,
        TbpSearchModule,
        TbpTableColumnModule
    ],
    exports: [
    ],
    providers: [
        { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
    ]
})
export class OrganizationModule { }
