import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ConfigurationService } from "../configuration/configuration.service";
import { first } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
@Component({
    selector: 'add-state-lookups',
    templateUrl: './configuration.state.component.html',
    providers: [MessageService]
})
export class ConfigurationStateComponent implements OnInit {

    @Output("parentFuntion") parentFuntion: EventEmitter<any> = new EventEmitter();
    @Input() uuid: string;
    @Output() showChange = new EventEmitter<boolean>();
    @Input() show: boolean = false;
    @Input() type: string;
    @Input() countryUuid: string;
    @Input() inputTextvalue: string;
    lookSpinner: boolean = false;
    lookSubmitted: boolean = false;
    stateForm: UntypedFormGroup;
    formInitialValue: any = null;
    constructor(private formBuilder: UntypedFormBuilder, private service: ConfigurationService, private messageService: MessageService) {
        this.stateForm = this.formBuilder.group({
            uuid: [],
            name: ['', Validators.required],
            iso2: []
        });
        this.formInitialValue = this.stateForm.value;
    }
    ngOnInit(): void {

    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.show && this.countryUuid != undefined && this.uuid != undefined) {
            this.service.getState(this.uuid).subscribe(data => {
                let objCls = data as any;
                this.stateForm.setValue({ uuid: objCls.uuid, name: objCls.name, iso2: objCls.iso2 });
            });
        }
        if (this.inputTextvalue != undefined)
            this.f.name.setValue(this.inputTextvalue);
    }

    onSaveState() {
        this.lookSubmitted = true;
        if (this.stateForm.invalid) {
            return;
        }
        let apiCall: any;
        this.lookSpinner = true;
        if (this.f.uuid.value == null || this.f.uuid.value == '') {
            apiCall = this.service.createState(this.countryUuid, this.stateForm.value);
        }
        else {
            apiCall = this.service.updateState(this.countryUuid, this.stateForm.value);
        }
        apiCall.pipe(first())
            .subscribe(
                data => {
                    let detail = 'State Created';
                    if (this.stateForm.controls['uuid'].value != null && this.stateForm.controls['uuid'].value != '') {
                        detail = 'State Updated';
                    }
                    this.messageService.add({ severity: 'success', summary: 'Successful', detail: detail, life: 3000 });
                    this.parentFuntion.emit(data);
                    this.hideDialogState();
                    this.lookSpinner = false;
                },
                error => {
                    this.lookSpinner = false;
                    let errors = error.error;
                    if (errors.code && errors.code == 'VALIDATION_ERROR') {
                        for (var i = 0; i < errors.subErrors.length; i++) {
                            this.f[errors.subErrors[i].field].setErrors({ 'message': errors.subErrors[i].message });
                        }
                    }
                }
            );

    }

    get f() { return this.stateForm.controls; }

    hideDialogState() {
        this.show = false;
        this.lookSubmitted = false;
        this.lookSpinner = false;
        this.stateForm.reset(this.formInitialValue);
        this.showChange.emit();
    }
}