<div class="p-fluid formgrid grid p-mb-5">
    <div class="col-12">
    <h5>Screen Details</h5>    
    <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-6" *ngIf="accountService.role == 1">
                <div class="grid mt-1 limit-width-large dashed-bottom">
                    <div class="col-12 md:col-4 label md:text-right">Account: </div>
                    <div class="col-12 md:col-8 contact-info"  *ngIf="!editingField.organization"><a *ngIf="model!=null && model.organization!=null"
                        [routerLink]="['/organization/', model.organization.uuid]">{{model?.organization?.name}}</a>
                        <button pButton type="button" icon="pi pi-pencil" title="Edit" class="p-button-text edit-button"
                        (click)="editField('organization', model.organization)"></button>
                    </div>
                    <div class="col-12 md:col-6" *ngIf="editingField.organization">
                        <div class="input-container">
                                 <p-autoComplete appendTo="body" [unique]="true" [forceSelection]="true" [required]="true"
                                  [suggestions]="organizationResults"  [(ngModel)]="model.organization" (completeMethod)="searchOrganizations($event)" field="name"> </p-autoComplete>
                                  <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                                    (click)="updateField('organization', model?.organization)"></button>
                                    <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                                    (click)="closeDialog('organization')"></button>
                        </div>
                    </div>
                 </div>
        </div>
        <div class="field col-12 md:col-6">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right">Contacts: </div>
                <div class="col-12 md:col-8 contact-info" *ngIf="!editingField.contacts">                    
                    <span *ngFor="let contact of model.contacts; let last = last;">
                        <a [routerLink]="['/contact/', contact.uuid]">{{contact.name}}</a>{{last?'':'; '}}
                    </span>
                    <button pButton type="button" icon="pi pi-pencil" title="Edit" class="p-button-text edit-button"
                    (click)="editField('contacts', model?.contacts)"></button>
                </div>
                <div class="col-12 md:col-6" *ngIf="editingField.contacts">
                    <div class="input-container">
                    <p-autoComplete appendTo="body" [forceSelection]="true" multiple="true"  [items]="" 
                    [suggestions]="contactResults"  [(ngModel)]="model.contacts" [unique]="true"
                    (completeMethod)="searchOrganizations($event)" field="name"> </p-autoComplete>
                    <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                                    (click)="updateField('contacts', model?.contacts)"></button>
                    <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                                    (click)="closeDialog('contacts')"></button>
                     </div>
                </div>
             </div>
        </div>
        <div  (click)="editField('screenTitle', model.screenTitle)" class="field col-12 md:col-6">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right" >Screen Title:</div>
                <div class="col-12 md:col-8" *ngIf="!editingField.screenTitle">
                    {{model?.screenTitle}}
                </div>
                <div  class="col-12 md:col-6"  *ngIf="editingField.screenTitle">
                    <div class="input-container">
                    <input type="text" pInputText  [(ngModel)]="model.screenTitle" [required]="true"/>
                     <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                     (click)="updateField('screenTitle', model.screenTitle)"></button>
                     <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                     (click)="closeDialog('screenTitle')"></button>
                     </div>
                </div>
            </div>
        </div>
        <div class="field col-12 md:col-6" (click)="editField('externalHeadline', model.externalHeadline)" >
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right">External Headline: </div>
                <div class="col-12 md:col-8" *ngIf="!editingField.externalHeadline">{{model?.externalHeadline}}</div>
                <div class="col-12 md:col-6" *ngIf="editingField.externalHeadline">
                    <div class="input-container">
                    <input type="text"  pInputText  [(ngModel)]="model.externalHeadline" 
                     [required]="true"/>
                     <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                     (click)="updateField('externalHeadline', model.externalHeadline)"  ></button>
                     <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                     (click)="closeDialog('externalHeadline')"></button>
                     </div>
                </div>
            </div>
        </div>
        <div class="field col-12 md:col-6" (click)="editField('primaryObjective', model.primaryObjective)">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right">Primary objective of screen: </div>
                <div class="col-12 md:col-8" *ngIf="!editingField.primaryObjective"  [innerHTML]="model | buysideobjective">
                    </div>
                    <div *ngIf="editingField.primaryObjective">
                        <p-dialog [visible]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
                        [style]="{width: '50vw'}" [closable]="false" [modal]="true">
                        <ng-template pTemplate="header">
                            <div class="p-fluid formgrid grid">
                              <strong>Primary objective of screen:</strong>
                            </div>
                          </ng-template>
                        <ng-template pTemplate="content">
                            <form [formGroup]="form" #cform>
                            <div class=" mb-1">
                                <div class="  mb-3 ml-2">
                                    <p-checkbox [binary]="true" styleClass="form-control"   formControlName="isStrategicBuyout"
                                         [value]="1" label="Strategic Buyout" (onChange)="validatePrimaryObjective($event)"
                                        labelStyleClass="pr-2"  tooltipPosition="top" [ngClass]="{ 'ng-invalid ng-dirty': formsubmited && f.isStrategicBuyout.errors }"></p-checkbox>
                                </div>
                                <p-divider layout="horizontal"></p-divider>
                                <div class=" mb-3">
                                    <div class="font-bold mt-4 mb-4">PE Buyout</div>
                                    <div  class=" ml-5">
                                        <p-checkbox [binary]="true" styleClass="form-control"  formControlName="isPEBuyoutPlatform"
                                            label="Platform" (onChange)="validatePrimaryObjective($event)"  [ngClass]="{ 'ng-invalid ng-dirty': formsubmited && f.isPEBuyoutPlatform.errors }"
                                            labelStyleClass="pr-2"  tooltipPosition="top"></p-checkbox>
                                    </div>
                                    <div  class="mt-4 ml-5">
                                        <p-checkbox [binary]="true" styleClass="form-control"  formControlName="isPEBuyoutTuckin"
                                            label="Tuck-in (Majority or 100%)" (onChange)="validatePrimaryObjective($event)" 
                                            labelStyleClass="pr-2"  tooltipPosition="top" [ngClass]="{ 'ng-invalid ng-dirty': formsubmited && f.equityType.errors }"></p-checkbox>
                                    </div>
                                </div>
                                <p-divider layout="horizontal" class="mt-3 mb-3"></p-divider>
                                <div class=" font-bold mt-4 mb-4">Investment</div>
                                <div class="mb-4 mt-4 ml-5">
                                    <div class="p-fluid grid">
                                        <div class="col-2 mt-3">
                                            Equity
                                        </div>
                                        <div class="field col-10 mt-4 ">
                                            <p-multiSelect [options]="equity"
                                             optionLabel="value" optionValue="value" appendTo="body" formControlName="equityType" [ngClass]="{ 'ng-invalid ng-dirty': formsubmited && f.equityType.errors }"
                                            [autoDisplayFirst]="false" placeholder="Select" [showClear]="true" [dataKey]="value" 
                                            tooltipPosition="top" (onChange)="validatePrimaryObjective($event)" >
                                        </p-multiSelect>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-4 mb-4 ml-5">
                                    <div class="p-fluid grid">
                                        <div class="col-2 mt-3">
                                                Debt
                                              </div>
                                              <div class="field col-10 mt-4 ">
                                            <p-multiSelect [options]="debt" appendTo="body" 
                                            optionLabel="value" optionValue="value" id="Checkbox2" formControlName="debtType"
                                            [autoDisplayFirst]="false" placeholder="Select" [showClear]="true" (onChange)="validatePrimaryObjective($event)" 
                                            tooltipPosition="top" [ngClass]="{ 'ng-invalid ng-dirty': formsubmited && f.debtType.errors }">
                                        </p-multiSelect>
                                        </div>
                                    </div>
                                </div>
                            </div>
                         </form>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <button pButton pRipple label="Save" [loading]="loading" form="clform" icon="pi pi-check" class="p-button-text"
                              (click)="prepareModel('primaryObjectives',  model.primaryObjectives)"></button>
                         <button pButton label="Close" class="p-button-text" (click)="closeDialog('primaryObjective')" > </button>
                        </ng-template>
                      </p-dialog> 
                </div>
            </div>
        </div>
        <div (click)="editField('transactionObjective', model?.transactionObjective)" class="field col-12 md:col-6">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right" >Transaction objective description: </div>
                <div class="col-12 md:col-8"  *ngIf="!editingField.transactionObjective" [innerHTML]="model?.transactionObjective"></div>
            </div>
            <div *ngIf="editingField.transactionObjective">
                <p-dialog [visible]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
                [style]="{width: '50vw'}" [closable]="false" [modal]="true">
                <ng-template pTemplate="header">
                  <div class="p-fluid formgrid grid">
                    <strong>{{model.screenTitle}}:</strong>Transaction objective description  </div>
                </ng-template>
                <ng-template pTemplate="content">
                  <editor [(ngModel)]="model.transactionObjective"></editor>
                </ng-template>
                <ng-template pTemplate="footer">
                  <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
                    (click)="updateField('transactionObjective', model?.transactionObjective)"></button>
                  <button pButton pRipple label="Close" class="p-button-text"
                    (click)="closeDialog('transactionObjective')" > </button>
                </ng-template>
              </p-dialog>
            </div>    
        </div>
    </div>
    <h5>Target Requirements</h5>    
    <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-6" (click)="editField('targetRevenue', model.targetRevenue)">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right">Target Revenue ($m): </div>
                <div class="col-12 md:col-8" *ngIf="!editingField.targetRevenue">{{getOutput(model?.targetRevenue)}}</div>
                <div class="col-12 md:col-6" *ngIf="editingField.targetRevenue">
                    <div class="input-container">
                    <p-dropdown [multiple]="false" [(ngModel)]="model.targetRevenue"  [options]="lookupList.REVENUE"  
                    optionLabel="value" tooltipPosition="top"  ></p-dropdown>
                    <!-- <p-autoComplete [unique]="true" [type]="'REVENUE'" [(ngModel)]="model.targetRevenue" [suggestions]="lookupRevenue" [forceSelection]="true" 
                    (completeMethod)="searchOrganizations($event)" field="value" tooltipPosition="top" (onSelect)="updateField('targetRevenue', model.targetRevenue)" ></p-autoComplete> -->

                    <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                    (click)="onSelectionChange('targetRevenue')"></button>
                    <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                    (click)="closeDialog('targetRevenue')"></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="field col-12 md:col-6" (click)="editField('targetEbitda', model?.targetEbitda)">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right">Target EBITDA ($m): </div>
                <div class="col-12 md:col-8" *ngIf="!editingField.targetEbitda" >{{getOutput(model?.targetEbitda)}}</div>
                <div class="col-12 md:col-6" *ngIf="editingField.targetEbitda">
                    <div class="input-container">
                    <p-dropdown [multiple]="false" [(ngModel)]="model.targetEbitda"  [options]="lookupList.EBITDA"  
                    optionLabel="value"></p-dropdown>
                    <!-- <p-autoComplete [unique]="true"  [type]="'EBITDA'" (onSelect)="updateField('targetEbitda',  model?.targetEbitda)" [suggestions]="lookupEbitda"
                    (completeMethod)="searchOrganizations($event)" tooltipPosition="top" field="value" [(ngModel)]="model.targetEbitda"  [forceSelection]="true" ></p-autoComplete> -->
                    <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                    (click)="onSelectionChange('targetEbitda')"></button>
                    <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                    (click)="closeDialog('targetEbitda')"></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="field col-12 md:col-6"  (click)="editField('dollorValueRange', model?.dollorValueRange)" *ngIf="hasEquity || hasDebt">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right">Dollar value of the investment ($m): </div>
                <div class="col-12 md:col-8" *ngIf="!editingField.dollorValueRange">{{getOutput(model?.dollorValueRange)}}</div>
                <div class="col-12 md:col-6" *ngIf="editingField.dollorValueRange">
                    <div class="input-container">
                    <p-dropdown [multiple]="false" [(ngModel)]="model.dollorValueRange"  [options]="lookupList.DOLLOR_VALUE_RANGE"  
                    optionLabel="value"></p-dropdown>
                    <!-- <p-autoComplete [multiple]="false"  (onSelect)="updateField('dollorValueRange',  model?.dollorValueRange)" [suggestions]="lookupdollorValueRange"
                    (completeMethod)="searchOrganizations($event)"  field="value" [(ngModel)]="model.dollorValueRange"></p-autoComplete> -->
                    <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                    (click)="onSelectionChange('dollorValueRange')" ></button>
                    <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                    (click)="closeDialog('dollorValueRange')"></button>
                    </div>
                </div>
            </div>
        </div>  
        <div class="field col-12 md:col-6" *ngIf="model.primaryObjectives?.strategicBuyout || model.primaryObjectives?.peBuyout.tuckin || model.primaryObjectives?.peBuyout.platform" (click)="editField('managementPreference', model.managementPreference)">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right">Management Preference:  </div>
                <div class="col-12 md:col-8"  *ngIf="!editingField.managementPreference">{{model?.managementPreference}}</div>
                <div class="col-12 md:col-6" *ngIf="editingField.managementPreference">
                    <div class="input-container">
                    <p-dropdown [options]="managementPreference" styleClass="form-control" [(ngModel)]="model.managementPreference"
                    optionLabel="value" optionValue="value"  tooltipPosition="top">
                    </p-dropdown>
                    <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                    (click)="updateField('managementPreference', model.managementPreference)"></button>
                    <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                    (click)="closeDialog('managementPreference')"></button>
                    </div>
                </div>
            </div>
        </div>   
        <div class="field col-12 md:col-6" (click)="editField('turnaround', model.turnaround)">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right">Open to company undergoing a
                    turnaround/restructuring?: </div>
                <div class="col-12 md:col-8" *ngIf="!editingField.turnaround">{{model.turnaround==true?"Yes":"No"}}</div>
                <div class="col-12 md:col-6" *ngIf="editingField.turnaround">
                <p-radioButton name="turnaround" [value]="true"
                [(ngModel)]="model.turnaround" label="Y"  [(ngModel)]="model.turnaround" (ngModelChange)="updateField('turnaround', model.turnaround)"></p-radioButton>
                <p-radioButton name="turnaround" [value]="false" 
                [(ngModel)]="model.turnaround" label="N" [(ngModel)]="model.turnaround" (ngModelChange)="updateField('turnaround', model.turnaround)" class="ml-2"></p-radioButton>
                </div>
            </div>
        </div>  
    </div>
    <h5>Classification</h5>   
     <div class="p-fluid formgrid grid">
        <ng-container *ngFor="let category of categories">
         <div class="field col-12 md:col-6" (click)="editField(category.label, model?.classification.level1)">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right">{{ category.label}}:</div>
                <div class="col-12 md:col-8" *ngIf="!editingField[category.label]">
                    <span  *ngFor="let classification of model?.classification[category.model];  let last = last;">
                        {{classification.name}}{{last?'':'; '}}
                    </span>
                </div>
                <div  *ngIf="editingField[category.label]" class="field col-12 md:col-8">
                    <div class="input-container">
                    <p-multiSelect [multiple]="false"[options]="classificationList[category.value]" [(ngModel)]="model?.classification[category.model]"
                     optionLabel="name" placeholder="Choose" [required]="true"
                    appendTo="body"  [type]="classification"> </p-multiSelect>
                    <button pButton type="button" icon="pi pi-check" class="transparent-button save-icon"
                    (click)="onSelectionChange(category.label)"></button>
                    <button pButton type="button" icon="pi pi-times" class="transparent-button cancel-icon"
                    (click)="closeDialog(category.label)"></button>
                    </div>
                </div>
            </div>
           </div>
        </ng-container>
       
       <!--   <div class="field col-12 md:col-6"  (click)="editField('classificationLevel2', model?.classification.level2)">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right">Level2: </div>
                <div class="col-12 md:col-8"  *ngIf="!editingField.classificationLevel2">
                    <span *ngFor="let classification of model?.classification?.level2; let last = last;">
                        {{classification.name}}{{last?'':'; '}}
                    </span>
                </div>
                <div *ngIf="editingField.classificationLevel2">
                    <p-multiSelect [multiple]="false" [options]="classificationList?.CATEGORY_LEVEL2" (ngModelChange)="onSelectionChange('classificationLevel2')"  
                    [(ngModel)]="model?.classification.level2" optionLabel="name" placeholder="Choose"  (keydown.enter)="level2EditMode = false"
                    appendTo="body" [type]="classification"></p-multiSelect>
                    </div>
            </div>
        </div>
       <div class="field col-12 md:col-6" (click)="editField('classificationLevel3', model?.classification.level3)">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right">Level3: </div>
                <div class="col-12 md:col-8" *ngIf="!editingField.classificationLevel3">
                    <span *ngFor="let classification of model?.classification?.level3; let last = last;">
                        {{classification.name}}{{last?'':'; '}}
                    </span>
                </div>
                <div *ngIf="editingField.classificationLevel3">
                    <p-multiSelect [multiple]="false" [options]="classificationList?.CATEGORY_LEVEL3" (ngModelChange)="onSelectionChange('classificationLevel3')" 
                    [(ngModel)]="model?.classification.level3" optionLabel="name" placeholder="Choose"  (keydown.enter)="level3EditMode = false"
                    appendTo="body"  ></p-multiSelect>
                    </div>
           
            </div>
        </div>
        <div class="field col-12 md:col-6" (click)="editField('classificationVertical', model?.classification.vertical)">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right">Vertical: </div>
                <div class="col-12 md:col-8"  *ngIf="!editingField.classificationVertical">
                    <span *ngFor="let classification of model?.classification?.vertical; let last = last;">
                        {{classification.name}}{{last?'':'; '}}
                    </span>
                </div>
                <div *ngIf="editingField.classificationVertical">
                    <p-multiSelect [multiple]="false" [options]="classificationList?.VERTICAL" (ngModelChange)="onSelectionChange('classificationVertical')"  
                    [(ngModel)]="model?.classification.vertical" optionLabel="name" placeholder="Choose"  (keydown.enter)="verticalEdit = false"
                    appendTo="body" [type]="classification"></p-multiSelect>
                </div>
            </div>
        </div>   
        <div class="field col-12 md:col-6" (click)="editField('classificationEcosystem', model?.classification.ecosystem)">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right">Ecosystem: </div>
                <div class="col-12 md:col-8" *ngIf="!editingField.classificationEcosystem">
                    <span *ngFor="let classification of model?.classification?.ecosystem; let last = last;">
                        {{classification.name}}{{last?'':'; '}}
                    </span>
                </div>
                <div *ngIf="editingField.classificationEcosystem">
                    <p-multiSelect [multiple]="false" [options]="classificationList?.ECOSYSTEM" (ngModelChange)="onSelectionChange('classificationEcosystem')"  
                    [(ngModel)]="model?.classification.ecosystem" optionLabel="name" placeholder="Choose" (keydown.enter)="ecosystemEdit = false"
                    appendTo="body" [type]="classification"></p-multiSelect>
                </div>
            </div>
        </div>  -->
        <div class="field col-12 md:col-6" (click)="editField('companyDescription', model?.companyDescription)">
            <div class="grid mt-1 limit-width-large dashed-bottom">
                <div class="col-12 md:col-4 label md:text-right">Target Description: </div>
                <div class="col-12 md:col-8" *ngIf="!editingField.companyDescription" [innerHTML]="model?.companyDescription"></div>
                <div *ngIf="editingField.companyDescription">
                    <p-dialog [visible]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
                    [style]="{width: '50vw'}" [closable]="false" [modal]="true">
                    <ng-template pTemplate="header">
                      <div class="p-fluid formgrid grid">
                        <strong>{{model.screenTitle}}:</strong>Target Description
                      
                      </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                      <editor [(ngModel)]="model.companyDescription" ></editor>
                    </ng-template>
                    <ng-template pTemplate="footer">
                      <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
                        (click)="updateField('companyDescription', model?.companyDescription)"></button>
                      <button pButton pRipple label="Close" class="p-button-text"
                        (click)="closeDialog('companyDescription')" > </button>
                    </ng-template>
                  </p-dialog>
                </div>    
            </div>
        </div>  
    </div>
    <p-toast></p-toast>
</div>
</div>
