import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AccountPopupService {

    constructor(private http: HttpClient) { }

    addOrganization(data) {
        return this.http.post(environment.apiUrl + '/organization', data);
    }
}
