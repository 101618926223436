import { Organization } from './../_models/organization';
import { MailLog } from './../_models/maillog';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, LazyLoadEvent, MenuItem } from 'primeng/api';
import { BreadcrumbService } from '../app.breadcrumb.service';
import { UsersService } from "./users.service";
import { User } from '../_models/user';
import { DataService } from '../_services/data.service';
import { UserOrganization, BuySide, SellSide, RuleSet } from '../_models';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { AccountService } from '../_services';
import * as mimeType from 'mime';
import { AppUtil, SearchHeler } from '../_helpers';
import { EmailCampaign } from '../_models/emailcampaign';
import { BuysideService } from '../buyside/buyside.service';
import { error, log } from 'console';
import { Table } from 'primeng/table';
import { OrganizationService } from '../organization/organization.service';
interface AutoCompleteCompleteEvent {
    originalEvent: Event;
    query: string;
}
import { ButtonModule } from 'primeng/button';
@Component({
    selector: 'app-user-view',
    templateUrl: './user.view.component.html',
    styleUrls: ['./user.view.component.scss'],
    providers: [MessageService, ConfirmationService]
})
export class UserViewComponent implements OnInit {
    @ViewChild('dt') dataTable: Table;
    @ViewChild('cform') formElement;
    contact: User;
    duplicateContact: User;
    contactItems: MenuItem[];
    uuid: string;
    loadingSpinner: boolean = false;
    userStatus: boolean = false;
    labelStatus: string;
    currntStatus: string;
    // organization panel
    organizations: UserOrganization[];
    selectedOrganizations: UserOrganization[] = [];
    organizationLoading: boolean = true;
    organizationTotalRecords: number = 0;

    // BuySide List
    buysides: BuySide[];
    buysideListForContact: BuySide[] = []
    buysideList: BuySide[] = [];
    selectedBuySides: BuySide[] = [];
    buysideLoading: boolean = true;
    buysideTotalRecords: number = 0;
    serchBuy: string;
    // SellSide List
    sellsides: SellSide[];
    selectedSellSides: SellSide[] = [];
    sellsideLoading: boolean = true;
    sellsideTotalRecords: number = 0;
    serchSell: string;
    // Email Campaign List
    emailCampaigns: EmailCampaign[];
    selectedEmailCampaigns: EmailCampaign[];
    emailCampaignLoading: boolean = true;
    emailCampaignTotalRecords: number = 0;

    // MailLogs
    mailLogs: MailLog[];
    selectedMailLogs: MailLog[] = [];
    mailLogsLoading: boolean = true;
    mailLogTotalRecords: number = 0;

    //Portfolio Companies List
    portfolio: Organization[];
    selectedPortfolio: Organization[] = [];
    portfolioLoading: boolean = true;
    portfolioTotalRecords: number = 0;

    ///////////Password Change
    passwordsDialog: boolean;
    dialogTitlePassword: string;
    passwordForm: UntypedFormGroup;
    pfSubmitted: boolean;
    showPassword = false;
    userCredentials: boolean = false;
    relatedProfiles = [];
    relatedProfileForm: UntypedFormGroup;
    submitted = false;
    showRelatedProfile: boolean = false;
    source: string;
    sourceuuid: string;
    showCheckbox: boolean = false;
    loading: boolean = false;
    visible: boolean = false;
    deleteConfirmation: string = '';
    editingField: any = {};
    organizationResults: any;
    stateSuggestions: any[];
    lookupList: any = {
        ACCOUNT_SOURCE: [], REVENUE: [], ORG_TYPE_INVESTOR: [], No_OF_EMPLOYEES: [],
        ORG_TYPE_LENDER: [], ORG_TYPE_OTHER: []
    };
    classificationList: any = {
        CATEGORY_LEVEL1: [], CATEGORY_LEVEL2: [], CATEGORY_LEVEL3: [],
        VERTICAL: [], ECOSYSTEM: []
    };
    tbpEmployees: any[] = [];
    filteredValues: any[] | undefined;
    countries: any;
    initialFieldValues: { [fieldName: string]: any } = {};
    showDropdown: boolean = false;
    leadSource: any;
    mailingList: any = [];
    stateDisabled:boolean = false;
    constructor(
        private messageService: MessageService,
        private breadcrumbService: BreadcrumbService,
        private usersService: UsersService,
        private route: ActivatedRoute,
        private dataService: DataService,
        private formBuilder: UntypedFormBuilder,
        private http: HttpClient,
        private accountService: AccountService,
        public appUtil: AppUtil,
        private router: Router,
        private buysideService: BuysideService,
        private searchHelper: SearchHeler,
        private confirmationService: ConfirmationService,
        private organizationService: OrganizationService,
    ) {

        this.breadcrumbService.setItems([
            { label: 'View Contact' }
        ]);
    }
  
    ngOnInit(): void {
        this.uuid = this.route.snapshot.params.uuid;
        this.tbpEmployees = this.route.snapshot.data['tbpEmployees'];
        this.lookupList = this.route.snapshot.data['lookupList'];
        this.classificationList = this.route.snapshot.data['classificationList'];
        this.countries = this.route.snapshot.data['countries'];
        this.loadPassword();
        this.getReletedProfile();
        this.loadRelatedForm();
        this.loadportfolio();
        this.reload();
        let message = this.dataService.getDataByKey('message');
        if (message != undefined) {
            setTimeout(() => { this.messageService.add({ severity: 'success', summary: 'Successful', detail: message, life: 3000 }); })
            this.dataService.setData('message', undefined);
        }
    }
    reload() {
    this.contact =  new User();
        this.usersService.getContact(this.uuid).subscribe(data => {
            this.contact = data as any; 
            this.leadSource =  this.lookupList.ACCOUNT_SOURCE.find(item => item.value === this.contact.leadSource)
            this.mailingList =this.contact.mailingList? this.contact.mailingList.map(value => 
                this.lookupList.MAILING_LIST.find(item => item.value === value)
            ):null;
            const country = this.countries.find(c => c.name === this.contact.country);
            this.contact.country = country ? country : '';
            this.contact.state = country ? country.states?.find(state => state.name === this.contact.state) : '';
            if (country) {
                this.stateSuggestions = country.states;
                this.stateDisabled =  false;
            }else{
                this.stateDisabled =  true;
                this.contact.state =null;
            }

            this.currntStatus = this.contact.status;
            if (this.contact.hasCredentials != true) {
                this.userCredentials = true;
            }
            else if (this.contact.status == "PENDING_VERIFICATION" && this.contact.hasCredentials) {
                this.userStatus = true;
                this.labelStatus = 'Verify Profile';
            }
            else if (this.contact.status == "REVOKED" && this.contact.hasCredentials) {
                this.userStatus = true;
                this.labelStatus = 'Activate Profile';
            }
            else if (this.contact.status == "ACTIVE" && this.contact.hasCredentials) {
                this.userStatus = true;
                this.labelStatus = 'Revoke Profile';
            }
            if (this.contact.status !== 'PENDING_ACTIVATION') {
                this.addContactAsProspects(this.currntStatus);
            }
        });
        this.usersService.getDuplicateUsers(this.uuid).subscribe(data => {
            this.duplicateContact = data as any;
        });
    }
    addContactAsProspects(value: string) {
        let selectedData = this.dataService.getDataByKey("buyside-contacts-" + this.uuid);
        let addAsContact = this.dataService.getDataByKey('addAsContact');
        let buysideToBeAdded = [];
        let sellsideToBeAdded = [];
        if (selectedData && Array.isArray(selectedData)) {
            buysideToBeAdded = selectedData.map(a => a.uuid);
            this.dataService.deleteData("buyside-contacts-" + this.uuid);
        }
        else {
            selectedData = this.dataService.getDataByKey("sellside-contacts-" + this.uuid);
            if (selectedData && Array.isArray(selectedData)) {
                sellsideToBeAdded = selectedData.map(a => a.uuid);
                this.dataService.deleteData("sellside-contacts-" + this.uuid);
            }
        }
        if ((buysideToBeAdded.length > 0 || sellsideToBeAdded.length > 0) && value !== 'PENDING_ACTIVATION') {
            this.loading = true;
            this.usersService.addBuysideSelside(this.uuid, buysideToBeAdded, sellsideToBeAdded, addAsContact).subscribe(
                data => {
                    if (addAsContact) {
                        this.messageService.add({ severity: 'success', summary: 'Successful', detail: this.appUtil.prepareMessageForProspects(data as any), life: 3000 });
                        this.loading = false;
                        this.dataService.deleteData('addAsContact');
                        setTimeout(()=>{
                            window.location.reload();
                        },1500);
                    }else{
                        this.messageService.add({ severity: 'success', summary: 'Successful', detail: this.appUtil.prepareMessageForProspects(data as any), life: 3000 });
                     
                        this.loading = false;
                        setTimeout(()=>{
                            window.location.reload();
                        },1500);
                    }
                  
                },
                error => {
                    this.loading = true;
                });
        }
        else {
            this.loading = false;
        }
    }

    loadOrganizations(e: LazyLoadEvent = { first: 0, rows: 10, sortField: 'name', sortOrder: 1 }) {
        var searchParams = [];
        searchParams['pageNo'] = (e.first / e.rows);
        searchParams['pageSize'] = e.rows;
        if (e.sortField && e.sortField != '')
            searchParams['sortBy'] = e.sortField;

        searchParams['sortDir'] = e.sortOrder == 0 ? 'asc' : 'desc';
        if (!this.organizationLoading)
            this.organizationLoading = true;

        this.usersService.getOrganizations(this.uuid, searchParams).subscribe(
            data => {
                let response = data as any;
                this.organizationTotalRecords = response.totalRecords;
                this.organizations = response.records;
                this.organizationLoading = false;
            });
    }
    setAsContact(type:string) {     
        this.dataService.setData('addAsContact', true)
        let rootRule: RuleSet = { "condition": "and", ruleSets: [], "rules": [] };
        this.searchHelper._search(rootRule, '/contacts/' + this.uuid + '/' + type + '/add', null, "relevance", -1, 0, this.appUtil.pageSize, true);

    }
    setAsProspects(type: string) {
        this.dataService.setData('addAsContact', false);
        let rootRule: RuleSet = { "condition": "and", ruleSets: [], "rules": [] };
        this.searchHelper._search(rootRule, '/contacts/' + this.uuid + '/' + type + '/add', null, "relevance", -1, 0, this.appUtil.pageSize, true);
    }

    loadBuySides(e: LazyLoadEvent = { first: 0, rows: 5, sortField: 'screenTitle', sortOrder: 1 }) {
        var searchParams = {};
        searchParams['page'] = (e.first / e.rows);
        searchParams['pageSize'] = e.rows;
        var sortBy = []
        var sortDir = e.sortOrder == 1 ? 'DESC' : 'ASC';
        if (e.sortField && e.sortField != '') {
            sortBy[sortBy.length] = { "property": e.sortField, "direction": sortDir };
        }
        searchParams['sortDetails'] = sortBy;
        if (this.serchBuy != '') {
            searchParams['query'] = this.serchBuy;
        }
        searchParams['rootRule'] = { 'condition': 'or', rules: [] }
        searchParams['rootRule']['rules'].push({ 'searchType': 'exact', 'field': 'contacts', 'operator': 'equals', 'value': this.uuid });
        this.usersService.getBuySides(this.uuid, searchParams).subscribe(
            data => {
                let response = data as any;
                this.buysideTotalRecords = response.totalRecords;
                this.buysides = response.records;
                this.buysideLoading = false;
            });
    }

    loadEmailCampaigns(e: LazyLoadEvent = { first: 0, rows: 5, sortField: 'title', sortOrder: 1 }) {
        var searchParams = {};
        searchParams['page'] = (e.first / e.rows);
        searchParams['pageSize'] = e.rows;
        var sortBy = []
        var sortDir = e.sortOrder == 1 ? 'DESC' : 'ASC';
        if (e.sortField && e.sortField != '') {
            sortBy[sortBy.length] = { "property": e.sortField, "direction": sortDir };
        }
        searchParams['sortDetails'] = sortBy;
        this.usersService.getEmailCampaigns(this.uuid, searchParams).subscribe(
            data => {
                let response = data as any;
                this.emailCampaignTotalRecords = response.totalRecords;
                this.emailCampaigns = response.records;
                this.emailCampaignLoading = false;
            });
    }

    loadSellSides(e: LazyLoadEvent = { first: 0, rows: 5, sortField: 'screenTitle', sortOrder: 1 }) {
        var searchParams = {};
        searchParams['page'] = (e.first / e.rows);
        searchParams['pageSize'] = e.rows;
        var sortBy = []
        var sortDir = e.sortOrder == 1 ? 'DESC' : 'ASC';
        if (e.sortField && e.sortField != '') {
            sortBy[sortBy.length] = { "property": e.sortField, "direction": sortDir };
        }
        searchParams['sortDetails'] = sortBy;
        if (this.serchSell != '') {
            searchParams['query'] = this.serchSell;
        }
        searchParams['rootRule'] = { 'condition': 'or', rules: [] }
        searchParams['rootRule']['rules'].push({ 'searchType': 'exact', 'field': 'contacts', 'operator': 'equals', 'value': this.uuid });
        this.usersService.getSellSides(this.uuid, searchParams).subscribe(
            data => {
                let response = data as any;
                this.sellsideTotalRecords = response.totalRecords;
                this.sellsides = response.records;
                this.sellsideLoading = false;
            });
    }


    loadportfolio(e: LazyLoadEvent = { first: 0, rows: 5, sortField: 'name', sortOrder: 1 }) {
        this.portfolioLoading = true;
        var searchParams = {};
        searchParams['page'] = (e.first / e.rows);
        searchParams['pageSize'] = e.rows;
        var sortBy = []
        searchParams['sortDetails'] = sortBy;
        if (!this.portfolioLoading)
            this.portfolioLoading = true;
        this.usersService.getPortfolioCompanies(this.uuid, searchParams).subscribe(
            data => {
                let response = data as any;
                this.portfolioTotalRecords = response.totalRecords;
                this.portfolio = response.records;
                this.portfolioLoading = false;
            });
    }

    handleError(elemId) {
        this.messageService.add({ severity: 'error', summary: 'Form Errors', detail: "Form has errors. Please fix them first", life: 3000 });
        let invalidControl = this.formElement.nativeElement.querySelector('.ng-invalid');
        if (invalidControl) {
            invalidControl.focus();
        }
        else if (elemId != null) {
            invalidControl = this.formElement.nativeElement.querySelector('#' + elemId);
            if (invalidControl) {
                invalidControl.focus();
            }
        }
    }

    changeStatus() {

        let st = '';
        if (this.currntStatus === "PENDING_VERIFICATION") {
            this.labelStatus = "Revoke Profile";
            st = 'PENDING_VERIFICATION';
        }
        if (this.currntStatus === "ACTIVE") {
            this.labelStatus = "Activate Profile";
            st = 'REVOKED';
        }
        if (this.currntStatus === "REVOKED") {
            this.labelStatus = "Revoke Profile";
            st = 'ACTIVE';

        }
        let dataToPost: any = { status: st };
        let apiToCall = this.usersService.updateStatus(this.contact.uuid, dataToPost);
        apiToCall.subscribe(
            data => {
                this.contact = data as any;
                this.currntStatus = this.contact.status;
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: "Status has been changed successfully", life: 3000 });
                this.userStatus = true;
            },
            error => {
                let errors = error.error;
                this.userStatus = true;
                if (errors.code && errors.code == 'VALIDATION_ERROR') {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: errors.subErrors[0].message, life: 3000 });
                }
            }
        );
    }

    get pf() { return this.passwordForm.controls; }

    loadPassword() {
        this.passwordForm = this.formBuilder.group({
            password: ['', [Validators.required, Validators.minLength(6)]]
        })
    }

    newPassword() {
        if (this.contact.email == null || this.contact.email == '') {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: "Please complete user profile", life: 3000 });
        }
        else {
            this.passwordForm.reset();
            this.dialogTitlePassword = "New Password";
            this.passwordsDialog = true;
        }
    }

    hidePassword() {
        this.pfSubmitted = false;
        this.passwordForm.reset();
        this.passwordsDialog = false;
    }

    showHidePassword() {
        this.showPassword = !(this.showPassword);
    }

    createCredential() {
        this.pfSubmitted = true;
        if (this.passwordForm.invalid) {
            return;
        }
        this.loadingSpinner = true;
        let dataToPost: any = { uuid: this.contact.uuid, 'password': this.appUtil.passwordEncrypt(this.pf.password.value) };
        let apiToCall = this.usersService.createCredential(this.contact.uuid, dataToPost);
        apiToCall.subscribe(
            data => {
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: "Credentials has been created Successfully", life: 3000 });
                this.passwordsDialog = false;
                this.loadingSpinner = false;
                this.userCredentials = false;
            }
        );

    }

    resetPassword() {

        let apiToCall = this.accountService.forgotPassword(this.contact.email);
        apiToCall.subscribe(
            data => {
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: "Reset password email has been sent successfully", life: 3000 });
            },
            error => {
                let errors = error.error;
                this.messageService.add({ severity: 'error', summary: 'Error', detail: errors.subErrors[0].message, life: 3000 });
            }
        );
    }

    generatePassword() {
        var length = 8,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        this.pf.password.setValue(retVal);
    }

    goToLink() {
        let url = this.contact.linkedInProfile;
        let chString = "https";
        if (url.indexOf(chString) !== -1)
            window.open(url, "_blank");
        else
            window.open('https://' + url, "_blank");
    }


    loadMailLogs(e: LazyLoadEvent = { first: 0, rows: 5, sortField: 'receivedOn', sortOrder: 1 }) {
        this.mailLogsLoading = true;
        var searchParams = {};
        searchParams['page'] = (e.first / e.rows);
        searchParams['pageSize'] = e.rows;
        var sortBy = []
        var sortDir = e.sortOrder == 1 ? 'DESC' : 'ASC';
        if (e.sortField && e.sortField != '') {
            sortBy[sortBy.length] = { "property": e.sortField, "direction": sortDir };
        }
        searchParams['sortDetails'] = sortBy;
        if (this.serchBuy != '') {
            searchParams['query'] = this.serchBuy;
        }
        this.usersService.getMailLogs(this.uuid, searchParams).subscribe(
            data => {
                let response = data as any;
                this.mailLogTotalRecords = response.totalRecords;
                this.mailLogs = response.records;
                this.mailLogsLoading = false;
                this.appUtil.reinitializeShowMore();
            });
    }

    openDocument(event) {
        event.preventDefault();
        var filename = event.target.href;
        this.http.get(filename, { responseType: 'arraybuffer' }).subscribe(
            data => {
                var file = new Blob([data], { type: mimeType.getType(filename) });
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            });
    }

    getReletedProfile() {
        this.usersService.getReletedProfile(this.route.snapshot.params.uuid).subscribe(data => {
            this.relatedProfiles = data as any;
            if (this.relatedProfiles != null && this.relatedProfiles.length > 0) {
                this.showRelatedProfile = true;
            }
        });
    }

    loadRelatedForm() {
        this.relatedProfileForm = this.formBuilder.group({
            membersToAdd: [null, [Validators.required, Validators.maxLength(40)]],
            membersToRemove: []
        })
    }

    get acf() { return this.relatedProfileForm.controls; }

    SaveRelatedProfile() {
        this.submitted = true;
        if (this.relatedProfileForm.invalid) {
            this.messageService.add({ severity: 'error', summary: 'Form Errors', detail: "Please fill the related profile", life: 3000 });
            return false;
        }
        let { membersToAdd, membersToRemove } = this.relatedProfileForm.value;
        let dataToPost: any = { membersToAdd, membersToRemove };
        dataToPost.membersToAdd = this.appUtil.getClassificationUuid(this.acf.membersToAdd.value);
        let apiToCall: any;
        for (let i = 0; i < membersToAdd.length; i++) {
            const list = this.relatedProfileForm.value.membersToAdd[i].name;
            const isProfileExists = this.relatedProfiles.find(profile =>
                profile.name === list
            );
            if (isProfileExists) {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: (this.relatedProfiles.length > 1 ? "RelatedProfiles are" : "RelatedProfile is") + " already exists.", life: 3000 });
                this.showDropdown = false;
                return false;
            }
        }
        apiToCall = this.usersService.addReletedProfile(this.uuid, dataToPost);
        apiToCall.subscribe(
            data => {
                this.relatedProfileForm.reset();
                this.getReletedProfile();
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: "Save Successfully", life: 3000 });
                this.submitted = false;
                this.showDropdown = false;
            },
            error => {
                let errors = error.error;
                if (errors.code && errors.code == 'VALIDATION_ERROR') {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: errors.subErrors[0].message, life: 3000 });
                }
            }
        );

    }

    deleteProfile(profileUUid) {
        let { membersToRemove } = this.relatedProfileForm.value;
        let dataToPost: any = { membersToRemove };
        let apiToCall: any;
        dataToPost.membersToRemove = [profileUUid]
        apiToCall = this.usersService.addReletedProfile(this.uuid, dataToPost);
        apiToCall.subscribe(
            data => {
                this.relatedProfileForm.reset();
                this.getReletedProfile();
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: "Remove Successfully", life: 3000 });
            });
    }
    setCurrentSellside() {
        this.dataService.setData('user-', this.contact);
        this.router.navigate(['/sell-side/new'], { queryParams: { 'contact': this.contact.uuid } });
    }
    setCurrentBuyside() {
        this.dataService.setData('user-', this.contact);
        this.router.navigate(['/buy-side/new'], { queryParams: { 'contact': this.contact.uuid } });
    }
    setCurrentCampaign() {
        this.router.navigate(['/email-campaign/new'], { queryParams: { 'contact': this.contact.uuid } });
    }

    getPeBuyout(buyside: any) {

        let val = "PE Buyout - ";
        let separator = "";
        if (buyside.primaryObjectives.peBuyout.tuckin) {
            val = val + "Tuck-in (Majority or 100%)";
            separator = "; ";
        }
        if (buyside.primaryObjectives.peBuyout.platform) {
            val = val + separator + "Platform";
        }
        if (val == "PE Buyout - ") {
            val = "";
        }
        return val;
    }

    getInvestmentDebt(buyside: any) {

        let debt = buyside.primaryObjectives.investment.debt;
        let val = "Investment (Debt) - ";
        let separator = "";

        if (debt.arFinancing) {
            val = val + "A/R Financing";
            separator = "; "
        }
        if (debt.juniorDebt) {
            val = val + separator + "Junior Debt"
            separator = "; "
        }
        if (debt.mezzanineFinancing) {
            val = val + separator + "Mezannine Financing";
            separator = "; "
        }
        if (debt.seniorDebt) {
            val = val + separator + "Senior Debt";
        }
        if (val == "Investment (Debt) - ") {
            val = "";
        }
        return val;
    }

    getInvestmentEquity(buyside: any) {
        let equity = buyside.primaryObjectives.investment.equity;
        let val = "Investment (Equity) - ";
        let separator = "";

        if (equity.angelInvestment) {
            val = val + "Angel Investment";
            separator = "; "
        }
        if (equity.growthEquity) {
            val = val + separator + "Growth Equity"
            separator = "; "
        }
        if (equity.minorityRecap) {
            val = val + separator + "Minority recap";
            separator = "; "
        }
        if (equity.ventureCapital) {
            val = val + separator + "Venture Capital";
        }
        if (val == "Investment (Equity) - ") {
            val = "";
        }
        return val;
    }

    getPrivateEquityPartner(sellside: any) {

        let val = "Private Equity Partner - ";
        let separator = "";
        if (sellside.primaryObjectives.privateEquityPartner.controlling) {
            val = val + separator + "Control";
            separator = "; ";
        }
        if (sellside.primaryObjectives.privateEquityPartner.hundredPercent) {
            val = val + separator + "100%";
            separator = "; ";
        }
        if (sellside.primaryObjectives.privateEquityPartner.minority) {
            val = val + separator + "Minority";
            separator = "; ";
        }
        if (val == "Private Equity Partner - ") {
            val = "";
        }
        return val;
    }

    getFinancingDebt(sellside: any) {

        let debt = sellside.primaryObjectives.financing.debt;
        let val = "Obtain Financing (Debt) - ";
        let separator = "";
        if (debt.arFinancing) {
            val = val + separator + "A/R Financing";
            separator = "; ";
        }
        if (debt.juniorDebt) {
            val = val + separator + "Junior Debt"
            separator = "; "
        }
        if (debt.mezzanineFinancing) {
            val = val + separator + "Mezannine Financing";
            separator = "; "
        }
        if (debt.seniorDebt) {
            val = val + separator + "Senior Debt";
        }
        if (val == "Obtain Financing (Debt) - ") {
            val = "";
        }
        return val;
    }
    getFinancingEquity(sellside: any) {

        let equity = sellside.primaryObjectives.financing.equity;
        let val = "Obtain Financing (Equity) - ";
        let separator = "";
        if (equity.angelInvestment) {
            val = val + separator + "Angel Investment";
            separator = "; ";
        }
        if (equity.growthEquity) {
            val = val + separator + "Growth Equity";
            separator = "; ";
        }
        if (equity.minorityRecap) {
            val = val + separator + "Minority Recap";
            separator = "; ";
        }
        if (equity.ventureCapital) {
            val = val + separator + "Venture Capital";
            separator = "; ";
        }
        if (val == "Obtain Financing (Equity) - ") {
            val = "";
        }
        return val;
    }
    onCancel() {
        this.visible = false;
    }
    showDialog() {
        this.visible = true;
    }
    deleteContact() {
        if (this.deleteConfirmation.toUpperCase() === 'DELETE') {
            this.usersService.deleteContact(this.uuid).subscribe(
                (data) => {
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Successful',
                        detail: 'Contact deleted Successfully',
                        life: 3000,
                    });
                    setTimeout(() => {
                        this.router.navigate(['/contacts']);
                    }, 600);
                },
                (error) => {
                    let errors = error.error;
                    if (errors.code && errors.code === 'VALIDATION_ERROR') {
                        this.messageService.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: errors.subErrors[0].message,
                            life: 3000,
                        });
                    }
                }
            );
        }
    }
    deleteContactOnEnter() {
        if (this.isDeleteEnabled()) {
            this.deleteContact();
        }
    }
    isDeleteEnabled(): boolean {
        return this.deleteConfirmation.toLowerCase() === 'delete';
    }

    checkIfAnyContactIsProspect(contacts: any[]): boolean {

        return contacts.some(contact => contact.name === this.contact.name);
    }
    setCurrentItem(user) {
        let items = [
            {
                label: "Remove",
                icon: "pi pi-trash",
                command: ($event) => {
                    this.deleteProfile(user.uuid)
                },
            },
        ]
        this.contactItems = items;
    }
    toggleDropdown() {
        this.showDropdown = true;
    }
    deepCopy(obj: any ): any {
        if (obj === undefined) {
            return obj;
        }
        return JSON.parse(JSON.stringify(obj));
      }
    
    get role() {
        return this.accountService.userValue.role;
    }
    editField(fieldName: string) {
         
        this.initialFieldValues[fieldName] = this.deepCopy(this.contact[fieldName]);
        for (let key in this.editingField) {            
            this.editingField[key] = false;
        }
          
        if (fieldName == 'accreditedInvestor' && (this.contact["organization"] != null || this.contact["organization"] != undefined)) {
            this.editingField[fieldName] = false;
        } else if (fieldName == 'accreditedInvestor' && (this.contact["organization"] == null || this.contact["organization"] == undefined)) {

            this.editingField[fieldName] = true;
        }
        if (fieldName == 'primaryContact' && (this.contact["organization"] != null || this.contact["organization"] != undefined)) {

            this.editingField[fieldName] = true;
        } else if (fieldName == 'primaryContact' && (this.contact["organization"] == null || this.contact["organization"] == undefined)) {

            this.editingField[fieldName] = false;
        }
        else if(fieldName == 'title' && (this.contact["organization"] != null || this.contact["organization"] != undefined)){
            this.editingField[fieldName] = true;
        }
        if (fieldName !== 'accreditedInvestor' && fieldName !== 'primaryContact' && fieldName !== 'title') {
            this.editingField[fieldName] = true;
        }
    }

    closeDescriptionDialog(fieldName: string) {
        this.contact[fieldName] = this.deepCopy(this.initialFieldValues[fieldName]);
        this.editingField[fieldName] = false;
        event.stopPropagation();
       // this.reload();
    }
    saveField(fieldName: string, value: any) {   
        let fieldsToUpdate = [];
        fieldsToUpdate.push(fieldName);
        if ((fieldName === 'firstName' || fieldName === 'lastName' || fieldName === 'email' ) && (value === '' || value === undefined)) {
            this.messageService.add({
                severity: 'error', summary: 'Error', detail: `${fieldName} can't be null or empty`, life: 3000
            });
            event.stopPropagation();
            return false;
        }  
        if (this.initialFieldValues[fieldName] === value) {
            this.editingField[fieldName] = false;
            event.stopPropagation();
            return;
        }
          
        let dataToPost: any = { uuid: this.contact.uuid, fieldsToUpdate: value };
        
            dataToPost[fieldName] = value;

        if ((fieldName === 'email' || fieldName === 'secondaryEmail' || fieldName === 'assistantEmail') && this.isInvalidEmail(value)) {
            return;
        }
        if (fieldName === 'leadSource') {
            dataToPost['leadSource'] = value?value.value : null;
        }
        if (fieldName === 'mailingList') {
            dataToPost['mailingList'] = value ? value.map(item => item.value) : null;
        }
        if (fieldName === 'country') {
            dataToPost['country'] = value?value.name : null;
            dataToPost['state'] = null;
            fieldsToUpdate.push('state');
        }
        if (fieldName === 'state') {
            dataToPost['state'] =  value?value.name : null;
        }  
        this.usersService.updateContactList(fieldsToUpdate, dataToPost).subscribe(
            data => {
                this.loading = false;
                if (fieldName === 'classification') {
                    const relatedFields = ['Level1', 'Level2', 'Level3', 'Vertical/Industry', 'Ecosystem'];
                    relatedFields.forEach(field => this.editingField[field] = false);
                } else {
                    this.editingField[fieldName] = false;
                }
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: `${this.formatFieldName(fieldName)} Updated Successfully`, life: 3000 });
                this.reload();
            },
            error => {
                let errors = error.error;
                if (errors.code && errors.code == 'VALIDATION_ERROR') {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: errors.subErrors[0].message, life: 3000 });
                }
            }
        )
    }
    isInvalidEmail(email: string): boolean {
        if(email === '' || email === undefined) {
            return false;
        }
        return !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }
    searchOrganizations(event) {
        this.organizationService.lookupOrganization(event.query).subscribe(data => {
            this.organizationResults = data as any;
        });
    }
    getOutput(Value: any) {
        if (Value && typeof Value === 'object') {
            return Value.name ? Value.name : Value;
        }
        return Value;
    }
    getLookupOutput(Value: any) {
        if (Value && typeof Value === 'object') {
            return Value.toString() ? Value.toString() : Value;;
        }
        return Value;
    }

    searchLookupValue(event: AutoCompleteCompleteEvent, property: string) {
        let filtered: any[] = [];
        let query = event.query;
        let sources: any[] = [];
        if (property == 'accountOwner' || property == 'country' || property) {
            switch (property) {
                case 'accountOwner':
                    sources = this.tbpEmployees;
                    break;
                case 'country':
                    sources = this.countries;
                    break;
            }
            for (let i = 0; i < (sources as any[]).length; i++) {
                let employee = (sources as any[])[i];
                if (employee.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(employee);
                }
            }
            this.filteredValues = filtered;
        }
    }
    disableNavigation(event: any) {
        if (event.key === "ArrowRight" || event.key === "Right"
            || event.key === "ArrowLeft" || event.key === "Left"
            || event.key === "ArrowUp" || event.key === "Up"
            || event.key === "ArrowDown" || event.key === "Down") {
            event.stopPropagation();
        }
    }

    emailPreferences = [
        { label: 'Send M&A Opportunities', fieldName: 'sendMAOpportunityEmails' },
        { label: 'Send Spotlights', fieldName: 'sendSpotlightEmails' },
        { label: 'Do not send me any information', fieldName: 'doNotSendUpdates' }
    ];

    categories = [
        { label: 'Level1', value: 'CATEGORY_LEVEL1', model: 'level1' },
        { label: 'Level2', value: 'CATEGORY_LEVEL2', model: 'level2' },
        { label: 'Level3', value: 'CATEGORY_LEVEL3', model: 'level3' },
        { label: 'Vertical/Industry', value: 'VERTICAL', model: 'vertical' },
        { label: 'Ecosystem', value: 'ECOSYSTEM', model: 'ecosystem' }
    ];
    formatFieldName(fieldName: string): string {

        const words = fieldName.split(/(?=[A-Z])/);
        return words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
    }
}