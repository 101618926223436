import { TaskService } from './task.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LazyLoadEvent, MenuItem } from 'primeng/api';
import {  Component, Input, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { BreadcrumbService } from '../app.breadcrumb.service';
import { AppUtil } from '../_helpers';
import { User } from '../_models';

@Component({
    selector: 'task-toggle-list',
    templateUrl: './task-toggle-list.component.html',
    styleUrls: ['./task-toggle-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TaskToggleListComponent implements OnInit {

    @Input() parent: any = null;
    @Input() parentUuid: string = null;
    @Input() taskType: string = null; /* organization, contact */
    searchMenuItems: MenuItem[];
    tasks: Task[];
    selectedTasks: Task[] = [];
    loading: boolean = true;
    totalRecords: number = 0;
    showForm: boolean = false;
    uuid: string = null;
    showListing: boolean = false;
    term: string;
    contact: User;
    checkTaskType: boolean = false;
    CONTACT :any;
    constructor(
        private route: ActivatedRoute,
        private breadCrumbService: BreadcrumbService,
        private router: Router,
        private taskService: TaskService,
        private appUtil: AppUtil,      
        ) 
        
    {   
        if (this.router.url.indexOf("/tasks") != -1) {
            let searchUrl = this.router.url.split("/")
            this.parentUuid = searchUrl[2];
            this.taskType = searchUrl[1];    

            this.breadCrumbService.setItems([{ label: 'Task Listing' }])
            const e: LazyLoadEvent = { first: 0, rows: -1, sortField: 'lastModifiedDate', sortOrder: 1 };
                const searchParams: any = {
                    page:e.first / e.rows,
                    pageSize: this.showListing ? -1 :  e.rows,
                    sortDetails: e.sortField && e.sortField !== '' ? [{ property: e.sortField, direction: e.sortOrder === 1 ? 'DESC' : 'ASC' }] : [],
                };
               this.loading= true ;
               this.term = '';
               this.showListing = true;
            if(this.taskType === "contact"){
                this.taskService.getContact(this.parentUuid).subscribe(
                    (contactData) => {
                      this.CONTACT = contactData;
                      this.parent = this.CONTACT;
                    },
                    (error) => {
                      console.error("Error fetching contact:", error);
                    }
                  );
            }
            else if(this.taskType === "tasks" || this.taskType === "upComingTask" || this.taskType === "overDueTask"){       
                this.checkTaskType = true; 
               this.taskService.searchDashboardTasks(this.parentUuid,searchParams ,this.taskType).subscribe((data: any) => this.handleSearchResults(data));
            }     
         }
         this.term = '';
          }
       ngOnInit(): void {
        this.route.params.subscribe(data => {
            this.showListing = data.showListing == 'true'?true:false;
            if(this.showListing && this.checkTaskType!=true){             
               this.search(); 
            }
        });
        
        this.searchMenuItems = [{
            label: 'Upcoming Task',
            command: () => {
            this.taskType = "upComingTask";
          
            }
        },
        {
            label: 'Overdue Task',
            command: () => {
                this.taskType = "overDueTask";
               
           }
        },
        {
            label: 'All Task',
            command: () => {
               this.taskType = "tasks";
              
            }
        }
        ];
    }
    ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty("parentUuid")) {
            this.search();
        }
    }
    new() {
        this.uuid = null;
        this.showForm = true;
       
    } 
    edit(task: any) {
        this.uuid = task.uuid;
        this.contact = task.contact;
        this.showForm = true;   
    }

    search() {
        const e: LazyLoadEvent = { first: 0, rows: 5, sortField: 'lastModifiedDate', sortOrder: 1 };
        const searchParams: any = {
            page:e.first / e.rows,
            pageSize: this.showListing ? -1 :  e.rows,
            sortDetails: e.sortField && e.sortField !== '' ? [{ property: e.sortField, direction: e.sortOrder === 1 ? 'DESC' : 'ASC' }] : [],
        };
        if (this.term !== '') {
            if (this.taskType === 'organization') {
                this.searchTaskForOrganization(searchParams);
            } else {
                if(this.taskType !== 'allTask'){
                    searchParams.rootRule = {
                        condition: 'and',
                        rules: [
                            { searchType: 'textsearch', field: 'subject', value: this.term, operator: 'matches' },
                            {
                                searchType: 'textsearch',
                                field: 
                                    this.taskType === 'buyside' ? 'buySideScreen.uuid' :
                                    this.taskType === 'sellside' ? 'sellSideScreen.uuid' : 
                                    ['upcomingTask', 'overDueTask'].includes(this.taskType) ? 'assignedTo.uuid' :
                                    `${this.taskType}s.uuid`,
                                value: this.parentUuid,
                                operator: 'matches'
                            }
                            
                        ],
                    };
                }else{
                    searchParams.rootRule = {
                        condition: 'and',
                        rules: [
                            { searchType: 'textsearch', field: 'subject', value: this.term, operator: 'matches' }
                        ]
                    }
                }
                this.taskService.searchTasks(searchParams).subscribe((data: any) => this.handleSearchResults(data));
            }
        } else  {
            if (this.loading) this.loading = true;    
            if( ['allTask','upComingTask', 'overDueTask'].includes(this.taskType)){
                this.taskService.searchDashboardTasks(this.parentUuid,searchParams ,this.taskType).subscribe((data: any) => this.handleSearchResults(data));
            }else{
                this.taskService.getList(this.taskType, this.parentUuid, searchParams).subscribe((data: any) => this.handleSearchResults(data));
            }
        }
    } 
    searchTaskForOrganization(searchParams: any) {
        const contactUuids: any[] = [];
        this.taskService.getContacts(this.parentUuid, { page: 0, pageSize: 25 }).subscribe((data: any) => {
            const response = data as any;
            contactUuids.push(...response.records.map((currentValue: { uuid: any }) => currentValue.uuid));
            searchParams.rootRule = {
                condition: 'and',
                rules: [
                    { searchType: 'textsearch', field: 'subject', value: this.term, operator: 'matches' },
                ],
            };
            if (contactUuids.length > 0) {
                searchParams.rootRule.rules.push({
                    condition: 'or',
                    rules: [
                        { searchType: 'textsearch', field: `${this.taskType}.uuid`, value: this.parentUuid, operator: 'matches' },
                        { searchType: 'exactsearch', field: 'contacts.uuid', values: contactUuids, operator: 'in' },
                    ],
                });
            } else {
                searchParams.rootRule.rules.push({ searchType: 'textsearch', field: `${this.taskType}.uuid`, value: this.parentUuid, operator: 'matches' });
            }
    
            this.taskService.searchTasks(searchParams).subscribe((data: any) => this.handleSearchResults(data));
        });
    }
    handleSearchResults(data: any) {
        const response = data as any;
        this.totalRecords = response.totalRecords;
        this.tasks = response.records;
        this.loading = false;
        this.appUtil.reinitializeShowMore();
    }
}    
function someMethod() {
    throw new Error('Function not implemented.');
}

