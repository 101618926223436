import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from '../app.breadcrumb.service';
import { Location } from '@angular/common';
import { AccountService } from 'src/app/_services';
import { SellsideService } from './sellside.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MessageService, ConfirmationService } from 'primeng/api';
import { AppUtil } from './../_helpers';
import { DataService } from "../_services/data.service";
import { UsersService } from '../users/users.service';
import { OrganizationService } from '../organization/organization.service';

@Component({
    selector: 'app-add.sellside',
    templateUrl: './add.sellside.component.html',
    styleUrls: ['./add.sellside.component.scss'],
    providers: [MessageService, ConfirmationService]
})
export class AddSellsideComponent implements OnInit {
    @ViewChild('cform') formElement;
    form: UntypedFormGroup;
    items: MenuItem[];
    activeIndex: number;
    equity: any[];
    debt: any[];
    managementPreference: any[];
    timeline: any[];
    privateEquityPartner: any[];
    formsubmited: boolean = false;
    isEdit: boolean = false;
    loadingSpinner: boolean = false;
    companyResults: [];
    organizations: any[] = [];
    participantResults: [];
    participants: [];
    classificationList: any = {
        CATEGORY_LEVEL1: [], CATEGORY_LEVEL2: [], CATEGORY_LEVEL3: [],
        VERTICAL: [], ECOSYSTEM: []
    };
    lookupList: any = { REVENUE: [], EBITDA: []};
    accountForm: boolean = false;
    tbpEmployees = [];
    helpList: any = {SELLSIDE:[]};
    model:any;
    constructor(private organizationService: OrganizationService,
        private usersService: UsersService,
        private dataService: DataService,
        private sellsideService: SellsideService,
        private router: Router,
        private messageService: MessageService,
        private _location: Location,
        private formBuilder: UntypedFormBuilder,
        public accountService: AccountService,
        private route: ActivatedRoute,
        private breadcrumbService: BreadcrumbService,
        private confirmationService: ConfirmationService,
        private appUtil:AppUtil,
    ) {

        if (this.router.url.indexOf("/edit/") != -1) {
            this.isEdit = true;
        }
        if (this.isEdit) {
            this.sellsideService.getSellside(this.route.snapshot.params.uuid).subscribe(data => {
                this.modelToForm(data as any);
            });
            this.breadcrumbService.setItems([
                { label: 'Edit Sell Side' }
            ]);
        } else {
            this.breadcrumbService.setItems([
                { label: 'New Sell Side Screen' }
            ]);
        }

        this.debt = [
            {value: 'A/R Financing'},
            {value: 'Junior Debt'},
            {value: 'Mezzanine Financing'},
            {value: 'Senior Debt'},
        ];
        this.equity = [
            {value: 'Growth Equity'},
            {value: 'Venture Capital'},
            {value: 'Angel Investment'},
            {value: 'Minority Recap'},
        ];
        this.privateEquityPartner = [
            {value: 'Control'},
            {value: '100%'},
            {value: 'Minority'},
        ];
        this.managementPreference = [
            {value: 'Flexible'},
            {value: 'Management will exit'},
            {value: 'Management will stay'},
        ];
        this.timeline = [
            {value: '3-6 months'},
            {value: 'Next 3 months'},
            {value: 'No immediate plans'},
        ];

    }

    ngOnInit(): void {
        this.classificationList = this.route.snapshot.data['classificationList'];
        this.lookupList = this.route.snapshot.data['lookupList'];
        this.tbpEmployees = this.route.snapshot.data['tbpEmployees'];
        this.helpList = this.route.snapshot.data['helpList']['SELLSIDE'];
        this.loadSellSideForm();
        this.activeIndex = 0;
        this.items = [
        {
            label: 'Screen Details',
            command: (event: any) => {
                this.activeIndex = 0;
            }
        },
        {
            label: 'Company Information',
            command: (event: any) => {
                this.activeIndex = 1;
            }
        },
        {
            label: 'Preview',
            command: (event: any) => {
                this.activeIndex = 2;
            }
        }];
        if (this.isEdit == false) {
            const org = this.dataService.getDataByKey('org-');
            const user = this.dataService.getDataByKey('user-');
            if (org != undefined) {
                this.f.organization.setValue(org);
            }
            if (user != undefined) {
                this.f.contacts.setValue([user]);
            }
        }
    }

    get f() { return this.form.controls; }

    checkClassifications(dbList, currentList) {
        if (dbList) {
            const temp = [...this.classificationList[currentList]];
            for (let i = 0; i < dbList.length; i++) {
                if (dbList[i].uuid == null) {
                    dbList[i].uuid = '-1' + dbList[i].name;
                    temp.push(dbList[i]);
                }
            }
            this.classificationList[currentList] = temp;
        }
    }

    searchCompanies(event) {
        this.organizationService.lookupOrganization(event.query).subscribe(data => {
            this.companyResults = data as any;
        });
    }

    searchParticipant(event) {
        this.usersService.lookupContact(event.query).subscribe(data => {
            this.participantResults = data as any;
        });

    }

    handleError(elemId) {
        this.messageService.add({ severity: 'error', summary: 'Form Errors', detail: "Form has errors. Please fix them first", life: 3000 });
        let invalidControl = this.formElement.nativeElement.querySelector('.ng-invalid');

        if (invalidControl) {
            invalidControl.focus();
        }
        else if (elemId != null) {
            invalidControl = this.formElement.nativeElement.querySelector('#' + elemId);
            if (invalidControl) {
                invalidControl.focus();
            }
        }
    }

    loadSellSideForm() {
        this.form = this.formBuilder.group({
            uuid: [''],
            contacts: [],
            strategicPartner: [false],
            organization: ['', [Validators.required]],
            debtType: [],
            screenTitle: ['', [Validators.required]],
            externalHeadline: ['', [Validators.required]],
            managementPreference: ['Flexible'],
            turnaround: [false],
            transactionObjective: [],
            companyDescription: [],
            timeline: [],
            level1: [],
            level2: [],
            level3: [],
            vertical: [],
            ecosystem: [],
            equityType: [],
            targetRevenue: [],
            targetEbitda: [],
            numberOfEmployee:[],
            equityPartnerType:[],
            hasBlindProfile: [false]
        });
    }

    modelToForm(model) {
        let debtTypeValues = [];
        if(model.primaryObjectives.financing.debt.arFinancing)
        {
            debtTypeValues.push(this.appUtil.findItemInList(this.debt, 'A/R Financing', 'value').value)
        }
        if(model.primaryObjectives.financing.debt.juniorDebt)
        {
            debtTypeValues.push(this.appUtil.findItemInList(this.debt, 'Junior Debt', 'value').value)
        }
        if(model.primaryObjectives.financing.debt.mezzanineFinancing)
        {
            debtTypeValues.push(this.appUtil.findItemInList(this.debt, 'Mezzanine Financing', 'value').value)
        }
        if(model.primaryObjectives.financing.debt.seniorDebt)
        {
            debtTypeValues.push(this.appUtil.findItemInList(this.debt, 'Senior Debt', 'value').value)
        }
        let equityTypeValues = [];
        if(model.primaryObjectives.financing.equity.growthEquity)
        {
            equityTypeValues.push(this.appUtil.findItemInList(this.equity, 'Growth Equity', 'value').value)
        }
        if(model.primaryObjectives.financing.equity.ventureCapital)
        {
            equityTypeValues.push(this.appUtil.findItemInList(this.equity, 'Venture Capital', 'value').value)
        }
        if(model.primaryObjectives.financing.equity.angelInvestment)
        {
            equityTypeValues.push(this.appUtil.findItemInList(this.equity, 'Angel Investment', 'value').value)
        }
        if(model.primaryObjectives.financing.equity.minorityRecap)
        {
            equityTypeValues.push(this.appUtil.findItemInList(this.equity, 'Minority Recap', 'value').value)
        }
        let equityPartnerTypeValues = [];
        if(model.primaryObjectives.privateEquityPartner.controlling)
        {
            equityPartnerTypeValues.push(this.appUtil.findItemInList(this.privateEquityPartner, 'Control', 'value').value)
        }
        if(model.primaryObjectives.privateEquityPartner.hundredPercent)
        {
            equityPartnerTypeValues.push(this.appUtil.findItemInList(this.privateEquityPartner, '100%', 'value').value)
        }
        if(model.primaryObjectives.privateEquityPartner.minority)
        {
            equityPartnerTypeValues.push(this.appUtil.findItemInList(this.privateEquityPartner, 'Minority', 'value').value)
        }

        this.checkClassifications(model.classification.level1, 'CATEGORY_LEVEL1');
        this.checkClassifications(model.classification.level2, 'CATEGORY_LEVEL2');
        this.checkClassifications(model.classification.level3, 'CATEGORY_LEVEL3');  
        this.checkClassifications(model.classification.vertical, 'VERTICAL');
        this.checkClassifications(model.classification.ecosystem, 'ECOSYSTEM');
        this.form.setValue({
            uuid: model.uuid,
            organization: model.organization,
            equityPartnerType: equityPartnerTypeValues,
            timeline: model.timeline,
            screenTitle: model.screenTitle,
            externalHeadline: model.externalHeadline,
            managementPreference: model.managementPreference,
            turnaround: model.turnaround,
            transactionObjective: model.transactionObjective,
            companyDescription: model.companyDescription,
            level1: (model.classification.level1 == null) ? null : model.classification.level1,
            level2: (model.classification.level2 == null) ? null : model.classification.level2,
            level3: (model.classification.level3 == null) ? null : model.classification.level3,
            vertical: (model.classification.vertical == null) ? null : model.classification.vertical,
            ecosystem: (model.classification.ecosystem == null) ? null : model.classification.ecosystem,
            targetRevenue: (model.targetRevenue == null || model.targetRevenue == '') ? null : this.lookupList['REVENUE'].find(field => field.value === model['targetRevenue']),
            targetEbitda: (model.targetEbitda == null || model.targetEbitda == '') ? null : this.lookupList['EBITDA'].find(field => field.value === model['targetEbitda']),
            contacts: model.contacts,
            equityType: equityTypeValues,
            debtType: debtTypeValues,
            strategicPartner: model.primaryObjectives.strategicPartner,
            numberOfEmployee: (model.numberOfEmployee == null || model.numberOfEmployee == '') ? null : this.lookupList['No_OF_EMPLOYEES'].find(field => field.value === model['numberOfEmployee']),
            hasBlindProfile: model.hasBlindProfile
        });
    }
    selectAccount(value) {
        if (value.classification.level1 != null)
            this.f.level1.setValue(value.classification.level1);
        if (value.classification.level2 != null)
            this.f.level2.setValue(value.classification.level2);
        if (value.classification.level3 != null)
            this.f.level3.setValue(value.classification.level3);
        if (value.classification.vertical != null)
            this.f.vertical.setValue(value.classification.vertical);
        if (value.classification.ecosystem != null)
            this.f.ecosystem.setValue(value.classification.ecosystem);
    }

    prepareModel(){
        let arFinancing =  this.appUtil.findItemInList(this.f.debtType.value, this.appUtil.findItemInList(this.debt, 'A/R Financing', 'value').value)?true:false;
        let juniorDebt =  this.appUtil.findItemInList(this.f.debtType.value, this.appUtil.findItemInList(this.debt, 'Junior Debt', 'value').value)?true:false;
        let mezzanineFinancing =  this.appUtil.findItemInList(this.f.debtType.value, this.appUtil.findItemInList(this.debt, 'Mezzanine Financing', 'value').value)?true:false;
        let seniorDebt =  this.appUtil.findItemInList(this.f.debtType.value, this.appUtil.findItemInList(this.debt, 'Senior Debt', 'value').value)?true:false;
        let growthEquity =  this.appUtil.findItemInList(this.f.equityType.value, this.appUtil.findItemInList(this.equity, 'Growth Equity', 'value').value)?true:false;
        let ventureCapital =  this.appUtil.findItemInList(this.f.equityType.value, this.appUtil.findItemInList(this.equity, 'Venture Capital', 'value').value)?true:false;
        let angelInvestment =  this.appUtil.findItemInList(this.f.equityType.value, this.appUtil.findItemInList(this.equity, 'Angel Investment', 'value').value)?true:false;
        let minorityRecap =  this.appUtil.findItemInList(this.f.equityType.value, this.appUtil.findItemInList(this.equity, 'Minority Recap', 'value').value)?true:false;
        let controlling =  this.appUtil.findItemInList(this.f.equityPartnerType.value, this.appUtil.findItemInList(this.privateEquityPartner, 'Control', 'value').value)?true:false;
        let hundredPercent =  this.appUtil.findItemInList(this.f.equityPartnerType.value, this.appUtil.findItemInList(this.privateEquityPartner, '100%', 'value').value)?true:false;
        let minority =  this.appUtil.findItemInList(this.f.equityPartnerType.value, this.appUtil.findItemInList(this.privateEquityPartner, 'Minority', 'value').value)?true:false;
        let debt = {arFinancing, juniorDebt,mezzanineFinancing,seniorDebt}
        let equity = {growthEquity, ventureCapital, angelInvestment, minorityRecap}
        let privateEquityPartner = {controlling, hundredPercent, minority}
        let { uuid, contacts, organization, timeline, screenTitle, externalHeadline, managementPreference, turnaround,
                 transactionObjective, companyDescription, targetRevenue, targetEbitda, hasBlindProfile } = this.form.value;
        let dataToPost: any = { uuid, contacts, organization, timeline,screenTitle, externalHeadline, managementPreference, turnaround,
                                 transactionObjective, companyDescription, targetRevenue, targetEbitda, hasBlindProfile,
            classification: {},
            primaryObjectives: { strategicPartner: this.f.strategicPartner.value, privateEquityPartner, financing: {debt, equity}} 
        };
        if (this.f.targetRevenue.value) {
            dataToPost.targetRevenue = this.f.targetRevenue.value.value;
        } if (this.f.targetEbitda.value) {
            dataToPost.targetEbitda = this.f.targetEbitda.value.value;
        }
        if (this.f.numberOfEmployee.value) {
            dataToPost.numberOfEmployee = this.f.numberOfEmployee.value.value;
        }
        if (this.f.level1.value) {
            dataToPost.classification.level1 = this.f.level1.value;
        }
        if (this.f.level2.value) {
            dataToPost.classification.level2 = this.f.level2.value;
        }
        if (this.f.level3.value) {
            dataToPost.classification.level3 = this.f.level3.value;
        }
        if (this.f.vertical.value) {
            dataToPost.classification.vertical = this.f.vertical.value;
        }
        if (this.f.ecosystem.value) {
            dataToPost.classification.ecosystem = this.f.ecosystem.value;
        }
        if (this.f.numberOfEmployee.value) {
            dataToPost.numberOfEmployee = this.f.numberOfEmployee.value.value;
        }
        if(this.f.hasBlindProfile.value){
            dataToPost.hasBlindProfile = this.f.hasBlindProfile.value;
        }
        this.model = dataToPost;
    }

    save() {
        this.formsubmited = true;
        
        if (this.form.invalid) {
            this.handleError(null);
            return;
        }
        this.loadingSpinner = true;
        this.prepareModel();
        let dataToPost = JSON.parse(JSON.stringify(this.model));
        dataToPost.organization = dataToPost.organization.uuid;
        if(dataToPost.contacts != null && dataToPost.contacts.length > 0)
        {
            let temp = [];
            dataToPost.contacts.forEach(function(currentValue) {
                temp.push(currentValue.uuid);
            })
            dataToPost.contacts = temp;
        }
        let apiToCall: any;
        if (this.isEdit) {
            apiToCall = this.sellsideService.updateSellside(dataToPost);
        }
        else {
            apiToCall = this.sellsideService.addSellside(dataToPost);
        }
        apiToCall.subscribe(
            data => {
                let sellside = data as any;
                let detail = 'Sell Side Created';
                if (this.isEdit) {
                    detail = 'Sell Side Updated';
                }
                this.dataService.setData('message', detail);
                this.router.navigate(['/sell-side/' + sellside.uuid]);
            },
            error => {
                this.loadingSpinner = false;
                let errors = error.error;
                if (errors.code && errors.code == 'VALIDATION_ERROR') {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: errors.subErrors[0].message, life: 3000 });
                 }
            }
        );

    }

    nextStep() {
        let hasError:boolean = false;
        if(this.activeIndex == 0) {
            this.validatePrimaryObjective(null);
            hasError = this.f.organization.invalid || this.f.strategicPartner.invalid
                || (this.f.equityPartnerType.invalid)
                || this.f.debtType.invalid || this.f.equityType.invalid || this.f.screenTitle.invalid 
                || this.f.externalHeadline.invalid;
        }
        else if(this.activeIndex == 1) {
            hasError = this.f.level1.invalid;
            this.prepareModel();
        }
        else if(this.activeIndex == 2) {
            
        }
        if(hasError)
        {
          this.formsubmited = true;
          this.messageService.add({severity: 'error', summary: 'Form Errors', detail: "Form has errors ", life: 3000});
        }
        else
        {
            this.formsubmited = false;
            this.activeIndex++;           
        }
    }

    preStep() {
        this.activeIndex--;
    }

    cancel() {
        this._location.back();
    }

    get role() {
        return this.accountService.userValue.role;
    }


    createAccount() {
        this.accountForm = true;
    }

    public onFormGroupChangeEvent(_event) {
        this.f.organization.setValue(_event);
    }

    validatePrimaryObjective(event: any) {
        let error = { 'message': 'Primary objective is required.' };
        if (this.f.strategicPartner.value || (this.f.equityPartnerType.value != null && this.f.equityPartnerType.value.length>0)
            || (this.f.equityType.value !=null && this.f.equityType.value.length > 0) 
            || (this.f.debtType.value !=null && this.f.debtType.value.length > 0))
        {
            error = null;
        }
        
        this.appUtil.setError(this.form, 'strategicPartner', error);
        this.appUtil.setError(this.form, 'equityPartnerType', error);
        this.appUtil.setError(this.form, 'equityType', error);
        this.appUtil.setError(this.form, 'debtType', error);
        this.formsubmited = true;
    }

    getHelpText(key) {
        let test = this.helpList.filter(function (element) {
            return element.key == key;
        });
        if (test[0] != undefined)
            return test[0]['text'];
    }
    
}
