<p-dialog [(visible)]="show" (onHide)="hide()" [breakpoints]="{'960px': '75vw', '640px': '30vw'}"
    [style]="{width: '28vw'}" [header]="title" [modal]="true" (onHide)="hide()" appendTo="body">
    <ng-template pTemplate="content">
        <form [formGroup]="form" #accountform>
            <div class="p-fluid grid">
                <div class="field col-12 " style="margin-top: 19px;">
                    <span class="p-float-label">
                        <input id="name" formControlName="name" type="text" maxlength="95" pInputText
                            [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.name.errors }">
                        <label for="name">Name <span class="mandate">*</span></label>
                    </span>
                    <span class="p-error" *ngIf="submitted && f.name.invalid && f.name.hasError('required')">Name is
                        required.</span>
                    <span class="p-error"
                        *ngIf="submitted && f.name.invalid && f.name.hasError('message')">{{f.name.errors.message}}</span>
                </div> &nbsp;
                <div class="field col-12 ">
                    <span class="p-float-label">
                        <input id="website" formControlName="website" type="text" maxlength="175" pInputText
                            [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.website.errors }">
                        <label for="website">Website </label>
                    </span>
                    <span class="p-error" *ngIf="submitted && f.website.hasError('pattern')">Please enter valid
                        URL(http://www.example.com)</span>
                    <span class="p-error"
                        *ngIf="submitted && f.website.invalid && f.website.hasError('message')">{{f.website.errors.message}}</span>
                </div> &nbsp;
                <div class="field col-12 ">
                    <span class="p-float-label">
                        <p-dropdown [options]="tbpEmployees" appendTo="body" styleClass="form-control"
                            formControlName="accountOwner" id="accountOwner" name="accountOwner" optionLabel="displayName"
                            optionValue="uuid" [autoDisplayFirst]="false" [filter]="true" [showClear]="true">
                        </p-dropdown>
                        <label for="accountOwner">Account Owner</label>
                    </span>
                </div>&nbsp;
                <div class="field col-12 ">
                    <span class="p-float-label">
                        <p-dropdown [options]="lookupList.ACCOUNT_SOURCE" appendTo="body" styleClass="form-control"
                            formControlName="leadSource"
                            [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.leadSource.errors }" maxlength="40"
                            #ddLeadSource id="leadSource" name="leadSource" optionLabel="value" optionValue="value"
                            [filter]="true" [autoDisplayFirst]="false" editable="true">
                        </p-dropdown>
                        <label for="leadSource"
                            [ngClass]="{'datafilled': ddLeadSource.value != null && ddLeadSource.value !=''}">Lead
                            Source</label>
                    </span>
                    <span class="p-error"
                        *ngIf="submitted && f.leadSource.invalid && f.leadSource.hasError('message')">{{f.leadSource.errors.message}}</span>
                </div>&nbsp;
                <div class="field col-12 ">
                    <span class="p-float-label">
                        <p-dropdown [options]="lookupList.REVENUE" appendTo="body" styleClass="form-control"
                            [filter]="true" maxlength="19"
                            [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.revenue.errors }"
                            formControlName="revenue" #ddRevenue id="revenue" name="revenue" optionLabel="value"
                            optionValue="value" [autoDisplayFirst]="false" editable="true">
                        </p-dropdown>
                        <label for="revenue"
                            [ngClass]="{'datafilled': ddRevenue.value != null && ddRevenue.value !=''}"> Revenue Range
                            ($m)</label>
                    </span>
                    <span class="p-error"
                        *ngIf="submitted && f.revenue.invalid && f.revenue.hasError('message')">{{f.revenue.errors.message}}</span>

                </div>&nbsp;
                <div class="field col-12 ">
                    <span class="p-float-label">
                        <p-dropdown [options]="lookupList.No_OF_EMPLOYEES" appendTo="body" styleClass="form-control"
                            maxlength="19" [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.numberOfEmployee.errors }"
                            formControlName="numberOfEmployee" #ddNumberOfEmployee id="numberOfEmployee"
                            name="numberOfEmployee" optionLabel="value" optionValue="value" [autoDisplayFirst]="false"
                            [filter]="true" editable="true">
                        </p-dropdown>
                        <label for="numberOfEmployee"
                            [ngClass]="{'datafilled': ddNumberOfEmployee.value != null && ddNumberOfEmployee.value !=''}">No.
                            of Employees</label>
                    </span>
                    <span class="p-error"
                        *ngIf="submitted && f.numberOfEmployee.invalid && f.numberOfEmployee.hasError('message')">{{f.numberOfEmployee.errors.message}}</span>

                </div>
                <div class="field col-12 ">
                    <span class="p-float-label">
                        <input id="linkedinPageId" formControlName="linkedinPageId" type="text" maxlength="175" pInputText>
                        <label for="linkedinPageId">Linked In Page</label>
                    </span>
                </div>&nbsp;
                <div class="field col-12 ">
                    <span class="p-float-label">
                        <input id="capitaliqId" formControlName="capitaliqId" type="text" maxlength="175" pInputText>
                        <label for="capitaliqId">CapIQ Id</label>
                    </span>
                </div>
            </div>
        </form>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hide()"></button>
        <button pButton pRipple label="Save" form="clform" icon="pi pi-check" class="p-button-text"
            (click)="save()"></button>
    </ng-template>
</p-dialog>