<p-dialog [(visible)]="show" (onHide)="hide()" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
    [style]="{width: '50vw'}" [header]="title" [modal]="true" (onHide)="hide()" appendTo="body">
    <ng-template pTemplate="content">
        <form [formGroup]="form" #noteForm>
            <div class="p-fluid grid mt-2 ">
                <div class="field col-6">
                    <span class="p-float-label">
                        <tbp-autocomplete [forceSelection]="true" [formControl]="f.contacts" [items]=""
                            [type]="'Contact'" field="displayName" [additionalData]="f.organization.value" (afterNewCreated)="newContactCreated.emit($event)">
                        </tbp-autocomplete>
                        <label for="user">Contact</label>
                    </span>
                </div>
                <div class="field col-6">
                    <span class="p-float-label">
                        <p-autoComplete [unique]="true" dataKey="uuid" [forceSelection]="true"
                            formControlName="engagement" [suggestions]="engagementResults"
                            (completeMethod)="searchEngagements($event)" field="name"> </p-autoComplete>
                        <label for="account">Engagement</label>
                    </span>
                </div>
            </div>
            <div class="p-fluid grid mt-1">
                <div class="field col-6">
                    <span class="p-float-label">
                        <p-autoComplete [unique]="true" dataKey="uuid" [forceSelection]="true"
                            formControlName="buysideScreen" [suggestions]="buySideResults"
                            (completeMethod)="searchBuySide($event)" field="screenTitle"> </p-autoComplete> <label
                            for="buysideScreen">BuySide Screen</label>
                    </span>
                </div>
                <div class="field col-6">
                    <span class="p-float-label">
                        <p-autoComplete appendTo="body" [unique]="true" dataKey="uuid" [forceSelection]="true"
                            formControlName="sellsideScreen" [suggestions]="sellSideResults"
                            (completeMethod)="searchSellSide($event)" field="screenTitle"> </p-autoComplete> <label
                            for="sellsideScreen">SellSide Screen </label>
                    </span>
                </div>
            </div>
            <div class="p-fluid grid mt-1">
                <div class="field col-6">
                    <span class="p-float-label">
                        <tbp-autocomplete [multiple]="false" [formControl]="f.organization" [items]="" [type]="'Account'" 
                       ></tbp-autocomplete>
                        <label for="account">Account</label>
                    </span>
                </div>
            </div>
            <div class="field">
                <label for="description">Notes</label>
                <editor #customers formControlName="notes" [init]="appUtil.getInitData"></editor>
                <small class="p-error" *ngIf="submitted && f.notes.invalid && f.notes.hasError('required')">Notes is
                    required.</small>
            </div>
            <div class="field">
                <label class="upload">Attachment</label>
                <p-fileUpload name="file" [url]="uploadURL" (onUpload)="onUpload($event)" [maxFileSize]="this.appUtil.maxFileSize"
                    [auto]="true" chooseLabel="Browse" [multiple]="false">
                    <ng-template pTemplate="content">
                        <ul *ngIf="uploadedFiles.length">
                            <li *ngFor="let file of uploadedFiles">
                                <a href="{{file.url}}" (click)="openDocument($event)">{{file.nameWithExtension}}</a>
                                <span title="Remove Attachment"><a href="#" (click)="deleteDocument($event)"><i
                                            class="pi pi-trash"
                                            style="font-size: .75rem;color:red;margin-left: 20px;font-weight: bold;font-size: bold;"></i></a></span>
                            </li>
                        </ul>
                    </ng-template>
                </p-fileUpload>
                <small class="p-error"
                    *ngIf="submitted && f.attachment.invalid && f.attachment.hasError('required')">Attachment is
                    required.</small>
            </div>

        </form>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hide()"></button>
        <button pButton pRipple label="Save"  [loading]="loading" form="clform" icon="pi pi-check" class="p-button-text"
            (click)="saveNote()"></button>
    </ng-template>
</p-dialog>
<p-toast></p-toast>