import { map } from 'rxjs/operators';
import { Classification } from './../_models/classification';
import { ClassificationService } from './classification.service';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';


@Injectable()
export class ClassificationResolver implements Resolve<any> {

    constructor(private classificationService: ClassificationService) { }

    resolve(): Observable<any> {
        return this.classificationService.getClassifications('all', null).pipe(
            map((result: any) => {
                let classifications = result.classifications as any[];
                let temp = {
                    CATEGORY_LEVEL1: [], CATEGORY_LEVEL2: [], CATEGORY_LEVEL3: [],
                    VERTICAL: [], ECOSYSTEM: []
                };
                for (let i = 0; i < classifications.length; i++) {
                    temp[classifications[i].type].push(classifications[i]);
                }
                return temp;
            })
        );
    }
}