<div class="grid table-demo">
    <div class="col-12">
        <div class="card">
            <p-toolbar styleClass="headerSticky">
                <div class="p-toolbar-group-left">
                    <div #srch>
                        <span class="p-input-icon-left p-input-icon-right">
                            <i class="pi pi-search"></i>
                            <input [(ngModel)]="term" pInputText type="text" placeholder="Search" class="table-search"
                                (keyup.enter)="search()" />
                            <i class="pi pi-bars"></i>
                        </span>
                    </div>
                </div>
                <div class="p-toolbar-group-right">
                    <button pButton pRipple label="Back" icon="pi pi-arrow-left" class="p-button-success mr-2" (click)="appUtil.return()"></button>
                    <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success mr-2"
                        (click)="new()"></button>
                </div>
            </p-toolbar>
            <p-table [autoLayout]="true" #dt [value]="engagements" [(selection)]="selectedEngagement" dataKey="uuid"
                [lazy]="true" (onLazyLoad)="search($event)"
                styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm" [rows]="15"
                [paginator]="true" [rowsPerPageOptions]="[15,25,50,75,100]" [loading]="loading"
                [alwaysShowPaginator]="false" [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [totalRecords]="totalRecords" sortField="name">
                <ng-template pTemplate="header">
                    <tr>

                        <th pSortableColumn="name">Engagement Name<p-sortIcon field="name"></p-sortIcon>
                        </th>
                        <th pSortableColumn="engagementType">Engagement Type<p-sortIcon field="engagementType">
                            </p-sortIcon>
                        </th>
                        <th pSortableColumn="startDate">Start Date<p-sortIcon field="startDate"></p-sortIcon>
                        </th>
                        <th pSortableColumn="endDate">End Date<p-sortIcon field="endDate"></p-sortIcon>
                        </th>
                        <th pSortableColumn="actualCloseDate">Actual Close Date<p-sortIcon field="actualCloseDate">
                            </p-sortIcon>
                        </th>
                        <th>Progress</th>
                        <th pSortableColumn="status">Status<p-sortIcon field="status"></p-sortIcon>
                        </th>
                        <th>Action</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-engagement>
                    <tr class="p-selectable-row">
                        <td class="wrap">
                            <span class="p-column-title">Engagement Name</span>
                            <a [routerLink]="['/engagement/', engagement.uuid]" [innerHTML]="engagement.name | highlight :term"></a>
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Engagement Type</span>
                            {{engagement.engagementType}}
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Start Date</span>
                            {{engagement.startDate | date:'MM-dd-yyyy'}}
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">End Date</span>
                            {{engagement.endDate | date:'MM-dd-yyyy'}}
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Actual Close Date</span>
                            {{engagement.actualCloseDate | date:'MM-dd-yyyy'}}
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Progress</span>
                            {{engagement.progress}}
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Status</span>
                            {{engagement.status}}
                        </td>
                        <td>
                            <span class="p-column-title">Action</span>
                            <button type="button" pButton icon="pi pi-list"
                                (click)="setCurrentItem(engagement); menu.toggle($event)"></button>
                        </td>

                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="8">No Engagement found.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
<p-menu #menu [popup]="true" [model]="engagementItems"></p-menu>