import { Component, OnInit } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { environment } from './../environments/environment';
import { AccountService } from './_services';
import { AppUtil } from './_helpers';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {

    model: any[];
    constructor(public appMain: AppMainComponent, public accountService: AccountService, public appUtil:AppUtil) { }

    ngOnInit() {
        this.model = [
            ...((this.accountService.userValue.role == 1) ? [] :
                [
                    { label: 'My Account', icon: 'pi pi-id-card', routerLink: ['/profile/' + this.accountService.userValue.uuid] },
                    { separator: true },
                ]
            ),
            /* Example of a menu with submenu
            {
              items: [
                  {
                      label: 'Submenu 1.1', icon: 'pi pi-fw pi-align-left',
                      items: [
                          {label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-align-left'},
                          {label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-align-left'},
                          {label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-align-left'},
                      ]
                  },
              ]
          },
          {separator: true}, 
          */

            { label: 'Dashboard', icon: 'pi pi-home', routerLink: ['/'] },
            ...((this.accountService.userValue.role == 0) ? [] :
                [
                    // { separator: true },
                    // {
                    //     label: 'Contacts', icon: 'pi pi-users', routerLink: ['/contact'],
                    //     badges: [
                    //         { value: this.appMain.PENDING_VERIFICATION, severity: 'warning' }
                    //     ]
                    // },
                    { separator: true },
                    {
                        label: 'Contacts', icon: 'pi pi-users', routerLink: ['/contacts'],
                        badges: [
                        { value: this.appMain.PENDING_VERIFICATION, severity: 'warning' }
                        ] 
                    },
                    { separator: true },
                    { label: 'Accounts', icon: 'pi pi-globe', routerLink: ['/organizations'], },
                ]
            ),
            { separator: true },
            { label: 'Spotlights', icon: 'pi pi-star', routerLink: ['/spotlight'], },
            { separator: true },
            { label: 'Deal Tracker', icon: 'pi pi-star', routerLink: ['/deals'], },
            { separator: true },
            { label: 'Buy Side Screens', icon: 'pi pi-credit-card', routerLink: ['/buysides'], },
            { separator: true },
            { label: 'Sell Side Screens', icon: 'pi pi-id-card', routerLink: ['/sellsides'], },
            { separator: true },
            { label: 'Engagements', icon: 'pi pi-id-card', routerLink: ['/engagement'], },
            ...((this.accountService.userValue.role == 0) ? [] :
                [
                    { separator: true },
                    { label: 'Email Campaigns', icon: 'pi pi-envelope', routerLink: ['/email-campaign'], },
                    { separator: true },
                    { label: 'Unrecognized Contacts', icon: 'pi pi-question-circle', routerLink: ['/contact/unrecognized-email-logs'], },
                    { separator: true },
                    { label: 'TBP Users', icon: 'pi pi-users', routerLink: ['/user'], },
                    { separator: true },
                    { label: 'Classifications', icon: 'pi pi-sitemap', routerLink: ['/classification'], },
                    { separator: true },
                    { label: 'Global Lookups', icon: 'pi pi-globe', routerLink: ['/configuration/globalLookup'], },
                    { separator: true },
                    { label: 'Media', icon: 'pi pi-fw pi-image', routerLink: ['/media'], },
                    { separator: true },
                    { label: 'Help Text', icon: 'pi pi-info-circle', routerLink: ['/help'], },
                    { separator: true },
                    { label: 'User Activity', icon: 'pi pi-chart-pie', routerLink: ['/user-activity'], },
                ]

            ),
        ];
    }
}
