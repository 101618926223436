import { DataService } from './../_services/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from './../app.breadcrumb.service';
import { EmailCampaignService } from './email-campaign.service';
import { HttpClient } from '@angular/common/http';
import { ConfirmationService, LazyLoadEvent, MenuItem, MessageService } from 'primeng/api';
import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { saveAs } from 'file-saver';
import { AppUtil } from '../_helpers';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { EmailCampaign } from '../_models/emailcampaign';
import { BuysideService } from '../buyside/buyside.service';
import { SellsideService } from '../sellside/sellside.service';

@Component({
    selector: 'app-recipient-list',
    templateUrl: './recipient-list.component.html',
    styleUrls: ['./recipient-list.component.scss'],
    providers: [ConfirmationService]
})
export class RecipientListComponent implements OnInit {

    uuid: string;
    @Input() emailCampaign: any;
    recipients: [];
    recipientItems: MenuItem[];
    uploadRecipient: boolean = false;
    uploadItems: MenuItem[];
    loading: boolean = false;
    showCampaigns: boolean = false;
    showOptions: boolean = false;
    copyRecipientForm: UntypedFormGroup;
    items = [];
    showCheckbox: boolean = false;
    selectedRecipients: any[];
    previousCampaignUuid: string;
    submitted: boolean = false;
    showSync: boolean = true;
    prospects: [];
    totalRecords: number = 0;

    constructor(
        private messageService: MessageService,
        private http: HttpClient,
        private dataService: DataService,
        private emailCampaignService: EmailCampaignService,
        private breadcrumbService: BreadcrumbService,
        private router: Router,
        private appUtil: AppUtil,
        private confirmationService: ConfirmationService,
        private formBuilder: UntypedFormBuilder,
        private buysideService: BuysideService,
        private sellsideService: SellsideService,
        private route: ActivatedRoute) {
        
        this.uploadItems = [{ label: 'Template', command: () => { this.downloadTemplate(); } }];
        this.loadCopyRecipientForm();
    }

    ngOnInit(): void {
        this.route.url.subscribe((url) => {
            if((url.length == 4 && url[2].path == "sync-screen")) {
                this.emailCampaignService.getEmailCampaign(url[1].path).subscribe(
                    data => {
                        this.emailCampaign = data as any;
                        if(url[3].path == 'edit') {
                            this.evaluateProspectsAndRecipientsEquality(url);
                            if(!this.showSync)
                                this.syncWithScreen();
                        } else if(url[3].path == 'add') {
                            this.evaluateProspectsAndRecipientsEquality(url);
                            if(!this.showSync)
                                this.syncWithScreen();
                        }
                    }
                )
            }
            else if(url.length==2 || url[2].path=="recipients") {
                this.evaluateProspectsAndRecipientsEquality(url);
            }
            if(url.length == 6 && url[5].path == "checkbox") {
                this.uuid = this.route.snapshot.params.uuid;
                this.previousCampaignUuid = this.route.snapshot.params.previousCampaignUuid;
                this.showCheckbox = this.route.snapshot.params.checkbox;
                this.breadcrumbService.setItems([
                    { label: ' Recipient Listing' }
                ]);
            }
            if(url.length == 5 && url[4].path == "show") {
                this.emailCampaignService.getEmailCampaign(url[3].path).subscribe(
                    data => {
                        let parentCampaign = data as any;
                        if(parentCampaign.hasRecipients) {
                            this.showOptions = true;
                            this.f.campaign.setValue(data as any)  
                            this.f.copyRecipients.setValue('Copy All');
                        } else {
                            this.showOptions = false;
                        }
                    }
                )
            }
        })  
    }

    ngOnChanges(changes: SimpleChanges) {

        if(changes.emailCampaign && changes.emailCampaign.currentValue && changes.emailCampaign.currentValue.uuid) {

            let selectedData = this.dataService.getDataByKey('contacts-email-campaign-' + this.emailCampaign.uuid);
            let recipients = [];
            if(selectedData && Array.isArray(selectedData))
            {
                recipients = selectedData.map(a => a.uuid);
                this.dataService.deleteData('contacts-email-campaign-' + this.emailCampaign.uuid);
            }
            if(recipients.length > 0)
            {
                this.emailCampaignService.addRecipients(this.emailCampaign.uuid,recipients).subscribe(
                    data => {
                        this.getRecipientList()
                        this.messageService.add({ severity: 'success', summary: 'Save Successful',detail: (recipients.length>1?"Recipients":"Recipient")+" Added Successfully",life: 3000 });
                    },
                    error =>{ 
                        this.messageService.add({ severity: 'error', summary: 'Form Errors', detail: "Recipients Not Added", life: 3000 })
                    }
                );
            }
        }
    }

    evaluateProspectsAndRecipientsEquality(url: any) {

        this.emailCampaignService.getEmailCampaign(url[1].path).subscribe(
            data => {
                if(this.emailCampaign.type === 'BuySide Screen Outreach') {
                    this.buysideService.getContactsAsProspects(this.emailCampaign.buySide.uuid).subscribe(
                        data => {
                            this.checkForEquality(data as any, url)
                        }
                    )
                } 
                else if(this.emailCampaign.type === 'SellSide Screen Outreach') {
                    this.sellsideService.getContactsAsProspects(this.emailCampaign.sellSide.uuid).subscribe(
                        data => {
                            this.checkForEquality(data as any, url)
                        }
                    )
                } else {
                    this.showSync = false;
                }
            }
        )
    }

    checkForEquality(prospects: any, url: any) {

        this.emailCampaignService.getRecipients(this.route.snapshot.params.uuid).subscribe(data => {
            this.recipients = data as any;
            let contactLength = 0
            let count = 0
            if(prospects.length > 0) {
                let recipientUuids = []
                this.recipients.forEach((data) =>{
                    let recipient = data as any;
                recipientUuids.push(recipient.uuid);
                });
                prospects.forEach((data) =>{
                    contactLength++;
                    let contact = data as any;
                    if(recipientUuids.includes(contact.uuid)){
                        count++;
                    }
                });
                if(contactLength == count) {
                    this.showSync = false;
                } else if(url.length==4) {
                    this.syncWithScreen();
                }
            } else {
                this.showSync=false;
            }
        })
    }

    loadCopyRecipientForm() {

        this.copyRecipientForm = this.formBuilder.group({
            campaign: EmailCampaign,
            copyRecipients: [],
        })
    }

    get f(){
        return this.copyRecipientForm.controls;
    }

    addRecipient() {
        let advanceQuery = {"condition": "or", "rules": []};
        this.router.navigate(['/email-campaign/'+ this.emailCampaign.uuid +'/' + '/contacts/add'],
                             { queryParams: 
                                { 'advsearch': true, 'term': this.appUtil.convertForQueryParam(advanceQuery), 'first': 0, 
                                        'pagesize': this.appUtil.pageSize, 'sortfield': "relevance", 'sortorder': -1 }}
                            );
    }

    downloadTemplate() {
        window.open('/app/assets/download-Template/contact-template.xlsx', '_blank');
    }

    getRecipientList(e: LazyLoadEvent = { first: 0, rows: 25, sortField: 'lastModifiedDate', sortOrder: 1 }) {
        
        var searchRequest = {};
        searchRequest['page'] = (e.first / e.rows);
        searchRequest['pageSize'] = e.rows;
        var sortBy = []
        var sortDir = e.sortOrder == 1 ? 'ASC' : 'DESC';
        if (e.sortField && e.sortField != '') {
            sortBy[sortBy.length] = { "property": e.sortField, "direction": sortDir };
        }
        searchRequest['sortDetails'] = sortBy;
        this.emailCampaignService.getRecipientsWithPaging(this.route.snapshot.params.uuid, searchRequest).subscribe(
            data => {
            let response = data as any;
            this.recipients = response.records;
            this.totalRecords = response.totalRecords;
        })
    }

    exportExcel() {
        this.emailCampaignService.downloadRecipients(this.emailCampaign.uuid).subscribe(blob => saveAs(blob, "Recipients.xlsx"));
    }

    uploadRecipients() {
        this.uploadRecipient = true;
    }

    setCurrentItem(recipient) 
    {
        this.recipientItems = [
            {
                label: 'Schedule',
                icon: 'pi pi-plus',
                command: () => {

                }
            },
            {
                label: 'Un-Schedule',
                icon: 'pi pi-times',
                command: () => {
                }
            },
            {
                label: 'Delete',
                icon: 'pi pi-trash',
                command: (event) => {
                    this.delete(recipient, false);
                }
            }
        ];
        if((this.emailCampaign.type == "BuySide Screen Outreach" || this.emailCampaign.type == "SellSide Screen Outreach") && !recipient.prospect) {
            this.recipientItems.push({
                label: 'Add as prospect',
                icon: 'pi pi-plus',
                command: (event) => {
                    this.addRecipientAsProspect(recipient);
                }
            });
        }
    }

    

    delete(recipient: any, deleteMultiple: boolean) {

        let recipientUuids = []
        if(!deleteMultiple) {
            recipientUuids.push(recipient.uuid)
        } else {
            this.selectedRecipients.forEach(element => {
                recipientUuids.push(element.uuid)
            });
        }
        this.confirmationService.confirm({
            message: 'Are you sure want to delete '+(deleteMultiple ? 'Recipients?':'Recipient?'),
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.emailCampaignService.deleteRecipients(this.emailCampaign.uuid, recipientUuids).subscribe(
                    data => {
                        this.getRecipientList()
                        if(!this.showSync)
                            window.location.reload();
                        this.messageService.add({ severity: 'success', summary: 'Successful', detail: (deleteMultiple ? 'Recipients':'Recipient')+' Deleted.', life: 3000 });
                        this.selectedRecipients = []
                    },
                    error => {
                        let errors = error.error;
                        if (errors.code && errors.code == 'VALIDATION_ERROR') {
                            this.messageService.add({ severity: 'error', summary: 'Error', detail: errors.subErrors[0].message, life: 3000 });
                        }
                    }
                )
            }
        });
    }

    _showOptions() {
        this.showOptions = true;
    }

    selectCampaign() {
        this.showCampaigns = true;
        this.copyRecipientForm.reset();
    }

    showRecipients() {

        if(this.f.copyRecipients.value === 'Copy All') {
            this.copyAllRecipients();
        } else if(this.f.copyRecipients.value === 'Select And Copy') {
            this.showCampaigns = false;
            this.router.navigate(["/email-campaign/"+this.emailCampaign.uuid+"/copy/"+this.f.campaign.value.uuid+"/recipients/checkbox"])
        } else {
            this.messageService.add({ severity: 'error', summary: 'Form Errors', detail: "Please Select an option.", life: 3000 })
        }
    }

    hideDialog() {
        this.showOptions = false;
        this.showCampaigns = false;
        this.copyRecipientForm.reset();
        this.router.navigate(["/email-campaign/"+this.emailCampaign.uuid+"/recipients"])
    }

    addSelectedRecipients() {
        let selectedUuids = []
        this.selectedRecipients.forEach(function(currentValue: any) {
            selectedUuids.push(currentValue.uuid);
        })
        this.confirmationService.confirm({
            message: 'Are you sure want to add '+(this.selectedRecipients.length>1?"Recipients?":"Recipient?"),
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.emailCampaignService.addRecipients(this.previousCampaignUuid, selectedUuids).subscribe(
                    data => {
                        this.messageService.add({ severity: 'success', summary: 'Save Successful', 
                                    detail: (this.selectedRecipients.length>1?"Recipients":"Recipient")+" Added Successfully",life: 3000 });
                        this.router.navigate(["/email-campaign/"+this.previousCampaignUuid+"/recipients"])
                    },
                    error =>{ 
                        this.messageService.add({ severity: 'error', summary: 'Form Errors', detail: "Recipients Not Added", life: 3000 })
                    }
                );
            }
        });
    }

    copyAllRecipients() {
        this.confirmationService.confirm({
            message: 'Are you sure want to copy all Recipients?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.emailCampaignService.addAllRecipients(this.emailCampaign.uuid, this.f.campaign.value.uuid).subscribe(
                    data => {
                        this.messageService.add({ severity: 'success', summary: 'Save Successful', 
                                    detail: "Recipients Added Successfully",life: 3000 });
                        
                        this.showOptions = false;
                    this.getRecipientList();
                    },
                    error =>{ 
                        this.messageService.add({ severity: 'error', summary: 'Form Errors', detail: "Recipients Not Added", life: 3000 })
                    }
                );
            }
        });
    }

    syncWithScreen() {

        this.confirmationService.confirm({
            message: 'Do you want to add prospects as recipients?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                let screenUuid;
                if(this.emailCampaign.buySide != null) {
                    screenUuid = this.emailCampaign.buySide.uuid
                } else {
                    screenUuid = this.emailCampaign.sellSide.uuid
                }
                this.emailCampaignService.syncWithScreen(this.emailCampaign.uuid, screenUuid).subscribe(
                    data => {
                        this.messageService.add({ severity: 'success', summary: 'Save Successful', 
                                    detail: "Recipients Added Successfully",life: 3000 });
                        this.getRecipientList();
                        this.router.navigate(["/email-campaign/"+this.emailCampaign.uuid+"/recipients"])
                        this.showSync = false;
                    },
                    error =>{ 
                        this.messageService.add({ severity: 'error', summary: 'Form Errors', detail: "Recipients Not Added", life: 3000 })
                    }
                );
            },
            reject: () => {
                this.router.navigate(["/email-campaign/"+this.emailCampaign.uuid+"/recipients"])
            }
        });
    }

    addRecipientAsProspect(recipient: any) {

        let recipientUuids = []
        recipientUuids.push(recipient.uuid)
        this.confirmationService.confirm({
            message: 'Are you sure want to add this recipient as prospect in "'+
             (this.emailCampaign.type==='BuySide Screen Outreach' ? this.emailCampaign.buySide.screenTitle : this.emailCampaign.sellSide.screenTitle)+'" screen?',
            header: 'Confirm',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.loading = true;
                this.emailCampaignService.addRecipientsAsProspects(this.emailCampaign.uuid, recipientUuids).subscribe(
                    data => {
                        this.getRecipientList()
                        this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Recipient Added as Prospect successfully', life: 3000 });
                        this.loading = false;
                        this.router.navigate(["/email-campaign/"+this.emailCampaign.uuid+"/recipients"])
                    },
                    error => {
                        let errors = error.error;
                        if (errors.code && errors.code == 'VALIDATION_ERROR') {
                            this.messageService.add({ severity: 'error', summary: 'Error', detail: errors.subErrors[0].message, life: 3000 });
                        }
                    }
                )
            }
        });
    }

}
