import { EntityClassification } from "./entity-classification";
import { Organization } from ".";
import { User } from "./user";
import { Blindprofile } from './blind-profile';
export class SellSide {
    constructor() {
        this.classification = new EntityClassification;
    }
    uuid?: string
    contacts?: User[];
    primaryObjective?: string;
    strategicPartner?: boolean;
    privateEquityPartner?: boolean;
    equity?: boolean;
    debt?: boolean;
    debtType?: string;
    equityType?: string;
    screenTitle?: string;
    externalHeadline?: string;
    timeline?: string;
    managementPreference?: string;
    turnaround?: boolean;
    transactionObjective?: string;
    companyDescription?: string;
    organization?: Organization[];
    classification: EntityClassification;
    user: User;
    hasBlindProfile: boolean;
    targetSize?: string;
    targetRevenue?: string;
    equityPartnerType?: string;
    targetEbitda?: string;
    controlEquityPartner?:boolean;
    hundredEquityPartner?: boolean;
    minorityEquityPartner?:boolean;
    createByUser?: User;
    createdDate?: User;
    modifiedByUser?: User;
    lastModifiedDate?: User;
}