import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EngagementAddComponent } from './engagement.add.component';
import { EngagementListComponent } from './engagement.list.component';
import { EngagementViewComponent } from './engagement.view.component';
import { ConfigurationResolver } from '../configuration/configuration.resolver';
import { EngagementBlindprofileComponent } from './engagement.blindprofile.component';
import { UserResolver } from '../users/user.resolver';
import { HelpResolver } from '../help/help.resolver';

const routes: Routes = [
    { path: '', component: EngagementListComponent },
    {
        path: 'new', component: EngagementAddComponent,
        resolve: {
            lookupList: ConfigurationResolver,
            tbpEmployees: UserResolver,
            helpList: HelpResolver,
        }
    },
    { path: ':uuid', component: EngagementViewComponent },
    {
        path: 'edit/:uuid', component: EngagementAddComponent,
        resolve: {
            lookupList: ConfigurationResolver,
            tbpEmployees: UserResolver,
            helpList: HelpResolver,
        }
    },
    { path: ':uuid/blind-profile/new', component: EngagementBlindprofileComponent },
    { path: ':uuid/blind-profile/edit/:uuid', component: EngagementBlindprofileComponent }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [ConfigurationResolver, UserResolver, HelpResolver]
})
export class EngagementRoutingModule { }
