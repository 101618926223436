<div class="card">
    <form [formGroup]="form" #cform>
        <div class="formgrid grid headerSticky">
            <div class="field col-12 md:col-12 text-right" *ngIf="loadingSpinner == false">
                <button pButton label="Save" icon="pi pi-check" (click)="save()" iconPos="left" class="mr-2"></button>
                <button pButton pRipple label="Merge" icon="pi pi-link" class="p-button-info  mr-2"
                    (click)="appUtil.routeToMergeURL('organization',f.uuid.value)" *ngIf="isEdit"></button>
                <p-button label="Cancel" icon="pi pi-times" (click)="cancel()" iconPos="left"
                    styleClass="p-button-secondary"></p-button>
            </div>
            <div class="field col-12 md:col-12 text-right" *ngIf="loadingSpinner">
                <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
                    fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
            </div>
        </div>
        <h5 class="custom-class">Account Information</h5>
        <div class="p-fluid grid">
            <div class="field col-12 md:col-4">
                <span class="p-float-label p-input-icon-right">
                    <input id="name" pTooltip="{{getHelpText('Name')}}" tooltipPosition="top" formControlName="name"
                        type="text" maxlength="95" pInputText
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.name.errors }">
                    <i class="pi pi-google link" (click)="appUtil.externalLink(f.name.value,'google')" ></i>
                    <label for="name">Name <span class="mandate">*</span></label>
                </span>
                <span class="p-error" *ngIf="submitted && f.name.invalid && f.name.hasError('required')">Name is
                    required.</span>
                <span class="p-error"
                    *ngIf="submitted && f.name.invalid && f.name.hasError('message')">{{f.name.errors.message}}</span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label p-input-icon-right">
                    <i class="pi pi-link link" (click)="appUtil.externalLink(f.website.value,'url')" *ngIf="f.website.value != null && f.website.value.trim()!=''"></i>
                    <input id="website" pTooltip="{{getHelpText('Website')}}" tooltipPosition="top"
                        formControlName="website" type="text" maxlength="175" pInputText
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.website.errors }">
                    <label for="website">Website </label>
                </span>
                <span class="p-error" *ngIf="submitted && f.website.hasError('pattern')">Please enter valid
                    URL(http://www.example.com)</span>
                <span class="p-error"
                    *ngIf="submitted && f.website.invalid && f.website.hasError('message')">{{f.website.errors.message}}</span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <p-dropdown [options]="tbpEmployees" pTooltip="{{getHelpText('Account Owner')}}"
                        tooltipPosition="top" styleClass="form-control" formControlName="accountOwner" id="accountOwner"
                        name="accountOwner" optionLabel="displayName" optionValue="uuid" [autoDisplayFirst]="false"
                        [filter]="true" [showClear]="true">
                    </p-dropdown>
                    <label for="accountOwner">Account Owner</label>
                </span>
            </div>

            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <look-autocomplete [multiple]="false" [formControl]="f.leadSource"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.leadSource.errors }" [type]="'ACCOUNT_SOURCE'"
                        tooltipPosition="top" pTooltip="{{getHelpText('Lead Source')}}" [items]="lookupList.ACCOUNT_SOURCE">
                    </look-autocomplete>
                    <label for="leadSource">Lead Source</label>
                </span>
                <span class="p-error"
                    *ngIf="submitted && f.leadSource.invalid && f.leadSource.hasError('message')">{{f.leadSource.errors.message}}</span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <look-autocomplete [multiple]="false" [formControl]="f.revenue"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.revenue.errors }" [type]="'REVENUE'"
                        tooltipPosition="top" pTooltip="{{getHelpText('Revenue')}}" [items]="lookupList.REVENUE"></look-autocomplete>
                    <label for="revenue"> Revenue Range ($m)</label>
                </span>
                <span class="p-error"
                    *ngIf="submitted && f.revenue.invalid && f.revenue.hasError('message')">{{f.revenue.errors.message}}</span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <look-autocomplete [multiple]="false" [formControl]="f.numberOfEmployee"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.numberOfEmployee.errors }"
                        [type]="'No_OF_EMPLOYEES'" tooltipPosition="top" pTooltip="{{getHelpText('No of Employee')}}"
                        [items]="lookupList.No_OF_EMPLOYEES"></look-autocomplete>
                    <label for="numberOfEmployee">No. of Employees</label>
                </span>
                <span class="p-error"
                    *ngIf="submitted && f.numberOfEmployee.invalid && f.numberOfEmployee.hasError('message')">{{f.numberOfEmployee.errors.message}}</span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label p-input-icon-right">
                    <i class="pi pi-link link" (click)="appUtil.externalLink(f.capitaliqId.value,'capiq')" *ngIf="f.capitaliqId.value != null && f.capitaliqId.value.trim()!=''"></i>
                    <i class="pi pi-google link" (click)="appUtil.externalLink(f.name.value,'google', 'capitaliq.com:')" *ngIf="f.capitaliqId.value == null ||  f.capitaliqId.value.trim()==''"></i>
                    <input id="capitaliqId" formControlName="capitaliqId" type="text" maxlength="100" pInputText
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.capitaliqId.errors }" pTooltip="{{'CapIQ Id'}}" tooltipPosition="top">
                    <label for="capitaliqId">CapIQ Id </label>
                </span>
                <span class="p-error" *ngIf="f.capitaliqId.invalid && f.capitaliqId.hasError('capIQValidator')">Sample
                    URL: {{appUtil.externalLink('capiqId','capiq',null,false)}}.</span>
                <span class="p-error"
                    *ngIf="submitted && f.capitaliqId.invalid && f.capitaliqId.hasError('message')">{{f.capitaliqId.errors.message}}</span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label p-input-icon-right">
                    <i class="pi pi-link link" (click)="appUtil.externalLink(f.pitchbookId.value,'pitchbook')" *ngIf="f.pitchbookId.value != null && f.pitchbookId.value.trim()!=''"></i>
                    <i class="pi pi-google link" (click)="appUtil.externalLink(f.name.value,'google','pitchbook.com:')" *ngIf="f.pitchbookId.value == null || f.pitchbookId.value.trim()==''"></i>
                    <input id="pitchbookId" formControlName="pitchbookId" type="text" maxlength="100" pInputText
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.pitchbookId.errors }" pTooltip="{{'Pitchbook Id'}}" tooltipPosition="top">
                    <label for="pitchbookId">Pitchbook Id </label>
                </span>
                <span class="p-error"
                    *ngIf="f.pitchbookId.invalid && f.pitchbookId.hasError('pitchbookValidator')">Sample URL:
                    {{appUtil.externalLink('pitchbookId','pitchbook',null, false)}}.</span>
                <span class="p-error"
                    *ngIf="submitted && f.pitchbookId.invalid && f.pitchbookId.hasError('message')">{{f.pitchbookId.errors.message}}</span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label p-input-icon-right">
                    <i class="pi pi-link link" (click)="appUtil.externalLink(f.linkedinPageId.value,'linkedin')" *ngIf="f.linkedinPageId.value != null && f.linkedinPageId.value.trim()!=''"></i>
                    <i class="pi pi-google link" (click)="appUtil.externalLink(f.name.value,'google', 'linkedin.com:')" *ngIf="f.linkedinPageId.value == null ||  f.linkedinPageId.value.trim()==''"></i> 
                    <input id="linkedinPageId" formControlName="linkedinPageId" type="text" maxlength="100" pInputText
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.linkedinPageId.errors }" pTooltip="{{'Linked In Page'}}" tooltipPosition="top">
                     <label for="linkedinPageId">Linked In Page </label>
                </span>
                <span class="p-error"
                    *ngIf="f.linkedinPageId.invalid && f.linkedinPageId.hasError('linkedInValidator')">Sample URL:
                    {{appUtil.externalLink('linkedinId', 'linkedin',null, false)}}.</span>
                <span class="p-error"
                    *ngIf="submitted && f.linkedinPageId.invalid && f.linkedinPageId.hasError('message')">{{f.linkedinPageId.errors.message}}</span>
            </div>

            <div class="field col-12 md:col-12">
                <editor #editor formControlName="description" [init]="appUtil.getInitData" pTooltip="{{('Description')}}" tooltipPosition="top"></editor>
            </div>

        </div>
        <h5 class="custom-class">Account Type</h5>
        <div class="p-fluid grid">
            <div class="field col-12 md:col-4">
                <p-radioButton inputId="oc" tooltipPosition="top" pTooltip="{{getHelpText('Operating Company')}}"
                    styleClass="form-control p-mb-2" formControlName="companyType" name="companyType" value="OPERATING_COMPANY"
                    label="Operating Company" labelStyleClass="pr-2"></p-radioButton>
                <p-radioButton inputId="ic" tooltipPosition="top" pTooltip="{{getHelpText('Investor')}}"
                    styleClass="form-control p-mb-2" formControlName="companyType" name="companyType" value="INVESTOR"
                    label="Investor" labelStyleClass="pr-2" (onClick)="processCompanyType($event)"></p-radioButton>
                <p-radioButton inputId="lc" tooltipPosition="top" pTooltip="{{getHelpText('Lender')}}"
                    styleClass="form-control p-mb-2" formControlName="companyType" name="companyType" value="LENDER"
                    label="Lender" labelStyleClass="pr-2" (onClick)="processCompanyType($event)"></p-radioButton>
                <p-radioButton inputId="ot" tooltipPosition="top" pTooltip="{{getHelpText('Other')}}"
                    styleClass="form-control p-mb-2" formControlName="companyType" name="companyType" value="OTHER"
                    label="Other" (onClick)="processCompanyType($event)"></p-radioButton>
            </div>
            <div class="field col-12 md:col-4" *ngIf="f.companyType.value== 'INVESTOR'">
                <span class="p-float-label">
                    <look-autocomplete [multiple]="true" [formControl]="f.subTypes"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.subTypes.errors }"
                        [type]="'ORG_TYPE_INVESTOR'" [items]="lookupList.ORG_TYPE_INVESTOR">
                    </look-autocomplete>
                    <label for="subTypes">Company Type</label>
                </span>
            </div>
            <div class="field col-12 md:col-4" *ngIf="f.companyType.value== 'LENDER'">
                <span class="p-float-label">
                    <look-autocomplete [multiple]="true" [formControl]="f.subTypes"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.subTypes.errors }"
                        [type]="'ORG_TYPE_LENDER'" [items]="lookupList.ORG_TYPE_LENDER"></look-autocomplete>
                    <label for="subTypes">Company Type</label>
                </span>
            </div>
            <div class="field col-12 md:col-4" *ngIf="f.companyType.value== 'OTHER'">
                <span class="p-float-label">
                    <look-autocomplete [multiple]="true" [formControl]="f.subTypes"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.subTypes.errors }"
                        [type]="'ORG_TYPE_OTHER'" [items]="lookupList.ORG_TYPE_OTHER"></look-autocomplete>
                    <label for="subTypes">Company Type</label>
                </span>
            </div>
            <div class="field col-12 md:col-4" *ngIf="f.companyType.value== 'OPERATING_COMPANY'">
                <h5 class="tbp-float-label">Company Type</h5>
                <div class="p-fluid grid">
                    <div class="col-12 md:col-8 p-pt-1">
                        <p-radioButton styleClass="form-control p-mb-2" tooltipPosition="top"
                            pTooltip="{{getHelpText('Large Company')}}" formControlName="customSubtypeValue"
                            name="customSubtypeValue" value="true" label="Large Company" labelStyleClass="pr-2">
                        </p-radioButton>
                        <p-radioButton styleClass="form-control p-mb-2" formControlName="customSubtypeValue"
                            name="customSubtypeValue" tooltipPosition="top" pTooltip="{{getHelpText('Prospect')}}"
                            value="false" label="Prospect" labelStyleClass="pr-2"></p-radioButton>
                    </div>  
                </div>
            </div>
            <div class="field col-12 md:col-4">
                <p-checkbox styleClass="form-control" formControlName="isPubliclyTraded"
                    name="isPubliclyTraded" [value]="true" tooltipPosition="top"
                    pTooltip="{{getHelpText('Is Publicly Traded?')}}" label="Is Publicly Traded?" [disabled]="f.companyType.value!= 'OPERATING_COMPANY'"
                    labelStyleClass="pr-2"></p-checkbox>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <tbp-autocomplete [multiple]="false" [formControl]="f.parentCompany" [items]=""
                        [type]="'Account'" tooltipPosition="top" pTooltip="{{getHelpText('Parent Company')}}"
                        [exclude]="excludedParents" (onChange)="computeExcludedAccounts()">
                    </tbp-autocomplete>
                    <label for="parentCompany">Parent Company</label>
                </span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <tbp-autocomplete [formControl]="f.corporateInvestor" [items]="" [type]="'Account'"
                        tooltipPosition="top" pTooltip="{{getHelpText('Investors')}}" [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.corporateInvestor.errors }"
                        [exclude]="excludedCorporateInvestors" (onChange)="computeExcludedAccounts()">
                    </tbp-autocomplete>
                    <label for="account">Corporate Investors</label>
                    <span class="p-error"
                    *ngIf="submitted && f.corporateInvestor.invalid && f.corporateInvestor.hasError('message')">{{f.corporateInvestor.errors.message}}</span>
                </span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <tbp-autocomplete [formControl]="f.individualInvestor" [items]="" [type]="'Contact'" field="displayName"
                        tooltipPosition="top" pTooltip="{{getHelpText('Individual Investors')}}">
                    </tbp-autocomplete>
                    <label for="contact">Individual Investors</label>
                </span>
            </div>
        </div>


        <h5 class="custom-class">Classification</h5>
        <div class="p-fluid  grid">
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <tbp-autocomplete [formControl]="f.level1" [type]="'CATEGORY_LEVEL1'" tooltipPosition="top"
                        pTooltip="{{getHelpText('Level1')}}" [items]="classificationList.CATEGORY_LEVEL1">
                    </tbp-autocomplete>

                    <label for="level1">Level1 </label>
                </span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <tbp-autocomplete [formControl]="f.level2" [type]="'CATEGORY_LEVEL2'" tooltipPosition="top"
                        pTooltip="{{getHelpText('Level2')}}" [items]="classificationList.CATEGORY_LEVEL2">
                    </tbp-autocomplete>
                    <label for="level2">Level2 </label>
                </span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <tbp-autocomplete [formControl]="f.level3" [type]="'CATEGORY_LEVEL3'" tooltipPosition="top"
                        pTooltip="{{getHelpText('Level3')}}" [items]="classificationList.CATEGORY_LEVEL3">
                    </tbp-autocomplete>
                    <label for="level3">Level3 </label>
                </span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <tbp-autocomplete [formControl]="f.vertical" [type]="'VERTICAL'" tooltipPosition="top"
                        pTooltip="{{getHelpText('Vertical/Industry')}}" [items]="classificationList.VERTICAL">
                    </tbp-autocomplete>
                    <label for="vertical">Vertical/Industry </label>
                </span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <tbp-autocomplete [formControl]="f.ecosystem" [type]="'ECOSYSTEM'" tooltipPosition="top"
                        pTooltip="{{'Ecosystem'}}" [items]="classificationList.ECOSYSTEM">
                    </tbp-autocomplete>
                    <label for="ecosystem">Ecosystem </label>
                </span>
            </div>

        </div>
        <h5 class="custom-class">Address Information</h5>
        <div class="p-fluid  grid">
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <input id="address" type="text" tooltipPosition="top" pTooltip="{{getHelpText('Street')}}"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.address.errors }" maxlength="220"
                        formControlName="address" pInputText>
                    <label for="address">Street</label>
                </span>
                <span class="p-error"
                    *ngIf="submitted && f.address.invalid && f.address.hasError('message')">{{f.address.errors.message}}</span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <input id="city" type="text" tooltipPosition="top" pTooltip="{{getHelpText('City')}}"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.city.errors }" maxlength="45"
                        formControlName="city" pInputText>
                    <label for="city">City </label>
                </span>
                <span class="p-error"
                    *ngIf="submitted && f.city.invalid && f.city.hasError('message')">{{f.city.errors.message}}</span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <input id="postalCode" type="text" tooltipPosition="top"
                        pTooltip="{{getHelpText('Zip/Postal Code')}}" maxlength="9"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.postalCode.errors }"
                        formControlName="postalCode" pInputText>
                    <label for="postalCode">Zip/Postal Code</label>
                </span>
                <span class="p-error"
                    *ngIf="submitted && f.postalCode.invalid && f.postalCode.hasError('message')">{{f.postalCode.errors.message}}</span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <input id="phone" type="text" tooltipPosition="top"
                        pTooltip="{{getHelpText('Phone')}}" maxlength="40"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.phone.errors }"
                        formControlName="phone" pInputText (blur)="appUtil.formatPhone(f.phone)">
                    <!--
                    <p-inputMask *ngIf="f.country.value !=null && f.country.value.name == 'United States' "
                        mask="(999) 999-9999? x99999" formControlName="phone"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.phone.errors }" tooltipPosition="top" pTooltip="{{getHelpText('Phone')}}"></p-inputMask>
                    <input *ngIf=" f.country.value ==null || f.country.value.name != 'United States' "
                        formControlName="phone" 
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.phone.errors }" type="text" maxlength="40"
                        pInputText>
                        -->
                    <label for="phone">Phone </label>
                </span>
                <span class="p-error" *ngIf="submitted && f.phone.hasError('pattern')">Please enter valid phone
                    number</span>
                <span class="p-error"
                    *ngIf="submitted && f.phone.invalid && f.phone.hasError('message')">{{f.phone.errors.message}}</span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <tbp-autocomplete [multiple]="false" (onChange)="getStates()" [formControl]="f.country"
                        [type]="'COUNTRY'" tooltipPosition="top" pTooltip="{{getHelpText('Country')}}"
                        [items]="countries">
                    </tbp-autocomplete>
                    <label for="country">Country</label>
                </span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <tbp-autocomplete [multiple]="false" [cntType]="'STATE'" [cntUuid]="f.country.value?.uuid"
                        [cntName]="f.country.value?.name"  [disabled]="stateDisabled" [formControl]="f.state" 
                        [type]="'STATE'" [items]="states" tooltipPosition="top" pTooltip="{{getHelpText('State')}}"></tbp-autocomplete>
                    <label for="state">State</label>
                </span>
                <span class="p-error"
                    *ngIf="submitted && f.state.invalid && f.state.hasError('message')">{{f.state.errors.message}}</span>
                <span class="p-error" *ngIf="submitted && f.state.hasError('required')">Please Select State</span>
            </div>

        </div>

    </form>
</div>