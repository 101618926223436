import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { CheckboxModule } from 'primeng/checkbox';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { UploadExcelComponent } from './upload-excel.component';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
@NgModule({
    declarations: [UploadExcelComponent],
    imports: [
        MessagesModule,
        MessageModule,
        CommonModule,
        TableModule,
        ToastModule,
        ButtonModule,
        FormsModule,
        ReactiveFormsModule,
        DialogModule,
        FileUploadModule,
        CheckboxModule,
        ProgressSpinnerModule
    ],
    exports: [
        UploadExcelComponent
    ]
})
export class UploadExcelModule { }
