<div class="card">
    <form [formGroup]="form" #cform>
        <div class="formgrid grid headerSticky">
            <div class="field col-12 md:col-12 text-right" *ngIf="loadingSpinner == false">
                <p-button label="Save" icon="pi pi-check" iconPos="left" (click)="save()" styleClass="mr-2">
                </p-button>
                <button pButton pRipple label="Merge" icon="pi pi-link" class="p-button-info  mr-2"
                    (click)="appUtil.routeToMergeURL('contact',f.uuid.value)" *ngIf="isEdit"></button>
                <p-button label="Cancel" icon="pi pi-times" iconPos="left" (click)="cancel()"
                    styleClass="p-button-secondary"></p-button>
            </div>
            <div class="field col-12 md:col-12 text-right" *ngIf="loadingSpinner">
                <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
                    fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
            </div>
        </div>
        <h5>Contact Information</h5><br>
        <div class="p-fluid  grid">
            <!-- <div  class="field col-12 text-right" style="position: relative; left: -11.2cm;">
                <label for="verified" class="vertical-align-bottom mr-2 font-bold">Verified</label>
                <p-inputSwitch formControlName="verified" label="verified" (onChange)="checkStatus($event)"></p-inputSwitch>
            </div>             -->
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <input id="firstName" [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.firstName.errors }"
                     formControlName="firstName" type="text" maxlength="40"  class="capitalize" (blur)="f.firstName.setValue(appUtil.toTitleCase(f.firstName.value))"
                        pInputText pTooltip="{{getHelpText('First Name')}}" tooltipPosition="top">
                    <label for="firstName">First Name <span class="mandate">*</span></label>
                </span>
                <span class="p-error" *ngIf="form.get('firstName').hasError('required') && submitted">First Name is
                    Required</span>
                <span class="p-error"
                    *ngIf="submitted && f.firstName.invalid && f.firstName.hasError('message')">{{f.firstName.errors.message}}</span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <input id="lastName" [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.lastName.errors }"
                        formControlName="lastName" type="text" maxlength="75"  class="capitalize" (blur)="f.lastName.setValue(appUtil.toTitleCase(f.lastName.value))"
                         pInputText  pTooltip="{{getHelpText('Last Name')}}" tooltipPosition="top">
                    <label for="lastName">Last Name <span class="mandate">*</span></label>
                </span>
                <span class="p-error" *ngIf="form.get('lastName').hasError('required') && submitted">Last Name is
                    Required</span>
                <span class="p-error"
                    *ngIf="submitted && f.lastName.invalid && f.lastName.hasError('message')">{{f.lastName.errors.message}}</span>
            </div>
              
            <div class="field col-12 md:col-3">
                <span class="p-float-label">
                    <input id="email" class="lowercase" (blur)="f.email.setValue(appUtil.toLowerCase(f.email.value))" [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.email.errors }"
                        formControlName="email" type="text" maxlength="175" pInputText  pTooltip="{{getHelpText('Email')}}" tooltipPosition="top">
                    <label for="email">Email <span class="mandate">*</span></label>
                </span>
                <span class="p-error" *ngIf="form.get('email').hasError('required') && submitted">Email is
                    Required</span>
                <span class="p-error" *ngIf="form.get('email').hasError('pattern') && submitted">Email address is
                    invalid</span>
                <span class="p-error"
                    *ngIf="submitted && f.email.invalid && f.email.hasError('message')">{{f.email.errors.message}}</span>
            </div>
            <div  class="field col-12 md:col-1 text-right">
                <label for="verified" class="vertical-align-bottom mr-1 font-bold ml-2 pl-1">Verified</label>
                <p-inputSwitch formControlName="verified" label="verified" (onChange)="checkStatus($event)"></p-inputSwitch>
            </div>     
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <input id="phone" type="text" tooltipPosition="top"
                        pTooltip="{{getHelpText('Phone')}}" maxlength="40"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.phone.errors }"
                        formControlName="phone" pInputText (blur)="appUtil.formatPhone(f.phone)">
                    <!-- <p-inputMask *ngIf="f.country.value !=null && f.country.value.name == 'United States' "
                        mask="(999) 999-9999? x99999" formControlName="phone"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.phone.errors }" pTooltip="{{getHelpText('Phone')}}" tooltipPosition="top"></p-inputMask>
                    <p-inputMask *ngIf=" f.country.value ==null || f.country.value.name != 'United States' "
                        mask="9999999999" id="phone" formControlName="phone"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.phone.errors }"></p-inputMask> -->
                    <label for="phone">Phone </label>
                </span>
                <span class="p-error" *ngIf="form.get('phone').hasError('required') && submitted">Phone is
                    Required</span>
                <span class="p-error"
                    *ngIf="submitted && f.phone.invalid && f.phone.hasError('message')">{{f.phone.errors.message}}</span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <input id="mobile" type="text" tooltipPosition="top"
                        pTooltip="{{getHelpText('Mobile')}}" maxlength="40"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.mobile.errors }"
                        formControlName="mobile" pInputText (blur)="appUtil.formatPhone(f.mobile)">
                    <!-- <p-inputMask *ngIf="f.country.value !=null && f.country.value.name == 'United States' "
                        mask="(999) 999-9999" formControlName="mobile"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.mobile.errors }" pTooltip="{{getHelpText('Mobile No')}}" tooltipPosition="top"></p-inputMask>
                    <p-inputMask *ngIf=" f.country.value ==null || f.country.value.name != 'United States' "
                        mask="9999999999" id="mobile" formControlName="mobile"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.mobile.errors }"></p-inputMask> -->
                    <label for="mobile">Mobile No </label>
                </span>
                <span class="p-error" *ngIf="form.get('mobile').hasError('required') && submitted">Mobile is
                    Required</span>
                <span class="p-error"
                    *ngIf="submitted && f.mobile.invalid && f.mobile.hasError('message')">{{f.mobile.errors.message}}</span>
            </div>
            <div class="field col-12 md:col-3">
                <span class="p-float-label">
                    <tbp-autocomplete [multiple]="false" [formControl]="f.organization"
                        (onSelect)="selectAccount($event)" (onChange)="setupFields($event)" [items]="" [type]="'Account'" pTooltip="{{getHelpText('Account')}}" tooltipPosition="top"></tbp-autocomplete>
                    <label for="account">Account</label>
                </span>
                <span class="p-error" *ngIf="form.get('organization').hasError('required') && submitted">Account / Individual is
                    Required</span>
                <span class="p-error"
                    *ngIf="submitted && f.organization.invalid && f.organization.hasError('message')">{{f.organization.errors.message}}</span>
            </div>

         <div class="field col-12 md:col-1 text-right">
            <label for="individual" class="vertical-align-bottom mr-1 font-bold">Individual</label>
            <p-inputSwitch formControlName="individual" id="individual" label="individual" (onChange)="checkIndividual($event)" ></p-inputSwitch>
        </div> 



            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <input id="title" formControlName="title"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.title.errors }" type="text" maxlength="130"
                        pInputText pTooltip="{{getHelpText('Title')}}" tooltipPosition="top">
                    <label for="title">Title </label>
                    <span class="p-error"
                        *ngIf="submitted && f.title.invalid && f.title.hasError('message')">{{f.title.errors.message}}</span>
                </span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <input id="secondaryEmail" class="lowercase" (blur)="f.secondaryEmail.setValue(appUtil.toLowerCase(f.secondaryEmail.value))"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.secondaryEmail.errors }"
                        formControlName="secondaryEmail" type="text" maxlength="175" pInputText pTooltip="{{getHelpText('Secondary Email')}}" tooltipPosition="top">
                    <label for="secondaryEmail">Secondary Email </label>
                    <span class="p-error" *ngIf="form.get('secondaryEmail').hasError('pattern') && submitted">Secondary
                        Email is invalid</span>
                    <span class="p-error"
                        *ngIf="submitted && f.secondaryEmail.invalid && f.secondaryEmail.hasError('message')">{{f.secondaryEmail.errors.message}}</span>
                </span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <look-autocomplete [multiple]="false" [formControl]="f.leadSource"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.leadSource.errors }" [type]="'ACCOUNT_SOURCE'"
                        [items]="lookupList.ACCOUNT_SOURCE" pTooltip="{{getHelpText('Lead Source')}}" tooltipPosition="top"></look-autocomplete>
                    <label for="leadSource">Lead Source </label>
                </span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label p-input-icon-right">
                    <i class="pi pi-link link" (click)="appUtil.externalLink(f.linkedInProfile.value,'url')" *ngIf="f.linkedInProfile.value != null && f.linkedInProfile.value.trim()!=''"></i>
                    <i class="pi pi-google link" (click)="appUtil.externalLink(
                        (f.firstName.value?f.firstName.value + ' ':'') + (f.lastName.value?f.lastName.value:'') ,'google', 'linkedin.com:')" *ngIf="f.linkedInProfile.value == null ||  f.linkedInProfile.value.trim()==''"></i> 

                    <input id="linkedInProfile" class="lowercase"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.linkedInProfile.errors }"
                        formControlName="linkedInProfile" type="text" maxlength="250" pInputText pTooltip="{{getHelpText('LinkedIn Profile URL')}}" tooltipPosition="top">
                    <label for="linkedInProfile">LinkedIn Profile URL </label>
                </span>
                <span class="p-error" *ngIf="form.get('linkedInProfile').hasError('url') && submitted">linkedInProfile
                    should be a URL</span>
                <span class="p-error"
                    *ngIf="submitted && f.linkedInProfile.invalid && f.linkedInProfile.hasError('message')">{{f.linkedInProfile.errors.message}}</span>
            </div>

            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <input id="assistantName" formControlName="assistantName" class="capitalize" (blur)="f.assistantName.setValue(appUtil.toTitleCase(f.assistantName.value))"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.assistantName.errors }" type="text"
                        maxlength="120" pInputText pTooltip="{{getHelpText('Assistant Name')}}" tooltipPosition="top">
                    <label for="assistantName">Assistant's Name </label>
                    <span class="p-error"
                        *ngIf="submitted && f.assistantName.invalid && f.assistantName.hasError('message')">{{f.assistantName.errors.message}}</span>
                </span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <input id="assistantPhone" type="text" tooltipPosition="top"
                        pTooltip="{{getHelpText('Assistant Phone')}}" maxlength="40"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.assistantPhone.errors }"
                        formControlName="assistantPhone" pInputText (blur)="appUtil.formatPhone(f.assistantPhone)">
                    <!-- <p-inputMask *ngIf="f.country.value != null && f.country.value.name == 'United States' "
                        mask="(999) 999-9999? x99999" formControlName="assistantPhone"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.assistantPhone.errors }" pTooltip="{{getHelpText('Assistants Phone')}}" tooltipPosition="top"></p-inputMask> -->
                    <!-- <input *ngIf="f.country.value != null && f.country.value.name != 'United States' "
                        id="assistantPhone" formControlName="assistantPhone"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.assistantPhone.errors }" type="text"
                        maxlength="40" pInputText> -->
                    <label for="assistantPhone">Assistant's Phone </label>
                    <span class="p-error"
                        *ngIf="submitted && f.assistantPhone.invalid && f.assistantPhone.hasError('message')">{{f.assistantPhone.errors.message}}</span>
                </span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <input id="assistantEmail" class="lowercase" (blur)="f.assistantEmail.setValue(appUtil.toLowerCase(f.assistantEmail.value))"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.assistantEmail.errors }"
                        formControlName="assistantEmail" type="text" maxlength="175" pInputText pTooltip="{{getHelpText('Assistants Email')}}" tooltipPosition="top">
                    <label for="assistantEmail">Assistant's Email </label>
                    <span class="p-error"
                        *ngIf="form.get('assistantEmail').hasError('pattern') && submitted">Assistant's Email is
                        invalid</span>
                    <span class="p-error"
                        *ngIf="submitted && f.assistantEmail.invalid && f.assistantEmail.hasError('message')">{{f.assistantEmail.errors.message}}</span>
                </span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <p-dropdown [options]="tbpEmployees" styleClass="form-control" formControlName="accountOwner"
                        id="accountOwner" name="accountOwner" optionLabel="displayName" optionValue="uuid" [filter]="true"
                        [autoDisplayFirst]="false" pTooltip="{{getHelpText('Account Owner')}}" tooltipPosition="top" [showClear]="true">
                    </p-dropdown>
                    <label for="accountOwner">Account Owner</label>
                </span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <look-autocomplete [multiple]="true" [formControl]="f.mailingList"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.mailingList.errors }" [type]="'MAILING_LIST'"
                        [items]="lookupList.MAILING_LIST" pTooltip="{{('Mailing List')}}" tooltipPosition="top"></look-autocomplete>
                    <label for="mailingList">Mailing List</label>
                </span>
            </div>
            <div class="field col-12 md:col-4">
                <p-checkbox id="masterProfile" formControlName="masterProfile" binary="masterProfile" class="ml-2"
                    (click)="confirm($event)" pTooltip="{{('Master')}}" tooltipPosition="top" label="Is Primary Profile?">
                </p-checkbox>
            </div>
            <div class="field col-12 md:col-4">
                <p-checkbox formControlName="accreditedInvestor" binary="accreditedInvestor"
                    id="accreditedInvestor" class="ml-2" pTooltip="{{('Accredited Investor')}}" tooltipPosition="top" label="Is Accredited Investor?"></p-checkbox>
            </div>   
            <div class="field col-12 md:col-4">
                <p-checkbox name="primaryContact" formControlName="primaryContact" binary="primaryContact"
                id="primaryContact" class="ml-2" pTooltip="{{getHelpText('Primary Contact')}}" tooltipPosition="top" label="Is Primary Contact?"></p-checkbox>
            </div>            

        </div>
        <h5>Description</h5>
        <div class="p-fluid formgrid grid">
            <div class="field col-12 md:col-12">
                    <editor #editor formControlName="description" [init]="appUtil.getInitData" pTooltip="{{('Description')}}" tooltipPosition="top"></editor>
            </div>
        </div>
        <h5>Email Preferences </h5>
        <div class="p-fluid formgrid grid">
            <div class="field col-12 md:col-4">
                <p-checkbox formControlName="sendMAOpportunityEmails" binary="sendMAOpportunityEmails" id="noe"
                    label="Send M&A Opportunities" pTooltip="{{getHelpText('Send M&A Opportunities')}}" tooltipPosition="top"></p-checkbox>
            </div>
            <div class="field col-12 md:col-4">
                <p-checkbox formControlName="sendSpotlightEmails" binary="sendSpotlightEmails" id="nse"
                    label="Send Spotlights" pTooltip="{{getHelpText('Send Spotlights')}}" tooltipPosition="top"></p-checkbox>
            </div>
            <div class="field col-12 md:col-4">
                <p-checkbox formControlName="doNotSendUpdates" binary="doNotSendUpdates" id="nnu"
                    label="Do not send me any information" pTooltip="{{getHelpText('Do not send me any information')}}" tooltipPosition="top"></p-checkbox>
            </div>
            <div class="field col-12 md:col-4" *ngFor="let lookupValue of lookupList.EMAIL_PREFERENCES">
                <p-checkbox formControlName="lookupValue" (onChange)="onChangeCheckbox($event,lookupValue.uuid)"
                    [value]="lookupValue.uuid" label="{{lookupValue.value}}"></p-checkbox>
            </div>
        </div>

        <h5 class="p-mb-5">Classification</h5>
        <div class="p-fluid  grid">
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <tbp-autocomplete [formControl]="f.level1" [type]="'CATEGORY_LEVEL1'"
                        [items]="classificationList.CATEGORY_LEVEL1" pTooltip="{{getHelpText('Level1')}}" tooltipPosition="top">
                    </tbp-autocomplete>
                    <label for="level1">Level1 </label>
                </span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <tbp-autocomplete [formControl]="f.level2" [type]="'CATEGORY_LEVEL2'"
                        [items]="classificationList.CATEGORY_LEVEL2" pTooltip="{{getHelpText('Level2')}}" tooltipPosition="top">
                    </tbp-autocomplete>
                    <label for="level2">Level2 </label>
                </span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <tbp-autocomplete [formControl]="f.level3" [type]="'CATEGORY_LEVEL3'"
                        [items]="classificationList.CATEGORY_LEVEL3" pTooltip="{{getHelpText('Level3')}}" tooltipPosition="top">
                    </tbp-autocomplete>
                    <label for="level3">Level3 </label>
                </span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <tbp-autocomplete [formControl]="f.vertical" [type]="'VERTICAL'"
                        [items]="classificationList.VERTICAL" pTooltip="{{getHelpText('Vertical/Industry')}}" tooltipPosition="top">
                    </tbp-autocomplete>
                    <label for="vertical">Vertical/Industry </label>
                </span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <tbp-autocomplete [formControl]="f.ecosystem" [type]="'ECOSYSTEM'"
                        [items]="classificationList.ECOSYSTEM" pTooltip="{{'Ecosystem'}}" tooltipPosition="top">
                    </tbp-autocomplete>
                    <label for="ecosystem">Ecosystem </label>
                </span>
            </div>

        </div>

        <h5 class="p-mb-5">Address Information</h5>
        <div class="p-fluid  grid" *ngIf="addressShow">
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <p-dropdown [options]="addresses" [autoDisplayFirst]="false" styleClass="form-control"
                        optionLabel="title" optionValue="title" [filter]="true" (onChange)="setAddress($event)">
                    </p-dropdown>
                    <label for="address">Select from Account Addresses</label>
                </span>
            </div>
        </div>
        <div class="p-fluid  grid">
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <input id="address" type="text" [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.address.errors }"
                        formControlName="address" maxlength="250" pInputText pTooltip="{{getHelpText('Street')}}" tooltipPosition="top">
                    <label for="address">Street</label>
                    <span class="p-error"
                        *ngIf="submitted && f.address.invalid && f.address.hasError('message')">{{f.address.errors.message}}</span>
                </span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <input id="city" type="text" [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.city.errors }"
                        formControlName="city" maxlength="45" pInputText pTooltip="{{getHelpText('City')}}" tooltipPosition="top">
                    <label for="city">City </label>
                </span>
                <span class="p-error" *ngIf="form.get('city').hasError('required') && submitted">City is Required</span>
                <span class="p-error"
                    *ngIf="submitted && f.city.invalid && f.city.hasError('message')">{{f.city.errors.message}}</span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <input id="postalCode" type="text"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.postalCode.errors }"
                        formControlName="postalCode" maxlength="9" pInputText pTooltip="{{getHelpText('Zip/Postal Code')}}" tooltipPosition="top">
                    <label for="zip">Zip/Postal Code</label>
                </span>
                <span class="p-error"
                    *ngIf="submitted && f.postalCode.invalid && f.postalCode.hasError('message')">{{f.postalCode.errors.message}}</span>
                <span class="p-error" *ngIf="form.get('postalCode').hasError('required') && submitted">Zip/Postal Code
                    is Required</span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <tbp-autocomplete [multiple]="false" (onChange)="getStates()" [formControl]="f.country"
                        [type]="'COUNTRY'" [items]="countries" pTooltip="{{getHelpText('Country')}}" tooltipPosition="top">
                    </tbp-autocomplete>
                    <label for="country">Country</label>
                </span>
            </div>
            <div class="field col-12 md:col-4">
                <span class="p-float-label">
                    <tbp-autocomplete [multiple]="false" [cntType]="'STATE'" [cntUuid]="f.country.value?.uuid"
                        [cntName]="f.country.value?.name" [disabled]="stateDisabled" [formControl]="f.state" 
                        [type]="'STATE'" [items]="states" pTooltip="{{getHelpText('State')}}" tooltipPosition="top" ></tbp-autocomplete>
                    <label for="state">State</label>
                </span>
                <span class="p-error"
                    *ngIf="submitted && f.state.invalid && f.state.hasError('message')">{{f.state.errors.message}}</span>
                <span class="p-error" *ngIf="submitted && f.state.hasError('required')">Please Select State</span>
            </div>
        </div>
    </form>
</div>
<p-toast></p-toast>
<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
