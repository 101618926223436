import { Component, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from '../app.breadcrumb.service';
import { EmailCampaign } from '../_models/emailcampaign';
import { AccountService } from '../_services';
import { EmailCampaignService } from './email-campaign.service';
import { HttpClient } from '@angular/common/http';
import * as mimeType from 'mime'
import { DataService } from '../_services/data.service';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmailCampaignProfile } from '../_models/emailcampaignprofile';
import { UsersService } from '../users/users.service';
import { saveAs } from 'file-saver';
import { AppUtil } from '../_helpers';
import { Location } from '@angular/common';
@Component({
    selector: 'app-email-campaign-view',
    templateUrl: './email-campaign-view.component.html',
    styleUrls: ['./email-campaign-view.component.scss'],
    providers: [MessageService, ConfirmationService]
})
export class EmailCampaignViewComponent implements OnInit {

    uuid: string;
    emailCampaign: EmailCampaign;
    recipients: [];
    recipientItems: MenuItem[];
    emailCampaignProfile: EmailCampaignProfile;
    uploadRecipient: boolean = false;
    uploadItems: MenuItem[];
    activeIndex: number = 0;

    constructor(
        private messageService: MessageService,
        private dataService: DataService,
        private http: HttpClient,
        private emailCampaignService: EmailCampaignService,
        private breadcrumbService: BreadcrumbService,
        private router: Router,
        private route: ActivatedRoute,
        public accountService: AccountService,
        private location: Location,
        public appUtil: AppUtil) {
        this.breadcrumbService.setItems([
            { label: ' View Email Campaign' }
        ]);
        this.uploadItems = [{ label: 'Template', command: () => { this.appUtil.downloadTemplate('contact-template.xlsx'); } }];
    }


    ngOnInit(): void {

        this.route.url.subscribe((url) => {
            this.uuid = this.route.snapshot.params.uuid;
            if(url.length == 2)
            {
                this.activeIndex = 0;
            }
            else if(url[2].path == 'recipients' || url[2].path == 'sync-screen')
            {
                this.activeIndex = 1;
            }
            this.emailCampaign = new EmailCampaign;
            this.uuid = this.route.snapshot.params.uuid;
            this.emailCampaignService.getEmailCampaign(this.route.snapshot.params.uuid).subscribe(data => {
            this.emailCampaign = data as any;
            });

            let message = this.dataService.getDataByKey('message');
            if (message != undefined) {
                setTimeout(() => { this.messageService.add({ severity: 'success', summary: 'Successful', detail: message, life: 3000 }); })
                this.dataService.setData('message', undefined);
            }
        })

        this.recipientItems = [
            {
                label: 'Schedule',
                icon: 'pi pi-plus',
                command: () => {

                }
            },
            {
                label: 'Un-Schedule',
                icon: 'pi pi-times',
                command: () => {
                }
            },
            {
                label: 'Delete',
                icon: 'pi pi-trash',
                command: () => {
                }
            }
        ];

    }

    ngOnChanges(changes: SimpleChanges) {
        
    }

    downloadTemplate() {
        window.open('/app/assets/download-Template/contact-template.xlsx', '_blank');
    }

    get role() {
        return this.accountService.userValue.role;
    }

    edit() {
        this.router.navigate(['/email-campaign/edit/' + this.emailCampaign.uuid]    );
    }

    openDocument(event) {
        // Prevent default behavior when clicking a link
        event.preventDefault();

        // Get filename from href
        var filename = event.target.href;
        this.http.get(filename, { responseType: 'arraybuffer' }).subscribe(
            data => {
                var file = new Blob([data], { type: mimeType.getType(filename) });
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            });
    }

    addRecipient() {
        let advanceQuery = {"condition": "or", "rules": []};
        this.router.navigate(['/email-campaign/'+ this.emailCampaign.uuid +'/' + '/contact/add'], { queryParams: { 'advsearch': true, 
        'term': this.appUtil.convertForQueryParam(advanceQuery), 'first': 0, 
        'pagesize': this.appUtil.pageSize, 'sortfield': "relevance", 'sortorder': -1 } });
 //       this.router.navigate(['/email-campaign/' + this.uuid + '/add-participants']);
    }

    getRecipientList() {
        this.emailCampaignService.getRecipients(this.route.snapshot.params.uuid).subscribe(data => {
            this.recipients = data as any;
        })
    }

    exportExcel() {
        this.emailCampaignService.downloadRecipients(this.uuid).subscribe(blob => saveAs(blob, "Recipients.xlsx"));
    }

    uploadRecipients() {
        this.uploadRecipient = true;
    }

    changeTab($event)
    {
        if($event.index == 1)
        {
            this.location.go('email-campaign/' + this.uuid + '/recipients');
        }
        else
        {
            this.location.go('email-campaign/' + this.uuid);
        }
    }

    afterUpload() {
        this.router.navigate(['/email-campaign/' + this.uuid + '/recipients']);
    }

}
