<div class="card" *ngIf="role == 1">
    <div class="grid">
        <div class="col-12">
            <p-table [autoLayout]="true" [value]="contacts" [(selection)]="selectedContacts" dataKey="userId"
            [lazy]="true" (onLazyLoad)="loadContacts($event)"
            styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm" [rowHover]="true"
            [rows]="5" [paginator]="false" [loading]="contactLoading" [showCurrentPageReport]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [totalRecords]="contactTotalRecords" [alwaysShowPaginator]="false">
            <ng-template pTemplate="caption">
                Contact(s)
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>Name</th>
                    <th>Account Name</th>
                    <th>Phone</th>
                    <th>Mobile</th>
                    <th>Email</th>
                    <th>Title</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-contact>
                <tr>
                    <td class="wrap">
                        <span class="p-column-title">Name</span>
                        <a [routerLink]="['/contact', contact.uuid]" [innerHTML]="contact.name | highlight:highlightTerm"></a>
                            <sup *ngIf=" contact.status == 'PENDING_ACTIVATION' || contact.status == 'PENDING_PROFILE'  || contact.status == 'REVOKED' || !contact.verified">
                                <p-badge class="small-p-badge" [pTooltip]="appUtil.getUserStatus(contact)" 
                                [ngClass]="{'pendingactivation': contact.status == 'PENDING_ACTIVATION','pendingprofile': contact.status == 'PENDING_PROFILE',
                                'pendingverification': (!contact.verified && !contact.selfSignUp),
                                'pendingverificationself': (!contact.verified && contact.selfSignUp),'revoked': contact.status == 'REVOKED'}"></p-badge>
                            </sup>                                
                    </td>
                    <td class="wrap">
                        <span class="p-column-title">Account Name</span>
                        <a *ngIf="contact.organization"
                            [routerLink]="['/organization/', contact.organization.uuid]"[innerHTML]="contact.organization?contact.organization.name:'' | highlight:highlightTerm"></a>
                    </td>
                    <td class="wrap">
                        <span class="p-column-title">Phone</span>
                        {{contact.phone}}
                    </td>
                    <td class="wrap">
                        <span class="p-column-title">Mobile</span>
                        {{contact.mobile}}
                    </td>
                    <td class="wrap">
                        <span class="p-column-title">Email</span>
                        <a [routerLink]="['/contact', contact.uuid]"[innerHTML]="contact.email | highlight:highlightTerm"></a>
                    </td>
                    <td class="wrap">
                        <span class="p-column-title">Title</span>
                        {{contact.title}}
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="6">No Contact(s) found.</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="footer">
                <tr *ngIf="contactTotalRecords > 5">
                    <td colspan="6" class="text-right"><a href="javascript:" (click)="ViewMore('Contact');">View
                            More</a></td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="col-12">
        <div class="col-12">
            <p-table [autoLayout]="true" [value]="organizations" [(selection)]="selectedOrg" dataKey="userId"
                [lazy]="true" (onLazyLoad)="loadOrganizations($event)"
                styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm" [rowHover]="true"
                [rows]="5" [paginator]="false" [loading]="orgLoading" [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [totalRecords]="orgTotalRecords" [alwaysShowPaginator]="false">
                <ng-template pTemplate="caption">
                    Account(s)
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Location</th>
                        <th>Website</th>
                        <th>Level1</th>
                        <th>Level2</th>
                        <th>Level3</th>
                        <th>Primary Contacts</th>
                        <th>Revenue</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-organization>
                    <tr>
                        <td class="wrap">
                          <a [routerLink]="['/organization', organization.uuid]" [innerHTML]="organization.name | highlight:highlightTerm"></a>
                        </td>
                        <td class="wrap">
                            {{organization.companyType.replace('_',' ') | titlecase}}
                        </td>
                        <td class="wrap">
                            <span class="limitedText limitText">
                                <span *ngIf="organization.state != null">{{organization.state | titlecase}}</span>
                                <span *ngIf="organization.country != null">
                                    <ng-container *ngIf="organization.state != null">, </ng-container>
                                    {{organization.country | titlecase}}
                                </span>
                            </span>
                            <a href="javascript:" (click)="appUtil.showHideClampedContent($event)"
                                class="moreless hidden ml-2 p-button-danger"
                                style="float:right;display:block;">More</a>
                        </td>
                        <td class="wrap">
                            {{organization.website}}
                        </td>
                        <td class="wrap">
                            <span class="limitedText limitText">
                                <span
                                    *ngFor="let classification of organization.classification.level1; let last = last;">{{classification.name}}{{last?'':';
                                    '}}</span>
                            </span>
                            <a href="javascript:" (click)="appUtil.showHideClampedContent($event)"
                                class="moreless hidden ml-2 p-button-danger"
                                style="float:right;display:block;">More</a>
                        </td>
                        <td class="wrap">
                            <span class="limitedText limitText">
                                <span
                                    *ngFor="let classification of organization.classification.level2; let last = last;">{{classification.name}}{{last?'':';
                                    '}}</span>
                            </span>
                            <a href="javascript:" (click)="appUtil.showHideClampedContent($event)"
                                class="moreless hidden ml-2 p-button-danger"
                                style="float:right;display:block;">More</a>

                        </td>
                        <td class="wrap">
                            <span class="limitedText limitText">
                                <span
                                    *ngFor="let classification of organization.classification.level3; let last = last;">{{classification.name}}{{last?'':';
                                    '}}</span>
                            </span>
                            <a href="javascript:" (click)="appUtil.showHideClampedContent($event)"
                                class="moreless hidden ml-2 p-button-danger"
                                style="float:right;display:block;">More</a>
                        </td>
                        <td class="wrap">
                            <span class="limitedText limitText">
                                <span *ngFor="let primaryContact of organization.primaryContact; let last = last;"><a
                                        [routerLink]="['/contact/', primaryContact.uuid]">{{primaryContact.email}}&nbsp;{{primaryContact.phone}}{{last?'':';
                                        '}}</a></span>
                            </span>
                            <a href="javascript:" (click)="appUtil.showHideClampedContent($event)"
                                class="moreless hidden ml-2 p-button-danger"
                                style="float:right;display:block;">More</a>
                        </td>
                        <td class="wrap">
                            {{organization.revenueLookup?organization.revenueLookup.value:''}}
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="9">No Account(s) found.</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="footer">
                    <tr *ngIf="orgTotalRecords > 5">
                        <td colspan="9" class="text-right"><a href="javascript:" (click)="ViewMore('Account');">View
                                More</a></td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <div class="col-12">
            <p-table [autoLayout]="true" [value]="deals" [(selection)]="selectedDeal" dataKey="userId" [lazy]="true"
                (onLazyLoad)="loadDeals($event)"
                styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm" [rowHover]="true"
                [rows]="5" [paginator]="false" [loading]="dealLoading" [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [totalRecords]="dealTotalRecords" [alwaysShowPaginator]="false">
                <ng-template pTemplate="caption">
                    Deal(s)
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th>Date</th>
                        <th>Buyers/Investors</th>
                        <th>Target/Issuer</th>
                        <th>Deal Type</th>
                        <th>Transaction Value ($m) </th>
                        <th>EV/Revenue</th>
                        <th>EV/EBITDA</th>
                        <th>Level1</th>
                        <th>Level2</th>
                        <th>Level3</th>
                        <th>Vertical/Industry</th>
                        <th>Ecosystem</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-deal>
                    <tr>
                        <td class="wrap">
                            <span class="p-column-title">Date</span>
                            <a [routerLink]="['/deal/', deal.uuid]">{{deal.dealDate | date:'MM-dd-yyyy'}}</a>
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Buyers/Investors</span>
                            <span class="limitedText limitText">
                                <span *ngFor="let organization of deal.buyerOrganizations; let last = last;"><a
                                        [routerLink]="['/organization/', organization.uuid]" [innerHTML]="organization.name | highlight:highlightTerm"></a>{{last?'':';
                                    '}}</span>
                            </span>
                            <a href="javascript:" (click)="appUtil.showHideClampedContent($event)"
                                class="moreless hidden ml-2 p-button-danger"
                                style="float:right;display:block;">More</a>
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Target/Issuer</span>
                            <span class="limitedText limitText">
                                <a *ngIf="deal.targetOrganization"
                                    [routerLink]="['/organization/', deal.targetOrganization.uuid]"
                                    [innerHTML]="deal.targetOrganization?.name | highlight:highlightTerm"></a>
                            </span>
                            <a href="javascript:" (click)="appUtil.showHideClampedContent($event)"
                                class="moreless hidden ml-2 p-button-danger"
                                style="float:right;display:block;">More</a>
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Deal Type</span>
                            {{deal.dealType | dealPipe}}
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Transaction Value ($m)</span>
                            {{deal.transactionValue==null?'-':deal.transactionValue==0?'-':deal.transactionValue}}
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">EV/Revenue</span>
                            {{deal.evRev==null?'-':deal.evRev==0?'-':deal.evRev}}
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">EV/EBITDA</span>
                            {{deal.evEbitda==null?'-':deal.evEbitda==0?'-':deal.evEbitda}}
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Level1</span>
                            <span class="limitedText limitText">
                                <span
                                    *ngFor="let classification of deal.classification.level1; let last = last;">{{classification.name}}{{last?'':';
                                    '}}</span>
                            </span>
                            <a href="javascript:" (click)="appUtil.showHideClampedContent($event)"
                                class="moreless hidden ml-2 p-button-danger"
                                style="float:right;display:block;">More</a>
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Level2</span>
                            <span class="limitedText limitText">
                                <span
                                    *ngFor="let classification of deal.classification.level2; let last = last;">{{classification.name}}{{last?'':';
                                    '}}</span>
                            </span>
                            <a href="javascript:" (click)="appUtil.showHideClampedContent($event)"
                                class="moreless hidden ml-2 p-button-danger"
                                style="float:right;display:block;">More</a>
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Level3</span>
                            <span class="limitedText limitText">
                                <span
                                    *ngFor="let classification of deal.classification.level3; let last = last;">{{classification.name}}{{last?'':';
                                    '}}</span>
                            </span>
                            <a href="javascript:" (click)="appUtil.showHideClampedContent($event)"
                                class="moreless hidden ml-2 p-button-danger"
                                style="float:right;display:block;">More</a>
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Vertical</span>
                            <span class="limitedText limitText">
                                <span
                                    *ngFor="let classification of deal.classification.vertical; let last = last;">{{classification.name}}{{last?'':';
                                    '}}</span>
                            </span>
                            <a href="javascript:" (click)="appUtil.showHideClampedContent($event)"
                                class="moreless hidden ml-2 p-button-danger"
                                style="float:right;display:block;">More</a>
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Ecosystem</span>
                            <span class="limitedText limitText">
                                <span
                                    *ngFor="let classification of deal.classification.ecosystem; let last = last;">{{classification.name}}{{last?'':';
                                    '}}</span>
                            </span>
                            <a href="javascript:" (click)="appUtil.showHideClampedContent($event)"
                                class="moreless hidden ml-2 p-button-danger"
                                style="float:right;display:block;">More</a>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="12">No Deal(s) found.</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="footer">
                    <tr *ngIf="dealTotalRecords > 5">
                        <td colspan="12" class="text-right"><a href="javascript:" (click)="ViewMore('Deal');">View
                                More</a></td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <div class="col-12 md:col-6"></div>
    </div>
</div>