<div class="grid table-demo">
    <div class="col-12">
        <div class="card">
            <p-toolbar styleClass="headerSticky">
                <div class="p-toolbar-group-left">
                    <div class="grid">
                        <div class="col-12">
                        </div>
                    </div>
                </div>
                <div class="p-toolbar-group-right mb-3">
                    <!-- <p-button  label="Associate" (onClick)="associate()" *ngIf="showCheckbox" 
                    [disabled]="(!appUtil.isArray(selectedDeals) || selectedDeals.length == 0)" class="p-button-success mr-2">
                        </p-button> -->
                    <button pButton pRipple label="Back" icon="pi pi-arrow-left" class="p-button-success mr-2" (click)="appUtil.return()"></button>
                    <button pButton type="button" class="p-button-success mb-1 mr-2" (click)="associate()"
                        *ngIf="showCheckbox"
                        [disabled]="(!appUtil.isArray(selectedDeals) || selectedDeals.length == 0)">Add
                        Selected</button>
                    <button *ngIf="!showCheckbox" pButton pRipple label="New" icon="pi pi-plus"
                        class="p-button-success mr-2" (click)="openNew()"></button>
                    <p-splitButton *ngIf="!showCheckbox" label="Upload" (onClick)="dealUpload()" class="mr-2"
                        icon="pi pi-upload" [model]="uploadItems"></p-splitButton>
                    <button pButton pRipple label="Download" class="p-button-success mr-2  mb-1"
                        (click)="downloadDeals()"></button>
                </div>
            </p-toolbar>
            <tbp-search #sd [fields]="fieldInfo" filterFor="DEAL_INFO" searchTermPlaceHolder="Search Deals"
                defaultSortField="dealDate" defaultSortOrder="-1" [searchURL]="searchUrl" (onSearch)="_search($event)"
                (onShowHideField)="_updateSelectedColumns($event)">
            </tbp-search>
            <p-table [autoLayout]="true" #dt [value]="deals" [(selection)]="selectedDeals" dataKey="uuid" [lazy]="true"
                [columns]="selectedColumns"
                (onLazyLoad)="sd.loadData($event.first,$event.rows,$event.sortField,$event.sortOrder)"
                [lazyLoadOnInit]="false"
                styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm" [rowHover]="true"
                [rows]="sd.pageSize" [first]="sd.firstRow" [paginator]="true"
                [rowsPerPageOptions]="[15,25,50,75,100]" [loading]="loading"
                [alwaysShowPaginator]="true" [showCurrentPageReport]="true" [showPageLinks]="appUtil.getPagination(totalRecords)" [showFirstLastIcon] = "appUtil.getPagination(totalRecords)"
                currentPageReportTemplate="Showing {first} to {last} of {{appUtil.getPagination(totalRecords) ? totalRecords :'many'}} entries"
                [totalRecords]="totalRecords" [sortField]="sd.sortField" [sortOrder]="sd.sortOrder">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th style="width: 3rem"
                            *ngIf="showCheckbox && (source != 'Spotlight' || localStrogeObj != null)">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <ng-container *ngFor="let column of columns;">
                            <th [pSortableColumn]="column.property" [pSortableColumnDisabled]="!column.sortable"
                                [ngStyle]="{'width': column.columnWidth}" pResizableColumn>
                                {{column.label}}
                                <p-sortIcon field="{{column.property}}" *ngIf="column.sortable"></p-sortIcon>
                            </th>
                        </ng-container>
                        <th *ngIf="!showCheckbox">Action</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-deal let-columns="columns">
                    <tr>
                        <td (click)="$event.stopPropagation();"
                            *ngIf="showCheckbox && (source != 'Spotlight' || localStrogeObj != null)">
                            <p-tableCheckbox [value]="deal"></p-tableCheckbox>
                        </td>
                        <ng-container *ngFor="let column of columns;">
                            <td class="wrap" [attr.width]="column.columnWidth">
                                <ng-container *ngIf="column.property != 'buyerOrganizations' && column.property != 'targetOrganization' && column.property != 'targetDescription'">
                                    <tbp-table-column [object]="deal" [fieldInfo]="column"
                                        (onPatch)="patchDeal($event)"></tbp-table-column>
                                </ng-container>
                              
                                <ng-container *ngIf="column.property === 'buyerOrganizations'">
                                    <ng-container *ngFor="let buyerOrg of deal.buyerOrganizations">
                                      <a [routerLink]="['/organization', buyerOrg?.uuid]" [innerHTML]="buyerOrg?.name | highlight:term"></a><br />
                                    </ng-container>
                                  </ng-container>
                                  <ng-container *ngIf="column.property == 'targetOrganization'">
                                    <a [routerLink]="['/organization', deal.targetOrganization?.uuid]" 
                                       [innerHTML]="deal.targetOrganization?.name | highlight:term">
                                    </a>
                                  </ng-container>
                                  <ng-container *ngIf="column.property == 'targetDescription'">
                                    <span class="limitedText limitText" [innerHTML]="deal.targetDescription | highlight:term"></span>
                                    <a href="javascript:" (click)="appUtil.showHideClampedContent($event)" class="moreless hidden ml-2 p-button-danger" style="float:right;display:block;">More</a>
                                  </ng-container>
                            </td>
                        </ng-container>
                        <td *ngIf="!showCheckbox">
                            <span class="p-column-title">Action</span>
                            <button type="button" pButton icon="pi pi-list"
                                (click)="setCurrentItem(deal); menu.toggle($event)"></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="15">No Deal found.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
<app-upload-excel (onSuccess)="reload()" [(show)]="uploadPop" url="deal/upload" title="Deal Upload"></app-upload-excel>
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
<p-toast></p-toast>
<p-menu #menu [popup]="true" [model]="dealItems"></p-menu>
<spotlight-contact-edit [(show)]="conDialog" contactType="deal" [parentUuid]="currentMenuData.uuid">
</spotlight-contact-edit>
<p-dialog [(visible)]="showAddDialog" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}">
    <ng-template pTemplate="header">
        <div class="p-fluid formgrid grid">
            <div class="field col-12">
                <i class="pi pi-exclamation-triangle mr-2"></i>Are you sure that you want to add the organizations
                listed below?
            </div>
        </div>
    </ng-template>

    <ng-template pTemplate="deal">
        <div class="p-fluid formgrid grid">
            <div class="field col-12">
                <p-table [autoLayout]="true" [value]="selectedOrganizations"
                    styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm"
                    [rowHover]="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="name">Account Name</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Title</th>
                            <th>Action</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-org>
                        <tr>
                            <td class="wrap capitalize">
                                <span class="p-column-title"></span>{{org.name}}
                            </td>
                            <td class="wrap ">
                                <span class="p-column-title"></span>
                            </td>
                            <td class="wrap lowercase">
                                <span class="p-column-title"></span>{{org.email}}

                            </td>
                            <td class="wrap">
                                <span class="p-column-title"></span>{{org.title}}

                            </td>
                            <td>
                                <span class="p-column-title">Action</span>
                                <button type="button" pButton icon="pi pi-minus-circle" (click)="remove(org);"></button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Add" icon="pi pi-check" class="p-button-text" (click)="addToSource()"></button>
        <button pButton pRipple label="Close" class="p-button-text" (click)="showAddDialog = false"></button>
    </ng-template>
</p-dialog>