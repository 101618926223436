import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment } from '@angular/router';
import { ConfigurationListComponent } from "../configuration/configuration.list.component";

const routes: Routes = [
    { 
        matcher: (url) => { // urls like globalLookup, globalLookup/EBITDA, buyside/:uuid/contact/add
            if ( (url[0].path == 'globalLookup') && url.length <=2) {
                return {
                    consumed: url,
                    posParams: {
                        keyid: url.length==2 ?url[1]:new UrlSegment('', {})
                    }
                };
            }
            return null;
        },
        component: ConfigurationListComponent },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ConfigurationRoutingModule { }
