<div class="grid table-demo">
    <div class="col-12">
        <div class="card divider-card">
            <p-toolbar styleClass="headerSticky" *ngIf="role == 1">
                <ng-template pTemplate="left">
                </ng-template>

                <ng-template pTemplate="right">
                    <button pButton pRipple label="Back" icon="pi pi-arrow-left"  class="p-button-success mr-2" (click)="appUtil.return()"></button>
                    <button pButton pRipple label="Edit" icon="pi pi-pencil" class="p-button-success mr-2"
                        (click)="edit()"></button>
                    <!-- <button pButton pRipple [label]="Delete" icon="pi pi-trash" class="p-button-danger  mr-2" ></button>-->
                </ng-template>
            </p-toolbar>
            <div class="p-fluid formgrid grid">
                <div class="field col-12">
                    <div class="grid mt-1">
                        <div class="col-12 md:col-6">
                            <div class="inline label">Created By:</div>
                             {{deal.createByUser}} on {{deal.createdDate | date:"MM-dd-yyyy HH:mm:ss"}}
                        </div>
                        <div class="col-12 md:col-6 text-right">
                            <div class="inline label">Modified By:</div>
                             {{deal.modifiedByUser}} on {{deal.lastModifiedDate | date:"MM-dd-yyyy HH:mm:ss"}}
                        </div>
                    </div>
                    <p-divider type="dashed"></p-divider>
                </div>
            </div>
            <h5>Deals</h5>
            <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-4">
                    <div class="grid mt-1 limit-width-large dashed-bottom">
                        <div class="col-12 md:col-4 label md:text-right">
                            Date
                        </div>
                        <div class="field col-12 md:col-8">
                            {{deal.dealDate | date:'MM-dd-yyyy'}}&nbsp;
                        </div>
                    </div>
                </div>
                <div class="field col-12 md:col-4">
                    <div class="grid mt-1 limit-width-large dashed-bottom">
                        <div class="col-12 md:col-4 label md:text-right">
                            Buyers/Investors
                        </div>
                        <div class="field col-12 md:col-8">
                            <span *ngFor="let organization of deal.buyerOrganizations; let last = last;"
                                [ngClass]="{'outstock' : organization.uuid ==null}">
                                <a
                                    [routerLink]="['/organization/', organization.uuid]">{{organization.name}}</a>{{last?'':';'}}
                            </span>&nbsp;
                        </div>
                    </div>
                </div>
                <div class="field col-12 md:col-4">
                    <div class="grid mt-1 limit-width-large dashed-bottom">
                        <div class="col-12 md:col-4 label md:text-right">
                            Target/Issuer
                        </div>
                        <div class="field col-12 md:col-8">
                            <span [ngClass]="{'outstock' : deal.targetOrganization?.uuid ==null }">
                                <a
                                    [routerLink]="['/organization/', deal.targetOrganization?.uuid]">{{deal.targetOrganization?.name}}</a>
                            </span>&nbsp;
                        </div>
                    </div>
                </div>
                <div class="field col-12 md:col-4">
                    <div class="grid mt-1 limit-width-large dashed-bottom">
                        <div class="col-12 md:col-4 label md:text-right">
                            Deal Type
                        </div>
                        <div class="field col-12 md:col-8">
                            {{deal.dealType | dealPipe}}&nbsp;
                        </div>
                    </div>
                </div>
                <div class="field col-12 md:col-4">
                    <div class="grid mt-1 limit-width-large dashed-bottom">
                        <div class="col-12 md:col-4 label md:text-right">
                            Transaction Value ($m)
                        </div>
                        <div class="field col-12 md:col-8">
                            {{deal?.transactionValue==null?'-':deal?.transactionValue==0?'-':deal.transactionValue}}&nbsp;
                        </div>
                    </div>
                </div>
                <div class="field col-12 md:col-4">
                    <div class="grid mt-1 limit-width-large dashed-bottom">
                        <div class="col-12 md:col-4 label md:text-right">
                            EV/Revenue
                        </div>
                        <div class="field col-12 md:col-8">
                            {{deal?.evRev==null?'-':deal?.evRev==0?'-':deal.evRev}}&nbsp;
                        </div>
                    </div>
                </div>
                <div class="field col-12 md:col-4">
                    <div class="grid mt-1 limit-width-large dashed-bottom">
                        <div class="col-12 md:col-4 label md:text-right">
                            EV/EBITDA
                        </div>
                        <div class="field col-12 md:col-8">
                            {{deal?.evEbitda==null?'-':deal?.evEbitda==0?'-':deal.evEbitda}}&nbsp;
                        </div>
                    </div>
                </div>

                <div class="field col-12 md:col-4">
                    <div class="grid mt-1 limit-width-large dashed-bottom">
                        <div class="col-12 md:col-4 label md:text-right">
                            <div>Business Description [Target/Issuer]</div>
                        </div>
                        <div class="field col-6 md:col-8" innerHTML="{{deal.targetDescription}}">&nbsp;
                        </div>
                    </div>
                </div>
                <div class="field col-12 md:col-4">
                    <div class="grid mt-1 limit-width-large dashed-bottom">
                        <div class="col-12 md:col-4 label md:text-right">
                            <div>Notes</div>
                        </div>
                        <div class="field col-6 md:col-8" innerHTML="{{deal.notes}}">
                        </div>
                    </div>
                </div>

            </div>

            <h5>Classification</h5>
            <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-4">
                    <div class="grid mt-1 limit-width-large dashed-bottom">
                        <div class="col-12 md:col-4 label md:text-right">
                            <div>Level1</div>
                        </div>
                        <div class="field col-12 md:col-8">
                            <span *ngFor="let classification of deal.classification.level1; let last = last;"
                                [ngClass]="{'outstock' : classification.uuid ==null}">
                                {{classification.name}}{{last?'':';'}}
                            </span>&nbsp;
                        </div>
                    </div>
                </div>
                <div class="field col-12 md:col-4">
                    <div class="grid mt-1 limit-width-large dashed-bottom">
                        <div class="col-12 md:col-4 label md:text-right">
                            <div>Level2</div>
                        </div>
                        <div class="field col-12 md:col-8">
                            <span *ngFor="let classification of deal.classification.level2; let last = last;"
                                [ngClass]="{'outstock' : classification.uuid ==null}">
                                {{classification.name}}{{last?'':';'}}
                            </span>&nbsp;
                        </div>
                    </div>
                </div>
                <div class="field col-12 md:col-4">
                    <div class="grid mt-1 limit-width-large dashed-bottom">
                        <div class="col-12 md:col-4 label md:text-right">
                            <div>Level3</div>
                        </div>
                        <div class="field col-12 md:col-8">
                            <span *ngFor="let classification of deal.classification.level3; let last = last;"
                                [ngClass]="{'outstock' : classification.uuid ==null}">
                                {{classification.name}}{{last?'':';'}}
                            </span>&nbsp;
                        </div>
                    </div>
                </div>
                <div class="field col-12 md:col-4">
                    <div class="grid mt-1 limit-width-large dashed-bottom">
                        <div class="col-12 md:col-4 label md:text-right">
                            <div>Vertical/Industry</div>
                        </div>
                        <div class="field col-12 md:col-8">
                            <span *ngFor="let classification of deal.classification.vertical; let last = last;"
                                [ngClass]="{'outstock' : classification.uuid ==null}">
                                {{classification.name}}{{last?'':';'}}
                            </span>&nbsp;
                        </div>
                    </div>
                </div>
                <div class="field col-12 md:col-4">
                    <div class="grid mt-1 limit-width-large dashed-bottom">
                        <div class="col-12 md:col-4 label md:text-right">
                            <div>Ecosystem</div>
                        </div>
                        <div class="field col-12 md:col-8">
                            <span *ngFor="let classification of deal.classification.ecosystem; let last = last;"
                                [ngClass]="{'outstock' : classification.uuid ==null}">
                                {{classification.name}}{{last?'':';'}}
                            </span>&nbsp;
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-4">
            <deal-spotlight-contact contactType="deal" [parentUuid]="uuid"  ></deal-spotlight-contact></div>
        </div>


    </div>
</div>
<p-dialog [(visible)]="previewDialog" (onHide)="hideDialog()" [style]="{width: '850px'}" [header]="dialogTitle"
    [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
        <div class="col-12">
            <div class="p-fluid  grid">
                <div class="field col-12" style="margin-bottom: -26px;">
                    {{deal.spotlight.spotlightDate | date:"MM-dd-yyyy"}}
                </div>
                <div class="field col-6">
                    <img src="{{spotlight.leftLogoAttachment?spotlight.leftLogoAttachment['url']:''}}"
                        style="float: left; width: 100px; height: 100px;" />
                </div>
                <div class="field col-6">
                    <img src="{{spotlight.rightLogoAttachment?spotlight.rightLogoAttachment['url']:''}}"
                        style="float: right; width: 100px; height: 100px;" />
                </div>
                <div class="field col-12" style="text-align: center; color: #1E4E78;">
                    <h1> {{deal.spotlight.title}}</h1>
                </div>
                <div class="field col-12" innerHTML="{{spotlight.content}}"></div>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
    </ng-template>
</p-dialog>
<p-toast></p-toast>