    <form [formGroup]="form" #cform>
        <div class="formgrid grid headerSticky mb-2">
            <div class="field col-12 md:col-12 text-right" *ngIf="loadingSpinner == false">
                <p-button label="Print" icon="pi pi-print" iconPos="left" styleClass="mr-2">
                </p-button>
                <p-button label="Save" icon="pi pi-check" iconPos="left" (click)="save()" styleClass="mr-2">
                </p-button>
                <p-button label="Cancel" icon="pi pi-times" iconPos="left" (click)="cancel()"
                    styleClass="p-button-secondary"></p-button>
            </div>
            <div class="field col-12 md:col-12 text-right" *ngIf="loadingSpinner">
                <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
                    fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
            </div>
        </div>
        <div style="border: 2px solid #000; padding: 30px;">
            <div class="grid p-fluid mt-1">
                <div class="field col-12 md:col-11">
                    <div class="grid p-fluid">
                        <div class="field col-12">
                            <span class="p-float-label" style="font-size: 15px; font-weight: bold; color: black;">
                                <textarea pInputTextarea rows="1" [autoResize]="true" maxlength="500" formControlName="title" 
                                    [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.title.errors }"></textarea>
                                <label for="title">Title<span class="mandate">*</span></label>
                            </span>
                            <span class="p-error" *ngIf="submitted && form.get('title').hasError('required')">Title is Required</span>
                            <span class="p-error" *ngIf="f.title.invalid && f.title.hasError('message')">{{f.title.errors.message}}</span>
                            <hr style="border-top: 2px solid #000; margin: 0;">
                        </div>
                        <div class="field col-12">
                            <span class="p-float-label" style="font-size: 15px; font-weight: bold; color: black;">
                                <textarea pInputTextarea rows="1" [autoResize]="true" maxlength="1000" formControlName="headline"></textarea>
                                <label>Headline</label>
                            </span>
                        </div>
                    </div>

                </div>
                <div class="field col-12 md:col-1">
                    <p-image src="assets/layout/images/tbp-logo-grey.jpg" width="100"></p-image>
                </div>
            </div>
            <div class="grid p-fluid mt-1">
                <div class="field col-12 md:col-5">
                    <h2 style="text-align: left; font-style: italic;">Offerings</h2>
                    <hr style="height:2px;background-color:black">
                    <div class="field col-6 md:col-12">
                        <h4>Services</h4>
                        <div class="field col-6 md:col-12">
                            <editor formControlName="services"></editor>
                        </div>
                    </div>
                    <div class="field col-6 md:col-12">
                        <h4>Customers</h4>
                        <div class="field col-6 md:col-12">
                            <editor formControlName="customers"></editor>
                        </div>
                    </div>
                    <div class="field col-6 md:col-12">
                        <h4>Business</h4>
                        <div class="field col-6 md:col-12">
                            <editor formControlName="business"></editor>
                        </div>
                    </div>
                </div>
                <div class="field col-12 md:col-2">
                    <p-divider layout="vertical" type="dashed"></p-divider>
                </div>
                <div class="field col-12 md:col-5">
                    <h2 style="text-align: left; font-style: italic;">Financials</h2>
                    <hr style="height:2px;background-color:black">
                    <p-table [value]="financials" dataKey="id"  [scrollable]="true"  scrollHeight="400px" scrollDirection="both">
                        <ng-template pTemplate="caption">
                            <div class="p-inputgroup" style="width: 200px;">
                                <input formControlName="financialYear" pInputText type="text" />
                                <p-button label="Year" icon="pi pi-plus" iconPos="left" (click)="addYear()" styleClass="mr-2"></p-button>  
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <ng-container *ngFor="let year of years; let i = index">
                                    <th  style="width:150px" pFrozenColumn *ngIf="year == 'dummy'"></th>
                                    <th  style="width:150px" *ngIf="year != 'dummy'">
                                        {{year}} <a href="#" style="margin-left: 5px;"><i class="pi pi-eye-slash"></i></a>
                                    </th>
                                </ng-container>
                                
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-financial>
                            <tr>
                                <ng-container *ngFor="let year of years; let i = index">
                                    <td *ngIf="year == 'dummy'" style="width:150px" pFrozenColumn>
                                        {{financial[year]}}
                                    </td>
                                    <td *ngIf="year != 'dummy'" style="width:150px">
                                        <input pInputText type="text" [(ngModel)]="financial[year]"  [ngModelOptions]="{standalone: true}" >
                                    </td>
                                </ng-container>
                            </tr>
                        </ng-template>
                    </p-table>
                    &nbsp;
                    <div class="field col-6 md:col-12">
                            <h4>Investment Considerations</h4>
                            <div class="field col-6 md:col-12">
                                <editor formControlName="investmentConsiderations"></editor>
                            </div>
                    </div>
                </div>
            </div>


            <div class="grid p-fluid mt-1">
                <div class="field col-12 md:col-4" style="text-align: right;">
                    <span>&nbsp;</span>
                </div>
                <div class="field col-12 md:col-4" style="text-align: center;">
                    <span>Confidential</span>
                </div>
                <div class="field col-12 md:col-4" style="text-align: right;">
                    <span>&copy; 2021 True Blue Partners All rights reserved.</span>
                </div>
            </div>
        </div>
    </form>
<p-toast></p-toast>
