import { AppUtil } from './../_helpers/app.util';
import { AccountService } from './../_services/account.service';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { NoteService } from './note.service';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import * as mimeType from 'mime'
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { SellsideService } from './../sellside/sellside.service';
import { BuysideService } from './../buyside/buyside.service';
import { EngagementService } from './../engagement/engagement.service';
import { User } from '../_models';
@Component({
    selector: 'note-edit',
    templateUrl: './note-edit.component.html',
    styleUrls: ['./note-edit.component.scss'],
    providers: [MessageService]
})
export class NoteEditComponent implements OnInit {

    @Input() show: boolean = false;
    @Output() showChange = new EventEmitter<boolean>();
    @Input() noteUuid: string = null;
    @Output() noteUuidChange = new EventEmitter<string>();
    @Input() parent = null;
    @Input() closeAfterSave: boolean = true;
    @Input() noteType: string = null; /* organization, contact */
    @Output() afterSave = new EventEmitter<any>();
    @Output() newContactCreated = new EventEmitter<any>();
    @Input() prospectContact: User = null;
    formInitialValue: any = null;
    @ViewChild('noteForm') formElement;
    title: string = "New Task";
    form: UntypedFormGroup;
    submitted: boolean = false;
    uploadURL: string;
    uploadedFiles: any[] = [];
    engagementResults: [];
    buySideResults: [];
    sellSideResults: [];
    loading: boolean;
    constructor(
        private route: ActivatedRoute,
        private messageService: MessageService,
        private formBuilder: UntypedFormBuilder,
        private noteService: NoteService,
        public accountService: AccountService,
        private engagementService: EngagementService,
        private buySideService: BuysideService,
        private sellSideService: SellsideService,
        private http: HttpClient,
        public appUtil:AppUtil) {
        this.uploadURL = environment.apiUrl + '/upload/organization_notes'
        this.form = this.formBuilder.group({
            uuid: [],
            notes: [],
            attachment: [],
            contacts: [],
            engagement: [],
            organization: [],
            buysideScreen: [],
            sellsideScreen: []
        })
        this.formInitialValue = this.form.value;
    }

    ngOnInit(): void {

    }

    ngOnChanges(changes: SimpleChanges) {
        this.form.reset(this.formInitialValue);
        if (this.show) {
            if (this.noteUuid == null || this.noteUuid == '') {
                this.title = "New Note";
                if (this.noteType === "organization")
                    this.f.organization.setValue(this.parent) 
                else if (this.noteType === "contact"){
                    this.f.contacts.setValue([this.parent]);
                    const obj = this.parent;
                    // @ts-ignore: Object is possibly 'null'.
                   this.f.organization.setValue(((obj == null)?null:obj.organization));  
                }
                else if (this.noteType === "sellside"){
                    this.f.sellsideScreen.setValue(this.parent)
                }
                else if (this.noteType === "buyside"){

                }
                else if (this.noteType === "engagement"){
                    this.f.engagement.setValue(this.parent)
                }
                else if(this.noteType ==="buyside-prospects"){
                    this.form.controls.buysideScreen.setValue(this.parent.buyside);
                    this.f.buysideScreen.disable();
                    this.f.contacts.setValue([this.prospectContact]);
                    this.f.contacts.disable();
                    const obj = this.parent;
                    // @ts-ignore: Object is possibly 'null'.
                   this.f.organization.setValue(((obj == null)?null:obj.organization));  
                   this.f.organization.disable();
                }
                else if(this.noteType ==="sellside-prospects"){
                    this.f.sellsideScreen.setValue(this.parent.sellside)
                    this.f.sellsideScreen.disable();
                    this.f.contacts.setValue([this.prospectContact]);
                    this.f.contacts.disable();
                    const obj = this.parent;
                    // @ts-ignore: Object is possibly 'null'.
                   this.f.organization.setValue(((obj == null)?null:obj.organization));  
                   this.f.organization.disable();
                }
                    
            }
            else {
                this.title = "Edit Note";
                this.noteService.getNote(this.noteUuid).subscribe(data => {
                    let response = data as any;
                    this.f.notes.setValue(response.notes);
                    this.f.uuid.setValue(response.uuid);
                    this.f.contacts.setValue(response.contacts);
                    this.f.engagement.setValue(response.engagement);
                    this.f.organization.setValue(response.organization);
                    this.f.buysideScreen.setValue(response.buysideScreen);
                    this.f.sellsideScreen.setValue(response.sellsideScreen);
                    if (response.attachment != null) {
                        this.f.attachment.setValue(response.attachment);
                        this.uploadedFiles = [response.attachment];
                    }
                    else
                        this.uploadedFiles = [];
                });
            }
        }
    }

    get f() { return this.form.controls; }

    onUpload(event) {
        this.f.attachment.setValue(event.originalEvent.body);
        this.uploadedFiles = [event.originalEvent.body];
    }

    deleteDocument(event) {
        // Prevent default behavior when clicking a link
        event.preventDefault();
        this.f.attachment.setValue(null);
        this.uploadedFiles = [];
    }
    openDocument(event) {
        // Prevent default behavior when clicking a link
        event.preventDefault();

        // Get filename from href
        var filename = event.target.href;
        this.http.get(filename, { responseType: 'arraybuffer' }).subscribe(
            data => {
                var file = new Blob([data], { type: mimeType.getType(filename) });
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            });
    }

    saveNote() {
        this.loading = true;
        this.submitted = true;
        if (this.form.invalid) {
            return;
        }
        if(this.f.notes.value == ""){
            this.f.notes.setValue(null);
        }
        if (this.f.notes.value == null && this.f.attachment.value == null ) {
            this.messageService.add({ severity: 'error', summary: 'Form Errors', detail: "Please either fill the notes or attach an attachment", life: 3000 });
            this.loading = false;
            return;
        }
        if ((this.f.organization.value == null || this.f.organization.value == '') && this.f.engagement.value == null &&
            this.f.buysideScreen.value == null && this.f.sellsideScreen.value == null && (this.f.contacts.value == null || (this.f.contacts.value).length == 0)) {
            this.messageService.add({ severity: 'error', summary: 'Form Errors', detail: "Please associate note with any of the one item", life: 3000 });
            this.loading = false;
            return;
        }
        let { uuid, notes, attachment, engagement, organization, buysideScreen, sellsideScreen, contacts } = this.form.value;
        let dataToPost: any = { uuid, notes, attachment, engagement, organization, buysideScreen, sellsideScreen, contacts };
        if (this.f.attachment != null && this.f.attachment.value != null) {
            dataToPost['attachment'] = { uuid: this.f.attachment.value.uuid };
        }
        if (this.f.organization.value == '') {
            dataToPost['organization'] = null;
        }
        if (this.f.buysideScreen.value != null ) {
            dataToPost['buysideScreen'] = this.f.buysideScreen.value;
        }
        if (this.f.sellsideScreen.value != null) {
            dataToPost['sellsideScreen'] = this.f.sellsideScreen.value;
        }
        if (this.f.organization.value != null) {
            dataToPost['organization'] = this.f.organization.value;
        }
        if (this.f.contacts != null) {
            dataToPost['contacts'] = this.f.contacts.value;
        }
        let apiToCall: any;
        if (this.f.uuid.value == null) {
            apiToCall = this.noteService.addNote(dataToPost);
        }
        else {
            apiToCall = this.noteService.updateNote(this.f.uuid.value, dataToPost);
        }
        apiToCall.subscribe(
            data => {
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: "Save Successfully", life: 3000 });
                this.submitted = false;
                this.afterSave.emit(data);
                this.loading = false;
                if (this.closeAfterSave) {
                    this.hide();
                }
            },
            error => {
                this.messageService.add({ severity: 'error', summary: 'Form Errors', detail: error.error.subErrors[0].message, life: 3000 });
                this.loading = false;
            }
        );

    }
    hide() {
        this.form.reset(this.formInitialValue);
        this.uploadedFiles = [];
        this.submitted = false;
        this.show = false;
        this.showChange.emit(false);
        this.noteUuid = null;
        this.noteUuidChange.emit(null);
        this.loading = false;
    }

    searchEngagements(event) {
        this.engagementService.lookupEngagement(event.query).subscribe(data => {
            this.engagementResults = data as any;
        });
    }

    searchBuySide(event) {
        this.buySideService.lookupBuySide(event.query).subscribe(data => {
            this.buySideResults = data as any;
        });
    }

    searchSellSide(event) {
        this.sellSideService.lookupSellSide(event.query).subscribe(data => {
            this.sellSideResults = data as any;
        });
    }


}
