import { DataService } from './../_services/data.service';
import { SpotlightService } from './spotlight.service';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';


@Injectable()
export class SpotlightDeal implements Resolve<any> {

    constructor(private spotlightService: SpotlightService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        return this.spotlightService.getSpotlightDeal(route.params.uuid);
    }
}