import { Organization } from ".";
import { EntityClassification } from "./entity-classification";
import { LookupValue } from "./lookup-value";
import { Spotlight } from "./spotlight";
import { User } from "./user";
export class Deal {

    constructor() {
        this.classification = new EntityClassification;
    }

    uuid?: string;
    dealDate?: string;
    dealType: string;
    buyerOrganizations?: Organization[];
    targetOrganization?: Organization;
    transactionValue?: string;
    evRev?: string;
    evEbitda?: string;
    targetDescription?: string;
    classification: EntityClassification;
    notes?: string;
    spotlight?: Spotlight;
    createByUser?: User;
    createdDate?: User;
    modifiedByUser?: User;
    lastModifiedDate?: User;

}