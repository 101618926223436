import { TbpAutocompleteModule } from './../_components/tbp-autocomplete/tbp-autocomplete.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ToolbarModule } from 'primeng/toolbar';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import { MenuModule } from 'primeng/menu';
import { SplitButtonModule } from 'primeng/splitbutton';
import { SharedModule } from '../shared/shared.module';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CalendarModule } from 'primeng/calendar';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { EngagementAddComponent } from './engagement.add.component';
import { EngagementListComponent } from './engagement.list.component';
import { EngagementViewComponent } from './engagement.view.component';
import { EngagementRoutingModule } from './engagement-routing.module';
import { EngagementBlindprofileComponent } from './engagement.blindprofile.component';
import { NoteModule } from './../note/note.module';
import { AccountPopupModule } from './../account-popup/account-popup.module';
import { TooltipModule } from 'primeng/tooltip';
import { DividerModule } from 'primeng/divider';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
@NgModule({
    declarations: [
        EngagementAddComponent,
        EngagementListComponent,
        EngagementViewComponent,
        EngagementBlindprofileComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        TableModule,
        OverlayPanelModule,
        ToolbarModule,
        InputTextModule,
        DropdownModule,
        InputTextareaModule,
        AutoCompleteModule,
        MultiSelectModule,
        PanelModule,
        MenuModule,
        ToastModule,
        SplitButtonModule,
        SharedModule,
        ProgressSpinnerModule,
        CalendarModule,
        EditorModule,
        TabViewModule,
        MessageModule,
        MessagesModule,
        DialogModule,
        ConfirmDialogModule,
        FileUploadModule,
        EngagementRoutingModule,
        NoteModule,
        AccountPopupModule,
        TooltipModule,
        DividerModule,
        TbpAutocompleteModule
    ],
    providers: [
        { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
    ]
})
export class EngagementModule { }
