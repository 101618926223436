import { NgModule } from '@angular/core';
import { UserResolver } from './../users/user.resolver';
import { Routes, RouterModule, UrlSegment } from '@angular/router';
import { NoteListComponent } from './note-list.component';

const routes: Routes = [
    {
        matcher: (url) => {
            if ( (url.length >=3 && url[2].path == 'notes') && url.length <=4) {
                return {
                    consumed: url,
                    posParams: {
                        showListing: new UrlSegment('true', {})
                    }
                };
            }
            return null;
        },
        component: NoteListComponent,
        resolve: {
            tbpEmployees: UserResolver,
        }
    }
    ];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: []
})
export class NoteRoutingModule { }
