import { MediaModule } from './../media/media.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ToolbarModule } from 'primeng/toolbar';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SplitButtonModule } from 'primeng/splitbutton';
import { MenuModule } from 'primeng/menu';
import { CheckboxModule } from 'primeng/checkbox';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { SpotlightRoutingModule } from './spotlight-routing.module';
import { SpotlightAddComponent } from './spotlight.add.component';
import { SpotlightListComponent } from './spotlight.list.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ToastModule } from 'primeng/toast';
import { SpotlightViewComponent } from './spotlight.view.component';
import { DialogModule } from 'primeng/dialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DealSpotlightContactModule } from './../deal-spotlight-contact/deal-spotlight-contact.module';
import { AccountPopupModule } from './../account-popup/account-popup.module';
import { TbpQueryBuilderModule } from '../_components/tbp-query-builder/tbp-query-builder.module';
import { TreeModule } from 'primeng/tree';
import { DividerModule } from "primeng/divider";
import { TbpAutocompleteModule } from '../_components/tbp-autocomplete/tbp-autocomplete.module';
import { PanelModule } from 'primeng/panel';
import { SharedModule } from '../shared/shared.module';
import { RadioButtonModule } from 'primeng/radiobutton';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { SpotlightPreviewComponent } from './spotlight.preview.component';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';


@NgModule({
    declarations: [SpotlightAddComponent, SpotlightListComponent, SpotlightViewComponent, SpotlightPreviewComponent],
    imports: [
        ReactiveFormsModule,
        AutoCompleteModule,
        CommonModule,
        TableModule,
        ButtonModule,
        OverlayPanelModule,
        ToolbarModule,
        FileUploadModule,
        InputTextModule,
        DropdownModule,
        InputTextareaModule,
        CheckboxModule,
        SplitButtonModule,
        MenuModule,
        SpotlightRoutingModule,
        CalendarModule,
        MultiSelectModule,
        ToastModule,
        DialogModule,
        FormsModule,
        ProgressSpinnerModule,
        DealSpotlightContactModule,
        AccountPopupModule,
        TbpQueryBuilderModule,
        TreeModule,
        DividerModule,
        TbpAutocompleteModule,
        PanelModule,
        SharedModule,
        RadioButtonModule,
        EditorModule,
        MediaModule,
        TooltipModule,
        ConfirmDialogModule
        
    ],
    providers: [
        { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
    ]
})
export class SpotlightModule { }
