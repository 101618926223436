import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';


@Component({
  selector: 'tbp-column-chooser',
  templateUrl: './tbp-column-chooser.component.html',
  styleUrls: ['./tbp-column-chooser.component.css']
})
export class TbpColumnChooserComponent implements OnInit{

    @Input() availableColumns:any[] = [];
    @Input() selectedColumns:any[] = null;

    @Output() selectedColumnsChange: EventEmitter<any> = new EventEmitter();

    constructor() { }

    ngOnInit() {
    }

    
    ngOnChanges(changes: SimpleChanges) {
        if(changes.hasOwnProperty('selectedColumns'))
        {
            if( !this.selectedColumns || this.selectedColumns == null || this.selectedColumns.length == 0)
            {
                this.selectedColumns = this.availableColumns.filter(col => (col.visible == "A" || col.visible == "T"));
                this.selectedColumnsChange.emit(this.selectedColumns);
            }
        }
    }



    @Input() get innerSelectedColumns(): any[] {
        return this.selectedColumns;
    }
    
    set innerSelectedColumns(val: any[]) {
        //restore original order
        this.selectedColumns = this.availableColumns.filter(col => val.includes(col));
        this.selectedColumnsChange.emit(this.selectedColumns);
    }
}
