import { Spotlight } from './../_models/spotlight';
import { AppUtil } from './../_helpers';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from '../app.breadcrumb.service';
import { SpotlightService } from "../spotlight/spotlight.service";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Location } from '@angular/common';
import { DataService } from '../_services/data.service';
import { MediaService } from './../media/media.service';


@Component({
    selector: 'app-spotlight-add',
    templateUrl: './spotlight.add.component.html',
    styleUrls: ['./spotlight.add.component.scss'],
    providers: [MessageService]
})
export class SpotlightAddComponent implements OnInit {

    @ViewChild('cform') formElement;
    @ViewChild('editor') _editor;
    form: UntypedFormGroup;
    submitted = false;
    status = [{ code: 'ARCHIVED', name: 'Archived' }, { code: 'PUBLISHED', name: 'Published' }, { code: 'DRAFT', name: 'Draft' }];
    deals: [];
    spotKey: string = null;
    isEdit: boolean = false;
    lookupList: any = { DEAL_TYPE: [] };
    tbpEmployees = [];
    previewDialog: boolean;
    dialogTitle: string;
    loading: boolean = false;
    logoType: string;
    leftLogoUrl: string;
    rightLogoUrl: string;
    showMediaLookupDialog: boolean = false;
    mediaLookupDialogTitle: string;
    spotlight: any;
    helpList: any = {SPOTLIGHT:[]};
    sampleContent:string = '<h4>Deal Financials</h4><ul><li>Transaction Value: $350 M</li><li>Enterprise Value / Revenue: 3.3x</li><li>Enterprise Value / EBITDA: 87.7x</li></ul>'
        + '<h4>Transaction Overview</h4><ul>'                    
        + '<li>On February 9, 2022, HelpSystems announced it would acquire Tripwire, Inc, from Belden Inc. (NYSE: BDC) for $350 million. Belden had originally acquired TripWire from Thoma Bravo for $710 million in December 2014. Thoma Bravo had acquired Tripwire in 2011 after Tripwire, filed to raise $86M through an IPO in 2010.</li>'
        + '<li>Tripwire is a Portland-based software company that specializes in cybersecurity and compliance solutions for enterprise service providers, and government agencies.</li>'
        + '<li>HelpSystems improves upon their existing IT security service namely by passing on Tripwire’s file integrity monitoring benefits to existing global customers.</li>'
        + '</ul><h4>TBP’s Analysis of Growth Drivers in Cybersecurity</h4><ul>'
        + '<li>The rapid implementation of remote work during the pandemic resulted in mass data consolidation susceptible to breaches, with targeted companies having technical and architectural vulnerabilities.</li>'
        + '<li>The number of major breaches compromising the security, confidentiality, and integrity of sensitive company data increased year-over-year in every major non-Military sector; more than doubling in Financial Services, Education, Transportation, and Manufacturing &amp; Utilities</li>'
        + '<li>The average cost for resolving data breaches rose from $3.86M in 2020 to $4.24M in 2021, with global cybercrime costs increasing from $3T in 2015 to $6T in 2021. (Source: <a href="https://cisomag.eccouncil.org/cybercrime-will-cost-the-world-us6-trillion-by-the-end-of-the-year-study/" rel="noopener" target="_blank">CISO Mag</a>)</li>'
        + '<li>Comprehensive state privacy laws are increasingly reaching their effective dates, requiring the implementation of reasonable data security measures in for-profit entities.</li>'
        + '<li>As a result of increasing costs to business and productivity, the global cybersecurity industry is anticipated to grow at a compound annual growth rate of 12% from $165.8 B to $336.1 B by 2028. (Source: <a href="https://www.globenewswire.com/news-release/2021/06/08/2243589/0/en/Cyber-Security-Market-to-Exhibit-Stellar-12-0-CAGR-by-2028-Backed-by-Increasing-Investment-in-Development-of-IT-Security-Solutions-in-Europe.html" rel="noopener" target="_blank">Fortune Business Insights</a>)</li>'
        + '</ul><h4>TBP’s Analysis of HelpSystems Growth via Acquisition</h4><p>In recent acquisitions, HelpSystems has obtained unique players in the cybersecurity space to integrate numerous security and protection services into their existing B2B product:</p><ul>'                 
        + '<li>Digital Defense (Feb 2021), a leader in vulnerability management and threat assessment solutions.</li>'
        + '<li>Beyond Security (May 2021), network vulnerability scanning software.</li>'
        + '<li>Agari Data (May 2021), is a leader in protecting against email threats and compromise.</li>'
        + '<li>Phish Labs (Oct 2021), cyber threat intelligence company mitigating digital risks.</li>'
        + '<li>Digital Guardian (Oct 2021), SaaS provider of data loss prevention for mid &amp; large-sized organizations.</li>'
        + '</ul><h4>TBP’s Analysis of Strategic and Financial Sponsors Interest in Cybersecurity Space</h4><ul>'
        + '<li>The deal volume in Cybersecurity M&amp;A increased at record rates in 2021, with $77.8 billion in the calendar year, a 338% increase from 2020. (Source: <a href="https://techcrunch.com/2022/02/09/vc-cybersecurity-startups-record-year/" rel="noopener" target="_blank">TechCrunch</a>)</li>'
        + '<li>PE+VC investment also increased in the sector from $10.7B to $29.3B in the aforementioned period.</li>'
        + '<li>TBP anticipates a continuation of this trend in 2022, with M&amp;A in the sector remaining very active to begin the year.</li>'
        + '<li>Given the sector’s expanding demand and potential use case, target valuations have increased with the rise in deal volume.</li>'
        + '</ul><h4>Relevant M&A Transactions Within Cybersecurity Space in 2022</h4><img src="/assets/layout/images/t1.jpg" />'
        + '<div class="row mt-3"><div class="col"><div style="color: #555; font-size: 14px;" class="mb-3"><strong><em>About True Blue Partners</em></strong><br>'
        + '<em>True Blue Partners is a boutique M&amp;A advisory firm that serves lower mid-market enterprise software, service and solutions companies. It brings the rigorous strategic and financial perspective of bulge bracket firms with a company building approach and a steadfast partnership that only an independent boutique firm can deliver. For more information please visit <a href="https://truebluepartners.com">www.truebluepartners.com</a> or email us at <a href="mailto:info@truebluepartners.com">info@truebluepartners.com</a>.</em></div>'
        + '<h4 style="color: #f33f21; text-align: center;"><strong>TBP was NOT a financial advisor on this transaction.</strong></h4></div>';

    constructor(
        public appUtil: AppUtil,
        private dataService: DataService,
        private _location: Location,
        private breadcrumbService: BreadcrumbService,
        private router: Router,
        private spotlightService: SpotlightService,
        private formBuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private mediaService: MediaService,
        private messageService: MessageService) {

        if (this.router.url.indexOf("/edit/") != -1) {
            this.isEdit = true;
        }
        if (this.isEdit) {
            this.breadcrumbService.setItems([
                { label: 'Edit Spotlight' }
            ]);
        } else {
            this.breadcrumbService.setItems([
                { label: 'New Spotlight' }
            ]);
        }

    }

    ngOnInit(): void {
        this.lookupList = this.route.snapshot.data['lookupList'];
        this.tbpEmployees = this.route.snapshot.data['tbpEmployees'];
        this.helpList = this.route.snapshot.data['helpList']['SPOTLIGHT'];

        this.previewDialog = false;
        if (this.isEdit && this.router.url.indexOf("?spot") == -1) {
            this.spotlightService.getSpotlight(this.route.snapshot.params.uuid).subscribe(data => {
                let datas = data as any;
                datas.deals = this.route.snapshot.data['spotlightDeal'];
                this.modelToForm(datas as any);
            });
        }
        this.loadSpotLightForm();
        if (this.route.snapshot.queryParamMap.get('spot') != null) {
            this.spotKey = this.route.snapshot.queryParamMap.get('spot');
            if (sessionStorage.getItem(this.spotKey) != null) {
                let localStorageobj = JSON.parse(sessionStorage.getItem(this.spotKey));
                this.modelToForm(localStorageobj.spotlight);
            }
        }
        this.appUtil.reinitializeShowMore();
    }

    loadSpotLightForm() {
        this.form = this.formBuilder.group({
            uuid: [''],
            title: ['', Validators.required],
            spotlightDate: ['', Validators.required],
            status: ['DRAFT'],
            urlKey: ['', Validators.pattern("[a-z0-9-]+")],
            organizations: ['', Validators.required],
            metaKeywords: [],
            deals: [],
            metaDescription: [],
            content: [this.sampleContent],
            rightLogo: [null, [Validators.required]],
            leftLogo: [null, [Validators.required]],
            primaryDeal: [],
        })
    }


    modelToForm(model) {
        if (model.rightLogo != null && model.rightLogo != undefined)
            this.rightLogoUrl = model.rightLogo.url;
        if (model.leftLogo != null && model.leftLogo != undefined)
            this.leftLogoUrl = model.leftLogo.url;
        this.deals = model.deals;
        model.spotlightDate = this.appUtil.DbToCalendarDateFormat(model.spotlightDate);
        this.form.setValue({
            uuid: model.uuid,
            title: model.title,
            urlKey: model.urlKey,
            deals: model.deals,
            metaKeywords: model.metaKeywords,
            metaDescription: model.metaDescription,
            spotlightDate: (model.spotlightDate == null) ? null : model.spotlightDate.split('T')[0],
            status: model.status,
            organizations: model.organizations,
            content: model.content,
            primaryDeal: model.primaryDeal ? model.primaryDeal.uuid : null,
            rightLogo: (model.rightLogo == null) ? null : model.rightLogo.uuid,
            leftLogo: (model.leftLogo == null) ? null : model.leftLogo.uuid,
        });
    }

    get f() { return this.form.controls; }

    save() {
        this.submitted = true;
        let ctnt:string = this._editor.editor.getContent({ format: 'text' });
        if(ctnt == null || ctnt.trim() == '')
        {
            this.f.content.markAsTouched();
            this.f.content.setErrors({'required':true})
        }
        if (this.form.invalid) {
            this.handleError(null);
            return;
        }
        this.loading = true;
        let { uuid, title, primaryDeal, urlKey, metaDescription, metaKeywords, spotlightDate, status, content, rightLogo, leftLogo } = this.form.value;
        let organizations = [];
        if (this.f.organizations.value != null) {
            this.f.organizations.value.forEach(function (currentValue) {
                organizations.push(currentValue.uuid);
            }, organizations)
        }
        let deals = [];
        if (this.f.deals.value != null) {
            this.f.deals.value.forEach(function (currentValue) {
                deals.push(currentValue.uuid);
            }, deals)
        }

        let dataToPost: any = { uuid, deals, title, primaryDeal, urlKey, metaDescription, metaKeywords, spotlightDate, status, organizations, content, rightLogo, leftLogo };


        dataToPost['spotlightDate'] = this.appUtil.calendarToDbDateFormat(this.f.spotlightDate.value);
        let apiToCall: any;

        if (this.isEdit) {
            apiToCall = this.spotlightService.updateSpotlight(dataToPost);
        }
        else {
            apiToCall = this.spotlightService.addSpotlight(dataToPost);
        }

        apiToCall.subscribe(
            data => {
                localStorage.removeItem(this.spotKey);
                let spotlight = data as any;
                let detail = 'Spotlight Created';
                if (this.isEdit) {
                    detail = 'Spotlight Updated';
                }
                this.dataService.setData('message', detail);
                this.router.navigate(['/spotlight/' + spotlight.uuid]);
                this.submitted = false;
            },
            error => {
                this.loading = false;
                let errors = error.error;
                if (errors.code && errors.code == 'VALIDATION_ERROR') {
                    for (var i = 0; i < errors.subErrors.length; i++) {
                        this.f[errors.subErrors[i].field].setErrors({ 'message': errors.subErrors[i].message });
                        if (errors.subErrors[i].field == "urlKey") {
                            this.f.urlKey.setValue(errors.subErrors[i].rejectedValue);
                            this.f.urlKey.setErrors({ '.ng-invalid': true, 'message': errors.subErrors[i].message });
                        }
                    }
                    this.handleError(errors.subErrors[0].field);
                }
            }
        );
    }

    handleError(elemId) {
        this.messageService.add({ severity: 'error', summary: 'Form Errors', detail: "Form has errors. Please fix them first", life: 3000 });
        let invalidControl = this.formElement.nativeElement.querySelector('.ng-invalid');
        if (invalidControl) {
            invalidControl.focus();
        }
        else if (elemId != null) {
            invalidControl = this.formElement.nativeElement.querySelector('#' + elemId);
            if (invalidControl) {
                invalidControl.focus();
            }
        }
    }

    preview() {
        this.spotlight = { spotlightDate: this.f.spotlightDate.value, content: this.f.content.value, leftLogo: { url: this.leftLogoUrl }, rightLogo: { url: this.rightLogoUrl }, title: this.f.title.value };
        this.previewDialog = true;
    }

    hideDialog() {
        this.previewDialog = false;
    }

    cancel() {
        this._location.back();
    }


    public onFormGroupChangeEvent(_event) {
        this.f.organizations.setValue([_event]);
    }

    lookupMedia(logoType) {

        this.showMediaLookupDialog = true;
        this.logoType = logoType;
        if (this.logoType == 'left') {
            this.mediaLookupDialogTitle = "Select Left Logo";
        }
        else if (this.logoType == 'right') {
            this.mediaLookupDialogTitle = "Select right Logo";
        }
        else if (this.logoType == 'other') {
            this.mediaLookupDialogTitle = "Select content";
        }

    }

    mediaSelected(mediaItem) {
        if (this.logoType == 'left') {
            this.leftLogoUrl = mediaItem.url;
            this.f.leftLogo.setValue(mediaItem.uuid);
        }
        else if (this.logoType == 'right') {
            this.rightLogoUrl = mediaItem.url;
            this.f.rightLogo.setValue(mediaItem.uuid);
        }
        else if (this.logoType == 'other') {
            this._editor._editor.insertContent('<img src="' + mediaItem.url + '" />');
        }
    }

    addDeal() {
        let formData = this.form.value;
        formData.leftLogo = { uuid: formData.leftLogo, url: this.leftLogoUrl };
        formData.rightLogo = { uuid: formData.rightLogo, url: this.rightLogoUrl };
        formData.primaryDeal = { uuid: formData.primaryDeal };
        let spot = {
            uuid: 'spot-' + new Date().getTime(),
            spotlight: formData
        };
        sessionStorage.setItem(spot.uuid, JSON.stringify(spot));
        this.router.navigate(['spotlight/'+spot.uuid+'/deals/showCheckbox'], { queryParams: { spot: spot.uuid } });
    }

    getInitData() {
        return {
            height: 500,
            width: 950,
            theme_advanced_resizing_max_width: 950,
            menubar: true,
            body_class: "spotlight-preview",
            content_css: [
                "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css",
                "/assets/layout/css/app.css"
            ],
            plugins: [
                'a11ychecker','advlist','advcode','advtable','autolink','checklist','markdown',
                'lists','link','image','charmap','anchor','searchreplace','visualblocks',
                'powerpaste','fullscreen','formatpainter','insertdatetime','media','table','help','wordcount'
              ],
              toolbar:
               'undo redo | casechange blocks | bold italic backcolor | \
               alignleft aligncenter alignright alignjustify | \
               bullist numlist checklist outdent indent | removeformat | a11ycheck code table help'
            }
    }

    getHelpText(key) {
        let test = this.helpList.filter(function (element) {
            return element.key == key;
        });
        if (test[0] != undefined)
            return test[0]['text'];
    }


}

