<div class="grid table-demo">
    <div class="col-12">
        <div class="card">
            <p-toolbar styleClass="headerSticky"> 
                <div class="p-toolbar-group-left">
                    <div class="grid">
                        <div class="col-12  p-fluid">
                            <span class="p-input-icon-right srchAccnt">
                                <input [(ngModel)]="term" pInputText type="text" placeholder="Search"
                                    class="table-search" (keyup.enter)="search(0)" />
                               <!-- <i class="pi pi-sliders-h" (click)="advsrch.toggle($event)"></i> -->
                            </span>
                        </div>
                    </div>
                </div>

                <div class="p-toolbar-group-right" *ngIf="role == 1">
                    <button pButton pRipple label="Back" icon="pi pi-arrow-left" class="p-button-success mr-2" (click)="appUtil.return()"></button>
                    <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success mr-2"
                        (click)="openNew()"></button>
                </div>
            </p-toolbar>
            <div class="p-fluid  grid" *ngIf="currentQuery">
                <div class="col-12">
                    <strong>Current Query:</strong> {{isAdvanceSearch?currentQuery:('matches ' +currentQuery)}}
                </div>
            </div>
            <p-table [autoLayout]="true" [value]="spotlights" [(selection)]="selectedSpotlights" dataKey="uuid"
                            [lazy]="true" (onLazyLoad)="loadData($event)" [lazyLoadOnInit]="false"
                           styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm" [rowHover]="true"
                            [rows]="pageSize" [first]="firstRow" [paginator]="true" [rowsPerPageOptions]="[15,25,50,75,100]"
                          [loading]="loading" [alwaysShowPaginator]="true" [showCurrentPageReport]="true"
                          [showPageLinks]="appUtil.getPagination(totalRecords)" [showFirstLastIcon] = "appUtil.getPagination(totalRecords)"
                          currentPageReportTemplate="Showing {first} to {last} of {{appUtil.getPagination(totalRecords) ? totalRecords :'many'}} entries"
                            [totalRecords]="totalRecords" [sortField]="sortField" [sortOrder]="sortOrder">
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="spotlightDate">Date
                            <p-sortIcon field="spotlightDate"></p-sortIcon>
                        </th>
                        <th pSortableColumn="title">Title
                            <p-sortIcon field="title"></p-sortIcon>
                        </th>
                        <th pSortableColumn="status">Status
                            <p-sortIcon field="status"></p-sortIcon>
                        </th>
                        <th>Companies Involved</th>
                        <th>Action</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-spotlight>
                    <tr class="p-selectable-row">
                        <td class="wrap">
                            <span class="p-column-title">Date</span>
                            {{spotlight.spotlightDate | date:'MM-dd-yyyy'}}
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Title</span>
                            <a [routerLink]="['/spotlight/', spotlight.uuid]" [innerHTML]="spotlight.title | highlight:term"></a>
                        </td>
                        <td class="wrap">
                            <span class="p-column-title">Status</span>
                            {{spotlight.status | titlecase}}
                        </td>
                        <td>
                            <span class="p-column-title">Companies Involved</span>
                            <span class="limitedText limitText">
                                <span *ngFor="let organizations of spotlight.organizations; let last = last;"><a
                                        [routerLink]="['/organization/', organizations.uuid]" [innerHTML]="organizations.name | highlight:term" ></a>{{last?'':';'}}</span>
                            </span>
                            <a href="javascript:" (click)="appUtil.showHideClampedContent($event)"
                                class="moreless hidden ml-2 p-button-danger"
                                style="float:right;display:block;">More</a>
                        </td>
                        <td>
                            <span class="p-column-title">Action</span>
                            <button type="button" pButton icon="pi pi-list"
                                (click)="setCurrentItem(spotlight); menu.toggle($event)"></button>
                        </td>

                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="5">No Spotlights found.</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>


    </div>
</div>
<p-menu #menu [popup]="true" [model]="spotlightItems"></p-menu>
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
<spotlight-contact-edit [(show)]="conDialog" contactType="spotlight" [parentUuid]="currentMenuData.uuid">
</spotlight-contact-edit>
<tbp-spotlight-preview [(show)]="previewDialog" [spotlight]="currentMenuData"></tbp-spotlight-preview>
<p-toast></p-toast>
<p-overlayPanel #advsrch [dismissable]="false" [showCloseIcon]="true">
    <ng-template pTemplate>
        <tbp-query-builder [fields]="queryBuilderFields" (onSearch)="advSearch($event,0)" [query]="queryBuilderQuery">
        </tbp-query-builder>
    </ng-template>
</p-overlayPanel>