import { BuySidePrimaryObjective } from './buyside.primaryobjective.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddressPipe } from './address.pipe';
import { DealPipe } from './deal.pipe';
import { SellSidePrimaryObjective } from './sellside.primaryobjective.pipe';
import { ReordableRowDrop } from './reorderablerow.directive';
import { HighlightPipe } from './highlight';

@NgModule({
    declarations: [
        AddressPipe,
        DealPipe,
        SellSidePrimaryObjective,
        BuySidePrimaryObjective,
		ReordableRowDrop,
        HighlightPipe
    ],
    imports: [
        CommonModule
    ],
    exports: [
        AddressPipe,
        DealPipe,
        SellSidePrimaryObjective,
        BuySidePrimaryObjective,
        ReordableRowDrop,
        HighlightPipe
    ]
})
export class SharedModule {

}
