import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Classification } from '../_models';
import { environment } from './../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ClassificationService {

    constructor(
        private router: Router,
        private http: HttpClient) { }

    getClassifications(classificationType: string, pageParams: any) {
        if (pageParams == null) {
            pageParams = { pageNo: 0, pageSize: -1, sortBy: 'name', sortDir: 'asc' };
        }
        return this.http.get<any>(environment.apiUrl + '/classifications/' + classificationType, { params: pageParams });
    }

    createClassification(params: any) {
        return this.http.post(environment.apiUrl + '/classification', params);
    }

    patchClassification(params: any) {
        return this.http.patch(environment.apiUrl + '/classification/' + params.uuid, params);
    }

    deleteClassification(uuid: string) {
        return this.http.delete(environment.apiUrl + '/classification/' + uuid);
    }

    getClassification(uuid: string) {
        return this.http.get(environment.apiUrl + '/classification/' + uuid);
    }

    mergeClassifications(master: string, source1: string, source2: string) {
        return this.http.post(environment.apiUrl + '/classification/' + master + "/merge/" + source1 + (source2 ? ("/" + source2) : ""), null);
    }

    moveClassifications(type:string, uuids:string[]){
        return this.http.post(environment.apiUrl + '/classification/move/' + type, uuids)
    }
    
}
