import { DataService } from './../_services/data.service';
import { DealService } from './deal.service';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';


@Injectable()
export class DealResolver implements Resolve<any> {

    constructor(private dealService: DealService,
        private dataService: DataService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        if (state.url.indexOf("/edit/") != -1) {
            let data = this.dataService.getDataByKey('edeal-' + route.params.uuid);
            if (data) {
                return of(data);
            }
            else {
                return this.dealService.getDeal(route.params.uuid);
            }
        }
        return of(null);
    }
}