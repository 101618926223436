import { MediaService } from './media.service';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';


@Injectable()
export class MediaResolver implements Resolve<any> {

    constructor(private mediaService: MediaService, private router: Router) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        if (!this.router.navigated && state.url.startsWith('/media')) {
            let url = state.url.substring(6);
            if (url == '' || url == '/') {
                return of(null);
            }
            return this.mediaService.getItemsInPath(url)
        }

        return of(null);
    }
}