<p-autoComplete appendTo="body" [(ngModel)]="autocompleteValue" [multiple]="multiple"
    [suggestions]="autoCompleteResults" [placeholder]="placeholder" (completeMethod)="searchItem($event)"
    [completeOnFocus]="completeOnFocus" [field]="field" [dataKey]="dataKey" [forceSelection]="forceSelection"
    (onFocus)="focus()" (onClick)="focus()" (onBlur)="blur()" (onShow)="show()" (onHide)="hide()" (onSelect)="select($event)"
    (onUnselect)="unselect($event)" (onClear)="clear($event)" [showClear]="showClear" [required]="required" [required]="required" [styleClass]="'limit-height-autocomplete'" 
    unqiue="true" [disabled]="disabled" appendTo="body">

    <ng-template let-item pTemplate="item">
        <span *ngIf="item.uuid.includes('tmp-')">{{item.tempName}} <p-tag severity="info">Add New</p-tag></span>
        <span *ngIf="!item.uuid.includes('tmp-')">
                {{item[field]}}
        </span>
    </ng-template>
</p-autoComplete>
<span class="tbp-autocomplete-icon p-input-icon-right" *ngIf="showSearch">
    <i class="pi pi-search" (click)="openSearch($event)"></i>
</span>

<add-classification
    *ngIf="type == 'CATEGORY_LEVEL1' || type == 'CATEGORY_LEVEL2' || type == 'CATEGORY_LEVEL3' || type == 'VERTICAL' || type=='ECOSYSTEM'"
    [(show)]="classificationDialog" [inputTextvalue]="inputTextvalue" [type]="type"
    (parentFuntion)="onFormGroupChangeEvent($event)"></add-classification>
<add-global-lookups *ngIf="type == 'COUNTRY'" [(show)]="lookupDialog" [type]="type" [inputTextvalue]="inputTextvalue"
    (parentFuntion)="onFormGroupChangeEvent($event)"></add-global-lookups>
<account-popup *ngIf="type=='Account'" [inputTextvalue]="inputTextvalue"
    (onFormGroupChange)="onFormGroupChangeEvent($event)" [(show)]="accountForm" type="account"></account-popup>
<user-popup-add *ngIf="type=='Contact'" [(show)]="contactForm" [inputTextvalue]="inputTextvalue"
    (afterSave)="onFormGroupChangeEvent($event)" [organization]="additionalData"></user-popup-add>
<add-state-lookups *ngIf="cntType == 'STATE'" [(show)]="stateDialog" [type]="cntName" [countryUuid]="cntUuid"
    (parentFuntion)="onFormGroupChangeEvent($event)" [inputTextvalue]="inputTextvalue"></add-state-lookups>