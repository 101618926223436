import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AccountService } from './../_services';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { confirmValidator } from './../_helpers/confirmed.validator';
import { AppUtil } from '../_helpers';

@Component({
    selector: 'app-resetpassword',
    templateUrl: './resetpassword.component.html',
    styleUrls: ['./resetpassword.component.scss'],
    providers: [MessageService, ConfirmationService]
})
export class ResetpasswordComponent implements OnInit {

    errorCode: string;
    token: string;

    form: UntypedFormGroup;
    loading = false;
    submitted = false;
    showPassword = false;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountService,
        private messageService: MessageService,
        public appUtil:AppUtil
    ) { }

    ngOnInit(): void {
        this.errorCode = '';
        this.token = this.route.snapshot.params.actionUUID;
        this.messageService.clear();
        this.form = this.formBuilder.group({
            password: ['', [Validators.required, Validators.minLength(6)]],
            confirmPassword: ['']
        }, {
            validator: confirmValidator('password', 'confirmPassword')
        });

    }
    get f() { return this.form.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        let { token, password, confirmPassword } = this.form.value;
        let dataToPost: any = { token, password, confirmPassword };
        dataToPost.token = this.token;
        dataToPost.password = this.appUtil.passwordEncrypt(this.form.value.password);
        dataToPost.confirmPassword = dataToPost.password;

        this.accountService.resetPassword(dataToPost)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.errorCode = 'SUCCESS';
                    this.form.reset();
                    setTimeout(() => {
                        this.goToLogin();
                    }, 6000);
                },
                error: error => {
                    let er = error.error;
                    if (er.code) {

                        if (er.code == 'VALIDATION_ERROR') {
                            for (var i = 0; i < er.subErrors.length; i++) {
                                this.f[er.subErrors[i].field].setErrors({ 'message': er.subErrors[i].message });
                            }
                        }
                        else {
                            this.errorCode = er.code;
                        }
                    }
                    this.loading = false;
                }
            }


            );
    }

    goToLogin() {
        this.router.navigate(['/account/login']);
    }

    showHidePassword() {
        this.showPassword = !(this.showPassword);
    }

}
