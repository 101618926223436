export class AuthUser {
    name: string;
    uuid: string;
    accessToken: string;
    expiresIn: number;
    refreshToken: string;
    refreshTokenExpiresIn: number;
    role: number;
    status: string;
    storageTime: number;
    redirectToUrl: string;
    internalUser: number;
}