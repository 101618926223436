import { AppUtil } from './../_helpers/app.util';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from '../app.breadcrumb.service';
import { Location } from '@angular/common';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MessageService, ConfirmationService } from 'primeng/api';
import { EngagementService } from './engagement.service';
import { DataService } from "../_services/data.service";

@Component({
    selector: 'app-engagement.blindprofile',
    templateUrl: './engagement.blindprofile.component.html',
    styleUrls: ['./engagement.blindprofile.component.scss'],
    providers: [MessageService, ConfirmationService]
})
export class EngagementBlindprofileComponent implements OnInit {

    @ViewChild('cform') formElement;
    form: UntypedFormGroup;
    isEdit: boolean = false;
    loadingSpinner: boolean = false;
    engagemantuuid: string;
    submitted = false;
    constructor(
        private engagementService: EngagementService,
        private router: Router,
        private messageService: MessageService,
        private _location: Location,
        private formBuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private breadcrumbService: BreadcrumbService,
        private dataService: DataService,
        public appUtil: AppUtil
    ) {
        this.engagemantuuid = this.route.url['value'][0].path;
        if (this.router.url.indexOf("/edit/") != -1) {
            this.isEdit = true;
        }

        if (this.isEdit) {
            this.engagementService.getBlindProfile(this.engagemantuuid, this.route.snapshot.params.uuid).subscribe(data => {
                this.modelToForm(data as any);
            });
            this.breadcrumbService.setItems([
                { label: 'Edit Blind Profile' }
            ]);
        } else {
            this.breadcrumbService.setItems([
                { label: 'New Blind Profile' }
            ]);
        }

    }

    ngOnInit(): void {
        this.loadBlindProfileForm();
    }

    loadBlindProfileForm() {
        this.form = this.formBuilder.group({
            title: ['', [Validators.required]],
            companyDescription: ['', [Validators.required]],
            serviceOfferings: ['', [Validators.required]],
            customers: ['', [Validators.required]],
            offices: ['', [Validators.required]],
            employees: ['', [Validators.required]],
            financials: ['', [Validators.required]],
            investmentConsiderations: ['', [Validators.required]],
            headquarter: ['', [Validators.required]]
        });
    }

    modelToForm(model) {
        this.form.setValue({
            title: model.title,
            companyDescription: model.companyDescription,
            serviceOfferings: model.serviceOfferings,
            customers: model.customers,
            offices: model.offices,
            employees: model.employees,
            financials: model.financials,
            investmentConsiderations: model.investmentConsiderations,
            headquarter: model.headquarter
        });
    }
    get f() { return this.form.controls; }
    handleError(elemId) {
        this.messageService.add({ severity: 'error', summary: 'Form Errors', detail: "Form has errors. Please fix them first", life: 3000 });
        let invalidControl = this.formElement.nativeElement.querySelector('.ng-invalid');

        if (invalidControl) {
            invalidControl.focus();
        }
        else if (elemId != null) {
            invalidControl = this.formElement.nativeElement.querySelector('#' + elemId);
            if (invalidControl) {
                invalidControl.focus();
            }
        }
    }

    save() {
        this.submitted = true;
        if (this.form.invalid) {
            this.handleError(null);
            return;
        }
        let { title, companyDescription, serviceOfferings, customers, offices, employees, financials, investmentConsiderations, headquarter } = this.form.value;
        let dataToPost: any = { title, companyDescription, serviceOfferings, customers, offices, employees, financials, investmentConsiderations, headquarter };
        this.loadingSpinner = true;
        let apiToCall: any;
        if (this.isEdit) {
            apiToCall = this.engagementService.UpdateBlindProfile(this.engagemantuuid, this.route.snapshot.params.uuid, dataToPost);
        }
        else {
            apiToCall = this.engagementService.addBlindProfile(this.engagemantuuid, dataToPost);
        }
        apiToCall.subscribe(
            data => {
                let detail = 'Blind Profile Created';
                if (this.isEdit) {
                    detail = 'Blind Profile Updated';
                }
                this.dataService.setData('message', detail);
                this.router.navigate(['/engagement/' + this.engagemantuuid]);
            },
            error => {
                this.loadingSpinner = false;
                let errors = error.error;
                if (errors.code && errors.code == 'VALIDATION_ERROR') {
                    for (var i = 0; i < errors.subErrors.length; i++) {
                        this.f[errors.subErrors[i].field].setErrors({ 'message': errors.subErrors[i].message });
                    }
                    this.handleError(errors.subErrors[0].field);
                }
            }
        );
    }

    cancel() {
        this._location.back();
    }

}
