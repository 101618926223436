import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Pipe({ name: 'sellsideobjective' })
export class SellSidePrimaryObjective implements PipeTransform {
    constructor(private _sanitizer: DomSanitizer) { }  
    transform(value: any, prefix?: string): SafeHtml {
        let retValue = '';
        if(value.primaryObjectives)
        {
            let sellTheBusinessValues = "";
            let privateEquityPartner = value.primaryObjectives.privateEquityPartner;
            let sepearator = "";
            if(privateEquityPartner.controlling)
            {
                sellTheBusinessValues = sellTheBusinessValues + sepearator + "Control";
                sepearator = "; ";
            }
            if(privateEquityPartner.hundredPercent)
            {
                sellTheBusinessValues = sellTheBusinessValues + sepearator + "100%";
                sepearator = "; ";
            }
            if(privateEquityPartner.minority)
            {
                sellTheBusinessValues = sellTheBusinessValues + sepearator + "Minority";
                sepearator = "; ";
            }
            if(sellTheBusinessValues != '')
            {
                sellTheBusinessValues = 'Private Equity Partner - [' + sellTheBusinessValues + ']';
            }
            if(value.primaryObjectives.strategicPartner)
            {
                if(sellTheBusinessValues != '')
                {
                    sellTheBusinessValues =  "Strategic Partner; " + sellTheBusinessValues;
                }
                else
                {
                    sellTheBusinessValues = "Strategic Partner";
                }
            }
            if(sellTheBusinessValues != '')
                retValue += '<span class="block">'+ 'Sell the business - ' + sellTheBusinessValues + '</span>'

            let equity = value.primaryObjectives.financing.equity;
            let eqtVal = "";
            sepearator = "";
            if(equity.angelInvestment)
            {
                eqtVal = eqtVal + "Angel Investment";
                sepearator = "; ";
            }
            if(equity.growthEquity)
            {
                eqtVal = eqtVal + sepearator + "Growth Equity";
                sepearator = "; ";
            }
            if(equity.minorityRecap)
            {
                eqtVal = eqtVal + sepearator + "Minority Recap";
                sepearator = "; ";
            }
            if(equity.ventureCapital)
            {
                eqtVal = eqtVal + sepearator +  "Venture Capital";
                sepearator = "; ";
            }
            if(eqtVal != '')
                retValue += '<span class="block">'+ 'Obtain Financing (Equity) - [' + eqtVal + ']</span>'
    
            let debt = value.primaryObjectives.financing.debt;
            let debtVal = "";
            sepearator = "";
            if(debt.arFinancing)
            {
                debtVal = debtVal + "A/R Financing";
                sepearator = "; ";
            }
            if(debt.juniorDebt)
            {
                debtVal = debtVal + sepearator + "Junior Debt";
                sepearator = "; ";
             }
            if(debt.mezzanineFinancing)
            {
                debtVal = debtVal + sepearator +  "Mezannine Financing";
                sepearator = "; ";
            }
            if(debt.seniorDebt)
            {
                debtVal = debtVal + sepearator + "Senior Debt";
                sepearator = "; ";
            }
            if(debtVal != '')
                retValue += '<span class="block">'+ 'Obtain Financing (Debt) - [' + debtVal + ']</span>';
        }
  
        return this._sanitizer.bypassSecurityTrustHtml(retValue);
    }
}