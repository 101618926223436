// highlight.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'highlight' })
export class HighlightPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string, term: string): SafeHtml {
    if (!value || !term) return value;
    const cleanedTerm = term.replace(/[.*+?^${}()|[\]\\]/g, '');
    if (!cleanedTerm) return value;
    const regex = new RegExp(`(${cleanedTerm})`, 'gi');
    const newValue = value.replace(regex, `<span class="highlight">$1</span>`);
    return this.sanitizer.bypassSecurityTrustHtml(newValue);
  }
}
