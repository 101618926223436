import { Component, OnInit, Input, ViewChild, ViewChildren, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Inplace } from 'primeng/inplace';
import { BuysideService } from './buyside.service';


@Component({
    selector: 'buyside-blind-profile-view',
    templateUrl: './buyside-blind-profile-view.component.html',
    styleUrls: ['./buyside-blind-profile-view.component.scss'],
    styles: [`
  :host ::ng-deep .p-divider.p-divider-vertical::before {
		border-left: 2px #dee2e6 dashed !important;
	}
  `]

})
export class BuysideBlindProfileViewComponent implements OnInit {

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private buysideService: BuysideService
    ) { }

    blindProfile: any;
    loadingSpinner:boolean = false;
    @Input() screenId: string;

    fieldData:any = {title:{loading:false,value:null,error:null},headline:{loading:false,value:null,error:null},
        capabilities:{loading:false,value:null,error:null},customers:{loading:false,value:null,error:null},
        business:{loading:false,value:null,error:null},businessBuyer:{loading:false,value:null,error:null},
        investmentStrategy:{loading:false,value:null,error:null}};
    

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges)
    {
        if(changes.screenId)
        {
            this.buysideService.getBlindProfile(this.screenId).subscribe(data => {
                this.blindProfile = data as any;
                for (const [key, value] of Object.entries(this.fieldData)) {
                    this.fieldData[key].value = this.blindProfile[key];
                }

            }).add( () => {
                this.loadingSpinner = false;
            });
        }
    }

    patch(selectedEditor: Inplace, fieldName: string) {
        let data = this.fieldData[fieldName];
        if(this.blindProfile[fieldName] != data.value)
        {
            let dataToPost = {uuid: this.blindProfile.uuid};
            dataToPost[fieldName] = data.value;
            data.loading = true;
            this.buysideService.patchBlindProfile( [fieldName],dataToPost).subscribe(
                {
                    next: (v) => {
                        this.blindProfile[fieldName] = data.value;
                        data.error = null;
                        selectedEditor.deactivate();
                    },
                    error: (e) => {
                        let errors = e.error;
                        if (errors.code && errors.code == 'VALIDATION_ERROR') {
                            data.error = errors.subErrors[0].message;
                        }
                    }
                }
            ).add(() => {
                data.loading = false;
            });
        }
        else {
            selectedEditor.deactivate();
          }
    }

    close(selectedEditor: Inplace, fieldName: string) {
        let data = this.fieldData[fieldName];
        data.value = this.blindProfile[fieldName];
        data.loading = false;
        data.error = null;
        selectedEditor.deactivate();
    }

    editBlindProfile()
    {
        this.router.navigate(['/buy-side/' + this.screenId + '/blind-profile/edit']);
    }
}
