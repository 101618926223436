<p-autoComplete [(ngModel)]="autocompleteValue" [multiple]="multiple" [suggestions]="autoCompleteResults"
    [placeholder]="placeholder" (completeMethod)="searchItem($event)" [completeOnFocus]="completeOnFocus"
    [field]="field" [dataKey]="dataKey" [forceSelection]="forceSelection" (onFocus)="focus()" (onBlur)="blur()"
    (onShow)="show()" (onHide)="hide()" (onSelect)="select($event)" (onUnselect)="checkFilled()"
    (onClear)="checkFilled()" appendTo="body">
    <ng-template let-item pTemplate="item">
        <span *ngIf="item.uuid.includes('tmp-')">{{item.tempName}} <p-tag severity="info">Add New</p-tag></span>
        <span *ngIf="!item.uuid.includes('tmp-')">{{item[field]}}</span>
    </ng-template>
</p-autoComplete>

<add-global-lookups
    *ngIf="checkType"
    [(show)]="lookupDialog" [type]="type" [inputTextvalue]="inputTextvalue" [entityType]="entityType" [entityId]="entityId"
    (parentFuntion)="onFormGroupChangeEvent($event)"></add-global-lookups>