import { AppUtil } from './../_helpers/app.util';
import { User } from './../_models/user';
import { OrganizationService } from './../organization/organization.service';
import { Organization } from './../_models/organization';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, SimpleChanges } from '@angular/core';
import { UserPopupService } from './user-popup.service';
import { ConfigurationService } from '../configuration/configuration.service';
import { environment } from 'src/environments/environment';
import * as mimeType from 'mime';
import { HttpClient } from '@angular/common/http';;

@Component({
    selector: 'user-popup-add',
    templateUrl: './user-popup.add.component.html',
    styleUrls: ['./user-popup.add.component.scss']
})
export class UserPopupAddComponent implements OnInit {

    @Input() show: boolean = false;
    @Input() apiToCall: string = '/contact';
    @Output() showChange = new EventEmitter<boolean>();
    @Input() organization: Organization;
    @Input() canChangeOrganization: boolean = true;
    @Input() closeAfterSave: boolean = true;
    @Output() afterSave = new EventEmitter<any>();
    @Input() contactData: User = null;
    @ViewChild('userform') formElement;
    title: string = "New Contact";
    form: UntypedFormGroup;
    submitted: boolean = false;
    formInitialValue: any = null;
    accountResults: Organization[];
    accountForm:boolean = false;
    accountText:string = null;
    lookupList: any = { MAILING_LIST: [] };
    uploadURL: string;
    uploadedFiles: any[] = [];
    @Input() inputTextvalue: string;
    constructor(private route: ActivatedRoute,
        private messageService: MessageService,
        private formBuilder: UntypedFormBuilder,
        private userPopupService: UserPopupService,
        private organizationService: OrganizationService,
        public appUtil:AppUtil,
        private lookupService: ConfigurationService,
        private http: HttpClient,
    ) {
        this.uploadURL = environment.apiUrl + '/upload/organization_notes'
        this.form = this.formBuilder.group({
            uuid: [],
            firstName: [null, [Validators.required, Validators.maxLength(40)]],
            lastName: [null, [Validators.required, Validators.maxLength(80)]],
            phone: [],
            email: [null, [Validators.required, Validators.pattern, Validators.pattern(this.appUtil.emailPattern)]],
            title: [],
            organization: [],
            verified: [false],
            mailingList: [],
            linkedInProfile: [null,[Validators.pattern(this.appUtil.urlPattern)]],
            uploadedFiles: []
        });
        this.formInitialValue = this.form.value;
        this.accountResults = [];
    }

    ngOnInit(): void {

        let lookupList = this.route.snapshot.data['lookupList'];
        if(lookupList == undefined || lookupList == null)
        {
            this.lookupService.getLookupValues('all').subscribe(
                data => {
                    this.lookupList = data as any[];
                }
                    
            );
        }
        else{
            this.lookupList = lookupList;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty("contactData") && this.contactData != null) {
            let { uuid, firstName, lastName, phone, email, title, verified, mailingList, linkedInProfile } = this.contactData;
            this.formInitialValue = { uuid, firstName: this.appUtil.toTitleCase(firstName) , 
                lastName: this.appUtil.toTitleCase(lastName), phone, email: this.appUtil.toLowerCase(email), 
                title, organization:this.organization, verified:verified, mailingList, linkedInProfile: this.appUtil.toLowerCase(linkedInProfile)};
        }
        this.form.reset(this.formInitialValue);
        if (changes.hasOwnProperty("organization"))
        {
            this.formInitialValue.organization = this.organization;
            this.f.organization.setValue(this.organization);
        }
        if (changes.hasOwnProperty("inputTextvalue") && this.inputTextvalue != undefined && this.inputTextvalue != '') {
            let firstname = this.inputTextvalue.trim().split(" ");
            this.f.firstName.setValue(this.appUtil.toTitleCase(firstname[0].trim()));
            firstname.splice(0,1);
            let lastname = this.appUtil.toTitleCase(firstname.join(" "));
            this.f.lastName.setValue(lastname.trim());
        }
        this.uploadedFiles = [];
    }

    get f() { return this.form.controls; }

    searchAccounts(event) {
        this.accountText = event.query;
        this.organizationService.lookupOrganization(event.query).subscribe(data => {
            this.accountResults = data as any;
        });
    }


    handleError(elemId) {
        this.messageService.add({ severity: 'error', summary: 'Form Errors', detail: "Form has errors. Please fix them first", life: 3000 });
        let invalidControl = this.formElement.nativeElement.querySelector('.ng-invalid');
        if (invalidControl) {
            invalidControl.focus();
        }
        else if (elemId != null) {
            invalidControl = this.formElement.nativeElement.querySelector('#' + elemId);
            if (invalidControl) {
                invalidControl.focus();
            }
        }
    }

    hide() {
        this.form.reset(this.formInitialValue);
        this.submitted = false;
        this.show = false;
        this.showChange.emit(false);
    }

    save() {
        this.submitted = true;
        if (this.form.invalid) {
            this.handleError(null);
            return;
        }
        if(this.f.organization.value == ""){
            this.f.organization.setValue(null);
        }
        
        let { uuid, firstName, lastName, phone, email, title, organization, isAdmin, level1, level2, level3, vertical, ecosystem,verified, mailingList, linkedInProfile, uploadedFiles } = this.form.value;
        let dataToPost: any = { uuid, firstName, lastName, phone, email, title, organization, isAdmin,verified, classification: { level1, level2, level3, vertical, ecosystem },
                                     mailingList, linkedInProfile, uploadedFiles };
        dataToPost['isAdmin'] = false;
        if (organization != null) {
            dataToPost.copyClassificationsFromOrganization = true;
        }
        if(this.uploadedFiles != null && this.uploadedFiles != undefined){
            dataToPost.uploadedFiles = [];
            for(var i=0; i<this.uploadedFiles.length; i++) {
                dataToPost.uploadedFiles.push(this.uploadedFiles[i].uuid)
            }
        }
        if(this.f.mailingList.value){
            dataToPost.mailingList = this.f.mailingList.value.map(tag => tag.value);
        }
        let apiToCall: any;
        apiToCall = this.userPopupService.addContact(dataToPost);
        apiToCall.subscribe(
            data => {
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: "Save Successfully", life: 3000 });
                this.submitted = false;
                this.afterSave.emit(data);
                if (this.closeAfterSave) {
                    this.userPopupService.filter(data);
                    this.hide();
                }
            },
            error => {
                let errors = error.error;
                if (errors.code && errors.code == 'VALIDATION_ERROR') {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: errors.subErrors[0].message, life: 3000 });
              //      this.handleError(errors.subErrors[0].field);
                }
            }
        );
    }

    accountCreated($event)
    {   
        this.f.organization.setValue($event);
    }

    showAccountForm()
    {
        this.accountForm = true;
    }

    onUpload(event){

        this.f.uploadedFiles.setValue(event.originalEvent.body);
        this.uploadedFiles.push(event.originalEvent.body);
    }

    openDocument(event) {

        event.preventDefault();
        var filename = event.target.href;
        this.http.get(filename, { responseType: 'arraybuffer' }).subscribe(
            data => {
                var file = new Blob([data], { type: mimeType.getType(filename) });
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            });
    }

    deleteDocument(event, uuid) {
        event.preventDefault();
        let newArr: any[] = [];
        let arr: any[] = this.uploadedFiles;
        for (var i = 0; i < arr.length; i++) {
            if (uuid != arr[i].uuid)
                newArr.push(arr[i]);
        }
        this.uploadedFiles = newArr;
    }

}
