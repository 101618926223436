import { EntityClassification } from "./entity-classification";
import { Organization } from ".";
import { Blindprofile } from './blind-profile';
import { User } from "./user";
export class BuySide {

    constructor() {
        this.classification = new EntityClassification;
    }
    uuid?: string
    contacts?: User[];
    organization?: Organization[];
    primaryObjective?: string;
    screenTitle?: string;
    externalHeadline?: string;
    dollorValueRange?: string;
    targetSize?: string;
    targetRevenue?: string;
    targetEbitda?: string;
    managementPreference?: string;
    turnaround?: boolean;
    debtType?: string;
    transactionObjective?: string;
    companyDescription?: string;
    classification: EntityClassification;
    user: string;
    hasBlindProfile: boolean;
    isStrategicBuyout?: boolean;
    isPeBuyout?: boolean;
    isPEBuyoutPlatform?: boolean;
    isPEBuyoutTuckin?: boolean;
    isInvestment?: boolean;
    equityType?: string;
    debt?: string;
    equity?: string;
    createByUser?: User;
    createdDate?: User;
    modifiedByUser?: User;
    lastModifiedDate?: User;

}