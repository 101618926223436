import { UsersService } from './users.service';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';


@Injectable()
export class UserResolver implements Resolve<any> {

    constructor(private userService: UsersService) { }

    resolve(): Observable<any> {
        return this.userService.getTBPEmployees();

    }
}