import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'tbp-media-lookup',
    templateUrl: './media.lookup.component.html',
    styleUrls: ['./media.lookup.component.scss']
})

export class MediaLookupComponent implements OnInit {

    @Input() show: boolean = false;
    @Output() onSelect = new EventEmitter<string>();
    @Output() showChange = new EventEmitter<false>();
    @Input() closeOnSelect: boolean = true;
    @Input() title: string;
    @Input() allowAddFolder: boolean = false;
    @Input() allowAddDocument: boolean = false;
    @Input() startAtFolder: string;
    @Input() onlyPublicImages: boolean = false;

    constructor() { }

    ngOnInit(): void {
    }

    hide() {
        this.show = false;
        this.showChange.emit(false);
    }

    mediaSelected(event) {
        this.onSelect.emit(event);
        if (this.closeOnSelect) {
            this.hide();
        }
    }
}
