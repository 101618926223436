import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class EmailCampaignService {

    constructor(private http: HttpClient) { }


    addEmailCampaign(data) {
        return this.http.post(environment.apiUrl + '/campaign', data);
    }

    updateEmailCampaign(data) {
        return this.http.put(environment.apiUrl + '/campaign/' + data.uuid, data);
    }

    getEmailCampaign(uuid: string) {
        return this.http.get(environment.apiUrl + '/campaign/' + uuid);
    }

    searchEmailCampaign(searchParams: any) {
        return this.http.post(environment.apiUrl + '/campaigns', searchParams);
    }

    addRecipients(uuid: string, contactUuids: string[]) {
        return this.http.post(environment.apiUrl + '/campaign/' + uuid + '/add-recipients', contactUuids);
    }

    addAllRecipients(targetUuid: string, sourceUuid: string) {
        return this.http.post(environment.apiUrl + '/campaign/' + targetUuid + '/add-recipients/' + sourceUuid, null);
    }

    getRecipientsWithPaging(campaignuuid: string, searchRequest: any) {
        return this.http.post(environment.apiUrl + '/campaign/' + campaignuuid + '/recipients', searchRequest);
    }

    getRecipients(campaignuuid: string) {
        return this.http.get(environment.apiUrl + '/campaign/' + campaignuuid + '/recipients');
    }

    downloadRecipients(campaignuuid: string) {
        return this.http.get(environment.apiUrl + '/campaign/' + campaignuuid + '/recipient/download', {
            responseType: 'blob'
        });
    }

    deleteRecipients(campaignUuid: string, recipientUuids: any){
        return this.http.post(environment.apiUrl + '/campaign/' + campaignUuid + "/remove-recipients", recipientUuids);
    }

    lookupCampaign(query) {
        return this.http.get(environment.apiUrl + '/campaigns/lookup', { params: { query } });
    }

    deleteCampaigns(uuids: any):Observable<any> {
        return this.http.delete(environment.apiUrl + '/campaigns', { params: { uuids } });
    }

    copyCampaign(campaignUuid: string, data) {
        return this.http.post(environment.apiUrl + '/campaign/' + campaignUuid + '/copy', data);
    }

    syncWithScreen(campaignUuid: string, screenUuid: string) {
        return this.http.post(environment.apiUrl + '/campaign/' + campaignUuid + "/sync-screen/" + screenUuid, null);
    }

    addRecipientsAsProspects(campaignUuid: string, recipientUuids: any) {
        return this.http.post(environment.apiUrl + '/campaign/' + campaignUuid + "/recipients/as-prospects", recipientUuids);
    }

    findRecipientsForCampaigns(campaignUuids: string[]) {
        return this.http.post(environment.apiUrl + '/campaigns/recipients', campaignUuids);
    }

}
