import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from '../app.breadcrumb.service';
import { DealService } from './deal.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MessageService, ConfirmationService } from 'primeng/api';
import { OrganizationService } from 'src/app/organization/organization.service';
import { Organization } from "../_models";
import { SpotlightService } from 'src/app/spotlight/spotlight.service';
import { InputNumberModule } from 'primeng/inputnumber';
import { AppUtil } from '../_helpers';
import { DataService } from '../_services/data.service';
import { Location } from '@angular/common';

@Component({
    selector: 'app-deal-add',
    templateUrl: './deal.add.component.html',
    styleUrls: ['./deal.add.component.scss'],
    providers: [MessageService, InputNumberModule, ConfirmationService]
})
export class DealAddComponent implements OnInit {

    @ViewChild('cform') formElement;
    form: UntypedFormGroup;
    submitted = false;
    classificationList: any = {
        CATEGORY_LEVEL1: [], CATEGORY_LEVEL2: [], CATEGORY_LEVEL3: [],
        VERTICAL: [], ECOSYSTEM: []
    };
    isEdit: boolean = false;
    loadingSpinner: boolean = false;
    tbpEmployees = [];
    DEAL_TYPE = [{ code: 'MERGER__ACQUISITION', name: 'Merger/Acquisition' }, { code: 'PRIVATE_PLACEMENT', name: 'Private Placement' }];
    helpList: any = {DEAL:[]};
    constructor(
        private _location: Location,
        private dealService: DealService,
        private breadcrumbService: BreadcrumbService,
        private router: Router,
        private route: ActivatedRoute,
        private messageService: MessageService,
        private organizationService: OrganizationService,
        private spotlightService: SpotlightService,
        private formBuilder: UntypedFormBuilder,
        private dataService: DataService,
        private confirmationService: ConfirmationService,
        public appUtil: AppUtil) {
        if (this.router.url.indexOf("/edit/") != -1) {
            this.isEdit = true;
        }
        if (this.isEdit) {
            this.breadcrumbService.setItems([
                { label: 'Edit Deal' }
            ]);
        } else {
            this.breadcrumbService.setItems([
                { label: 'New Deal' }
            ]);
        }

    }

    ngOnInit(): void {

        this.loadDealForm();
        this.classificationList = this.route.snapshot.data['classificationList'];
        this.tbpEmployees = this.route.snapshot.data['tbpEmployees'];
        this.helpList = this.route.snapshot.data['helpList']['DEAL'];
        if (this.isEdit) {
            this.modelToForm(this.route.snapshot.data['deal'])
        }

    }

    modelToForm(model) {
        model.dealDate = this.appUtil.DbToCalendarDateFormat(model.dealDate);
        this.form.setValue({
            uuid: model.uuid,
            dealDate: model.dealDate,
            dealType: model.dealType,
            buyerOrganizations: model.buyerOrganizations,
            targetOrganization: model.targetOrganization,
            transactionValue: model.transactionValue,
            evRev: model.evRev,
            evEbitda: model.evEbitda,
            targetDescription: model.targetDescription,
            notes: model.notes,
            level1: (model.classification.level1 == null) ? null : model.classification.level1,
            level2: (model.classification.level2 == null) ? null : model.classification.level2,
            level3: (model.classification.level3 == null) ? null : model.classification.level3,
            vertical: (model.classification.vertical == null) ? null : model.classification.vertical,
            ecosystem: (model.classification.ecosystem == null) ? null : model.classification.ecosystem,
        });

    }

    loadDealForm() {
        this.form = this.formBuilder.group({
            uuid: [''],
            dealDate: ['', Validators.required],
            dealType: [this.DEAL_TYPE[0].code, Validators.required],
            buyerOrganizations: [],
            targetOrganization: [],
            transactionValue: [],
            evRev: [],
            evEbitda: [],
            targetDescription: ['', Validators.required],
            level1: [],
            level2: [],
            level3: [],
            vertical: [],
            ecosystem: [],
            notes: [],
        });
        this.f.transactionValue.setValue(null);
        this.f.evRev.setValue(null);
        this.f.evEbitda.setValue(null);
    }

    save() {

        this.submitted = true;
        if (this.form.invalid) {
            this.handleError(null);
            return;
        }
        if (this.f.targetOrganization.value == null && (this.f.buyerOrganizations.value == null || this.f.buyerOrganizations.value.length == 0)) {
            this.messageService.add({ severity: 'error', summary: 'Form Errors', detail: "Please either fill the Buyers/Investors or Target/Issuer", life: 3000 });
            return;
        }
        let { level1, level2, level3, vertical, ecosystem, ...others } = this.form.value;
        let dataToPost = { ...others, classification: { level1, level2, level3, vertical, ecosystem } };

        if (this.f.dealType.value) {
            dataToPost.dealType = this.f.dealType.value;
        }
        if(this.f.targetOrganization.value == ""){
            dataToPost.targetOrganization = null;
        }
        if (this.f.level1.value) {
            dataToPost.classification.level1 = this.f.level1.value;
        }
        if (this.f.level2.value) {
            dataToPost.classification.level2 = this.f.level2.value;
        }
        if (this.f.level3.value) {
            dataToPost.classification.level3 = this.f.level3.value;
        }
        if (this.f.vertical.value) {
            dataToPost.classification.vertical = this.f.vertical.value;
        }
        if (this.f.ecosystem.value) {
            dataToPost.classification.ecosystem = this.f.ecosystem.value;
        }

        dataToPost['dealDate'] = this.appUtil.calendarToDbDateFormat(this.f.dealDate.value);
        let apiToCall: any;

        if (this.isEdit) {
            apiToCall = this.dealService.updateDeal(dataToPost);
        }
        else {
            apiToCall = this.dealService.addDeal(dataToPost);
        }
        this.loadingSpinner = true;
        apiToCall.subscribe(
            data => {
                let deal = data as any;
                let detail = 'Deal Created';
                if (this.isEdit) {
                    detail = 'Deal Updated';
                }
                this.dataService.setData('message', detail);
                this.dataService.setData('vdeal-' + deal.uuid, deal);
                this.router.navigate(['/deal/' + deal.uuid]);
                this.submitted = false;
            },
            error => {
                this.loadingSpinner = false;
                let errors = error.error;
                if (errors.code && errors.code == 'VALIDATION_ERROR') {
                    for (var i = 0; i < errors.subErrors.length; i++) {
                        this.f[errors.subErrors[i].field].setErrors({ 'message': errors.subErrors[i].message });
                    }
                    this.handleError(errors.subErrors[0].field);
                }
            }
        );

    }

    get f() { return this.form.controls; }

    handleError(elemId) {
        this.messageService.add({ severity: 'error', summary: 'Form Errors', detail: "Form has errors. Please fix them first", life: 3000 });
        let invalidControl = this.formElement.nativeElement.querySelector('.ng-invalid');

        if (invalidControl) {
            invalidControl.focus();
        }
        else if (elemId != null) {
            invalidControl = this.formElement.nativeElement.querySelector('#' + elemId);
            if (invalidControl) {
                invalidControl.focus();
            }
        }
    }

    cancel() {
        this._location.back();
    }

    selectAccount(value) {
        this.confirmationService.confirm({
            message: 'Would you like to use address and classification from selected account?',
            accept: () => {
                if (value.classification.level1 != null)
                    this.f.level1.setValue(value.classification.level1);
                if (value.classification.level2 != null)
                    this.f.level2.setValue(value.classification.level2);
                if (value.classification.level3 != null)
                    this.f.level3.setValue(value.classification.level3);
                if (value.classification.vertical != null)
                    this.f.vertical.setValue(value.classification.vertical);
                if (value.classification.ecosystem != null)
                    this.f.ecosystem.setValue(value.classification.ecosystem);
            }
        });
    }
    getHelpText(key) {
        let test = this.helpList.filter(function (element) {
            return element.key == key;
        });
        if (test[0] != undefined)
            return test[0]['text'];
    }

}
