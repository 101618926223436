<p-dialog [(visible)]="show" (onHide)="hide()" [breakpoints]="{'960px': '75vw', '640px': '30vw'}"
    [style]="{width: '28vw'}" [header]="title" [modal]="true" (onHide)="hide()" appendTo="body">
    <ng-template pTemplate="content">
        <form [formGroup]="form" #userform>
            <div class="p-fluid grid">
                <div class="field col-12 text-right mt-2">
                    <label for="verified" class="vertical-align-bottom mr-2 font-bold">Verified</label>
                    <p-inputSwitch formControlName="verified"></p-inputSwitch>
                </div>   
                <div class="field col-12 ">
                    <span class="p-float-label">
                        <input id="firstName" [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.firstName.errors }"
                            formControlName="firstName" type="text" maxlength="40" pInputText  class="capitalize" (blur)="f.firstName.setValue(appUtil.toTitleCase(f.firstName.value))">
                        <label for="firstName">First Name <span class="mandate">*</span></label>
                    </span>
                    <span class="p-error"
                        *ngIf="submitted && f.firstName.invalid && f.firstName.hasError('required')">First Name is
                        Required</span>
                    <span class="p-error"
                        *ngIf="submitted && f.firstName.invalid && f.firstName.hasError('message')">{{f.firstName.errors.message}}</span>
                </div>
                <div class="field col-12 ">
                    <span class="p-float-label">
                        <input id="lastName" [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.lastName.errors }"
                            formControlName="lastName" type="text" maxlength="75" pInputText  class="capitalize" (blur)="f.lastName.setValue(appUtil.toTitleCase(f.lastName.value))">
                        <label for="lastName">Last Name <span class="mandate">*</span></label>
                    </span>
                    <span class="p-error"
                        *ngIf="submitted && f.lastName.invalid && f.lastName.hasError('required')">Last Name is
                        Required</span>
                    <span class="p-error"
                        *ngIf="submitted && f.lastName.invalid && f.lastName.hasError('message')">{{f.lastName.errors.message}}</span>
                </div>
                <div class="field col-12 ">
                    <span class="p-float-label">
                        <input id="title" formControlName="title"
                            [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.title.errors }" type="text"
                            maxlength="40" pInputText>
                        <label for="title">Title </label>
                        <span class="p-error"
                            *ngIf="submitted && f.title.invalid && f.title.hasError('message')">{{f.title.errors.message}}</span>
                    </span>
                </div>
                <div class="field col-12 ">
                    <span class="p-float-label">
                        <input id="email" [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.email.errors }"
                            formControlName="email" type="text" maxlength="175" pInputText class="lowercase" (blur)="f.email.setValue(appUtil.toLowerCase(f.email.value))">
                        <label for="email">Email <span class="mandate">*</span></label>
                    </span>
                    <span class="p-error" *ngIf="form.get('email').hasError('pattern') && submitted">Email address is
                        invalid</span>
                    <span class="p-error" *ngIf="submitted && f.email.invalid && f.email.hasError('required')">Email is
                        Required</span>
                    <span class="p-error"
                        *ngIf="submitted && f.email.invalid && f.email.hasError('message')">{{f.email.errors.message}}</span>

                </div>
                <div class="field col-12 ">
                    <span class="p-float-label">
                        <input id="phone" type="text" tooltipPosition="top"
                        maxlength="40"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.phone.errors }"
                        formControlName="phone" pInputText (blur)="appUtil.formatPhone(f.phone)">
                        <!-- <input id="phone" formControlName="phone"
                            [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.phone.errors }" type="text"
                            maxlength="16" pInputText> -->
                        <label for="phone">Phone </label>
                    </span>
                    <span class="p-error" *ngIf="submitted && f.phone.invalid && f.phone.hasError('required')">Phone is
                        Required</span>
                    <span class="p-error"
                        *ngIf="submitted && f.phone.invalid && f.phone.hasError('message')">{{f.phone.errors.message}}</span>
                </div>
                <div class="field col-12">
                    <span class="p-float-label">
                        <look-autocomplete [multiple]="true" [formControl]="f.mailingList"
                        [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.mailingList.errors }" [type]="'MAILING_LIST'"
                        [items]="lookupList.MAILING_LIST"></look-autocomplete>
                    <label for="mailingList">Mailing List</label>
                    </span>
                </div>

                <div class="field col-12">
                    <span class="p-float-label p-input-icon-right">
                        <i class="pi pi-link link" (click)="appUtil.externalLink(f.linkedInProfile.value,'url')" *ngIf="f.linkedInProfile.value != null && f.linkedInProfile.value.trim()!=''"></i>
                        <i class="pi pi-google link" (click)="appUtil.externalLink(
                            (f.firstName.value?f.firstName.value + ' ':'') + (f.lastName.value?f.lastName.value:'') ,'google', 'linkedin.com:')" *ngIf="f.linkedInProfile.value == null ||  f.linkedInProfile.value.trim()==''"></i>         
                        <input id="linkedInProfile" class="lowercase"
                            [ngClass]="{ 'ng-invalid ng-dirty': submitted && f.linkedInProfile.errors }"
                            formControlName="linkedInProfile" type="text" maxlength="250" pInputText tooltipPosition="top">
                        <label for="linkedInProfile">LinkedIn Profile URL </label>
                    </span>
                    <span class="p-error" *ngIf="form.get('linkedInProfile').hasError('url') && submitted">linkedInProfile
                        should be a URL</span>
                    <span class="p-error"
                        *ngIf="submitted && f.linkedInProfile.invalid && f.linkedInProfile.hasError('message')">{{f.linkedInProfile.errors.message}}</span>
                </div>

                <div class="field col-12 ">
                    <span class="p-float-label">
                        <p-autoComplete appendTo="body" [suggestions]="accountResults" formControlName="organization"
                            (completeMethod)="searchAccounts($event)" field="name" dataKey="uuid"
                            [forceSelection]="true" [disabled]="!canChangeOrganization"></p-autoComplete>
                        <label for="account">Account</label>
                    </span>
                    <span><a href="javascript:" (click)="showAccountForm()">Create New</a></span>
                </div>
                <div class="field col-12">
                    <label class="upload">Attachment (Notes)</label>
                    <p-fileUpload name="file" [url]="uploadURL" (onUpload)="onUpload($event)" [maxFileSize]="this.appUtil.maxFileSize"
                        [auto]="true" chooseLabel="Browse" [multiple]="true" accept=".jpg,.png,.jpeg,.pdf,.docx,.xlsx,.xls">
                        <ng-template pTemplate="content">
                            <ul *ngIf="uploadedFiles.length">
                                <li *ngFor="let file of uploadedFiles">
                                    <a href="{{file.url}}" (click)="openDocument($event)">{{file.nameWithExtension}}</a>
                                    <span title="Remove Attachment"><a href="#" (click)="deleteDocument($event,file.uuid)">
                                        <i class="pi pi-trash" style="font-size: .75rem;color:red;margin-left: 20px;font-weight: bold;font-size: bold;"></i></a>
                                    </span>
                                </li>
                            </ul>
                        </ng-template>
                    </p-fileUpload>
                </div>
            </div>
        </form>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Save" form="clform" icon="pi pi-check" class="p-button-text"
            (click)="save()"></button>
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hide()"></button>
    </ng-template>
</p-dialog>
<account-popup [inputTextvalue]="accountText" (onFormGroupChange)="accountCreated($event)" 
        [(show)]="accountForm" type="account"></account-popup>
<!-- <p-toast></p-toast> -->