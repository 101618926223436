
import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, OnInit, ViewChild, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { MediaService } from './media.service';
import { BreadcrumbService } from '../app.breadcrumb.service';
import { UntypedFormBuilder, UntypedFormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Clipboard } from '@angular/cdk/clipboard';
import { DataView } from 'primeng/dataview';
import { AppUtil } from '../_helpers';

@Component({
    selector: 'media-list',
    templateUrl: './media.list.component.html',
    styleUrls: ['./media.list.component.scss'],
    providers: [MessageService]
})
export class MediaListComponent implements OnInit {
    @ViewChild('dv') dataView: DataView;
    form: UntypedFormGroup;
    documentForm: UntypedFormGroup;
    submittedUpload: boolean;
    loading: boolean = true;
    folderDialog: boolean = false;
    documentDialog: boolean = false;
    submitted: boolean;
    loadingSpinner: boolean = false;
    folderTitle: string = "New Folder";
    uploadFormInitialValues: any;
    items: any;
    totalRecords: number;
    pathSegments: any = null;
    sortOptions: any[] = [{ name: 'Sort By Name', code: 'name' }, { name: 'Sort By Date Created', code: 'createdDate' }, { name: 'Sort By Date Modified', code: 'lastModifiedDate' }];
    accessTypes: any[] = [{ name: 'All Documents', code: 'all' }, { name: 'Public Only', code: 'public' }, { name: 'Private Only', code: 'private' }];
    sortKey: string;
    accessType:string;
    itemSelectedForEditing: any;
    fileTitle = 'New Document';
    sortField: string = 'name';
    sortOrder: number = 0;
    term: string;
    firstRow: number = 0;
    mediaLoading: boolean = false;
    @Input() embeded: boolean = false;
    @Input() allowAddFolder: boolean = true;
    @Input() allowAddDocument: boolean = true;
    @Input() startAtFolder: string;
    @Output() onSelect = new EventEmitter<string>();
    @Output() onCopy = new EventEmitter<string>();
    @Input() onlyPublicImages:boolean = false;
    maxFileSize: number = 20000000;
    constructor(private mediaService: MediaService,
        private messageService: MessageService,
        private formBuilder: UntypedFormBuilder,
        private breadcrumbService: BreadcrumbService,
        private clipboard: Clipboard,
        private router: Router,
        public appUtil:AppUtil,
        private route: ActivatedRoute) {
        
        this.pathSegments = this.route.snapshot.data['pathSegments'];
        if(this.pathSegments != null && this.pathSegments.length > 0)
            this.itemSelectedForEditing = this.pathSegments[this.pathSegments.length-1];
    }

    ngOnInit(): void {

        if(!this.embeded)
        {
            this.breadcrumbService.setItems([
                { label: 'Media List' }
            ]);
        }
        
        if(this.onlyPublicImages)
        {
            this.accessType = 'public';
            
        }

        this.documentForm = this.formBuilder.group({
            isPublic: [false],
            urlKey: [''],
            file: [null, [Validators.required]],
        });
        this.uploadFormInitialValues = this.documentForm.value;
        this.form = this.formBuilder.group({
            uuid: [],
            name: ['', Validators.required]
        });
    }


    ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty("startAtFolder") && this.embeded
            && this.startAtFolder != null && this.startAtFolder != '') {
            this.mediaService.getItemsInPath(this.startAtFolder).subscribe(
                data => {
                    let segments = data as any;
                    this.pathSegments = segments;
                    if(this.pathSegments != null && this.pathSegments.length > 0)
                        this.itemSelectedForEditing = this.pathSegments[this.pathSegments.length-1];
                    
                    this.loadData();
                });

        }


    }

    search(first) {
        if (this.firstRow == 0) {
            this.loadData({ first: 0, rows: this.dataView.rows, sortField: this.sortField, sortOrder: this.sortOrder });
        }
        else {
            this.firstRow = 0;

        }
    }

    onSortChange(event) {
        let value = event.value;

        if (value.indexOf('!') === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        }
        else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

    onAccessTypeChange(event) {
        this.search(0);
    }

    getType(element) {
        let type = null;
        if (!element.folder) {
            let name = element.name.toLowerCase();
            type = 'unknown';
            if (name.endsWith('.xls') || name.endsWith('.xls')) {
                type = 'xls';
            }
            else if (name.endsWith('.doc') || name.endsWith('.docx')) {
                type = 'word';
            }
            else if (name.endsWith('.ppt') || name.endsWith('.pptx')) {
                type = 'ppt';
            }
            else if (name.endsWith('.txt') || name.endsWith('.log')) {
                type = 'text';
            }
            else if (name.endsWith('.png') || name.endsWith('.jpg') || name.endsWith('.jpeg') || name.endsWith('.bmp') || name.endsWith('.gif')) {
                type = 'image';
            }
        }
        return type;
    }

    _processListResponse(itemList) {
        let temp = [];
        if (itemList != null) {
            (itemList as any).forEach(element => {

                temp.push({ ...element, type: this.getType(element) });
            });
        }
        this.totalRecords = 10;
        this.items = temp;
        this.loading = false;
        if (!this.embeded) {
            if (this.pathSegments == null || this.pathSegments.length == 0) {
                this.router.navigate(['/media']);
            }
            else {
                this.router.navigate(['/media' + this.pathSegments[this.pathSegments.length - 1].itemPath]);
            }
        }
    }

    _getRootItems() {
        this.loading = true;
        this.mediaService.getRootFolders().subscribe(data => {
            this._processListResponse(data);
        });
    }

    _getMediaItem() {
        this.loading = true
        this.mediaService.getFolder(this.pathSegments[this.pathSegments.length - 1].uuid).subscribe(
            data => {
                let mediaItem = data as any;
                this._processListResponse(mediaItem.childItems);
            });
    }

    loadData(e: LazyLoadEvent = { first: 0, rows: 50, sortField: this.sortField, sortOrder: this.sortOrder }) {
        this.loading = true;
        var searchParams = {};
        searchParams['page'] = (e.first / e.rows);
        searchParams['pageSize'] = e.rows;
        var sortBy = []
        var sortDir = e.sortOrder == 1 ? 'ASC' : 'DESC';
        sortBy[sortBy.length] = { "property": e.sortField, "direction": sortDir };
        searchParams['sortDetails'] = sortBy;
        searchParams['rootRule'] = { 'condition': 'and', rules: [{ "field": "name", "value": this.term, "operator": "contains" },
        { "field": "accessType", "value": this.accessType, "operator": "exact" }] }

        let parentUuid = 'root';
        if (this.pathSegments != null) {
            parentUuid = this.pathSegments[this.pathSegments.length - 1].uuid
        }
        this.mediaLoading = true;
        this.mediaService.getFolderContent(parentUuid, searchParams).subscribe(
            data => {
                let paginatedList = data as any;
                this._processListResponse(paginatedList.records);
                this.totalRecords = paginatedList.totalRecords;
                this.mediaLoading = false;
            });

    }


    editItem(item) {
        if (item.folder) {
            this.newFolder();
            this.form.setValue({ uuid: item.uuid, name: item.name });
            this.folderTitle = "Rename Folder";
        }
        else {
            this.addDocument();
            this.documentForm.setValue({ urlKey: null, isPublic: item.availableToGuestUser, file: null });
            this.df.file.clearValidators();
            this.fileTitle = "Edit: " + item.name;
        }
        this.itemSelectedForEditing = item;
    }

    get f() { return this.form.controls; }

    newFolder() {
        this.itemSelectedForEditing = null;
        this.form.reset();
        Object.keys(this.f).forEach(key => {
            this.f[key].setErrors(null);
        });
        this.submitted = false;
        this.folderDialog = true;
        this.folderTitle = "New Folder";
    }

    hideFolderForm() {
        this.folderDialog = false;
    }

    saveFolder() {
        this.submitted = true;
        if (this.f.uuid.value != null && this.itemSelectedForEditing != null && this.itemSelectedForEditing.folder && (this.itemSelectedForEditing.name == this.f.name.value)) {
            this.hideFolderForm();
            return;
        }
        if (this.form.invalid) {
            return;
        }
        this.loadingSpinner = true;
        let apiCall: any;
        if (this.f.uuid.value != null) {
            apiCall = this.mediaService.updateFolder(this.form.value);
        }
        else {
            apiCall = this.mediaService.createFolder(this.form.value,
                this.pathSegments == null ? null : this.pathSegments[this.pathSegments.length - 1].uuid);
        }
        apiCall.subscribe(
            data => {
                let detail = 'Folder Created';
                if (this.f.uuid.value != null) {
                    detail = 'Folder Renamed';
                }
                this.loadData({ first: this.dataView.first, rows: this.dataView.rows, sortField: this.sortField, sortOrder: this.sortOrder });
                this.messageService.add({ severity: 'success', summary: 'Successfully', detail: detail, life: 3000 });
                this.hideFolderForm();
                this.loadingSpinner = false;
            },
            error => {
                this.loadingSpinner = false;
                let errors = error.error;
                if (errors.code && errors.code == 'VALIDATION_ERROR') {
                    for (var i = 0; i < errors.subErrors.length; i++) {
                        this.f[errors.subErrors[i].field].setErrors({ 'message': errors.subErrors[i].message });
                    }
                }
            }
        );
    }

    deleteFolder(item) {
        this.loadingSpinner = true;
        let deleteItem = true;
        if (item.folder && !item.leaf) {

        }

        if (deleteItem) {
            this.mediaService.deleteFolder(item.uuid).subscribe(
                data => {
                    this.messageService.add({ severity: 'success', summary: 'Successfully', detail: "Folder Deleted", life: 3000 });
                    this.loadingSpinner = false;
                },
                error => {
                    this.loadingSpinner = false;
                    let errors = error.error;
                    this.messageService.add({ severity: 'error', summary: 'Successfully', detail: error.error, life: 3000 });
                }
            );
        }
    }

    copyUrl(item: any) {
        this.clipboard.copy(item.url);
        this.messageService.add({ severity: 'success', summary: 'Successfully', detail: "Copied Url", life: 3000 });
        if (this.embeded) {
            this.onCopy.emit(item);
        }

    }

    goToFolder(item) {
        if (item == null) {
            this.pathSegments = null;
        }
        else {
            let temp = [];
            for (let i = 0; i < this.pathSegments.length; i++) {
                temp.push(this.pathSegments[i]);
                if (this.pathSegments[i].uuid == item.uuid) {
                    break;
                }
            }
            this.pathSegments = temp;
        }
        this.loadData({ first: 0, rows: this.dataView.rows, sortField: this.sortField, sortOrder: this.sortOrder });
    }

    openItem(event, item) {
        if (item.folder) {
            if (this.pathSegments == null || this.pathSegments.length == 0) {
                this.pathSegments = [item];
            }
            else {
                this.pathSegments.push(item);
            }
            this.loadData({ first: 0, rows: this.dataView.rows, sortField: this.sortField, sortOrder: this.sortOrder });

        }
        else if (!this.embeded) {

        }
        else {
            this.onSelect.emit(item);
        }

    }

    isPublicChecked() {
        if (this.df.isPublic.value) {
            this.documentForm.controls.urlKey.enable();
        }
        else {
            this.documentForm.controls.urlKey.disable();
        }
    }

    addDocument() {
        this.itemSelectedForEditing = this.pathSegments[this.pathSegments.length-1];
        this.fileTitle = "New File";
        this.documentDialog = true;
        this.documentForm.reset(this.uploadFormInitialValues);
        this.df.file.clearValidators;
        this.df.file.setValidators(Validators.required);
        this.isPublicChecked();
        this.submittedUpload = false;
        Object.keys(this.documentForm.controls).forEach(key => {
            this.documentForm.controls[key].setErrors(null);
        });
    }

    hideDocumentDialog() {
        this.documentDialog = false;
    }

    get df() { return this.documentForm.controls; }

    selectFile(event: any, fileUpload) {
        const file = event.files[0];
        this.documentForm.get('file').setValue(file);
    }

    deleteDocument(event: any, fileUpload) {
        this.df.file.setValue(null);
    }

    saveDocument() {
        this.submittedUpload = true;
        if (this.itemSelectedForEditing != null && !this.itemSelectedForEditing.folder 
            && (this.itemSelectedForEditing.availableToGuestUser == this.df.isPublic.value)
            && (this.df.file.value == null)) {
            this.hideDocumentDialog();
            return;
        }
        if (this.documentForm.invalid) {
            return;
        }
        const formData = new FormData();
        if (this.df.file.value != null) {
            formData.append('file', this.documentForm.get('file').value);
        }
        else {
            formData.append('file', null);
        }

        formData.append('availableToGuestUser', this.df.isPublic.value);
        formData.append('urlKey', this.df.urlKey.value);
        let apiToCall: any;
        if (this.itemSelectedForEditing == null || this.itemSelectedForEditing.folder) {
            apiToCall = this.mediaService.uploadMedia(formData, this.pathSegments[this.pathSegments.length - 1].uuid);
        }
        else {
            apiToCall = this.mediaService.updateMedia(formData, this.itemSelectedForEditing.uuid);
        }

        apiToCall.subscribe(
            data => {
                this.loadData({ first: this.dataView.first, rows: this.dataView.rows, sortField: this.sortField, sortOrder: this.sortOrder });
                if (this.itemSelectedForEditing == null) {
                    this.messageService.add({ severity: 'success', summary: 'Successful', detail: "File uploaded successfully", life: 3000 });
                }
                else {
                    this.messageService.add({ severity: 'success', summary: 'Successful', detail: "File uploaded successfully", life: 3000 });
                }

                this.hideDocumentDialog();
            },
            error => {
                let errors = error.error;
                if (errors.code && errors.code == 'VALIDATION_ERROR') {
                    for (var i = 0; i < errors.subErrors.length; i++) {
                        this.df[errors.subErrors[i].field].setErrors({ 'message': errors.subErrors[i].message });
                    }
                }
            });
    }

}