import { TouchSequence } from 'selenium-webdriver';
import { FieldInfo } from './field-info';

export interface RuleSet {
    condition: string;
    rules: Array<Rule>;
    ruleSets: Array<RuleSet>;
}
export class Rule {
    field: FieldInfo;
    value?: any;
    maxValue?: any;
    operator?: string;
    label?: string;

    constructor(field: any, value: any) {
        this.field = field;
        this.operator = "matches";
        if (this.field.type != 'string' && this.field.type != 'rating' && this.field.type != 'Account') {
            this.operator = "=";
        }
        this.value = value;
        if(this.value == null && this.field.type == 'boolean')
        {
            this.value = true;
        }
    }

    prepareLabel(): void {
        let lbl = '';
        if(this.operator == 'exists')
        {
            lbl = 'Assigned';
        }
        else if(this.operator == 'notexists')
        {
            lbl = 'Not Assisgned';
        }
        else if (this.value == null || (typeof this.value == 'string' && this.value == '') || (Array.isArray(this.value) && this.value.length == 0)) 
        {
            lbl = "All";
        }
        else if (this.field.searchType == 'boolean') {

            if(typeof this.value == 'string'){
                lbl = (this.value == 'true') ? "Yes" : "No";
            } else {
                lbl = this.value ? "Yes" : "No";
            }
        }
        else if (this.field.searchType == 'integer' || this.field.searchType == 'double' || this.field.searchType == 'date') {
            if(this.operator == 'between')
            {
                if(this.value && this.value!='' && this.maxValue && this.maxValue != '')
                {
                    lbl = this.value + ' to ' + this.maxValue;
                }
                else if(this.value && this.value != '')
                {
                    lbl = '>=' + this.value;
                }
                else if(this.maxValue && this.maxValue != '')
                {
                    lbl = '<=' + this.maxValue;
                }               
            }
            else
            {
                lbl = this.value;
            }
        }
        else if (this.field.searchType == 'exact') {
            let data = this.field.searchOptions.filter(function (current: any) {
                let found: boolean = false;
                for (var i = 0; i < this.value.length; i++) {
                    let val: any = this.value[i] as any;
                    if (val == current[this.valueProperty]) {
                        found = true;
                    }
                }
                return found;
            }, { value: this.value, valueProperty: this.field.valueProperty });
            for (let i = 0; i < data.length; i++) {
                if (i != 0) {
                    lbl = lbl + ";"
                }
                lbl = lbl + data[i][this.field.labelProperty];
            }
        }
        else {
            lbl = this.value;
        }
        this.label = lbl;
    }
}

export function makeRule(field: any, value: any, operator:string)
{
    const rule = new Rule(field,value);
    rule.operator = operator;
    return rule;
}