import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AccountService } from './../_services';
import { AuthUser } from '../_models';
import { catchError, firstValueFrom, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserActivityService } from '../users-activity/users-activity.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private accountService: AccountService,private userActivityService: UserActivityService

    ) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const user = this.accountService.userValue;
        if (user != null) {
          return true;
        } else {
          try {
            const data = await this.accountService.getProfile().toPromise();
            const authUser = new AuthUser();
            authUser.name = data.name;
            authUser.uuid = data.uuid;
            authUser.accessToken = null;
            authUser.expiresIn = null;
            authUser.refreshToken = null;
            authUser.refreshTokenExpiresIn = null;
            authUser.role = data.internalUser ? 1 : 0;
            authUser.status = data.status;
            authUser.storageTime = new Date().getTime();
            this.accountService.storeAuthUser(authUser);
            this.userActivityService.logLoginActivity(authUser.name);
            return true;
          } catch (error) {
            return false;
          }
        }
      }
    }