import { Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AutoComplete } from 'primeng/autocomplete';
import { AppUtil } from 'src/app/_helpers';
export const LOOKUP_AUTOCOMPLETE_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => LookupcompleteComponent),
    multi: true
};

@Component({
    selector: 'look-autocomplete',
    templateUrl: './lookup.component.html',
    providers: [LOOKUP_AUTOCOMPLETE_VALUE_ACCESSOR],
    host: {
        '[class.p-inputwrapper-filled]': 'filled',
        '[class.p-inputwrapper-focus]': '(focused && !disabled) || overlayVisible'
    },
})
export class LookupcompleteComponent implements OnInit {

    @Input() multiple: boolean = true;
    @Input() completeOnFocus: boolean = true;
    @Input() field: string = "value";
    @Input() dataKey: string = "uuid";
    @Input() items: any[];
    @Input() forceSelection: boolean = true;
    @Input() disabled: boolean = false;
    @Input() placeholder: string;
    @Input() entityType: string;
    @Input() entityId: string;
    @Input() type: string; // one of LEVEL1, LEVEL2, LEVEL3, VERTICAL, ECOSYSTEM, ACCOUNT, SPOTLIGHT, AN OTHER LOOKUP VALUES Like COUNTRY, STATE, LEAD_SOURCE, REVENUE, NUM_OF_EMPLOYEES
    //lookup and create services will  be decided based on type and also the pop-up that needs to be shown.

    @ViewChild('ac') autocomplete: AutoComplete;
    autoCompleteResults: any[];
    autocompleteValue: any;
    filled: boolean = false;
    focused: boolean = false;
    overlayVisible: boolean = false;
    private onTouched!: Function;
    private onChanged!: Function;
    lookupDialog: boolean = false;
    inputTextvalue: string;
    constructor( public appUtil:AppUtil) {
        this.onChanged = () => { };
        this.onTouched = () => { };
    }

    ngOnInit(): void {
        this.checkFilled();
    }


    private _showOption(result, exactFound, term) {
        if (this.items == undefined) {
            this.items = [];
        }
        if (term && term != '') {
            result = this.items.filter((currentValue, index) => {
                if (currentValue[this.field].toLowerCase() == term.toLowerCase()) {
                    exactFound = true;
                }
                return currentValue[this.field].toLowerCase().indexOf(term.toLowerCase()) != -1;
            });
            if (!exactFound) {
                var toadd = { uuid: 'tmp-' + new Date().getTime(), tempName: term };
                toadd[this.field] = '';
                result.unshift(toadd);
            }
        }
        else {
            result = [...this.items];
        }
        return result;
    }

    get checkType()
    {
        return  (this.type == "ACCOUNT_SOURCE" || this.type == "REVENUE" || this.type == "No_OF_EMPLOYEES" || this.type == "MAILING_LIST" || this.type == "ORG_TYPE_INVESTOR"
        || this.type == "ORG_TYPE_LENDER" || this.type == "ORG_TYPE_OTHER"
        || this.type == "EBITDA"
        || this.type == "DOLLOR_VALUE_RANGE"
        || this.type=="PROSPECTS_STATUS"
        || this.type=="BUYSIDE_PROSPECT_LOOKUP_1"
        || this.type=="BUYSIDE_PROSPECT_LOOKUP_2"
        || this.type=="SELLSIDE_PROSPECT_LOOKUP_1"
        || this.type=="SELLSIDE_PROSPECT_LOOKUP_2")
    }

    searchItem($event) {
        let result = [];
        let exactFound = false;
        let term = $event.query;
        if (this.checkType){
            this.autoCompleteResults = this._showOption(result, exactFound, term) as any;
        }
    }

    focus() {
        this.focused = true;
    }

    blur() {
        this.focused = false;
        this.onTouched();
    }

    show() {
        this.overlayVisible = true;
    }

    hide() {
        this.overlayVisible = false;
    }

    select($event) {
        if ($event.uuid.includes('tmp-')) {
            if (this.multiple == true) {
                this.autocompleteValue = this.autocompleteValue.filter((currentValue, index) => {
                    return (currentValue.uuid != $event.uuid);
                });
            }
            if (this.multiple == false) {
                this.autocompleteValue = [$event];
            }
            if (this.checkType) {
                this.lookupDialog = true;
            }
            this.inputTextvalue = $event['tempName'];
        }
        this.checkFilled();
        this.onChanged(this.autocompleteValue);
    }

    checkFilled() {
        this.onChanged(this.autocompleteValue);
        if (this.autocompleteValue && this.autocompleteValue != null
            && ((Array.isArray(this.autocompleteValue) && this.autocompleteValue.length > 0)
                || (!Array.isArray(this.autocompleteValue) && this.autocompleteValue.length > 0))) {
            this.filled = true;

        }
        else {
            if (this.multiple == false)
                this.filled = true;
            else
                this.filled = false;
        }
    }

    writeValue(value) {
        this.autocompleteValue = value;
        this.checkFilled();
    }

    registerOnChange(fn) {
        this.onChanged = fn;
    }
    registerOnTouched(fn) {
        this.onTouched = fn;
    }
    setDisabledState(val) {
        this.disabled = val;
    }

    onFormGroupChangeEvent($event) {
        if (this.multiple == true)
            this.autocompleteValue = [...this.autocompleteValue, $event];
        else
            this.autocompleteValue = $event;

        if(this.items)
        {
            this.items.push($event);
            switch(this.type)
            {
                case "REVENUE": 
                case "No_OF_EMPLOYEES": 
                case "EBITDA": 
                case "BUYSIDE_PROSPECT_LOOKUP_1": 
                case "BUYSIDE_PROSPECT_LOOKUP_2": 
                case "SELLSIDE_PROSPECT_LOOKUP_1": 
                case "SELLSIDE_PROSPECT_LOOKUP_2": 
                case "DOLLOR_VALUE_RANGE": 
                    this.appUtil.sortByRangeProperties(this.items, "min", "max");
                    break;
                default:
                    this.appUtil.sortByStringProperty(this.items, this.field);
                    break;
            }
        }

        this.checkFilled();
        this.onChanged(this.autocompleteValue);
    }

    /* below will needed if a pop-up needs to be opened on addNew


  addItem($event)
  {
//console.log($event)
  }
  */
}
