import { SellsideService } from './../sellside/sellside.service';
import { BuysideService } from './../buyside/buyside.service';
import { EngagementService } from './../engagement/engagement.service';
import { SpotlightService } from './../spotlight/spotlight.service';
import { AccountService } from './../_services/account.service';
import { TaskService } from './task.service';
import { MessageService } from 'primeng/api';
import { AppUtil } from './../_helpers/app.util';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormGroup, FormControl, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Task, User } from '../_models';
import { OrganizationService } from '../organization/organization.service';
import { UsersService } from '../users/users.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
    selector: 'task-edit',
    templateUrl: './task-edit.component.html',
    styleUrls: ['./task-edit.component.scss'],
    providers: [MessageService]
})
export class TaskEditComponent implements OnInit {
    @Input() loadingSpinner: boolean = false;
    @Input() show: boolean = false;
    @Input() showQuickEdit: boolean = false;
    @Output() showChange = new EventEmitter<boolean>();
    @Input() taskUuid: string = null;
    @Output() taskUuidChange = new EventEmitter<string>();
    @Input() parentUuid: string = null;
    @Input() closeAfterSave: boolean = true;
    @Input() taskType: string = null; /* organization, contact */
    @Output() afterSave = new EventEmitter<any>();
    @Input() contact: User = null;
    @Input() prospectContact: User = null;
    @Input() parent = null;
    @ViewChild('taskform') formElement;
    visible: boolean = false;
    taskStatus: boolean = false;
    statusCompleted: any;
    title: string = "New Task";
    form: UntypedFormGroup;
    submitted: boolean = false;
    entityTypes = [{ value: 'Spotlight' }, { value: 'Industry Report' }, { value: 'Marketing' }, { value: 'Engagement Outreach' }, { value: 'BuySide Screen Outreach' }, { value: 'SellSide Screen Outreach' }];
    status = [{ code: 'STARTED', name: 'Started' }, { code: 'NOT_STARTED', name: 'Not Started' }, { code: 'COMPLETED', name: 'Completed' }];
    priority = [{ code: 'LOW', name: 'Low' }, { code: 'HIGH', name: 'High' }, { code: 'MEDIUM', name: "Medium" }];
    tbpEmployees = [];
    formInitialValue: any = null;
    spotlightResults: [];
    engagementResults: [];
    buySideResults: [];
    sellSideResults: [];
    organizationResults: [];
    contactResults: [];
    taskContact: [];
    task: Task;
    editingField: any = {};
    relatedTo: any = [];
    loading: boolean;
    minDate: Date;
    selectedHour: number = undefined;
    selectedMinute: number = undefined;
    selectedTask: any;
    reminderDate: string | undefined;
    assignedTo: string | undefined;
    showRelatedTypeDialog = false;
    field: string = '';
    initialFieldValues: { [fieldName: string]: any } = {};
    searchUrl=[]
    relatedTypeLabels: { [key: string]: { label: string, property: string } } = {
        'Spotlight': { label: 'Spotlight', property: 'spotlight' },
        'BuySide Screen Outreach': { label: 'BuySide Screen Outreach', property: 'buySideScreen' },
        'SellSide Screen Outreach': { label: 'SellSide Screen Outreach', property: 'sellSideScreen' },
        'Engagement Outreach': { label: 'Engagement Outreach', property: 'engagement' },
    }
    constructor(private route: ActivatedRoute,
        private messageService: MessageService,
        private formBuilder: UntypedFormBuilder,
        private taskService: TaskService,
        public accountService: AccountService,
        private spotlightService: SpotlightService,
        private engagementService: EngagementService,
        private buySideService: BuysideService,
        private sellSideService: SellsideService,
        private organizationService: OrganizationService,
        private userService: UsersService,
        public appUtil: AppUtil,
        private router: Router,
    ) {
       
        this.form = this.formBuilder.group({
            uuid: [],
            assignedTo: [null, Validators.required],
            subject: [null, [Validators.required, this.notOnlyWhitespaceValidator()]],
            dueDate: [],
            relatedType: [{ value: 'Spotlight' }],
            spotlight: [],
            engagement: [],
            buySideScreen: [],
            sellSideScreen: [],
            relatedTo: [],
            status: ['NOT_STARTED'],
            priority: ['MEDIUM'],
            reminderChecked: false,
            reminderDate: [],
            description: [],
            organization: [],
            contacts: [],
            completionDate: [null],
            completionComment: [],
        });
        this.formInitialValue = this.form.value;
        this.tbpEmployees = this.route.snapshot.data['tbpEmployees'];
        this.taskContact = this.route.snapshot.data['tbpEmployees'];

        this.minDate = new Date();
    
 
    }

    ngOnInit(): void {
    }
    notOnlyWhitespaceValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value && control.value.trim().length === 0) {
                return { 'whitespace': true };
            }
            return null;
        };
    }
    ngOnChanges(changes: SimpleChanges) {
        this.form.reset(this.formInitialValue);
        if (this.show) {
            this.title = "New Task";
            if (this.taskUuid == null || this.taskUuid == '') {
                if (this.taskType === "organization") {
                    this.f.organization.setValue(this.parent)
                    this.f.organization.disable();
                }
                else if (this.taskType === "contact") {
                    const obj = this.parent;
                    this.f.contacts.setValue([this.parent])
                    this.f.organization.setValue(((obj == null) ? null : obj.organization));
                }
                else if (this.taskType === "engagement") {
                    this.f.engagement.setValue(this.parent)
                }
                else if (this.taskType === "sellside") {
                    const obj = this.parent;
                    this.f.sellSideScreen.setValue(this.parent)
                    this.f.relatedType.setValue({ 'value': 'SellSide Screen Outreach' })
                    this.f.relatedType.disable()
                    this.f.sellSideScreen.disable()
                    this.f.organization.setValue(((obj == null) ? null : obj.organization));
                }
                else if (this.taskType === "buyside") {
                    const obj = this.parent;
                    this.f.buySideScreen.setValue(this.parent)
                    this.f.relatedType.setValue({ 'value': 'BuySide Screen Outreach' })
                    this.f.relatedType.disable()
                    this.f.buySideScreen.disable()
                    this.f.organization.setValue(((obj == null) ? null : obj.organization));
                }
                else if (this.taskType === "buyside-prospects") {
                    this.f.buySideScreen.setValue(this.parent.buyside)
                    this.f.relatedType.setValue({ 'value': 'BuySide Screen Outreach' })
                    this.f.relatedType.disable()
                    this.f.buySideScreen.disable()
                    const obj = this.parent;
                    this.f.organization.setValue(((obj == null) ? null : obj.organization));
                    if (obj.organization != null) {
                        this.f.organization.disable();
                    }
                    if (Array.isArray(obj.contacts) && obj.contacts.length > 0 && this.prospectContact == undefined) {
                        this.f.contacts.setValue([obj.contacts[0]]);
                    } else if (this.prospectContact != undefined) {
                        this.f.contacts.setValue([this.prospectContact]);
                    }
                    else {
                        this.f.contacts.setValue(null);
                    }
                    this.f.assignedTo.setValue(((obj.employees == null) ? null : obj.employees[0].uuid));
                }
                else if (this.taskType === "sellside-prospects") {
                    this.f.sellSideScreen.setValue(this.parent.sellside)
                    this.f.relatedType.setValue({ 'value': 'SellSide Screen Outreach' })
                    this.f.relatedType.disable()
                    this.f.sellSideScreen.disable()
                    const obj = this.parent;
                    this.f.organization.setValue(((obj == null) ? null : obj.organization));
                    if (obj.organization != null) {
                        this.f.organization.disable();
                    }
                    if (Array.isArray(obj.contacts) && obj.contacts.length > 0 && this.prospectContact == undefined) {
                        this.f.contacts.setValue([obj.contacts[0]]);
                    } else if (this.prospectContact != undefined) {
                        this.f.contacts.setValue([this.prospectContact]);
                    }
                    else {
                        this.f.contacts.setValue(null);
                    }
                    this.f.assignedTo.setValue(((obj.employees == null) ? null : obj.employees[0].uuid));
                }
                if (this.f.assignedTo.value == null) {
                    this.f.assignedTo.setValue(this.accountService.userValue.uuid);
                }
            }
            else {
                this.show = false;
                this.showQuickEdit = true;
                this.title = "Edit Task";
                if (this.taskType == 'dashboard') {
                    this.userService.getTBPEmployees().subscribe(data => {
                        this.tbpEmployees = data;
                    });
                }
                this.taskService.get(this.taskUuid).subscribe(data => {
                    this.modelToForm(data as any);
                    this.task = data;
                    if (this.task.reminderDate != null) {
                        this.task.reminderDate = this.appUtil.viewformatDate(this.task.reminderDate);
                    }
                });
            }
        }
    }

    get f() { return this.form.controls; }
    hide() {
        this.form.reset(this.formInitialValue);
        this.submitted = false;
        this.show = false;
        this.showQuickEdit = false;
        this.showChange.emit(false);
        this.taskUuid = null;
        this.taskUuidChange.emit(null);
        this.editField(null)
    }
    save() {
        this.loading = true;
        if (this.f.status.value === 'COMPLETED') {
            this.f.reminderDate.setValue(null);
        }
        if (this.f.relatedType.value === null) {
            this.f.sellSideScreen.setValue(null);
            this.f.buySideScreen.setValue(null);
            this.f.spotlight.setValue(null);
            this.f.engagement.setValue(null);
        }
        if (this.f.status.value === 'COMPLETED' && this.f.completionDate.value == null) {
            this.f.completionDate.setErrors({ 'message': 'Completion Date required for Completed task.' });
        }
        if (this.f.completionDate.value != null && this.f.dueDate.value == null) {
            this.f.reminderDate.setValue(null);
            this.f.dueDate.setErrors({ 'message': 'Due date required for Completed Task.' });
        }
        if (this.f.reminderDate.value != null && this.f.dueDate.value == null) {
            this.f.completionDate.setValue(null);
            this.f.dueDate.setErrors({ 'message': 'Due date required for reminder date.' });
        }
        if (this.f.dueDate.value != null && this.f.reminderDate.value != null) {
            const dueDate = this.appUtil.calendarToDbDateFormat(this.f.dueDate.value);
            const reminderDate = this.appUtil.calenderToDbDate(this.f.reminderDate.value);
            const newDue = dueDate.split("T");
            const newrem = reminderDate.split("T");
            const reminderTime = new Date(this.f.reminderDate.value);
            const minutes = reminderTime.getMinutes();
            if (newDue[0] < newrem[0] || newDue[0] == newrem[0]) {
                this.f.dueDate.setErrors({ 'message': 'Due Date must be greater than Reminder Date.' });
            } else if (minutes != 0 && minutes != 30) {
                this.f.reminderDate.setErrors({ 'message': 'Invalid time selection. Please select a time with minutes 0 or 30.' });
            }
            else {
                this.f.dueDate.setErrors(null);
                this.f.reminderDate.setErrors(null);
            }
        }
        this.submitted = true;
        if (this.form.invalid) {
            this.handleError(null);
            return;
        }
        let { uuid, assignedTo, subject, relatedType, dueDate, spotlight, engagement, buySideScreen, sellSideScreen, relatedTo, status, priority, reminderChecked, reminderDate, description, completionDate, completionComment } = this.form.value;
        let dataToPost: any = { uuid, assignedTo, subject, relatedType, dueDate, spotlight, engagement, buySideScreen, sellSideScreen, relatedTo, status, priority, reminderChecked, reminderDate, description, completionDate, completionComment };

        if (this.f.assignedTo.value != null) {
            dataToPost.assignedTo = this.appUtil.getObject(this.tbpEmployees, this.f.assignedTo.value);
        }
        if (this.f.buySideScreen.value != null) {
            dataToPost['buySideScreen'] = this.f.buySideScreen.value;
        }
        if (this.f.sellSideScreen.value != null) {
            dataToPost['sellSideScreen'] = this.f.sellSideScreen.value;
        }
        if (this.f.organization.value != null) {
            dataToPost['organization'] = this.f.organization.value;
        }
        if (this.f.contacts.value != null) {
            dataToPost['contacts'] = this.f.contacts.value;
        }
        if (this.f.dueDate.value != null) {
            dataToPost['dueDate'] = this.appUtil.calendarToDbDateFormat(this.f.dueDate.value);
        }
        if (this.f.completionDate.value != null) {
            dataToPost['reminderDate'] = null;
            dataToPost['completionDate'] = this.appUtil.calendarToDbDateFormat(this.f.completionDate.value);
        }
        if (this.f.completionComment.value != null) {
            dataToPost['completionComment'] = this.f.completionComment.value;
        }
        if (this.f.relatedType.value != null) {
            dataToPost['relatedType'] = this.f.relatedType.value.value;
        }
        if (this.f.reminderDate.value != null) {
            dataToPost['completionDate'] = null;
            dataToPost['reminderDate'] = this.appUtil.calenderToDbDate(this.f.reminderDate.value);
        }
        let apiToCall: any;
        if (dataToPost['uuid'] != null) {
            apiToCall = this.taskService.updateTask(this.f.uuid.value, dataToPost);
        }
        else {
            apiToCall = this.taskService.addTask(dataToPost);
        }
        apiToCall.subscribe(
            data => {
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: "Save Successfully", life: 3000 });
                this.submitted = false;
                window.location.reload();
                this.afterSave.emit(data);
                this.loading = false;
                if (this.closeAfterSave) {
                    this.taskService.filter(data);
                    this.hide();
                }
            },
            error => {
                let errors = error.error;
                if (errors.code && errors.code == 'VALIDATION_ERROR') {
                    for (var i = 0; i < errors.subErrors.length; i++) {
                        this.f[errors.subErrors[i].field].setErrors({ 'message': errors.subErrors[i].message });
                    }
                    this.handleError(errors.subErrors[0].field);
                }
                this.loading = false;
            },
        );
    }

    modelToForm(task: any) {      
        if (task.dueDate != null) {
            task.dueDate = this.appUtil.DbToCalendarDateFormat(task.dueDate);
        }
        this.form.setValue({
            uuid: task.uuid,
            
            subject: task.subject,
            assignedTo: (task.assignedTo == null) ? null : task.assignedTo?.uuid,
            dueDate: (task.dueDate == null) ? null : task.dueDate,
            relatedType: (task.relatedType == null) ? { 'value': '' } : { 'value': task.relatedType },
            status: task.status,
            priority: task.priority,
            reminderDate: (task.reminderDate == null) ? null : this.appUtil.viewformatDate(task.reminderDate),
            description: task.description,
            reminderChecked: false,
            spotlight: task.spotlight,
            engagement: task.engagement,
            buySideScreen: (task.buySideScreen == null) ? null : task.buySideScreen,
            sellSideScreen: (task.sellSideScreen == null) ? null : task.sellSideScreen,
            relatedTo: task.relatedTo,
            organization: task.organization,
            contacts: task.contacts,
            completionDate: (task.completionDate == null) ? null : this.appUtil.viewformatDate(task.completionDate),
            completionComment: task.completionComment,
        });
        if ((this.form.controls.buySideScreen != null) && (this.taskType === "buyside")) {
            this.form.controls.buySideScreen.disable();
            this.form.controls.relatedType.disable();
        }
        if ((this.form.controls.sellSideScreen != null) && (this.taskType === "sellside")) {
            this.form.controls.sellSideScreen.disable();
            this.form.controls.relatedType.disable();
        }
    }

    handleError(elemId) {
        this.messageService.add({ severity: 'error', summary: 'Form Errors', detail: "Form has errors. Please fix them first", life: 3000 });
        let invalidControl = this.formElement.nativeElement.querySelector('.ng-invalid');
        this.loading = false;
        if (invalidControl) {
            invalidControl.focus();
        }
        else if (elemId != null) {
            invalidControl = this.formElement.nativeElement.querySelector('#' + elemId);
            if (invalidControl) {
                invalidControl.focus();
            }
        }
    }

    searchSpotlights(event) {
        this.spotlightService.lookupSpotlight(event.query).subscribe(data => {
            this.spotlightResults = data as any;
        });
    }

    searchEngagements(event) {
        this.engagementService.lookupEngagement(event.query).subscribe(data => {
            this.engagementResults = data as any;
        });
    }

    searchBuySide(event) {
        this.buySideService.lookupBuySide(event.query).subscribe(data => {
            this.buySideResults = data as any;
        });
    }

    searchSellSide(event) {
        this.sellSideService.lookupSellSide(event.query).subscribe(data => {
            this.sellSideResults = data as any;
        });
    }
    searchOrganizations(event) {
        this.organizationService.lookupOrganization(event.query).subscribe(data => {
            this.organizationResults = data as any;
        });
    }
    searchContacts(event) {
        const inputValue = event.query.toLowerCase();
        if (this.parent && this.parent.contacts && this.parent.contacts.length > 0) {
            this.contactResults = this.parent.contacts.filter(contact =>
                contact.name.toLowerCase().includes(inputValue)
            );
        } else {
            this.userService.lookupContact(event.query).subscribe(data => {
                this.organizationResults = data as any;
                this.contactResults = this.organizationResults;
            });
        }
    }
    deepCopy(obj: any ): any {
        if (obj === undefined) {
            return obj;
        }
        return JSON.parse(JSON.stringify(obj));
      }
   
    editField(oldVal: string) {
        if(oldVal != null){
            this.initialFieldValues[oldVal] = this.deepCopy(this.task[oldVal]);
            for (let key in this.editingField) {            
                this.editingField[key] = false;
              }
              this.editingField[oldVal] = true;
        }
       
    }
    updateField(fieldName: string, newValue: any) {
        if (this.task.relatedType === null) {
            this.task.sellSideScreen = null;
            this.task.buySideScreen = null;
            this.task.spotlight = null;
            this.task.engagement = null;
        }
        if (this.task.completionDate != null && this.task.dueDate == null) {
            this.task.reminderDate = null;
            this.messageService.add({ severity: 'error', summary: 'Error', detail: "Due date required for Completed Task.", life: 3000 });
            return;
        }
        if (this.task.dueDate != null && this.task.reminderDate != null) {
            const dueDate = this.appUtil.calendarToDbDateFormat(this.task.dueDate);
            const reminderDate = this.appUtil.calenderToDbDate(this.task.reminderDate);
            const newDue = dueDate.split("T");
            const newrem = reminderDate.split("T");
            const reminderTime = new Date(this.task.reminderDate);
            const minutes = reminderTime.getMinutes();
            if (newDue[0] < newrem[0] || newDue[0] == newrem[0]) {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: "Due Date must be greater than Reminder Date.", life: 3000 });
                return;
            } else if (minutes != 0 && minutes != 30) {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: "Invalid time selection. Please select a time with minutes 0 or 30.", life: 3000 });
                return;
            }
        }
        if ((fieldName === 'subject') && (newValue.trim() == null || newValue.trim() == '')) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: "Next step is Required", life: 3000 });
            return;
        }
        let dataToPost = { uuid: this.task.uuid };
        if (fieldName == 'contacts') {
            dataToPost[fieldName] = newValue.map(item => item);
            this.editingField[fieldName] = false;
        }
        else if (fieldName == 'organization') {
            dataToPost[fieldName] = newValue;
            this.editingField[fieldName] = false;
        }
        else if (fieldName == 'assignedTo') {
            dataToPost[fieldName] = newValue;
        }
        else if (fieldName == 'reminderDate') {
            this.task.completionDate = null;
            dataToPost['reminderDate'] = this.appUtil.calenderToDbDate(newValue);
        }
        else if (fieldName == 'dueDate') {
            dataToPost['dueDate'] = this.appUtil.calendarToDbDateFormat(newValue);
        }
        else {
            dataToPost[fieldName] = newValue;
        }
        this.taskService.quickUpdateTask([fieldName], dataToPost).subscribe(
            data => {
                this.loading = false;
                this.editingField[fieldName] = false;
                this.afterSave.emit(data);
                event.stopPropagation();
            })
    }
    closeDescriptionDialog(fieldName: string) {
        this.editingField[fieldName] = false;
        event.stopPropagation();
    }
    formatDate(dateString: string): string {
        if (!dateString) {
            return '';
        }
        const date = new Date(dateString);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        const year = date.getFullYear();
        return `${month}-${day}-${year}`;
    }
    getRelatedType(value: any) {
        if (value && typeof value === 'object') {
            return value.value;
        }
        return value;
    }
    updateCombineFields(task:any,fieldName:string){
        this.task.completionDate =  this.task.completionDate == null? null : this.appUtil.viewformatDate(this.task.completionDate);
            this.field = fieldName;
            this.showRelatedTypeDialog =true;
    }
    hideDialog() {
        this.showRelatedTypeDialog =false;
         this.form.reset(this.formInitialValue);
          this.f.relatedType.setValue({'value':this.task.relatedType});
    }
   
    submitTask(task ,fieldName:string ,relatedType:any){
        if ((fieldName === 'Status' && task.status === 'COMPLETED') || this.task.status === 'COMPLETED') {
            if (this.task.completionDate == null) {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: "Completion Date required for Completed task.", life: 3000 });
                return;
            }
        }
        let dataToPost = { uuid: this.task.uuid };
        let fieldsName = [];
        if (fieldName == 'Status') {
            dataToPost[fieldName.toLowerCase()] = task.status;
            fieldsName.push('status');
            if(task.status == 'COMPLETED'){
                dataToPost['completionDate'] = this.appUtil.calendarToDbDateFormat(task.completionDate?task.completionDate:null);
                dataToPost['completionComment'] = task.completionComment?task.completionComment:'';
                fieldsName.push('completionDate');
                fieldsName.push('completionComment');
               
            }
        }else{
            if(fieldName == 'Type'){
                if(relatedType == 'Spotlight'){
                    dataToPost['spotlight'] = task.spotlight;
                    fieldsName.push('spotlight');
                }if(relatedType == 'SellSide Screen Outreach'){
                    dataToPost['sellSideScreen'] = task.sellSideScreen;
                    fieldsName.push('sellSideScreen');
                }
                if(relatedType == 'BuySide Screen Outreach'){
                    dataToPost['buySideScreen'] = task.buySideScreen;
                    fieldsName.push('buySideScreen');
                }
                if
                (relatedType == 'Engagement Outreach'){
                    dataToPost['engagement'] = task.engagement;
                    fieldsName.push('engagement');
                }
                dataToPost['relatedType'] = relatedType;
                fieldsName.push('relatedType');
            }
        }
        this.taskService.quickUpdateTask(fieldsName, dataToPost).subscribe(
            data => {
                this.task = data;
                this.loading = false;
                this.editingField[fieldName] = false;
                this.afterSave.emit(data);
                this.modelToForm(data);
                event.stopPropagation();
            })
        this.showRelatedTypeDialog =false;
    }

}