import { SellsideService } from './sellside.service';
 import { AccountService } from './../_services/account.service';
import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UsersService } from '../users/users.service';
import { OrganizationService } from '../organization/organization.service';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup,   UntypedFormBuilder, FormBuilder } from '@angular/forms';
import { AppUtil } from '../_helpers';
import { ConfigurationService } from '../configuration/configuration.service';
import { Classification } from '../_models';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'sellside-view-include',
  templateUrl: './sellside-view-include.component.html',
  styleUrls: ['./sellside-view-include.component.scss']
})
export class SellsideViewIncludeComponent implements OnInit {

    @Input() model:any;
    @ViewChild('cform') formElement;
    form: UntypedFormGroup;
    loading: boolean;
    organizationResults: any;
    contactResults: any;
    timeline: any[];
    debt: any[];
    equity:any[];
    activeIndex: number;
    formsubmited: boolean = false;
    privateEquityPartner:any[];
    managementPreference: any[];
    classificationField: any;
    editingField: any ={};
    tbpEmployees: any;
    oldValues: any;
    lookupList: any = {  REVENUE: [], EBITDA: [], DOLLOR_VALUE_RANGE: [] };
    classificationList: any = {
      CATEGORY_LEVEL1: [], CATEGORY_LEVEL2: [], CATEGORY_LEVEL3: [],
      VERTICAL: [], ECOSYSTEM: []
  };

  constructor(
    private organizationService : OrganizationService,
    public accountService:AccountService,
    private userService :UsersService,
    private route: ActivatedRoute,
    private appUtil:AppUtil,
    private formBuilder: UntypedFormBuilder,
    private lookupService: ConfigurationService,
    private sellsideService: SellsideService,
    private messageService: MessageService,
     ) { 
      this.debt = [
        {value: 'A/R Financing'},
        {value: 'Junior Debt'},
        {value: 'Mezzanine Financing'},
        {value: 'Senior Debt'},
    ];
    this.equity = [
        {value: 'Growth Equity'},
        {value: 'Venture Capital'},
        {value: 'Angel Investment'},
        {value: 'Minority Recap'},
    ];
      this.timeline = [
        {value: '3-6 months'},
        {value: 'Next 3 months'},
        {value: 'No immediate plans'},
    ];
    this.managementPreference = [
      {value: 'Flexible'},
      {value: 'Management will exit'},
      {value: 'Management will stay'},
    ];
    this.privateEquityPartner = [
      {value: 'Control'},
      {value: '100%'},
      {value: 'Minority'},
  ];
     }
     loadSellSideForm() {
      this.form = this.formBuilder.group({
          uuid: [''],
          strategicPartner: [false],
          equityPartnerType:[],
          equityType: [],
          debtType: []
      });
  }

  ngOnInit(): void {
    this.classificationList = this.route.snapshot.data['classificationList'];
    this.lookupList = this.route.snapshot.data['lookupList'];
    this.tbpEmployees = this.route.snapshot.data['tbpEmployees']; 
    this.loadSellSideForm(); 
    this.sellsideService.getSellside(this.route.snapshot.params.uuid).subscribe(data => {
      this.modelToForm(data as any);
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.model && (typeof changes.model.currentValue !== 'undefined') 
        && changes.model.currentValue.primaryObjectives!=null
        &&  (typeof changes.model.currentValue.primaryObjectives !== 'undefined')) {
        this.model = changes.model.currentValue;
    }
  }
  initEdit(field: string, data: any) {
    if(( field === 'organization') && data === null ){
      this.messageService.add({ severity: 'error', summary: 'Error', detail: "Account is Required", life: 3000});
      return;  
    }
    if (data === undefined) {
      this.oldValues = (data);
    } else {
      this.oldValues = JSON.parse(JSON.stringify(data));
    }
    }
  searchOrganizations(event) {
    this.organizationService.lookupOrganization(event.query).subscribe(data => {
        this.organizationResults = data as any;
    });
    this.userService.lookupContact(event.query).subscribe(data => {
      this.contactResults = data as any;
  }); 
}
splitAndCapitalize(field: string): string {
  return field
    .split(/(?=[A-Z])/)
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}
updateField(fieldName: string, newValue: any){
  let dataToPost = { uuid: this.model.uuid };
  if(fieldName ==='classification' && newValue.level1.length === 0 ){
    this.messageService.add({ severity: 'error', summary: 'Error', detail: "Level1 is Required", life: 3000});
    return;
  } 
  else if((fieldName === 'screenTitle' || fieldName === 'externalHeadline') && newValue === ""){
    const readableField = this.splitAndCapitalize(fieldName);
  this.messageService.add({ severity: 'error', summary: 'Error', detail: readableField+" is Required", life: 3000}); 
  return;   
  } 
if(this.oldValues === newValue){
  if(fieldName ==='companyDescription' || fieldName ==='transactionObjective'){
    this.closeDialog(fieldName);
  }
  else{
     this.closeDialog(fieldName);
  }
  return;
}
if(fieldName == 'contacts'){
 dataToPost[fieldName] = newValue.map(item => item.uuid);
 this.editingField[fieldName] = false;
}
else if(fieldName == 'targetEbitda' || fieldName == 'dollorValueRange' || fieldName == 'targetRevenue'){
  this.editingField[fieldName] = false;
  dataToPost[fieldName] = newValue.value;
}
else if (fieldName=='classification') {
  dataToPost['classification'] = newValue ? newValue: null;
  this.editingField[this.classificationField] = false;
}
else if(fieldName=='organization'){
   dataToPost[fieldName] = newValue.uuid;
   this.editingField[fieldName] = false;
}
else{
  dataToPost[fieldName] = newValue;
  this.editingField[fieldName] = false;
}
this.model[fieldName] = newValue;
this.sellsideService.updateSellsideList([fieldName], dataToPost).subscribe(
  data => {
      this.loading = false; 
      if (fieldName === 'classification') {
        const relatedFields = ['Level1', 'Level2', 'Level3', 'Vertical/Industry', 'Ecosystem'];
        relatedFields.forEach(field => this.editingField[field] = false);
    } else {
      this.closeDialog(fieldName);
    }
    this.messageService.add({ severity: 'success', summary: 'Successful', detail: `${this.formatFieldName(fieldName)} Updated Successfully`, life: 3000 });
    this.ngOnInit();   
  })
}
formatFieldName(fieldName: string): string {

  const words = fieldName.split(/(?=[A-Z])/);
  return words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
}
editField(oldVal: string, data: any){

  for (let key in this.editingField) {            
    this.editingField[key] = false;
  }
    this.initEdit(oldVal, data);
    this.editingField[oldVal] = true;
}
onSelectionChange(field : string) {
  if(field === 'targetRevenue' || field === 'targetEbitda'){
    this.updateField(field, this.model[field]);
  }
  else{
  this.classificationField = field;
  this.updateField('classification', this.model?.classification);
}
}
closeDialog(fieldName: string) { 
  this.editingField[fieldName] = false;
  event.stopPropagation();
} 

getOutput( value: any) {
  if (value && typeof value === 'object') {
      return value.value;
  }
  return value;
}

nextStep() {
  let hasError:boolean = false;
 
      this.validatePrimaryObjective(null);
      hasError =  this.f.strategicPartner.invalid
          || (this.f.equityPartnerType.invalid)
          || this.f.debtType.invalid || this.f.equityType.invalid 
          
 
  // if(hasError)
  // {
  //   this.formsubmited = true;
  //   this.messageService.add({severity: 'error', summary: 'Form Errors', detail: "Form has errors ", life: 3000});
  // }
  // else
  // {
  //     this.formsubmited = false;
  //     //this.activeIndex++;           
  // }
}

get f() { return this.form.controls; }
modelToForm(model) {
  let debtTypeValues = [];
  if(model.primaryObjectives.financing.debt.arFinancing)
  {
      debtTypeValues.push(this.appUtil.findItemInList(this.debt, 'A/R Financing', 'value').value)
  }
  if(model.primaryObjectives.financing.debt.juniorDebt)
  {
      debtTypeValues.push(this.appUtil.findItemInList(this.debt, 'Junior Debt', 'value').value)
  }
  if(model.primaryObjectives.financing.debt.mezzanineFinancing)
  {
      debtTypeValues.push(this.appUtil.findItemInList(this.debt, 'Mezzanine Financing', 'value').value)
  }
  if(model.primaryObjectives.financing.debt.seniorDebt)
  {
      debtTypeValues.push(this.appUtil.findItemInList(this.debt, 'Senior Debt', 'value').value)
  }
  let equityTypeValues = [];
  if(model.primaryObjectives.financing.equity.growthEquity)
  {
      equityTypeValues.push(this.appUtil.findItemInList(this.equity, 'Growth Equity', 'value').value)
  }
  if(model.primaryObjectives.financing.equity.ventureCapital)
  {
      equityTypeValues.push(this.appUtil.findItemInList(this.equity, 'Venture Capital', 'value').value)
  }
  if(model.primaryObjectives.financing.equity.angelInvestment)
  {
      equityTypeValues.push(this.appUtil.findItemInList(this.equity, 'Angel Investment', 'value').value)
  }
  if(model.primaryObjectives.financing.equity.minorityRecap)
  {
      equityTypeValues.push(this.appUtil.findItemInList(this.equity, 'Minority Recap', 'value').value)
  }
  let equityPartnerTypeValues = [];
  if(model.primaryObjectives.privateEquityPartner.controlling)
  {
      equityPartnerTypeValues.push(this.appUtil.findItemInList(this.privateEquityPartner, 'Control', 'value').value)
  }
  if(model.primaryObjectives.privateEquityPartner.hundredPercent)
  {
      equityPartnerTypeValues.push(this.appUtil.findItemInList(this.privateEquityPartner, '100%', 'value').value)
  }
  if(model.primaryObjectives.privateEquityPartner.minority)
  {
      equityPartnerTypeValues.push(this.appUtil.findItemInList(this.privateEquityPartner, 'Minority', 'value').value)
  }

  this.form.setValue({
      uuid: model.uuid,
      equityPartnerType: equityPartnerTypeValues,
      equityType: equityTypeValues,
      debtType: debtTypeValues,
      strategicPartner: model.primaryObjectives.strategicPartner,
      
  });
}
handleError(elemId) {
  this.messageService.add({ severity: 'error', summary: 'Form Errors', detail: "Please select at least one primary objective", life: 3000 });
  let invalidControl = this.formElement.nativeElement.querySelector('.ng-invalid');

  if (invalidControl) {
      invalidControl.focus();
  }
  else if (elemId != null) {
      invalidControl = this.formElement.nativeElement.querySelector('#' + elemId);
      if (invalidControl) {
          invalidControl.focus();
      }
  }
}
validatePrimaryObjective(event: any) {
  let error = { 'message': 'Primary objective is required.' };
  if (this.f.strategicPartner.value || (this.f.equityPartnerType.value != null && this.f.equityPartnerType.value.length>0)
      || (this.f.equityType.value !=null && this.f.equityType.value.length > 0) 
      || (this.f.debtType.value !=null && this.f.debtType.value.length > 0))
  {
      error = null;
  }
  
  this.appUtil.setError(this.form, 'strategicPartner', error);
  this.appUtil.setError(this.form, 'equityPartnerType', error);
  this.appUtil.setError(this.form, 'equityType', error);
  this.appUtil.setError(this.form, 'debtType', error);
  this.formsubmited = true;
}
  prepareModel(fieldName: string, value: any) {
    this.nextStep();
    if (this.form.invalid) {
      this.handleError(null);
      return;
    }
    let arFinancing = this.appUtil.findItemInList(this.f.debtType.value, this.appUtil.findItemInList(this.debt, 'A/R Financing', 'value').value) ? true : false;
    let juniorDebt = this.appUtil.findItemInList(this.f.debtType.value, this.appUtil.findItemInList(this.debt, 'Junior Debt', 'value').value) ? true : false;
    let mezzanineFinancing = this.appUtil.findItemInList(this.f.debtType.value, this.appUtil.findItemInList(this.debt, 'Mezzanine Financing', 'value').value) ? true : false;
    let seniorDebt = this.appUtil.findItemInList(this.f.debtType.value, this.appUtil.findItemInList(this.debt, 'Senior Debt', 'value').value) ? true : false;
    let growthEquity = this.appUtil.findItemInList(this.f.equityType.value, this.appUtil.findItemInList(this.equity, 'Growth Equity', 'value').value) ? true : false;
    let ventureCapital = this.appUtil.findItemInList(this.f.equityType.value, this.appUtil.findItemInList(this.equity, 'Venture Capital', 'value').value) ? true : false;
    let angelInvestment = this.appUtil.findItemInList(this.f.equityType.value, this.appUtil.findItemInList(this.equity, 'Angel Investment', 'value').value) ? true : false;
    let minorityRecap = this.appUtil.findItemInList(this.f.equityType.value, this.appUtil.findItemInList(this.equity, 'Minority Recap', 'value').value) ? true : false;
    let controlling = this.appUtil.findItemInList(this.f.equityPartnerType.value, this.appUtil.findItemInList(this.privateEquityPartner, 'Control', 'value').value) ? true : false;
    let hundredPercent = this.appUtil.findItemInList(this.f.equityPartnerType.value, this.appUtil.findItemInList(this.privateEquityPartner, '100%', 'value').value) ? true : false;
    let minority = this.appUtil.findItemInList(this.f.equityPartnerType.value, this.appUtil.findItemInList(this.privateEquityPartner, 'Minority', 'value').value) ? true : false;
    let debt = { arFinancing, juniorDebt, mezzanineFinancing, seniorDebt }
    let equity = { growthEquity, ventureCapital, angelInvestment, minorityRecap }
    let privateEquityPartner = { controlling, hundredPercent, minority }

    let dataToPost: any = { uuid: this.model.uuid, primaryObjectives: { strategicPartner: this.f.strategicPartner.value, privateEquityPartner, financing: { debt, equity } } };
    this.sellsideService.updateSellsideList([fieldName], dataToPost).subscribe(
      data => {
        this.loading = false;
        this.editingField[fieldName] = false;
        window.location.reload();
      })

  }

categories = [
  { label: 'Level1', value: 'CATEGORY_LEVEL1', model: 'level1' },
  { label: 'Level2', value: 'CATEGORY_LEVEL2', model: 'level2' },
  { label: 'Level3', value: 'CATEGORY_LEVEL3', model: 'level3' },
  { label: 'Vertical/Industry', value: 'VERTICAL', model: 'vertical' },
  { label: 'Ecosystem', value: 'ECOSYSTEM', model: 'ecosystem' }
];
}
