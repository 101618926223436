<div class="layout-topbar">
    <div class="topbar-left">
        <a tabindex="0" class="menu-button" (click)="appMain.onMenuButtonClick($event)">
            <i class="pi pi-bars"></i>
        </a>
        <span class="topbar-separator"></span>
        <ng-template ngFor let-item let-last="last" [ngForOf]="items">
            <span class="viewname" style="text-transform: uppercase">{{item.label}}</span>
        </ng-template>
        <img id="logo-mobile" class="mobile-logo" [src]="'assets/layout/images/tbp-logo-blue.png'"
            alt="diamond-layout" />
    </div>

    <div class="topbar-right">
        <ul class="topbar-menu">
            <li class="search-item">
                <span class="p-input-icon-right">
                    <i class="pi pi-search"></i>
                    <input [(ngModel)]="term" type="text" class="p-inputtext search-input" placeholder="Search"
                        (keydown)="_search($event)" />
                </span>
            </li>
            <li class="search-item" *ngIf="user.role == 1">
                <a tabindex="0" (click)="appMain.quickTaskPop()">
                    <i class="pi pi-plus" title="Add Notes"></i>
                </a>
            </li>
            <!--<li class='notifications-item' [ngClass]="{'active-menuitem ': appMain.topbarNotificationMenuActive}">
                <a href="#" tabindex="0" (click)="appMain.onTopbarNotificationMenuButtonClick($event)">
                    <i class="pi pi-bell"></i>
                    <span class="topbar-badge">5</span>
                </a>
                <ul class="notifications-menu fade-in-up">
                    <li role="menuitem">
                        <a href="#" tabindex="0">
                            <i class="pi pi-shopping-cart"></i>
                            <div class="notification-item">
                                <div class="notification-summary">New Order</div>
                                <div class="notification-detail">You have <strong>3</strong> new orders.</div>
                            </div>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="#" tabindex="0">
                            <i class="pi pi-check-square"></i>
                            <div class="notification-item">
                                <div class="notification-summary">Withdrawn Completed</div>
                                <div class="notification-detail">Funds are on their way.</div>
                            </div>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="#" tabindex="0">
                            <i class="pi pi-chart-line"></i>
                            <div class="notification-item">
                                <div class="notification-summary">Monthly Reports</div>
                                <div class="notification-detail">New reports are ready.</div>
                            </div>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="#" tabindex="0">
                            <i class="pi pi-comments"></i>
                            <div class="notification-item">
                                <div class="notification-summary">Comments</div>
                                <div class="notification-detail"><strong>2</strong> new comments.</div>
                            </div>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="#" tabindex="0">
                            <i class="pi pi-exclamation-circle"></i>
                            <div class="notification-item">
                                <div class="notification-summary">Chargeback Request</div>
                                <div class="notification-detail"><strong>1</strong> to review.</div>
                            </div>
                        </a>
                    </li>
                </ul>
            </li>-->

            <li class="profile-item" [ngClass]="{'active-menuitem fadeInDown': appMain.topbarUserMenuActive}">
                <a href="#" (click)="appMain.onTopbarUserMenuButtonClick($event)">
                    <img src="assets/layout/images/profile.jpg" alt="diamond-layout" class="profile-image" />
                    <span class="profile-name">{{user.name}}</span>
                </a>
                <ul class="profile-menu fade-in-up">
                    <li>
                        <a (click)="profile()">
                            <i class="pi pi-user"></i>
                            <span>Profile</span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <i class="pi pi-cog"></i>
                            <span>Settings</span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <i class="pi pi-calendar"></i>
                            <span>Calendar</span>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <i class="pi pi-inbox"></i>
                            <span>Inbox</span>
                        </a>
                    </li>
                    <li>
                        <a (click)="logout()">
                            <i class="pi pi-power-off"></i>
                            <span>Logout</span>
                        </a>
                    </li>
                </ul>
            </li>
            <!--
            <li class="right-sidebar-item">
                <a href="#" tabindex="0" (click)="appMain.onRightMenuClick($event)">
                    <i class="pi pi-align-right"></i>
                </a>
            </li>
            -->
        </ul>
    </div>
</div>

<p-dialog header="Add Notes" blockScroll="true" (onHide)="appMain.hideQuickTask()" [(visible)]="appMain.quickTaskShow"
    [style]="{width: '35vw'}">
    <ng-template pTemplate="content">
        <form [formGroup]="appMain.quickTaskForm" #msgElem>
            <p style="color: red;" *ngIf="appMain.showError">
                {{appMain.errorMsg}}
            </p>
            <div class="grid p-fluid lg:mt-3">
                <div class="col-12">
                    <span class="p-float-label">
                        <tbp-autocomplete
                            [ngClass]="{ 'ng-invalid ng-dirty': appMain.quickSubmitted && appMain.f.organization.errors }"
                            [multiple]="false" [formControl]="appMain.f.organization" [items]="" [type]="'Account'">
                        </tbp-autocomplete>
                        <label for="account">Account</label>
                    </span>
                    <span class="p-error"
                        *ngIf="appMain.quickSubmitted && appMain.f.organization.invalid && appMain.f.organization.hasError('message')">{{appMain.f.organization.errors.message}}</span>
                </div>
                <div class="col-12 ">
                    <span class="p-float-label">
                        <tbp-autocomplete [forceSelection]="true" field="displayName"
                            [ngClass]="{ 'ng-invalid ng-dirty': appMain.quickSubmitted && appMain.f.contacts.errors }"
                            [additionalParam]="appMain.f.organization.value?.uuid" [multiple]="true"
                            [formControl]="appMain.f.contacts" [items]="" [type]="'Contact'"
                            [multiple]="true" [formControl]="appMain.f.contacts" [items]="" [type]="'Contact'"
                            [additionalData]="appMain.f.organization.value">
                        </tbp-autocomplete>
                        <label for="user">Contact</label>
                    </span>
                    <span class="p-error"
                        *ngIf="appMain.quickSubmitted && appMain.f.contacts.invalid && appMain.f.contacts.hasError('message')">{{appMain.f.contacts.errors.message}}</span>
                </div>
            </div>
            <div class="grid p-fluid">
                <div class="col-12">
                    <label for="notes">Notes</label>
                    <editor #editor formControlName="notes" [init]="appUtil.getInitData(180,null)"></editor>
                    <span class="p-error"
                        *ngIf="appMain.quickSubmitted && appMain.f.notes.invalid && appMain.f.notes.hasError('required')">Notes
                        is required.</span>
                    <span class="p-error"
                        *ngIf="appMain.quickSubmitted && appMain.f.notes.invalid && appMain.f.notes.hasError('message')">{{appMain.f.subject.errors.message}}</span>
                </div>
            </div>
            <div class="grid p-fluid">
                <div class="col-12 md:col-12 lg:mt-3">
                    <span class="p-float-label">
                        <input [ngClass]="{ 'ng-invalid ng-dirty': appMain.quickSubmitted && appMain.f.subject.errors }"
                            formControlName="subject" type="text" maxlength="95" pInputText
                            [attr.disabled]="appMain.f.createTask.value ? 'disabled' : null" />
                        <label for="subject">Next Step <span class="mandate">*</span></label>
                    </span>
                    <span class="p-error"
                        *ngIf="appMain.quickSubmitted && appMain.f.subject.invalid && appMain.f.subject.hasError('required')">Next
                        Step is required.</span>
                    <span class="p-error"
                        *ngIf="appMain.quickSubmitted && appMain.f.subject.invalid && appMain.f.subject.hasError('message')">{{appMain.f.subject.errors.message}}</span>
                </div>
                <div class="col-12 md:col-6">
                    <span class="p-float-label">
                        <p-calendar appendTo="body" [showIcon]="false" dateFormat="mm-dd-yy" formControlName="dueDate"
                            inputId="icon" [readonlyInput]="true" [disabled]="appMain.f.createTask.value"></p-calendar>
                        <label for="dueDate">Due Date</label>
                    </span>
                    <span class="p-error"
                    *ngIf="appMain.quickSubmitted && appMain.f.dueDate.invalid && appMain.f.dueDate.hasError('message')">{{appMain.f.dueDate.errors.message}}</span>
                </div>
                <div class=" col-12 md:col-6">
                    <span class="p-float-label">
                        <p-dropdown appendTo="body" [options]="appMain.priority" styleClass="form-control"
                            formControlName="priority" [autoDisplayFirst]="false" optionLabel="name" optionValue="code"
                            [disabled]="appMain.f.createTask.value">
                        </p-dropdown> <label for="priority">Priority<span class="mandate">*</span> </label>
                    </span>
                </div>
                <div class=" col-12 md:col-6">
                    <span class="p-float-label">
                        <p-dropdown appendTo="body" [options]="appMain.status" styleClass="form-control"
                            formControlName="status" [autoDisplayFirst]="false" optionLabel="name" optionValue="code"
                            [disabled]="appMain.f.createTask.value">
                        </p-dropdown> <label for="status">Status <span class="mandate">*</span> </label>
                    </span>
                </div>
                <div class="col-12 md:col-6">
                    <span class="p-float-label">
                        <p-dropdown [options]="appMain.entityTypes" appendTo="body" styleClass="form-control"
                            [autoDisplayFirst]="false" formControlName="relatedType" optionLabel="value"
                            [disabled]="appMain.f.createTask.value" [showClear]="true"></p-dropdown>
                        <label for="relatedType">Type </label>
                    </span>
                </div>
                <div class="col-12 md:col-6">
                    <span class="p-float-label">
                        <p-dropdown [options]="appMain.tbpEmployees" appendTo="body" styleClass="form-control"
                            formControlName="assignedTo" optionLabel="displayName" optionValue="uuid" [filter]="true"
                            [autoDisplayFirst]="false" [disabled]="appMain.f.createTask.value">
                        </p-dropdown>
                        <label for="assignedTo">Assigned To<span class="mandate">*</span></label>
                    </span>
                </div>
                <div class="field col-12 md:col-6"
                    *ngIf="appMain.f.relatedType.value!= null &&  appMain.f.relatedType.value.value === 'Spotlight'">
                    <span class="p-float-label">
                        <p-autoComplete [unique]="true" dataKey="uuid" [forceSelection]="true"
                            formControlName="spotlight" [suggestions]="spotlightResults"
                            (completeMethod)="searchSpotlights($event)" field="title"> </p-autoComplete> <label
                            for="spotlight">Spotlight </label>
                    </span>
                </div>
                <div class="field col-12 md:col-6"
                    *ngIf="appMain.f.relatedType.value!= null && appMain.f.relatedType.value.value === 'Engagement Outreach'">
                    <span class="p-float-label">
                        <p-autoComplete [unique]="true" dataKey="uuid" [forceSelection]="true"
                            formControlName="engagement" [suggestions]="engagementResults"
                            (completeMethod)="searchEngagements($event)" field="name"> </p-autoComplete> <label
                            for="engagement">Engagement </label>
                    </span>
                </div>
                <div class="field col-12 md:col-6"
                    *ngIf="appMain.f.relatedType.value!= null && appMain.f.relatedType.value.value === 'BuySide Screen Outreach'">
                    <span class="p-float-label">
                        <p-autoComplete [unique]="true" dataKey="uuid" [forceSelection]="true"
                            formControlName="buySideScreen" [suggestions]="buySideResults"
                            (completeMethod)="searchBuySide($event)" field="screenTitle"> </p-autoComplete> <label
                            for="buySideScreen">BuySide Screen Outreach </label>
                    </span>
                </div>
                <div class="field col-12 md:col-6"
                    *ngIf="appMain.f.relatedType.value !=null && appMain.f.relatedType.value.value === 'SellSide Screen Outreach'">
                    <span class="p-float-label">
                        <p-autoComplete appendTo="body" [unique]="true" dataKey="uuid" [forceSelection]="true"
                            formControlName="sellSideScreen" [suggestions]="sellSideResults"
                            (completeMethod)="searchSellSide($event)" field="screenTitle"> </p-autoComplete> <label
                            for="sellSideScreen">SellSide Screen Outreach </label>
                    </span>
                </div>
                <ng-container *ngIf="appMain.f.status.value === 'COMPLETED' ">
                    <div class="field col-12 md:col-6">
                        <span class="p-float-label">
                            <p-calendar [showIcon]="false" dateFormat="mm-dd-yy" formControlName="completionDate"
                                inputId="icon" appendTo="body" [readonlyInput]="true"></p-calendar>
                            <label>Completion Date <span class="mandate">*</span></label>
                        </span>
                        <span class="p-error"
                            *ngIf="appMain.quickSubmitted && appMain.f.completionDate.invalid && appMain.f.completionDate.hasError('message')">{{appMain.f.completionDate.errors.message}}</span>
                    </div>
                </ng-container>
                <ng-container *ngIf="appMain.f.status.value !== 'COMPLETED' ">
                    <div class="field col-12 md:col-6">
                        <span class="p-float-label">
                            <p-calendar appendTo="body" [showIcon]="false" showTime="true" hourFormat="24"  [minDate]="minDate" 
                            dateFormat="mm-dd-yy" formControlName="reminderDate" [readonlyInput]="true"></p-calendar>
                            <label for="reminderDate">Reminder Date</label>
                        </span>
                        <span class="p-error"
                            *ngIf="appMain.quickSubmitted  && appMain.f.reminderDate.invalid && appMain.f.reminderDate.hasError('message')">{{appMain.f.reminderDate.errors.message}}</span>
                    </div>
                </ng-container>
                <div class="field col-12 md:col-6">
                    <p-checkbox formControlName="createTask" binary="true"
                        (onChange)="appMain.taskOption($event)"></p-checkbox>&nbsp; Do not create Task
                </div>
                <ng-container *ngIf="appMain.f.dueDate && appMain.f.dueDate.value">
                    <div class="field col-12 md:col-6">
                        <p-checkbox formControlName="reminderChecked" binary="reminderChecked" id="reminderChecked"
                            class="ml-2" pTooltip="{{('Reminder Checked ')}}" tooltipPosition="top"
                            label="Send Email to Assignee"></p-checkbox>
                    </div>
                </ng-container>
            </div>

        </form>
    </ng-template>
    <ng-template pTemplate="footer">
        <button *ngIf="appMain.quickSpinner == false" pButton pRipple label="Cancel" icon="pi pi-times"
            class="p-button-text" (click)="appMain.hideQuickTask()"></button>
        <button *ngIf="appMain.quickSpinner == false" pButton pRipple label="Save" form="clform" icon="pi pi-check"
            class="p-button-text" (click)="appMain.savefrom()"></button>
        <p-progressSpinner *ngIf="appMain.quickSpinner == true" [style]="{width: '50px', height: '50px'}"
            styleClass="custom-spinner" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
    </ng-template>
</p-dialog>
<p-toast></p-toast>