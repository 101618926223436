import { TaskService } from './task.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LazyLoadEvent, MenuItem } from 'primeng/api';
import {  Component, Input, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { BreadcrumbService } from '../app.breadcrumb.service';
import { AppUtil } from '../_helpers';
import { DatePipe } from '@angular/common';
import { Task, User } from '../_models';



@Component({
    selector: 'task-toggle-list',
    templateUrl: './task-toggle-list.component.html',
    styleUrls: ['./task-toggle-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TaskToggleListComponent implements OnInit {
    @Input() parent: any = null;
    @Input() parentUuid: string = null;
    @Input() taskType: string = null; /* organization, contact */
    searchMenuItems: MenuItem[];
    tbpEmployees = [];
    tasks: Task[] = [];
    task:any;
    selectedTasks: Task[] = [];
    loading: boolean = true;
    totalRecords: number = 0;
    showForm: boolean = false;
    uuid: string = null;
    showListing: boolean = false;
    term: string;
    contact: User;
    searchUrl=[];
    filterType:string;
    currentDate: string;
    checkTaskType: boolean = false;
    CONTACT :any;
    quickFilterOptions = [];
    selectedType:any;
    selectedUser:any;
    disable:boolean=false;
    userUuid:string
    type:string=null
    checkChangeUser:boolean=false
    constructor(
        private route: ActivatedRoute,
        private breadCrumbService: BreadcrumbService,
        private router: Router,
        private taskService: TaskService,
        private appUtil: AppUtil,    
    ) {  
        this.tbpEmployees = this.route.snapshot.data['tbpEmployees'];
        this.quickFilterOptions = [{ label: 'Upcoming Task' , type:'upComingTask'}, 
            { label: 'OverDue Task', type:'overDueTask'},
             { label: 'All Task', type:'tasks'}];
        if ((this.router.url.indexOf("/tasks") != -1) || (this.router.url.indexOf("/task") != -1)) {
             this.searchUrl = this.router.url.split("/")
            this.parentUuid = this.searchUrl[2];
            this.taskType = this.searchUrl[3];    
            this.breadCrumbService.setItems([{ label: 'Task Listing' }])
            const e: LazyLoadEvent = { first: 0, rows: -1, sortField: 'lastModifiedDate', sortOrder: 1 };
                const searchParams: any = {
                    page:e.first / e.rows,
                    pageSize: this.showListing ? -1 :  e.rows,
                    sortDetails: e.sortField && e.sortField !== '' ? [{ property: e.sortField, direction: e.sortOrder === 1 ? 'DESC' : 'ASC' }] : [],
                };
               this.loading= true ;
               this.term = '';
               this.showListing = true;
            if(this.taskType === "contact"){
                this.selectedType= this.quickFilterOptions[2];
                this.taskService.getContact(this.parentUuid).subscribe(
                    (contactData) => {
                      this.CONTACT = contactData
                      this.parent = this.CONTACT;

                    },
                    (error) => {
                      console.error("Error fetching contact:", error);
                    }
                  );
            }
            else if(this.taskType === "allTask" || this.taskType === "upComingTask" || this.taskType === "overDueTask"){       
                this.checkTaskType = true; 
                if(['upComingTask','overDueTask'].includes(this.taskType)){
                 this.selectedType= this.quickFilterOptions.find(item=>item.type==this.taskType);
                 this.selectedUser= this.tbpEmployees.find(item => item.uuid=== this.parentUuid)
                 } 
                if(this.taskType == "allTask"){
                    this.tbpEmployees=this.tbpEmployees.filter(item=>item.uuid!=this.parentUuid)
                    this.type="tasks"
                    this.selectedType= this.quickFilterOptions[2];
                    this.selectedUser=null;
                    this.checkTaskType=false
                }
               this.taskService.searchDashboardTasks(this.parentUuid,searchParams ,this.taskType).subscribe((data: any) => this.handleSearchResults(data));
            } 
           else if(this.searchUrl[1]=="tasks" && this.taskType==null){
            this.taskType === "tasks"
            this.checkTaskType = true
           this.taskService.searchTasks(searchParams).subscribe((data: any) => {
            const response = data as any;
            this.totalRecords = response.totalRecords;
           this.tasks = response.records;
           this.loading=false
           });
           }   
         } 
          if(this.searchUrl[1]=="task"){
            this.uuid=this.searchUrl[2]
            this.checkTaskType = true
             this.taskService.get(this.uuid).subscribe((data: any) => { 
             this.tasks.push(data);
             this.parentUuid=data.assignedTo.uuid;
             this.task=data.assignedTo
             this.selectedUser = this.tbpEmployees.find(item => item.displayName === this.task.displayName);
                this.disable=true
                this.loading=false;
                const now=new Date();
                this.currentDate=new DatePipe('en-US').transform(now, 'yyyy-MM-dd');
                if(this.tasks[0].dueDate<this.currentDate){
                    this.selectedType= this.quickFilterOptions[1];
                }
                if(this.tasks[0].dueDate>this.currentDate){
                    this.selectedType= this.quickFilterOptions[0];
                }
           });  
         }
         if( ['organization','contact', 'buyside','sellside','allTask'].includes(this.taskType)){
            this.selectedType=this.quickFilterOptions[2]
         }
             this.term="";
    }
       ngOnInit(): void {
        this.route.params.subscribe(data => {
            this.showListing = data.showListing == 'true'?true:false;
            if(this.showListing && this.checkTaskType!=true){             
               this.search(); 
            } });
    } 
    ngOnChanges(changes: SimpleChanges) {
        if (changes.hasOwnProperty("parentUuid")) {
            this.search();
        }
    }
    new() {
        this.uuid = null;
        this.showForm = true;
    } 
    edit(task: any) {
        this.uuid = task.uuid;
        this.contact = task.contact;
        this.showForm = true;   
    }
    changeUser(event:any){
        const e: LazyLoadEvent = { first: 0, rows: -1, sortField: 'lastModifiedDate', sortOrder: 1 };
        const searchParams: any = {
            page:e.first / e.rows,
            pageSize: this.showListing ? -1 :  e.rows,
            sortDetails: e.sortField && e.sortField !== '' ? [{ property: e.sortField, direction: e.sortOrder === 1 ? 'DESC' : 'ASC' }] : [],
        };
        
        if(!event.value){
            if(['overDueTask','upComingTask','allTask'].includes(this.taskType)){
                this.selectedType= this.quickFilterOptions.find(item=>item.type==this.taskType);   
                if(this.taskType=="allTask"){
                    this.selectedType=this.quickFilterOptions[2]
                }
                this.taskService.searchDashboardTasks(this.parentUuid,searchParams ,this.taskType).subscribe((data: any) => this.handleSearchResults(data));
             } 
             else{
                this.selectedType=this.quickFilterOptions[2]
                this.taskService.getList(this.taskType, this.parentUuid, searchParams ).subscribe((data: any) => this.handleSearchResults(data));
             }
             this.checkChangeUser=false
            return
        }
        this.checkChangeUser=true
        if(event.value['uuid'] ==null){
       this.userUuid =  this.selectedUser?.uuid;
       this.type =  event.value['type'];
        }
        else{
        this.type =  this.selectedType.type;
        this.userUuid = event.value['uuid'];
        }
        if(this.taskType === "tasks" || this.taskType === "upComingTask" || this.taskType === "overDueTask" ){ 
            if(this.userUuid==null){
                this.userUuid=this.parentUuid
            }
            this.search();
        } 
        if( ['organization','contact', 'buyside','sellside','allTask'].includes(this.taskType)){
        this.search()
       
        }  
    }
    search() {
        const e: LazyLoadEvent = { first: 0, rows: 5, sortField: 'lastModifiedDate', sortOrder: 1 };
        const searchParams: any = {
            page:e.first / e.rows,
            pageSize: this.showListing ? -1 :  e.rows,
            sortDetails: e.sortField && e.sortField !== '' ? [{ property: e.sortField, direction: e.sortOrder === 1 ? 'DESC': 'ASC' }] : [],
        };
        if( ['organization','contact', 'buyside','sellside','allTask'].includes(this.taskType)){
            const now = new Date();
            this.currentDate=new DatePipe('en-US').transform(now, 'yyyy-MM-dd');
            if(this.userUuid!=null){
                searchParams.rootRule = {
                    condition: 'and',
                    rules: [
                        { searchType: 'exactsearch', field: 'assignedTo.uuid', value: this.userUuid, operator: '='},
                    ],
                };
                if(this.type=="upComingTask"){
                searchParams.rootRule.rules.push( { searchType: 'date', field: 'dueDate', value: this.currentDate, operator: '>='})
                }
                if(this.type=="overDueTask"){
                    searchParams.rootRule.rules.push(  { searchType: 'date', field: 'dueDate', value: this.currentDate, operator: '<'} )
                    searchParams.rootRule.rules.push(  { searchType: 'text', field: 'status', value: 'COMPLETED', operator: '!=' } )
                }
            }
            if(this.userUuid==null){    
            if(this.type=="upComingTask"){
            searchParams.rootRule = {
                condition: 'and',
                rules: [
                    { searchType: 'date', field: 'dueDate', value: this.currentDate, operator: '>='},
                ],
            };
            }
            if(this.type=="overDueTask"){
                searchParams.rootRule = {
                    condition: 'and',
                    rules: [
                        { searchType: 'date', field: 'dueDate', value: this.currentDate, operator: '<'},   
                    ],
                };
                searchParams.rootRule.rules.push({ searchType: 'text', field: 'status', value: 'COMPLETED', operator: '!=' });
            }
        }
    }
        if (this.term !== '') {
            if (this.taskType === 'organization') {
                this.searchTaskForOrganization(searchParams)
            } else  {
                if(this.taskType !== 'allTask'){
                  if(this.checkChangeUser){
                    if( ['contact', 'buyside','sellside',].includes(this.taskType)){
                    searchParams.rootRule.rules.push( 
                            { searchType: 'exactsearch', field: 'subject', value: this.term, operator: 'matches' },
                            { searchType: 'textsearch',
                                field: 
                                    this.taskType === 'buyside' ? 'buySideScreen.uuid' :
                                    this.taskType === 'sellside' ? 'sellSideScreen.uuid' :
                                    ['upcomingTask', 'overDueTask',].includes(this.taskType) ? 'assignedTo.uuid' :
                                    `${this.taskType}s.uuid`,
                                value: this.parentUuid,
                                operator: 'matches'                                     
                            } 
                        );
                    }
                    if (['upComingTask', 'overDueTask',].includes(this.taskType)){
                        searchParams.rootRule={
                            condition: 'and',
                            rules: [ { searchType: 'exactsearch', field: 'subject', value: this.term, operator: 'matches' }]
                        }
                           if(this.userUuid!=null){
                        searchParams.rootRule.rules.push(
                        {searchType: 'exactsearch', field: 'assignedTo.uuid', value: this.userUuid, operator: '='}
                    );
              }
                      }
                    }
                    else{
                       searchParams.rootRule={
                        condition: 'and',
                        rules: [ { searchType: 'exactsearch', field: 'subject', value: this.term, operator: 'matches' },
                            { searchType: 'textsearch',
                                field: 
                                    this.taskType === 'buyside' ? 'buySideScreen.uuid' :
                                    this.taskType === 'sellside' ? 'sellSideScreen.uuid' :
                                    ['upComingTask', 'overDueTask',].includes(this.taskType) ? 'assignedTo.uuid' :
                                    `${this.taskType}s.uuid`,
                                value: this.parentUuid,
                                operator: 'matches'                                     
                            } ]
                       } 
                    }
                
                }else{
                    searchParams.rootRule = {
                        condition: 'and',
                        rules: [
                            { searchType: 'exactsearch', field: 'subject', value: this.term, operator: 'matches' }
                        ]
                    }
                    if(this.taskType == 'allTask' ){
                        if(this.userUuid!=null){
                        searchParams.rootRule.rules.push({searchType: 'exactsearch', field: 'assignedTo.uuid', value: this.userUuid, operator: '='})
                        }
                        if(this.userUuid==null){
                            searchParams.rootRule.rules.push({searchType: 'exactsearch', field: 'assignedTo.uuid', value: this.parentUuid, operator: '!='})
                        }
                       }    
                }
                if(['contact', 'buyside','sellside'].includes(this.taskType)){
                    this.taskService.getList(this.taskType, this.parentUuid, searchParams ).subscribe((data: any) => this.handleSearchResults(data)) 
                    }
                    if(this.taskType=="allTask"){
                        this.taskService.searchDashboardTasks(this.parentUuid,searchParams ,this.taskType).subscribe((data: any) => this.handleSearchResults(data));
                        }  
                        if( ['upComingTask', 'overDueTask'].includes(this.taskType)){
                            if(this.userUuid==null){
                                this.type=this.taskType
                                this.userUuid=this.parentUuid
                            }
                            this.taskService.searchDashboardTasks(this.userUuid,searchParams ,this.type).subscribe((data: any) => this.handleSearchResults(data));
                        }
             
             
            }
        } else  {
            if (this.loading) this.loading = true;  
            if( ['upComingTask', 'overDueTask'].includes(this.taskType)){
                if(this.userUuid==null){
                    this.type=this.taskType
                    this.userUuid=this.parentUuid
                }
                this.taskService.searchDashboardTasks(this.userUuid,searchParams ,this.type).subscribe((data: any) => this.handleSearchResults(data));
            }
            else{
                if(['organization','contact', 'buyside','sellside'].includes(this.taskType)){
                this.taskService.getList(this.taskType, this.parentUuid, searchParams ).subscribe((data: any) => this.handleSearchResults(data)) 
                }
                if(this.taskType=="allTask"){
                    this.taskService.searchDashboardTasks(this.parentUuid,searchParams ,this.taskType).subscribe((data: any) => this.handleSearchResults(data));
                    }  
            }
            }
        
    } 
    searchTaskForOrganization(searchParams: any) {
        const contactUuids: any[] = [];
        this.taskService.getContacts(this.parentUuid, { page: 0, pageSize: 25 }).subscribe((data: any) => {
            const response = data as any;
            contactUuids.push(...response.records.map((currentValue: { uuid: any }) => currentValue.uuid));
            searchParams.rootRule = {
                condition: 'and',
                rules: [
                    { searchType: 'exactsearch', field: 'subject', value: this.term, operator: 'matches' },
                ],
             };
            if (contactUuids.length > 0) {
                   searchParams.rootRule.rules.push({
                    condition: 'or',
                    rules: [
                        { searchType: 'textsearch', field: `${this.taskType}.uuid`, value: this.parentUuid, operator: 'matches' },
                        { searchType: 'exactsearch', field: 'contacts.uuid', values: contactUuids, operator: 'in' },
                    ],
                });
            } else {
                searchParams.rootRule.rules.push({ searchType: 'exactsearch', field: `${this.taskType}.uuid`, value: this.parentUuid, operator: 'matches' });
                if(this.userUuid!=null){
                    searchParams.rootRule.rules.push({searchType: 'exactsearch', field: 'assignedTo.uuid', value: this.userUuid, operator: '='})
                }
              
            }
            this.taskService.searchTasks(searchParams).subscribe((data: any) => this.handleSearchResults(data));
        });
    }
    handleSearchResults(data: any) {
        const response = data as any;
              this.totalRecords = response.totalRecords;
            this.tasks = response.records;
          this.loading = false;
          this.appUtil.reinitializeShowMore();
        }
    
    }
function someMethod() {
    throw new Error('Function not implemented.');
}

