import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function capIQValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        let value = control.value;
        if (!value)
            return null;

        let valid = true;
        try {
            let params = (new URL(value)).searchParams;
            value = params.get('companyId');
        }
        catch (err) {
            if (value.indexOf('/') != -1 || value.indexOf('http:') != -1 || value.indexOf('https:') != -1)
                valid = false;
        }

        if (valid) {
            if (value == null) {
                valid = false;
            }
            else if (value != control.value) {
                control.setValue(value);
            }
        }
        return valid ? null : { capIQValidator: true };
    }
}