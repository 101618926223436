import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { catchError, filter, Observable, of, tap } from 'rxjs';

import { environment } from 'src/environments/environment';
import { AccountService } from '../_services';


interface UserActivity {
  entityName: string;
  type: string;
  url: string;
  description: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserActivityService {
  private localStorageKey = 'userActivities';
  user = this.accountService.userValue;
  constructor(private http: HttpClient, private router: Router,
     private accountService: AccountService) {

    this.trackPageNavigation();
    this.trackClicks();
  }

  private trackPageNavigation() {
    let entityName = '';
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      entityName = this.getEntityName(event.urlAfterRedirects);
      if (entityName == '' || entityName == null || entityName == undefined) {
        entityName = 'Dashboard';
      }
      this.logActivity('Navigation', `Navigated to the page ${event.urlAfterRedirects}`, entityName, window.location.href);
    });

  }
  logLoginActivity(name:string) {
    this.logActivity('Login', `${name} - User logged in`, 'Login' , window.location.href);
  }
  logLogOutActivity(name:string) {
    this.logActivity('Logout', `${name} - User logged out`, 'Logout' , window.location.href);
  }
  private trackClicks(): void {
    document.addEventListener('click', (event) => {
      const target = event.target as HTMLElement;
      if (target && this.isImportantElement(target)) {
        const description = target.innerText || target.nodeName;
        const entityName = this.determineEntityName(target);
        const url = window.location.href;
        this.logActivity('Click', `Clicked on ${description}`, entityName, url);
      }
    });
  }
  private isImportantElement(target: HTMLElement): boolean {
    const importantTags = ['BUTTON', 'A', 'INPUT', 'SELECT', 'TEXTAREA'];
    return importantTags.includes(target.nodeName);
  }
  private determineEntityName(target: HTMLElement): string {

    const entityName = target.innerText || target.nodeName;
    return entityName;
  }
  private getEntityName(url: string): string | null {

    const parts = url.split('/');
    const path = parts[1];
    const entityName = path ? path.split('?')[0] : null;

    return entityName
  }
  logActivity(type: string, description: string, entityName: string, url?: string) {
    const activity: UserActivity = {
      entityName,
      type,
      url,
      description
    };
    this.saveActivityToLocalStorage(activity);
    this.sendActivitiesToServer(activity);
  }

  private saveActivityToLocalStorage(activity: UserActivity) {
    const activities = this.getActivitiesFromLocalStorage();
    activities.push(activity);
    localStorage.setItem(this.localStorageKey, JSON.stringify(activities));
  }

  private getActivitiesFromLocalStorage(): UserActivity[] {
    const activitiesStr = localStorage.getItem(this.localStorageKey);
    return activitiesStr ? JSON.parse(activitiesStr) : [];
  }

  sendActivitiesToServer(activity: UserActivity) {

    this.http.post(`${environment.apiUrl}/user-activity`, activity, { responseType: 'text' })
      .subscribe(response => {
        this.clearActivities();
      },
        error => console.error('Error logging activity', error));
    // this.clearActivities();
  }

  private clearActivities() {
    localStorage.removeItem(this.localStorageKey);
  }


getAnyUserActivity(searchParams:any){

const url = `${environment.apiUrl}/any-user-activity`;
return this.http.post(url,searchParams);
}
}
