import { Component, OnInit, Input, ViewChild, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService, ConfirmationService } from 'primeng/api';
import { DataService } from 'src/app/_services/data.service';
import { SellsideService } from './sellside.service';
import { Location } from '@angular/common';
import { BreadcrumbService } from 'src/app/app.breadcrumb.service';
import { SellSide } from '../_models';

@Component({
  selector: 'sellside-blind-profile',
  templateUrl: './sellside-blind-profile.component.html',
  styles: [`
  :host ::ng-deep .p-divider.p-divider-vertical::before {
		border-left: 2px #dee2e6 dashed !important;
	}
  `],
	providers: [MessageService, ConfirmationService]
})
export class SellsideBlindProfileComponent implements OnInit {
	@ViewChild('cform') formElement;
	form: UntypedFormGroup;
	financials: any[];
    years: string[];
	loadingSpinner: boolean = false;
	submitted = false;
	@Input() screenId: string;
    blindProfileId: string;
	@Input() isEdit: boolean = false;
    @Input() sellside: SellSide;
    formInitialValue: any = null;

  constructor(
	private sellsideService: SellsideService,
    private messageService: MessageService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private dataService: DataService,
	private breadcrumbService: BreadcrumbService,
    private router: Router,
	private location: Location
  ) { 
    this.financials = [ {'dummy':'Revenue ($m)'},{'dummy':'EBITDA ($m)'},{'dummy':'YOY Growth'},{'dummy':'Revenue Projections'} ];
    this.years = ['dummy'];
    this.loadBlindProfileForm();
    this.formInitialValue = this.form.value;
  }

  ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges)
    {
        if(this.isEdit)
        {
            this.breadcrumbService.setItems([{ label: 'Edit Blind Profile' }]);
            this.loadingSpinner = true;
            this.sellsideService.getBlindProfile(this.screenId).subscribe(data => {
                let sellside = data as any;
                this.blindProfileId = sellside.uuid;
                this.modelToForm(sellside);
            }).add(()=>{
                this.loadingSpinner = false;
            });
        }
        else {
            this.blindProfileId = null;
            this.breadcrumbService.setItems([{ label: 'New Blind Profile' }]);
            this.formInitialValue = { title: this.sellside.screenTitle, headline: this.sellside.externalHeadline};
            this.form.reset(this.formInitialValue);
        }
    }

    loadBlindProfileForm() {
        this.form = this.formBuilder.group({
            title: [],
            headline: [],
            services: [],
            customers: [],
            business: [],
            investmentConsiderations: [],
            financialYear:[]
        });
    }

	modelToForm(model) {
		this.form.setValue({
			title: model.title,
			headline: model.headline,
			services: model.services,
			customers: model.customers,
			business: model.business,
			investmentConsiderations: model.investmentConsiderations,
            financialYear:[]
		});
        if(model.financials && model.financials.length > 0)
        {
            let financials = model.financials;
            financials.forEach(element => {
                this.financials[0][element.year] = element.revenue;
                this.financials[1][element.year] = element.ebitda;
                this.financials[2][element.year] = element.yoyGrowth;
                this.financials[3][element.year] = element.revenueProjection;
                this.years.push(element.year);
            });
        }

	  }

	  save() {
        let financials:any[] =[];
        this.years.forEach((element) =>{
            if(element != 'dummy')
            {
                financials[financials.length] = { year: element, revenue: this.financials[0][element],
                    ebitda: this.financials[1][element], yoyGrowth: this.financials[2][element], 
                    revenueProjection: this.financials[3][element]}
            }
        })
        this.submitted = true;
        if (this.form.invalid) {
            this.handleError(null);
            return;
        }
        let { title, headline, services, customers, business, investmentConsiderations} = this.form.value;
        let dataToPost: any = { title, headline, services, customers, business, investmentConsiderations, financials};

        this.loadingSpinner = true;
        let apiToCall: any;
        if (this.isEdit) {
            apiToCall = this.sellsideService.UpdateBlindProfile(this.screenId, this.blindProfileId, dataToPost);
        } else {
            apiToCall = this.sellsideService.addBlindProfile(this.screenId, dataToPost);
        }
        apiToCall.subscribe(
            data => {
                let detail = 'Sellside Blind Profile Created';
                if (this.isEdit) {
                    detail = 'Sellside Blind Profile Updated';
                }
                this.dataService.setData('message', detail);
                this.router.navigate(['/sell-side/' + this.screenId + '/blind-profile']);
            },
            error => {
                this.loadingSpinner = false;
                let errors = error.error;
                if (errors.code && errors.code == 'VALIDATION_ERROR') {
                    for (var i = 0; i < errors.subErrors.length; i++) {
                        this.f[errors.subErrors[i].field].setErrors({ 'message': errors.subErrors[i].message });
                    }
                    this.handleError(errors.subErrors[0].field);
                }
            }
        );
    }

	  get f() {
		return this.form.controls;
	  }

	  handleError(elemId) {
		this.messageService.add({ severity: 'error', summary: 'Form Errors', detail: "Form has errors. Please fix them first", life: 3000 });
		let invalidControl = this.formElement.nativeElement.querySelector('.ng-invalid');
  
		if (invalidControl) {
			invalidControl.focus();
		}
		else if (elemId != null) {
			invalidControl = this.formElement.nativeElement.querySelector('#' + elemId);
			if (invalidControl) {
				invalidControl.focus();
			}
		}
	}

	cancel() {
		this.router.navigate(['/sell-side/' + this.screenId + '/blind-profile']);
	}

    addYear()
    {
        let existing = this.years.find((a) => (a.toLowerCase() == this.f.financialYear.value.toLowerCase()));
        if(existing)
        {

        }
        else
        {
            this.financials.forEach((el) => {
                el[this.f.financialYear.value] = null;
            })
            this.years.push(this.f.financialYear.value);
            this.years.sort((a:string, b:string) =>
            {
                if(a == 'dummy')
                    return -1;
                if(b == 'dummy')
                    return 1;
                let a1 = a.length >4?a.substring(0,4):a;
                let b1 = b.length >4?b.substring(0,4):b;
                if(a1.toLowerCase().localeCompare(b1.toLowerCase()) == 0)
                {
                    return a.toLowerCase().localeCompare(b.toLowerCase());
                }
                return a1.toLowerCase().localeCompare(b1.toLowerCase());
            });
            this.f.financialYear.setValue(null);
        }

    }

}
