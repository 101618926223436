import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { ImageSrcDirective } from './../shared/imagesrc.directive';
import { TooltipModule } from 'primeng/tooltip';
import { TreeTableModule } from 'primeng/treetable';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MediaRoutingModule } from './media-routing.module';
import { MediaListComponent } from './media.list.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ToastModule } from 'primeng/toast';
import { TreeModule } from 'primeng/tree';
import { ToolbarModule } from 'primeng/toolbar';
import { SplitButtonModule } from 'primeng/splitbutton';
import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { FileUploadModule } from 'primeng/fileupload';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { DataViewModule } from 'primeng/dataview';
import { RippleModule } from 'primeng/ripple';
import { BadgeModule } from 'primeng/badge';
import { ChipModule } from 'primeng/chip';
import { TagModule } from 'primeng/tag';
import { MediaLookupComponent } from './media.lookup.component';

@NgModule({
    declarations: [MediaListComponent, ImageSrcDirective, MediaLookupComponent],
    imports: [
        CommonModule,
        MediaRoutingModule,
        ReactiveFormsModule,
        ToastModule,
        TreeModule,
        TreeTableModule,
        ToolbarModule,
        SplitButtonModule,
        InputTextModule,
        DialogModule,
        ProgressSpinnerModule,
        FileUploadModule,
        CheckboxModule,
        FormsModule,
        TooltipModule,
        DataViewModule,
        DropdownModule,
        RippleModule,
        ButtonModule,
        BadgeModule,
        ChipModule,
        TagModule

    ],
    exports: [
        MediaListComponent,
        MediaLookupComponent
    ]
})
export class MediaModule { }
