import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '../app.breadcrumb.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SpotlightService } from "../spotlight/spotlight.service";
import { Spotlight } from "../_models";
import { AccountService } from 'src/app/_services';
import { MessageService } from 'primeng/api';
import { DataService } from '../_services/data.service';
import { AppUtil } from './../_helpers';

@Component({
    selector: 'app-spotlight.view',
    templateUrl: './spotlight.view.component.html',
    styleUrls: ['./spotlight.view.component.scss'],
    providers: [MessageService]
})
export class SpotlightViewComponent implements OnInit {

    uuid: string;
    spotlight: Spotlight;
    previewDialog: boolean;
    dialogTitle: string;
    constructor(private dataService: DataService, private messageService: MessageService, private spotlightService: SpotlightService, private breadcrumbService: BreadcrumbService,
        private router: Router, private route: ActivatedRoute, public accountService: AccountService, public appUtil: AppUtil) {
        this.breadcrumbService.setItems([
            { label: ' View SpotLight' }
        ]);
    }

    ngOnInit(): void {
        this.previewDialog = false;
        this.spotlight = new Spotlight;
        this.uuid = this.route.snapshot.params.uuid;
        this.spotlightService.getSpotlight(this.route.snapshot.params.uuid).subscribe(data => {
            this.spotlight = data as any;
            this.spotlight.deals = this.route.snapshot.data['spotlightDeal'];
            let message = this.dataService.getDataByKey('message');
            if (message != undefined) {
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: message, life: 3000 });
                this.dataService.setData('message', undefined);
            }
        });
        this.appUtil.reinitializeShowMore();
    }

    get role() {
        return this.accountService.userValue.role;
    }

    edit() {
        this.router.navigate(['/spotlight/edit/' + this.spotlight.uuid]);
    }

    preview() {
        this.dialogTitle = "PREVIEW SPOTLIGHT";
        this.previewDialog = true;
    }

    hideDialog() {
        this.previewDialog = false;
    }

}
