import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class BuysideService {

    constructor(private http: HttpClient) { }

    searchBuySides(searchParams: any) {
        return this.http.post(environment.apiUrl + '/buysides', searchParams);
    }

    getBuyside(uuid: string) {
        return this.http.get(environment.apiUrl + '/buyside/' + uuid);
    }

    addBuyside(data) {
        return this.http.post(environment.apiUrl + '/buyside', data);
    }

    updateBuyside(data) {
        return this.http.put(environment.apiUrl + '/buyside/' + data.uuid, data);
    }


    lookupBuySide(query) {
        return this.http.get(environment.apiUrl + '/buyside/lookup', { params: { query } });
    }

    getBlindProfile(sellUuid: string) {
        return this.http.get(environment.apiUrl + '/buyside/' + sellUuid + '/blind-profile');
    }

    addBlindProfile(sellUuid: string, data: any) {
        return this.http.post(environment.apiUrl + '/buyside/' + sellUuid + '/blind-profile', data);
    }

    UpdateBlindProfile(sellUuid: string, uuid: string, data: any) {
        return this.http.put(environment.apiUrl + '/buyside/blind-profile/' + uuid, data);
    }

    patchBlindProfile(fieldsToBeUpdated: string[], data: any): Observable<any> {
        return this.http.patch(environment.apiUrl + '/buyside/blind-profile/' + data.uuid,
            { "fieldsToBeUpdated": fieldsToBeUpdated, "model": data });
    }

    getCampaign(uuid: string, searchParams: any) {
        if (searchParams == null)
            return this.http.get(environment.apiUrl + '/buyside/' + uuid + '/all-campaigns');
        else
            return this.http.post(environment.apiUrl + '/buyside/' + uuid + '/campaign', searchParams);
    }

    getProspects(uuid: string, searchParams: any) {
        return this.http.post(environment.apiUrl + '/buyside/' + uuid + '/prospects', searchParams);
    }

    getContactsAsProspects(uuid: string) {
        return this.http.post(environment.apiUrl + '/buyside/' + uuid + '/contact-as-prospect', null);
    }

    addProspects(uuid: string, contactIds: string[], accountIds: string[]) {
        return this.http.post(environment.apiUrl + '/buyside/' + uuid + '/add-prospects',
            { "contacts": contactIds, "accounts": accountIds });
    }
    addAssociatedContactsAsProspects(uuid: string, contactIds: string[] , prospect:string) {
        return this.http.post(environment.apiUrl + '/buyside/' + uuid + '/add-associated-contact-as-prospects',
            { "contacts": contactIds ,"prospectUuid" :prospect} );
    }

    patchProspect(fieldsToBeUpdated: string[], data: any) {
        return this.http.patch(environment.apiUrl + '/buyside/prospect/' + data.uuid,
            { "fieldsToBeUpdated": fieldsToBeUpdated, "model": data });
    }

    deleteProspects(buysideUuid: string, prospects: any) {
        return this.http.post(environment.apiUrl + '/buyside/' + buysideUuid + "/remove-prospects", prospects);
    }

    deleteBuysideScreens(buysides: any): Observable<any> {
        return this.http.delete(environment.apiUrl + '/buysides', { params: { buysides } });
    }

    addProspectAsRecipients(campaignuuids: string[], prospectUuid: string) {
        return this.http.post(environment.apiUrl + "/buyside/prospect/" + prospectUuid + "/as-recipient", campaignuuids);
    }

    updateProspectSortOrder(prospectUuid: string, newSortOrder: number) {
        return this.http.put(environment.apiUrl + "/buyside/prospect/" + prospectUuid + "/updateSortOrder", { newSortOrder });
    }
    getBuysidesForContact(uuid: string) {
        return this.http.get(environment.apiUrl + '/buysides/' + uuid + '/forContact')
    }

    downloadProspect(searchParams: any, uuid: string) {
        return this.http.post(environment.apiUrl + '/buyside/' + uuid + '/download/xlsx', searchParams, {
            responseType: 'blob'
        });
    }
    updateBuysideList(fieldsToBeUpdated: string[], data: any) { 
        return this.http.patch(environment.apiUrl + '/buysides/' + data.uuid,
            { "fieldsToBeUpdated": fieldsToBeUpdated, "model": data });
    }
}
