<div class="grid table-demo">
  <div class="col-12">
    <div class="card">
      <p-toolbar styleClass="headerSticky">
        <div class="p-toolbar-group-left">
          <div class="grid">
            <div class="col-12"></div>
          </div>
        </div>
        <div class="p-toolbar-group-right">
          <div class="grid">
            <div class="col-12">
              <button pButton pRipple label="Back" icon="pi pi-arrow-left" class="p-button-success mr-2 mb-1"
                (click)="return()"></button>
              <button pButton type="button" class="p-button-success mr-2 mb-1" (click)="confirmAdd()" *ngIf="showCheckbox"
                [disabled]="!appUtil.isArray(selectedContacts) || selectedContacts.length == 0">Add Selected ({{appUtil.isArray(selectedContacts)? selectedContacts.length: 0}})
              </button>
              <button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success mr-2 mb-1"
                (click)="appUtil.routeToNewURL('contact')" *ngIf="!showCheckbox"></button>
              <button pButton pRipple label="Download" class="p-button-success mr-2 mb-1"
                (click)="downloadUser()"></button>
              <p-splitButton label="Upload" (onClick)="contactUpload()" icon="pi pi-upload" styleClass=" mb-1"
                [model]="uploadItems" *ngIf="!showCheckbox"></p-splitButton>
            </div>
          </div>
        </div>
      </p-toolbar>
      <tbp-search #sc [fields]="fieldInfo" filterFor="CONTACT" searchTermPlaceHolder="Search Contacts By Name"
        [searchURL]="searchUrl" (onSearch)="_search($event)" [pageId]="pageId" (onShowHideField)="_updateSelectedColumns($event)">
      </tbp-search>
      <div class="p-fluid grid" *ngIf="currentQuery">
        <div class="col-12">
          <strong>Current Query:</strong>
          {{ isAdvanceSearch ? currentQuery : "matches " + currentQuery }}
        </div>
      </div>
      <p-table [autoLayout]="true" #dt [value]="contacts" [(selection)]="selectedContacts" dataKey="uuid"
        [columns]="selectedColumns" [lazy]="true" (onLazyLoad)="
          sc.loadData(
            $event.first,
            $event.rows,
            $event.sortField,
            $event.sortOrder
          )
        " [lazyLoadOnInit]="false"
        styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm" [rowHover]="true"
        [rows]="sc.pageSize" [first]="sc.firstRow" [paginator]="true"
        [rowsPerPageOptions]="[15, 25, 50, 75, 100]" [loading]="loading"
        [alwaysShowPaginator]="true" [showCurrentPageReport]="true" [showPageLinks]="appUtil.getPagination(totalRecords)" [showFirstLastIcon] = "appUtil.getPagination(totalRecords)"
        currentPageReportTemplate="Showing {first} to {last} of {{appUtil.getPagination(totalRecords) ? totalRecords :'many'}} entries"[totalRecords]="totalRecords"
        [sortField]="sc.sortField" [sortOrder]="sc.sortOrder" (onEditInit)="initEdit($event)"
        (onEditComplete)="editCompleted($event)" (onEditCancel)="editCancelled($event)">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th style="width: 3rem" *ngIf="showCheckbox">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <ng-container *ngFor="let column of columns">
              <th pSortableColumn="{{ column.property }}" [pSortableColumnDisabled]="!column.sortable">
                {{ column.label }}
                <p-sortIcon field="{{ column.property }}" *ngIf="column.sortable"></p-sortIcon>
              </th>
            </ng-container>
            <th *ngIf="!showCheckbox">Action</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-contact let-columns="columns">
          <tr>
            <td (click)="$event.stopPropagation()" *ngIf="showCheckbox">
              <p-tableCheckbox [value]="contact"></p-tableCheckbox>
            </td>
            <ng-container *ngFor="let column of columns; let colIndex = index">
              <ng-container *ngIf="column.property == 'name';else notName">
                <td class="wrap" [attr.width]="column.columnWidth" [pEditableColumn]="contact"
                  [pEditableColumnField]="'name'" [pEditableColumnRowIndex]="index">
                  <ng-container *ngIf="!isEditing(contact.uuid); else editMode">
                    <div class="contact-info">
                      <a [routerLink]="['/contact', contact.uuid]" [innerHTML]="contact.name | highlight:term"></a>
                      <sup *ngIf=" contact.status == 'PENDING_ACTIVATION' || contact.status == 'PENDING_PROFILE' || contact.status == 'REVOKED' || !contact.verified">
                        <p-badge class="small-p-badge" [pTooltip]="appUtil.getUserStatus(contact)"
                          [ngClass]="{'pendingactivation': contact.status == 'PENDING_ACTIVATION','pendingprofile': contact.status == 'PENDING_PROFILE',
                        'pendingverification': (!contact.verified && !contact.selfSignUp),
                        'pendingverificationself': (!contact.verified && contact.selfSignUp),'revoked': contact.status == 'REVOKED'}"></p-badge>
                      </sup>
                      <button pButton type="button" icon="pi pi-pencil" title="Edit" class="p-button-text edit-button"
                        (click)="toggleEditingMode(contact.uuid)"></button>
                    </div>
                  </ng-container>
                  <ng-template #editMode>
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="contact.name"
                          (keydown)="disableNavigation($event)" />
                      </ng-template>
                      <ng-template pTemplate="output">
                        <a [routerLink]="['/contact', contact.uuid]">{{ contact.name }}</a>
                      </ng-template>
                    </p-cellEditor>
                  </ng-template>

                  <!-- <ng-container *ngIf="column.property != 'name'">
                  <tbp-table-column [object]="contact" [fieldInfo]="column"
                    (onPatch)="patchDeal($event)"></tbp-table-column>
                </ng-container> -->
                </td>
              </ng-container>

              <ng-template #notName>
                <ng-container *ngIf="column.property == 'organization'; else notOrganization ">
                  <td class="wrap" [pEditableColumn]="contact" [pEditableColumnField]="'organization'"
                    [pEditableColumnRowIndex]="index">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <span class="p-column-title">{{ appUtil.getCustomLabel(column,
                          customLabels) }}</span>
                        <p-autoComplete appendTo="body" [unique]="true" [forceSelection]="true" [showClear]="true"
                          [suggestions]="organizationResults" [(ngModel)]="contact.organization"
                          (completeMethod)="searchOrganizations($event)" field="name"> </p-autoComplete>

                      </ng-template>
                      <ng-template pTemplate="output">
                        <span class="p-column-title">{{appUtil.getCustomLabel(column,
                          customLabels)}}</span>
                        <a
                          [routerLink]="['/organization/', contact.organization!=null ? contact.organization.uuid:null]">{{
                          getOutput(contact.organization)}}</a>
                      </ng-template>
                    </p-cellEditor>
                  </td>
                </ng-container>
              </ng-template>
              <ng-template #notOrganization>
                <ng-container *ngIf="column.property == 'accountOwner'; else notAccountOwner ">
                  <td class="wrap" [pEditableColumn]="contact" [pEditableColumnField]="'accountOwner'"
                    [pEditableColumnRowIndex]="index">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <span class="p-column-title">{{ appUtil.getCustomLabel(column,
                          customLabels) }}</span>
                        <p-autoComplete [(ngModel)]="contact.accountOwner" [forceSelection]="true"
                          [suggestions]="filteredAccountOwner" (completeMethod)="searchAccountOwner($event)"
                          field="name"></p-autoComplete>

                      </ng-template>
                      <ng-template pTemplate="output">
                        <span class="p-column-title">{{appUtil.getCustomLabel(column,
                          customLabels)}}</span>

                        {{ getAccountOwner(contact.accountOwner)}}
                      </ng-template>
                    </p-cellEditor>
                  </td>
                </ng-container>
              </ng-template>
              <ng-template #notAccountOwner>
                <ng-container
                  *ngIf="(column.property == 'status'  || column.property == 'createdDate' || column.property == 'lastModifiedDate') ; else notStatus">
                  <td class="wrap">
                    {{ getOutputValue(column, contact) }}
                  </td>
                </ng-container>
              </ng-template>
              <ng-template #notStatus>
                <ng-container *ngIf="column.property == 'level1';else notLevel1">
                  <td class="wrap" [pEditableColumn]="contact" [pEditableColumnField]="'level1'"
                    [pEditableColumnRowIndex]="index">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <span class="p-column-title">{{appUtil.getCustomLabel(column,
                          customLabels)}}</span>
                        <p-multiSelect [multiple]="false" [options]="classificationList.CATEGORY_LEVEL1"
                          [(ngModel)]="contact.classification.level1" optionLabel="name" placeholder="Choose"
                          appendTo="body" dataKey="uuid" (keydown)="disableNavigation($event)"></p-multiSelect>
                      </ng-template>
                      <ng-template pTemplate="output">
                        <span class="p-column-title">{{appUtil.getCustomLabel(column,
                          customLabels)}}</span>
                        <span *ngFor="let classi of contact.classification.level1; let last = last;"
                          pTooltip="{{classi.name}}" tooltipPosition="bottom">
                          {{classi.name }}{{last?'':','}}
                        </span>
                      </ng-template>
                    </p-cellEditor>
                  </td>
                </ng-container>
              </ng-template>
              <ng-template #notLevel1>
                <ng-container *ngIf="column.property == 'level2';else notLevel2">
                  <td class="wrap" [pEditableColumn]="contact" [pEditableColumnField]="'level2'"
                    [pEditableColumnRowIndex]="index">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <span class="p-column-title">{{appUtil.getCustomLabel(column,
                          customLabels)}}</span>
                        <p-multiSelect [multiple]="false" [options]="classificationList.CATEGORY_LEVEL2"
                          [(ngModel)]="contact.classification.level2" optionLabel="name" placeholder="Choose"
                          appendTo="body" dataKey="uuid" (keydown)="disableNavigation($event)"></p-multiSelect>
                      </ng-template>
                      <ng-template pTemplate="output">
                        <span class="p-column-title">{{appUtil.getCustomLabel(column,
                          customLabels)}}</span>
                        <span *ngFor="let classi of contact.classification.level2; let last = last;"
                          pTooltip="{{classi.name}}" tooltipPosition="bottom">
                          {{classi.name }}{{last?'':','}}
                        </span>
                      </ng-template>
                    </p-cellEditor>
                  </td>
                </ng-container>
              </ng-template>
              <ng-template #notLevel2>
                <ng-container *ngIf="column.property == 'level3';else notLevel3">
                  <td class="wrap" [pEditableColumn]="contact" [pEditableColumnField]="'level3'"
                    [pEditableColumnRowIndex]="index">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <span class="p-column-title">{{appUtil.getCustomLabel(column,
                          customLabels)}}</span>
                        <p-multiSelect [multiple]="false" [options]="classificationList.CATEGORY_LEVEL3"
                          [(ngModel)]="contact.classification.level3" optionLabel="name" placeholder="Choose"
                          appendTo="body" dataKey="uuid" (keydown)="disableNavigation($event)"></p-multiSelect>
                      </ng-template>
                      <ng-template pTemplate="output">
                        <span class="p-column-title">{{appUtil.getCustomLabel(column,
                          customLabels)}}</span>
                        <span *ngFor="let classi of contact.classification.level3; let last = last;"
                          pTooltip="{{classi.name}}" tooltipPosition="bottom">
                          {{classi.name }}{{last?'':','}}
                        </span>
                      </ng-template>
                    </p-cellEditor>
                  </td>
                </ng-container>
              </ng-template>
              <ng-template #notLevel3>
                <ng-container *ngIf="column.property == 'vertical';else notVertical">
                  <td class="wrap" [pEditableColumn]="contact" [pEditableColumnField]="'vertical'"
                    [pEditableColumnRowIndex]="index">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <span class="p-column-title">{{appUtil.getCustomLabel(column,
                          customLabels)}}</span>
                        <p-multiSelect [options]="classificationList.VERTICAL"
                          [(ngModel)]="contact.classification.vertical" optionLabel="name" placeholder="Choose"
                          appendTo="body" dataKey="uuid" (keydown)="disableNavigation($event)">
                        </p-multiSelect>
                      </ng-template>
                      <ng-template pTemplate="output">
                        <span class="p-column-title">{{appUtil.getCustomLabel(column,
                          customLabels)}}</span>
                        <span *ngFor="let classi of contact.classification.vertical; let last = last;"
                          pTooltip="{{classi.name}}" tooltipPosition="bottom">
                          {{classi.name }}{{last?'':','}}
                        </span>
                      </ng-template>
                    </p-cellEditor>
                  </td>
                </ng-container>
              </ng-template>
              <ng-template #notVertical>
                <ng-container *ngIf="column.property == 'ecosystem';else notEcosystem">
                  <td class="wrap" [pEditableColumn]="contact" [pEditableColumnField]="'ecosystem'"
                    [pEditableColumnRowIndex]="index">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <span class="p-column-title">{{appUtil.getCustomLabel(column,
                          customLabels)}}</span>
                        <p-multiSelect [options]="classificationList.ECOSYSTEM"
                          [(ngModel)]="contact.classification.ecosystem" optionLabel="name" placeholder="Choose"
                          appendTo="body" dataKey="uuid" (keydown)="disableNavigation($event)">
                        </p-multiSelect>
                      </ng-template>
                      <ng-template pTemplate="output">
                        <span class="p-column-title">{{appUtil.getCustomLabel(column,
                          customLabels)}}</span>
                        <span *ngFor="let classi of contact.classification.ecosystem; let last = last;"
                          pTooltip="{{classi.name}}" tooltipPosition="bottom">
                          {{classi.name }}{{last?'':','}}
                        </span>
                      </ng-template>
                    </p-cellEditor>
                  </td>
                </ng-container>
              </ng-template>
              <ng-template #notEcosystem>
                <td class="wrap" [pEditableColumn]="contact" [pEditableColumnField]="column.property"
                  [pEditableColumnRowIndex]="index">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <ng-container
                        *ngIf="column.property == 'primaryContact' && contact.organization; else notPrimaryContact">
                        <p-radioButton name="primaryContact" [value]="true" (keydown)="disableNavigation($event)"
                          [(ngModel)]="contact[column.property]" label="Y"></p-radioButton>
                        <p-radioButton name="primaryContact" [value]="false" (keydown)="disableNavigation($event)"
                          [(ngModel)]="contact[column.property]" label="N" class="ml-2"></p-radioButton>
                      </ng-container>
                      <ng-template #notPrimaryContact>
                        <ng-container
                          *ngIf="column.property == 'accreditedInvestor' && !contact.organization; else notAccreditedInvestor">
                          <p-radioButton name="accreditedInvestor" [value]="true" (keydown)="disableNavigation($event)"
                            [(ngModel)]="contact[column.property]" label="Y"></p-radioButton>
                          <p-radioButton name="accreditedInvestor" [value]="false" (keydown)="disableNavigation($event)"
                            [(ngModel)]="contact[column.property]" label="N" class="ml-2"></p-radioButton>
                        </ng-container>
                      </ng-template>
                      <ng-template #notAccreditedInvestor>
                        <ng-container *ngIf="column.property == 'masterProfile'; else notMasterProfile">
                          <p-radioButton name="masterProfile" [value]="true" (keydown)="disableNavigation($event)"
                            [(ngModel)]="contact[column.property]" label="Y"></p-radioButton>
                          <p-radioButton name="masterProfile" [value]="false" (keydown)="disableNavigation($event)"
                            [(ngModel)]="contact[column.property]" label="N" class="ml-2"></p-radioButton>
                        </ng-container>
                      </ng-template>
                      <ng-template #notMasterProfile>
                        <ng-container *ngIf="column.property == 'email' ; else notEmail">
                          <input pInputText type="email" [(ngModel)]="contact.email"
                            (keydown)="disableNavigation($event)"
                            [ngClass]="{'invalid-email': !isInvalidEmail(contact.email)}">
                          <div *ngIf="isInvalidEmail(contact.email)" style="color: red;">Invalid email format</div>
                        </ng-container>
                      </ng-template>
                      <ng-template #notEmail>
                        <ng-container *ngIf="column.property == 'leadSource' ; else notLeadSource">
                          <look-autocomplete [multiple]="false"
                          [type]="getLookupType(column.property)"
                          [(ngModel)]="contact[column.property]"
                          [items]="getLookupItems(column.property)" tooltipPosition="top"
                          (keydown)="disableNavigation($event)">
                      </look-autocomplete>
                        </ng-container>
                      </ng-template>
                      <ng-template #notLeadSource>
                        <ng-container *ngIf="column.property=='description'; else notDescription">
                          <p-dialog [visible]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
                            [style]="{width: '50vw'}" [closable]="false" [modal]="true">
                            <ng-template pTemplate="header">
                              <div class="p-fluid formgrid grid">
                                <div class="field col-12">
                                  <strong>{{contact.name}}:</strong>
                                  {{column.label}}
                                </div>
                              </div>
                            </ng-template>
                            <ng-template pTemplate="content">
                              <editor [(ngModel)]="contact.description" [init]="appUtil.getInitData(180,null)">
                              </editor>
                            </ng-template>
                            <ng-template pTemplate="footer">
                              <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
                                (click)="editorAction($event, contact, 'description', false)"></button>
                              <button pButton pRipple label="Close" class="p-button-text"
                                (click)="editorAction($event, contact, 'description', true)"></button>
                            </ng-template>
                          </p-dialog>
                        </ng-container>
                      </ng-template>
                      <ng-template #notDescription>
                        <ng-container
                          *ngIf="column.property !== 'primaryContact' && column.property !== 'accreditedInvestor'">
                          <input pInputText type="text" mode="text" [(ngModel)]="contact[column.property]"
                            (keydown)="disableNavigation($event)">
                        </ng-container>
                      </ng-template>
                    </ng-template>
                    <ng-template pTemplate="output">
                      <span class="p-column-title">{{appUtil.getCustomLabel(column,
                        customLabels)}}</span>
                      <ng-container *ngIf="column.property == 'primaryContact' ; else notPrimaryContact">
                        {{contact[column.property] === true?'Y':(contact[column.property] === false?'N':'')}}
                      </ng-container>
                      <ng-template #notPrimaryContact>
                        <ng-container *ngIf="column.property == 'accreditedInvestor' ; else notAccreditedInvestor">
                          {{contact[column.property] === true?'Y':(contact[column.property] === false?'N':'')}}
                        </ng-container>
                      </ng-template>
                      <ng-template #notAccreditedInvestor>
                        <ng-container *ngIf="column.property == 'masterProfile' ; else notMasterProfile">
                          {{contact[column.property] === true?'Y':(contact[column.property] === false?'N':'')}}
                        </ng-container>
                      </ng-template>
                      <ng-template #notMasterProfile>
                        <ng-container *ngIf="column.property == 'email' ; else notEmail">
                          {{contact.email}}
                        </ng-container>
                      </ng-template>
                      <ng-template #notEmail>
                        <ng-container *ngIf="column.property == 'leadSource' ; else notLeadSource">
                          {{getOutputs(contact[column.property])}}
                        </ng-container>
                      </ng-template>
                      <ng-template #notLeadSource>
                        <ng-container *ngIf=" column.property=='description' ; else notDescription ">
                          <span class="limitedText limitText" [innerHTML]="contact[column.property]"></span>
                          <a href="javascript:" (click)="appUtil.showHideClampedContent($event)"
                            class="moreless hidden ml-2 p-button-danger" style="float:right;display:block;">More</a>
                        </ng-container>
                      </ng-template>
                      <ng-template #notDescription>
                        <ng-container
                          *ngIf="column.property != 'name' || column.property != 'description' || column.property != 'ecosystem' || column.property !='accreditedInvestor'
                             (column.property != 'organization' && contact.organization !=null) || (column.property != 'accountOwner' && contact.organization !=null) ">
                          {{contact[column.property]}}
                        </ng-container>
                      </ng-template>
                    </ng-template>
                  </p-cellEditor>
                </td>

              </ng-template>


            </ng-container>
            <td *ngIf="!showCheckbox">
              <span class="p-column-title">Action</span>
              <button type="button" pButton icon="pi pi-list"
                (click)="setCurrentItem(contact); menu.toggle($event)"></button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="20">
              <span *ngIf="term != ''">Search did not return any result. Try relaxing the criteria or
                use wildcard [*, ?]</span>
              <span *ngIf="term == ''">No Contacts found</span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
<ng-template #classification let-column="column" let-contact="contact">
  <span class="p-column-title">Level1</span>
  <span class="limitedText limitText">
    <span *ngFor="
        let classification of contact.classification[column.property];
        let last = last
      ">{{ classification.name }}{{ last ? "" : "; " }}</span>
  </span>
  <a href="javascript:" (click)="appUtil.showHideClampedContent($event)" class="moreless hidden ml-2 p-button-danger"
    style="float: right; display: block">More</a>
</ng-template>
<ng-template #noclassification let-column="column" let-contact="contact">
  {{ contact[column.property] }}
</ng-template>
<p-toast></p-toast>
<app-upload-excel (onSuccess)="reload()" [(show)]="uploadPop" url="users/upload" title="Contact Upload">
</app-upload-excel>
<note-edit [(show)]="notesDialog" noteType="contact" [parent]="currentMenuData"></note-edit>
<task-edit [(show)]="tasksDialog" taskType="contact" [parentUuid]="currentMenuData.uuid"
  [parent]="currentMenuData"></task-edit>
<p-menu #menu [popup]="true" [model]="contactItems"></p-menu>
<p-dialog [(visible)]="credentialDialog" (onHide)="hidePassword()" [style]="{ width: '35vw' }"
  [header]="dialogTitleCredential" [modal]="true">
  <ng-template pTemplate="content">
    <form [formGroup]="credentialForm" #pform>
      <div class="card">
        <div class="p-fluid formgrid grid">
          <div class="field col-12">
            <span class="p-float-label">
              <input type="text" pInputText [disabled]="true" value="{{ currentMenuData.email }}" />
              <label for="email">Email <span class="mandate">*</span></label>
            </span>
          </div>
          <div class="field col-12 spacing">
            <span class="p-input-icon-right">
              <i class="pi pi-eye" (click)="showHidePassword()"></i>
              <input pPassword id="password" (keyup.enter)="createCredential()" formControlName="password"
                placeholder="Password *" [ngClass]="{
                  'ng-invalid ng-dirty': pfSubmitted && pf.password.errors
                }" [feedback]="false" [showPassword]="showPassword" />
            </span>
            <span class="p-error" *ngIf="
                pfSubmitted &&
                pf.password.invalid &&
                pf.password.hasError('required')
              ">Password is required.</span>
          </div>
          <div class="field col-4">
            <button pButton label="Generate Password" class="p-button-success" (click)="generatePassword()"></button>
          </div>
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template pTemplate="footer">
    <div *ngIf="loadingSpinner == false">
      <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hidePassword()"></button>
      <button pButton pRipple label="Save" form="clform" icon="pi pi-check" class="p-button-text"
        (click)="saveCredential()"></button>
    </div>
    <div *ngIf="loadingSpinner">
      <p-progressSpinner [style]="{ width: '50px', height: '50px' }" styleClass="custom-spinner" strokeWidth="8"
        fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
    </div>
  </ng-template>
</p-dialog>
<p-dialog [(visible)]="showAddDialog" [breakpoints]="{ '960px': '75vw', '640px': '100vw' }" [style]="{ width: '50vw' }">
  <ng-template pTemplate="header">
    <div class="p-fluid formgrid grid">
      <div class="field col-12">
        <i class="pi pi-exclamation-triangle mr-2"></i>Are you sure that you
        want to add the contacts listed below?
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="p-fluid formgrid grid">
      <div class="field col-12">
        <p-table [autoLayout]="true" [value]="selectedContacts"
          styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm" [rowHover]="true">
          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="name">
                Name
                <p-sortIcon field="name"></p-sortIcon>
              </th>
              <th pSortableColumn="organization">
                Account Name
                <p-sortIcon field="organization"></p-sortIcon>
              </th>
              <th pSortableColumn="email">
                Email
                <p-sortIcon field="email"></p-sortIcon>
              </th>
              <th pSortableColumn="title">
                Title
                <p-sortIcon field="title"></p-sortIcon>
              </th>
              <th>Action</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-contact>
            <tr>
              <td class="wrap capitalize">
                <span class="p-column-title">Name</span>{{ contact.name }}
              </td>
              <td class="wrap">
                <span class="p-column-title">Account Name</span>{{ contact.organization ? contact.organization.name : ""
                }}
              </td>
              <td class="wrap lowercase">
                <span class="p-column-title">Email</span>
                <a href="mailto:{{ contact.email }}">{{ contact.email }}</a>
              </td>
              <td class="wrap">
                <span class="p-column-title">Title</span>
                {{ contact.title }}
              </td>
              <td>
                <span class="p-column-title">Action</span>
                <button type="button" pButton icon="pi pi-minus-circle" (click)="remove(contact)"></button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Add" icon="pi pi-check" class="p-button-text" (click)="addToSource()"></button>
    <button pButton pRipple label="Close" class="p-button-text" (click)="showAddDialog = false"></button>
  </ng-template>
</p-dialog>
<p-confirmDialog [style]="{width: '450px'}">

</p-confirmDialog>