import { UserPopupListComponent } from './../user-popup/user-popup.list.component';
import { Component, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LazyLoadEvent, MenuItem } from 'primeng/api';
import { BreadcrumbService } from '../app.breadcrumb.service';
import { AppUtil, SearchHeler } from './../_helpers';
import { Organization, Deal, Spotlight, BuySide, SellSide, Address, User, RuleSet } from '../_models';
import { DataService } from '../_services/data.service';
import { OrganizationService } from './organization.service';
import { MessageService, ConfirmationService } from 'primeng/api';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AccountService } from 'src/app/_services';
import { UsersService } from '../users/users.service';
import { EmailCampaign } from '../_models/emailcampaign';
import { retry } from 'rxjs';
interface AutoCompleteCompleteEvent {
    originalEvent: Event;
    query: string;
}
@Component({
    selector: 'app-organization.view',
    templateUrl: './organization.view.component.html',
    styleUrls: ['./organization.view.component.scss'],
    providers: [MessageService, ConfirmationService]
})
export class OrganizationViewComponent implements OnInit {
    @ViewChild('cform') formElement;
    @ViewChild('contactlist') contactList: UserPopupListComponent;
    organization: Organization;
    uuid: string;
    contactItems: MenuItem[];
    noteAttachmentItems: MenuItem[];
    //Address 
    addressForm: UntypedFormGroup;
    orgAddress: boolean = false;
    afSubmitted: boolean;
    acfSubmitted: boolean;
    dialogTitleAddress: string;
    countries = [];
    states = [];
    stateMap = new Map();
    addresses = [];
    modelState = '';
    // Deal List
    deals: Deal[];
    selectedDeals: Deal[] = [];
    dealLoading: boolean = true;
    dealTotalRecords: number = 0;
    // Spotlight List
    spotlights: Spotlight[];
    selectedSpotlight: Spotlight[] = [];
    spotlightLoading: boolean = true;
    spotlightTotalRecords: number = 0;

    // BuySide List
    buysides: BuySide[];
    selectedBuySides: BuySide[] = [];
    buysideLoading: boolean = true;
    buysideTotalRecords: number = 0;
    serchBuy: string;
    // BuySide List
    sellsides: SellSide[];
    selectedSellSides: SellSide[] = [];
    sellsideLoading: boolean = true;
    sellsideTotalRecords: number = 0;
    serchSell: string;
    // Email Campaign List
    emailCampaigns: EmailCampaign[];
    selectedEmailCampaigns: EmailCampaign[];
    emailCampaignLoading: boolean = true;
    emailCampaignTotalRecords: number = 0;

    // Parents List
    parents: Organization[];
    selectedParent: Organization[] = [];
    parentLoading: boolean = true;
    parentTotalRecords: number = 0;

    // Investors List
    investors: Organization[];
    selectedInvestor: Organization[] = [];
    investorLoading: boolean = true;
    investorTotalRecords: number = 0;
    //Portfolio Companies List
    portfolio: Organization[];
    selectedPortfolio: Organization[] = [];
    portfolioLoading: boolean = true;
    portfolioTotalRecords: number = 0;
    participantResults: [];

    // Associate Contact List
    relatedContact: User[];
    selectedContacts: User[];
    diaContactShow: boolean;
    diaTitleContact: string = "Associate Contacts";
    stateDisabled: boolean = false;
    stateSuggestions: any[];
    buysidePortfolio: string[] = [];
    editingField: any = {};
    lookupList: any = {
        ACCOUNT_SOURCE: [], REVENUE: [], ORG_TYPE_INVESTOR: [], No_OF_EMPLOYEES: [],
        ORG_TYPE_LENDER: [], ORG_TYPE_OTHER: []
    };
    classificationList: any = {
        CATEGORY_LEVEL1: [], CATEGORY_LEVEL2: [], CATEGORY_LEVEL3: [],
        VERTICAL: [], ECOSYSTEM: []
    };
    tbpEmployees: any[] = [];
    filteredValues: any[] | undefined;
    initialFieldValues: { [fieldName: string]: any } = {};
    parent: any;
    loading: boolean = false;
    organizationResults: any;
    country:any;
    state:any;
    companyType:any;
    numberOfEmployee:any;
    revenue:any;
    leadSource:any;
    constructor(
        private messageService: MessageService,
        private breadcrumbService: BreadcrumbService,
        private organizationService: OrganizationService,
        private route: ActivatedRoute,
        private dataService: DataService,
        private formBuilder: UntypedFormBuilder,
        public accountService: AccountService,
        public appUtil: AppUtil,
        private usersService: UsersService,
        private router: Router,
        private searchHelper: SearchHeler

    ) {
        this.breadcrumbService.setItems([
            { label: 'View Account' }
        ]);
    }
    loops = [0, 1, 2, 3, 4];
    associateContacts = this.formBuilder.group({
        membersToAdd: this.formBuilder.array([]),
    });

    ngOnInit(): void {
        this.reload();
        let message = this.dataService.getDataByKey('message');

        this.dataService.setData('message', undefined);
        if (message != undefined) {
            setTimeout(() => { this.messageService.add({ severity: 'success', summary: 'Successful', detail: message, life: 3000 }); })

        }

        this.loops.forEach((document) =>
            this.documentsControl.push(
                this.formBuilder.group({
                    uuid: [],
                    role: [],
                })
            )
        );

        let selectedData = this.dataService.getDataByKey("buyside-organizations-" + this.uuid);
        let buysideToBeAdded = [];
        let sellsideToBeAdded = [];
        if (selectedData && Array.isArray(selectedData)) {
            buysideToBeAdded = selectedData.map(a => a.uuid);
            this.dataService.deleteData("buyside-organizations-" + this.uuid);
        }
        else {
            selectedData = this.dataService.getDataByKey("sellside-organizations-" + this.uuid);
            if (selectedData && Array.isArray(selectedData)) {
                sellsideToBeAdded = selectedData.map(a => a.uuid);
                this.dataService.deleteData("sellside-organizations-" + this.uuid);
            }
        }
        if (buysideToBeAdded.length > 0 || sellsideToBeAdded.length > 0) {
            this.loading = true;
            this.organizationService.addBuysideSelside(this.uuid, buysideToBeAdded, sellsideToBeAdded).subscribe(
                data => {
                    this.messageService.add({ severity: 'success', summary: 'Successful', detail: this.appUtil.prepareMessageForProspects(data as any), life: 3000 });
                    this.reload();
                    this.loading = false;
                },
                error => {
                    this.loading = true;
                });
        }
        else {
            this.loading = true;
        }

    }

    reload() {
        this.organization = new Organization();
        this.route.paramMap.subscribe(params => {
            this.countries = this.route.snapshot.data['countries'];
            this.lookupList = this.route.snapshot.data['lookupList'];
            this.classificationList = this.route.snapshot.data['classificationList'];
            this.tbpEmployees = this.route.snapshot.data['tbpEmployees'];
            this.uuid = this.route.snapshot.params.uuid;
            this.organizationService.getOrganization(this.uuid).subscribe(data => {
                this.organization = data as any;
                this.country = this.countries.find(c => c.name === this.organization.country);
                this.state = this.country ? this.country.states?.find(state => state.name === this.organization.state) : '';
                this.leadSource = this.lookupList.ACCOUNT_SOURCE.find(ls => ls.value == this.organization.leadSource);
                this.revenue = this.lookupList.REVENUE.find(r => r.value == this.organization.revenue);
                this.numberOfEmployee = this.lookupList.No_OF_EMPLOYEES.find(noe => noe.value == this.organization.numberOfEmployee);
                this.companyType = this.searchOptions.find(option => option.uuid === this.organization.companyType);
                if (this.country) {
                    this.stateSuggestions = this.country.states;
                    this.stateDisabled = false;
                } else {
                    this.stateDisabled = true;
                    this.organization.state = null;
                }
            });
            this.organization = this.route.snapshot.data['organization'];
            this.addresses = this.organization.addresses as any;
            this.loadAddress();
            this.loadDeals();
            this.loadBuySides();
            this.loadSellSides();
            this.loadSpotlights();
            this.loadParents();
            this.loadinvestors();
            this.loadportfolio();
            this.loadAssociateContact();
            this.originalWebsiteValue = this.organization.website;

        });
    }
    checkState() {

        if (this.af.country.value && this.af.country.value != null && this.af.country.value != '') {
            this.stateDisabled = false;
        }
        else {
            this.stateDisabled = true;
            this.af.state.setValue(null);
            this.modelState = null;
            this.states = [];
        }
    }

    loadDeals(e: LazyLoadEvent = { first: 0, rows: 5, sortField: 'dealDate', sortOrder: 1 }) {
        this.dealLoading = true;
        var searchParams = {};
        searchParams['page'] = (e.first / e.rows);
        searchParams['pageSize'] = e.rows;
        var sortBy = []
        var sortDir = e.sortOrder == 1 ? 'DESC' : 'ASC';
        if (e.sortField && e.sortField != '') {
            sortBy[sortBy.length] = { "property": e.sortField, "direction": sortDir };
        }
        searchParams['sortDetails'] = sortBy;
        searchParams['rootRule'] = { 'condition': 'or', rules: [] }
        searchParams['rootRule']['rules'].push({ 'searchType': 'exact', 'field': 'targetOrganization', 'operator': 'equals', 'value': this.uuid });
        searchParams['rootRule']['rules'].push({ 'searchType': 'exact', 'field': 'buyerOrganizations', 'operator': 'in', 'values': [this.uuid] });
        this.organizationService.getDeals(this.uuid, searchParams).subscribe(
            data => {
                let response = data as any;
                this.dealTotalRecords = response.totalRecords;
                this.deals = response.records;
                this.dealLoading = false;
                this.appUtil.reinitializeShowMore();
            });
    }
    processSearchCommand(type: string) {
        let rootRule: RuleSet = { "condition": "and", ruleSets: [], "rules": [] };
        this.searchHelper._search(rootRule, '/organizations/' + this.uuid + '/' + type + '/add', null, "relevance", -1, 0, this.appUtil.pageSize, true);
    }
    loadSpotlights(e: LazyLoadEvent = { first: 0, rows: 5, sortField: 'dealDate', sortOrder: 1 }) {
        var searchParams = {};
        searchParams['page'] = (e.first / e.rows);
        searchParams['pageSize'] = e.rows;
        var sortBy = []
        var sortDir = e.sortOrder == 1 ? 'DESC' : 'ASC';
        if (e.sortField && e.sortField != '') {
            sortBy[sortBy.length] = { "property": e.sortField, "direction": sortDir };
        }
        searchParams['sortDetails'] = sortBy;
        this.organizationService.getSpotlights(this.uuid, searchParams).subscribe(
            data => {
                let response = data as any;
                this.spotlightTotalRecords = response.totalRecords;
                this.spotlights = response.records;
                this.spotlightLoading = false;
            });
    }

    get role() {
        return this.accountService.userValue.role;
    }

    loadBuySides(e: LazyLoadEvent = { first: 0, rows: 5, sortField: 'screenTitle', sortOrder: 1 }) {
        var searchParams = {};
        searchParams['page'] = (e.first / e.rows);
        searchParams['pageSize'] = e.rows;
        var sortBy = []
        var sortDir = e.sortOrder == 1 ? 'DESC' : 'ASC';
        if (e.sortField && e.sortField != '') {
            sortBy[sortBy.length] = { "property": e.sortField, "direction": sortDir };
        }
        searchParams['sortDetails'] = sortBy;
        if (this.serchBuy != '') {
            searchParams['query'] = this.serchBuy;
        }
        searchParams['rootRule'] = { 'condition': 'or', rules: [] }
        searchParams['rootRule']['rules'].push({ 'searchType': 'exact', 'field': 'organization', 'operator': 'equals', 'value': this.uuid });
        // searchParams['rootRule']['rules'].push({ 'searchType': 'exact', 'field': 'prospects.organization', 'operator': 'equals', 'value': this.uuid });
        this.organizationService.getBuySides(this.uuid, searchParams).subscribe(
            data => {
                let response = data as any;
                this.buysideTotalRecords = response.totalRecords;
                this.buysides = response.records;
                this.buysideLoading = false;
            });
    }

    loadSellSides(e: LazyLoadEvent = { first: 0, rows: 5, sortField: 'screenTitle', sortOrder: 1 }) {
        var searchParams = {};
        searchParams['page'] = (e.first / e.rows);
        searchParams['pageSize'] = e.rows;
        var sortBy = []
        var sortDir = e.sortOrder == 1 ? 'DESC' : 'ASC';
        if (e.sortField && e.sortField != '') {
            sortBy[sortBy.length] = { "property": e.sortField, "direction": sortDir };
        }
        searchParams['sortDetails'] = sortBy;
        if (this.serchSell != '') {
            searchParams['query'] = this.serchSell;
        }
        searchParams['rootRule'] = { 'condition': 'or', rules: [] }
        searchParams['rootRule']['rules'].push({ 'searchType': 'exact', 'field': 'organization', 'operator': 'equals', 'value': this.uuid });
        // searchParams['rootRule']['rules'].push({ 'searchType': 'exact', 'field': 'prospects.organization', 'operator': 'equals', 'value': this.uuid });

        this.organizationService.getSellSides(this.uuid, searchParams).subscribe(
            data => {
                let response = data as any;
                this.sellsideTotalRecords = response.totalRecords;
                this.sellsides = response.records;
                this.sellsideLoading = false;
            });
    }

    loadEmailCampaigns(e: LazyLoadEvent = { first: 0, rows: 5, sortField: 'title', sortOrder: 1 }) {
        var searchParams = {};
        searchParams['page'] = (e.first / e.rows);
        searchParams['pageSize'] = e.rows;
        var sortBy = []
        var sortDir = e.sortOrder == 1 ? 'DESC' : 'ASC';
        if (e.sortField && e.sortField != '') {
            sortBy[sortBy.length] = { "property": e.sortField, "direction": sortDir };
        }
        searchParams['sortDetails'] = sortBy;
        this.organizationService.getEmailCampaigns(this.uuid, searchParams).subscribe(
            data => {
                let response = data as any;
                this.emailCampaignTotalRecords = response.totalRecords;
                this.emailCampaigns = response.records;
                this.emailCampaignLoading = false;
            });
    }

    loadParents(e: LazyLoadEvent = { first: 0, rows: 5, sortField: 'name', sortOrder: 1 }) {
        this.parentLoading = true;
        var searchParams = {};
        searchParams['page'] = (e.first / e.rows);
        searchParams['pageSize'] = e.rows;
        var sortBy = []
        searchParams['sortDetails'] = sortBy;
        if (!this.parentLoading)
            this.parentLoading = true;
        this.organizationService.getParents(this.uuid, searchParams).subscribe(
            data => {
                let response = data as any;
                this.parentTotalRecords = response.totalRecords;
                this.parents = response.records;
                this.parentLoading = false;
            });
    }

    loadinvestors(e: LazyLoadEvent = { first: 0, rows: 5, sortField: 'name', sortOrder: 1 }) {
        this.investorLoading = true;
        var searchParams = {};
        searchParams['page'] = (e.first / e.rows);
        searchParams['pageSize'] = e.rows;
        var sortBy = []
        searchParams['sortDetails'] = sortBy;
        if (!this.investorLoading)
            this.investorLoading = true;
        this.organizationService.getInvestors(this.uuid, searchParams).subscribe(
            data => {
                let response = data as any;
                this.investorTotalRecords = response.totalRecords;
                this.investors = response.records;
                this.investorLoading = false;
            });
    }

    loadportfolio(e: LazyLoadEvent = { first: 0, rows: 5, sortField: 'name', sortOrder: 1 }) {
        this.portfolioLoading = true;
        var searchParams = {};
        searchParams['page'] = (e.first / e.rows);
        searchParams['pageSize'] = e.rows;
        var sortBy = []
        searchParams['sortDetails'] = sortBy;
        if (!this.portfolioLoading)
            this.portfolioLoading = true;
        this.organizationService.getPortfolioCompanies(this.uuid, searchParams).subscribe(
            data => {
                let response = data as any;
                this.portfolioTotalRecords = response.totalRecords;
                this.portfolio = response.records;
                this.portfolioLoading = false;
            });
    }

    get af() { return this.addressForm.controls; }


    loadAddress() {
        this.addressForm = this.formBuilder.group({
            uuid: [''],
            title: [null, Validators.required],
            phone: [''],
            address: [],
            city: [''],
            state: [],
            postalCode: [''],
            country: []
        });
        let country = this.countries.find(field => field.name === "United States");
        this.af.country.setValue(country);
        this.getStates();
    }

    getStates() {
        this.checkState();
        if (this.countries != null) {
            if (this.af.country && this.af.country.value != '' && this.af.country.value != null) {
                let states = this.appUtil.getStates(this.countries, this.af.country.value.name);
                if (states == null) {
                    states = []
                }
                this.states = states;

                if (this.modelState != null && this.modelState != '') {
                    let tempState = this.states.find(field => field.name === this.modelState);
                    if (tempState != undefined)
                        this.af.state.setValue(tempState);
                    else
                        this.af.state.setValue(this.modelState);
                }
            }
        }
    }

    newAddress() {
        this.loadAddress();
        this.dialogTitleAddress = "New Address";
        this.orgAddress = true;
    }

    hideDialogAddress() {
        this.orgAddress = false;
        this.addressForm.reset();
    }

    saveAddress() {
        this.afSubmitted = true;
        if (this.addressForm.invalid) {
            return;
        }
        let { uuid, title, phone, address, city, state, country, postalCode, organization } = this.addressForm.value;
        let dataToPost: any = { uuid, title, phone, country, address, city, state, postalCode, organization: {} };
        dataToPost.organization = this.organization;
        if (this.af.country.value) {
            dataToPost.country = this.af.country.value.name;
        }
        if (this.af.state.value) {
            if (typeof (this.af.state.value) === 'string')
                dataToPost.state = this.af.state.value;
            else
                dataToPost.state = this.af.state.value.name;
        }
        let apiToCall: any;
        if (dataToPost['uuid'] != '') {
            apiToCall = this.organizationService.updateAddress(dataToPost);
        }
        else {
            apiToCall = this.organizationService.addAddress(dataToPost);
        }
        apiToCall.subscribe(
            data => {
                this.getAllAddress();
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: "Save Successfully", life: 3000 });
                this.loadAddress();
                this.orgAddress = false;
                this.afSubmitted = false;
            },
            error => {
                let errors = error.error;
                if (errors.code && errors.code == 'VALIDATION_ERROR') {
                    for (var i = 0; i < errors.subErrors.length; i++) {
                        this.af[errors.subErrors[i].field].setErrors({ 'message': errors.subErrors[i].message });
                    }
                }
            }
        );
    }

    editAddress(address: Address) {
        this.loadAddress();
        this.dialogTitleAddress = "Edit Address";
        this.orgAddress = true;
        this.modelToFormAddress(address);
    }

    getAllAddress() {
        this.organizationService.getAllAddress(this.organization.uuid).subscribe(data => {
            this.addresses = data as any;
        });
    }

    modelToFormAddress(model) {
        this.addressForm.setValue({
            uuid: model.uuid,
            title: model.title,
            phone: model.phone,
            address: model.address,
            country: null,
            city: model.city,
            state: null,
            postalCode: model.postalCode,
        });
        let tempCnt = [];
        if (model.country != "" && model.country != null)
            tempCnt = this.countries.find(field => field.name === model.country);
        this.af.country.setValue(tempCnt);
        this.modelState = model.state;
    }

    associateContact() {
        this.diaContactShow = true;
    }
    associateContactHide() {
        this.diaContactShow = false;
        this.associateContacts.reset();
    }

    searchParticipant(event) {
        this.usersService.lookupContact(event.query).subscribe(data => {
            this.participantResults = data as any;
        });

    }

    getAssociateContact(data) {
        let membersToAdd = [];
        for (var key in data) {
            for (let i = 0; i < 5; i++) {
                if (data[key][i].uuid != null)
                    membersToAdd.push({ 'uuid': data[key][i].uuid.uuid, "role": data[key][i].role })
            }
        }
        return membersToAdd;
    }
    get acf() { return this.associateContacts.controls; }

    loadAssociateContact() {
        this.organizationService.getRelatedContact(this.uuid).subscribe(
            data => {
                this.relatedContact = data as any;
            });
    }

    saveAssociateContact() {
        if (this.associateContacts.invalid) {
            this.messageService.add({ severity: 'error', summary: 'Form Errors', detail: "Please fill Associate Contacts ", life: 3000 });
            return false;
        }
        let { membersToAdd } = this.associateContacts.value;
        let dataToPost: any = { membersToAdd };
        dataToPost.membersToAdd = this.getAssociateContact(this.associateContacts.value);
        let apiToCall: any;
        apiToCall = this.organizationService.getAssociateContact(this.uuid, dataToPost);
        apiToCall.subscribe(
            data => {
                this.associateContactHide();
                this.loadAssociateContact();
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: "Save Successfully", life: 3000 });
                this.acfSubmitted = false;
            },
            error => {
                let errors = error.error;
                if (errors.code && errors.code == 'VALIDATION_ERROR') {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: errors.subErrors[0].message, life: 3000 });

                }

            });
    }
    get documentsControl(): UntypedFormArray {
        return this.associateContacts.get('membersToAdd') as UntypedFormArray;
    }

    deleteAssContact() {
        let membersToRemove = this.appUtil.getClassificationUuid(this.selectedContacts);
        let dataToPost: any = { membersToRemove };
        let apiToCall: any
        apiToCall = this.organizationService.getAssociateContact(this.uuid, dataToPost);
        apiToCall.subscribe(
            data => {
                this.loadAssociateContact();
                this.reload();
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: "Remove Successfully", life: 3000 });
            },
            error => {
                let errors = error.error;
                if (errors.code && errors.code == 'VALIDATION_ERROR') {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: errors.subErrors[0].message, life: 3000 });
                }
            });
    }

    setCurrentSellside() {
        this.dataService.setData('org-', this.organization)
        this.router.navigate(['/sell-side/new'], { queryParams: { 'account': this.organization.uuid } });
    }
    setCurrentBuyside() {
        this.dataService.setData('org-', this.organization)
        this.router.navigate(['/buy-side/new'], { queryParams: { 'account': this.organization.uuid } });
    }

    setCurrentCampaign() {
        this.router.navigate(['/email-campaign/new'], { queryParams: { 'account': this.organization.uuid } });
    }

    getPeBuyout(buyside: any) {
        let val = "PE Buyout - ";
        let separator = "";
        if (buyside.primaryObjectives.peBuyout.tuckin) {
            val = val + "Tuck-in (Majority or 100%)";
            separator = "; ";
        }
        if (buyside.primaryObjectives.peBuyout.platform) {
            val = val + separator + "Platform";
        }
        if (val == "PE Buyout - ") {
            val = "";
        }
        return val;
    }

    getInvestmentDebt(buyside: any) {

        let debt = buyside.primaryObjectives.investment.debt;
        let val = "Investment (Debt) - ";
        let separator = "";

        if (debt.arFinancing) {
            val = val + "A/R Financing";
            separator = "; "
        }
        if (debt.juniorDebt) {
            val = val + separator + "Junior Debt"
            separator = "; "
        }
        if (debt.mezzanineFinancing) {
            val = val + separator + "Mezannine Financing";
            separator = "; "
        }
        if (debt.seniorDebt) {
            val = val + separator + "Senior Debt";
        }
        if (val == "Investment (Debt) - ") {
            val = "";
        }
        return val;
    }

    getInvestmentEquity(buyside: any) {
        let equity = buyside.primaryObjectives.investment.equity;
        let val = "Investment (Equity) - ";
        let separator = "";

        if (equity.angelInvestment) {
            val = val + "Angel Investment";
            separator = "; "
        }
        if (equity.growthEquity) {
            val = val + separator + "Growth Equity"
            separator = "; "
        }
        if (equity.minorityRecap) {
            val = val + separator + "Minority recap";
            separator = "; "
        }
        if (equity.ventureCapital) {
            val = val + separator + "Venture Capital";
        }
        if (val == "Investment (Equity) - ") {
            val = "";
        }
        return val;
    }

    getPrivateEquityPartner(sellside: any) {

        let val = "Private Equity Partner - ";
        let separator = "";
        if (sellside.primaryObjectives.privateEquityPartner.controlling) {
            val = val + separator + "Control";
            separator = "; ";
        }
        if (sellside.primaryObjectives.privateEquityPartner.hundredPercent) {
            val = val + separator + "100%";
            separator = "; ";
        }
        if (sellside.primaryObjectives.privateEquityPartner.minority) {
            val = val + separator + "Minority";
            separator = "; ";
        }
        if (val == "Private Equity Partner - ") {
            val = "";
        }
        return val;
    }

    getFinancingDebt(sellside: any) {

        let debt = sellside.primaryObjectives.financing.debt;
        let val = "Obtain Financing (Debt) - ";
        let separator = "";
        if (debt.arFinancing) {
            val = val + separator + "A/R Financing";
            separator = "; ";
        }
        if (debt.juniorDebt) {
            val = val + separator + "Junior Debt"
            separator = "; "
        }
        if (debt.mezzanineFinancing) {
            val = val + separator + "Mezannine Financing";
            separator = "; "
        }
        if (debt.seniorDebt) {
            val = val + separator + "Senior Debt";
        }
        if (val == "Obtain Financing (Debt) - ") {
            val = "";
        }
        return val;
    }

    getFinancingEquity(sellside: any) {

        let equity = sellside.primaryObjectives.financing.equity;
        let val = "Obtain Financing (Equity) - ";
        let separator = "";
        if (equity.angelInvestment) {
            val = val + "Angel Investment";
            separator = "; ";
        }
        if (equity.growthEquity) {
            val = val + separator + "Growth Equity";
            separator = "; ";
        }
        if (equity.minorityRecap) {
            val = val + separator + "Minority Recap";
            separator = "; ";
        }
        if (equity.ventureCapital) {
            val = val + separator + "Venture Capital";
            separator = "; ";
        }
        if (val == "Obtain Financing (Equity) - ") {
            val = "";
        }
        return val;
    }
    deepCopy(obj: any): any {
        if (obj === undefined) {
            return obj;
        }
        return JSON.parse(JSON.stringify(obj));
    }
    editField(fieldName: string) {
    //  console.log("editing field" ,  this.editingField);
     
    if(this.accountService.userValue.role==1){
        this.initialFieldValues[fieldName] = this.deepCopy(this.organization[fieldName]);
        for (let key in this.editingField) {
            this.editingField[key] = false;
        }
        if ((fieldName === 'isPubliclyTraded' || fieldName === 'parentCompany') && this.organization.companyType !== 'OPERATING_COMPANY') {
            this.editingField[fieldName] = false;
        }
        if ((fieldName === 'isPubliclyTraded' || fieldName === 'parentCompany') && this.organization.companyType === 'OPERATING_COMPANY') {
            this.editingField[fieldName] = true;
        }
        if (fieldName !== 'isPubliclyTraded' && fieldName !== 'parentCompany') {
            this.editingField[fieldName] = true;
        }
        if (fieldName === 'Level1' || fieldName === 'Level2' || fieldName === 'Level3' || fieldName === 'Vertical/Industry' || fieldName === 'Ecosystem') {

            this.editingField[fieldName] = true;
        }
        if (fieldName == 'subTypes') {
            this.editingField[fieldName] = true;
        }
    }
    
    }
    originalWebsiteValue: string;
    saveField(fieldName: string, value: any) {
        let fieldsToUpdate = [];
        fieldsToUpdate.push(fieldName);
        if (this.initialFieldValues[fieldName] === value) {
            this.closeDescriptionDialog(fieldName);
            this.editingField[fieldName] = false;
            return false;
        }
        if (fieldName == 'name' && (value == '' || value == null)) {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Name is required' });
            this.reload();
            //  this.editingField[fieldName] = false;
            return false;
        }
        let dataToPost: any = { uuid: this.organization.uuid, fieldsToUpdate: value };

        dataToPost[fieldName] = value;
        if (fieldName === 'leadSource') {
            dataToPost['leadSource'] = value ? value.value : null;
        }
        if (fieldName === 'numberOfEmployee') {
            dataToPost['numberOfEmployee'] = value ? value.value : null;
        }
        if (fieldName === 'revenue') {
            dataToPost['revenue'] = value ? value.value : null;
        }
        if (fieldName === 'country') {
            this.stateSuggestions = value?.states || [];
            dataToPost['country'] = value ? value.name : null;
            dataToPost['state'] = null;
            fieldsToUpdate.push('state');
        }
        if (fieldName === 'state') {
            dataToPost['state'] = value ? value.name : null;
        }
        if (fieldName === 'companyType') {
            dataToPost['companyType'] = value ? value.uuid : null;
            dataToPost['subTypes'] = null;
        }
        if (fieldName === 'subTypes') {
            dataToPost['subTypes'] = value ? value.map(option => option) : null;
        }
        if (fieldName === 'website' && !this.isValidUrl(value) && value !== '') {
            this.editingField[fieldName] = false;
            this.organization.website = this.originalWebsiteValue;
            return;
        }

        this.organizationService.updateOrganizationList(fieldsToUpdate, dataToPost).subscribe(
            data => {
                this.loading = false;
                if (fieldName === 'classification') {
                    const relatedFields = ['Level1', 'Level2', 'Level3', 'Vertical/Industry', 'Ecosystem'];
                    relatedFields.forEach(field => this.editingField[field] = false);
                }
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: `${this.formatFieldName(fieldName)} Updated Successfully`, life: 3000 });
                this.reload();

                this.editingField[fieldName] = false;
            },
            error => {
                let errors = error.error;
                if (errors.code && errors.code == 'VALIDATION_ERROR') {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: errors.subErrors[0].message, life: 3000 });
                }
            }
        )
    }
    searchOrganizations(event) {
        this.organizationService.lookupOrganization(event.query).subscribe(data => {
            this.organizationResults = data as any;
        });
    }
    getOutput(Value: any) {
        if (Value && typeof Value === 'object') {
            return Value.name ? Value.name : Value;
        }
        return Value;
    }
    getLookupOutput(Value: any) {
        if (Value && typeof Value === 'object') {
            return Value.value;
        }
        return Value;
    }

    searchLookupValue(event: AutoCompleteCompleteEvent, property: string) {
        let filtered: any[] = [];
        let query = event.query;
        let sources: any[] = [];
        if (property == 'accountOwner' || property == 'country' || property) {
            switch (property) {
                case 'accountOwner':
                    sources = this.tbpEmployees;
                    break;
                case 'country':
                    sources = this.countries;
                    break;
            }
            for (let i = 0; i < (sources as any[]).length; i++) {
                let employee = (sources as any[])[i];
                if (employee.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(employee);
                }
            }
            this.filteredValues = filtered;
        }
    }
    disableNavigation(event: any) {
        if (event.key === "ArrowRight" || event.key === "Right"
            || event.key === "ArrowLeft" || event.key === "Left"
            || event.key === "ArrowUp" || event.key === "Up"
            || event.key === "ArrowDown" || event.key === "Down") {
            event.stopPropagation();
        }
    }

    textInputCategories = [
        { label: 'CapIQ Id', fieldName: 'capitaliqId', url: 'capitaliq' },
        { label: 'Pitchbook Id', fieldName: 'pitchbookId', url: 'pitchbook' },
        { label: 'Linked In Page', fieldName: 'linkedinPageId', url: 'linkedin' }
    ];

    lookupCategoies = [
        { label: 'Lead Source', fieldName: 'leadSource', data: 'ACCOUNT_SOURCE' },
        { label: 'Revenue Range ($m)', fieldName: 'revenue', data: 'REVENUE' },
        { label: 'No. of Employees', fieldName: 'numberOfEmployee', data: 'No_OF_EMPLOYEES' },
    ];

    categories = [
        { label: 'Level1', value: 'CATEGORY_LEVEL1', model: 'level1' },
        { label: 'Level2', value: 'CATEGORY_LEVEL2', model: 'level2' },
        { label: 'Level3', value: 'CATEGORY_LEVEL3', model: 'level3' },
        { label: 'Vertical/Industry', value: 'VERTICAL', model: 'vertical' },
        { label: 'Ecosystem', value: 'ECOSYSTEM', model: 'ecosystem' }
    ];
    formatFieldName(fieldName: string): string {

        if (fieldName == 'companyType') {
            return 'Entity Type';
        }
        if (fieldName == 'subTypes') {
            return 'Company Sub Type';
        }
        if (fieldName == 'customSubtypeValue') {
            return 'Company Type';
        }
        if (fieldName == 'isPubliclyTraded') {
            return 'Publicly Traded';
        }
        const words = fieldName.split(/(?=[A-Z])/);
        return words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
    }
    closeDescriptionDialog(fieldName: string) {
        this.editingField[fieldName] = false;
        this.organization[fieldName] = this.deepCopy(this.initialFieldValues[fieldName]);
        event.stopPropagation();
        this.reload();
    }
    getOptions(field: string) {
        if (field === 'revenue') {
            return this.lookupList.REVENUE;
        }
        if (field === 'numberOfEmployee') {
            return this.lookupList.No_OF_EMPLOYEES;
        }
        if (field === 'leadSource') {
            return this.lookupList.ACCOUNT_SOURCE;
        }
    }
    searchOptions: any = [
        { 'name': 'Operating Company', 'uuid': 'OPERATING_COMPANY' },
        { 'name': 'Investor', 'uuid': 'INVESTOR' },
        { 'name': 'Lender', 'uuid': 'LENDER' },
        { 'name': 'Other', 'uuid': 'OTHER' }];

    getCompanyType(type: any): any {
        if (typeof type === 'object') {
            return type.name;
        } else {
            switch (type) {
                case 'OPERATING_COMPANY':
                    return 'Operating Company'
                case 'INVESTOR':
                    return 'Investor'
                case 'LENDER':
                    return 'Lender'
                case 'OTHER':
                    return 'Other'
            };
        }
    }

    isValidUrl(url: string): boolean {
        const pattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
        return pattern.test(url);
    }
    getAssociationTypeForBuyside(organization: any, buyside: any): string {
        if (organization.name === buyside.organization.name) {
            return "Organization";
        } else if (buyside.orgsAsPortfolio?.find(org => org.name === organization.name)) {
            return "Portfolio";
        } else {
            return "Prospect";
        }
    }
    getAssociationTypeForSellside(organization: any, sellside: any): string {
        if (organization.name === sellside.organization.name) {
            return "Organization";
        } else if (sellside.orgsAsPortfolio?.find(org => org.name === organization.name)) {
            return "Portfolio";
        } else {
            return "Prospect";
        }
    }
    onCompanyTypeChange(event: any) {
        // Update the organization.companyType with the selected value's uuid
        this.organization.companyType = event.value?.uuid || null;
    }
    onRevenueChange(event: any) {
       
        this.organization.revenue = event.value?.uuid || null;
        
        
    }
}