<div class="grid table-demo">
    <div class="col-12">
        <div class="card">
            <p-toolbar styleClass="m-0 headerSticky">
                <ng-template pTemplate="left">
                </ng-template>

                <ng-template pTemplate="right">
                    <button pButton pRipple label="Back" icon="pi pi-arrow-left" class="p-button-success mr-2" (click)="appUtil.return()"></button>
                    <button pButton pRipple label="Merge" icon="pi pi-link" class="p-button-info  mr-2"
                        (click)="merge()" [loading]="isMerging"></button>
                </ng-template>
            </p-toolbar>
            <form [formGroup]="mergeForm">
                <p-table [autoLayout]="true" [value]="fields" [rowHover]="true"
                    styleClass="p-datatable-tbp p-datatable-gridlines p-datatable-striped p-datatable-sm">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="min-width:100px"></th>
                            <th style="width:30%">
                                {{org1.name}}
                            </th>
                            <th style="width:30%">
                                <span class="mandate">*</span>
                                <p-autoComplete [suggestions]="suggestionList"
                                    (completeMethod)="searchOrg($event, true)" (onSelect)="selectSource($event, true)"
                                    (onUnselect)="unselectSource($event, true)" (onClear)="unselectSource($event, true)"
                                    field="name" dataKey="uuid" forceSelection="true" required="true"
                                    [ngClass]="{ 'ng-invalid ng-dirty': mergeForm.touched && !org2 }">
                                </p-autoComplete>
                            </th>
                            <th style="width:30%">
                                <p-autoComplete [suggestions]="suggestionList"
                                    (completeMethod)="searchOrg($event, false)" (onSelect)="selectSource($event, false)"
                                    (onUnselect)="unselectSource($event, false)"
                                    (onClear)="unselectSource($event, false)" field="name" dataKey="uuid"
                                    forceSelection="true" appendTo="body">
                                </p-autoComplete>
                            </th>
                        </tr>
                        <tr>
                            <th class="wrap labelCol" style="max-width:100px">Is Master Record?</th>
                            <th>
                                <p-radioButton value="{{org1.uuid}}" formControlName="masterId"></p-radioButton>
                            </th>
                            <th>
                                <p-radioButton value="{{org2.uuid}}" formControlName="masterId" *ngIf="org2">
                                </p-radioButton>
                            </th>
                            <th>
                                <p-radioButton value="{{org3.uuid}}" formControlName="masterId" *ngIf="org3">
                                </p-radioButton>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-field>
                        <tr>
                            <td class="wrap labelCol" style="max-width:100px">
                                <span class="p-column-title">Field Name</span>
                                {{field.label}}
                            </td>
                            <td class="wrap">
                                <span class="p-column-title">{{org1.name}}</span>
                                <ng-container  [ngTemplateOutlet]="readOnlyFields.indexOf(field.field) != -1 ?readonly : checkuserinput" [ngTemplateOutletContext]="{field:field, org:org1}">
                                </ng-container>
                            </td>
                            <td class="wrap">
                                <span class="p-column-title">Source 1</span>
                                <ng-container  [ngTemplateOutlet]="readOnlyFields.indexOf(field.field) != -1 ?readonly : checkuserinput" [ngTemplateOutletContext]="{field:field, org:org2}">
                                </ng-container>
                            </td>
                            <td class="wrap">
                                <span class="p-column-title">Source 2</span>
                                <ng-container  [ngTemplateOutlet]="readOnlyFields.indexOf(field.field) != -1 ?readonly : checkuserinput" [ngTemplateOutletContext]="{field:field, org:org3}">
                                </ng-container>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <ng-template #readonly let-field="field" let-org="org">
                    <ng-container *ngIf="org">
                        <ng-container [ngTemplateOutlet]="field.field == 'corporateInvestors' ?corporateInvestors : nocorporateInvestors" 
                            [ngTemplateOutletContext]="{field:field, org:org}"></ng-container>
                    </ng-container>
                </ng-template>
                <ng-template #corporateInvestors let-field="field" let-org="org">
                    <span *ngFor="let investor of org.investors">
                        <ng-container *ngIf="investor.type == 'CORPORATE'">
                            {{investor.name}}<ng-container *ngIf=" (mf.parentCompany.value?.uuid != investor.uuid) && (appUtil.getObject(mf.corporateInvestors.value,investor) == null)">
                                (<a href="javascript:" (click)="addCorporate(investor)">Add</a>)</ng-container>{{';'}}
                        </ng-container>
                    </span>
                </ng-template>
                <ng-template #nocorporateInvestors let-field="field" let-org="org">
                    <ng-container [ngTemplateOutlet]="field.field == 'individualInvestors' ?individualInvestors : noindividualInvestors" [ngTemplateOutletContext]="{field:field, org:org}">
                    </ng-container>
                </ng-template>
                <ng-template #individualInvestors let-field="field" let-org="org">
                    <span *ngFor="let investor of org.investors">
                        <ng-container *ngIf="investor.type != 'CORPORATE'">
                            {{investor.displayName}}<ng-container *ngIf="appUtil.getObject(mf.individualInvestors.value,investor) == null">
                                (<a href="javascript:" (click)="addIndividual(investor)">Add</a>)</ng-container>{{';'}}
                        </ng-container>
                    </span>
                </ng-template>
                <ng-template #noindividualInvestors let-field="field" let-org="org">
                    <ng-container [ngTemplateOutlet]="field.field == 'customSubtypeValue' && mf.companyType.value.uuid!='OPERATING_COMPANY' ?subTypes : nosubTypes" [ngTemplateOutletContext]="{field:field, org:org}">
                    </ng-container>
                </ng-template>
                <ng-template #subTypes let-field="field" let-org="org">
                    <span *ngFor="let subType of org.subTypes">
                        {{subType.value}}<ng-container *ngIf="(mf.companyType.value.uuid == org.companyType) && (appUtil.getObject(mf.subTypes.value,subType) == null)">
                            (<a href="javascript:" (click)="addSubtype(subType)">Add</a>)</ng-container>{{';'}}
                    </span>
                </ng-template>               
                <ng-template #nosubTypes let-field="field" let-org="org">
                    <ng-container [ngTemplateOutlet]="field.field == 'parentCompany'?parentcompany : noparentcompany" [ngTemplateOutletContext]="{field:field, org:org}">
                    </ng-container>
                </ng-template>   
                <ng-template #parentcompany let-field="field" let-org="org">
                    {{getValue(org,field.field)}}<ng-container *ngIf="org.parentCompany && mf.parentCompany.value?.uuid != org.parentCompany.uuid">
                    (<a href="javascript:" (click)="addParent(org.parentCompany)">Add</a>)</ng-container>
                </ng-template>   
                <ng-template #noparentcompany let-field="field" let-org="org">
                    <span innerHTML="{{getValue(org,field.field)}}" class="ml-2"></span>
                </ng-template>                                
                <ng-template #checkuserinput let-field="field" let-org="org">
                    <ng-container [ngTemplateOutlet]="editableFields.indexOf(field.field) != -1 ?userinput : simple" [ngTemplateOutletContext]="{field:field, org:org}">
                    </ng-container>
                </ng-template>
                <ng-template #userinput let-field="field" let-org="org">
                    <ng-container [ngTemplateOutlet]="(org && org.uuid == mf.masterId.value) ?editable : readonly" [ngTemplateOutletContext]="{field:field, org:org}">
                    </ng-container>
                </ng-template>
                <ng-template #editable let-field="field" let-org="org">
                    <ng-container *ngIf="field.field === 'companyType'">
                        <p-dropdown [options]="companyTypes" [formControlName]="field.field" optionLabel="name" dataKey="uuid"></p-dropdown>
                    </ng-container>
                    <ng-container *ngIf="field.field === 'customSubtypeValue'">
                        <p-dropdown [options]="operatingTypes" [formControlName]="field.field" optionLabel="name" *ngIf="mf.companyType.value.uuid === 'OPERATING_COMPANY'" 
                            dataKey="uuid" [autoDisplayFirst]="false"></p-dropdown>
                        <look-autocomplete [multiple]="true" [formControl]="mf.subTypes" [ngClass]="{ 'ng-invalid ng-dirty': submitted && mf.subTypes.errors }"
                            [type]="'ORG_TYPE_OTHER'" [items]="lookupList.ORG_TYPE_OTHER" *ngIf="mf.companyType.value.uuid === 'OTHER'">
                        </look-autocomplete>
                        <look-autocomplete [multiple]="true" [formControl]="mf.subTypes" [ngClass]="{ 'ng-invalid ng-dirty': submitted && mf.subTypes.errors }"
                            [type]="'ORG_TYPE_LENDER'" [items]="lookupList.ORG_TYPE_LENDER" *ngIf="mf.companyType.value.uuid === 'LENDER'">
                        </look-autocomplete>
                        <look-autocomplete [multiple]="true" [formControl]="mf.subTypes" [ngClass]="{ 'ng-invalid ng-dirty': submitted && mf.subTypes.errors }"
                            [type]="'ORG_TYPE_INVESTOR'" [items]="lookupList.ORG_TYPE_INVESTOR" *ngIf="mf.companyType.value.uuid === 'INVESTOR'">
                        </look-autocomplete>
                    </ng-container>
                    <ng-container *ngIf="field.field === 'individualInvestors'">
                        <tbp-autocomplete [formControl]="mf.individualInvestors" [items]="" [type]="'Contact'" field="displayName">
                        </tbp-autocomplete>
                    </ng-container>
                    <ng-container *ngIf="field.field === 'corporateInvestors'">
                        <tbp-autocomplete [formControl]="mf.corporateInvestors" [items]="" [type]="'Account'"  [exclude]="excludedParents" (onChange)="computeExcludedAccounts()">
                        </tbp-autocomplete>
                    </ng-container> 
                    <ng-container *ngIf="field.field === 'parentCompany'">
                        <tbp-autocomplete [multiple]="false" [formControl]="mf.parentCompany" [items]="" [type]="'Account'"  [exclude]="excludedParents" (onChange)="computeExcludedAccounts()">
                    </tbp-autocomplete>
                    </ng-container>       
                </ng-template>
                <ng-template #simple let-field="field" let-org="org">
                    <p-radioButton value="{{org.uuid}}" [formControlName]="field.field"
                        *ngIf="getValue(org,field.field)">
                    </p-radioButton>
                    <span innerHTML="{{getValue(org,field.field)}}" class="ml-2"></span>
                </ng-template>               
            </form>
        </div>
    </div>
</div>
