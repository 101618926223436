<p-dialog [(visible)]="show" (onHide)="hideDialogCls()" [style]="{width: '450px'}" [header]="type" [modal]="true"
    styleClass="p-fluid">
    <ng-template pTemplate="content">
        <form [formGroup]="formclass" #clsForm>
            <div class="field">
                <label for="name" class="label-set">Name</label><span class="mandate">*</span>
                <input type="text" maxlength="60" pInputText id="name" formControlName="name"
                    [ngClass]="{ 'ng-invalid ng-dirty': clsSubmitted && fc.name.errors }" />
                <small class="p-error" *ngIf="clsSubmitted && fc.name.invalid && fc.name.hasError('required')">Name is
                    required.</small>
                <small class="p-error"
                    *ngIf="clsSubmitted && fc.name.invalid && fc.name.hasError('message')">{{fc.name.errors.message}}</small>
            </div>
            <div class="field">
                <label for="description" class="label-set">Description</label>
                <textarea id="description" pInputTextarea formControlName="description" rows="6" cols="20"></textarea>
            </div>
        </form>
    </ng-template>

    <ng-template pTemplate="footer">
        <div *ngIf="clsSpinner">
            <p-progressSpinner [style]="{width: '50px', height: '50px'}" styleClass="custom-spinner" strokeWidth="8"
                fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
        </div>
        <div *ngIf="clsSpinner == false">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
                (click)="hideDialogCls()"></button>
            <button pButton pRipple label="Save" form="clform" icon="pi pi-check" (click)="saveClassification()"
                class="p-button-text"></button>
        </div>
    </ng-template>
</p-dialog>
<p-toast></p-toast>